import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { Count } from "epcm-common/dist/Types/GeneralTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getTimesheetGroupEmployeeCount = async (id: number, timesheetGroupId: number): Promise<Count> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/employees/count`,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  return response.data as Count;
};
