import { ApiResponseCtrMap } from "epcm-common/dist/Types/CtrTypes.ts";
import { FrontendCtrMappingRow } from "../CtrMappingTypes.ts";

export const convertCtrMappingToFrontendCtrMappingRow = (apiResponseCtrMap: ApiResponseCtrMap[]): FrontendCtrMappingRow[] => {
  return apiResponseCtrMap.map((apiResponseCtrMapItem) => {
    return {
      ctrs: apiResponseCtrMapItem.ctrs.map((ctr) => {
        return {
          ctrId: ctr.ctrId,
          canBeDeleted: ctr.canBeDeleted,
        };
      }),
      positionId: apiResponseCtrMapItem.positionId,
      positionDescription: apiResponseCtrMapItem.positionDescription,
    };
  });
};
