import { FC, useEffect, useMemo } from "react";
import GroupsItemStyles from "./GroupsItemStyles.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import TimesheetGroupSelectGroupMilitaryIcon from "../../../../../assets/images/timesheet-group-select-group-military-icon.svg";
import TimesheetGroupSelectGroupMembersIcon from "../../../../../assets/images/timesheet-group-select-group-members-icon.svg";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames, SubQueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { FrontendUserLimitedType } from "../../../../../types/apicallstypes/UsersApiTypes.ts";
import { useEpcmApiUsers } from "../../../../../apicalls/users/useEpcmApiUsers.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import EmployeeNameSkeletonLoader from "../../timesheetgroupsettingspopup/loaders/EmployeeNameSkeletonLoader.tsx";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { Fade, Tooltip } from "@mui/material";

interface GroupsItemProps {
  projectId: number;
  groupItemId: number;
  groupName: string;
  sectionHeadCode: string | null;
}

const GroupsItem: FC<GroupsItemProps> = ({ groupName, projectId, groupItemId, sectionHeadCode }) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getUser } = useEpcmApiUsers();
  const { getTimesheetGroupEmployeeCount } = useEpcmApiProjectsTimesheetsGroups();

  const timesheetGroupSectionHeadQuery = useQuery({
    queryKey: [QueryNames.User, sectionHeadCode],

    queryFn: () => getUser(sectionHeadCode ?? "", DetailLevel.BASIC),

    enabled: isAuthorized && sectionHeadCode != null,
    select: (data) => data as FrontendUserLimitedType,
  });

  const timesheetGroupEmployeesCountQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetGroupEmployeeCount, SubQueryNames.list, projectId, groupItemId],
    queryFn: () => getTimesheetGroupEmployeeCount(projectId, groupItemId),
    enabled: isAuthorized,
  });
  const timesheetGroupSectionHeadData = useMemo(() => {
    return timesheetGroupSectionHeadQuery.data ? timesheetGroupSectionHeadQuery.data : null;
  }, [timesheetGroupSectionHeadQuery]);

  const timesheetGroupEmployeesCount = useMemo(() => {
    return timesheetGroupEmployeesCountQuery.data ? timesheetGroupEmployeesCountQuery.data : null;
  }, [timesheetGroupEmployeesCountQuery]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.User, sectionHeadCode] }).then(() => console.log(`Query ${QueryNames.User} cancelled`));
    };
  }, [queryClient, sectionHeadCode]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectTimesheetGroupEmployeeCount, SubQueryNames.list, projectId, groupItemId] })
        .then(() => console.log(`Query ${QueryNames.ProjectTimesheetGroupEmployeeCount} cancelled`));
    };
  }, [queryClient, projectId, groupItemId]);

  return (
    <div
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.flex1,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.centerVertical,
        GlobalStyles.gap025,
        GroupsItemStyles.container,
      )}
      key={groupItemId}
    >
      <div className={classNames(GroupsItemStyles.mainText)}>{groupName}</div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025, GlobalStyles.centerHorizontal, GroupsItemStyles.textItem)}>
          <img src={TimesheetGroupSelectGroupMilitaryIcon} alt={"timesheet-group-select-group-military-icon"} />
          {timesheetGroupSectionHeadData ? (
            <div className={classNames(GroupsItemStyles.subText)}>{timesheetGroupSectionHeadData.name}</div>
          ) : sectionHeadCode ? (
            <EmployeeNameSkeletonLoader />
          ) : (
            <Tooltip title={"No section head"} placement={"top"} arrow TransitionComponent={Fade}>
              <div className={classNames(GroupsItemStyles.subText)}>--</div>
            </Tooltip>
          )}
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025, GlobalStyles.centerHorizontal)}>
          <img src={TimesheetGroupSelectGroupMembersIcon} alt={"timesheet-group-select-group-members-icon"} />
          {timesheetGroupEmployeesCount && timesheetGroupEmployeesCount > 0 ? (
            <div className={classNames(GroupsItemStyles.subText)}>{`${timesheetGroupEmployeesCount}`} members</div>
          ) : timesheetGroupEmployeesCountQuery.isLoading ? (
            <div className={classNames(GroupsItemStyles.subText)}>Loading group members...</div>
          ) : (
            <div className={classNames(GroupsItemStyles.subText)}>0 Members</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupsItem;
