import { FC } from "react";
import classnames from "classnames";
import GlobalStyles from "../../../../../../../../../assets/css/GlobalStyles.module.css";
import OvertimeTooltipStyles from "./OvertimeTooltip.module.css";

interface OvertimeTooltipProps {
  maxHours: number;
  locationWorkingHours: number;
  overtimeHours: number;
}

const OvertimeTooltip: FC<OvertimeTooltipProps> = ({ maxHours, locationWorkingHours, overtimeHours }) => {
  return (
    <div className={classnames(OvertimeTooltipStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
      <div className={classnames(GlobalStyles.flex, GlobalStyles.gap025)}>
        <div className={classnames(OvertimeTooltipStyles.hoursLabel, GlobalStyles.centerVertical)}>{"MAX HOURS:"}</div>
        <div className={classnames(GlobalStyles.flex1)} />
        <div className={classnames(OvertimeTooltipStyles.hoursValue, GlobalStyles.centerVertical)}>{maxHours}</div>
      </div>
      <div className={classnames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
        <div className={classnames(GlobalStyles.flex, GlobalStyles.gap025)}>
          <div className={classnames(OvertimeTooltipStyles.hoursLabel, GlobalStyles.centerVertical)}>{"Location Hours:"}</div>
          <div className={classnames(GlobalStyles.flex1)} />
          <div className={classnames(OvertimeTooltipStyles.hoursValue, GlobalStyles.centerVertical)}>{locationWorkingHours}</div>
        </div>
        <div className={classnames(GlobalStyles.flex, GlobalStyles.gap025)}>
          <div className={classnames(OvertimeTooltipStyles.hoursLabel, GlobalStyles.centerVertical)}>{"Overtime Hours:"}</div>
          <div className={classnames(GlobalStyles.flex1)} />
          <div className={classnames(OvertimeTooltipStyles.hoursValue, GlobalStyles.centerVertical)}>{overtimeHours}</div>
        </div>
      </div>
    </div>
  );
};

export default OvertimeTooltip;
