import classNames from "classnames";
import TimesheetGroupItemStyles from "./TimesheetGroupItemStyles.module.css";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import TimesheetMilitaryIcon from "../../../../../../assets/images/timesheet-military-icon.svg";
import TimesheetBuilderIcon from "../../../../../../assets/images/timesheet-builder-icon.svg";
import settingsIcon from "../../../../../../assets/images/setting-dots-black.svg";
import { FrontendTimesheetGroupBasicWithHasPendingType } from "../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import groupBgIcon from "../../../../../../assets/images/timeheet-group-gear-bg.svg";
import { EpcmTooltip } from "../../../../../../ui/epcmtooltip/EpcmTooltip.tsx";
import { ClickAwayListener, Fade, Skeleton, Tooltip } from "@mui/material";
import settingGearIcon from "../../../../../../assets/images/settings-icon-blue-dark.svg";
import deleteIcon from "../../../../../../assets/images/trashcan-icon-red.svg";
import DeleteMmrItemGrayIcon from "../../../../../../assets/images/delete-bin-light-grey.svg";

import PencilBlackIcon from "../../../../../../assets/images/pencil-black.svg";
import PencilGrayIcon from "../../../../../../assets/images/pencil-gray.svg";
import { getSubstringBasedOnChars } from "../../../../../../utils/StringManipulation.ts";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { useProjectContext } from "../../../../ProjectsUtils.ts";
import { useEpcmApiUsers } from "../../../../../../apicalls/users/useEpcmApiUsers.ts";
import { DeletePopup } from "../../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { useParams } from "react-router-dom";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../use-projects-popups.ts";
import TimesheetGroupSettingsPopup from "../../../../popups/timesheetgroupsettingspopup/TimesheetGroupSettingsPopup.tsx";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import { useRetrieveUserPermittedActions } from "../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../types/Roles.ts";
import CreateNewProjectGroup from "../../../../popups/createnewprojectgroup/CreateNewProjectGroup.tsx";
import { ProjectTimesheetGroupModificationTypeEnum } from "../../../../../../types/projects/ProjectTimesheetsTypes.ts";

interface TimesheetGroupItemProps {
  groupItem: FrontendTimesheetGroupBasicWithHasPendingType;
}

const TimesheetGroupItem: FC<TimesheetGroupItemProps> = ({ groupItem }) => {
  const { currentProject } = useProjectContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getTimesheetGroupEmployeeCount, getTimesheetGroupDeletionEligibility } = useEpcmApiProjectsTimesheetsGroups();
  const { getUser } = useEpcmApiUsers();
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const onOpenGroupSettings = useCallback(() => {
    onOpenPopup(ProjectsPagePopups.timesheetGroupSettings, popupHandler);
  }, [onOpenPopup, popupHandler]);

  const sectionHeadUserQuery = useQuery({
    queryKey: [QueryNames.Users, groupItem.sectionHeadCode],
    queryFn: () => getUser(groupItem.sectionHeadCode!),
    enabled: isAuthorized && !!groupItem.sectionHeadCode,
  });

  const timesheetGroupEmployeeCountQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetGroupEmployeeCount, currentProject.id, groupItem.id],
    queryFn: () => getTimesheetGroupEmployeeCount(currentProject.id, groupItem.id),
    enabled: isAuthorized,
  });

  const timesheetGroupItemDeletionEligibilityQuery = useQuery({
    queryKey: [QueryNames.TimesheetGroupDeletionEligibility, currentProject.id, groupItem.id],
    queryFn: () => getTimesheetGroupDeletionEligibility(currentProject.id, groupItem.id),
    enabled: isAuthorized,
  });

  const timesheetGroupItemDeletionEligibilityData = useMemo(() => {
    return timesheetGroupItemDeletionEligibilityQuery.data ?? undefined;
  }, [timesheetGroupItemDeletionEligibilityQuery]);

  const sectionHeadUserData = useMemo(() => {
    return sectionHeadUserQuery.data ?? undefined;
  }, [sectionHeadUserQuery]);

  const timesheetGroupEmployeeCount = useMemo(() => {
    return timesheetGroupEmployeeCountQuery.data ?? undefined;
  }, [timesheetGroupEmployeeCountQuery]);

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };
  const handleToolTipClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    handleTooltipOpen();
  };
  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };
  const { useDeleteTimesheetGroupMutation } = useEpcmApiProjectTimesheetsGroupsMutations();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const deleteTimesheetGroupMutation = useDeleteTimesheetGroupMutation(Number(projectId), groupItem.id, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Group was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });
  const invalidateQueries = useCallback(() => {
    void queryClient.resetQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, Number(projectId)] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, Number(projectId)] });
  }, [queryClient, projectId]);

  const canDeleteTimesheetGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupDelete);
  const canRenameTimesheetGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupRename);

  const timesheetGroupItemTooltip = (
    <div className={classNames(TimesheetGroupItemStyles.employeeTooltipContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.gap)}
        onClick={(event) => {
          event.preventDefault();
          onOpenGroupSettings();
        }}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img className={classNames(TimesheetGroupItemStyles.employeeTooltipIconImg)} src={settingGearIcon} alt="gear" />
        </div>
        <div className={classNames(GlobalStyles.centerVertical, TimesheetGroupItemStyles.toolTipTextBase)}>{"Group Settings"}</div>
      </div>
      {
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.gap, canRenameTimesheetGroup ? GlobalStyles.elementWithCursor : "")}
          onClick={() => {
            if (canRenameTimesheetGroup) {
              onOpenPopup(ProjectsPagePopups.updateProjectGroupName, popupHandler);
            }
          }}
        >
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img
              className={classNames(TimesheetGroupItemStyles.employeeTooltipIconImg)}
              src={canRenameTimesheetGroup ? PencilBlackIcon : PencilGrayIcon}
              alt="renameIcon"
            />
          </div>
          <div
            className={classNames(
              GlobalStyles.centerVertical,
              TimesheetGroupItemStyles.toolTipTextBase,
              canRenameTimesheetGroup ? "" : TimesheetGroupItemStyles.toolTipNotClickableText,
            )}
          >
            {"Rename Group"}
          </div>
        </div>
      }
      {canDeleteTimesheetGroup ? (
        !timesheetGroupItemDeletionEligibilityQuery.isLoading ? (
          <div
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.gap,
              timesheetGroupItemDeletionEligibilityData?.canBeDeleted ? GlobalStyles.elementWithCursor : "",
            )}
            onClick={(event) => {
              if (timesheetGroupItemDeletionEligibilityData?.canBeDeleted) {
                event.preventDefault();
                onOpenUtilPopup();
              }
            }}
          >
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img
                className={classNames(TimesheetGroupItemStyles.employeeTooltipIconImg)}
                src={timesheetGroupItemDeletionEligibilityData?.canBeDeleted ? deleteIcon : DeleteMmrItemGrayIcon}
                alt="deleteIcon"
              />
            </div>
            <div
              className={classNames(
                GlobalStyles.centerVertical,
                TimesheetGroupItemStyles.toolTipTextBase,
                timesheetGroupItemDeletionEligibilityData?.canBeDeleted
                  ? TimesheetGroupItemStyles.toolTipTextRed
                  : (TimesheetGroupItemStyles.toolTipItemNotClickable, TimesheetGroupItemStyles.toolTipNotClickableText),
              )}
            >
              {"Delete Group"}
            </div>
          </div>
        ) : (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap)}>
            <Skeleton variant={"rounded"} height={20} width={17} />
            <Skeleton variant={"rounded"} height={20} width={96} />
          </div>
        )
      ) : (
        <></>
      )}

      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteTimesheetGroupMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </div>
  );

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Users, groupItem.sectionHeadCode] })
        .then(() => console.log(`In timesheet group item with id ${groupItem.id}, ${QueryNames.Users} query canceled`));
    };
  }, [queryClient, currentProject, groupItem]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectTimesheetGroupEmployeeCount, currentProject.id, groupItem.id] })
        .then(() =>
          console.log(
            `In project${currentProject.id}, in  timesheet group item with id ${groupItem.id}, ${QueryNames.ProjectTimesheetGroupEmployeeCount} query canceled`,
          ),
        );
    };
  }, [queryClient, currentProject, groupItem.id]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.TimesheetGroupDeletionEligibility, currentProject.id, groupItem.id] })
        .then(() =>
          console.log(
            `In project${currentProject.id}, in  timesheet group item with id ${groupItem.id}, ${QueryNames.TimesheetGroupDeletionEligibility} query canceled`,
          ),
        );
    };
  }, [queryClient, currentProject, groupItem.id]);

  return (
    <div
      className={classNames(
        TimesheetGroupItemStyles.timesheetItemContainer,
        groupItem.hasPending && TimesheetGroupItemStyles.timesheetItemContainerRed,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap3,
      )}
      onClick={(event) => {
        (popupHandler.get(ProjectsPagePopups.timesheetGroupSettings)!.isOpen || isTooltipOpen) && event.stopPropagation();
      }}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
        <div
          className={classNames(
            TimesheetGroupItemStyles.groupInfoContainer,
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap025,
          )}
        >
          <Tooltip
            TransitionComponent={Fade}
            title={getSubstringBasedOnChars(groupItem.name) !== groupItem.name ? groupItem.name : ""}
            placement="top"
            arrow
          >
            <div className={classNames(TimesheetGroupItemStyles.timesheetItemTitle)}>{getSubstringBasedOnChars(groupItem.name)}</div>
          </Tooltip>
          <div className={classNames(TimesheetGroupItemStyles.timesheetItemSubTitle)}>{"Group"}</div>
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img className={classNames(TimesheetGroupItemStyles.groupBgIconImg)} src={groupBgIcon} alt="bgIcon" />
        </div>
        <div className={classNames()}>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div
              className={classNames(TimesheetGroupItemStyles.settingsIcon, GlobalStyles.centerVertical)}
              onClick={(event) => {
                handleToolTipClick(event);
              }}
            >
              <EpcmTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={isTooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={timesheetGroupItemTooltip}
                arrow
                placement={"bottom"}
              >
                <img className={classNames(TimesheetGroupItemStyles.settingsIconImg)} src={settingsIcon} alt="settings" />
              </EpcmTooltip>
            </div>
          </ClickAwayListener>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.flex1, GlobalStyles.gap025)}>
          <div className={classNames(TimesheetGroupItemStyles.iconContainer, GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
            <img src={TimesheetMilitaryIcon} alt="icon" />
          </div>
          {groupItem.sectionHeadCode && sectionHeadUserQuery.isLoading && <Skeleton variant={"rounded"} height={20} width={200} />}
          {!groupItem.sectionHeadCode && (
            <Tooltip TransitionComponent={Fade} title={"No section head"} placement="top" arrow>
              <div className={classNames(TimesheetGroupItemStyles.noInfoMsg)}>{"- -"}</div>
            </Tooltip>
          )}
          {sectionHeadUserData && (
            <Tooltip
              TransitionComponent={Fade}
              title={getSubstringBasedOnChars(sectionHeadUserData.name, 20) !== sectionHeadUserData.name ? sectionHeadUserData.name : ""}
              placement="top"
              arrow
            >
              <div>{getSubstringBasedOnChars(sectionHeadUserData.name, 20)}</div>
            </Tooltip>
          )}
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
          <div className={classNames(TimesheetGroupItemStyles.iconContainer, GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
            <img src={TimesheetBuilderIcon} alt="icon" />
          </div>
          {timesheetGroupEmployeeCountQuery.isLoading && <Skeleton variant={"rounded"} height={20} width={20} />}
          <div>{timesheetGroupEmployeeCount}</div>
        </div>
      </div>
      {popupHandler.get(ProjectsPagePopups.timesheetGroupSettings)!.isOpen && (
        <TimesheetGroupSettingsPopup
          timesheetGroupId={groupItem.id}
          isOpen={popupHandler.get(ProjectsPagePopups.timesheetGroupSettings)!.isOpen}
          projectId={Number(projectId!)}
          closeFn={() => onClosePopup(ProjectsPagePopups.timesheetGroupSettings, popupHandler)}
          headerText={groupItem.name ?? popupHeaders.get(ProjectsPagePopups.timesheetGroupSettings)}
        />
      )}
      {popupHandler.get(ProjectsPagePopups.updateProjectGroupName)!.isOpen && (
        <CreateNewProjectGroup
          mode={ProjectTimesheetGroupModificationTypeEnum.update}
          projectId={parseInt(projectId!)}
          timesheetGroupId={groupItem.id}
          existingGroupName={groupItem.name}
          isOpen={popupHandler.get(ProjectsPagePopups.updateProjectGroupName)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.updateProjectGroupName, popupHandler)}
          headerText={groupItem.name ?? popupHeaders.get(ProjectsPagePopups.updateProjectGroupName)}
        />
      )}
    </div>
  );
};

export default TimesheetGroupItem;
