import { QueryModelWithPagination } from "../../types/apicallstypes/queryCommons.ts";
import { getAllUsers } from "./getAllUsers.ts";
import { getUser } from "./getUser.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { FrontendUserLimitedType, FrontendUserRolesType } from "../../types/apicallstypes/UsersApiTypes.ts";
import { convertMultipleUsersLimitedData, convertUserLimitedData } from "../../types/apicallstypes/apicallstypesconverters/UsersApiConverter.ts";
import { getUsersRoles } from "./getUsersRoles.ts";
import { convertUserRolesList } from "../../types/apicallstypes/apicallstypesconverters/projects/projectpermissions/ProjectPermissionsApiConverter.ts";

interface ApiDefinition {
  getAllUsers: (
    pageOffset: number,
    pageSize?: number,
    searchFilter?: string,
    projectId?: number,
    hasProjectRoles?: boolean,
    hasGlobalRoles?: boolean,
    projectRoles?: string[],
  ) => Promise<QueryModelWithPagination<FrontendUserLimitedType>>;
  getUsersRoles: () => Promise<FrontendUserRolesType[]>;
  getUser: (code: string, detailLevel?: DetailLevel) => Promise<FrontendUserLimitedType>;
}

export const useEpcmApiUsers = (): ApiDefinition => {
  const getAllUsersConverted = async (
    pageOffset: number,
    pageSize?: number,
    searchFilter?: string,
    projectId?: number,
    hasProjectRoles?: boolean,
    hasGlobalRoles?: boolean,
    projectRoles?: string[],
  ): Promise<QueryModelWithPagination<FrontendUserLimitedType>> => {
    const usersData = await getAllUsers(pageOffset, pageSize, searchFilter, projectId, hasProjectRoles, hasGlobalRoles, projectRoles);

    return {
      data: convertMultipleUsersLimitedData(usersData.data),
      nextPage: usersData.nextPage,
      totalCount: usersData.totalCount,
    };
  };
  const getUserConverted = async (code: string, detailLevel?: DetailLevel): Promise<FrontendUserLimitedType> => {
    const userData = await getUser(code, detailLevel);
    return convertUserLimitedData(userData);
  };

  const getUsersRolesConverted = async (): Promise<FrontendUserRolesType[]> => {
    const response = await getUsersRoles();

    return convertUserRolesList(response);
  };

  return {
    getAllUsers: getAllUsersConverted,
    getUsersRoles: getUsersRolesConverted,
    getUser: getUserConverted,
  };
};
