import styles from "./GlobalEmployeeAccordionGrouped.module.css";
import { FC } from "react";

import GlobalEmployeeAccordion from "../globalemployeeaccordion/GlobalEmployeeAccordion.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";

interface ProjectEmployeeAccordionGroupedProps {
  qualifications: string | undefined;
  certifications: string | undefined;
  experience: string | undefined;
}

const GlobalEmployeeAccordionGrouped: FC<ProjectEmployeeAccordionGroupedProps> = ({ qualifications, certifications, experience }) => {
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025, styles.container)}>
      {<GlobalEmployeeAccordion title={"Qualifications"} content={qualifications} />}
      {<GlobalEmployeeAccordion title={"Certifications"} content={certifications} />}
      {<GlobalEmployeeAccordion title={"Experience"} content={experience} />}
    </div>
  );
};

export default GlobalEmployeeAccordionGrouped;
