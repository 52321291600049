import { create } from "zustand";
import { persist } from "zustand/middleware";

interface BannerSideState {
  isCollapsed: boolean;
  toggleIsCollapsed: (newState: boolean) => void;
}

export const useBannerSideStore = create<BannerSideState>()(
  persist(
    (set, get) => ({
      isCollapsed: get()?.isCollapsed,
      toggleIsCollapsed: (newState: boolean) => {
        set((state) => ({
          ...state,
          isCollapsed: newState,
        }));
      },
    }),
    {
      name: "banner-side-storage",
    },
  ),
);
