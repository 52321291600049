import { PopupButtonTypeEnum, PopupType } from "../../types/PopupType.ts";
import { FC } from "react";
import DeletePopupStyles from "./DeletePopup.module.css";
import { PopupStructure } from "../popupstructure/PopupStructure.tsx";
import warningIcon from "../../assets/images/warning-icon-circled-red-background.svg";
import classNames from "classnames";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";

interface DeletePopupProps extends PopupType {
  onDeleteFn: () => void;
}

export const DeletePopup: FC<DeletePopupProps> = ({ isOpen, closeFn, onDeleteFn }) => {
  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel",
          buttonType: PopupButtonTypeEnum.neutral,
          action: closeFn,
        },
        {
          text: "Delete",
          buttonType: PopupButtonTypeEnum.danger,
          action: onDeleteFn,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={undefined}
    >
      <div className={classNames(DeletePopupStyles.contentContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img src={warningIcon} alt="warning" className={classNames(DeletePopupStyles.warningIconImg)} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(DeletePopupStyles.contentDangerText)}>{"Delete Action"}</div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <div className={classNames(DeletePopupStyles.contentMainText)}>{"Are you sure?"}</div>
            <div className={classNames(DeletePopupStyles.contentSecondaryText)}>{"This action cannot be undone"}</div>
          </div>
        </div>
      </div>
    </PopupStructure>
  );
};
