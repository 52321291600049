import { ApiResponseMissingConfiguration } from "epcm-common/dist/Types/GeneralTypes";
import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getProjectConfigurationsMissingInfo = async (projectId: number): Promise<ApiResponseMissingConfiguration> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/configuration`,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  return response.data;
};
