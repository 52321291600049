import { Fade, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const EpcmTimesheetTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip TransitionComponent={Fade} {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#eff2fa",
    boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25)",
    maxWidth: "none",
    padding: "0 0",
    borderRadius: "8px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#343238",
  },
});
