import { FC, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import MonthListItemStyles from "./MonthListItem.module.css";
// import settingsIcon from "../../../../../../../assets/images/setting-dots-black.svg";
import counterCalendarIcon from "../../../../../../../assets/images/configurations-counter-calendar.svg";
import percentageIcon from "../../../../../../../assets/images/configurations-percentage.svg";

import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";

import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { useEpcmApiConfigurationCalendarInfo } from "../../../../../../../apicalls/configurations/calendar/useEpcmApiConfigurationCalendarInfo.ts";
import { QueryNames } from "../../../../../../../types/apicallstypes/queryCommons.ts";
import InfiniteScrollInViewElement from "../../../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { Skeleton, Tooltip } from "@mui/material";
import { FrontendDayTypeOptionsEnum } from "../../../../../../../types/apicallstypes/CalendarApiTypes.ts";

interface MonthListItemProps {
  monthId: number;
}

const MonthListItem: FC<MonthListItemProps> = ({ monthId }) => {
  const { projectId } = useParams();
  const { ref, inView } = useInView();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getGeneralCalendarInfo } = useEpcmApiConfigurationCalendarInfo();
  const { getAllCalendarDays } = useEpcmApiConfigurationCalendarInfo();
  const queryClient = useQueryClient();

  const [searchQuery] = useState<string>("");

  const projectCalendarManagementQuery = useQuery({
    queryKey: [QueryNames.ProjectCalendar, projectId],
    queryFn: () => getGeneralCalendarInfo(parseInt(projectId!)),
    enabled: isAuthorized,
  });

  const projectCalendarDaysQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectCalendarDays, projectId, monthId],
    queryFn: ({ pageParam }) => getAllCalendarDays(parseInt(projectId!), monthId, pageParam, searchQuery, 31),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const projectCalendarDaysData = useMemo(() => {
    return projectCalendarDaysQuery.data?.pages.flatMap((page) => page.data);
  }, [projectCalendarDaysQuery.data]);

  const uniqueLocationIds = useMemo(() => {
    const allLocationIds = projectCalendarDaysData?.flatMap((dayData) =>
      dayData.calendarDays.flatMap((calendarDay) => calendarDay.locationCalendarDays.map((locationDay) => locationDay.locationId)),
    );
    return Array.from(new Set(allLocationIds));
  }, [projectCalendarDaysData]);

  // Days per location for the counter
  const daysPerLocation = useMemo(() => {
    const counts: { [locationId: number]: number } = {};
    projectCalendarDaysData?.forEach((frontendDay) => {
      frontendDay.calendarDays.forEach((calendarDay) => {
        calendarDay.locationCalendarDays.forEach((locationCalendarDay) => {
          const { locationId } = locationCalendarDay;
          // Increment count only for working days
          if (locationCalendarDay.optionType === FrontendDayTypeOptionsEnum.WORKING_DAY) {
            counts[locationId] = (counts[locationId] || 0) + 1;
          }
        });
      });
    });
    return counts;
  }, [projectCalendarDaysData]);

  const progressiveDayCounter = useMemo(() => {
    const cumulativeCounts: { [locationId: number]: number } = {}; // Keeps track of the progressive count per location

    return projectCalendarDaysData?.map((frontendDay) => {
      frontendDay.calendarDays.forEach((calendarDay) => {
        calendarDay.locationCalendarDays.forEach((locationCalendarDay) => {
          const { locationId } = locationCalendarDay;
          if (!cumulativeCounts[locationId]) cumulativeCounts[locationId] = 0;

          if (locationCalendarDay.optionType === FrontendDayTypeOptionsEnum.WORKING_DAY) {
            cumulativeCounts[locationId] += 1;
          }
        });
      });

      return { ...cumulativeCounts };
    });
  }, [projectCalendarDaysData]);

  const locationDescriptionMap = useMemo(() => {
    const map = new Map();
    if (projectCalendarManagementQuery.data && projectCalendarManagementQuery.data.locations) {
      projectCalendarManagementQuery.data.locations.forEach((location) => {
        map.set(location.id, location.description);
      });
    }
    return map;
  }, [projectCalendarManagementQuery]);

  useEffect(() => {
    if (
      inView &&
      !projectCalendarDaysQuery.isLoading &&
      !projectCalendarDaysQuery.isFetching &&
      !projectCalendarDaysQuery.isFetchingNextPage &&
      projectCalendarDaysQuery.hasNextPage
    ) {
      projectCalendarDaysQuery.fetchNextPage();
    }
  }, [inView, projectCalendarDaysQuery]);

  const dayItemSkeletonLoader = <Skeleton width={"100%"} height={60} variant={"rounded"}></Skeleton>;

  const calendarDaysLoader = (
    <div
      className={classNames(
        MonthListItemStyles.fullWidthElement,
        GlobalStyles.overflowHiddenFullHeight,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
      )}
    >
      <Skeleton width={"100%"} height={100} variant={"rounded"}></Skeleton>
      <Skeleton width={"100%"} height={100} variant={"rounded"}></Skeleton>
      <Skeleton width={"100%"} height={100} variant={"rounded"}></Skeleton>
      <Skeleton width={"100%"} height={100} variant={"rounded"}></Skeleton>
      <Skeleton width={"100%"} height={100} variant={"rounded"}></Skeleton>
      <Skeleton width={"100%"} height={100} variant={"rounded"}></Skeleton>
      <Skeleton width={"100%"} height={100} variant={"rounded"}></Skeleton>
      <Skeleton width={"100%"} height={100} variant={"rounded"}></Skeleton>
      <Skeleton width={"100%"} height={100} variant={"rounded"}></Skeleton>
    </div>
  );

  useEffect(() => {
    return () => {
      void queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectCalendarDays, projectId, monthId] })
        .then(() => console.log(`In MonthListItem component, ${QueryNames.ProjectCalendarDays} query for month ${monthId} cancelled)`));
    };
  }, [queryClient, projectId, monthId]);

  return (
    <div
      className={classNames(
        MonthListItemStyles.monthItemContainerDiv,
        GlobalStyles.flex,
        GlobalStyles.gap,
        GlobalStyles.overflowHiddenFullHeight,
        GlobalStyles.flex6,
      )}
    >
      {projectCalendarDaysData ? (
        <div className={classNames(MonthListItemStyles.dayContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
          {projectCalendarDaysData &&
            projectCalendarDaysData.map((dayData, index) =>
              dayData.calendarDays.map((day) => {
                return (
                  <div key={day.id} className={classNames(MonthListItemStyles.parentDiv, GlobalStyles.flex, GlobalStyles.centerVertical)}>
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
                      <div
                        className={classNames(
                          MonthListItemStyles.dateContainer,
                          GlobalStyles.flex,
                          GlobalStyles.flexDirectionColumn,
                          GlobalStyles.gap01,
                          GlobalStyles.centerHorizontal,
                        )}
                      >
                        <div className={classNames(MonthListItemStyles.weekdayText)}>{day.dayName}</div>
                        <div className={classNames(MonthListItemStyles.numericDayText)}>{day.dayNumber}</div>
                        <div className={classNames(MonthListItemStyles.shortMonthText)}>{day.monthName}</div>
                      </div>
                      <div
                        className={classNames(
                          MonthListItemStyles.locationContainer,
                          GlobalStyles.flex,
                          GlobalStyles.flex1,
                          GlobalStyles.centerHorizontal,
                          GlobalStyles.gap05,
                        )}
                      >
                        {uniqueLocationIds.map((locationId) => {
                          const locationDay = day.locationCalendarDays.find((ld) => ld.locationId === locationId);
                          const totalDays = daysPerLocation[locationId];
                          const cumulativeCounter = progressiveDayCounter ? progressiveDayCounter[index]?.[locationId] || 0 : 0;
                          const description = locationDescriptionMap.get(locationId);

                          return (
                            locationDay && (
                              <Tooltip
                                arrow
                                key={locationId}
                                title={
                                  locationDay.optionType === FrontendDayTypeOptionsEnum.NO_WORKING_DAY
                                    ? "No working day"
                                    : locationDay.optionType === FrontendDayTypeOptionsEnum.HOLIDAY
                                      ? "Holiday: " + locationDay.holidayTitle
                                      : ""
                                }
                              >
                                <div
                                  className={classNames(
                                    MonthListItemStyles.officeContainer,
                                    GlobalStyles.flex,
                                    GlobalStyles.flex1,
                                    GlobalStyles.centerHorizontal,
                                    GlobalStyles.gap,

                                    locationDay.optionType === FrontendDayTypeOptionsEnum.NO_WORKING_DAY
                                      ? MonthListItemStyles.noWorkingDayBackground
                                      : locationDay.optionType === FrontendDayTypeOptionsEnum.HOLIDAY
                                        ? MonthListItemStyles.holidayDayBackground
                                        : "",
                                  )}
                                >
                                  <div
                                    className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.flex1, GlobalStyles.gap15)}
                                  >
                                    <div
                                      className={classNames(
                                        GlobalStyles.flex,
                                        GlobalStyles.flex1,
                                        GlobalStyles.centerHorizontal,
                                        MonthListItemStyles.minWidthText,
                                      )}
                                    >
                                      {description}
                                    </div>
                                    {locationDay.optionType === FrontendDayTypeOptionsEnum.WORKING_DAY && (
                                      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap)}>
                                        <div
                                          className={classNames(
                                            MonthListItemStyles.statisticsContainer,
                                            GlobalStyles.flex,
                                            GlobalStyles.centerHorizontal,
                                            GlobalStyles.gap05,
                                          )}
                                        >
                                          <div>
                                            <img
                                              className={classNames(MonthListItemStyles.settingsIconImg)}
                                              src={counterCalendarIcon}
                                              alt="counter"
                                            />
                                          </div>
                                          <div
                                            className={classNames(MonthListItemStyles.statisticsNumber)}
                                          >{`${cumulativeCounter} of ${totalDays}`}</div>
                                        </div>
                                        <div
                                          className={classNames(
                                            MonthListItemStyles.statisticsContainer,
                                            GlobalStyles.flex,
                                            GlobalStyles.centerHorizontal,
                                            GlobalStyles.gap05,
                                          )}
                                        >
                                          <div>
                                            <img className={classNames(MonthListItemStyles.settingsIconImg)} src={percentageIcon} alt="percentage" />
                                          </div>
                                          <div className={classNames(MonthListItemStyles.statisticsNumber)}>
                                            {(cumulativeCounter / totalDays).toFixed(2)}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {/*<div className={classNames(MonthListItemStyles.statisticsContainer)}>*/}
                                    {/*  <img className={classNames(MonthListItemStyles.settingsIconImg)} src={settingsIcon} alt="settings" />*/}
                                    {/*</div>*/}
                                  </div>
                                </div>
                              </Tooltip>
                            )
                          );
                        })}
                      </div>
                    </div>
                    {index !== projectCalendarDaysData.length - 1 && <div className={classNames(MonthListItemStyles.dottedLine)}></div>}
                  </div>
                );
              }),
            )}
          <InfiniteScrollInViewElement
            key={"InfiniteScrollInViewElement"}
            reference={ref}
            infiniteQueryResult={projectCalendarDaysQuery}
            loaderComponent={dayItemSkeletonLoader}
          />
        </div>
      ) : (
        calendarDaysLoader
      )}
    </div>
  );
};

export default MonthListItem;
