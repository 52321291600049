import TimesheetFormMMRInfoStyles from "./TimesheetFormMMRInfoStyles.module.css";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import { FC, useEffect, useMemo } from "react";
import { useEpcmApiProjectEmployee } from "../../../../../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { QueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { FrontendProjectEmployeeMmrNormalType } from "../../../../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { useParams } from "react-router-dom";
import { useEpcmApiProjectsUtils } from "../../../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { Skeleton } from "@mui/material";

interface TimesheetFormMMRInfoProps {
  MMRId: number;
  projectEmployeeId: number;
}

const TimesheetFormMMRInfo: FC<TimesheetFormMMRInfoProps> = ({ MMRId, projectEmployeeId }) => {
  const { projectId } = useParams();
  const { getProjectEmployeeMmr } = useEpcmApiProjectEmployee();
  const { getProjectPosition } = useEpcmApiProjectsUtils();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const mmrItemQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployeeMmrItem, projectId, projectEmployeeId, MMRId, DetailLevel.NORMAL],
    queryFn: () => getProjectEmployeeMmr(parseInt(projectId!), projectEmployeeId, MMRId, DetailLevel.NORMAL),

    enabled: isAuthorized,
    select: (data) => data as FrontendProjectEmployeeMmrNormalType,
  });

  const mmrItemData = useMemo(() => {
    return mmrItemQuery.data ?? undefined;
  }, [mmrItemQuery]);

  const projectPositionQuery = useQuery({
    queryKey: [QueryNames.ProjectLocations, projectId, mmrItemData?.manpowerPositionDetailed.positionId],
    queryFn: () => getProjectPosition(parseInt(projectId!), mmrItemData!.manpowerPositionDetailed.positionId),

    enabled: isAuthorized && !!mmrItemData,
  });

  const projectPositionData = useMemo(() => {
    return projectPositionQuery.data ?? undefined;
  }, [projectPositionQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectEmployeeMmrItem, projectId, projectEmployeeId, MMRId, DetailLevel.NORMAL] })
        .then(() => console.log(`In timesheet employees, employee MMR with id ${MMRId}, ${QueryNames.ProjectEmployeeMmrItem} query canceled`));
    };
  }, [queryClient, projectId, projectEmployeeId, MMRId]);

  useEffect(() => {
    return () => {
      mmrItemData &&
        queryClient
          .cancelQueries({ queryKey: [QueryNames.ProjectPositions, projectId, mmrItemData.manpowerPositionDetailed.positionId] })
          .then(() => console.log(`In timesheet employees, employee MMR with id ${mmrItemData.id}, ${QueryNames.ProjectPositions} query canceled`));
    };
  }, [queryClient, projectId, mmrItemData]);

  return (
    <div className={classNames(TimesheetFormMMRInfoStyles.container)}>
      {mmrItemQuery.isLoading || projectPositionQuery.isLoading ? (
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <Skeleton variant="rounded" height={15} width={150} />
          <Skeleton variant="rounded" height={15} width={120} />
        </div>
      ) : (
        mmrItemData &&
        projectPositionData && (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <div className={classNames(TimesheetFormMMRInfoStyles.positionDescription)}>{projectPositionData.description}</div>
          </div>
        )
      )}
    </div>
  );
};
export default TimesheetFormMMRInfo;
