import { AxiosRequestConfig } from "axios";

import { UserProjectRoleLimited } from "epcm-common/dist/Types/UserTypes";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getUserProjectRoles = async (projectId: number): Promise<UserProjectRoleLimited[]> => {
  const config = {
    url: `${API}/${projectId}/roles`,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  return response.data satisfies UserProjectRoleLimited[];
};
