import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import ManpowerPlanListItemStyles from "./ManpowerPlanListItem.module.css";
import classNames from "classnames";
import { FC, useMemo, useState } from "react";
import { ManpowerPlanStatusEnum, ManpowerPlanType } from "../../../../../../types/projects/ManpowerPlanTypes.ts";
import documentIconApproved from "../../../../../../assets/images/document-icon-green-lines.svg";
import documentIconPending from "../../../../../../assets/images/document-icon-yellow-lines.svg";
import documentIconRejected from "../../../../../../assets/images/document-icon-red-lines.svg";
import documentIconHistory from "../../../../../../assets/images/document-icon-gray-lines.svg";
import arrowDownBlue from "../../../../../../assets/images/arrow-down-blue-fill.svg";
import downloadIcon from "../../../../../../assets/images/download-icon-dark-blue-bg.svg";
import downloadIconHovered from "../../../../../../assets/images/download-icon-green-bg.svg";
import addFilesIcon from "../../../../../../assets/images/add-files-icon-dark-blue-bg.svg";
import addFilesIconHovered from "../../../../../../assets/images/add-files-icon-blue-bg.svg";
import deleteIcon from "../../../../../../assets/images/trashcan-icon-dark-blue.svg";
import deleteIconHovered from "../../../../../../assets/images/trashcan-icon-red.svg";
import archiveIcon from "../../../../../../assets/images/archive-dark-blue.svg";
import archiveIconHovered from "../../../../../../assets/images/archive-yellow.svg";
import { capitalizeFirstLetter } from "../../../../../../utils/StringManipulation.ts";
import { formatDate } from "../../../../../../utils/DateManipulation.ts";
import { Fade, Tooltip } from "@mui/material";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../use-projects-popups.ts";
import { ChangeManpowerPlanStatusPopup } from "../../../../popups/changemanpowerplanstatuspopup/ChangeManpowerPlanStatusPopup.tsx";
import { ManpowerPlanReferenceFilesPopup } from "../../../../popups/manpowerplanreferencefilespopup/ManpowerPlanReferenceFilesPopup.tsx";

interface ManpowerPlanListItemProps {
  manpowerPlanItem: ManpowerPlanType;
  isHistory: boolean;
  onChangeStatusFn: (manpowerPlanItem: ManpowerPlanType) => void;
}

export const ManpowerPlanListItem: FC<ManpowerPlanListItemProps> = ({ manpowerPlanItem, isHistory, onChangeStatusFn }) => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();

  const [isDownloadButtonHovered, setIsDownloadButtonHovered] = useState<boolean>(false);
  const [isAddFilesButtonHovered, setIsAddFilesButtonHovered] = useState<boolean>(false);
  const [isDeleteButtonHovered, setIsDeleteButtonHovered] = useState<boolean>(false);
  const [isArchiveButtonHovered, setIsArchiveButtonHovered] = useState<boolean>(false);
  const [openReferenceFilesPopupWithDnD, setOpenReferenceFilesPopupWithDnD] = useState<boolean | null>(null);

  const isPending = useMemo(() => {
    return manpowerPlanItem.approvalStatus === ManpowerPlanStatusEnum.pending;
  }, [manpowerPlanItem]);
  const isRejected = useMemo(() => {
    return manpowerPlanItem.approvalStatus === ManpowerPlanStatusEnum.rejected;
  }, [manpowerPlanItem]);

  return (
    <div
      className={classNames(
        ManpowerPlanListItemStyles.manpowerPlanItemContainer,
        isHistory
          ? ManpowerPlanListItemStyles.manpowerPlanItemContainerHistory
          : isPending
            ? ManpowerPlanListItemStyles.manpowerPlanItemContainerPending
            : isRejected
              ? ManpowerPlanListItemStyles.manpowerPlanItemContainerRejected
              : undefined,
        GlobalStyles.flex,
        GlobalStyles.gap025,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img
              className={classNames(ManpowerPlanListItemStyles.manpowerPlanItemDocIconImg)}
              src={isHistory ? documentIconHistory : isPending ? documentIconPending : isRejected ? documentIconRejected : documentIconApproved}
              alt="doc"
            />
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
          <div className={classNames()}>{manpowerPlanItem.code}</div>
          <div className={classNames(ManpowerPlanListItemStyles.detailsText)}>{manpowerPlanItem.versionDisplay}</div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
          <div className={classNames()}>{"Client approval"}</div>
          <div
            className={classNames(
              ManpowerPlanListItemStyles.detailsText,
              manpowerPlanItem.approvalStatus === ManpowerPlanStatusEnum.approved
                ? ManpowerPlanListItemStyles.approvalStatusApproved
                : manpowerPlanItem.approvalStatus === ManpowerPlanStatusEnum.pending
                  ? ManpowerPlanListItemStyles.approvalStatusPending
                  : manpowerPlanItem.approvalStatus === ManpowerPlanStatusEnum.rejected
                    ? ManpowerPlanListItemStyles.approvalStatusRejected
                    : undefined,
            )}
          >
            {capitalizeFirstLetter(manpowerPlanItem.approvalStatus)}
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        {!isHistory && manpowerPlanItem.approvalStatus === ManpowerPlanStatusEnum.pending && (
          <div
            className={classNames(GlobalStyles.elementWithCursor, GlobalStyles.flex, GlobalStyles.gap05)}
            onClick={() => onOpenPopup(ProjectsPagePopups.changeManpowerPlanStatus, popupHandler)}
          >
            <div className={classNames(ManpowerPlanListItemStyles.linkBlueText, GlobalStyles.centerVertical)}>{"Change"}</div>
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(ManpowerPlanListItemStyles.arrowDownBlueIconImg)} src={arrowDownBlue} alt="arrowDown" />
            </div>
          </div>
        )}
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
        <div className={classNames()}>{"Regarding month"}</div>
        <div className={classNames(ManpowerPlanListItemStyles.detailsText)}>{manpowerPlanItem.month}</div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
        <div className={classNames()}>{"Uploaded on"}</div>
        <div className={classNames(ManpowerPlanListItemStyles.detailsText)}>{formatDate(manpowerPlanItem.dateUploaded)}</div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
        <div className={classNames()}>{"Uploaded by"}</div>
        <div className={classNames(ManpowerPlanListItemStyles.detailsText)}>{manpowerPlanItem.uploadedBy.name}</div>
      </div>
      <div
        className={classNames(
          GlobalStyles.elementWithCursor,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.centerVertical,
        )}
        onClick={() => {
          setOpenReferenceFilesPopupWithDnD(false);
          onOpenPopup(ProjectsPagePopups.manpowerPlanAddReferenceFiles, popupHandler);
        }}
      >
        <div className={classNames()}>{"Reference files"}</div>
        {manpowerPlanItem.referenceFiles.length > 0 ? (
          <div className={classNames(ManpowerPlanListItemStyles.linkBlueText, GlobalStyles.flex, GlobalStyles.gap05)}>
            <div className={classNames(GlobalStyles.centerVertical)}>{manpowerPlanItem.referenceFiles[0].fileName}</div>
            {manpowerPlanItem.referenceFiles.length > 1 && (
              <div className={classNames(ManpowerPlanListItemStyles.blueBorderedSquare, GlobalStyles.centerVertical)}>
                {`+${manpowerPlanItem.referenceFiles.length - 1}`}
              </div>
            )}
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
        ) : (
          <div className={classNames()}>{"--"}</div>
        )}
      </div>

      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap3)}>
        <div className={classNames(GlobalStyles.flex1)} />
        <div
          className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
          onMouseEnter={() => setIsDownloadButtonHovered(true)}
          onMouseLeave={() => setIsDownloadButtonHovered(false)}
        >
          <Tooltip TransitionComponent={Fade} title={"Download Version"} placement="top" arrow>
            <img
              className={classNames(ManpowerPlanListItemStyles.utilIconImg)}
              src={isDownloadButtonHovered ? downloadIconHovered : downloadIcon}
              alt="downloadTemplate"
            />
          </Tooltip>
        </div>
        <div
          className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
          onMouseEnter={() => setIsAddFilesButtonHovered(true)}
          onMouseLeave={() => setIsAddFilesButtonHovered(false)}
          onClick={() => {
            setOpenReferenceFilesPopupWithDnD(true);
            onOpenPopup(ProjectsPagePopups.manpowerPlanAddReferenceFiles, popupHandler);
          }}
        >
          <Tooltip TransitionComponent={Fade} title={"Add Reference Files"} placement="top" arrow>
            <img
              className={classNames(ManpowerPlanListItemStyles.utilIconImg)}
              src={isAddFilesButtonHovered ? addFilesIconHovered : addFilesIcon}
              alt="add"
            />
          </Tooltip>
        </div>
        {manpowerPlanItem.approvalStatus !== ManpowerPlanStatusEnum.rejected ? (
          <div
            className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
            onMouseEnter={() => setIsDeleteButtonHovered(true)}
            onMouseLeave={() => setIsDeleteButtonHovered(false)}
          >
            <Tooltip TransitionComponent={Fade} title={"Delete Version"} placement="top" arrow>
              <img
                className={classNames(ManpowerPlanListItemStyles.utilIconImg)}
                src={isDeleteButtonHovered ? deleteIconHovered : deleteIcon}
                alt="delete"
              />
            </Tooltip>
          </div>
        ) : (
          <div
            className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
            onMouseEnter={() => setIsArchiveButtonHovered(true)}
            onMouseLeave={() => setIsArchiveButtonHovered(false)}
          >
            <Tooltip TransitionComponent={Fade} title={"Archive Version"} placement="top" arrow>
              <img
                className={classNames(ManpowerPlanListItemStyles.utilIconImg)}
                src={isArchiveButtonHovered ? archiveIconHovered : archiveIcon}
                alt="archive"
              />
            </Tooltip>
          </div>
        )}
        <div className={classNames(GlobalStyles.flex1)} />
      </div>

      {popupHandler.get(ProjectsPagePopups.changeManpowerPlanStatus)!.isOpen && (
        <ChangeManpowerPlanStatusPopup
          isOpen={popupHandler.get(ProjectsPagePopups.changeManpowerPlanStatus)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.changeManpowerPlanStatus, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.changeManpowerPlanStatus)}
          secondaryHeaderText={`${manpowerPlanItem.code}-${manpowerPlanItem.versionDisplay}`}
          currentStatus={manpowerPlanItem.approvalStatus}
          manpowerPlanItem={manpowerPlanItem}
          onChangeStatusFn={onChangeStatusFn}
          onOpenReferenceFilesPopup={() => {
            setOpenReferenceFilesPopupWithDnD(true);
            onOpenPopup(ProjectsPagePopups.manpowerPlanAddReferenceFiles, popupHandler);
          }}
        />
      )}
      {openReferenceFilesPopupWithDnD !== null && popupHandler.get(ProjectsPagePopups.manpowerPlanAddReferenceFiles)!.isOpen && (
        <ManpowerPlanReferenceFilesPopup
          isOpen={popupHandler.get(ProjectsPagePopups.manpowerPlanAddReferenceFiles)!.isOpen}
          closeFn={() => {
            setOpenReferenceFilesPopupWithDnD(null);
            onClosePopup(ProjectsPagePopups.manpowerPlanAddReferenceFiles, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.manpowerPlanAddReferenceFiles)}
          secondaryHeaderText={`${manpowerPlanItem.code}-${manpowerPlanItem.versionDisplay}`}
          showDnD={openReferenceFilesPopupWithDnD}
          manpowerPlanItem={manpowerPlanItem}
          onChangeStatusFn={onChangeStatusFn}
        />
      )}
    </div>
  );
};
