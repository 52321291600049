import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../../config.ts";
import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { CtrLimited } from "epcm-common/dist/Types/CtrTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getAllProjectCtrs = async (
  id: number,
  pageOffset?: number,
  searchFilter?: string,
  pageSize: number = PAGINATION_PAGE_SIZE,
): Promise<QueryModelWithPagination<CtrLimited>> => {
  const params = new URLSearchParams();
  pageOffset && params.append("pageOffset", `${pageOffset}`);
  pageSize && params.append("pageSize", `${pageSize}`);
  searchFilter && params.append("search", `${searchFilter}`);
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/ctrs`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);
  const responseData = response.data as QueryModelWithPagination<CtrLimited>;
  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;
  return { data: responseData.data, nextPage: nextPage, totalCount: responseData.totalCount };
};
