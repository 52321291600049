import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../../config.ts";
import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { AxiosRequestConfig } from "axios";
import { ManpowerPositionLimited } from "epcm-common/dist/Types/ManpowerPositionTypes";

const URL = `${EPCM_API_URLS.projects}`;

export const getAllProjectManpowerPositions = async (
  projectId: number,
  pageOffset?: number,
  searchFilter?: string,
  pageSize: number = PAGINATION_PAGE_SIZE,
): Promise<QueryModelWithPagination<ManpowerPositionLimited>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);

  const config: AxiosRequestConfig = {
    url: `${URL}/${projectId}/manpower-position`,
    method: "GET",
    params,
  };
  const response = await epcmAxiosInstance(config);
  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);
  const responseData = response.data as QueryModelWithPagination<ManpowerPositionLimited>;
  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;

  return {
    data: responseData.data,
    nextPage: nextPage,
    totalCount: responseData.totalCount,
  };
};
