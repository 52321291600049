import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { FrontendProjectConfigurationsEntityEnum } from "./useEpcmApiProjectUtilsMutations.ts";

const API = `${EPCM_API_URLS.projects}`;

export const createProjectConfigurationEntity = async <T>(
  projectId: number,
  entityType: FrontendProjectConfigurationsEntityEnum,
  newEntityData: T,
  subEntityId?: number,
) => {
  const subEntityPath = subEntityId ? `/${subEntityId}` : "";
  if (entityType === FrontendProjectConfigurationsEntityEnum.Subposition) {
    const config: AxiosRequestConfig = {
      url: `${API}/${projectId}/position/${subEntityId}/sub-positions`,
      method: "POST",
      data: newEntityData,
    };
    return epcmAxiosInstance(config);
  } else {
    const config: AxiosRequestConfig = {
      url: `${API}/${projectId}/${entityType}${subEntityPath}`,
      method: "POST",
      data: newEntityData,
    };

    return epcmAxiosInstance(config);
  }
};
