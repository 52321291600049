import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { GlobalEmployeeInfoBasic } from "./globalemployeeinfobasic/GlobalEmployeeInfoBasic.tsx";

import { FC } from "react";

import GlobalEmployeeInfoSectionStyles from "./GlobalEmployeeInfoSectionContainer.module.css";

import GlobalEmployeeInfoContainer from "./globalemployeeinfocontainer/GlobalEmployeeInfoContainer.tsx";

interface GlobalEmployeeInfoSectionContainerProps {
  globalEmployeeId: number;
  projectId: number;
}

const GlobalEmployeeInfoSectionContainer: FC<GlobalEmployeeInfoSectionContainerProps> = ({ globalEmployeeId, projectId }) => {
  return (
    <div className={classNames(GlobalEmployeeInfoSectionStyles.employeeInfoContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(GlobalEmployeeInfoSectionStyles.employeeBasicInfoContainer)}>
        <GlobalEmployeeInfoBasic projectId={projectId} globalEmployeeId={globalEmployeeId} />
      </div>
      <GlobalEmployeeInfoContainer globalEmployeeId={globalEmployeeId} projectId={projectId} />
    </div>
  );
};
export default GlobalEmployeeInfoSectionContainer;
