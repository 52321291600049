import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../config.ts";
import { ConfigurationScopeEnum } from "../../types/projects/ConfigurationTypes.ts";
import { QueryModelWithCountAndData } from "../../types/apicallstypes/queryCommons.ts";
import { RoleNormal } from "epcm-common/dist/Types/RoleTypes";

const API = `${EPCM_API_URLS.configurations}`;

export const getAllConfigurationRoles = async (scope: ConfigurationScopeEnum): Promise<QueryModelWithCountAndData<RoleNormal>> => {
  const config = {
    url: `${API}/roles`,
    method: "GET",
    params: {
      scope,
    },
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  const responseData = response.data as QueryModelWithCountAndData<RoleNormal>;
  return {
    count: responseData.count,
    data: responseData.data,
  };
};
