import { EpcmMutationOptionsType } from "../../../../config.ts";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { updateTimesheet } from "./updateTimesheet.ts";
import { FrontendApiRequestBodyTimesheetRecordSave } from "../../../../../types/apicallstypes/CtrTypes.ts";
import { convertApiRequestBodyTimesheetRecordSaveDataToBackend } from "../../../../../types/apicallstypes/apicallstypesconverters/CtrApiConverter.ts";
import {
  ApiRequestBodyTimesheetGroupAddProjectEmployees,
  ApiRequestBodyTimesheetGroupAddSectionHead,
} from "epcm-common/dist/Types/TimesheetGroupTypes.ts";
import { ApiRequestBodyTimesheetGroupCreate, ApiRequestBodyTimesheetGroupUpdate } from "epcm-common/dist/Types/TimesheetGroupTypes";
import { createTimesheetGroupEmployees } from "./createTimesheetGroupEmployees.ts";
import { createTimesheetGroupSectionHead } from "./createTimesheetGroupSectionHead.ts";
import { createTimesheetGroup } from "./createTimesheetGroup.ts";
import { deleteTimesheetGroupProjectEmployee } from "./deleteTimesheetGroupEmployee.ts";
import { deleteProjectTimesheetGroup } from "./deleteTimesheetGroup.ts";
import { deleteProjectTimesheetGroupId } from "./deleteTimesheetGroupId.ts";
import { ApiRequestBodyTimesheetCreate, ApiRequestBodyTimesheetUpdate } from "epcm-common/dist/Types/TimesheetTypes";
import { createTimesheet } from "./createTimesheet.ts";
import { changeTimesheetStatus } from "./changeTimesheetStatus.ts";
import { updateTimesheetGroup } from "./updateTimesheetGroup.ts";

interface ApiDefinition {
  useUpdateTimesheetMutation: (
    projectId: number,
    timesheetGroupId: number,
    timesheetId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, FrontendApiRequestBodyTimesheetRecordSave, unknown>;

  useUpdateTimesheetGroupNameMutation: (
    projectId: number,
    timesheetGroupId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyTimesheetGroupUpdate, unknown>;
  useUpdateTimesheetStatusMutation: (
    projectId: number,
    timesheetGroupId: number,
    timesheetId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyTimesheetUpdate, unknown>;

  useCreateTimesheetGroupEmployeesMutation: (
    projectId: number,
    timesheetGroupId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<unknown, unknown, ApiRequestBodyTimesheetGroupAddProjectEmployees, unknown>;

  useCreateTimesheetGroupSectionHeadMutation: (
    projectId: number,
    timesheetGroupId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<unknown, unknown, ApiRequestBodyTimesheetGroupAddSectionHead, unknown>;

  useCreateTimesheetGroupMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<unknown, unknown, ApiRequestBodyTimesheetGroupCreate, unknown>;

  useDeleteTimesheetGroupEmployeeMutation: (
    projectId: number,
    timesheetGroupId: number,
    projectEmployeeId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<unknown, unknown, void, unknown>;

  useDeleteTimesheetGroupMutation: (
    projectId: number,
    timesheetGroupId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<unknown, unknown, void, unknown>;

  useDeleteTimesheetGroupIdMutation: (
    projectId: number,
    timesheetGroupId: number,
    timesheetId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<unknown, unknown, void, unknown>;

  useCreateProjectTimesheetMutation: (
    projectId: number,
    timesheetGroupId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<unknown, unknown, ApiRequestBodyTimesheetCreate, unknown>;
}

export const useEpcmApiProjectTimesheetsGroupsMutations = (): ApiDefinition => {
  const queryClient = useQueryClient();

  const useUpdateTimesheetMutation = (projectId: number, timesheetGroupId: number, timesheetId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, FrontendApiRequestBodyTimesheetRecordSave>({
      mutationFn: async (timesheetData: FrontendApiRequestBodyTimesheetRecordSave) => {
        return updateTimesheet(projectId, timesheetGroupId, timesheetId, convertApiRequestBodyTimesheetRecordSaveDataToBackend(timesheetData));
      },
      onSuccess: (data) => {
        console.log("on post update timesheet mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post update timesheet mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeeMmr] });
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useUpdateTimesheetGroupNameMutation = (projectId: number, timesheetGroupId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyTimesheetGroupUpdate, unknown>({
      mutationFn: async (newGroupData: ApiRequestBodyTimesheetGroupUpdate) => {
        return updateTimesheetGroup(projectId, timesheetGroupId, newGroupData);
      },
      onSuccess: (data) => {
        console.log("on post update timesheet group name mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post update timesheet group name mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useUpdateTimesheetStatusMutation = (
    projectId: number,
    timesheetGroupId: number,
    timesheetId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyTimesheetUpdate, unknown>({
      mutationFn: (status: ApiRequestBodyTimesheetUpdate) => {
        return changeTimesheetStatus(projectId, timesheetGroupId, timesheetId, status);
      },

      onSuccess: async (data) => {
        console.log("on post update timesheet status mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post update timesheet status mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeeMmr] });
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useCreateTimesheetGroupEmployeesMutation = (projectId: number, timesheetGroupId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyTimesheetGroupAddProjectEmployees>({
      mutationFn: async (newTimesheetGroupEmployees: ApiRequestBodyTimesheetGroupAddProjectEmployees) => {
        return createTimesheetGroupEmployees(projectId, timesheetGroupId, newTimesheetGroupEmployees);
      },
      onSuccess: (data) => {
        console.log("on post create timesheet group employees mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create timesheet group employees mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useCreateTimesheetGroupSectionHeadMutation = (projectId: number, timesheetGroupId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyTimesheetGroupAddSectionHead>({
      mutationFn: async (newTimesheetGroupSectionHead: ApiRequestBodyTimesheetGroupAddSectionHead) => {
        return createTimesheetGroupSectionHead(projectId, timesheetGroupId, newTimesheetGroupSectionHead);
      },
      onSuccess: (data) => {
        console.log("on post create timesheet group section head mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create timesheet group section head mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useCreateTimesheetGroupMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyTimesheetGroupCreate>({
      mutationFn: async (newTimesheetGroupName: ApiRequestBodyTimesheetGroupCreate) => {
        return createTimesheetGroup(projectId, newTimesheetGroupName);
      },
      onSuccess: (data) => {
        console.log("on post create timesheet group mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create timesheet group mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId] });
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useDeleteTimesheetGroupEmployeeMutation = (
    projectId: number,
    timesheetGroupId: number,
    projectEmployeeId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void>({
      mutationFn: async () => {
        return deleteTimesheetGroupProjectEmployee(projectId, timesheetGroupId, projectEmployeeId);
      },
      onSuccess: (data) => {
        console.log("on delete project employees mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on delete project employees mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };
  const useDeleteTimesheetGroupMutation = (projectId: number, timesheetGroupId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void, unknown>({
      mutationFn: () => {
        return deleteProjectTimesheetGroup(projectId, timesheetGroupId);
      },

      onSuccess: async (data) => {
        console.log("on delete project timesheet group mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on delete project timesheet group mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };
  const useDeleteTimesheetGroupIdMutation = (
    projectId: number,
    timesheetGroupId: number,
    timesheetId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void, unknown>({
      mutationFn: async () => {
        return deleteProjectTimesheetGroupId(projectId, timesheetGroupId, timesheetId);
      },

      onSuccess: async (data) => {
        console.log("on delete project timesheet id group mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on delete project timesheet id group mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };
  const useCreateProjectTimesheetMutation = (projectId: number, timesheetGroupId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyTimesheetCreate, unknown>({
      mutationFn: (newTimesheet: ApiRequestBodyTimesheetCreate) => {
        return createTimesheet(projectId, timesheetGroupId, newTimesheet);
      },

      onSuccess: async (data) => {
        console.log("on post create timesheet mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create timesheet mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  return {
    useUpdateTimesheetMutation: useUpdateTimesheetMutation,
    useUpdateTimesheetGroupNameMutation: useUpdateTimesheetGroupNameMutation,
    useCreateTimesheetGroupEmployeesMutation: useCreateTimesheetGroupEmployeesMutation,
    useCreateTimesheetGroupSectionHeadMutation: useCreateTimesheetGroupSectionHeadMutation,
    useDeleteTimesheetGroupEmployeeMutation: useDeleteTimesheetGroupEmployeeMutation,
    useCreateTimesheetGroupMutation: useCreateTimesheetGroupMutation,
    useDeleteTimesheetGroupMutation: useDeleteTimesheetGroupMutation,
    useDeleteTimesheetGroupIdMutation: useDeleteTimesheetGroupIdMutation,
    useCreateProjectTimesheetMutation: useCreateProjectTimesheetMutation,
    useUpdateTimesheetStatusMutation: useUpdateTimesheetStatusMutation,
  };
};
