import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import EmployeeInfoDetailedStyles from "./EmployeeInfoDetailed.module.css";
import { FC, useMemo } from "react";
import { calculateAge } from "../../../../../../utils/DateManipulation.ts";
import { capitalizeFirstLetter } from "../../../../../../utils/StringManipulation.ts";

import { FrontendProjectPositionType } from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";

interface EmployeeInfoDetailedProps {
  employeePosition: FrontendProjectPositionType | null;
  employeeCategory: string;
  employeeNationality: string;
  employeeDateOfBirth: string;
}

export const EmployeeInfoDetailed: FC<EmployeeInfoDetailedProps> = ({
  employeePosition,
  employeeCategory,
  employeeNationality,
  employeeDateOfBirth,
}) => {
  const detailedInfo = useMemo(() => {
    return [
      {
        label: "Position",
        value: employeePosition?.description ?? "-",
      },
      {
        label: "Employee Type",
        value: employeeCategory,
      },
      {
        label: "Nationality",
        value: employeeNationality
          .split(" ")
          .map((wordItem) => capitalizeFirstLetter(wordItem))
          .join(" "),
      },
      {
        label: "Date of Birth",
        value: employeeDateOfBirth ? `${employeeDateOfBirth} [${calculateAge(new Date(employeeDateOfBirth))} Y/O]` : `-`,
      },
    ];
  }, [employeePosition, employeeCategory, employeeNationality, employeeDateOfBirth]);

  return (
    <div className={classNames(EmployeeInfoDetailedStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15)}>
      {detailedInfo.map((info) => (
        <div key={info.label} className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(EmployeeInfoDetailedStyles.secondaryText)}>{info.label}</div>
          <div className={classNames(EmployeeInfoDetailedStyles.highlightedText)}>{info.value}</div>
        </div>
      ))}
    </div>
  );
};
