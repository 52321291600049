import { EpcmMutationOptionsType } from "../../config.ts";
import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../types/apicallstypes/queryCommons.ts";
import { ApiRequestBodyEmployeeAdd, ApiRequestBodyEmployeeUpdate } from "epcm-common/dist/Types/EmployeeTypes.ts";
import { createEmployee } from "./createEmployee.ts";
import { updateEmployee } from "./updateEmployee.ts";
import { deleteEmployees } from "./deleteEmployee.ts";

interface ApiDefinition {
  useCreateEmployeeMutation: (
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyEmployeeAdd>;
  useUpdateEmployeeMutation: (
    employeeId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyEmployeeUpdate>;
  useDeleteEmployeeMutation: (
    employeeId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, void>;
}

export const useEpcmApiEmployeesMutations = (): ApiDefinition => {
  const queryClient = useQueryClient();

  const useCreateEmployeeMutation = (mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyEmployeeAdd>({
      mutationFn: async (newEmployee: ApiRequestBodyEmployeeAdd) => {
        return createEmployee(newEmployee);
      },
      onSuccess: (data) => {
        console.log("on post create employee mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create employee mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        void queryClient.invalidateQueries({ queryKey: [QueryNames.Employees] });
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useUpdateEmployeeMutation = (employeeId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyEmployeeUpdate>({
      mutationFn: async (employeeData: ApiRequestBodyEmployeeUpdate) => {
        return updateEmployee(employeeId, employeeData);
      },
      onSuccess: (data) => {
        console.log("on post update employee mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post update employee mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        void queryClient.invalidateQueries({ queryKey: [QueryNames.Employees] });
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useDeleteEmployeeMutation = (employeeId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void>({
      mutationFn: async () => {
        return deleteEmployees(employeeId);
      },
      onSuccess: (data) => {
        console.log("on post delete employee mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post delete employee mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        void queryClient.invalidateQueries({
          queryKey: [QueryNames.Employees],
        });
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  return {
    useCreateEmployeeMutation,
    useUpdateEmployeeMutation,
    useDeleteEmployeeMutation,
  };
};
