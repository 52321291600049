import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

const API = `${EPCM_API_URLS.configurations}`;

export const createSampleData = async () => {
  const config: AxiosRequestConfig = {
    url: `${API}/setup`,
    method: "POST",
  } as AxiosRequestConfig;

  return epcmAxiosInstance(config);
};
