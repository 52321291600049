import { useState } from "react";

import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import SearchBarSubHeader from "../searchbarsubheader/SearchBarSubHeader.tsx";
import ConfigurationEntityTableManager from "../configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import ConfigurationTradesStyles from "./ConfigurationDisciplines.module.css";
import NewDisciplineIcon from "../../../../../assets/images/configuration/new-discipline-icon.svg";

import ConfigurationPositionStyles from "../configurationpositions/ConfigurationPositions.module.css";
import ConfigurationDisciplinesPopup from "../popups/configurationdisciplinespopup/ConfigurationDisciplinesPopup.tsx";
import { ProjectsPagePopups, useProjectsPopups } from "../../../use-projects-popups.ts";
import { useProjectContext } from "../../../ProjectsUtils.ts";
import { ConfigurationScreenType, configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { ConfigurationTargetType } from "../../../../../types/settings/GlobalConfigurationTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";

const ConfigurationDisciplines = () => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { currentProject } = useProjectContext();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const canListConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canCreateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigCreate);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3, GlobalStyles.overflowHiddenFullHeight)}>
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.overflowHiddenFullHeight,
          ConfigurationPositionStyles.contentContainer,
        )}
      >
        {canListConfiguration && <SearchBarSubHeader onSearchQueryChange={setSearchQuery} subheaderTitle={"Position Disciplines"} />}
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationPositionStyles.mainContent)}>
            <ConfigurationEntityTableManager
              searchQuery={searchQuery}
              configurationScreenType={ConfigurationScreenType.disciplines}
              target={ConfigurationTargetType.project}
            />
          </div>

          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, ConfigurationTradesStyles.actionBtnContainer)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
            {canCreateConfiguration && (
              <div
                onClick={() => onOpenPopup(ProjectsPagePopups.addProjectDiscipline, popupHandler)}
                className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
              >
                <div>New Position Discipline</div>
                <div className={classNames(ConfigurationTradesStyles.actionBtnImage)}>
                  <img src={NewDisciplineIcon} alt="New Position Discipline" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {popupHandler.get(ProjectsPagePopups.addProjectDiscipline)!.isOpen && (
        <ConfigurationDisciplinesPopup
          mode={configurationsPopupType.create}
          closeFn={() => onClosePopup(ProjectsPagePopups.addProjectDiscipline, popupHandler)}
          projectId={currentProject.id}
          isOpen={popupHandler.get(ProjectsPagePopups.addProjectDiscipline)!.isOpen}
          secondaryHeaderText={currentProject.name}
          headerText={popupHeaders.get(ProjectsPagePopups.addProjectDiscipline)}
        />
      )}
    </div>
  );
};
export default ConfigurationDisciplines;
