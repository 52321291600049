import GlobalStyles from "../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import { Outlet, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useEpcmApiProjects } from "../../../apicalls/projects/useEpcmApiProjects.ts";
import { useImpersonationStore } from "../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../types/apicallstypes/queryCommons.ts";
import { Skeleton } from "@mui/material";
import { FrontendProjectDetailedType } from "../../../types/apicallstypes/ProjectsApiTypes.ts";

export const Project = () => {
  const { projectId } = useParams();
  const { getProject } = useEpcmApiProjects();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const projectQuery = useQuery({
    queryKey: [QueryNames.Projects, projectId],
    queryFn: () => getProject(parseInt(projectId!)),
    enabled: isAuthorized,
    select: (data) => data as FrontendProjectDetailedType,
  });
  const projectData = useMemo(() => {
    return projectQuery.data;
  }, [projectQuery.data]);

  const projectPageSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap5, GlobalStyles.overflowHiddenFullHeight)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={180} width={170} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={180} width={150} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={180} width={150} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={170} width={550} />
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Projects, projectId] })
        .then(() => console.log(`In project with id ${projectId}, ${QueryNames.Projects} query canceled`));
    };
  }, [queryClient, projectId]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
      {projectData ? <Outlet /> : projectPageSkeletonLoader}
    </div>
  );
};
