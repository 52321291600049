import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useMemo, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import AddProjectEmployeesFiltersPopupStyles from "./AddProjectEmployeesFiltersPopup.module.css";
import arrowDownIcon from "../../../../assets/images/arrow-down-dark-blue-fill.svg";
import sortIcon from "../../../../assets/images/sort-icon.svg";
import sortIconActive from "../../../../assets/images/sort-icon-active.svg";
import sortIconActiveReverted from "../../../../assets/images/sort-icon-reverted-active.svg";
import checkIcon from "../../../../assets/images/tick-white.svg";
import {
  EMPLOYEE_LIST,
  EmployeeFilterEnum,
  employeeFilterNamesMap,
  EmployeeFilterType,
  SortEnum,
  SubFilterType,
} from "../../../../types/employees/EmployeesTypes.ts";
import { useAutoAnimate } from "@formkit/auto-animate/react";

interface AddProjectEmployeesFiltersPopupProps extends PopupType {
  onApplyFilters: (filterList: EmployeeFilterType[]) => void;
  initialFilterList?: EmployeeFilterType[];
}

interface SelectedForSortingType {
  filterType: EmployeeFilterEnum;
  sortingType: SortEnum;
}
export const AddProjectEmployeesFiltersPopup: FC<AddProjectEmployeesFiltersPopupProps> = ({
  isOpen,
  closeFn,
  headerText,
  secondaryHeaderText,
  initialFilterList,
  onApplyFilters,
}) => {
  const [parent] = useAutoAnimate();
  const INITIAL_EMPLOYEE_TYPE_SUB_FILTER_STATE = useMemo(() => {
    return [...new Set(EMPLOYEE_LIST.map((employeeItem) => employeeItem.category))]
      .map((employeeTypeItem) => {
        return { title: employeeTypeItem, isActive: false } satisfies SubFilterType;
      })
      .slice();
  }, []);
  const INITIAL_POSITION_SUB_FILTER_STATE = useMemo(() => {
    return [...new Set(EMPLOYEE_LIST.map((employeeItem) => employeeItem.position))]
      .map((positionItem) => {
        return { title: positionItem, isActive: false } satisfies SubFilterType;
      })
      .slice();
  }, []);
  const INITIAL_NATIONALITY_SUB_FILTER_STATE = useMemo(() => {
    return [...new Set(EMPLOYEE_LIST.map((employeeItem) => employeeItem.nationality))]
      .map((nationalityItem) => {
        return { title: nationalityItem, isActive: false } satisfies SubFilterType;
      })
      .slice();
  }, []);
  const INITIAL_LANGUAGES_SUB_FILTER_STATE = useMemo(() => {
    return [...new Set(EMPLOYEE_LIST.map((employeeItem) => employeeItem.languages).flatMap((languagesArrayItem) => languagesArrayItem))]
      .map((languageItem) => {
        return { title: languageItem, isActive: false } satisfies SubFilterType;
      })
      .slice();
  }, []);

  const [selectedForSorting, setSelectedForSorting] = useState<SelectedForSortingType | null>(null);
  const [showSubFilters, setShowSubFilters] = useState<EmployeeFilterEnum | null>(null);
  const [employeeTypeSubFilters, setEmployeeTypeSubFilters] = useState<SubFilterType[]>(
    initialFilterList
      ? initialFilterList.find((filter) => filter.id === EmployeeFilterEnum.employeeType)!.subFilters
      : INITIAL_EMPLOYEE_TYPE_SUB_FILTER_STATE,
  );
  const [positionSubFilters, setPositionTypeSubFilters] = useState<SubFilterType[]>(
    initialFilterList ? initialFilterList.find((filter) => filter.id === EmployeeFilterEnum.position)!.subFilters : INITIAL_POSITION_SUB_FILTER_STATE,
  );
  const [nationalitySubFilters, setNationalitySubFilters] = useState<SubFilterType[]>(
    initialFilterList
      ? initialFilterList.find((filter) => filter.id === EmployeeFilterEnum.nationality)!.subFilters
      : INITIAL_NATIONALITY_SUB_FILTER_STATE,
  );
  const [languagesSubFilters, setLanguagesSubFilters] = useState<SubFilterType[]>(
    initialFilterList
      ? initialFilterList.find((filter) => filter.id === EmployeeFilterEnum.languages)!.subFilters
      : INITIAL_LANGUAGES_SUB_FILTER_STATE,
  );

  const deselectAllSubFilters = useCallback(() => {
    setEmployeeTypeSubFilters(INITIAL_EMPLOYEE_TYPE_SUB_FILTER_STATE);
    setPositionTypeSubFilters(INITIAL_POSITION_SUB_FILTER_STATE);
    setNationalitySubFilters(INITIAL_NATIONALITY_SUB_FILTER_STATE);
    setLanguagesSubFilters(INITIAL_LANGUAGES_SUB_FILTER_STATE);
  }, [
    INITIAL_EMPLOYEE_TYPE_SUB_FILTER_STATE,
    INITIAL_POSITION_SUB_FILTER_STATE,
    INITIAL_NATIONALITY_SUB_FILTER_STATE,
    INITIAL_LANGUAGES_SUB_FILTER_STATE,
  ]);

  const clearAllFilters = useCallback(() => {
    deselectAllSubFilters();
    setSelectedForSorting(null);
  }, [deselectAllSubFilters]);

  const filterList: EmployeeFilterType[] = useMemo(() => {
    return [
      {
        id: EmployeeFilterEnum.employeeName,
        title: employeeFilterNamesMap.get(EmployeeFilterEnum.employeeName)!,
        sortStatus: selectedForSorting?.filterType === EmployeeFilterEnum.employeeName ? selectedForSorting.sortingType : SortEnum.inactive,
        subFilters: [],
      } satisfies EmployeeFilterType,
      {
        id: EmployeeFilterEnum.employeeType,
        title: employeeFilterNamesMap.get(EmployeeFilterEnum.employeeType)!,
        sortStatus: selectedForSorting?.filterType === EmployeeFilterEnum.employeeType ? selectedForSorting.sortingType : SortEnum.inactive,
        subFilters: employeeTypeSubFilters,
      } satisfies EmployeeFilterType,
      {
        id: EmployeeFilterEnum.position,
        title: employeeFilterNamesMap.get(EmployeeFilterEnum.position)!,
        sortStatus: selectedForSorting?.filterType === EmployeeFilterEnum.position ? selectedForSorting.sortingType : SortEnum.inactive,
        subFilters: positionSubFilters,
      } satisfies EmployeeFilterType,
      {
        id: EmployeeFilterEnum.nationality,
        title: employeeFilterNamesMap.get(EmployeeFilterEnum.nationality)!,
        sortStatus: selectedForSorting?.filterType === EmployeeFilterEnum.nationality ? selectedForSorting.sortingType : SortEnum.inactive,
        subFilters: nationalitySubFilters,
      } satisfies EmployeeFilterType,
      {
        id: EmployeeFilterEnum.languages,
        title: employeeFilterNamesMap.get(EmployeeFilterEnum.languages)!,
        sortStatus: selectedForSorting?.filterType === EmployeeFilterEnum.languages ? selectedForSorting.sortingType : SortEnum.inactive,
        subFilters: languagesSubFilters,
      } satisfies EmployeeFilterType,
    ];
  }, [selectedForSorting, employeeTypeSubFilters, positionSubFilters, nationalitySubFilters, languagesSubFilters]);

  const onSubFilterToggle = useCallback((filter: EmployeeFilterType, subFilter: SubFilterType) => {
    if (filter.id === EmployeeFilterEnum.employeeType) {
      setEmployeeTypeSubFilters((currentState) => {
        const subFilterListClone = currentState.slice();
        const subFilterIndex = subFilterListClone.map((subFilterItem) => subFilterItem.title).indexOf(subFilter.title);
        if (subFilterIndex !== -1) {
          subFilterListClone[subFilterIndex] = { ...subFilterListClone[subFilterIndex], isActive: !subFilterListClone[subFilterIndex].isActive };
        }
        return subFilterListClone;
      });
    } else if (filter.id === EmployeeFilterEnum.position) {
      setPositionTypeSubFilters((currentState) => {
        const subFilterListClone = currentState.slice();
        const subFilterIndex = subFilterListClone.map((subFilterItem) => subFilterItem.title).indexOf(subFilter.title);
        if (subFilterIndex !== -1) {
          subFilterListClone[subFilterIndex] = { ...subFilterListClone[subFilterIndex], isActive: !subFilterListClone[subFilterIndex].isActive };
        }
        return subFilterListClone;
      });
    } else if (filter.id === EmployeeFilterEnum.nationality) {
      setNationalitySubFilters((currentState) => {
        const subFilterListClone = currentState.slice();
        const subFilterIndex = subFilterListClone.map((subFilterItem) => subFilterItem.title).indexOf(subFilter.title);
        if (subFilterIndex !== -1) {
          subFilterListClone[subFilterIndex] = { ...subFilterListClone[subFilterIndex], isActive: !subFilterListClone[subFilterIndex].isActive };
        }
        return subFilterListClone;
      });
    } else if (filter.id === EmployeeFilterEnum.languages) {
      setLanguagesSubFilters((currentState) => {
        const subFilterListClone = currentState.slice();
        const subFilterIndex = subFilterListClone.map((subFilterItem) => subFilterItem.title).indexOf(subFilter.title);
        if (subFilterIndex !== -1) {
          subFilterListClone[subFilterIndex] = { ...subFilterListClone[subFilterIndex], isActive: !subFilterListClone[subFilterIndex].isActive };
        }
        return subFilterListClone;
      });
    }
  }, []);

  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Clear all filters ",
          buttonType: PopupButtonTypeEnum.main,
          action: clearAllFilters,
        },
        {
          text: "Apply Selected",
          buttonType: PopupButtonTypeEnum.main,
          // disabled: employeeIdsSelected.length === 0,
          // tooltipText: employeeIdsSelected.length === 0 ? "Please select some employees first" : undefined,
          action: () => {
            onApplyFilters(filterList);
            closeFn();
          },
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
    >
      <div
        className={classNames(
          AddProjectEmployeesFiltersPopupStyles.filterListContainer,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap075,
        )}
      >
        {filterList.map((filterItem) => (
          <div key={filterItem.id} ref={parent} className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
              <div
                className={classNames(GlobalStyles.flex, GlobalStyles.gap075, filterItem.subFilters.length > 0 && GlobalStyles.elementWithCursor)}
                onClick={() =>
                  filterItem.subFilters.length > 0 &&
                  setShowSubFilters((currentState) => {
                    if (currentState === filterItem.id) {
                      return null;
                    }
                    return filterItem.id;
                  })
                }
              >
                <div className={classNames()}>{filterItem.title}</div>
                {filterItem.subFilters.length > 0 && (
                  <div className={classNames(GlobalStyles.centerVertical)}>
                    <img
                      className={classNames(
                        AddProjectEmployeesFiltersPopupStyles.arrowIconImg,
                        showSubFilters === filterItem.id && AddProjectEmployeesFiltersPopupStyles.arrowIconImgReverted,
                      )}
                      src={arrowDownIcon}
                      alt="arrow"
                    />
                  </div>
                )}
              </div>
              <div className={classNames(GlobalStyles.flex1)} />
              <div
                className={classNames(GlobalStyles.centerVertical)}
                onClick={() =>
                  setSelectedForSorting((currentState) => {
                    if (!currentState || currentState.filterType !== filterItem.id) {
                      return { filterType: filterItem.id, sortingType: SortEnum.active };
                    }
                    if (currentState.sortingType === SortEnum.active) {
                      return { ...currentState, sortingType: SortEnum.reverted };
                    }
                    return null;
                  })
                }
              >
                <img
                  className={classNames(AddProjectEmployeesFiltersPopupStyles.sortIconImg)}
                  src={
                    filterItem.sortStatus === SortEnum.inactive
                      ? sortIcon
                      : filterItem.sortStatus === SortEnum.active
                        ? sortIconActive
                        : sortIconActiveReverted
                  }
                  alt="sort"
                />
              </div>
            </div>
            {showSubFilters === filterItem.id && (
              <div
                className={classNames(
                  AddProjectEmployeesFiltersPopupStyles.subFiltersContainer,
                  GlobalStyles.flex,
                  GlobalStyles.flexDirectionColumn,
                  GlobalStyles.gap05,
                )}
              >
                {filterItem.subFilters.map((subFilterItem) => (
                  <div
                    key={subFilterItem.title}
                    className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
                    onClick={() => onSubFilterToggle(filterItem, subFilterItem)}
                  >
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
                      <div className={classNames(GlobalStyles.flex1)} />
                      <div
                        className={classNames(
                          AddProjectEmployeesFiltersPopupStyles.subFiltersCheckBox,
                          subFilterItem.isActive && AddProjectEmployeesFiltersPopupStyles.subFiltersCheckBoxChecked,
                          GlobalStyles.centerVertical,
                        )}
                      >
                        {subFilterItem.isActive && (
                          <div className={classNames(GlobalStyles.centerVertical)}>
                            <img className={classNames(AddProjectEmployeesFiltersPopupStyles.checkIconImg)} src={checkIcon} alt="check" />
                          </div>
                        )}
                      </div>
                      <div className={classNames(GlobalStyles.flex1)} />
                    </div>
                    <div className={classNames(GlobalStyles.centerVertical)}>{subFilterItem.title}</div>
                    <div className={classNames(GlobalStyles.flex1)} />
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </PopupStructure>
  );
};
