export interface PopupHandlerType {
  opener: () => void;
  closer: () => void;
  isOpen: boolean;
}

export class PopupHandlerTypeAsMap<T extends string> extends Map<T, PopupHandlerType> {}

export interface PopupHandlerReturnType<T extends string> {
  popupHandler: PopupHandlerTypeAsMap<T>;
  onOpenPopup: (popupType: T, popupHandler: PopupHandlerTypeAsMap<T>) => void;
  onClosePopup: (popupType: T, popupHandler: PopupHandlerTypeAsMap<T>) => void;
}
