import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

export const deleteTimesheetGroupProjectEmployee = async (projectId: number, timesheetGroupId: number, timesheetGroupProjectEmployeeId: number) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/timesheet-groups/${timesheetGroupId}/employees/${timesheetGroupProjectEmployeeId}`,
    method: "DELETE",
  } as AxiosRequestConfig;
  return epcmAxiosInstance(config);
};
