import { useCallback } from "react";
import {
  convertBackendGlobalRoleListToFrontend,
  convertBackendProjectRoleListToFrontend,
  GlobalAction,
  GlobalRole,
  globalRoleActions,
  ProjectAction,
  projectRoleActions,
  projectViewingActions,
} from "../types/Roles.ts";
import { useGlobalRoleStore } from "../store/use-global-role-store.ts";
import { useProjectRoleStore } from "../store/use-project-role-store.ts";

interface RolesReturnType {
  canPerformGlobalAction: (action: GlobalAction) => boolean;
  canPerformProjectAction: (action: ProjectAction) => boolean;
}

export const useRetrieveUserPermittedActions = (): RolesReturnType => {
  const globalRolesListBackend = useGlobalRoleStore((state) => state.globalRoles);
  const globalRolesList = globalRolesListBackend ? convertBackendGlobalRoleListToFrontend(globalRolesListBackend) : [];

  // Memoized function to prevent re-creation on each render
  const canPerformGlobalAction = useCallback(
    (action: GlobalAction): boolean => {
      for (const role of globalRolesList) {
        const actions = globalRoleActions[role] || [];
        if (actions.includes(action)) {
          return true;
        }
      }
      return false;
    },
    [globalRolesList],
  );

  const projectRolesListBackend = useProjectRoleStore((state) => state.projectRoles);
  const projectRolesList = projectRolesListBackend ? convertBackendProjectRoleListToFrontend(projectRolesListBackend) : [];

  const canPerformProjectAction = useCallback(
    (action: ProjectAction): boolean => {
      if (globalRolesList.includes(GlobalRole.Admin)) {
        return true;
      }

      if (globalRolesList.includes(GlobalRole.Viewer) && projectViewingActions.includes(action)) {
        return true;
      }
      for (const role of projectRolesList) {
        const actions = projectRoleActions[role] || [];
        if (actions.includes(action)) {
          return true;
        }
      }
      return false;
    },
    [projectRolesList],
  );

  return { canPerformGlobalAction, canPerformProjectAction };
};
