import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import { DnDComponent } from "../../../../ui/dndcomponent/DnDComponent.tsx";
import { ExtensionConstraintEnum } from "../../../../types/DnDComponentTypes.ts";
import { useDndComponentStates } from "../../../../utils/use-dnd-component-states.ts";
import classNames from "classnames";
// import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import UploadManpowerPlanVersionPopupStyles from "./UploadManpowerPlanVersionPopup.module.css";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useEpcmApiProjectManpowerPlanMutations } from "../../../../apicalls/projects/projectmanpowerplan/mutations/useEpcmApiProjectManpowerPlanMutations.ts";
import { useProjectContext } from "../../ProjectsUtils.ts";
// import { useQueryClient } from "@tanstack/react-query";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { ApiResponseTypeEnum } from "../../../../types/apicallstypes/queryCommons.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import { useParams } from "react-router-dom";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useNotificationContext } from "../../../../ui/globalnotificationcontext/GlobalNotificationContext.ts";
import { FrontendFileAction } from "../../../../types/projects/FileTypes.ts";
import useEpcmProcessFileEvents from "../../../../utils/useProcessFileEvents.ts";

interface UploadManpowerPlanVersionPopupProps extends PopupType {}

// enum VersionTypeEnum {
//   new = "new",
//   revised = "revised",
// }

// const MANPOWER_PLAN_VERSIONS: string[] = MANPOWER_PLAN_LIST.map((plan) => plan.versionDisplay);

export const UploadManpowerPlanVersionPopup: FC<UploadManpowerPlanVersionPopupProps> = ({ isOpen, closeFn, headerText, secondaryHeaderText }) => {
  const [parent] = useAutoAnimate();
  const { chosenFileList, setChosenFileList, hasChosenFiles, isAnyLoading, setIsAnyLoading } = useDndComponentStates();
  const { currentProject } = useProjectContext();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const { useUploadManpowerPlan } = useEpcmApiProjectManpowerPlanMutations();
  const { projectId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { addNotification, updateNotification } = useNotificationContext();

  const { processFileEvents: processFileEventsUploadManpowerPlan } = useEpcmProcessFileEvents(
    FrontendFileAction.IMPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
  );

  const startFileEvents = useCallback(
    async (fileId: string) => {
      await processFileEventsUploadManpowerPlan(fileId);
    },
    [processFileEventsUploadManpowerPlan],
  );

  const uploadManpowerPlanVersionMutation = useUploadManpowerPlan(currentProject.id, {
    onSuccessCallback: (data) => {
      void startFileEvents(data.data as unknown as string).then(() => {});
      closeFn();
    },

    onErrorCallback: (err) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(err.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();

      // TODO: INVALIDATE MANPOWER PLAN VERSIONS LIST
    },
  });

  const uploadManpowerPlanVersion = useCallback(() => {
    if (chosenFileList) {
      const formData = new FormData();
      formData.append("file", chosenFileList[0], "file");
      uploadManpowerPlanVersionMutation.mutate(formData);
      onOpenLoaderPopup();
    }
  }, [chosenFileList, uploadManpowerPlanVersionMutation, onOpenLoaderPopup]);

  const fileInputValidation = (): boolean => {
    return hasChosenFiles;
  };

  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel ",
          buttonType: PopupButtonTypeEnum.neutral,
          action: closeFn,
        },
        {
          text: "Save",
          buttonType: PopupButtonTypeEnum.main,
          disabled: !fileInputValidation() || isAnyLoading, // TODO: add validation for mandatory fields
          tooltipText: !fileInputValidation() ? "Please select some files first" : isAnyLoading ? "Preparing files" : undefined,
          action: () => {
            fileInputValidation() && uploadManpowerPlanVersion();
          },
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      overrideContentContainerStyleClass={""}
    >
      <div className={classNames()} ref={parent}>
        <div className={classNames(UploadManpowerPlanVersionPopupStyles.dndContainer)}>
          <DnDComponent
            chosenFileList={chosenFileList}
            setChosenFileList={setChosenFileList}
            setIsAnyLoading={setIsAnyLoading}
            constraintOptions={{
              validExtensions: [ExtensionConstraintEnum.xlsx, ExtensionConstraintEnum.xls],
              maxNumberOfFiles: 1,
            }}
          />
        </div>

        {isResponseAlertPopupOpen && responseType && responseObject && (
          <ResponseAlertPopup
            isOpen={isResponseAlertPopupOpen}
            closeFn={() => {
              initializeResponseAlertPopup();
              onCloseResponseAlertPopup();
              if (chosenFileList) {
                setChosenFileList([]);
              }
              if (responseType === ApiResponseTypeEnum.success) {
                closeFn();
              }
            }}
            responseType={responseType}
            responseObject={responseObject}
          />
        )}
        {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      </div>
    </PopupStructure>
  );
};
