import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { FrontendGlobalConfigurationsEntityEnum } from "./useEpcmApiConfigurationsMutations.ts";

const API = `${EPCM_API_URLS.configurations}`;

export const deleteGlobalConfigurationEntity = async (
  globalConfigurationEntityId: number,
  globalConfigurationEntityType: FrontendGlobalConfigurationsEntityEnum,
  subPositionId?: number,
) => {
  if (globalConfigurationEntityType === FrontendGlobalConfigurationsEntityEnum.Subposition && subPositionId) {
    const config: AxiosRequestConfig = {
      url: `${API}/position/${globalConfigurationEntityId}/${globalConfigurationEntityType}/${subPositionId}`,
      method: "DELETE",
    } as AxiosRequestConfig;
    return epcmAxiosInstance(config);
  } else {
    const config: AxiosRequestConfig = {
      url: `${API}/${globalConfigurationEntityType}/${globalConfigurationEntityId}`,
      method: "DELETE",
    } as AxiosRequestConfig;
    return epcmAxiosInstance(config);
  }
};
