import MmrVersionInfoBoxStyles from "./MmrVersionsInfoBox.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import { FC } from "react";

interface MmrVersionsInfoBoxProps {
  mmrFileReference: string | null;
}

const MmrVersionsInfoBox: FC<MmrVersionsInfoBoxProps> = ({ mmrFileReference }) => {
  return (
    <div className={classNames(MmrVersionInfoBoxStyles.mainContainer, GlobalStyles.flex)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex2, MmrVersionInfoBoxStyles.container)}>
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap075,
            MmrVersionInfoBoxStyles.textContainer,
          )}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, MmrVersionInfoBoxStyles.headerText)}>MMR Reference</div>
          <div className={classNames(MmrVersionInfoBoxStyles.mainText)}>{mmrFileReference ?? "No MMR selected"}</div>
        </div>
      </div>
    </div>
  );
};

export default MmrVersionsInfoBox;
