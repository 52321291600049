import { EpcmMutationOptionsType } from "../../../config.ts";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { ErrorCallbackDataType, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { ApiRequestBodyGlobalRoles } from "epcm-common/dist/Types/RoleTypes";
import updateGlobalUserPermissionMutation from "./updateUserPermissionMutation.ts";
import deleteUserFromPermissionList from "./deleteUserFromPermissions.ts";

interface ApiDefinition {
  useUpdateGlobalPermissionMutation: (
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyGlobalRoles>;
  useDeleteGlobalUserFromPermissionsMutation: (
    userId: string,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, void>;
}

export const useEpcmApiUserPermissionsMutation = (): ApiDefinition => {
  const useUpdateGlobalPermissionMutation = (mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyGlobalRoles>({
      mutationFn: async (globalUsersRoles: ApiRequestBodyGlobalRoles) => {
        return updateGlobalUserPermissionMutation(globalUsersRoles);
      },
      onSuccess: (data) => {
        console.log("on post update global permissions mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post update global permissions mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };
  const useDeleteGlobalUserFromPermissionsMutation = (userId: string, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void>({
      mutationFn: async () => {
        return deleteUserFromPermissionList(userId);
      },
      onSuccess: (data) => {
        console.log("on post delete global user from permissions mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post delete global user from permissions mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  return {
    useUpdateGlobalPermissionMutation: useUpdateGlobalPermissionMutation,
    useDeleteGlobalUserFromPermissionsMutation: useDeleteGlobalUserFromPermissionsMutation,
  };
};
