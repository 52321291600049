import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import GlobalStyles from "../../../assets/css/GlobalStyles.module.css";
import DnDChosenFileListStyles from "./DnDChosenFileList.module.css";
import classNames from "classnames";
import xlsFileIcon from "../../../assets/images/xls-file-icon.svg";
import pdfFileIcon from "../../../assets/images/pdf-file-icon.svg";
import docxFileIcon from "../../../assets/images/docx-file-icon.svg";
import closeIcon from "../../../assets/images/close-popup.svg";
import xlsFileIconFade from "../../../assets/images/xls-file-icon-fade.svg";
import pdfFileIconFade from "../../../assets/images/pdf-file-icon-fade.svg";
import docxFileIconFade from "../../../assets/images/docx-file-icon-fade.svg";
import { Fade, Tooltip } from "@mui/material";
import { getSubstringBasedOnChars } from "../../../utils/StringManipulation.ts";
import deleteIcon from "../../../assets/images/trashcan-icon-blue.svg";

interface DnDChosenFileListProps {
  chosenFile: File;
  setChosenFileList: React.Dispatch<React.SetStateAction<File[]>>;
  setIsDoneLoading: (fileIndex: number) => void;
  fileIndexInList: number;
}

export const DnDChosenFileList: FC<DnDChosenFileListProps> = ({ chosenFile, setChosenFileList, setIsDoneLoading, fileIndexInList }) => {
  const [progress, setProgress] = useState<number>(0);

  const progressRef = useRef(() => {});

  const chosenFileExtension = useCallback((file: File) => {
    return file?.name.split(".").reverse()[0];
  }, []);

  const chosenFileSize = useCallback((file: File) => {
    const selectedFileSize = Math.floor((file.size / (1024 * 1024)) * 100) / 100;
    if (selectedFileSize < 1) {
      return `${(Math.floor((file.size / 1024) * 100) / 100).toFixed(2)}KB`;
    }
    return `${selectedFileSize.toFixed(2)}MB`;
  }, []);

  const deleteFileFromList = useCallback(
    (file: File) => {
      setChosenFileList((currentState) => {
        const chosenFileListClone = currentState.slice();
        const fileIndex = chosenFileListClone.map((fileItem) => fileItem.name).indexOf(file.name);
        if (fileIndex !== -1) {
          chosenFileListClone.splice(fileIndex, 1);
        }
        return chosenFileListClone;
      });
    },
    [setChosenFileList],
  );

  useEffect(() => {
    progressRef.current = () => {
      if (progress <= 100) {
        const diff = Math.random() * 8;
        setProgress(progress + 4 * diff);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    progress >= 100 && setIsDoneLoading(fileIndexInList);
  }, [progress, setIsDoneLoading, fileIndexInList]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img
            src={
              progress > 100
                ? ["XLS", "XLSX"].includes(chosenFileExtension(chosenFile).toUpperCase())
                  ? xlsFileIcon
                  : chosenFileExtension(chosenFile).toUpperCase() === "PDF"
                    ? pdfFileIcon
                    : chosenFileExtension(chosenFile).toUpperCase() === "DOCX"
                      ? docxFileIcon
                      : closeIcon
                : ["XLS", "XLSX"].includes(chosenFileExtension(chosenFile).toUpperCase())
                  ? xlsFileIconFade
                  : chosenFileExtension(chosenFile).toUpperCase() === "PDF"
                    ? pdfFileIconFade
                    : chosenFileExtension(chosenFile).toUpperCase() === "DOCX"
                      ? docxFileIconFade
                      : closeIcon
            }
            alt="file"
            className={classNames(DnDChosenFileListStyles.fileIconImg)}
          />
        </div>
        <Tooltip TransitionComponent={Fade} title={chosenFile.name} placement="top" arrow>
          <div
            className={classNames(
              DnDChosenFileListStyles.uploadedFileText,
              progress <= 100 && DnDChosenFileListStyles.uploadingFileText,
              GlobalStyles.centerVertical,
            )}
          >
            {getSubstringBasedOnChars(chosenFile.name, 32)}
          </div>
        </Tooltip>
        <div className={classNames(GlobalStyles.flex1)} />
        {progress > 100 && (
          <div
            className={classNames(GlobalStyles.centerVertical)}
            onClick={() => {
              deleteFileFromList(chosenFile);
            }}
          >
            <img src={deleteIcon} alt="delete" className={classNames(DnDChosenFileListStyles.deleteFileIconImg)} />
          </div>
        )}
      </div>
      <div className={classNames(DnDChosenFileListStyles.fileInfoContainer, GlobalStyles.flex, GlobalStyles.gap2)}>
        {progress > 100 ? (
          <>
            <div className={classNames(GlobalStyles.centerVertical)}>{chosenFileExtension(chosenFile).toUpperCase()}</div>
            <div className={classNames(GlobalStyles.centerVertical)}>{chosenFileSize(chosenFile)}</div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
