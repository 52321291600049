import { useState } from "react";

interface PopupStatesType {
  isUtilPopupOpen: boolean;
  onOpenUtilPopup: () => void;
  onCloseUtilPopup: () => void;
}

export const usePopupState = (): PopupStatesType => {
  const [isUtilPopupOpen, setIsUtilPopupOpen] = useState<boolean>(false);

  const onOpenUtilPopup = () => {
    setIsUtilPopupOpen(true);
  };

  const onCloseUtilPopup = () => {
    setIsUtilPopupOpen(false);
  };

  return {
    isUtilPopupOpen,
    onOpenUtilPopup,
    onCloseUtilPopup,
  } satisfies PopupStatesType;
};
