import { getAllProjects } from "./getAllProjects.ts";
import {
  convertProjectsData,
  convertSingleProjectDetailedData,
  convertSingleProjectLimitedData,
  convertSingleProjectNormalData,
} from "../../types/apicallstypes/apicallstypesconverters/projects/ProjectsApiConverter.ts";
import { QueryModelWithPagination } from "../../types/apicallstypes/queryCommons.ts";
import { FrontendProjectLimitedType, FrontendProjectStatus } from "../../types/apicallstypes/ProjectsApiTypes.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { getProject } from "./getProject.ts";

import { ProjectDetailed } from "epcm-common/dist/Types/ProjectTypes.ts";
import { getOvertimeTemplate } from "./getOvertimeTemplate.ts";
import { FrontendFileRetrievalResponseType } from "../../types/apicallstypes/GeneralTypes.ts";
import { FrontendOvertimeExportFileType } from "../../types/projects/OvertimeTypes.ts";
import { convertToBackendOvertimeRequestExportType } from "../../types/apicallstypes/apicallstypesconverters/projects/ProjectsOvertimeRequestConverter.ts";
import { getFileEvents } from "./getFileEvents.ts";
import { ProjectNormal } from "epcm-common/dist/Types/ProjectTypes";

interface ApiDefinition {
  getAllProjects: (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
    status?: FrontendProjectStatus,
  ) => Promise<QueryModelWithPagination<FrontendProjectLimitedType>>;
  getProject: (id: number, detailLevel?: DetailLevel) => Promise<FrontendProjectLimitedType>;
  getFileEvents: (projectId: number, fileId?: string) => Promise<ReadableStream>;
  getOvertimeTemplate: (id: number, type: FrontendOvertimeExportFileType) => Promise<FrontendFileRetrievalResponseType>;
}

export const useEpcmApiProjects = (): ApiDefinition => {
  const getAllProjectsConverted = async (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
    status?: FrontendProjectStatus,
  ): Promise<QueryModelWithPagination<FrontendProjectLimitedType>> => {
    const projectsData = await getAllProjects(pageOffset, searchFilter, pageSize, status);
    return {
      data: convertProjectsData(projectsData.data),
      nextPage: projectsData.nextPage,
      totalCount: projectsData.totalCount,
    };
  };

  const getProjectConverted = async (id: number, detailLevel?: DetailLevel): Promise<FrontendProjectLimitedType> => {
    const projectData = await getProject(id, detailLevel);

    return detailLevel === DetailLevel.DETAILED
      ? convertSingleProjectDetailedData(projectData as ProjectDetailed)
      : detailLevel === DetailLevel.NORMAL
        ? convertSingleProjectNormalData(projectData as ProjectNormal)
        : convertSingleProjectLimitedData(projectData);
  };

  const getOvertimeTemplateConverted = async (id: number, type: FrontendOvertimeExportFileType): Promise<FrontendFileRetrievalResponseType> => {
    return getOvertimeTemplate(id, convertToBackendOvertimeRequestExportType(type));
  };

  const getFileEventsConverted = async (projectId: number, fileId?: string): Promise<ReadableStream> => {
    return await getFileEvents(projectId, fileId);
  };
  return {
    getAllProjects: getAllProjectsConverted,
    getProject: getProjectConverted,
    getOvertimeTemplate: getOvertimeTemplateConverted,
    getFileEvents: getFileEventsConverted,
  };
};
