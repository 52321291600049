import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import ProjectInfoStyles from "./ProjectInfoItem.module.css";

interface ProjectInfoItemProps {
  labelTitle: string;
  labelValue: string | number;
}

const ProjectInfoItem: React.FC<ProjectInfoItemProps> = ({ labelTitle, labelValue }) => {
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(ProjectInfoStyles.labelTitle)}>{labelTitle}</div>
      <div className={classNames(ProjectInfoStyles.labelValue)}>{labelValue !== "" ? labelValue : "-"}</div>
    </div>
  );
};

export default ProjectInfoItem;
