import { QueryModelWithCount, QueryModelWithCountAndData } from "../../../../types/apicallstypes/queryCommons.ts";
import { getAllProjectTimesheetGroupsCount } from "./getAllProjectTimesheetGroupsCount.ts";
import { getAllProjectTimesheetItemsCount } from "./getAllProjectTimesheetItemsCount.ts";
import { FrontendTimesheetGroupNotFinal } from "../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { convertTimesheetsGroupNotFinalData } from "../../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/projecttimesheetgroups/ProjectTimesheetGroupsApiConverters.ts";
import { getAllProjectTimesheetNotFinalGroups } from "./getAllProjectTimesheetNotFinalGroups.ts";
import { FrontendProjectEmployeeNotInTimesheet } from "../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { getAllProjectNotInTimesheetEmployees } from "./getAllProjectNotInTimesheetEmployees.ts";
import { convertProjectEmployeesNotInTimesheetLimitedData } from "../../../../types/apicallstypes/apicallstypesconverters/projects/projectemployees/ProjectEmployeesApiConverter.ts";
import { getAllProjectEmployeesByPeriodCount } from "./getAllProjectEmployeesByPeriodCount.ts";

interface ApiDefinition {
  getAllProjectEmployeesByPeriodCount: (
    id: number,
    periodStart?: number,
    periodEnd?: number,
    active?: boolean,
    notActive?: boolean,
    inTimesheetReviewed?: boolean,
    inTimesheetPrepared?: boolean,
    inTimesheetPending?: boolean,
    notInTimesheet?: boolean,
    ungrouped?: boolean,
  ) => Promise<QueryModelWithCount>;

  getAllProjectNotInTimesheetEmployees: (
    id: number,
    periodStart: number,
    periodEnd: number,
  ) => Promise<QueryModelWithCountAndData<FrontendProjectEmployeeNotInTimesheet>>;

  getAllProjectTimesheetGroupsCount: (
    id: number,
    periodStart: number,
    periodEnd: number,
    draft?: boolean,
    final?: boolean,
  ) => Promise<QueryModelWithCount>;

  getAllProjectTimesheetItemsCount: (
    id: number,
    periodStart: number,
    periodEnd: number,
    draft?: boolean,
    final?: boolean,
  ) => Promise<QueryModelWithCount>;

  getAllProjectTimesheetNotFinalGroups: (
    id: number,
    periodStart: number,
    periodEnd: number,
    draft?: boolean,
    final?: boolean,
  ) => Promise<QueryModelWithCountAndData<FrontendTimesheetGroupNotFinal>>;
}

export const useEpcmProjectTimesheetAnalytics = (): ApiDefinition => {
  const getAllProjectEmployeesByPeriodCountConverted = async (
    id: number,
    periodStart?: number,
    periodEnd?: number,
    active?: boolean,
    notActive?: boolean,
    inTimesheetReviewed?: boolean,
    inTimesheetPrepared?: boolean,
    inTimesheetPending?: boolean,
    notInTimesheet?: boolean,
    ungrouped?: boolean,
  ): Promise<QueryModelWithCount> => {
    const allEmployeeCounts = await getAllProjectEmployeesByPeriodCount(
      id,
      periodStart,
      periodEnd,
      active,
      notActive,
      inTimesheetReviewed,
      inTimesheetPrepared,
      inTimesheetPending,
      notInTimesheet,
      ungrouped,
    );
    return {
      count: allEmployeeCounts.count,
    };
  };

  const getAllProjectTimesheetGroupsCountConverted = async (
    id: number,
    periodStart: number,
    periodEnd: number,
    draft?: boolean,
    final?: boolean,
  ): Promise<QueryModelWithCount> => {
    const allGroupCounts = await getAllProjectTimesheetGroupsCount(id, periodStart, periodEnd, draft, final);
    return {
      count: allGroupCounts.count,
    };
  };

  const getAllProjectTimesheetItemsCountConverted = async (
    id: number,
    periodStart: number,
    periodEnd: number,
    draft?: boolean,
    final?: boolean,
  ): Promise<QueryModelWithCount> => {
    const allItemsCounts = await getAllProjectTimesheetItemsCount(id, periodStart, periodEnd, draft, final);
    return {
      count: allItemsCounts.count,
    };
  };

  const getAllProjectTimesheetNotFinalGroupsConverted = async (
    id: number,
    periodStart: number,
    periodEnd: number,
    draft?: boolean,
    final?: boolean,
  ): Promise<QueryModelWithCountAndData<FrontendTimesheetGroupNotFinal>> => {
    const allNotFinalGroups = await getAllProjectTimesheetNotFinalGroups(id, periodStart, periodEnd, draft, final);

    return { data: convertTimesheetsGroupNotFinalData(allNotFinalGroups.data), count: allNotFinalGroups.count };
  };

  const getAllProjectNotInTimesheetEmployeesConverted = async (
    id: number,
    periodStart: number,
    periodEnd: number,
  ): Promise<QueryModelWithCountAndData<FrontendProjectEmployeeNotInTimesheet>> => {
    const allNotInTimesheetEmployees = await getAllProjectNotInTimesheetEmployees(id, periodStart, periodEnd);
    return { data: convertProjectEmployeesNotInTimesheetLimitedData(allNotInTimesheetEmployees.data), count: allNotInTimesheetEmployees.count };
  };

  return {
    getAllProjectEmployeesByPeriodCount: getAllProjectEmployeesByPeriodCountConverted,
    getAllProjectTimesheetGroupsCount: getAllProjectTimesheetGroupsCountConverted,
    getAllProjectTimesheetItemsCount: getAllProjectTimesheetItemsCountConverted,
    getAllProjectTimesheetNotFinalGroups: getAllProjectTimesheetNotFinalGroupsConverted,
    getAllProjectNotInTimesheetEmployees: getAllProjectNotInTimesheetEmployeesConverted,
  };
};
