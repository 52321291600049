import classNames from "classnames";
import ConfigurationTradesPopupStyles from "./ConfigurationTradesPopup.module.css";

import TimesheetGroupSettingsMainIcon from "../../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import { FC, useCallback, useMemo, useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { PopupButtonTypeEnum, PopupType } from "../../../../../../types/PopupType.ts";
import { FrontendProjectTradeType } from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { PopupStructure } from "../../../../../../ui/popupstructure/PopupStructure.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";

interface ConfigurationTradesPopupProps extends PopupType {
  projectId?: number;
  tradeItem?: FrontendProjectTradeType;
  mode: configurationsPopupType;
}

const ConfigurationTradesPopup: FC<ConfigurationTradesPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  projectId,
  tradeItem,
  mode,
}) => {
  const [tradeName, setTradeName] = useState<string>(tradeItem?.description || "");
  const [tradeCode, setTradeCode] = useState<string>(tradeItem?.code || "");

  useEffect(() => {
    if (tradeItem) {
      setTradeName(tradeItem.description);
      setTradeCode(tradeItem.code);
    }
  }, [tradeItem]);

  const { useCreateProjectEntityMutation, useUpdateProjectEntityMutation } = useEpcmApiProjectUtilsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createProjectTradeMutation = useCreateProjectEntityMutation(projectId!, FrontendProjectConfigurationsEntityEnum.Trade, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Trade was added to project successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateProjectTradeMutation = useUpdateProjectEntityMutation(projectId!, tradeItem?.id ?? 0, FrontendProjectConfigurationsEntityEnum.Trade, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Trade was updated successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onCreateProjectTrade = useCallback(() => {
    createProjectTradeMutation.mutate({
      code: tradeCode,
      description: tradeName,
    });
    onOpenLoaderPopup();
  }, [createProjectTradeMutation, tradeName, tradeCode, onOpenLoaderPopup]);

  const onUpdateProjectTrade = useCallback(() => {
    updateProjectTradeMutation.mutate({
      code: tradeCode,
      description: tradeName,
    });
    onOpenLoaderPopup();
  }, [updateProjectTradeMutation, tradeName, tradeCode, onOpenLoaderPopup]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTrades, projectId] });
  }, [queryClient, projectId]);

  const isEitherInputEmpty = useMemo(() => !tradeName || !tradeCode, [tradeName, tradeCode]);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(ConfigurationTradesPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Trade" : "Create New Trade",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !!tradeCode && !!tradeName && onUpdateProjectTrade();
            } else {
              !!tradeCode && !!tradeName && onCreateProjectTrade();
            }
          },
          tooltipText: isEitherInputEmpty ? "Please enter both trade code and trade description" : "",
          disabled: isEitherInputEmpty,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationTradesPopupStyles.mainContainer)}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationTradesPopupStyles.infoText)}>Trade Code</div>
          <input
            placeholder={"Type a trade code..."}
            className={classNames(ConfigurationTradesPopupStyles.infoContainer, ConfigurationTradesPopupStyles.infoContainerInput)}
            required
            value={tradeCode}
            onChange={(e) => setTradeCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationTradesPopupStyles.infoText)}>Trade Name</div>
          <input
            placeholder={"Type a trade name..."}
            className={classNames(ConfigurationTradesPopupStyles.infoContainer, ConfigurationTradesPopupStyles.infoContainerInput)}
            required
            value={tradeName}
            onChange={(e) => setTradeName(e.target.value)}
          ></input>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ConfigurationTradesPopup;
