import { FC, useEffect, useMemo, useState } from "react";
import TrashCanDarkIcon from "../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIconHover from "../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import EditIcon from "../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../assets/images/trashcan-icon-red.svg";

import GlobalConfigurationPositionsRowStyles from "./GlobalConfigurationPositionsRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { FrontendGlobalPositionType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { SettingsPagePopups, useSettingsPopups } from "../../../use-settings-pupups.ts";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { DeletePopup } from "../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import GlobalConfigurationPositionsPopup from "../../popups/globalconfigurationpositionspopup/GlobalConfigurationPositionsPopup.tsx";
import { useEpcmApiConfigurations } from "../../../../../apicalls/configurations/useEpcmApiConfigurations.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import ArrowDropDownIcon from "../../../../../assets/images/arrow-down-black.svg";
import { ClickAwayListener } from "@mui/material";
import { EpcmTooltip } from "../../../../../ui/epcmtooltip/EpcmTooltip.tsx";
import settingsIcon from "../../../../../assets/images/setting-dots-black.svg";
import BluePlusIcon from "../../../../../assets/images/blue-plus-icon.svg";
import GlobalConfigurationSubpositionTooltip from "./globalconfigurationsubpositiontooltip/GlobalConfigurationSubpositionTooltip.tsx";
import GlobalConfigurationSubpositionsPopup from "../../popups/globalconfigurationsubpositionspopup/GlobalConfigurationSubpositionsPopup.tsx";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../../types/Roles.ts";

interface GlobalConfigurationPositionRowProps {
  globalPositionItem: FrontendGlobalPositionType;
}

const GlobalConfigurationPositionsRow: FC<GlobalConfigurationPositionRowProps> = ({ globalPositionItem }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTooltipOpenMap, setIsTooltipOpenMap] = useState<Record<number, boolean>>({});
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();
  const { useDeleteGlobalEntityMutation } = useEpcmApiConfigurationsMutations();
  const { getAllGlobalSubpositions } = useEpcmApiConfigurations();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const getGlobalPositionSubpositionsQuery = useQuery({
    queryKey: [QueryNames.GlobalSubpositions, globalPositionItem.id],
    queryFn: () => getAllGlobalSubpositions(globalPositionItem.id),
    enabled: isAuthorized,
  });

  const subPositions = useMemo(() => {
    return getGlobalPositionSubpositionsQuery.data;
  }, [getGlobalPositionSubpositionsQuery.data]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleTooltipClose = (subPositionId: number) => {
    setIsTooltipOpenMap((prev) => ({ ...prev, [subPositionId]: false }));
  };

  const handleSpecificSubpositionTooltipClick = (event: React.MouseEvent<HTMLDivElement>, subPositionId: number) => {
    event.preventDefault();
    handleSubpositionTooltipOpen(subPositionId);
  };

  const handleSubpositionTooltipOpen = (subPositionId: number) => {
    setIsTooltipOpenMap((prev) => ({ ...prev, [subPositionId]: true }));
  };

  const deleteGlobalPositionMutation = useDeleteGlobalEntityMutation(globalPositionItem.id, FrontendGlobalConfigurationsEntityEnum.Position, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Global Position was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const invalidateQueries = () => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalPositions] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalSubpositions, globalPositionItem.id] });
  };

  const canListConfigurations = canPerformGlobalAction(GlobalAction.ConfigList);
  const canEditConfigurations = canPerformGlobalAction(GlobalAction.ConfigUpdate);
  const canDeleteConfigurations = canPerformGlobalAction(GlobalAction.ConfigDelete);
  const canCreateConfiguration = canPerformGlobalAction(GlobalAction.ConfigCreate);

  useEffect(() => {
    if (subPositions) {
      // Initialize tooltip state for new subpositions
      setIsTooltipOpenMap((prev) => {
        const newTooltipState = { ...prev };
        subPositions.forEach((subPosition) => {
          if (!(subPosition.id in newTooltipState)) {
            newTooltipState[subPosition.id] = false;
          }
        });
        return newTooltipState;
      });
    }
  }, [subPositions]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.GlobalSubpositions, globalPositionItem.id] }).then(() => {
        console.log(
          `In configuration-position-row component, ${QueryNames.GlobalSubpositions} query for position ${globalPositionItem.id} cancelled`,
        );
      });
    };
  }, [queryClient, globalPositionItem.id]);

  return (
    <>
      <tr
        key={globalPositionItem.id}
        className={classNames(
          GlobalConfigurationPositionsRowStyles.itemContainer,
          isRowHovered && GlobalConfigurationPositionsRowStyles.rowHoverEffect,
          GlobalConfigurationPositionsRowStyles.Trow,
        )}
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
      >
        {globalPositionItem && canListConfigurations ? (
          <>
            <td
              className={classNames(
                GlobalConfigurationPositionsRowStyles.tableCell,
                GlobalConfigurationPositionsRowStyles.boldText,
                GlobalConfigurationPositionsRowStyles.tD,
              )}
            >
              {globalPositionItem.code}
            </td>
            <td className={classNames(GlobalConfigurationPositionsRowStyles.tableCell, GlobalConfigurationPositionsRowStyles.tD)}>
              {globalPositionItem.description}
            </td>
            <td className={classNames(GlobalConfigurationPositionsRowStyles.tableCell, GlobalConfigurationPositionsRowStyles.tD)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalConfigurationPositionsRowStyles.subpositionsCell)}>
                <div className={classNames(GlobalConfigurationPositionsRowStyles.subpositionText)}>Sub-Positions</div>
                <div
                  className={classNames(
                    GlobalConfigurationPositionsRowStyles.subpositionCount,
                    GlobalStyles.centerVertical,
                    GlobalStyles.centerHorizontal,
                  )}
                >
                  {subPositions?.length}
                </div>
                <div onClick={() => toggleExpand()} className={classNames(GlobalStyles.elementWithCursor)}>
                  <img
                    src={ArrowDropDownIcon}
                    alt={"Arrow dropdown icon"}
                    className={classNames(
                      isExpanded && GlobalConfigurationPositionsRowStyles.rotateImg,
                      GlobalConfigurationPositionsRowStyles.duration1,
                    )}
                  ></img>
                </div>
              </div>
            </td>
            <td className={classNames(GlobalConfigurationPositionsRowStyles.tableCellActions, GlobalConfigurationPositionsRowStyles.tD)}>
              <div
                className={classNames(
                  GlobalConfigurationPositionsRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                {canDeleteConfigurations && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();

                      onOpenUtilPopup();
                    }}
                    onMouseEnter={() => setDeleteIconHovered(true)}
                    onMouseLeave={() => setDeleteIconHovered(false)}
                  >
                    <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                  </div>
                )}
                {canEditConfigurations && (
                  <div
                    className={classNames(GlobalConfigurationPositionsRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                    onClick={() => onOpenPopup(SettingsPagePopups.updateGlobalPosition, popupHandler)}
                    onMouseEnter={() => setEditIconHovered(true)}
                    onMouseLeave={() => setEditIconHovered(false)}
                  >
                    <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                  </div>
                )}
              </div>
            </td>
          </>
        ) : (
          <></>
        )}
      </tr>
      {isExpanded && (
        <tr
          className={classNames(
            GlobalConfigurationPositionsRowStyles.itemContainer,
            isRowHovered && GlobalConfigurationPositionsRowStyles.rowHoverEffect,
            GlobalConfigurationPositionsRowStyles.Trow,
          )}
          onMouseEnter={() => setIsRowHovered(true)}
          onMouseLeave={() => setIsRowHovered(false)}
        >
          <td colSpan={4} className={classNames(GlobalConfigurationPositionsRowStyles.expandedContent, GlobalConfigurationPositionsRowStyles.tD)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap, GlobalStyles)}>
              {/* Sub-Positions label positioned absolutely to the left */}
              {subPositions && (
                <div className={GlobalConfigurationPositionsRowStyles.subPositionLabelContainer}>
                  <div className={GlobalConfigurationPositionsRowStyles.subPositionLabel}>SUB-POSITIONS:</div>
                </div>
              )}
              <div
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.gap,

                  GlobalConfigurationPositionsRowStyles.subpositionsContainer,
                )}
              >
                {subPositions?.map((subPosition, index) => (
                  <div
                    key={subPosition.id}
                    className={classNames(
                      GlobalStyles.flex,
                      GlobalStyles.centerHorizontal,
                      GlobalStyles.gap075,
                      GlobalConfigurationPositionsRowStyles.subpositionItemCell,
                      { [GlobalConfigurationPositionsRowStyles.subpositionItemCellFirst]: index === 0 }, // Additional styling for the first subposition
                    )}
                  >
                    <div className={GlobalConfigurationPositionsRowStyles.subPositionCode}>{subPosition.code}</div>
                    <div className={GlobalConfigurationPositionsRowStyles.subPositionDescription}>{subPosition.description}</div>
                    {(canEditConfigurations || canDeleteConfigurations) && (
                      <div
                        className={classNames(
                          GlobalConfigurationPositionsRowStyles.settingsSubpositionContainer,
                          GlobalStyles.flex,
                          GlobalStyles.elementWithCursor,
                          GlobalStyles.centerVertical,
                        )}
                        onClick={(event) => {
                          isTooltipOpenMap[subPosition.id] && event.stopPropagation();
                        }}
                      >
                        <ClickAwayListener
                          onClickAway={() => (canEditConfigurations || canDeleteConfigurations) && handleTooltipClose(subPosition.id)}
                        >
                          <div
                            className={classNames(GlobalConfigurationPositionsRowStyles.settingsIcon, GlobalStyles.flex, GlobalStyles.centerVertical)}
                            onClick={(event) =>
                              (canEditConfigurations || canDeleteConfigurations) && handleSpecificSubpositionTooltipClick(event, subPosition.id)
                            }
                          >
                            <EpcmTooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={() => handleTooltipClose(subPosition.id)}
                              open={isTooltipOpenMap[subPosition.id] ?? false}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                canListConfigurations ? (
                                  <GlobalConfigurationSubpositionTooltip
                                    positionId={globalPositionItem.id}
                                    subpositionItem={subPosition}
                                    canBeDeleted={subPositions?.length > 1}
                                  />
                                ) : (
                                  ""
                                )
                              }
                              arrow
                              placement={"top-end"}
                            >
                              <img
                                className={classNames(GlobalConfigurationPositionsRowStyles.settingsSubpositionIcon)}
                                src={settingsIcon}
                                alt="settings"
                              />
                            </EpcmTooltip>
                          </div>
                        </ClickAwayListener>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {canCreateConfiguration && (
                <div className={classNames(GlobalConfigurationPositionsRowStyles.addSubpositionContainerPadding)}>
                  <div
                    className={classNames(
                      GlobalStyles.flex,
                      GlobalStyles.centerHorizontal,
                      GlobalStyles.gap05,
                      GlobalStyles.elementWithCursor,
                      GlobalConfigurationPositionsRowStyles.addSubpositionContainer,
                    )}
                    onClick={() => onOpenPopup(SettingsPagePopups.addGlobalSubposition, popupHandler)}
                  >
                    <div
                      className={classNames(
                        GlobalStyles.flex,
                        GlobalStyles.centerHorizontal,
                        GlobalStyles.centerVertical,
                        GlobalConfigurationPositionsRowStyles.plusIconContainer,
                      )}
                    >
                      <img src={BluePlusIcon} alt={"Add Subposition"} />
                    </div>
                    <div className={classNames(GlobalConfigurationPositionsRowStyles.addGroupText)}>Add subposition</div>
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteGlobalPositionMutation.mutate();

            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      {popupHandler.get(SettingsPagePopups.addGlobalSubposition)!.isOpen && (
        <GlobalConfigurationSubpositionsPopup
          mode={configurationsPopupType.create}
          closeFn={() => onClosePopup(SettingsPagePopups.addGlobalSubposition, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.addGlobalSubposition)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.addGlobalSubposition)}
          positionId={globalPositionItem.id}
        />
      )}
      {popupHandler.get(SettingsPagePopups.updateGlobalPosition)!.isOpen && (
        <GlobalConfigurationPositionsPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(SettingsPagePopups.updateGlobalPosition, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.updateGlobalPosition)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.updateGlobalPosition)}
          globalPositionItem={globalPositionItem}
        />
      )}
    </>
  );
};

export default GlobalConfigurationPositionsRow;
