import { FC, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import classNames from "classnames";
import TimesheetEmployeesInTimesheetTooltipStyles from "./InTimesheetTooltip.module.css";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import { Skeleton } from "@mui/material";
import { useEpcmProjectTimesheetAnalytics } from "../../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsanalytics/useEpcmProjectTimesheetAnalytics.ts";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { QueryNames, SubQueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { convertDateToMilSecs } from "../../../../../../../../utils/DateManipulation.ts";

interface TimesheetEmployeesInTimesheetTooltipProps {
  projectId: number;
  periodStart: Date | null;
  periodEnd: Date | null;
}

const InTimesheetTooltip: FC<TimesheetEmployeesInTimesheetTooltipProps> = ({ projectId, periodStart, periodEnd }) => {
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const { getAllProjectEmployeesByPeriodCount } = useEpcmProjectTimesheetAnalytics();

  const projectTimesheetEmployeesInFinalizedQuery = useQuery({
    queryKey: [
      QueryNames.ProjectTimesheetAnalyticsEmployees,
      projectId,
      periodStart,
      periodEnd,
      SubQueryNames.inTooltip,
      SubQueryNames.inFinalizedTimesheet,
    ],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(
        projectId,
        convertDateToMilSecs(periodStart!),
        convertDateToMilSecs(periodEnd!),
        undefined,
        undefined,
        true,
        true,
        false,
      ),

    enabled: isAuthorized,
  });

  const projectTimesheetEmployeesInFinalizedData = useMemo(() => {
    return projectTimesheetEmployeesInFinalizedQuery.data;
  }, [projectTimesheetEmployeesInFinalizedQuery]);

  const projectTimesheetEmployeesInDraftQuery = useQuery({
    queryKey: [
      QueryNames.ProjectTimesheetAnalyticsEmployees,
      projectId,
      periodStart,
      periodEnd,
      SubQueryNames.inTooltip,
      SubQueryNames.inDraftTimesheet,
    ],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(
        projectId,
        convertDateToMilSecs(periodStart!),
        convertDateToMilSecs(periodEnd!),
        undefined,
        undefined,
        false,
        false,
        true,
      ),

    enabled: isAuthorized,
  });

  const projectTimesheetEmployeesInDraftData = useMemo(() => {
    return projectTimesheetEmployeesInDraftQuery.data;
  }, [projectTimesheetEmployeesInDraftQuery]);

  useEffect(() => {
    return () => {
      if (periodStart && periodEnd) {
        queryClient
          .cancelQueries({
            queryKey: [
              QueryNames.ProjectTimesheetAnalyticsEmployees,
              projectId,
              periodStart,
              periodEnd,
              SubQueryNames.inTooltip,
              SubQueryNames.inFinalizedTimesheet,
            ],
          })
          .then(() => {
            console.log(
              `Query with name ${QueryNames.ProjectTimesheetAnalyticsEmployees} for project ${projectId} and finalized timesheet employees was cancelled`,
            );
          });
        queryClient
          .cancelQueries({
            queryKey: [
              QueryNames.ProjectTimesheetAnalyticsEmployees,
              projectId,
              periodStart,
              periodEnd,
              SubQueryNames.inTooltip,
              SubQueryNames.inDraftTimesheet,
            ],
          })
          .then(() => {
            console.log(
              `Query with name ${QueryNames.ProjectTimesheetAnalyticsEmployees} for project ${projectId} and draft timesheet employees was cancelled`,
            );
          });
      }
    };
  }, [queryClient, projectId, periodStart, periodEnd]);

  return (
    <div
      className={classNames(
        TimesheetEmployeesInTimesheetTooltipStyles.tooltipContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap005,
      )}
    >
      <div
        className={classNames(
          TimesheetEmployeesInTimesheetTooltipStyles.tooltipItemContainer,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap01,
        )}
      >
        {!projectTimesheetEmployeesInFinalizedQuery.isLoading ? (
          <div
            className={classNames(TimesheetEmployeesInTimesheetTooltipStyles.contentText)}
          >{`${projectTimesheetEmployeesInFinalizedData?.count} Employees`}</div>
        ) : (
          <Skeleton variant={"text"} width={50} />
        )}
        <div className={classNames(TimesheetEmployeesInTimesheetTooltipStyles.contentText, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
          <div>{`In`}</div>
          <div className={classNames(TimesheetEmployeesInTimesheetTooltipStyles.finalizedText)}>{`Finalized`}</div>
          <div>{`Timesheets`}</div>
        </div>
      </div>
      <div
        className={classNames(
          TimesheetEmployeesInTimesheetTooltipStyles.tooltipItemContainer,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap025,
        )}
      >
        {!projectTimesheetEmployeesInDraftQuery.isLoading ? (
          <div
            className={classNames(TimesheetEmployeesInTimesheetTooltipStyles.contentText)}
          >{`${projectTimesheetEmployeesInDraftData?.count} Employees`}</div>
        ) : (
          <Skeleton variant={"text"} width={50} />
        )}
        <div className={classNames(TimesheetEmployeesInTimesheetTooltipStyles.contentText, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
          <div>{`In`}</div>
          <div className={classNames(TimesheetEmployeesInTimesheetTooltipStyles.draftText)}>{`Draft`}</div>
          <div>{`Timesheets`}</div>
        </div>
      </div>
    </div>
  );
};
export default InTimesheetTooltip;
