import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ManpowerPlanStyles from "./ManpowerPlan.module.css";
import classNames from "classnames";
import { EPCMInfoContainerDiv } from "../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import { useCallback, useMemo, useState } from "react";
// import settingsIcon from "../../../../assets/images/setting-dots-black.svg";
import uploadIcon from "../../../../assets/images/upload-icon-black-lines.svg";
import uploadIconHovered from "../../../../assets/images/upload-icon-white-lines.svg";
import { ManpowerPlanList } from "./manpowerplanlist/ManpowerPlanList.tsx";
import { FrontendManpowerPlanStates, MANPOWER_PLAN_LIST, ManpowerPlanStatusEnum } from "../../../../types/projects/ManpowerPlanTypes.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import { UploadManpowerPlanVersionPopup } from "../../popups/uploadmanpowerplanversionpopup/UploadManpowerPlanVersionPopup.tsx";
import { GeneratePlanLoader } from "./generateplanloader/GeneratePlanLoader.tsx";
import { DownloadPlanView } from "./downloadplanview/DownloadPlanView.tsx";
import { fileSave } from "browser-fs-access";
import { FrontendExportExcelResponseType, FrontendFileIdResponseType } from "../../../../types/apicallstypes/GeneralTypes.ts";
import { ApiResponseTypeEnum } from "../../../../types/apicallstypes/queryCommons.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { useEpcmApiProjectManpowerPlan } from "../../../../apicalls/projects/projectmanpowerplan/useEpcmApiProjectManpowerPlan.ts";
import { FrontendFileAction } from "../../../../types/projects/FileTypes.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectFiles } from "../../../../apicalls/projects/projectfiles/useEpcmApiProjectFiles.ts";
import { useParams } from "react-router-dom";
import { useNotificationContext } from "../../../../ui/globalnotificationcontext/GlobalNotificationContext.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../types/Roles.ts";
import useEpcmProcessFileEvents from "../../../../utils/useProcessFileEvents.ts";

export const ManpowerPlan = () => {
  const { projectId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    // initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const [loaderPercentage, setLoaderPercentage] = useState<number>(0);
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const [generatedManpowerPlan, setGeneratedManpowerPlan] = useState<FrontendExportExcelResponseType | null>(null);
  const [databaseFileId, setDatabaseFileId] = useState<number | undefined>();
  const [fileId, setFileId] = useState<string>("");
  const [isUploadButtonHovered, setIsUploadButtonHovered] = useState<boolean>(false);
  const { getManpowerPlan } = useEpcmApiProjectManpowerPlan();
  const { getProjectFile } = useEpcmApiProjectFiles();
  const { addNotification, updateNotification } = useNotificationContext();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const [planState, setPlanState] = useState(FrontendManpowerPlanStates.ReadyToGenerate);

  const { processFileEvents: processFileEventsManpower } = useEpcmProcessFileEvents(
    FrontendFileAction.EXPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
    undefined,
    undefined,
    setLoaderPercentage,
    setFileId,
    setPlanState,
    setDatabaseFileId,
  );

  const manpowerPlanList = useMemo(() => {
    return MANPOWER_PLAN_LIST;
  }, []);

  const mostRecentApproved = useMemo(() => {
    if (manpowerPlanList.length === 0) {
      return { code: "-", versionDisplay: "" };
    }
    return manpowerPlanList
      .filter((plan) => plan.approvalStatus === ManpowerPlanStatusEnum.approved)
      .reduce((prev, current) => (prev.dateUploaded > current.dateUploaded ? prev : current));
  }, [manpowerPlanList]);

  const getManpowerPlanFunction = useCallback(async (): Promise<FrontendFileIdResponseType> => {
    return getManpowerPlan(parseInt(projectId!));
  }, [projectId, getManpowerPlan]);

  // const canListManpowerPlan = canPerformProjectAction(ProjectAction.ProjectManpowerList);
  const canUploadManpowerPlan = canPerformProjectAction(ProjectAction.ProjectManpowerUpdate);
  const canGenerateManpowerPlan = canPerformProjectAction(ProjectAction.ProjectManpowerCreate);
  const canListManpowerPlan = canPerformProjectAction(ProjectAction.ProjectManpowerList);

  const startFileEventsListener = async (fileId: string) => {
    await processFileEventsManpower(fileId);
    setLoaderPercentage(0);
  };

  const exportManpowerPlanToFile = useCallback(async () => {
    setPlanState(FrontendManpowerPlanStates.Generating);
    getManpowerPlanFunction()
      .then((value) => {
        startFileEventsListener(value.fileId);
      })
      .catch((error) => {
        setPlanState(FrontendManpowerPlanStates.ReadyToGenerate);
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      });
  }, [setResponseType, setResponseObject, onOpenResponseAlertPopup, addNotification, projectId, getManpowerPlanFunction, startFileEventsListener]);

  const saveOvertimeTemplateFile = useCallback(async () => {
    const generatedFile = await getProjectFile(parseInt(projectId!), databaseFileId!);
    setGeneratedManpowerPlan(generatedFile);
    if (generatedFile) {
      fileSave(generatedFile.responseData, {
        fileName: generatedFile.fileName,
        extensions: [".xlsx"],
      })
        .then(() => {
          onOpenResponseAlertPopup();
          setResponseType(ApiResponseTypeEnum.success);
          setResponseObject({ status: 200, message: "File saved successfully!" });
        })
        .catch((error) => {
          console.error("Error saving file:", error);
          setResponseType(ApiResponseTypeEnum.error);
          setResponseObject(error.response.data);
          onOpenResponseAlertPopup();
        })
        .finally(() => {
          setPlanState(FrontendManpowerPlanStates.ReadyToGenerate);
        });
    }
  }, [generatedManpowerPlan, setResponseType, setResponseObject, onOpenResponseAlertPopup, projectId, getProjectFile, fileId, databaseFileId]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3, GlobalStyles.overflowHiddenFullHeight)}>
      <div className={classNames(ManpowerPlanStyles.infoBarContainer, GlobalStyles.flex, GlobalStyles.gap)}>
        {/*{canListManpowerPlan && (*/}
        {/*  <EPCMInfoContainerDiv className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>*/}
        {/*    <div className={classNames(classNames(ManpowerPlanStyles.infoPanelHeaderContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn))}>*/}
        {/*      <div className={classNames(classNames(GlobalStyles.flex, GlobalStyles.gap))}>*/}
        {/*        <div className={classNames(ManpowerPlanStyles.infoPanelHeader, GlobalStyles.centerVertical, GlobalStyles.flex, GlobalStyles.flex1)}>*/}
        {/*          {mostRecentApproved.code}*/}
        {/*        </div>*/}
        {/*        <div className={classNames(GlobalStyles.centerVertical)}>*/}
        {/*          <img className={classNames(ManpowerPlanStyles.settingsIconImg)} src={settingsIcon} alt="settings" />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className={classNames(GlobalStyles.centerVertical)}>{"Plan code"}</div>*/}
        {/*    </div>*/}
        {/*    <div className={classNames(GlobalStyles.flex1)} />*/}
        {/*    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>*/}
        {/*      <div className={classNames()}>{"Active version:"}</div>*/}
        {/*      <div className={classNames(ManpowerPlanStyles.infoPanelLargeText)}>{`#${mostRecentApproved.versionDisplay}`}</div>*/}
        {/*    </div>*/}
        {/*  </EPCMInfoContainerDiv>*/}
        {/*)}*/}
        {/*{canListManpowerPlan && (*/}
        {/*  <EPCMInfoContainerDiv className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>*/}
        {/*    <div className={classNames(ManpowerPlanStyles.infoPanelHeader, GlobalStyles.centerVertical)}>*/}
        {/*      {MANPOWER_PLAN_LIST.filter((plan) => plan.approvalStatus === ManpowerPlanStatusEnum.approved).length}*/}
        {/*    </div>*/}
        {/*    <div className={classNames(GlobalStyles.flex1)} />*/}
        {/*    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>*/}
        {/*      <div className={classNames()}>{"Approved"}</div>*/}
        {/*      <div className={classNames(ManpowerPlanStyles.infoPanelLargeText)}>{`Versions`}</div>*/}
        {/*    </div>*/}
        {/*  </EPCMInfoContainerDiv>*/}
        {/*)}*/}
        {canUploadManpowerPlan && (
          <EPCMInfoContainerDiv
            className={classNames(ManpowerPlanStyles.largeButton, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
            onMouseEnter={() => setIsUploadButtonHovered(true)}
            onMouseLeave={() => setIsUploadButtonHovered(false)}
            onClick={() => onOpenPopup(ProjectsPagePopups.uploadManpowerPlanVersion, popupHandler)}
          >
            <div className={classNames(ManpowerPlanStyles.largeButtonIcon)}>
              <img
                className={classNames(ManpowerPlanStyles.largeButtonIconImg)}
                src={isUploadButtonHovered ? uploadIconHovered : uploadIcon}
                alt="upload"
              />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
              <div className={classNames()}>{"Upload"}</div>
              <div className={classNames(ManpowerPlanStyles.infoPanelLargeText)}>{"Version"}</div>
            </div>
          </EPCMInfoContainerDiv>
        )}
        <div className={classNames(GlobalStyles.flex1)} />
        {(canGenerateManpowerPlan || canListManpowerPlan) && (
          <EPCMInfoContainerDiv>
            {planState === FrontendManpowerPlanStates.ReadyToGenerate && (
              <div
                className={classNames(
                  ManpowerPlanStyles.generateButtonContainer,
                  GlobalStyles.flex,
                  GlobalStyles.flexDirectionColumn,
                  GlobalStyles.gap,
                )}
                onClick={() => {
                  if (isAuthorized) {
                    void exportManpowerPlanToFile();
                  }
                }}
              >
                <div className={classNames()}>{"Generate Plan"}</div>
                <div className={classNames(GlobalStyles.flex1)} />
                <div className={classNames(GlobalStyles.flex1)} />
                <div className={classNames(ManpowerPlanStyles.generateButton)}>{"Generate"}</div>
              </div>
            )}
            {planState === FrontendManpowerPlanStates.Generating && <GeneratePlanLoader progress={loaderPercentage} />}
            {planState === FrontendManpowerPlanStates.ReadyToDownload && <DownloadPlanView downloadHandler={saveOvertimeTemplateFile} />}
          </EPCMInfoContainerDiv>
        )}
      </div>
      {manpowerPlanList.length > 0 && (
        <ManpowerPlanList
          manpowerPlanList={manpowerPlanList}
          mostRecentApprovedPlanUniqueIdentifier={mostRecentApproved.code + mostRecentApproved.versionDisplay}
        />
      )}

      {popupHandler.get(ProjectsPagePopups.uploadManpowerPlanVersion)!.isOpen && (
        <UploadManpowerPlanVersionPopup
          isOpen={popupHandler.get(ProjectsPagePopups.uploadManpowerPlanVersion)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.uploadManpowerPlanVersion, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.uploadManpowerPlanVersion)}
          secondaryHeaderText={`Project ID: ${projectId}`}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          isOpen={isResponseAlertPopupOpen}
          closeFn={onCloseResponseAlertPopup}
          responseType={responseType}
          responseObject={responseObject}
        />
      )}
    </div>
  );
};
