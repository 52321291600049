import { FrontendExportExcelResponseType } from "../../../types/apicallstypes/GeneralTypes.ts";
import { getFile } from "./getFile.ts";
import { FilesFilterType, FrontendFileType } from "../../../types/projects/FileTypes.ts";
import { getAllFiles } from "./getAllFiles.ts";
import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { convertProjectFileData } from "../../../types/apicallstypes/apicallstypesconverters/projects/projectfiles/ProjectFilesApiConverter.ts";

interface ApiDefinition {
  getAllProjectFiles: (
    projectId: number,
    pageOffset?: number,
    pageSize?: number,
    searchFilter?: string,
    filtersList?: FilesFilterType[],
  ) => Promise<QueryModelWithPagination<FrontendFileType>>;
  getProjectFile: (id: number, sseRequestId: number) => Promise<FrontendExportExcelResponseType>;
}

export const useEpcmApiProjectFiles = (): ApiDefinition => {
  const getAllProjectFilesConverted = async (
    id: number,
    pageOffset?: number,
    pageSize?: number,
    searchFilter?: string,
    filtersList?: FilesFilterType[],
  ): Promise<QueryModelWithPagination<FrontendFileType>> => {
    const responseData = await getAllFiles(id, pageOffset, pageSize, searchFilter, filtersList);
    return {
      data: convertProjectFileData(responseData.data),
      totalCount: responseData.totalCount,
      nextPage: responseData.nextPage,
    };
  };
  const getProjectFileConverted = async (id: number, sseRequestId: number): Promise<FrontendExportExcelResponseType> => {
    const response = await getFile(id, sseRequestId);

    return {
      responseData: response.responseData,
      fileName: response.fileName,
    };
  };

  return {
    getProjectFile: getProjectFileConverted,
    getAllProjectFiles: getAllProjectFilesConverted,
  };
};
