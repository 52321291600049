import { useState } from "react";
import { ApiResponseTypeEnum } from "../types/apicallstypes/queryCommons.ts";
import { BaseErrorInterface } from "epcm-common/dist/Types/ErrorTypes.ts";

interface ResponseAlertPopupStateType {
  isResponseAlertPopupOpen: boolean;
  onOpenResponseAlertPopup: () => void;
  onCloseResponseAlertPopup: () => void;
  responseType: ApiResponseTypeEnum | null;
  setResponseType: React.Dispatch<React.SetStateAction<ApiResponseTypeEnum | null>>;
  responseObject: BaseErrorInterface | null;
  setResponseObject: React.Dispatch<React.SetStateAction<BaseErrorInterface | null>>;
  initializeResponseAlertPopup: () => void;
}

export const useResponseAlertPopupStateType = (): ResponseAlertPopupStateType => {
  const [isResponseAlertPopupOpen, setIsResponseAlertPopupOpen] = useState<boolean>(false);
  const [responseType, setResponseType] = useState<ApiResponseTypeEnum | null>(null);
  const [responseObject, setResponseObject] = useState<BaseErrorInterface | null>(null);

  const onOpenResponseAlertPopup = () => {
    setIsResponseAlertPopupOpen(true);
  };

  const onCloseResponseAlertPopup = () => {
    setIsResponseAlertPopupOpen(false);
  };

  const initializeResponseAlertPopup = () => {
    setResponseType(null);
    setResponseObject(null);
  };

  return {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } satisfies ResponseAlertPopupStateType;
};
