export interface PopupType {
  isOpen: boolean;
  closeFn: () => void;
  headerText?: string;
  secondaryHeaderText?: string;
}

export interface PopupHeaderHighlightedPartType {
  text: string;
  styleClass: string;
}

export enum PopupButtonTypeEnum {
  neutral = "neutral",
  main = "main",
  danger = "danger",
}

export interface PopupButtonType {
  text: string;
  buttonType: PopupButtonTypeEnum;
  action: () => void;
  disabled?: boolean;
  tooltipText?: string;
}
