import classNames from "classnames";
import ConfigurationCtrPopupStyles from "./ConfigurationCtrPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";

import { FC, MouseEvent, useCallback, useMemo, useState, useEffect } from "react";
import ConfigurationManpowerCodesPopupStyles from "../configurationmanpowercodespopup/ConfigurationManpowerCodesPopup.module.css";
import arrowSelectBox from "../../../../../../assets/images/arrow-down-dark-blue-fill.svg";
import { Menu, MenuItem } from "@mui/material";

import { useQueryClient } from "@tanstack/react-query";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import { PopupButtonTypeEnum, PopupType } from "../../../../../../types/PopupType.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import {
  FrontendCtrNormalType,
  FrontendCtrOptions,
  FrontendCtrTypeEnum,
  FrontendCtrCategoryEnum,
  FrontendCtrCategoryOptions,
} from "../../../../../../types/apicallstypes/CtrTypes.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import {
  convertCtrCategoryToBackend,
  convertCtrTypeToBackend,
} from "../../../../../../types/apicallstypes/apicallstypesconverters/CtrApiConverter.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import { PopupStructure } from "../../../../../../ui/popupstructure/PopupStructure.tsx";

interface ConfigurationCtrPopupProps extends PopupType {
  projectId?: number;
  ctrItem?: FrontendCtrNormalType;
  mode: configurationsPopupType;
}

const ConfigurationCtrPopup: FC<ConfigurationCtrPopupProps> = ({ closeFn, isOpen, headerText, secondaryHeaderText, projectId, ctrItem, mode }) => {
  const [ctrCode, setCtrCode] = useState<string>(ctrItem?.code || "");
  const [ctrDescription, setCtrDescription] = useState<string>(ctrItem?.description || "");
  const [selectedCtrType, setSelectedCtrType] = useState<FrontendCtrTypeEnum | undefined>(ctrItem?.type);
  const [selectedCtrCategory, setSelectedCtrCategory] = useState<FrontendCtrCategoryEnum | undefined>(ctrItem?.category);

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(null);
  const [categoryDropdownAnchorEl, setCategoryDropdownAnchorEl] = useState<null | HTMLElement>(null);

  const { useCreateProjectEntityMutation, useUpdateProjectEntityMutation } = useEpcmApiProjectUtilsMutations();

  const queryClient = useQueryClient();

  //keep track of the hovered state of which delete subposition button
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  useEffect(() => {
    if (ctrItem) {
      setCtrCode(ctrItem.code);
      setCtrDescription(ctrItem.description);
      setSelectedCtrType(ctrItem.type);
      setSelectedCtrCategory(ctrItem.category);
    }
  }, [ctrItem]);

  const createProjectCtrMutation = useCreateProjectEntityMutation(projectId!, FrontendProjectConfigurationsEntityEnum.Ctr, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `New CTR was added to project successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateProjectCtrMutation = useUpdateProjectEntityMutation(projectId!, ctrItem?.id ?? 0, FrontendProjectConfigurationsEntityEnum.Ctr, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `CTR was updated successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onCreateProjectCtr = useCallback(() => {
    createProjectCtrMutation.mutate({
      code: ctrCode,
      description: ctrDescription,
      category: convertCtrCategoryToBackend(selectedCtrCategory!),
      type: convertCtrTypeToBackend(selectedCtrType!),
    });
    onOpenLoaderPopup();
  }, [createProjectCtrMutation, ctrCode, ctrDescription, selectedCtrCategory, selectedCtrType, onOpenLoaderPopup]);

  const onUpdateProjectCtr = useCallback(() => {
    updateProjectCtrMutation.mutate({
      code: ctrCode,
      description: ctrDescription,
      category: convertCtrCategoryToBackend(selectedCtrCategory!),
      type: convertCtrTypeToBackend(selectedCtrType!),
    });
    onOpenLoaderPopup();
  }, [updateProjectCtrMutation, ctrCode, ctrDescription, selectedCtrCategory, selectedCtrType, onOpenLoaderPopup]);

  const onDropdownMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const onDropdownMenuClose = () => {
    setDropdownAnchorEl(null);
  };

  const onCategoryDropdownMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setCategoryDropdownAnchorEl(event.currentTarget);
  };
  const onCategoryDropdownMenuClose = () => {
    setCategoryDropdownAnchorEl(null);
  };

  const dropdownMenuOpen = useMemo(() => Boolean(dropdownAnchorEl), [dropdownAnchorEl]);
  const categoryDropdownMenuOpen = useMemo(() => Boolean(categoryDropdownAnchorEl), [categoryDropdownAnchorEl]);

  const onCtrTypeChange = useCallback(
    (item: FrontendCtrTypeEnum) => {
      setSelectedCtrType(item);
      onDropdownMenuClose();
    },
    [setSelectedCtrType],
  );

  const onCtrCategoryChange = useCallback(
    (item: FrontendCtrCategoryEnum) => {
      setSelectedCtrCategory(item);
      onCategoryDropdownMenuClose();
    },
    [setSelectedCtrCategory],
  );

  const isInputInvalid = useMemo(
    () => !ctrCode || !ctrDescription || !selectedCtrType || !selectedCtrCategory,
    [ctrCode, ctrDescription, selectedCtrType, selectedCtrCategory],
  );

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectCtrs, projectId] });
  }, [queryClient, projectId]);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(ConfigurationCtrPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update CTR" : "Create New CTR",
          action: () => {
            if (!isInputInvalid) {
              if (mode === configurationsPopupType.edit) {
                onUpdateProjectCtr();
              } else {
                onCreateProjectCtr();
              }
            }
          },
          tooltipText: isInputInvalid ? "Please fill all required fields" : "",
          disabled: isInputInvalid,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationCtrPopupStyles.mainContainer)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationCtrPopupStyles.infoText)}>CTR Code</div>
          <input
            placeholder={"Type a CTR code..."}
            className={classNames(ConfigurationCtrPopupStyles.infoContainer, ConfigurationCtrPopupStyles.infoContainerInput)}
            required
            value={ctrCode}
            onChange={(e) => setCtrCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationCtrPopupStyles.infoText)}>CTR Description</div>
          <input
            placeholder={"Type a CTR description..."}
            className={classNames(ConfigurationCtrPopupStyles.infoContainer, ConfigurationCtrPopupStyles.infoContainerInput)}
            required
            value={ctrDescription}
            onChange={(e) => setCtrDescription(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025, GlobalStyles.elementWithCursor)}>
          <div className={classNames(ConfigurationManpowerCodesPopupStyles.infoText)}>{`CTR Type`}</div>
          <div
            className={classNames(ConfigurationManpowerCodesPopupStyles.inputContainer, GlobalStyles.flex, GlobalStyles.gap)}
            onClick={onDropdownMenuOpen}
          >
            {selectedCtrType ? (
              <div>{selectedCtrType}</div>
            ) : (
              <div className={classNames(ConfigurationManpowerCodesPopupStyles.inputPlaceholder)}>{"Select " + `CTR Type`}</div>
            )}
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(ConfigurationManpowerCodesPopupStyles.selectBoxArrowIconImg)} src={arrowSelectBox} alt="arrow" />
            </div>
          </div>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={dropdownAnchorEl}
            open={dropdownMenuOpen}
            onClose={onDropdownMenuClose}
            PaperProps={{
              style: {
                maxHeight: "15em",
                width: "20em",
              },
            }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            {FrontendCtrOptions.map((item) => (
              <MenuItem key={item} selected={item === selectedCtrType} onClick={() => onCtrTypeChange(item)}>
                {item}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025, GlobalStyles.elementWithCursor)}>
          <div className={classNames(ConfigurationManpowerCodesPopupStyles.infoText)}>{`CTR Category`}</div>
          <div
            className={classNames(ConfigurationManpowerCodesPopupStyles.inputContainer, GlobalStyles.flex, GlobalStyles.gap)}
            onClick={onCategoryDropdownMenuOpen}
          >
            {selectedCtrCategory ? (
              <div>{selectedCtrCategory}</div>
            ) : (
              <div className={classNames(ConfigurationManpowerCodesPopupStyles.inputPlaceholder)}>{"Select " + `CTR Category`}</div>
            )}
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(ConfigurationManpowerCodesPopupStyles.selectBoxArrowIconImg)} src={arrowSelectBox} alt="arrow" />
            </div>
          </div>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={categoryDropdownAnchorEl}
            open={categoryDropdownMenuOpen}
            onClose={onCategoryDropdownMenuClose}
            PaperProps={{
              style: {
                maxHeight: "15em",
                width: "28em",
              },
            }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            {FrontendCtrCategoryOptions.map((item) => (
              <MenuItem key={item} selected={item === selectedCtrCategory} onClick={() => onCtrCategoryChange(item)}>
                {item}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ConfigurationCtrPopup;
