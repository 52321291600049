import TimesheetFormEmployeeInfoStyles from "./TimesheetFormEmployeeInfoStyles.module.css";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import { FC, useEffect, useMemo } from "react";
import { DefaultAvatar } from "../../../../../../../../ui/defaultavatar/DefaultAvatar.tsx";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { FrontendEmployeeDetailedType } from "../../../../../../../../types/apicallstypes/EmployeesApiTypes.ts";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { useEpcmApiEmployees } from "../../../../../../../../apicalls/employees/useEpcmApiEmployees.ts";
import { Skeleton } from "@mui/material";

interface TimesheetFormEmployeeInfoProps {
  employeeId: number;
  gridRowSpanNum: number;
}
const TimesheetFormEmployeeInfo: FC<TimesheetFormEmployeeInfoProps> = ({ employeeId, gridRowSpanNum }) => {
  const { getEmployee } = useEpcmApiEmployees();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const employeeQuery = useQuery({
    queryKey: [QueryNames.Employees, employeeId],
    queryFn: () => getEmployee(employeeId, DetailLevel.DETAILED),
    enabled: isAuthorized,
    select: (data) => data as FrontendEmployeeDetailedType,
  });

  const employeeData = useMemo(() => {
    return employeeQuery.data ?? undefined;
  }, [employeeQuery]);

  const groupTimesheetEmployeeSkeletonLoader = (
    <div
      className={classNames(TimesheetFormEmployeeInfoStyles.container, GlobalStyles.flex, GlobalStyles.gap075)}
      style={{
        gridRow: "auto / span " + gridRowSpanNum,
      }}
    >
      <Skeleton variant="circular" height={40} width={40} />
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
        <Skeleton variant="rounded" height={15} width={120} />
        <Skeleton variant="rounded" height={15} width={80} />
      </div>
      <div className={classNames(GlobalStyles.flex1)} />
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
        <Skeleton variant="rounded" height={15} width={50} />
      </div>
    </div>
  );

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Employees, employeeId] })
        .then(() => console.log(`In timesheet employees, employee with id ${employeeId}, ${QueryNames.Employees} query canceled`));
    };
  }, [queryClient, employeeId]);

  return employeeQuery.isLoading
    ? groupTimesheetEmployeeSkeletonLoader
    : employeeData && (
        <div
          className={classNames(TimesheetFormEmployeeInfoStyles.container, GlobalStyles.flex, GlobalStyles.gap075)}
          style={{
            gridRow: "auto / span " + gridRowSpanNum,
          }}
        >
          <DefaultAvatar avatarSizeInEm={2.5} avatarImgSizeInEm={1.5} />
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <div className={classNames(TimesheetFormEmployeeInfoStyles.employeeName)}>{employeeData.name}</div>
            <div className={classNames(TimesheetFormEmployeeInfoStyles.employeeSubText)}>{employeeData.nationality}</div>
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <div className={classNames(TimesheetFormEmployeeInfoStyles.employeeCode)}>{employeeData.code}</div>
          </div>
        </div>
      );
};
export default TimesheetFormEmployeeInfo;
