import { QueryModelWithCountAndData, QueryModelWithPagination } from "../../types/apicallstypes/queryCommons.ts";
import {
  FrontendGlobalCategoryType,
  FrontendGlobalCtrType,
  FrontendGlobalDisciplineType,
  FrontendGlobalLocationType,
  FrontendGlobalPositionType,
  FrontendGlobalSubPositionType,
  FrontendGlobalTradeType,
  GlobalConfigurationEndpointType,
} from "../../types/apicallstypes/ConfigurationsTypes.ts";
import {
  convertGlobalCategoriesData,
  convertGlobalCtrLimitedData,
  convertGlobalCtrsData,
  convertGlobalDisciplinesData,
  convertGlobalLocationLimitedData,
  convertGlobalLocationsData,
  convertGlobalPositionLimitedData,
  convertGlobalPositionsData,
  convertGlobalSubPositionsData,
  convertGlobalTradeLimitedData,
  convertGlobalTradesData,
  convertRolesData,
} from "../../types/apicallstypes/apicallstypesconverters/ConfigurationsApiConverter.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { getAllGlobalSubpositions } from "./getAllGlobalSubpositions.ts";
import { getAllGlobalEntity } from "./getAllGlobalEntity.ts";
import { GlobalCtrLimited } from "epcm-common/dist/Types/GlobalCtrTypes";
import { getGlobalEntity } from "./getGlobalEntity.ts";
import { FrontendNationalityLimited } from "../../types/projects/NationalityTypes.ts";
import { getAllGlobalNationalities } from "./getAllNationalities.ts";
import { ConfigurationScopeEnum } from "../../types/projects/ConfigurationTypes.ts";
import { FrontendRoleNormal } from "../../types/apicallstypes/UsersApiTypes.ts";
import { getAllConfigurationRoles } from "./getAllConfigurationRoles.ts";

interface ApiDefinition {
  getAllGlobalPositions: (
    pageOffset: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendGlobalPositionType>>;

  getAllGlobalSubpositions: (positionId: number) => Promise<FrontendGlobalSubPositionType[]>;

  getAllGlobalLocations: (
    pageOffset: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendGlobalLocationType>>;

  getAllGlobalTrades: (pageOffset: number, searchFilter?: string, pageSize?: number) => Promise<QueryModelWithPagination<FrontendGlobalTradeType>>;

  getAllGlobalCategories: (
    pageOffset: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendGlobalCategoryType>>;
  getAllGlobalDisciplines: (
    pageOffset: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendGlobalDisciplineType>>;

  getAllGlobalCtrs: (
    pageOffset: number,
    searchFilter?: string,
    pageSize?: number,
    globalConfigurationType?: GlobalConfigurationEndpointType,
  ) => Promise<QueryModelWithPagination<FrontendGlobalCtrType>>;

  getAllNationalities: (
    pageOffset: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendNationalityLimited>>;
  getAllConfigurationRoles: (scope: ConfigurationScopeEnum) => Promise<QueryModelWithCountAndData<FrontendRoleNormal>>;

  getGlobalPosition: (id: number, detailLevel?: DetailLevel) => Promise<FrontendGlobalPositionType>;
  getGlobalLocation: (id: number, detailLevel?: DetailLevel) => Promise<FrontendGlobalLocationType>;
  getGlobalTrade: (id: number, detailLevel?: DetailLevel) => Promise<FrontendGlobalTradeType>;
  getGlobalCategory: (id: number, detailLevel?: DetailLevel) => Promise<FrontendGlobalCategoryType>;
  getGlobalDiscipline: (id: number, detailLevel?: DetailLevel) => Promise<FrontendGlobalDisciplineType>;
  getGlobalCtr: (id: number, detailLevel?: DetailLevel) => Promise<FrontendGlobalCtrType>;
}

export const useEpcmApiConfigurations = (): ApiDefinition => {
  const getAllGlobalPositionsConverted = async (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendGlobalPositionType>> => {
    const globalPositionsData = await getAllGlobalEntity(GlobalConfigurationEndpointType.Positions, pageOffset, searchFilter, pageSize);
    return {
      data: convertGlobalPositionsData(globalPositionsData.data),
      nextPage: globalPositionsData.nextPage,
      totalCount: globalPositionsData.totalCount,
    };
  };

  const getAllGlobalSubpositionsConverted = async (positionId: number): Promise<FrontendGlobalSubPositionType[]> => {
    const globalSubpositionsData = await getAllGlobalSubpositions(positionId);

    return convertGlobalSubPositionsData(globalSubpositionsData);
  };

  const getAllGlobalLocationsConverted = async (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendGlobalLocationType>> => {
    const globalLocationsData = await getAllGlobalEntity(GlobalConfigurationEndpointType.Locations, pageOffset, searchFilter, pageSize);

    return {
      data: convertGlobalLocationsData(globalLocationsData.data),
      nextPage: globalLocationsData.nextPage,
      totalCount: globalLocationsData.totalCount,
    };
  };

  const getAllGlobalTradesConverted = async (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendGlobalTradeType>> => {
    const globalTradesData = await getAllGlobalEntity(GlobalConfigurationEndpointType.Trades, pageOffset, searchFilter, pageSize);

    return {
      data: convertGlobalTradesData(globalTradesData.data),
      nextPage: globalTradesData.nextPage,
      totalCount: globalTradesData.totalCount,
    };
  };

  const getAllGlobalCategoriesConverted = async (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendGlobalCategoryType>> => {
    const globalCategoriesData = await getAllGlobalEntity(GlobalConfigurationEndpointType.Categories, pageOffset, searchFilter, pageSize);
    return {
      data: convertGlobalCategoriesData(globalCategoriesData.data),
      nextPage: globalCategoriesData.nextPage,
      totalCount: globalCategoriesData.totalCount,
    };
  };

  const getAllGlobalDisciplinesConverted = async (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendGlobalDisciplineType>> => {
    const globalDisciplinesData = await getAllGlobalEntity(GlobalConfigurationEndpointType.Disciplines, pageOffset, searchFilter, pageSize);
    return {
      data: convertGlobalDisciplinesData(globalDisciplinesData.data),
      nextPage: globalDisciplinesData.nextPage,
      totalCount: globalDisciplinesData.totalCount,
    };
  };

  const getAllGlobalCtrsConverted = async (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendGlobalCtrType>> => {
    const globalCtrsData = await getAllGlobalEntity(GlobalConfigurationEndpointType.Ctrs, pageOffset, searchFilter, pageSize);

    return {
      data: convertGlobalCtrsData(globalCtrsData.data as GlobalCtrLimited[]),
      nextPage: globalCtrsData.nextPage,
      totalCount: globalCtrsData.totalCount,
    };
  };

  const getAllGlobalNationalitiesConverted = async (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendNationalityLimited>> => {
    const nationalitiesData = await getAllGlobalNationalities(pageOffset, searchFilter, pageSize);

    return {
      data: nationalitiesData.data,
      nextPage: nationalitiesData.nextPage,
      totalCount: nationalitiesData.totalCount,
    };
  };

  const getAllConfigurationRolesConverted = async (scope: ConfigurationScopeEnum): Promise<QueryModelWithCountAndData<FrontendRoleNormal>> => {
    const response = await getAllConfigurationRoles(scope);
    return {
      count: response.count,
      data: convertRolesData(response.data),
    };
  };

  const getGlobalPositionConverted = async (id: number, detailLevel?: DetailLevel): Promise<FrontendGlobalPositionType> => {
    const globalPositionData = await getGlobalEntity(GlobalConfigurationEndpointType.Positions, id, detailLevel);
    return convertGlobalPositionLimitedData(globalPositionData);
  };

  const getGlobalLocationConverted = async (id: number, detailLevel?: DetailLevel): Promise<FrontendGlobalLocationType> => {
    const globalLocationsData = await getGlobalEntity(GlobalConfigurationEndpointType.Locations, id, detailLevel);
    return convertGlobalLocationLimitedData(globalLocationsData);
  };
  const getGlobalTradeConverted = async (id: number, detailLevel?: DetailLevel): Promise<FrontendGlobalTradeType> => {
    const globalTradesData = await getGlobalEntity(GlobalConfigurationEndpointType.Trades, id, detailLevel);
    return convertGlobalTradeLimitedData(globalTradesData);
  };
  const getGlobalCategoryConverted = async (id: number, detailLevel?: DetailLevel): Promise<FrontendGlobalCategoryType> => {
    const globalPositionData = await getGlobalEntity(GlobalConfigurationEndpointType.Categories, id, detailLevel);
    return convertGlobalTradeLimitedData(globalPositionData);
  };
  const getGlobalDisciplineConverted = async (id: number, detailLevel?: DetailLevel): Promise<FrontendGlobalDisciplineType> => {
    const globalPositionData = await getGlobalEntity(GlobalConfigurationEndpointType.Disciplines, id, detailLevel);
    return convertGlobalTradeLimitedData(globalPositionData);
  };
  const getGlobalCtrConverted = async (id: number, detailLevel?: DetailLevel): Promise<FrontendGlobalCtrType> => {
    const globalCtrData = await getGlobalEntity(GlobalConfigurationEndpointType.Ctrs, id, detailLevel);
    return convertGlobalCtrLimitedData(globalCtrData);
  };

  return {
    getAllGlobalPositions: getAllGlobalPositionsConverted,
    getAllGlobalSubpositions: getAllGlobalSubpositionsConverted,
    getAllGlobalLocations: getAllGlobalLocationsConverted,
    getAllGlobalTrades: getAllGlobalTradesConverted,
    getAllGlobalCategories: getAllGlobalCategoriesConverted,
    getAllGlobalDisciplines: getAllGlobalDisciplinesConverted,
    getAllGlobalCtrs: getAllGlobalCtrsConverted,
    getAllNationalities: getAllGlobalNationalitiesConverted,
    getAllConfigurationRoles: getAllConfigurationRolesConverted,
    getGlobalPosition: getGlobalPositionConverted,
    getGlobalLocation: getGlobalLocationConverted,
    getGlobalTrade: getGlobalTradeConverted,
    getGlobalCategory: getGlobalCategoryConverted,
    getGlobalDiscipline: getGlobalDisciplineConverted,
    getGlobalCtr: getGlobalCtrConverted,
  };
};
