import { PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import MmrActionsPopupStyles from "./MmrActionsPopup.module.css";
import classNames from "classnames";
import downloadTemplateIcon from "../../../../assets/images/spreadsheet-download.svg";
import uploadTemplateIcon from "../../../../assets/images/spreadsheet-upload.svg";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import { UploadBulkMmrPopup } from "../uploadbulkmmrpopup/UploadBulkMmrPopup.tsx";
import useEpcmProcessFileEvents from "../../../../utils/useProcessFileEvents.ts";
import { FrontendFileAction } from "../../../../types/projects/FileTypes.ts";
import { useParams } from "react-router-dom";
import { useNotificationContext } from "../../../../ui/globalnotificationcontext/GlobalNotificationContext.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { ApiResponseTypeEnum } from "../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjectEmployee } from "../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import { ProjectAction } from "../../../../types/Roles.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ButtonsWithHoverEnum } from "../../../../types/projects/ProjectsTypes.ts";
import InfoMessageIcon from "../../../../assets/images/info-message-icon.svg";
import { Fade, Tooltip } from "@mui/material";

export interface MmrActionsPopupProps extends PopupType {}

export const MmrActionsPopup: FC<MmrActionsPopupProps> = ({ isOpen, closeFn, headerText, secondaryHeaderText }) => {
  const [currentlyHovered, setCurrentlyHovered] = useState<ButtonsWithHoverEnum | null>(null);
  const { projectId } = useParams();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { addNotification, updateNotification } = useNotificationContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getBulkMmrExport, getMmrRegisterExport } = useEpcmApiProjectEmployee();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { processFileEvents: processFileEventsMmrExport } = useEpcmProcessFileEvents(
    FrontendFileAction.EXPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
  );
  const { processFileEvents: processFileEventsExportMmrRegistry } = useEpcmProcessFileEvents(
    FrontendFileAction.EXPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
  );
  // const { processFileEvents: processFileEventsExportReport } = useEpcmProcessFileEvents(
  //   FrontendFileAction.EXPORT,
  //   projectId!,
  //   isAuthorized,
  //   addNotification,
  //   updateNotification,
  //   onOpenResponseAlertPopup,
  //   setResponseType,
  //   setResponseObject,
  // );

  const startFileEventsListener = async (fileId: string) => {
    await processFileEventsMmrExport(fileId);
  };
  const startFileEventsListenerMmrRegistry = async (fileId: string) => {
    await processFileEventsExportMmrRegistry(fileId);
  };

  const exportBulkMmrToFile = useCallback(async () => {
    getBulkMmrExport(parseInt(projectId!))
      .then((value) => {
        void startFileEventsListener(value.fileId);
      })
      .catch((error) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      });
  }, [projectId, getBulkMmrExport, onOpenResponseAlertPopup, setResponseType, setResponseObject, startFileEventsListener]);

  const exportBulkMmrRegistryToFile = useCallback(async () => {
    getMmrRegisterExport(parseInt(projectId!))
      .then((value) => {
        startFileEventsListenerMmrRegistry(value.fileId);
      })
      .catch((error) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      });
  }, [projectId, getMmrRegisterExport, onOpenResponseAlertPopup, setResponseType, setResponseObject, startFileEventsListenerMmrRegistry]);

  const canUpdateProjectEmployee = canPerformProjectAction(ProjectAction.ProjectEmployeeUpdate);

  const MMR_BUTTON_DESCRIPTIONS: Readonly<Record<ButtonsWithHoverEnum, string>> = {
    [ButtonsWithHoverEnum.IMPORT_BULK_MMR]: "Upload a file with MMRs for employees. Creates new employees if not found.",
    [ButtonsWithHoverEnum.EXPORT_BULK_MMR]:
      "Download a template to enter MMR details for employees. Add entries to register employees with their MMR.",
    [ButtonsWithHoverEnum.EXPORT_MMR_REGISTRY]: "Download a full registry of all employees and their MMRs. This file is for reference only.",
  } as const;

  return (
    <PopupStructure popupButtons={[]} isOpen={isOpen} closeFn={closeFn} headerText={headerText} secondaryHeaderText={secondaryHeaderText}>
      <div className={classNames(MmrActionsPopupStyles.contentContainer, GlobalStyles.flex, GlobalStyles.gap)}>
        {canUpdateProjectEmployee && (
          <div
            className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
            onMouseEnter={() => setCurrentlyHovered(ButtonsWithHoverEnum.IMPORT_BULK_MMR)}
            onMouseLeave={() => setCurrentlyHovered(null)}
            onClick={() => {
              onOpenPopup(ProjectsPagePopups.bulkMmrUpload, popupHandler);
            }}
          >
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(MmrActionsPopupStyles.templateIconImg)} src={uploadTemplateIcon} alt="downloadTemplate" />
            </div>
            <div
              className={classNames(
                MmrActionsPopupStyles.text,
                currentlyHovered === ButtonsWithHoverEnum.IMPORT_BULK_MMR && MmrActionsPopupStyles.textHovered,
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
                GlobalStyles.gap075,
              )}
            >
              {"Import MMRs"}
              <Tooltip title={MMR_BUTTON_DESCRIPTIONS[ButtonsWithHoverEnum.IMPORT_BULK_MMR]} arrow TransitionComponent={Fade}>
                <div className={classNames(MmrActionsPopupStyles.infoIcon, GlobalStyles.flex, GlobalStyles.centerVertical)}>
                  <img src={InfoMessageIcon} className={classNames(MmrActionsPopupStyles.infoImg)} alt="info message icon" />
                </div>
              </Tooltip>
            </div>
          </div>
        )}
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.elementWithCursor)}
          onMouseEnter={() => setCurrentlyHovered(ButtonsWithHoverEnum.EXPORT_BULK_MMR)}
          onMouseLeave={() => setCurrentlyHovered(null)}
          onClick={() => {
            void exportBulkMmrToFile();
          }}
        >
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(MmrActionsPopupStyles.templateIconImgRotated)} src={downloadTemplateIcon} alt="downloadTemplate" />
          </div>
          <div
            className={classNames(
              MmrActionsPopupStyles.text,
              currentlyHovered === ButtonsWithHoverEnum.EXPORT_BULK_MMR && MmrActionsPopupStyles.textHovered,
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
            )}
          >
            {"Export MMRs Template "}
            <Tooltip title={MMR_BUTTON_DESCRIPTIONS[ButtonsWithHoverEnum.EXPORT_BULK_MMR]} arrow TransitionComponent={Fade}>
              <div className={classNames(MmrActionsPopupStyles.infoIcon)}>
                <img src={InfoMessageIcon} className={classNames(MmrActionsPopupStyles.infoImg)} alt="info message icon" />
              </div>
            </Tooltip>
          </div>
        </div>
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.elementWithCursor)}
          onMouseEnter={() => setCurrentlyHovered(ButtonsWithHoverEnum.EXPORT_MMR_REGISTRY)}
          onMouseLeave={() => setCurrentlyHovered(null)}
          onClick={() => {
            void exportBulkMmrRegistryToFile();
          }}
        >
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(MmrActionsPopupStyles.templateIconImgRotated)} src={downloadTemplateIcon} alt="downloadTemplate" />
          </div>
          <div
            className={classNames(
              MmrActionsPopupStyles.text,
              currentlyHovered === ButtonsWithHoverEnum.EXPORT_MMR_REGISTRY && MmrActionsPopupStyles.textHovered,
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
            )}
          >
            {"Export MMR Registry"}
            <Tooltip title={MMR_BUTTON_DESCRIPTIONS[ButtonsWithHoverEnum.EXPORT_MMR_REGISTRY]} arrow TransitionComponent={Fade}>
              <div className={classNames(MmrActionsPopupStyles.infoIcon, GlobalStyles.flex, GlobalStyles.centerVertical)}>
                <img src={InfoMessageIcon} className={classNames(MmrActionsPopupStyles.infoImg)} alt="info message icon" />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      {popupHandler.get(ProjectsPagePopups.bulkMmrUpload)!.isOpen && (
        <UploadBulkMmrPopup
          isOpen={popupHandler.get(ProjectsPagePopups.bulkMmrUpload)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.bulkMmrUpload, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.bulkMmrUpload)}
          secondaryHeaderText={"Please, select an action"}
        />
      )}

      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();

            if (responseType === ApiResponseTypeEnum.success) {
              closeFn();
            }
          }}
          responseType={responseType}
          responseObject={responseObject}
        />
      )}
    </PopupStructure>
  );
};
