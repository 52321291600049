import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../store/use-impersonation-store.ts";
import { FC, ReactNode, useEffect, useMemo } from "react";
import { useProjectRoleStore } from "../store/use-project-role-store.ts";
import { useParams } from "react-router-dom";
import { useEpcmApiProjectPermissions } from "../apicalls/projects/projectpermissions/useEpcmApiProjectPermissions.ts";
import GlobalStyles from "../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import { Skeleton } from "@mui/material";

interface ProjectRolesAuthenticatorProps {
  children: ReactNode;
}

const ProjectRolesAuthenticator: FC<ProjectRolesAuthenticatorProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const { getUserProjectRoles } = useEpcmApiProjectPermissions();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const projectRolesState = useProjectRoleStore((state) => state);
  const { projectId } = useParams();

  const projectRolesQuery = useQuery({
    queryKey: [QueryNames.UserProjectRoles, projectId],
    queryFn: () => getUserProjectRoles(parseInt(projectId!)),
    enabled: isAuthorized,
  });
  const isProjectRolesQueryLoading = useMemo(() => {
    return projectRolesQuery.isLoading;
  }, [projectRolesQuery.isLoading]);

  const projectRolesData = useMemo(() => {
    return projectRolesQuery.data;
  }, [projectRolesQuery.data]);

  const rolesSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.overflowHiddenFullHeight, GlobalStyles.gap)}>
      <Skeleton variant="rectangular" height={150} width={"100%"} />
      <Skeleton variant="rectangular" height={150} width={"100%"} />
      <Skeleton variant="rectangular" height={150} width={"100%"} />
      <Skeleton variant="rectangular" height={150} width={"100%"} />
      <Skeleton variant="rectangular" height={150} width={"100%"} />
    </div>
  );

  useEffect(() => {
    if (projectRolesData && JSON.stringify(projectRolesData) !== JSON.stringify(projectRolesState.projectRoles)) {
      projectRolesState.setProjectRoles(projectRolesData.map((role) => role));
    }
  }, [projectRolesData, projectRolesState]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.UserProjectRoles, projectId] }).then(() => {
        console.log(`Query ${QueryNames.UserProjectRoles} for project ${projectId} canceled`);
      });
    };
  }, [queryClient, projectId]);

  return <>{isProjectRolesQueryLoading ? rolesSkeletonLoader : children}</>;
};

export default ProjectRolesAuthenticator;
