import classNames from "classnames";
import TimesheetFormCtrRecordListStyles from "../timesheetformctrrecordlist/TimesheetFormCtrRecordList.module.css";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import { FrontendDayTypeOptionsEnum } from "../../../../../../../../types/apicallstypes/CalendarApiTypes.ts";
import { NumericFormat } from "react-number-format";
import { ClickAwayListener, Fade, Skeleton, Tooltip } from "@mui/material";
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { FrontendCtrLimitedType, FrontendMmrCtrsBody } from "../../../../../../../../types/apicallstypes/CtrTypes.ts";
import {
  FrontendTimesheetFormHeaderDateType,
  FrontendTimesheetProjectEmployeeActiveMMRType,
  FrontendTimesheetRecordType,
} from "../../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { useParams } from "react-router-dom";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { QueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjectsUtils } from "../../../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import arrowDown from "../../../../../../../../assets/images/arrow-down-gray-light-fill.svg";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../../../use-projects-popups.ts";
import { SelectCtrCodePopup } from "../../../../../../popups/selectctrcodepopup/SelectCtrCodePopup.tsx";
import { CtrTooltipOptionHoverEnum, FrontendMmrCalendarDayRecords } from "../../../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import TimesheetFormDateRecordCtrInfoStyles from "../timesheetformdaterecord/timesheetformdaterecordctrinfo/TimesheetFormDateRecordCtrInfoStyles.module.css";
import eraseIconHovered from "../../../../../../../../assets/images/eraser-red-icon.svg";
import eraseIcon from "../../../../../../../../assets/images/eraser-dark-grey-icon.svg";
import deleteIconDisabled from "../../../../../../../../assets/images/delete-bin-light-grey.svg";
import deleteIconHovered from "../../../../../../../../assets/images/delete-bin-red.svg";
import deleteIcon from "../../../../../../../../assets/images/delete-bin-dark-grey.svg";
import { EpcmTooltip } from "../../../../../../../../ui/epcmtooltip/EpcmTooltip.tsx";
import settingsIcon from "../../../../../../../../assets/images/setting-dots-grey.svg";

interface TimesheetFormCtrRecordProps {
  ctrId: number | null;
  timesheetGroupId: number;
  timesheetId: number;
  timesheetEmployeeId: number;
  projectEmployeeId: number;
  ctrsArrayLength: number;
  onAddCtr: (mmrId: number) => void;
  mmrCtrRecords: { [mmrId: number]: FrontendMmrCtrsBody[] };
  setMmrCtrRecords: Dispatch<SetStateAction<{ [mmrId: number]: FrontendMmrCtrsBody[] }>>;
  onWorkHoursChange: (ctrId: number, calendarDayId: number, workedHours: number, newHours: number) => void;
  MMRItem: FrontendTimesheetProjectEmployeeActiveMMRType;
  applicableDates: FrontendMmrCalendarDayRecords;
  groupTimesheetDates: FrontendTimesheetFormHeaderDateType[];
  isEditMode: boolean;
  itemIndex: number;
  hoursPerDayRecordData: {
    [calendarDayId: number]: FrontendTimesheetRecordType;
  };
}

const TimesheetFormCtrRecord: FC<TimesheetFormCtrRecordProps> = ({
  ctrId,
  timesheetGroupId,
  timesheetId,
  timesheetEmployeeId,
  projectEmployeeId,
  ctrsArrayLength,
  onAddCtr,
  mmrCtrRecords,
  setMmrCtrRecords,
  onWorkHoursChange,
  MMRItem,
  applicableDates,
  groupTimesheetDates,
  isEditMode,
  itemIndex,
  hoursPerDayRecordData,
}) => {
  const { projectId } = useParams();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [isWorkedHoursInputFocused, setIsWorkedHoursInputFocused] = useState<boolean>(false);
  const [ctrTooltipOptionHovered, setCtrTooltipOptionHovered] = useState<CtrTooltipOptionHoverEnum | null>(null);
  const { getGroupTimesheetActiveMmrCtrItemRecords } = useEpcmApiProjectsTimesheetsGroups();
  const { getProjectCtr } = useEpcmApiProjectsUtils();

  const isFinalIndex = (index: number) => {
    return index === ctrsArrayLength - 1;
  };

  const mmrCtrCodeRecordsQuery = useQuery({
    queryKey: [
      QueryNames.ProjectTimesheetGroupActiveMmrCtrItemRecords,
      parseInt(projectId!),
      timesheetGroupId,
      timesheetId,
      timesheetEmployeeId,
      projectEmployeeId,
      MMRItem.mmrId,
      ctrId as number,
    ],
    queryFn: () =>
      getGroupTimesheetActiveMmrCtrItemRecords(
        parseInt(projectId!),
        timesheetGroupId,
        timesheetId,
        timesheetEmployeeId,
        projectEmployeeId,
        MMRItem.mmrId,
        ctrId as number,
      ),
    enabled: isAuthorized && ctrId != null,
  });

  const mmrCtrCodeRecordsData = useMemo(() => {
    return mmrCtrCodeRecordsQuery.data?.records;
  }, [mmrCtrCodeRecordsQuery.data]);

  const recordCtrQuery = useQuery({
    queryKey: [QueryNames.ProjectCtrs, projectId, ctrId, DetailLevel.LIMITED],
    queryFn: () => getProjectCtr(parseInt(projectId!), ctrId as number, DetailLevel.LIMITED),

    enabled: isAuthorized && ctrId != null,
    select: (data) => data as FrontendCtrLimitedType,
  });

  const recordCtrData = useMemo(() => {
    return recordCtrQuery.data ?? undefined;
  }, [recordCtrQuery]);

  const ctrCodesInUseForThisMmr = useMemo(() => {
    //Filter to not include null values and i need a number array, but for this Mmr ONLY

    return Object.values(mmrCtrRecords[MMRItem.mmrId] ?? [])
      .filter((ctr) => ctr.ctrId != null)
      .map((ctr) => ctr.ctrId as number);
  }, [mmrCtrRecords, MMRItem.mmrId]);

  const onUnselectCtr = useCallback(() => {
    setMmrCtrRecords((prevMmrCtrRecords) => {
      const updatedMmrCtrRecords = { ...prevMmrCtrRecords };
      const ctrRecords = updatedMmrCtrRecords[MMRItem.mmrId] || [];

      // Find the index of the record with the current ctrId
      const recordIndex = ctrRecords.findIndex((record) => record.ctrId === ctrId);
      if (recordIndex !== -1) {
        // Set the ctrId to null and reset the workedHours for each calendarDay
        ctrRecords[recordIndex] = {
          ...ctrRecords[recordIndex],
          ctrId: null, // Reset ctrId to null
          calendarDayHours: ctrRecords[recordIndex].calendarDayHours.map((day) => ({
            ...day,
            workedHours: 0, // Reset workedHours to zero
          })),
        };
      }
      return { ...updatedMmrCtrRecords, [MMRItem.mmrId]: ctrRecords };
    });
  }, [setMmrCtrRecords, ctrId, MMRItem.mmrId]);

  const ctrSettingsTooltip = (
    <div className={classNames(TimesheetFormDateRecordCtrInfoStyles.settingsTooltipContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.gap, !!ctrId && GlobalStyles.elementWithCursor)}
        onMouseEnter={() => setCtrTooltipOptionHovered(CtrTooltipOptionHoverEnum.clear)}
        onMouseLeave={() => setCtrTooltipOptionHovered(null)}
        onClick={() => !!ctrId && onClearCtrInput()}
      >
        <div className={classNames(!ctrId && TimesheetFormDateRecordCtrInfoStyles.settingsTooltipIconDisabled, GlobalStyles.centerVertical)}>
          <img
            className={classNames(TimesheetFormDateRecordCtrInfoStyles.settingsTooltipIconImg)}
            src={ctrTooltipOptionHovered === CtrTooltipOptionHoverEnum.clear && !!ctrId ? eraseIconHovered : eraseIcon}
            alt="erase"
          />
        </div>
        <div
          className={classNames(
            TimesheetFormDateRecordCtrInfoStyles.settingsToolTipText,
            !ctrId && TimesheetFormDateRecordCtrInfoStyles.settingsToolTipTextDisabled,
            GlobalStyles.centerVertical,
          )}
        >
          {"Clear CTR"}
        </div>
      </div>
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.gap, ctrsArrayLength > 0 && GlobalStyles.elementWithCursor)}
        onMouseEnter={() => setCtrTooltipOptionHovered(CtrTooltipOptionHoverEnum.delete)}
        onMouseLeave={() => setCtrTooltipOptionHovered(null)}
        onClick={() => ctrsArrayLength > 0 && onDeleteCtrInput()}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img
            className={classNames(TimesheetFormDateRecordCtrInfoStyles.settingsTooltipIconImg)}
            src={
              !(ctrsArrayLength > 0)
                ? deleteIconDisabled
                : ctrTooltipOptionHovered === CtrTooltipOptionHoverEnum.delete
                  ? deleteIconHovered
                  : deleteIcon
            }
            alt="delete"
          />
        </div>
        <div
          className={classNames(
            TimesheetFormDateRecordCtrInfoStyles.settingsToolTipText,
            !(ctrsArrayLength > 0) && TimesheetFormDateRecordCtrInfoStyles.settingsToolTipTextDisabled,
            GlobalStyles.centerVertical,
          )}
        >
          {"Delete CTR"}
        </div>
      </div>
    </div>
  );

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };
  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };
  const onDeleteCtrInput = useCallback(() => {
    setMmrCtrRecords((prevMmrCtrRecords) => {
      const updatedMmrCtrRecords = { ...prevMmrCtrRecords };
      const ctrsArray = updatedMmrCtrRecords[MMRItem.mmrId] || [];
      const ctrIndex = ctrsArray.findIndex((ctr) => ctr.ctrId === ctrId);
      if (ctrIndex !== -1) {
        ctrsArray.splice(ctrIndex, 1);
      }
      return { ...updatedMmrCtrRecords, [MMRItem.mmrId]: ctrsArray };
    });
  }, [setMmrCtrRecords, MMRItem.mmrId, ctrId]);

  const onClearCtrInput = useCallback(() => {
    setMmrCtrRecords((prevMmrCtrRecords) => {
      const updatedMmrCtrRecords = { ...prevMmrCtrRecords };
      const ctrsArray = updatedMmrCtrRecords[MMRItem.mmrId] || [];
      const ctrIndex = ctrsArray.findIndex((ctr) => ctr.ctrId === ctrId);
      if (ctrIndex !== -1) {
        ctrsArray[ctrIndex] = { ctrId: null, calendarDayHours: [] };
      }
      return { ...updatedMmrCtrRecords, [MMRItem.mmrId]: ctrsArray };
    });
  }, [setMmrCtrRecords, MMRItem.mmrId, ctrId]);

  useEffect(() => {
    if (mmrCtrCodeRecordsData) {
      setMmrCtrRecords((prevMmrCtrRecords) => {
        const updatedMmrCtrRecords = { ...prevMmrCtrRecords };
        const ctrsArray = updatedMmrCtrRecords[MMRItem.mmrId] || [];

        const ctrIndex = ctrsArray.findIndex((ctr) => ctr.ctrId === ctrId);
        if (ctrIndex !== -1) {
          //Filter and make sure the day is working daY

          ctrsArray[ctrIndex].calendarDayHours = mmrCtrCodeRecordsData
            .filter((record) => {
              const calendarDay = MMRItem.calendars.find((calendar) => calendar.id === record.calendarDayId);
              return calendarDay?.dayType === FrontendDayTypeOptionsEnum.WORKING_DAY;
            })
            .map((record) => ({
              calendarDayId: record.calendarDayId,
              workedHours: record.workedHours,
            }));
        }
        return updatedMmrCtrRecords;
      });
    }
  }, [mmrCtrCodeRecordsData, setMmrCtrRecords, MMRItem.mmrId, ctrId, MMRItem.calendars]);

  useEffect(() => {
    return () => {
      ctrId != null &&
        queryClient
          .cancelQueries({
            queryKey: [
              QueryNames.ProjectTimesheetGroupActiveMmrCtrItemRecords,
              parseInt(projectId!),
              timesheetGroupId,
              timesheetId,
              timesheetEmployeeId,
              projectEmployeeId,
              MMRItem.mmrId,
              ctrId,
            ],
          })
          .then(() => {
            console.log(
              `In timesheets-groups component, ${QueryNames.ProjectTimesheetGroupActiveMmrCtrItemRecords} for ctrId:${ctrId} query cancelled`,
            );
          });
    };
  }, [projectId, timesheetGroupId, timesheetId, timesheetEmployeeId, projectEmployeeId, MMRItem.mmrId, ctrId, queryClient]);

  useEffect(() => {
    return () => {
      ctrId &&
        queryClient
          .cancelQueries({ queryKey: [QueryNames.ProjectCtrs, projectId, ctrId, DetailLevel.LIMITED] })
          .then(() => console.log(`In timesheet employees ctrs, ctr with id ${ctrId}, ${QueryNames.ProjectCtrs} query canceled`));
    };
  }, [queryClient, projectId, ctrId]);

  return (
    <React.Fragment key={ctrId ? `mmrId:${MMRItem.mmrId} ctr-${ctrId}` : `ctr-new-${mmrCtrRecords[MMRItem.mmrId].length}`}>
      <div
        className={classNames(
          TimesheetFormCtrRecordListStyles.container,
          isFinalIndex(itemIndex) && TimesheetFormCtrRecordListStyles.additionalPaddingCtr,
        )}
      >
        <EpcmTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={isTooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={ctrSettingsTooltip}
          arrow
          placement={"right"}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
            {ctrId != null && recordCtrQuery.isLoading ? (
              <Skeleton variant="rounded" height={20} width={"100%"} />
            ) : (
              <div
                className={classNames(
                  TimesheetFormCtrRecordListStyles.ctrCodeContainer,
                  !isEditMode && TimesheetFormCtrRecordListStyles.viewOnlyCell,
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.gap025,
                  GlobalStyles.flex1,
                )}
                onClick={() => isEditMode && onOpenPopup(ProjectsPagePopups.selectCtrCode, popupHandler)}
              >
                <div className={classNames(TimesheetFormCtrRecordListStyles.ctrInfoText, GlobalStyles.flex)}>
                  {ctrId ? recordCtrData && recordCtrData.code : "CTR:"}
                </div>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
                {isEditMode && (
                  <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.centerHorizontal, GlobalStyles.flex)}>
                    <img className={classNames(TimesheetFormCtrRecordListStyles.ctrSelectArrowIconImg)} src={arrowDown} alt="arrow" />
                  </div>
                )}
              </div>
            )}
            {isEditMode && (ctrsArrayLength > 0 || !!ctrId) && (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div onClick={handleTooltipOpen}>
                  <img className={classNames(TimesheetFormDateRecordCtrInfoStyles.settingsIconImg)} src={settingsIcon} alt="settings" />
                </div>
              </ClickAwayListener>
            )}
          </div>
        </EpcmTooltip>
        {/* Conditionally render the plus button only for the last CTR */}
        {itemIndex === ctrsArrayLength - 1 && (
          <div
            className={classNames(
              TimesheetFormCtrRecordListStyles.addCtrTextDiv,
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.centerVertical,
              isEditMode && GlobalStyles.elementWithCursor,
              !isEditMode && TimesheetFormCtrRecordListStyles.hideDiv,
            )}
            onClick={() => isEditMode && onAddCtr(MMRItem.mmrId)}
          >
            {isEditMode ? "+" : "-"}
          </div>
        )}
      </div>
      {groupTimesheetDates.map((eachDayOfTheWeek) => {
        const timesheetCalendarInfo = MMRItem.calendars.find((calendar) => calendar.id === eachDayOfTheWeek.id);
        const calendarDayHours = mmrCtrRecords[MMRItem.mmrId]
          ?.find((ctr) => ctr.ctrId === ctrId)
          ?.calendarDayHours.find((day) => day.calendarDayId === eachDayOfTheWeek.id);

        const isDayApplicable = applicableDates[eachDayOfTheWeek.id]?.isApplicable ?? false;
        // Update calendar day but also allow for new entries
        const calendarDayId = (calendarDayHours?.calendarDayId ?? eachDayOfTheWeek.id === eachDayOfTheWeek.id) ? eachDayOfTheWeek.id : undefined;
        // Computation for each day
        const hasInvalidDayWorkedHoursSum = (() => {
          //Also return if the day has one 0 record

          const maxHoursForTheSpecificDay =
            hoursPerDayRecordData[eachDayOfTheWeek.id]?.maxHours + hoursPerDayRecordData[eachDayOfTheWeek.id]?.overtimeHours;

          const currentMmrCtrRecordWorkedHours = mmrCtrRecords[MMRItem.mmrId]
            ?.filter((ctr) => ctr.ctrId === ctrId && ctr.calendarDayHours.some((day) => day.calendarDayId === eachDayOfTheWeek.id))
            .find((ctr) => ctr.ctrId === ctrId)?.calendarDayHours;
          //Also account for null values

          const currentMmrCtrRecordWorkedHoursSum = currentMmrCtrRecordWorkedHours
            ?.filter((day) => day.calendarDayId === eachDayOfTheWeek.id)
            .reduce((acc, day) => acc + day.workedHours, 0);

          return currentMmrCtrRecordWorkedHoursSum ? currentMmrCtrRecordWorkedHoursSum > maxHoursForTheSpecificDay : false;
        })();

        return (
          <div
            key={`ctr-${ctrId ?? "new"}-date-${eachDayOfTheWeek.id}`}
            className={classNames(
              TimesheetFormCtrRecordListStyles.dayCell,
              eachDayOfTheWeek.id !== groupTimesheetDates[0].id && TimesheetFormCtrRecordListStyles.dayCellBorder,
              isFinalIndex(itemIndex) && TimesheetFormCtrRecordListStyles.additionalPaddingCtrCellValue,
            )}
          >
            {timesheetCalendarInfo &&
              (timesheetCalendarInfo.dayType === FrontendDayTypeOptionsEnum.WORKING_DAY ? (
                isDayApplicable ? (
                  <div className={classNames(TimesheetFormCtrRecordListStyles.dayCellContainer)}>
                    {ctrId != null ? (
                      recordCtrQuery && recordCtrQuery.isLoading ? (
                        <Skeleton variant="rounded" height={20} width={"90%"} />
                      ) : (
                        <NumericFormat
                          className={classNames(
                            TimesheetFormCtrRecordListStyles.ctrInfoContainer,
                            TimesheetFormCtrRecordListStyles.ctrInfoText,
                            hasInvalidDayWorkedHoursSum && !isWorkedHoursInputFocused && TimesheetFormDateRecordCtrInfoStyles.workedHoursInputInvalid,
                            !isEditMode && TimesheetFormCtrRecordListStyles.viewOnlyCell,
                            isEditMode && GlobalStyles.elementWithCursor,
                          )}
                          // value={ctrRecord?.workedHours ?? 0}
                          value={
                            !calendarDayHours || calendarDayHours.workedHours === undefined || calendarDayHours.workedHours === null
                              ? "--"
                              : calendarDayHours.workedHours
                          }
                          placeholder={isDayApplicable ? "--" : "0"}
                          onValueChange={(values) => {
                            if (values.floatValue !== undefined) {
                              if (ctrId != null && calendarDayId) {
                                onWorkHoursChange(MMRItem.mmrId, ctrId, calendarDayId, values.floatValue);
                              }
                            } else {
                              if (ctrId != null && calendarDayId) {
                                onWorkHoursChange(MMRItem.mmrId, ctrId, calendarDayId, -1);
                              }
                            }
                          }}
                          //     // ctrRecord &&
                          //     // setCtrRecords((currentState) => {
                          //     //     const ctrRecordsClone = currentState.slice();
                          //     //     ctrRecordsClone[ctrRecordsClone.itemIndexOf(ctrRecord)] = {
                          //     //         ...ctrRecord,
                          //     //         workedHours: values.floatValue ?? 0,
                          //     //     };
                          //     //     return ctrRecordsClone;
                          //     // });
                          // }}
                          onBlur={(event) => {
                            if (event.target && !event.target.value) {
                              event.target.value = "0";
                            }

                            setIsWorkedHoursInputFocused(false);
                          }}
                          onFocus={() => setIsWorkedHoursInputFocused(true)}
                          allowNegative={false}
                          disabled={!isEditMode}
                        />
                      )
                    ) : (
                      <Tooltip
                        className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.centerVertical)}
                        TransitionComponent={Fade}
                        title={"Select a CTR first"}
                        placement="right-start"
                        arrow
                      >
                        <div
                          className={classNames(
                            TimesheetFormCtrRecordListStyles.ctrInfoContainer,
                            TimesheetFormCtrRecordListStyles.ctrInfoText,
                            TimesheetFormCtrRecordListStyles.nullCtrDiv,
                          )}
                        >
                          {"--"}
                        </div>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  <div className={classNames(TimesheetFormCtrRecordListStyles.dayCellContainer)}>
                    <NumericFormat
                      className={classNames(
                        TimesheetFormCtrRecordListStyles.ctrInfoContainer,
                        TimesheetFormCtrRecordListStyles.ctrInfoText,
                        TimesheetFormCtrRecordListStyles.notApplicableText,
                      )}
                      disabled={true}
                      value={"--"}
                    ></NumericFormat>
                  </div>
                )
              ) : (
                <div className={classNames(TimesheetFormCtrRecordListStyles.notApplicableText)}>{"-"}</div>
              ))}
          </div>
        );
      })}
      {popupHandler.get(ProjectsPagePopups.selectCtrCode)!.isOpen && (
        <SelectCtrCodePopup
          isOpen={popupHandler.get(ProjectsPagePopups.selectCtrCode)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.selectCtrCode, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.selectCtrCode)}
          ctrId={ctrId}
          setCtrRecords={setMmrCtrRecords}
          projectEmployeeId={projectEmployeeId}
          mmrId={MMRItem.mmrId}
          ctrCodesInUse={ctrCodesInUseForThisMmr}
          onUnselectCtr={onUnselectCtr}
        />
      )}
    </React.Fragment>
  );
};

export default TimesheetFormCtrRecord;
