import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiResponseTimesheetCreationDates } from "epcm-common/dist/Types/TimesheetTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getProjectTimesheetMonthPeriods = async (
  id: number,
  timesheetGroupId: number,
  monthId: number,
): Promise<ApiResponseTimesheetCreationDates> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/calendar-months/${monthId}/periods`,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  return response.data;
};
