export const convertDateToMilSecs = (inputDate: Date) => {
  // Convert to UTC, keep just the date part
  return Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
};

const getMonthName = (monthNumber: number): string | null => {
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  if (monthNumber < 0 || monthNumber > 11) {
    return null;
  }
  return monthNames[monthNumber];
};
export enum MuiDateFormatEnum {
  YYYY_MM = "yyyy/MM",
  DD_MM_YYYY = "dd/MM/yyyy",
  DD_MMM_YYYY = "dd MMM yyyy",
  DD_COMMA_MMM_YYYY = "dd, MMM yyyy",
  DD_MMM_COMMA_YYYY = "dd MMM, yyyy",
}

export enum DateFormatEnum {
  YYYY_MM = "YYYY/MM",
  DD_MM_YYYY = "DD_MM_YYYY",
  DD_MMM_YYYY = "DD MMM YYYY",
  DD_COMMA_MMM_YYYY = "DD, MMM YYYY",
}
export const formatDate = (inputDate: Date, format?: DateFormatEnum): string => {
  if (format === DateFormatEnum.YYYY_MM) {
    return `${inputDate.getFullYear()}/${(inputDate.getMonth() + 1).toString().padStart(2, "0")}`;
  }
  if (format === DateFormatEnum.DD_MM_YYYY) {
    return `${inputDate.getDate().toString().padStart(2, "0")}/${(inputDate.getMonth() + 1).toString().padStart(2, "0")}/${inputDate.getFullYear()}`;
  }
  if (format === DateFormatEnum.DD_MMM_YYYY) {
    return `${inputDate.getDate().toString().padStart(2, "0")} ${getMonthName(inputDate.getMonth())?.slice(0, 3)} ${inputDate.getFullYear()}`;
  }
  if (format === DateFormatEnum.DD_COMMA_MMM_YYYY) {
    const optionsCaseDCommaMY: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formatter = new Intl.DateTimeFormat("en-US", optionsCaseDCommaMY);
    const parts = formatter.formatToParts(inputDate);
    const partsArray: string[] = [];
    parts.forEach((part) => {
      switch (part.type) {
        case "day":
          partsArray[0] = part.value.padStart(2, "0") + ",";
          return part.value.padStart(2, "0") + ",";
        case "month":
          partsArray[1] = part.value.toUpperCase();
          return part.value.toUpperCase();
        case "year":
          partsArray[2] = part.value;
          return part.value;
        default:
          return "";
      }
    });
    return partsArray.join(" ");
  }

  const optionsGeneric: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  return new Intl.DateTimeFormat("en-US", optionsGeneric).format(inputDate);
};

export const isCurrentDate = (inputDate: Date) => {
  const currentDate = new Date();
  return (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  );
};

export const getTimeFromDate = (inputDate: Date) => {
  const hours = inputDate.getHours().toString().padStart(2, "0");
  const minutes = inputDate.getMinutes().toString().padStart(2, "0");
  const period = inputDate.getHours() >= 12 ? "pm" : "am";
  return `${hours}:${minutes} ${period}`;
};

export const calculateAge = (dateOfBirth: Date): number => {
  const birthDate = new Date(dateOfBirth);
  const now = new Date();

  let age = now.getFullYear() - birthDate.getFullYear();

  // Adjust age if birthday hasn't occurred yet this year
  if (now.getMonth() < birthDate.getMonth() || (now.getMonth() === birthDate.getMonth() && now.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};
