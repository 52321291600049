import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { ApiRequestBodyMmrCreate } from "epcm-common/dist/Types/MmrTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const createMmr = async (projectId: number, employeeId: number, newMmrData: ApiRequestBodyMmrCreate) => {
  const url = `${API}/${projectId}/employees/${employeeId}/mmr`;

  const config: AxiosRequestConfig = {
    url: `${url}`,
    method: "POST",
    data: newMmrData,
  } as AxiosRequestConfig;
  return epcmAxiosInstance(config);
};
