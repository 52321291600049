import { FC, useCallback, useEffect, useMemo, useState } from "react";
import GlobalConfigurationCtrLibraryRowStyles from "./GlobalConfigurationCtrLibraryRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

import TrashCanDarkIcon from "../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIcon from "../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../assets/images/trashcan-icon-red.svg";
import EditIconHover from "../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "@mui/material";
import { FrontendGlobalCtrType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";

import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useEpcmApiConfigurations } from "../../../../../apicalls/configurations/useEpcmApiConfigurations.ts";
import { SettingsPagePopups, useSettingsPopups } from "../../../use-settings-pupups.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { DeletePopup } from "../../../../../ui/deletepopup/DeletePopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import GlobalConfigurationCtrPopup from "../../popups/globalconfigurationtradespopup/GlobalConfigurationCtrPopup.tsx";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../../types/Roles.ts";

interface ConfigurationCtrLibraryRowProps {
  globalCtrItem: FrontendGlobalCtrType;
}

const ConfigurationCtrLibraryRow: FC<ConfigurationCtrLibraryRowProps> = ({ globalCtrItem }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();

  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const canListConfigurations = canPerformGlobalAction(GlobalAction.ConfigList);
  const canEditConfigurations = canPerformGlobalAction(GlobalAction.ConfigUpdate);
  const canDeleteConfigurations = canPerformGlobalAction(GlobalAction.ConfigDelete);
  // const { currentProject } = useProjectContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getGlobalCtr } = useEpcmApiConfigurations();

  const queryClient = useQueryClient();
  const { useDeleteGlobalEntityMutation } = useEpcmApiConfigurationsMutations();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const deleteGlobalCtrCodeMutation = useDeleteGlobalEntityMutation(globalCtrItem.id, FrontendGlobalConfigurationsEntityEnum.Ctr, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Global CTR was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalCtrs] });
  }, [queryClient]);

  const globalCtrItemQuery = useQuery({
    queryKey: [QueryNames.GlobalCtrs, globalCtrItem.id],
    queryFn: () => getGlobalCtr(globalCtrItem.id, DetailLevel.LIMITED),
    enabled: isAuthorized,
    select: (data) => data as FrontendGlobalCtrType,
  });

  const globalCtrItemData = useMemo(() => {
    return globalCtrItemQuery.data;
  }, [globalCtrItemQuery.data]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.GlobalCtrs, globalCtrItem.id] }).then(() => {
        console.log(`In configuration-ctr-library-row component, ${QueryNames.GlobalCtrs} query for ctr ${globalCtrItem.id} cancelled`);
      });
    };
  }, [queryClient, globalCtrItem.id]);

  return (
    <>
      <tr
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
        key={globalCtrItem.id}
        className={classNames(
          GlobalConfigurationCtrLibraryRowStyles.itemContainer,
          isRowHovered && GlobalConfigurationCtrLibraryRowStyles.rowHoverEffect,
          GlobalConfigurationCtrLibraryRowStyles.Trow,
        )}
      >
        {globalCtrItem && canListConfigurations ? (
          <>
            <td
              className={classNames(
                GlobalConfigurationCtrLibraryRowStyles.tableCell,
                GlobalConfigurationCtrLibraryRowStyles.boldText,
                GlobalConfigurationCtrLibraryRowStyles.tD,
              )}
            >
              {globalCtrItem.code}
            </td>
            <td
              className={classNames(
                GlobalConfigurationCtrLibraryRowStyles.tableCell,
                GlobalConfigurationCtrLibraryRowStyles.boldText,
                GlobalConfigurationCtrLibraryRowStyles.tD,
              )}
            >
              {globalCtrItemData ? globalCtrItemData.description : <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>}
            </td>
            <td
              className={classNames(
                GlobalConfigurationCtrLibraryRowStyles.tableCell,
                GlobalConfigurationCtrLibraryRowStyles.boldText,
                GlobalConfigurationCtrLibraryRowStyles.tD,
              )}
            >
              {globalCtrItemData ? globalCtrItemData.type : <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>}
            </td>
            <td
              className={classNames(
                GlobalConfigurationCtrLibraryRowStyles.tableCell,
                GlobalConfigurationCtrLibraryRowStyles.boldText,
                GlobalConfigurationCtrLibraryRowStyles.tD,
              )}
            >
              {globalCtrItemData ? globalCtrItemData.category : <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>}
            </td>
            <td className={classNames(GlobalConfigurationCtrLibraryRowStyles.tableCellActions, GlobalConfigurationCtrLibraryRowStyles.tD)}>
              <div
                className={classNames(
                  GlobalConfigurationCtrLibraryRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                {canDeleteConfigurations && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();

                      onOpenUtilPopup();
                    }}
                    onMouseEnter={() => setDeleteIconHovered(true)}
                    onMouseLeave={() => setDeleteIconHovered(false)}
                  >
                    <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                  </div>
                )}
                {canEditConfigurations && (
                  <div
                    className={classNames(GlobalConfigurationCtrLibraryRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                    onClick={() => onOpenPopup(SettingsPagePopups.updateGlobalCtr, popupHandler)}
                    onMouseEnter={() => setEditIconHovered(true)}
                    onMouseLeave={() => setEditIconHovered(false)}
                  >
                    <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                  </div>
                )}
              </div>
            </td>
            {isUtilPopupOpen && (
              <DeletePopup
                isOpen={isUtilPopupOpen}
                closeFn={() => {
                  onCloseUtilPopup();
                }}
                onDeleteFn={() => {
                  deleteGlobalCtrCodeMutation.mutate();
                  onOpenLoaderPopup();
                }}
              />
            )}
            {isResponseAlertPopupOpen && responseType && responseObject && (
              <ResponseAlertPopup
                responseType={responseType}
                responseObject={responseObject}
                isOpen={isResponseAlertPopupOpen}
                closeFn={() => {
                  initializeResponseAlertPopup();
                  onCloseResponseAlertPopup();
                  onCloseUtilPopup();
                  responseType === ApiResponseTypeEnum.success && invalidateQueries();
                }}
              />
            )}
            {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
          </>
        ) : (
          <></>
        )}
      </tr>
      {popupHandler.get(SettingsPagePopups.updateGlobalCtr)!.isOpen && (
        <GlobalConfigurationCtrPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(SettingsPagePopups.updateGlobalCtr, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.updateGlobalCtr)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.updateGlobalCtr)}
          globalCtrItem={
            {
              code: globalCtrItem.code,
              description: globalCtrItemData ? globalCtrItemData.description : "",
              type: globalCtrItemData ? globalCtrItemData.type : "",
              category: globalCtrItemData ? globalCtrItemData.category : "",
              id: globalCtrItem.id,
            } as FrontendGlobalCtrType
          }
        />
      )}
    </>
  );
};

export default ConfigurationCtrLibraryRow;
