export interface FrontendCalendarMonthItemType {
  id: number;
  monthNumber: number;
  rangeStartDate: Date;
  rangeEndDate: Date;
  displayName: string;
}

export enum FrontendDayTypeOptionsEnum {
  WORKING_DAY = "WORKING DAY",
  NO_WORKING_DAY = "NO WORKING DAY",
  HOLIDAY = "HOLIDAY",
}

export interface FrontendCalendarMMRType {
  id: number;
  dayType: FrontendDayTypeOptionsEnum;
}

export interface FrontendCalendarDayHours {
  calendarDayId: number;
  workedHours: number;
}

export interface FrontendMmrCalendarDayBody {
  calendarDayId: number;
  isApplicable: boolean;
}

export interface FrontendCalendarDayRecords {
  calendarDayId: number;
  workedHours: number;
}
