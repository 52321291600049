import { createContext, JSX } from "react";

export interface AppContextType {
  openSnackbar: (snackbarText: string) => void;
  EPCM_AppSnackbar: JSX.Element | undefined;
}

export const EPCM_AppContext = createContext<AppContextType>({
  openSnackbar: () => null,
  EPCM_AppSnackbar: undefined,
});
