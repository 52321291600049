import {
  FrontendCalendarManagement,
  FrontendCalendarManagementDays,
  FrontendCalendarManagementMonths,
} from "../../../types/apicallstypes/CalendarManagementTypes";
import { getGeneralCalendarInfo } from "./getGeneralCalendarInfo";
import {
  convertCalendarManagementToFrontendCalendarManagement,
  convertCalendarManagementToFrontendCalendarManagementDays,
  convertCalendarManagementToFrontendCalendarManagementMonths,
} from "../../../types/apicallstypes/apicallstypesconverters/CalendarManagementApiConverter";
import { getAllCalendarMonths } from "./getAllCalendarMonths.ts";
import { getAllCalendarDays } from "./getAllCalendarDays.ts";
import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";

interface ApiDefinition {
  getGeneralCalendarInfo: (id: number) => Promise<FrontendCalendarManagement>;
  getAllCalendarMonths: (
    id: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendCalendarManagementMonths>>;
  getAllCalendarDays: (
    id: number,
    monthId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ) => Promise<QueryModelWithPagination<FrontendCalendarManagementDays>>;
}

export const useEpcmApiConfigurationCalendarInfo = (): ApiDefinition => {
  const getGeneralCalendarInfoConverted = async (id: number): Promise<FrontendCalendarManagement> => {
    const calendarManagementData = await getGeneralCalendarInfo(id);

    return convertCalendarManagementToFrontendCalendarManagement(calendarManagementData);
  };

  const getAllCalendarMonthsConverted = async (
    id: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendCalendarManagementMonths>> => {
    const calendarManagementMonthsData = await getAllCalendarMonths(id, pageOffset, pageSize, searchFilter);
    return {
      data: convertCalendarManagementToFrontendCalendarManagementMonths(calendarManagementMonthsData.data),
      nextPage: calendarManagementMonthsData.nextPage,
      totalCount: calendarManagementMonthsData.totalCount,
    };
  };

  const getAllCalendarDaysConverted = async (
    id: number,
    monthId: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
  ): Promise<QueryModelWithPagination<FrontendCalendarManagementDays>> => {
    const calendarManagementDaysData = await getAllCalendarDays(id, monthId, pageOffset, pageSize, searchFilter);
    return {
      data: convertCalendarManagementToFrontendCalendarManagementDays(calendarManagementDaysData.data),
      nextPage: calendarManagementDaysData.nextPage,
      totalCount: calendarManagementDaysData.totalCount,
    };
  };

  return {
    getGeneralCalendarInfo: getGeneralCalendarInfoConverted,
    getAllCalendarMonths: getAllCalendarMonthsConverted,
    getAllCalendarDays: getAllCalendarDaysConverted,
  };
};
