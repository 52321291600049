import classNames from "classnames";
import ConfigurationTradesPopupStyles from "./GlobalConfigurationSubpositionsPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PopupButtonTypeEnum, PopupType } from "../../../../../types/PopupType.ts";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { PopupStructure } from "../../../../../ui/popupstructure/PopupStructure.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { FrontendGlobalSubPositionType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { ApiRequestBodyGlobalSubPositionCreate, ApiRequestBodyGlobalSubPositionUpdate } from "epcm-common/dist/Types/GlobalSubPositionTypes";

interface GlobalConfigurationSubpositionPopupProps extends PopupType {
  positionId: number;
  subpositionItem?: FrontendGlobalSubPositionType;
  mode: configurationsPopupType;
}

const GlobalConfigurationSubpositionsPopup: FC<GlobalConfigurationSubpositionPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  subpositionItem,
  positionId,
  mode,
}) => {
  const [subpositionDescription, setSubpositionDescription] = useState<string>(subpositionItem?.description || "");
  const [subpositionCode, setSubpositionCode] = useState<string>(subpositionItem?.code || "");

  useEffect(() => {
    if (subpositionItem) {
      setSubpositionDescription(subpositionItem.description);
      setSubpositionCode(subpositionItem.code);
    }
  }, [subpositionItem]);

  const { useCreateGlobalEntityMutation, useUpdateGlobalEntityMutation } = useEpcmApiConfigurationsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createGlobalSubpositionMutation = useCreateGlobalEntityMutation(
    FrontendGlobalConfigurationsEntityEnum.Subposition,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: `Subposition was added  successfully` });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
    positionId,
  );

  const updateGlobalSubpositionMutation = useUpdateGlobalEntityMutation(
    positionId,
    FrontendGlobalConfigurationsEntityEnum.Subposition,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: `Subposition was updated successfully` });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
    subpositionItem?.id ?? 0,
  );

  const onCreateSubposition = useCallback(() => {
    createGlobalSubpositionMutation.mutate({
      code: subpositionCode,
      description: subpositionDescription,
    } satisfies ApiRequestBodyGlobalSubPositionCreate);
    onOpenLoaderPopup();
  }, [createGlobalSubpositionMutation, subpositionDescription, subpositionCode, onOpenLoaderPopup]);

  const onUpdateSubposition = useCallback(() => {
    updateGlobalSubpositionMutation.mutate({
      code: subpositionCode,
      description: subpositionDescription,
    } satisfies ApiRequestBodyGlobalSubPositionUpdate);
    onOpenLoaderPopup();
  }, [updateGlobalSubpositionMutation, subpositionDescription, subpositionCode, onOpenLoaderPopup]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalPositions] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalSubpositions, positionId] });
  }, [queryClient, positionId]);

  const isEitherInputEmpty = useMemo(() => !subpositionDescription || !subpositionCode, [subpositionDescription, subpositionCode]);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(ConfigurationTradesPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Subposition" : "Create New Subposition",
          tooltipText: isEitherInputEmpty ? "Please enter both subposition code and subposition description " : "",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !isEitherInputEmpty && onUpdateSubposition();
            } else {
              !isEitherInputEmpty && onCreateSubposition();
            }
          },
          disabled: isEitherInputEmpty,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationTradesPopupStyles.mainContainer)}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationTradesPopupStyles.infoText)}>Subposition Code</div>
          <input
            placeholder={"Type a subposition code..."}
            className={classNames(ConfigurationTradesPopupStyles.infoContainer, ConfigurationTradesPopupStyles.infoContainerInput)}
            required
            value={subpositionCode}
            onChange={(e) => setSubpositionCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationTradesPopupStyles.infoText)}>Subposition Description</div>
          <input
            placeholder={"Type a subposition description..."}
            className={classNames(ConfigurationTradesPopupStyles.infoContainer, ConfigurationTradesPopupStyles.infoContainerInput)}
            required
            value={subpositionDescription}
            onChange={(e) => setSubpositionDescription(e.target.value)}
          ></input>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default GlobalConfigurationSubpositionsPopup;
