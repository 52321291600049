import { PopupType } from "../../../../types/PopupType.ts";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import { SearchBar } from "../../../../ui/searchbar/SearchBar.tsx";
import SelectCtrCodePopupStyles from "./SelectCtrCodePopup.module.css";
import { CtrListItem } from "./ctrlistItem/CtrListItem.tsx";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useParams } from "react-router-dom";
import InfiniteScrollInViewElement from "../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useInView } from "react-intersection-observer";
import { Skeleton, Tooltip } from "@mui/material";
import { PAGINATION_PAGE_SIZE } from "../../../../apicalls/config.ts";
import { FrontendMmrCtrsBody } from "../../../../types/apicallstypes/CtrTypes.ts";
import tickIcon from "../../../../assets/images/tick-circled-arrow-blue.svg";
import { useEpcmApiProjectEmployee } from "../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";

interface SelectCtrCodePopupProps extends PopupType {
  ctrId: number | null;
  setCtrRecords: Dispatch<SetStateAction<{ [mmrId: number]: FrontendMmrCtrsBody[] }>>;
  projectEmployeeId: number;
  mmrId: number;
  ctrCodesInUse: number[];
  onUnselectCtr: () => void;
}

export const SelectCtrCodePopup: FC<SelectCtrCodePopupProps> = ({
  isOpen,
  closeFn,
  headerText,
  secondaryHeaderText,
  ctrId,
  setCtrRecords,
  projectEmployeeId,
  mmrId,
  ctrCodesInUse,
  onUnselectCtr,
}) => {
  const { projectId } = useParams();
  const [parent] = useAutoAnimate();
  const { getAllProjectEmployeeMmrCtrs } = useEpcmApiProjectEmployee();
  // const { useCreateProjectEmployeesMutation } = useEpcmApiProjectsMutations();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const allProjectCtrsQuery = useInfiniteQuery({
    queryKey: [QueryNames.Employees, searchQuery],
    queryFn: ({ pageParam = 1 }) =>
      getAllProjectEmployeeMmrCtrs(parseInt(projectId!), projectEmployeeId, mmrId, pageParam, PAGINATION_PAGE_SIZE, searchQuery),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const allProjectCtrsData = useMemo(() => {
    return allProjectCtrsQuery.data?.pages
      .flatMap((page) => page.data)
      .filter((projectCtrItem) => !ctrCodesInUse.includes(projectCtrItem.id) || (!!ctrId && ctrId === projectCtrItem.id));
  }, [allProjectCtrsQuery.data, ctrId, ctrCodesInUse]);

  const onSearchQueryChange = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const onCtrToggle = useCallback(
    (selectedCtrId: number) => {
      setCtrRecords((currentRecords) => {
        const updatedRecords = { ...currentRecords };
        const mmrRecords = updatedRecords[mmrId] || [];

        // Find index of the CTR to be toggled
        const ctrIndex = mmrRecords.findIndex((record) => record.ctrId === selectedCtrId);

        if (ctrIndex !== -1) {
          // If found and it's currently selected, reset to null
          mmrRecords[ctrIndex] = { ctrId: null, calendarDayHours: [] };
        } else {
          // Check if there is a null record to replace, only if there are no selected CTR
          const nullIndex = mmrRecords.findIndex((record) => record.ctrId === null);
          if (nullIndex !== -1) {
            // Replace the first null record found
            mmrRecords[nullIndex] = { ctrId: selectedCtrId, calendarDayHours: [] };
          } else {
            // If no null records, add new CTR record
            mmrRecords.push({ ctrId: selectedCtrId, calendarDayHours: [] });
          }
        }

        return { ...updatedRecords, [mmrId]: mmrRecords };
      });
      closeFn();
    },
    [setCtrRecords, mmrId, closeFn],
  );

  const noCtrCodesInUse = useMemo(() => {
    return ctrCodesInUse.length === 0;
  }, [ctrCodesInUse]);

  const TooltipText = useMemo(() => {
    if (noCtrCodesInUse) {
      return "No code selected";
    } else {
      return "";
    }
  }, [noCtrCodesInUse]);

  useEffect(() => {
    if (
      inView &&
      !allProjectCtrsQuery.isLoading &&
      !allProjectCtrsQuery.isFetching &&
      !allProjectCtrsQuery.isFetchingNextPage &&
      allProjectCtrsQuery.hasNextPage
    ) {
      void allProjectCtrsQuery.fetchNextPage();
    }
  }, [inView, allProjectCtrsQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectCtrs, projectId] })
        .then(() => console.log(`In select ctr popup, ${QueryNames.ProjectCtrs} query canceled`));
    };
  }, [queryClient, projectId]);

  return (
    <PopupStructure popupButtons={[]} isOpen={isOpen} closeFn={closeFn} headerText={headerText} secondaryHeaderText={secondaryHeaderText}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
        <SearchBar
          placeholder={"Search applicable codes..."}
          setSearchQuery={onSearchQueryChange}
          searchBarContainerStyles={SelectCtrCodePopupStyles.selectCtrPopupSearchBarContainer}
        />
        <div className={classNames(SelectCtrCodePopupStyles.subheader)}>{"Applicable Codes"}</div>
        <div ref={parent} className={classNames(SelectCtrCodePopupStyles.ctrListContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          {allProjectCtrsQuery.isLoading ? (
            ["ctr1", "ctr2", "ctr3"].map((key) => <Skeleton key={key} variant="rounded" height={50} width={"100%"} />)
          ) : allProjectCtrsData && allProjectCtrsData.length > 0 ? (
            <>
              {allProjectCtrsData.map((projectCtrItem) => (
                <CtrListItem
                  key={projectCtrItem.id}
                  ctrItem={projectCtrItem}
                  isSelected={ctrId === projectCtrItem.id}
                  onCtrToggle={() => onCtrToggle(projectCtrItem.id)}
                />
              ))}
              <InfiniteScrollInViewElement
                reference={ref}
                infiniteQueryResult={allProjectCtrsQuery}
                loaderComponent={<Skeleton variant="rounded" height={30} width={"100%"} />}
              />
            </>
          ) : (
            <div className={classNames(GlobalStyles.emptyListMsg, GlobalStyles.flex)}>
              <div className={classNames(GlobalStyles.flex1)} />
              <div>{"No Ctr Codes"}</div>
              <div className={classNames(GlobalStyles.flex1)} />
            </div>
          )}
        </div>

        <div className={classNames(GlobalStyles.flex)}>
          <div
            className={classNames(SelectCtrCodePopupStyles.unselectedActionContainer, GlobalStyles.flex, GlobalStyles.gap05)}
            onClick={() => {
              if (!noCtrCodesInUse) {
                onUnselectCtr();
                // Add any additional logic here
              }
            }}
          >
            <div className={classNames((noCtrCodesInUse || !ctrId) && SelectCtrCodePopupStyles.disabledContent, GlobalStyles.centerVertical)}>
              <img className={classNames(SelectCtrCodePopupStyles.tickIconImg)} src={tickIcon} alt="tick" />
            </div>
            <Tooltip title={TooltipText} placement={"right-end"} arrow>
              <div
                className={classNames(
                  (noCtrCodesInUse || !ctrId) && SelectCtrCodePopupStyles.disabledContent,
                  SelectCtrCodePopupStyles.unselectedText,
                  GlobalStyles.centerVertical,
                )}
              >
                {"Unselect"}
              </div>
            </Tooltip>
          </div>

          <div className={classNames(GlobalStyles.flex!)} />
        </div>
      </div>
    </PopupStructure>
  );
};
