import { BaseErrorInterface } from "epcm-common/dist/Types/ErrorTypes.ts";

export enum QueryNames {
  Employees = "Employees",
  Profile = "Profile",
  ProjectEmployee = "ProjectEmployee",
  ProjectEmployeeMmr = "ProjectEmployeeMmr",
  ProjectEmployeeMmrItem = "ProjectEmployeeMmrItem",
  ProjectEmployees = "ProjectEmployees",
  ProjectEmployeesMmrStatusCount = "ProjectEmployeesMmrStatusCount",
  ProjectCtrs = "ProjectCtrs",
  Projects = "Projects",
  ProjectTimesheetGroupEmployeeCount = "ProjectTimesheetGroupEmployeeCount",
  ProjectTimesheetGroups = "ProjectTimesheetGroups",
  ProjectTimesheetGroupTimesheetDateRecords = "ProjectTimesheetGroupTimesheetDateRecords",
  ProjectTimesheetGroupTimesheetDates = "ProjectTimesheetGroupTimesheetDates",
  ProjectTimesheetGroupTimesheetEmployees = "ProjectTimesheetGroupTimesheetEmployees",
  ProjectTimesheetGroupTimesheetProjectEmployeeActiveMMRs = "ProjectTimesheetGroupTimesheetProjectEmployeeActiveMMRs",
  ProjectTimesheetGroupActiveMmrCtrCodes = "ProjectTimesheetGroupActiveMmrCtrCodes",
  ProjectTimesheetGroupActiveMmrCtrItemRecords = "ProjectTimesheetGroupActiveMmrCtrItemRecords",
  ProjectTimesheetGroupTimesheets = "ProjectTimesheetGroupTimesheets",
  ProjectTimesheetPeriods = "ProjectTimesheetPeriods",
  TimesheetGroups = "TimesheetGroups",
  TimesheetGroupIds = "TimesheetGroupIds",
  TimesheetGroupDeletionEligibility = "TimesheetGroupDeletionEligibility",
  TimesheetGroupEmployees = "TimesheetGroupEmployees",
  TimesheetCalendarMonths = "TimesheetCalendarMonths",
  TimesheetCalendarMonthPeriods = "TimesheetCalendarMonthPeriods",
  ProjectPositionCodes = "ManpowerPositionCodes",
  ProjectPositionCode = "ManpowerPositionCode",
  ProjectLocations = "ProjectLocations",
  ProjectPositions = "ProjectPositions",
  ProjectDisciplines = "ProjectDiscipline",
  ProjectSubpositions = "ProjectSubpositions",
  ProjectTrades = "ProjectTrades",
  ProjectTimesheetAnalyticsGroups = "ProjectTimesheetAnalyticsGroups",
  ProjectTimesheetAnalyticsNotFinalGroups = "ProjectTimesheetAnalyticsNotFinalGroups",
  ProjectTimesheetAnalyticsEmployees = "ProjectTimesheetAnalyticsEmployees",
  ProjectTimesheetAnalyticsItems = "ProjectTimesheetAnalyticsItems",
  ProjectCategories = "ProjectCategories",
  ProjectCalendar = "ProjectCalendar",
  ProjectCalendarMonths = "ProjectCalendarMonths",
  ProjectCalendarDays = "ProjectCalendarDays",
  ProjectCtrMapping = "ProjectCtrMapping",
  ProjectConfigurationsMissingInfo = "ProjectConfigurationsMissingInfo",
  ProjectConfigurationsPermissions = "ProjectConfigurationsPermissions",
  ConfigurationRoles = "ConfigurationRoles",
  ProjectFiles = "ProjectFiles",
  ProjectFileEvents = "ProjectFileEvents",
  ProjectMmrDateLimits = "ProjectMmrDateLimits",
  TimesheetGroupSectionHead = "TimesheetGroupSectionHead",
  ProjectTimesheets = "ProjectTimesheets",
  User = "User",
  Users = "Users",
  UserProjectRoles = "UserProjectRoles",
  GlobalRoles = "GlobalRoles",
  ProjectRoles = "ProjectRoles",
  GlobalPositions = "GlobalPositions",
  GlobalSubpositions = "GlobalSubpositions",
  GlobalLocations = "GlobalLocations",
  GlobalTrades = "GlobalTrades",
  GlobalDisciplines = "GlobalDisciplines",
  GlobalCategories = "GlobalCategories",
  GlobalCtrs = "GlobalCtrs",
  GlobalPermissions = "GlobalPermissions",
  GlobalNationalities = "GlobalNationalities",
}

export enum SubQueryNames {
  count = "count",
  active = "active",
  timesheetList = "timesheetList",
  notActive = "notActive",
  inTimesheet = "inTimesheet",
  notInTimesheet = "notInTimesheet",
  finalized = "finalized",
  draft = "draft",
  editMode = "editMode",
  library = "library",
  changeStatus = "change-status",
  list = "list",
  inFinalizedTimesheet = "inFinalizedTimesheet",
  inDraftTimesheet = "inDraftTimesheet",
  inTooltip = "inTooltip",
  employeesVersatileList = "employeesVersatileList",
  manpowerPopupContent = "manpowerPopupContent",
  inMMR = "inMMR",
}

export interface QueryModelWithCountAndData<T> {
  count: number;
  data: T[];
}

export interface QueryModelWithCount {
  count: number;
}

export interface QueryModelWithPagination<T> {
  data: T[];
  nextPage: number | null;
  totalCount: number;
}

export enum ApiResponseTypeEnum {
  success = "success",
  error = "error",
  warning = "warning",
}

export interface SuccessCallbackDataType {
  data: { status: number; id?: number };
}

export interface ErrorCallbackDataType {
  response: {
    data: BaseErrorInterface;
  };
}
