import { AxiosRequestConfig } from "axios";
import { epcmAxiosInstance, EPCM_API_URLS } from "../config";
import { ApiResponseGetMe } from "epcm-common/dist/Types/UserTypes";

const API = `${EPCM_API_URLS.profile}`;

export const getMe = async (): Promise<ApiResponseGetMe> => {
  const config: AxiosRequestConfig = {
    url: `${API}`,
    method: "GET",
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies ApiResponseGetMe;
};
