import { FrontendCalendarProjectStatusEnum, FrontendTimesheetPeriodType, FrontendTimesheetStatusEnum } from "../../../ProjectTimesheetsApiTypes.ts";
import { TimesheetPeriod } from "epcm-common/dist/Types/TimesheetTypes.ts";
import { calendarProjectStatus } from "epcm-common/dist/Types/CalendarTypes";
import { TimesheetStatus } from "epcm-common/dist/Types/TimesheetTypes";

export const projectTimesheetStatusToFrontend: Map<TimesheetStatus, FrontendTimesheetStatusEnum> = new Map<
  TimesheetStatus,
  FrontendTimesheetStatusEnum
>([
  [TimesheetStatus.PENDING, FrontendTimesheetStatusEnum.PENDING],
  [TimesheetStatus.PREPARED, FrontendTimesheetStatusEnum.PREPARED],
  [TimesheetStatus.REVIEWED, FrontendTimesheetStatusEnum.REVIEWED],
]);
export const convertProjectTimesheetStatusToBackend = (projectTimesheetStatus: FrontendTimesheetStatusEnum): TimesheetStatus => {
  const projectTimesheetStatusMap: Map<FrontendTimesheetStatusEnum, TimesheetStatus> = new Map([
    [FrontendTimesheetStatusEnum.PENDING, TimesheetStatus.PENDING],
    [FrontendTimesheetStatusEnum.PREPARED, TimesheetStatus.PREPARED],
    [FrontendTimesheetStatusEnum.REVIEWED, TimesheetStatus.REVIEWED],
  ]);
  return projectTimesheetStatusMap.get(projectTimesheetStatus)!;
};
export const calendarProjectStatusToFrontend: Map<calendarProjectStatus, FrontendCalendarProjectStatusEnum> = new Map<
  calendarProjectStatus,
  FrontendCalendarProjectStatusEnum
>([
  [calendarProjectStatus.VALID, FrontendCalendarProjectStatusEnum.VALID],
  [calendarProjectStatus.BEFORE_PROJECT_START, FrontendCalendarProjectStatusEnum.BEFORE_PROJECT_START],
  [calendarProjectStatus.AFTER_PROJECT_END, FrontendCalendarProjectStatusEnum.AFTER_PROJECT_END],
]);

export const convertProjectTimesheetPeriodsData = (projectTimesheetPeriodsData: TimesheetPeriod[]): FrontendTimesheetPeriodType[] => {
  return projectTimesheetPeriodsData.map((timesheetPeriodItem): FrontendTimesheetPeriodType => {
    return {
      periodStart: new Date(timesheetPeriodItem.periodStart),
      periodEnd: new Date(timesheetPeriodItem.periodEnd),
    };
  });
};
