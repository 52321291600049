import {
  FrontendManpowerPositionDetailedType,
  FrontendManpowerPositionLimitedType,
  FrontendManpowerPositionNormalType,
  FrontendMmrDateStatusEnum,
  FrontendMmrStatusEnum,
  FrontendProjectEmployeeMmrBasicType,
  FrontendProjectEmployeeMmrDetailedType,
  FrontendProjectEmployeeMmrLimitedType,
  FrontendProjectEmployeeMmrNormalType,
  FrontendProjectEmployeeMmrType,
} from "../../../ProjectEmployeesApiTypes.ts";
import { MmrBasic, MmrDateStatus, MmrDetailed, MmrGetAll, MmrLimited, MmrNormal, MmrStatus } from "epcm-common/dist/Types/MmrTypes";
import { ManpowerPositionDetailed, ManpowerPositionLimited, ManpowerPositionNormal } from "epcm-common/dist/Types/ManpowerPositionTypes";

export const convertProjectEmployeeMmrData = (projectEmployeeMmrData: MmrGetAll[]): FrontendProjectEmployeeMmrType[] => {
  return projectEmployeeMmrData.map((projectEmployeeItem): FrontendProjectEmployeeMmrType => {
    return {
      id: projectEmployeeItem.id,
      documentNumber: projectEmployeeItem.documentNumber,
      status: convertMmrStatusToFrontend(projectEmployeeItem.status),
      dateStatus: convertMmrDateStatusToFrontend(projectEmployeeItem.dateStatus),
      isEligible: projectEmployeeItem.isEligible,
    };
  });
};

export const convertMmrStatusToBackend = (mmrStatus: FrontendMmrStatusEnum): MmrStatus => {
  const mmrStatusMap: Map<FrontendMmrStatusEnum, MmrStatus> = new Map([
    [FrontendMmrStatusEnum.APPROVED, MmrStatus.APPROVED],
    [FrontendMmrStatusEnum.REJECTED, MmrStatus.REJECTED],
    [FrontendMmrStatusEnum.PENDING, MmrStatus.PENDING],
  ]);
  return mmrStatusMap.get(mmrStatus)!;
};
export const convertMmrStatusToFrontend = (mmrStatus: MmrStatus | null): FrontendMmrStatusEnum => {
  if (mmrStatus === null) {
    return FrontendMmrStatusEnum.NoMmr;
  }
  const mmrStatusMap: Map<MmrStatus, FrontendMmrStatusEnum> = new Map([
    [MmrStatus.APPROVED, FrontendMmrStatusEnum.APPROVED],
    [MmrStatus.REJECTED, FrontendMmrStatusEnum.REJECTED],
    [MmrStatus.PENDING, FrontendMmrStatusEnum.PENDING],
  ]);
  return mmrStatusMap.get(mmrStatus)!;
};

export const convertMmrDateStatusToBackend = (mmrDateStatus: FrontendMmrDateStatusEnum): MmrDateStatus => {
  const mmrDateStatusMap: Map<FrontendMmrDateStatusEnum, MmrDateStatus> = new Map([
    [FrontendMmrDateStatusEnum.ACTIVE, MmrDateStatus.ACTIVE],
    [FrontendMmrDateStatusEnum.UPCOMING, MmrDateStatus.UPCOMING],
    [FrontendMmrDateStatusEnum.HISTORY, MmrDateStatus.HISTORY],
  ]);
  return mmrDateStatusMap.get(mmrDateStatus)!;
};
export const convertMmrDateStatusToFrontend = (mmrDateStatus: MmrDateStatus): FrontendMmrDateStatusEnum => {
  const mmrDateStatusMap: Map<MmrDateStatus, FrontendMmrDateStatusEnum> = new Map([
    [MmrDateStatus.ACTIVE, FrontendMmrDateStatusEnum.ACTIVE],
    [MmrDateStatus.UPCOMING, FrontendMmrDateStatusEnum.UPCOMING],
    [MmrDateStatus.HISTORY, FrontendMmrDateStatusEnum.HISTORY],
  ]);
  return mmrDateStatusMap.get(mmrDateStatus)!;
};

export const convertSingleProjectEmployeeMmrLimitedData = (projectEmployeeMmrData: MmrLimited): FrontendProjectEmployeeMmrLimitedType => {
  return {
    id: projectEmployeeMmrData.id,
    agreementNumber: projectEmployeeMmrData.agreementNumber,
    status: convertMmrStatusToFrontend(projectEmployeeMmrData.status),
  };
};

export const convertSingleProjectEmployeeMmrBasicData = (projectEmployeeMmrData: MmrBasic): FrontendProjectEmployeeMmrBasicType => {
  return {
    ...convertSingleProjectEmployeeMmrLimitedData(projectEmployeeMmrData),
    typeOfMobilization: projectEmployeeMmrData.typeOfMobilization,
    actualMobilizationDate: projectEmployeeMmrData.actualMobilizationDate ? new Date(projectEmployeeMmrData.actualMobilizationDate) : null,
    actualDemobilizationDate: projectEmployeeMmrData.actualDemobilizationDate ? new Date(projectEmployeeMmrData.actualDemobilizationDate) : null,
    anticipatedMobilizationDate: new Date(projectEmployeeMmrData.anticipatedMobilizationDate), //TODO GIANNIS REVERT THIS
    anticipatedDemobilizationDate: new Date(projectEmployeeMmrData.anticipatedDemobilizationDate), //TODO GIANNIS REVERT THIS
  };
};

export const convertManpowerPositionLimitedData = (manpowerPositionData: ManpowerPositionLimited): FrontendManpowerPositionLimitedType => {
  return {
    id: manpowerPositionData.id,
    code: manpowerPositionData.code,
  };
};

export const convertManpowerPositionNormalData = (manpowerPositionData: ManpowerPositionNormal): FrontendManpowerPositionNormalType => {
  return {
    ...convertManpowerPositionLimitedData(manpowerPositionData),
    rate: manpowerPositionData.rate,
    pricingScheduledManhours: manpowerPositionData.pricingScheduledManhours,
  };
};

export const convertManpowerPositionDetailedData = (manpowerPositionData: ManpowerPositionDetailed): FrontendManpowerPositionDetailedType => {
  return {
    ...convertManpowerPositionNormalData(manpowerPositionData),
    locationId: manpowerPositionData.locationId,
    positionId: manpowerPositionData.positionId,
    tradeId: manpowerPositionData.tradeId,
    categoryId: manpowerPositionData.categoryId,
  };
};

export const convertSingleProjectEmployeeMmrNormalData = (projectEmployeeMmrData: MmrNormal): FrontendProjectEmployeeMmrNormalType => {
  return {
    ...convertSingleProjectEmployeeMmrBasicData(projectEmployeeMmrData),
    creationDate: new Date(projectEmployeeMmrData.creationDate),
    manpowerPositionDetailed: convertManpowerPositionDetailedData(projectEmployeeMmrData.manpowerPositionDetailed),
    subPositionId: projectEmployeeMmrData.subPositionId,
    disciplineId: projectEmployeeMmrData.disciplineId,
  };
};

export const convertSingleProjectEmployeeMmrDetailedData = (projectEmployeeMmrData: MmrDetailed): FrontendProjectEmployeeMmrDetailedType => {
  return {
    ...convertSingleProjectEmployeeMmrNormalData(projectEmployeeMmrData),
    justificationForRequest: projectEmployeeMmrData.justificationForRequest,
    approvalRecord: projectEmployeeMmrData.approvalRecord,
    clientInterviewDate: projectEmployeeMmrData.clientInterview ? new Date(projectEmployeeMmrData.clientInterview) : null,
    keyPersonnel: projectEmployeeMmrData.keyPersonnel,
    //subcontractor: projectEmployeeMmrData.subcontractor,
    subcontractor: projectEmployeeMmrData.subcontractor, //TODO GIANNIS REVERT THIS WHEN IMPLEMENTED FROM BACKEND
    revisionNumber: projectEmployeeMmrData.revisionNumber,
    editorCode: projectEmployeeMmrData.editor.code,
    editorName: `${projectEmployeeMmrData.editor.name} ${projectEmployeeMmrData.editor.surname}`,
    editDate: projectEmployeeMmrData.editDate ? new Date(projectEmployeeMmrData.editDate) : null,
    mmrReference: projectEmployeeMmrData.reference,
    //return 50% chance of true or false
    isEligible: true, //TODO CHANGE WHEN isEligible is implemented
  };
};
