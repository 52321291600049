import { FC, useEffect, useMemo } from "react";
import CtrItemStyles from "./CtrItem.module.css";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { Fade } from "@mui/material";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import TagIcon from "../../../../../../../assets/images/tag-icon.svg";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectsUtils } from "../../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { QueryNames } from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { FrontendCtrNormalType } from "../../../../../../../types/apicallstypes/CtrTypes.ts";
import { EpcmTooltipNoPadding } from "../../../../../../../ui/epcmtooltip/EpcmTooltipNoPadding.tsx";

interface CtrItemProps {
  projectId: number;
  ctrId: number;
  ctrCode: string;
}

const CtrItem: FC<CtrItemProps> = ({ ctrCode, ctrId, projectId }) => {
  const { getProjectCtr } = useEpcmApiProjectsUtils();
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const ctrItemQuery = useQuery({
    queryKey: [QueryNames.ProjectCtrs, projectId, ctrId, DetailLevel.NORMAL],
    queryFn: () => getProjectCtr(projectId, ctrId, DetailLevel.NORMAL),
    enabled: isAuthorized,
    select: (data) => data as FrontendCtrNormalType,
  });

  const ctrItemData = useMemo(() => {
    return ctrItemQuery.data;
  }, [ctrItemQuery.data]);

  const ctrItemInfo = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(CtrItemStyles.ctrTooltipDescription, CtrItemStyles.ctrTooltip)}>{ctrItemData?.description}</div>
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.centerHorizontal,
          GlobalStyles.gap05,
          CtrItemStyles.ctrTooltipTopBorder,
          CtrItemStyles.ctrTooltip,
        )}
      >
        <div>
          <img src={TagIcon} alt={"tag-icon"}></img>
        </div>
        <div className={classNames(CtrItemStyles.ctrTooltipType)}>{ctrItemData?.type}</div>
      </div>
    </div>
  );
  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectCtrs, projectId, ctrId, DetailLevel.NORMAL] })
        .then(() => console.log(`In project with id ${projectId}, ${QueryNames.ProjectCtrs} query canceled`));
    };
  }, [queryClient, ctrId, projectId]);

  return (
    <EpcmTooltipNoPadding TransitionComponent={Fade} placement={"top"} arrow title={ctrItemInfo}>
      <div>{ctrCode}</div>
    </EpcmTooltipNoPadding>
  );
};
export default CtrItem;
