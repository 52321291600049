import classNames from "classnames";
import GlobalConfigurationCtrPopupStyles from "./GlobalConfigurationCtrPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { PopupButtonTypeEnum, PopupType } from "../../../../../types/PopupType.ts";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PopupStructure } from "../../../../../ui/popupstructure/PopupStructure.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { FrontendGlobalCtrType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import {
  FrontendCtrCategoryEnum,
  FrontendCtrCategoryOptions,
  FrontendCtrOptions,
  FrontendCtrTypeEnum,
} from "../../../../../types/apicallstypes/CtrTypes.ts";
import { convertCtrCategoryToBackend, convertCtrTypeToBackend } from "../../../../../types/apicallstypes/apicallstypesconverters/CtrApiConverter.ts";
import arrowSelectBox from "../../../../../assets/images/arrow-down-dark-blue-fill.svg";
import { Menu, MenuItem } from "@mui/material";

interface GlobalConfigurationCtrPopupProps extends PopupType {
  globalCtrItem?: FrontendGlobalCtrType;
  mode: configurationsPopupType;
}

const GlobalConfigurationCtrPopup: FC<GlobalConfigurationCtrPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  globalCtrItem,
  mode,
}) => {
  const [ctrDescription, setCtrDescription] = useState<string>(globalCtrItem?.description || "");
  const [ctrCode, setCtrCode] = useState<string>(globalCtrItem?.code || "");
  const [ctrType, setCtrType] = useState<FrontendCtrTypeEnum | undefined>(globalCtrItem?.type);
  const [ctrCategory, setCtrCategory] = useState<FrontendCtrCategoryEnum | undefined>(globalCtrItem?.category);

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(null);
  const [categoryDropdownAnchorEl, setCategoryDropdownAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (globalCtrItem) {
      setCtrDescription(globalCtrItem.description);
      setCtrCode(globalCtrItem.code);
      setCtrCategory(globalCtrItem.category);
      setCtrType(globalCtrItem.type);
    }
  }, [globalCtrItem]);

  const { useCreateGlobalEntityMutation, useUpdateGlobalEntityMutation } = useEpcmApiConfigurationsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createGlobalCtrMutation = useCreateGlobalEntityMutation(FrontendGlobalConfigurationsEntityEnum.Ctr, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global CTR was added successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });
  const updateGlobalCtrCodeMutation = useUpdateGlobalEntityMutation(globalCtrItem?.id ?? 0, FrontendGlobalConfigurationsEntityEnum.Ctr, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global CTR was updated successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onCreateGlobalCtr = useCallback(() => {
    createGlobalCtrMutation.mutate({
      code: ctrCode,
      description: ctrDescription,
      type: convertCtrTypeToBackend(ctrType!),
      category: convertCtrCategoryToBackend(ctrCategory!),
    });
    onOpenLoaderPopup();
  }, [createGlobalCtrMutation, ctrDescription, ctrCode, onOpenLoaderPopup, ctrCategory, ctrType]);

  const onUpdateGlobalCtr = useCallback(() => {
    updateGlobalCtrCodeMutation.mutate({
      code: ctrCode,
      description: ctrDescription,
      type: convertCtrTypeToBackend(ctrType!),
      category: convertCtrCategoryToBackend(ctrCategory!),
    });
    onOpenLoaderPopup();
  }, [updateGlobalCtrCodeMutation, ctrDescription, ctrCode, onOpenLoaderPopup, ctrCategory, ctrType]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalCtrs] });
  }, [queryClient]);

  const isInputInvalid = useMemo(() => !ctrDescription || !ctrCode || !ctrCategory || !ctrType, [ctrDescription, ctrCode, ctrType, ctrCategory]);

  const onDropdownMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const onDropdownMenuClose = () => {
    setDropdownAnchorEl(null);
  };

  const onCategoryDropdownMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setCategoryDropdownAnchorEl(event.currentTarget);
  };
  const onCategoryDropdownMenuClose = () => {
    setCategoryDropdownAnchorEl(null);
  };

  const dropdownMenuOpen = useMemo(() => Boolean(dropdownAnchorEl), [dropdownAnchorEl]);
  const categoryDropdownMenuOpen = useMemo(() => Boolean(categoryDropdownAnchorEl), [categoryDropdownAnchorEl]);

  const onCtrTypeChange = useCallback(
    (item: FrontendCtrTypeEnum) => {
      setCtrType(item);
      onDropdownMenuClose();
    },
    [setCtrType],
  );

  const onCtrCategoryChange = useCallback(
    (item: FrontendCtrCategoryEnum) => {
      setCtrCategory(item);
      onCategoryDropdownMenuClose();
    },
    [setCtrCategory],
  );

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(GlobalConfigurationCtrPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update CTR" : "Create New Global CTR",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !isInputInvalid && onUpdateGlobalCtr();
            } else {
              !isInputInvalid && onCreateGlobalCtr();
            }
          },
          tooltipText: isInputInvalid ? "Please fill all required fields" : "",
          disabled: isInputInvalid,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalConfigurationCtrPopupStyles.mainContainer)}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationCtrPopupStyles.infoText)}>CTR Code</div>
          <input
            placeholder={"Type a ctr code..."}
            className={classNames(GlobalConfigurationCtrPopupStyles.infoContainer, GlobalConfigurationCtrPopupStyles.infoContainerInput)}
            required
            value={ctrCode}
            onChange={(e) => setCtrCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationCtrPopupStyles.infoText)}>CTR Description</div>
          <input
            placeholder={"Type a ctr description..."}
            className={classNames(GlobalConfigurationCtrPopupStyles.infoContainer, GlobalConfigurationCtrPopupStyles.infoContainerInput)}
            required
            value={ctrDescription}
            onChange={(e) => setCtrDescription(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025, GlobalStyles.elementWithCursor)}>
          <div className={classNames(GlobalConfigurationCtrPopupStyles.infoText)}>{`CTR Type`}</div>
          <div
            className={classNames(GlobalConfigurationCtrPopupStyles.inputContainer, GlobalStyles.flex, GlobalStyles.gap)}
            onClick={onDropdownMenuOpen}
          >
            {ctrType ? (
              <div>{ctrType}</div>
            ) : (
              <div className={classNames(GlobalConfigurationCtrPopupStyles.inputPlaceholder)}>{"Select " + `CTR Type`}</div>
            )}
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(GlobalConfigurationCtrPopupStyles.selectBoxArrowIconImg)} src={arrowSelectBox} alt="arrow" />
            </div>
          </div>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={dropdownAnchorEl}
            open={dropdownMenuOpen}
            onClose={onDropdownMenuClose}
            PaperProps={{
              style: {
                maxHeight: "15em",
                width: "20em",
              },
            }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            {FrontendCtrOptions.map((item) => (
              <MenuItem key={item} selected={item === ctrType} onClick={() => onCtrTypeChange(item)}>
                {item}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025, GlobalStyles.elementWithCursor)}>
          <div className={classNames(GlobalConfigurationCtrPopupStyles.infoText)}>{`CTR Category`}</div>
          <div
            className={classNames(GlobalConfigurationCtrPopupStyles.inputContainer, GlobalStyles.flex, GlobalStyles.gap)}
            onClick={onCategoryDropdownMenuOpen}
          >
            {ctrCategory ? (
              <div>{ctrCategory}</div>
            ) : (
              <div className={classNames(GlobalConfigurationCtrPopupStyles.inputPlaceholder)}>{"Select " + `CTR Category`}</div>
            )}
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(GlobalConfigurationCtrPopupStyles.selectBoxArrowIconImg)} src={arrowSelectBox} alt="arrow" />
            </div>
          </div>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={categoryDropdownAnchorEl}
            open={categoryDropdownMenuOpen}
            onClose={onCategoryDropdownMenuClose}
            PaperProps={{
              style: {
                maxHeight: "15em",
                width: "28em",
              },
            }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            {FrontendCtrCategoryOptions.map((item) => (
              <MenuItem key={item} selected={item === ctrCategory} onClick={() => onCtrCategoryChange(item)}>
                {item}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default GlobalConfigurationCtrPopup;
