import { ApiResponseGetAllRoles } from "epcm-common/dist/Types/RoleTypes";
import { FrontendRoleType } from "../RolesApiTypes.ts";
import { ApiResponseUserGetOne } from "epcm-common/dist/Types/UserTypes";
import { FrontendUserLimitedType } from "../UsersApiTypes.ts";

export const convertUserRolesData = (userRolesData: ApiResponseGetAllRoles[]): FrontendRoleType[] => {
  return userRolesData.map((roleItem): FrontendRoleType => {
    return {
      id: roleItem.id,
      name: roleItem.name,
    };
  });
};

export const convertUserLimitedData = (userData: ApiResponseUserGetOne): FrontendUserLimitedType => {
  return {
    code: userData.code,
    name: `${userData.surname} ${userData.name}`,
    email: userData.email,
  };
};

export const convertMultipleUsersLimitedData = (usersData: ApiResponseUserGetOne[]): FrontendUserLimitedType[] => {
  return usersData.map((userItem): FrontendUserLimitedType => {
    return {
      code: userItem.code,
      name: `${userItem.surname} ${userItem.name}`,
      email: userItem.email,
    };
  });
};
