import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { FrontendFileIdResponseType } from "../../../types/apicallstypes/GeneralTypes.ts";
import { AxiosRequestConfig } from "axios";

const API = `${EPCM_API_URLS.projects}`;

export const getBulkMmrExport = async (projectId: number): Promise<FrontendFileIdResponseType> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/employees/export`,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  const responseData = response.data;

  return {
    fileId: responseData,
  };
};
