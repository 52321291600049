import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { EpcmMutationOptionsType } from "../../../config";
import { ErrorCallbackDataType, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons";
import { createProjectLocation } from "./createProjectLocation";
import { ProjectLocationData } from "../../../../types/apicallstypes/ProjectsUtilsApiTypes";

interface ApiDefinition {
  useCreateNewProjectLocationMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<unknown, unknown, ProjectLocationData, unknown>;
}

export const useEpcmApiProjectLocationMutation = (): ApiDefinition => {
  const useCreateNewProjectLocationMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ProjectLocationData, unknown>({
      mutationFn: async (locationData: ProjectLocationData) => {
        return createProjectLocation(projectId, locationData);
      },
      onSuccess: async (data) => {
        console.log("on post create new project location mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create new project location mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };
  return {
    useCreateNewProjectLocationMutation,
  };
};
