import { FC } from "react";
import classNames from "classnames";
import AddEntityActionButtonStyles from "./AddEntityActionButtonStyles.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

interface AddEntityActionButtonProps {
  icon: string;
  text: string;
  actionBtnText: string;
  actionFn: () => void;
}

const AddEntityActionButton: FC<AddEntityActionButtonProps> = ({ icon, text, actionBtnText, actionFn }) => {
  return (
    <div className={classNames(AddEntityActionButtonStyles.container, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
      <div>
        <img src={`${icon}`} alt={"action-img"}></img>
      </div>
      <div className={classNames(AddEntityActionButtonStyles.infoText, GlobalStyles.flex, GlobalStyles.flex1)}>{text}</div>
      <div onClick={actionFn} className={classNames(AddEntityActionButtonStyles.actionBtnText, GlobalStyles.elementWithCursor)}>
        {actionBtnText}
      </div>
    </div>
  );
};

export default AddEntityActionButton;
