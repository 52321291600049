import { PopupButtonTypeEnum, PopupType } from "../../../../../types/PopupType.ts";
import { Dispatch, FC, SetStateAction, useCallback } from "react";
import { PopupStructure } from "../../../../../ui/popupstructure/PopupStructure.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import ConfirmAddEmployeesToGroupPopupStyles from "./ConfirmAddEmployeesToGroupPopupStyles.module.css";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";

import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SubQueryNames,
  SuccessCallbackDataType,
} from "../../../../../types/apicallstypes/queryCommons.ts";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { ApiRequestBodyTimesheetGroupAddProjectEmployees } from "epcm-common/dist/Types/TimesheetGroupTypes.ts";
import { useQueryClient } from "@tanstack/react-query";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";

interface ConfirmAddEmployeesToGroupPopupProps extends PopupType {
  newMembers: number[];
  setNewMembers: Dispatch<SetStateAction<number[]>>;
  projectId: number;
  timesheetGroupId: number;
}

const ConfirmAddEmployeesToGroupPopup: FC<ConfirmAddEmployeesToGroupPopupProps> = ({
  newMembers,
  setNewMembers,
  projectId,
  timesheetGroupId,
  isOpen,
  closeFn,
  headerText,
  secondaryHeaderText,
}) => {
  const { useCreateTimesheetGroupEmployeesMutation } = useEpcmApiProjectTimesheetsGroupsMutations();
  const queryClient = useQueryClient();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const createTimesheetGroupEmployeesMutation = useCreateTimesheetGroupEmployeesMutation(projectId, timesheetGroupId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Employee was added to group successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onConfirmAddEmployeesToGroup = useCallback(() => {
    createTimesheetGroupEmployeesMutation.mutate({ projectEmployeesIds: newMembers } satisfies ApiRequestBodyTimesheetGroupAddProjectEmployees);
    onOpenLoaderPopup();
  }, [createTimesheetGroupEmployeesMutation, onOpenLoaderPopup, newMembers]);

  const invalidateQueries = useCallback(() => {
    void queryClient.resetQueries({ queryKey: [QueryNames.ProjectTimesheetGroupEmployeeCount, projectId, timesheetGroupId] });
    void queryClient.resetQueries({
      queryKey: [QueryNames.ProjectTimesheetGroupEmployeeCount, SubQueryNames.list, projectId, timesheetGroupId],
    });

    void queryClient.resetQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId.toString(), timesheetGroupId.toString()] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.TimesheetGroupEmployees, projectId] });
  }, [queryClient, projectId, timesheetGroupId]);

  return (
    <PopupStructure
      popupButtons={[
        { text: "Back to selection", buttonType: PopupButtonTypeEnum.neutral, action: closeFn },
        {
          text: "Proceed",
          disabled: newMembers.length == 0,
          buttonType: PopupButtonTypeEnum.main,
          action: () => onConfirmAddEmployeesToGroup(),
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      overrideContentContainerStyleClass={""}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.centerVertical,
          GlobalStyles.centerHorizontal,
          ConfirmAddEmployeesToGroupPopupStyles.content,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
          <div className={classNames(ConfirmAddEmployeesToGroupPopupStyles.codeText)}>{`${newMembers.length}`}</div>
          <div>{`new employee${newMembers.length > 1 ? "s" : ""} will be appointed to the group`}</div>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
            setNewMembers([]);
            closeFn();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ConfirmAddEmployeesToGroupPopup;
