import { FrontendProjectCategoryType } from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { FC, useCallback, useState } from "react";
import ConfigurationCategoriesRowStyles from "./ConfigurationCategoriesRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";

import TrashCanDarkIcon from "../../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIconHover from "../../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import EditIcon from "../../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../../assets/images/trashcan-icon-red.svg";
import { useQueryClient } from "@tanstack/react-query";

import ConfigurationCategoriesPopup from "../../popups/configurationcategoriespopup/ConfigurationCategoriesPopup.tsx";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../use-projects-popups.ts";
import { useProjectContext } from "../../../../ProjectsUtils.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { DeletePopup } from "../../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../types/Roles.ts";

interface ConfigurationTradeRowProps {
  categoryItem: FrontendProjectCategoryType;
}

const ConfigurationCategoriesRow: FC<ConfigurationTradeRowProps> = ({ categoryItem }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { currentProject } = useProjectContext();
  const queryClient = useQueryClient();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const { useDeleteProjectConfigurationEntityMutation } = useEpcmApiProjectUtilsMutations();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const deleteProjectCategoryMutation = useDeleteProjectConfigurationEntityMutation(
    currentProject.id,
    categoryItem.id,
    FrontendProjectConfigurationsEntityEnum.Category,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: "Project Category was deleted successfully!" });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectCategories, currentProject.id] });
  }, [queryClient, currentProject.id]);

  const canListConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canEditConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigUpdate);
  const canDeleteConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigDelete);

  return (
    <>
      <tr
        key={categoryItem.id}
        className={classNames(
          ConfigurationCategoriesRowStyles.itemContainer,
          isRowHovered && ConfigurationCategoriesRowStyles.rowHoverEffect,
          ConfigurationCategoriesRowStyles.Trow,
        )}
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
      >
        {categoryItem && canListConfiguration ? (
          <>
            <td
              className={classNames(
                ConfigurationCategoriesRowStyles.tableCell,
                ConfigurationCategoriesRowStyles.boldText,
                ConfigurationCategoriesRowStyles.tD,
              )}
            >
              {categoryItem.code}
            </td>
            <td className={classNames(ConfigurationCategoriesRowStyles.tableCell, ConfigurationCategoriesRowStyles.tD)}>
              {categoryItem.description}
            </td>
            <td className={classNames(ConfigurationCategoriesRowStyles.tableCellActions, ConfigurationCategoriesRowStyles.tD)}>
              <div
                className={classNames(
                  ConfigurationCategoriesRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                {canDeleteConfiguration && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();

                      onOpenUtilPopup();
                    }}
                    onMouseEnter={() => setDeleteIconHovered(true)}
                    onMouseLeave={() => setDeleteIconHovered(false)}
                  >
                    <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                  </div>
                )}
                {canEditConfiguration && (
                  <div
                    className={classNames(ConfigurationCategoriesRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                    onClick={() => onOpenPopup(ProjectsPagePopups.updateProjectCategory, popupHandler)}
                    onMouseEnter={() => setEditIconHovered(true)}
                    onMouseLeave={() => setEditIconHovered(false)}
                  >
                    <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                  </div>
                )}
              </div>
            </td>
          </>
        ) : (
          <></>
        )}
      </tr>
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteProjectCategoryMutation.mutate();

            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      {popupHandler.get(ProjectsPagePopups.updateProjectCategory)!.isOpen && (
        <ConfigurationCategoriesPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(ProjectsPagePopups.updateProjectCategory, popupHandler)}
          projectId={currentProject.id}
          isOpen={popupHandler.get(ProjectsPagePopups.updateProjectCategory)!.isOpen}
          secondaryHeaderText={currentProject.name}
          headerText={popupHeaders.get(ProjectsPagePopups.updateProjectCategory)}
          categoryItem={categoryItem}
        />
      )}
    </>
  );
};

export default ConfigurationCategoriesRow;
