import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { ChangeEvent, FC, MutableRefObject, useMemo, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import OvertimeRequestPopupStyles from "./OvertimeRequestPopup.module.css";
import classNames from "classnames";
import downloadTemplateIcon from "../../../../assets/images/spreadsheet-download.svg";
import downloadActiveIcon from "../../../../assets/images/spreadsheet-download-active.svg";
import downloadOverviewIcon from "../../../../assets/images/spreadsheet-download-overview.svg";
import uploadTemplateIcon from "../../../../assets/images/spreadsheet-upload.svg";
import { OvertimeRequestLoader } from "./overtimerequestloader/OvertimeRequestLoader.tsx";
import { DownloadFileView } from "./downloadfileview/DownloadFileView.tsx";
import { UploadFileView } from "./uploadfileview/UploadFileView.tsx";
import { FrontendOvertimeExportFileType, OvertimeButtonsWithHoverEnum, OvertimeButtonTypeEnum } from "../../../../types/projects/OvertimeTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../types/Roles.ts";
import InfoMessageIcon from "../../../../assets/images/info-message-icon.svg";
import { Fade, Tooltip } from "@mui/material";

interface OvertimeRequestPopupProps extends PopupType {
  exportOvertimeTemplateToFile: (type: FrontendOvertimeExportFileType) => void;
  isExportTemplateLoading: boolean;
  showDownloadView: boolean;
  saveOvertimeTemplateFile: () => void;
  cancelOvertimeTemplateFileDownload: () => void;
  isTemplateDownloading: boolean;
  uploadOvertimeXlsxRef: MutableRefObject<HTMLInputElement | null>;
  onOvertimeXlsxFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  showUploadView: boolean;
  cancelOvertimeXlsxFileUpload: () => void;
  onOvertimeXlsxFileUpload: () => void;
  isOvertimeRequestUploading: boolean;
}

export const OvertimeRequestPopup: FC<OvertimeRequestPopupProps> = ({
  isOpen,
  closeFn,
  headerText,
  secondaryHeaderText,
  exportOvertimeTemplateToFile,
  isExportTemplateLoading,
  showDownloadView,
  saveOvertimeTemplateFile,
  cancelOvertimeTemplateFileDownload,
  isTemplateDownloading,
  uploadOvertimeXlsxRef,
  onOvertimeXlsxFileChange,
  showUploadView,
  cancelOvertimeXlsxFileUpload,
  onOvertimeXlsxFileUpload,
  isOvertimeRequestUploading,
}) => {
  const [currentlyHovered, setCurrentlyHovered] = useState<OvertimeButtonsWithHoverEnum | null>(null);
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const showLoader = useMemo(
    () => isExportTemplateLoading || isTemplateDownloading || isOvertimeRequestUploading,
    [isExportTemplateLoading, isTemplateDownloading, isOvertimeRequestUploading],
  );

  const popupHeaderText = useMemo(
    () =>
      showDownloadView
        ? "Export Overtime Template"
        : showUploadView
          ? "Import Approved Request"
          : isExportTemplateLoading
            ? "Generating Template"
            : isTemplateDownloading
              ? "Downloading Template"
              : isOvertimeRequestUploading
                ? "Uploading Overtime Request"
                : headerText,
    [headerText, showDownloadView, showUploadView, isExportTemplateLoading, isTemplateDownloading, isOvertimeRequestUploading],
  );

  const popupSecondaryHeaderText = useMemo(() => (showLoader ? "Please wait" : secondaryHeaderText), [showLoader, secondaryHeaderText]);
  const canUploadOvertimeRequest = canPerformProjectAction(ProjectAction.ProjectEmployeeUpdate);

  const OVERTIME_BUTTON_DESCRIPTIONS: Readonly<Record<OvertimeButtonTypeEnum, string>> = {
    [OvertimeButtonTypeEnum.importOvertimeRequest]: "Upload a file with employee overtime data to update assignments.",
    [OvertimeButtonTypeEnum.exportOvertimeRequestTemplate]:
      "Download a template to enter overtime details for employees. Import it back to submit requests.",
    [OvertimeButtonTypeEnum.exportOvertimeRequestActive]:
      "Download current and upcoming overtime assignments. Edit and re-import to update assignments.",
    [OvertimeButtonTypeEnum.exportOvertimeRequestOverview]: "Download a full registry of all overtime assignments. This file is for reference only.",
  } as const;

  return (
    <PopupStructure
      popupButtons={
        showDownloadView
          ? [
              {
                text: "Cancel",
                buttonType: PopupButtonTypeEnum.neutral,
                action: cancelOvertimeTemplateFileDownload,
              },
              {
                text: "Download",
                buttonType: PopupButtonTypeEnum.main,
                action: saveOvertimeTemplateFile,
              },
            ]
          : showUploadView
            ? [
                {
                  text: "Cancel",
                  buttonType: PopupButtonTypeEnum.neutral,
                  action: cancelOvertimeXlsxFileUpload,
                },
                {
                  text: "Upload",
                  buttonType: PopupButtonTypeEnum.main,
                  action: onOvertimeXlsxFileUpload,
                },
              ]
            : []
      }
      isOpen={isOpen}
      closeFn={() => (!showLoader ? closeFn() : {})}
      headerText={popupHeaderText}
      secondaryHeaderText={popupSecondaryHeaderText}
      hideCloseHeaderButton={showLoader}
      overrideContentContainerStyleClass={""}
    >
      {showLoader ? (
        <OvertimeRequestLoader />
      ) : showDownloadView ? (
        <DownloadFileView />
      ) : showUploadView ? (
        <UploadFileView />
      ) : (
        <div className={classNames(OvertimeRequestPopupStyles.contentContainer, GlobalStyles.flex, GlobalStyles.gap)}>
          {canUploadOvertimeRequest && (
            <div
              className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
              onMouseEnter={() => setCurrentlyHovered(OvertimeButtonsWithHoverEnum.upload)}
              onMouseLeave={() => setCurrentlyHovered(null)}
              onClick={() => uploadOvertimeXlsxRef.current?.click()}
            >
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(OvertimeRequestPopupStyles.templateIconImg)} src={uploadTemplateIcon} alt="upload" />
              </div>
              <div
                className={classNames(
                  OvertimeRequestPopupStyles.text,
                  currentlyHovered === OvertimeButtonsWithHoverEnum.upload && OvertimeRequestPopupStyles.textHovered,
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                )}
              >
                {"Import Overtime Data"}
                <Tooltip title={OVERTIME_BUTTON_DESCRIPTIONS[OvertimeButtonTypeEnum.importOvertimeRequest]} arrow TransitionComponent={Fade}>
                  <div className={classNames(OvertimeRequestPopupStyles.infoIcon)}>
                    <img src={InfoMessageIcon} className={classNames(OvertimeRequestPopupStyles.infoImg)} alt="info message icon" />
                  </div>
                </Tooltip>
              </div>
              <input ref={uploadOvertimeXlsxRef} type="file" onChange={onOvertimeXlsxFileChange} hidden accept={".xlsx"} />
            </div>
          )}
          <div
            className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
            onMouseEnter={() => setCurrentlyHovered(OvertimeButtonsWithHoverEnum.downloadTemplate)}
            onMouseLeave={() => setCurrentlyHovered(null)}
            onClick={() => exportOvertimeTemplateToFile(FrontendOvertimeExportFileType.TEMPLATE)}
          >
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(OvertimeRequestPopupStyles.templateIconImg)} src={downloadTemplateIcon} alt="downloadTemplate" />
            </div>
            <div
              className={classNames(
                OvertimeRequestPopupStyles.text,
                currentlyHovered === OvertimeButtonsWithHoverEnum.downloadTemplate && OvertimeRequestPopupStyles.textHovered,
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
              )}
            >
              {"Export Overtime Template"}
              <Tooltip title={OVERTIME_BUTTON_DESCRIPTIONS[OvertimeButtonTypeEnum.exportOvertimeRequestTemplate]} arrow TransitionComponent={Fade}>
                <div className={classNames(OvertimeRequestPopupStyles.infoIcon)}>
                  <img src={InfoMessageIcon} className={classNames(OvertimeRequestPopupStyles.infoImg)} alt="info message icon" />
                </div>
              </Tooltip>
            </div>
          </div>
          <div
            className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
            onMouseEnter={() => setCurrentlyHovered(OvertimeButtonsWithHoverEnum.downloadActive)}
            onMouseLeave={() => setCurrentlyHovered(null)}
            onClick={() => exportOvertimeTemplateToFile(FrontendOvertimeExportFileType.ACTIVE)}
          >
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(OvertimeRequestPopupStyles.templateIconImg)} src={downloadActiveIcon} alt="downloadTemplate" />
            </div>

            <div
              className={classNames(
                OvertimeRequestPopupStyles.text,
                currentlyHovered === OvertimeButtonsWithHoverEnum.downloadActive && OvertimeRequestPopupStyles.textHovered,
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
              )}
            >
              {"Export Active Overtime "}
              <Tooltip title={OVERTIME_BUTTON_DESCRIPTIONS[OvertimeButtonTypeEnum.exportOvertimeRequestActive]} arrow TransitionComponent={Fade}>
                <div className={classNames(OvertimeRequestPopupStyles.infoIcon)}>
                  <img src={InfoMessageIcon} className={classNames(OvertimeRequestPopupStyles.infoImg)} alt="info message icon" />
                </div>
              </Tooltip>
            </div>
          </div>
          <div
            className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
            onMouseEnter={() => setCurrentlyHovered(OvertimeButtonsWithHoverEnum.downloadOverview)}
            onMouseLeave={() => setCurrentlyHovered(null)}
            onClick={() => exportOvertimeTemplateToFile(FrontendOvertimeExportFileType.OVERVIEW)}
          >
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(OvertimeRequestPopupStyles.templateIconImg)} src={downloadOverviewIcon} alt="downloadTemplate" />
            </div>
            <div
              className={classNames(
                OvertimeRequestPopupStyles.text,
                currentlyHovered === OvertimeButtonsWithHoverEnum.downloadOverview && OvertimeRequestPopupStyles.textHovered,
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
              )}
            >
              {"Export Full Overtime Registry "}
              <Tooltip title={OVERTIME_BUTTON_DESCRIPTIONS[OvertimeButtonTypeEnum.exportOvertimeRequestOverview]} arrow TransitionComponent={Fade}>
                <div className={classNames(OvertimeRequestPopupStyles.infoIcon)}>
                  <img src={InfoMessageIcon} className={classNames(OvertimeRequestPopupStyles.infoImg)} alt="info message icon" />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </PopupStructure>
  );
};
