import { Accordion, styled } from "@mui/material";

export const EPCMAccordionCyan = styled(Accordion)({
  // Styles for the root
  boxShadow: "none",

  padding: "0em 0em",
  backgroundColor: "#eff2fa",

  "& .MuiAccordionSummary-root,.MuiAccordionDetails-root": {
    padding: "0em 0em",
    overflow: "auto",
  },
  "& .MuiAccordionSummary-root": {
    padding: "0em 0.6em 0em 0em",
  },
  "& .MuiAccordionDetails-root": {
    maxHeight: "13em",
    overflowY: "auto",
  },
});
