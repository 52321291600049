import {
  FrontendProjectManpowerPositionDetailedType,
  FrontendProjectManpowerPositionLimitedType,
} from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import ConfigurationsManpowerRowStyles from "../../configurationlocations/configurationlocationsrow/ConfigurationLocationsRow.module.css";
import { Skeleton } from "@mui/material";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import TrashCanRedIcon from "../../../../../../assets/images/trashcan-icon-red.svg";
import TrashCanDarkIcon from "../../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIconHover from "../../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import EditIcon from "../../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import ConfigurationManpowerPositionRowStyles from "./ConfigurationManpowerPositionsRow.module.css";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import ConfigurationManpowerCodesPopup from "../../popups/configurationmanpowercodespopup/ConfigurationManpowerCodesPopup.tsx";
import { useProjectContext } from "../../../../ProjectsUtils.ts";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../use-projects-popups.ts";
import { useEpcmApiProjectManpowerCodes } from "../../../../../../apicalls/projects/projectmanpowercodes/useEpcmApiProjectManpowerCodes.ts";
import {
  useEpcmApiProjectUtilsMutations,
  FrontendProjectConfigurationsEntityEnum,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { useEpcmApiProjectsUtils } from "../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { getInitials } from "../../../../../../utils/StringManipulation.ts";
import { DeletePopup } from "../../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../types/Roles.ts";

interface ConfigurationManpowerPositionRowProps {
  manpowerPositionLimitedItem: FrontendProjectManpowerPositionLimitedType;
}

const ConfigurationManpowerPositionRow: FC<ConfigurationManpowerPositionRowProps> = ({ manpowerPositionLimitedItem }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const { currentProject } = useProjectContext();
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const { getManpowerPositionCode } = useEpcmApiProjectManpowerCodes();
  const { useDeleteProjectConfigurationEntityMutation } = useEpcmApiProjectUtilsMutations();
  const { getProjectLocation, getProjectCategory, getProjectPosition, getProjectTrade } = useEpcmApiProjectsUtils();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const manpowerPositionQuery = useQuery({
    queryKey: [QueryNames.ProjectPositionCodes, currentProject.id, manpowerPositionLimitedItem.id],
    queryFn: () => getManpowerPositionCode(currentProject.id, manpowerPositionLimitedItem.id, DetailLevel.DETAILED),
    enabled: isAuthorized,
    select: (data) => data as FrontendProjectManpowerPositionDetailedType,
  });

  const manpowerPositionData = useMemo(() => {
    return manpowerPositionQuery.data;
  }, [manpowerPositionQuery.data]);

  const manpowerPositionLocationQuery = useQuery({
    queryKey: [QueryNames.ProjectLocations, currentProject.id, manpowerPositionLimitedItem.id, manpowerPositionData?.locationId],
    queryFn: () => getProjectLocation(currentProject.id, manpowerPositionData!.locationId, DetailLevel.LIMITED),
    enabled: isAuthorized && !!manpowerPositionData?.locationId,
  });

  const manpowerPositionLocationData = useMemo(() => {
    return manpowerPositionLocationQuery.data;
  }, [manpowerPositionLocationQuery.data]);

  const manpowerPositionCategoryQuery = useQuery({
    queryKey: [QueryNames.ProjectCategories, currentProject.id, manpowerPositionLimitedItem.id, manpowerPositionData?.categoryId],
    queryFn: () => getProjectCategory(currentProject.id, manpowerPositionData!.categoryId, DetailLevel.LIMITED),
    enabled: isAuthorized && !!manpowerPositionData?.categoryId,
  });

  const manpowerPositionCategoryData = useMemo(() => {
    return manpowerPositionCategoryQuery.data;
  }, [manpowerPositionCategoryQuery.data]);

  const manpowerPositionPositionQuery = useQuery({
    queryKey: [QueryNames.ProjectPositions, currentProject.id, manpowerPositionLimitedItem.id, manpowerPositionData?.positionId],
    queryFn: () => getProjectPosition(currentProject.id, manpowerPositionData!.positionId, DetailLevel.LIMITED),
    enabled: isAuthorized && !!manpowerPositionData?.positionId,
  });

  const manpowerPositionPositionData = useMemo(() => {
    return manpowerPositionPositionQuery.data;
  }, [manpowerPositionPositionQuery.data]);

  const manpowerPositionTradeQuery = useQuery({
    queryKey: [QueryNames.ProjectTrades, currentProject.id, manpowerPositionLimitedItem.id, manpowerPositionData?.tradeId],
    queryFn: () => getProjectTrade(currentProject.id, manpowerPositionData!.tradeId, DetailLevel.LIMITED),
    enabled: isAuthorized && !!manpowerPositionData?.tradeId,
  });

  const manpowerPositionTradeData = useMemo(() => {
    return manpowerPositionTradeQuery.data;
  }, [manpowerPositionTradeQuery.data]);

  const deleteProjectManpowerCode = useDeleteProjectConfigurationEntityMutation(
    currentProject.id,
    manpowerPositionLimitedItem.id,
    FrontendProjectConfigurationsEntityEnum.ManpowerPosition,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: "Project Manpower Code was deleted successfully!" });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectPositionCodes, currentProject.id] });
  }, [queryClient, currentProject.id]);

  const canListConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canDeleteConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigDelete);
  const canUpdateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigUpdate);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectPositionCodes, currentProject.id, manpowerPositionLimitedItem.id] }).then(() => {
        console.log(
          `In configuration-manpower-position-row component, ${QueryNames.ProjectPositionCodes} query for position ${manpowerPositionLimitedItem.id} cancelled`,
        );
      });
    };
  }, [currentProject.id, manpowerPositionLimitedItem.id, queryClient]);

  return (
    <tr
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseLeave={() => setIsRowHovered(false)}
      key={manpowerPositionLimitedItem.id}
      className={classNames(
        ConfigurationsManpowerRowStyles.itemContainer,
        isRowHovered && ConfigurationsManpowerRowStyles.rowHoverEffect,
        ConfigurationsManpowerRowStyles.Trow,
      )}
    >
      {manpowerPositionLimitedItem && canListConfiguration ? (
        <>
          <td
            className={classNames(
              ConfigurationManpowerPositionRowStyles.tableCell,
              ConfigurationManpowerPositionRowStyles.boldText,
              ConfigurationsManpowerRowStyles.tD,
            )}
          >
            {manpowerPositionLimitedItem.code}
          </td>
          <td
            className={classNames(
              ConfigurationManpowerPositionRowStyles.tableCell,
              ConfigurationManpowerPositionRowStyles.boldText,
              ConfigurationsManpowerRowStyles.tD,
            )}
          >
            {manpowerPositionLocationData ? (
              getInitials(manpowerPositionLocationData.description)
            ) : (
              <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>
            )}
          </td>
          <td
            className={classNames(
              ConfigurationManpowerPositionRowStyles.tableCell,
              ConfigurationManpowerPositionRowStyles.boldText,
              ConfigurationsManpowerRowStyles.tD,
            )}
          >
            {manpowerPositionCategoryData ? (
              `${manpowerPositionCategoryData.description} [${manpowerPositionCategoryData.code}]`
            ) : (
              <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>
            )}
          </td>
          <td
            className={classNames(
              ConfigurationManpowerPositionRowStyles.tableCell,
              ConfigurationManpowerPositionRowStyles.boldText,
              ConfigurationsManpowerRowStyles.tD,
            )}
          >
            {manpowerPositionPositionData ? (
              manpowerPositionPositionData.description
            ) : (
              <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>
            )}
          </td>
          <td
            className={classNames(
              ConfigurationManpowerPositionRowStyles.tableCell,
              ConfigurationManpowerPositionRowStyles.boldText,
              ConfigurationsManpowerRowStyles.tD,
            )}
          >
            {manpowerPositionTradeData ? manpowerPositionTradeData.description : <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>}
          </td>

          <td
            className={classNames(
              ConfigurationManpowerPositionRowStyles.tableCell,
              ConfigurationManpowerPositionRowStyles.boldText,
              ConfigurationsManpowerRowStyles.tD,
            )}
          >
            {manpowerPositionData ? `$${manpowerPositionData.rate}` : <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>}
          </td>
          <td
            className={classNames(
              ConfigurationManpowerPositionRowStyles.tableCell,
              ConfigurationManpowerPositionRowStyles.boldText,
              ConfigurationsManpowerRowStyles.tD,
            )}
          >
            {manpowerPositionData ? manpowerPositionData.pricingScheduledManhours : <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>}
          </td>
          <td className={classNames(ConfigurationManpowerPositionRowStyles.tableCellActions, ConfigurationsManpowerRowStyles.tD)}>
            <div
              className={classNames(
                ConfigurationManpowerPositionRowStyles.tableCellActionsDiv,
                GlobalStyles.flex,
                GlobalStyles.gap2,
                GlobalStyles.elementWithCursor,
              )}
            >
              {canDeleteConfiguration && (
                <div
                  onClick={(event) => {
                    event.stopPropagation();

                    onOpenUtilPopup();
                  }}
                  onMouseEnter={() => setDeleteIconHovered(true)}
                  onMouseLeave={() => setDeleteIconHovered(false)}
                >
                  <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                </div>
              )}
              {canUpdateConfiguration && (
                <div
                  className={classNames(ConfigurationManpowerPositionRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                  onClick={() => onOpenPopup(ProjectsPagePopups.updateProjectManpowerPosition, popupHandler)}
                  onMouseEnter={() => setEditIconHovered(true)}
                  onMouseLeave={() => setEditIconHovered(false)}
                >
                  <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                </div>
              )}
            </div>
          </td>
          {isUtilPopupOpen && (
            <DeletePopup
              isOpen={isUtilPopupOpen}
              closeFn={() => {
                onCloseUtilPopup();
              }}
              onDeleteFn={() => {
                deleteProjectManpowerCode.mutate();
                onOpenLoaderPopup();
              }}
            />
          )}
          {isResponseAlertPopupOpen && responseType && responseObject && (
            <ResponseAlertPopup
              responseType={responseType}
              responseObject={responseObject}
              isOpen={isResponseAlertPopupOpen}
              closeFn={() => {
                initializeResponseAlertPopup();
                onCloseResponseAlertPopup();
                onCloseUtilPopup();
                responseType === ApiResponseTypeEnum.success && invalidateQueries();
              }}
            />
          )}
          {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
        </>
      ) : (
        <></>
      )}
      {popupHandler.get(ProjectsPagePopups.updateProjectManpowerPosition)!.isOpen && (
        <ConfigurationManpowerCodesPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(ProjectsPagePopups.updateProjectManpowerPosition, popupHandler)}
          projectId={currentProject.id}
          isOpen={popupHandler.get(ProjectsPagePopups.updateProjectManpowerPosition)!.isOpen}
          secondaryHeaderText={currentProject.name}
          headerText={popupHeaders.get(ProjectsPagePopups.updateProjectManpowerPosition)}
          manpowerCodeItem={{
            id: manpowerPositionLimitedItem.id,
            code: manpowerPositionLimitedItem.code,
            location: manpowerPositionLocationData!,
            category: manpowerPositionCategoryData!,
            position: manpowerPositionPositionData!,
            trade: manpowerPositionTradeData!,
            rate: manpowerPositionData!.rate,
            pricingScheduledManhours: manpowerPositionData!.pricingScheduledManhours,
          }}
        />
      )}
    </tr>
  );
};
export default ConfigurationManpowerPositionRow;
