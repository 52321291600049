import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import ViewProjectInfoStyles from "./ViewProjectInfo.module.css";
import ProjectInfoItem from "./projectinfoitem/ProjectInfoItem.tsx";
import { formatDate } from "../../../../../utils/DateManipulation.ts";
import { FC, useEffect, useMemo } from "react";
import { FrontendProjectDetailedType } from "../../../../../types/apicallstypes/ProjectsApiTypes.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useEpcmApiUsers } from "../../../../../apicalls/users/useEpcmApiUsers.ts";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { FrontendUserLimitedType } from "../../../../../types/apicallstypes/UsersApiTypes.ts";
import { Skeleton } from "@mui/material";
import { capitalizeFirstLetter } from "../../../../../utils/StringManipulation.ts";
import { FrontendProjectFieldsEnum } from "../../../../../types/projects/ProjectsTypes.ts";

interface ViewProjectInfoProps {
  projectInfoObject: FrontendProjectDetailedType;
}

const ViewProjectInfo: FC<ViewProjectInfoProps> = ({ projectInfoObject }) => {
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getUser } = useEpcmApiUsers();

  const creatorCodeQuery = useQuery({
    queryKey: [QueryNames.User, projectInfoObject.creator.code],
    queryFn: () => getUser(projectInfoObject.creator.code),
    enabled: isAuthorized && !!projectInfoObject.creator.code,
    select: (data) => data as FrontendUserLimitedType,
  });

  const creatorData = useMemo(() => {
    return creatorCodeQuery.data;
  }, [creatorCodeQuery.data]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.Users, projectInfoObject.creator.code] });
    };
  }, [queryClient, projectInfoObject.creator.code]);

  return (
    <div
      className={classNames(
        ViewProjectInfoStyles.columnWrapper,
        GlobalStyles.flex,
        GlobalStyles.centerHorizontal,
        GlobalStyles.gap,
        GlobalStyles.overflowAutoFullHeight,
      )}
    >
      <div
        className={classNames(
          ViewProjectInfoStyles.columnContent,
          ViewProjectInfoStyles.border,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
        )}
      >
        <ProjectInfoItem labelTitle={FrontendProjectFieldsEnum.PROJECT_CODE} labelValue={projectInfoObject?.code} />
        <ProjectInfoItem labelTitle={FrontendProjectFieldsEnum.PROJECT_NAME} labelValue={projectInfoObject.name} />
        <ProjectInfoItem labelTitle={FrontendProjectFieldsEnum.PROJECT_STATUS} labelValue={capitalizeFirstLetter(projectInfoObject.status)} />
      </div>
      <div
        className={classNames(
          ViewProjectInfoStyles.columnContent,
          ViewProjectInfoStyles.border,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
        )}
      >
        {creatorData ? (
          <ProjectInfoItem labelTitle={"CREATOR NAME"} labelValue={creatorData?.name} />
        ) : (
          <Skeleton variant="text" width={200} height={40} />
        )}
        <ProjectInfoItem labelTitle={FrontendProjectFieldsEnum.AGREEMENT_NUMBER} labelValue={projectInfoObject.agreementNumber} />
        <ProjectInfoItem labelTitle={FrontendProjectFieldsEnum.DATE_OF_SIGN} labelValue={formatDate(projectInfoObject.dateOfSign)} />
        <ProjectInfoItem labelTitle={FrontendProjectFieldsEnum.DURATION} labelValue={projectInfoObject.duration} />
        <ProjectInfoItem labelTitle={FrontendProjectFieldsEnum.PROJECT_LOCATION} labelValue={projectInfoObject.projectLocation} />
        <ProjectInfoItem labelTitle={FrontendProjectFieldsEnum.MMR_PREFIX} labelValue={projectInfoObject.mmrPrefix} />
        <ProjectInfoItem labelTitle={FrontendProjectFieldsEnum.OVERTIME_PREFIX} labelValue={projectInfoObject.overtimePrefix} />
      </div>
      <div
        className={classNames(
          ViewProjectInfoStyles.columnContent,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(ViewProjectInfoStyles.managerLabel)}>ARCHIRODON</div>
          <div
            className={classNames(ViewProjectInfoStyles.managerInputsPadding, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
          >
            <ProjectInfoItem labelTitle={FrontendProjectFieldsEnum.PROJECT_MANAGER} labelValue={projectInfoObject?.projectManager?.name ?? "-"} />
            <ProjectInfoItem
              labelTitle={FrontendProjectFieldsEnum.PROJECT_CONTROLS_MANAGER}
              labelValue={projectInfoObject?.projectControlsManager?.name ?? "-"}
            />
            <ProjectInfoItem
              labelTitle={FrontendProjectFieldsEnum.PROJECT_COST_CONTROL_ENGINEER}
              labelValue={projectInfoObject?.projectCostControlEngineer?.name ?? "-"}
            />
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(ViewProjectInfoStyles.managerLabel)}>CLIENT</div>
          <div
            className={classNames(ViewProjectInfoStyles.managerInputsPadding, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
          >
            <ProjectInfoItem
              labelTitle={FrontendProjectFieldsEnum.PROJECT_CLIENT_SENIOR_MANAGER}
              labelValue={projectInfoObject?.projectClientSeniorManager ?? "-"}
            />
            <ProjectInfoItem
              labelTitle={FrontendProjectFieldsEnum.PROJECT_CONTROLS_MANAGER}
              labelValue={projectInfoObject?.projectClientControlsManager ?? "-"}
            />
            <ProjectInfoItem
              labelTitle={FrontendProjectFieldsEnum.PROJECT_COST_CONTROL_ENGINEER}
              labelValue={projectInfoObject?.projectClientCostControlEngineer ?? "-"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProjectInfo;
