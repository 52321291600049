import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import CalendarManagementStyles from "./CalendarManagementEmpty.module.css";
import { useEffect, useMemo } from "react";
import { MonthList } from "./monthlist/MonthList.tsx";
import projectDurationImg from "../../../../../assets/images/configuration-calendar-duration.svg";
import currentDateImg from "../../../../../assets/images/configuration-current-date.svg";
import cutOffDateImg from "../../../../../assets/images/configuration-cutoff-date.svg";
import locationsImg from "../../../../../assets/images/configuration-locations.svg";
import { useParams } from "react-router-dom";

import { CalendarManagementEmpty } from "./CalendarManagementEmpty.tsx";
import { useEpcmApiConfigurationCalendarInfo } from "../../../../../apicalls/configurations/calendar/useEpcmApiConfigurationCalendarInfo.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { EPCMInfoContainerDiv } from "../../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import { formatDate } from "../../../../../utils/DateManipulation.ts";
import RectangleGraySeparator from "../../../../../assets/images/rectangle-gray-separator.svg";
import { Fade, Tooltip } from "@mui/material";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";

export const CalendarManagement = () => {
  const { projectId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getGeneralCalendarInfo } = useEpcmApiConfigurationCalendarInfo();
  const queryClient = useQueryClient();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const projectCalendarManagementQuery = useQuery({
    queryKey: [QueryNames.ProjectCalendar, projectId],
    queryFn: () => getGeneralCalendarInfo(parseInt(projectId!)),
    enabled: isAuthorized,
  });

  const projectCalendarManagementData = useMemo(() => {
    return projectCalendarManagementQuery.data;
  }, [projectCalendarManagementQuery]);

  const canListCalendar = canPerformProjectAction(ProjectAction.ProjectConfigList);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectCalendar, projectId] });
    };
  }, [queryClient, projectId]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3, GlobalStyles.overflowHiddenFullHeight)}>
      {!projectCalendarManagementQuery.isPending ? (
        projectCalendarManagementData && canListCalendar && projectCalendarManagementData?.cutOffDay != null ? (
          <EPCMInfoContainerDiv
            className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}
          >
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
              <div className={classNames(CalendarManagementStyles.calendarTitle)}>Calendar Management</div>
              <div className={classNames(GlobalStyles.flex6, GlobalStyles.flex3)} />
              <div className={classNames(GlobalStyles.flex2)}></div>
            </div>
            <div className={classNames(CalendarManagementStyles.managementContainer, GlobalStyles.flex, GlobalStyles.gap4)}>
              <Tooltip title={"Calendar Duration"} arrow TransitionComponent={Fade}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                  <img className={classNames(CalendarManagementStyles.imgStyling)} src={projectDurationImg} alt="duration" />
                  <div>{projectCalendarManagementData.duration} Months</div>
                </div>
              </Tooltip>
              <Tooltip title={"Date of Sign"} arrow TransitionComponent={Fade}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                  <img className={classNames(CalendarManagementStyles.imgStyling)} src={currentDateImg} alt="current" />
                  <div>{formatDate(new Date(parseInt(projectCalendarManagementData.dateOfSignTimestamp, 10)))}</div>
                </div>
              </Tooltip>
              <Tooltip title={"Cut-Off Date"} arrow TransitionComponent={Fade}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                  <img className={classNames(CalendarManagementStyles.imgStyling)} src={cutOffDateImg} alt="cutoff" />
                  <div>{projectCalendarManagementData.cutOffDay}th of the month</div>
                </div>
              </Tooltip>
              <Tooltip title={"Locations"} arrow TransitionComponent={Fade}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
                  <img className={classNames(CalendarManagementStyles.imgStyling)} src={locationsImg} alt="locations" />
                  {projectCalendarManagementData.locations.map((location) => (
                    <div key={location.id} className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
                      <div>
                        <img src={RectangleGraySeparator} alt={location.description} />
                      </div>

                      <div key={location.id}>{location.description}</div>
                    </div>
                  ))}
                </div>
              </Tooltip>
            </div>
            <div className={classNames(CalendarManagementStyles.projectMenuContainer, GlobalStyles.flex, GlobalStyles.gap)}></div>
            <div
              className={classNames(
                CalendarManagementStyles.monthListContainer,
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
                GlobalStyles.overflowHiddenFullHeight,
                GlobalStyles.gap05,
              )}
            >
              {canListCalendar && <MonthList />}
            </div>
          </EPCMInfoContainerDiv>
        ) : (
          <CalendarManagementEmpty />
        )
      ) : null}
    </div>
  );
};
