import { PopupHandlerReturnType, PopupHandlerTypeAsMap } from "../../types/PopupHandlerTypes.ts";
import { useMemo, useState } from "react";
import { onClosePopup, onOpenPopup } from "../../utils/PopupHandlerUtils.ts";

export enum ProjectsPagePopups {
  createProject = "create-project",
  changeProjectEmployeeMobilizationStatus = "change-project-employee-mobilization-status",
  changeProjectEmployeeMobilizationStatusFollowup = "change-project-employee-mobilization-status-follow-up",
  changeManpowerPlanStatus = "change-manpower-plan-status",
  changeManpowerPlanStatusFollowup = "change-manpower-plan-status-follow-up",
  manpowerPlanAddReferenceFiles = "manpower-plan-add-reference-files",
  manpowerPlanReferenceFilesList = "manpower-plan-reference-files-list",
  overtimeRequest = "overtime-request",
  bulkMmrUpload = "bulk-mmr-upload",
  addProjectEmployees = "add-project-employees",
  uploadManpowerPlanVersion = "upload-manpower-plan-version",
  addProjectEmployeesFilters = "add-project-employees-filters",
  viewProjectEmployee = "view-employee",
  selectManpowerCode = "select-manpower-code",
  changeMmrStatus = "change-mmr-status",
  mmrActions = "mmr-actions",
  changeTimesheetStatus = "change-timesheet-status",
  changeEntityStatus = "change-entity-status",
  timesheetGroupSettings = "timesheet-group-settings",
  viewUngroupedEmployees = "view-ungrouped-employees",
  createProjectGroup = "create-project-group",
  updateProjectGroupName = "update-project-group-name",
  confirmAddEmployeesToGroup = "confirm-add-employees-to-group",
  confirmAddSectionHeadToGroup = "confirm-add-section-head-to-group",
  selectGroup = "select-group",
  selectCtrCode = "select-ctr-code",
  addProjectPosition = "add-project-position",
  addProjectSubPosition = "add-project-sub-position",
  addProjectLocation = "add-project-location",
  addProjectTrade = "add-project-trade",
  addProjectCategory = "add-project-category",
  addProjectCtr = "add-project-ctr",
  addProjectDiscipline = "add-project-discipline",
  addProjectManpowerPosition = "add-project-manpower-position",
  addProjectUser = "add-project-user",
  setNewTimesheet = "set-new-timesheet",
  setUpCalendar = "set-up-calendar",
  confirmCalendarCreation = "confirm-calendar-creation",
  updateProjectDiscipline = "update-project-discipline",
  updateProjectCategory = "update-project-category",
  updateProjectTrade = "update-project-trade",
  updateProjectLocation = "update-project-location",
  updateProjectCtr = "update-project-ctr",
  updateProjectManpowerPosition = "update-project-manpower-position",
  updateProjectPosition = "update-project-position",
  updateProjectSubPosition = "update-project-sub-position",
  updateProjectUserRole = "update-project-user-role",
  updateGlobalUserRole = "update-global-user-role",
  documentsFilter = "documents-filter",
  createProjectReport = "create-project-report",
  selectUserForProjectRole = "select-user-for-project-role",
}

export enum ProjectsPagePopupHeaders {
  createProject = "Create New Project",
  changeProjectEmployeeMobilizationStatus = "Mobilization Status",
  changeProjectEmployeeMobilizationStatusFollowup = "Mobilization Status:",
  changeManpowerPlanStatus = "Status",
  referenceFiles = "Reference Files",
  overtimeRequest = "Overtime Request",
  importBulkMmr = "Import Bulk MMR",
  addProjectEmployees = "Add Employees",
  uploadManpowerPlanVersion = "Upload version",
  addProjectEmployeesFilters = "Apply Filers",
  viewProjectEmployee = "View Employee",
  selectManpowerCode = "Select Manpower Position",
  changeMmrStatus = "MMR Status",
  mmrActions = "MMR Actions",
  changeTimesheetStatus = "Timesheet Status",
  changeEntityStatus = "Change Status",
  timesheetGroupSettings = "Timesheet Group Settings",
  viewUngroupedEmployees = "Ungrouped Employees",
  setNewTimesheet = "New Timesheet",
  createProjectGroup = "New Group",
  updateProjectGroupName = "Update Group Name",
  confirmAddEmployeesToGroup = "Add Employees to Group",
  confirmAddSectionHeadToGroup = "Add Section Head to Group",
  selectGroup = "Select Group",
  selectCtrCode = "Select CTR Code",
  setUpCalendar = "Setting up calendar",
  confirmCalendarCreateion = "Confirm Calendar Creation",
  addProjectPosition = "Add Position",
  addProjectSubPosition = "Add Sub Position",
  addProjectLocation = "Add Location",
  addProjectTrade = "Add Trade",
  addProjectCategory = "Add Category",
  addProjectCtr = "Add CTR",
  addProjectDiscipline = "Add Discipline",
  addProjectManpowerPosition = "Add Manpower Position",
  addProjectUser = "Add User",
  updateProjectDiscipline = "Update Project Discipline",
  updateProjectCategory = "Update Project Category",
  updateProjectTrade = "Update Project Trade",
  updateProjectLocation = "Update Project Location",
  updateProjectCtr = "Update Project CTR",
  updateProjectManpowerPosition = "Update Project Manpower Position",
  updateProjectPosition = "Update Project Position",
  updateProjectSubPosition = "Update Project Sub Position",
  updateProjectUserRole = "Update User Roles",
  updateGlobalUserRole = "Update Global User Roles",
  documentsFilter = "Filter Documents",
  createProjectReport = "Create Project Report",
  selectUserForProjectRole = "Select User For Project Role",
}

const popupHeadersMap: Map<ProjectsPagePopups, ProjectsPagePopupHeaders> = new Map([
  [ProjectsPagePopups.createProject, ProjectsPagePopupHeaders.createProject],
  [ProjectsPagePopups.changeProjectEmployeeMobilizationStatus, ProjectsPagePopupHeaders.changeProjectEmployeeMobilizationStatus],
  [ProjectsPagePopups.changeProjectEmployeeMobilizationStatusFollowup, ProjectsPagePopupHeaders.changeProjectEmployeeMobilizationStatusFollowup],
  [ProjectsPagePopups.changeManpowerPlanStatus, ProjectsPagePopupHeaders.changeManpowerPlanStatus],
  [ProjectsPagePopups.changeManpowerPlanStatusFollowup, ProjectsPagePopupHeaders.referenceFiles],
  [ProjectsPagePopups.manpowerPlanAddReferenceFiles, ProjectsPagePopupHeaders.referenceFiles],
  [ProjectsPagePopups.manpowerPlanReferenceFilesList, ProjectsPagePopupHeaders.referenceFiles],
  [ProjectsPagePopups.overtimeRequest, ProjectsPagePopupHeaders.overtimeRequest],
  [ProjectsPagePopups.bulkMmrUpload, ProjectsPagePopupHeaders.importBulkMmr],
  [ProjectsPagePopups.addProjectEmployees, ProjectsPagePopupHeaders.addProjectEmployees],
  [ProjectsPagePopups.uploadManpowerPlanVersion, ProjectsPagePopupHeaders.uploadManpowerPlanVersion],
  [ProjectsPagePopups.addProjectEmployeesFilters, ProjectsPagePopupHeaders.addProjectEmployeesFilters],
  [ProjectsPagePopups.viewProjectEmployee, ProjectsPagePopupHeaders.viewProjectEmployee],
  [ProjectsPagePopups.selectManpowerCode, ProjectsPagePopupHeaders.selectManpowerCode],
  [ProjectsPagePopups.changeMmrStatus, ProjectsPagePopupHeaders.changeMmrStatus],
  [ProjectsPagePopups.mmrActions, ProjectsPagePopupHeaders.mmrActions],
  [ProjectsPagePopups.timesheetGroupSettings, ProjectsPagePopupHeaders.timesheetGroupSettings],
  [ProjectsPagePopups.viewUngroupedEmployees, ProjectsPagePopupHeaders.viewUngroupedEmployees],
  [ProjectsPagePopups.setNewTimesheet, ProjectsPagePopupHeaders.setNewTimesheet],
  [ProjectsPagePopups.createProjectGroup, ProjectsPagePopupHeaders.createProjectGroup],
  [ProjectsPagePopups.updateProjectGroupName, ProjectsPagePopupHeaders.updateProjectGroupName],
  [ProjectsPagePopups.confirmAddEmployeesToGroup, ProjectsPagePopupHeaders.confirmAddEmployeesToGroup],
  [ProjectsPagePopups.confirmAddSectionHeadToGroup, ProjectsPagePopupHeaders.confirmAddSectionHeadToGroup],
  [ProjectsPagePopups.selectGroup, ProjectsPagePopupHeaders.selectGroup],
  [ProjectsPagePopups.selectCtrCode, ProjectsPagePopupHeaders.selectCtrCode],
  [ProjectsPagePopups.setUpCalendar, ProjectsPagePopupHeaders.setUpCalendar],
  [ProjectsPagePopups.confirmCalendarCreation, ProjectsPagePopupHeaders.confirmCalendarCreateion],
  [ProjectsPagePopups.addProjectCategory, ProjectsPagePopupHeaders.addProjectCategory],
  [ProjectsPagePopups.addProjectCtr, ProjectsPagePopupHeaders.addProjectCtr],
  [ProjectsPagePopups.addProjectLocation, ProjectsPagePopupHeaders.addProjectLocation],
  [ProjectsPagePopups.addProjectPosition, ProjectsPagePopupHeaders.addProjectPosition],
  [ProjectsPagePopups.addProjectSubPosition, ProjectsPagePopupHeaders.addProjectSubPosition],
  [ProjectsPagePopups.addProjectTrade, ProjectsPagePopupHeaders.addProjectTrade],
  [ProjectsPagePopups.addProjectDiscipline, ProjectsPagePopupHeaders.addProjectDiscipline],
  [ProjectsPagePopups.addProjectManpowerPosition, ProjectsPagePopupHeaders.addProjectManpowerPosition],
  [ProjectsPagePopups.addProjectUser, ProjectsPagePopupHeaders.addProjectUser],
  [ProjectsPagePopups.changeMmrStatus, ProjectsPagePopupHeaders.changeMmrStatus],
  [ProjectsPagePopups.changeTimesheetStatus, ProjectsPagePopupHeaders.changeTimesheetStatus],
  [ProjectsPagePopups.changeEntityStatus, ProjectsPagePopupHeaders.changeEntityStatus],
  [ProjectsPagePopups.updateProjectDiscipline, ProjectsPagePopupHeaders.updateProjectDiscipline],
  [ProjectsPagePopups.updateProjectCategory, ProjectsPagePopupHeaders.updateProjectCategory],
  [ProjectsPagePopups.updateProjectTrade, ProjectsPagePopupHeaders.updateProjectTrade],
  [ProjectsPagePopups.updateProjectLocation, ProjectsPagePopupHeaders.updateProjectLocation],
  [ProjectsPagePopups.updateProjectCtr, ProjectsPagePopupHeaders.updateProjectCtr],
  [ProjectsPagePopups.updateProjectManpowerPosition, ProjectsPagePopupHeaders.updateProjectManpowerPosition],
  [ProjectsPagePopups.updateProjectPosition, ProjectsPagePopupHeaders.updateProjectPosition],
  [ProjectsPagePopups.updateProjectSubPosition, ProjectsPagePopupHeaders.updateProjectSubPosition],
  [ProjectsPagePopups.updateProjectUserRole, ProjectsPagePopupHeaders.updateProjectUserRole],
  [ProjectsPagePopups.updateGlobalUserRole, ProjectsPagePopupHeaders.updateGlobalUserRole],
  [ProjectsPagePopups.documentsFilter, ProjectsPagePopupHeaders.documentsFilter],
  [ProjectsPagePopups.createProjectReport, ProjectsPagePopupHeaders.createProjectReport],
  [ProjectsPagePopups.selectUserForProjectRole, ProjectsPagePopupHeaders.selectUserForProjectRole],
]);

interface ProjectsPagePopupsReturnType extends PopupHandlerReturnType<ProjectsPagePopups> {
  popupHeaders: Map<ProjectsPagePopups, ProjectsPagePopupHeaders>;
}

export const useProjectsPopups = (): ProjectsPagePopupsReturnType => {
  const [isCreateProjectOpen, setIsCreateProjectOpen] = useState(false);
  const [isChangeProjectEmployeeMobilizationStatusOpen, setIsChangeProjectEmployeeMobilizationStatusOpen] = useState(false);
  const [isChangeProjectEmployeeMobilizationStatusFollowupOpen, setIsChangeProjectEmployeeMobilizationStatusFollowupOpen] = useState(false);
  const [isChangeManpowerPlanStatusOpen, setIsChangeManpowerPlanStatusOpen] = useState(false);
  const [isChangeManpowerPlanStatusFollowupOpen, setIsChangeManpowerPlanStatusFollowupOpen] = useState(false);
  const [isManpowerPlanAddReferenceFilesOpen, setIsManpowerPlanAddReferenceFilesOpen] = useState(false);
  const [isManpowerPlanReferenceFilesListOpen, setIsManpowerPlanReferenceFilesListOpen] = useState(false);
  const [isOvertimeRequestOpen, setIsOvertimeRequestOpen] = useState(false);
  const [isBulkMmrUploadOpen, setIsBulkMmrUploadOpen] = useState(false);
  const [isAddProjectEmployeesOpen, setIsAddProjectEmployeesOpen] = useState(false);
  const [isUploadManpowerPlanVersionOpen, setIsUploadManpowerPlanVersionOpen] = useState(false);
  const [isAddProjectEmployeesFiltersOpen, setIsAddProjectEmployeesFiltersOpen] = useState(false);
  const [isViewProjectEmployeeOpen, setIsViewProjectEmployeeOpen] = useState(false);
  const [isSelectManpowerCodeOpen, setIsSelectManpowerCodeOpen] = useState(false);
  const [isTimesheetGroupSettingsOpen, setIsTimesheetGroupSettingsOpen] = useState(false);
  const [isViewUngroupedEmployeesOpen, setIsViewUngroupedEmployeesOpen] = useState(false);
  const [isSetNewTimesheetOpen, setIsSetNewTimesheetOpen] = useState(false);
  const [isSelectCtrCodeOpen, setIsSelectCtrCodeOpen] = useState(false);
  const [isCreateProjectGroupOpen, setIsCreateProjectGroupOpen] = useState(false);
  const [isUpdateProjectGroupNameOpen, setIsUpdateProjectGroupNameOpen] = useState(false);
  const [isConfirmAddEmployeesToGroupOpen, setIsConfirmAddEmployeesToGroupOpen] = useState(false);
  const [isConfirmAddSectionHeadToGroupOpen, setIsConfirmAddSectionHeadToGroupOpen] = useState(false);
  const [isSelectGroupOpen, setIsSelectGroupOpen] = useState(false);
  const [isChangeMmrStatusOpen, setIsChangeMmrStatusOpen] = useState(false);
  const [isOpenMmrActions, setIsOpenMmrActions] = useState(false);
  const [isChangeTimesheetStatusOpen, setIsChangeTimesheetStatusOpen] = useState(false);
  const [isChangeEntityStatusOpen, setIsChangeEntityStatusOpen] = useState(false);
  const [isAddProjectPositionOpen, setIsAddProjectPositionOpen] = useState(false);
  const [isAddProjectSubPositionOpen, setIsAddProjectSubPositionOpen] = useState(false);
  const [isAddProjectLocationOpen, setIsAddProjectLocationOpen] = useState(false);
  const [isAddProjectTradeOpen, setIsAddProjectTradeOpen] = useState(false);
  const [isAddProjectCategoryOpen, setIsAddProjectCategoryOpen] = useState(false);
  const [isAddProjectCtrOpen, setIsAddProjectCtrOpen] = useState(false);
  const [isAddProjectDisciplineOpen, setIsAddProjectDisciplineOpen] = useState(false);
  const [isAddProjectManpowerPositionOpen, setIsAddProjectManpowerPositionOpen] = useState(false);
  const [isAddProjectUserOpen, setIsAddProjectUserOpen] = useState(false);
  const [isSetUpCalendarOpen, setIsSetUpCalendarOpen] = useState(false);
  const [isConfirmCalendarCreateionOpen, setIsConfirmCalendarCreateionOpen] = useState(false);
  const [isUpdateProjectDisciplineOpen, setIsUpdateProjectDisciplineOpen] = useState(false);
  const [isUpdateProjectCategoryOpen, setIsUpdateProjectCategoryOpen] = useState(false);
  const [isUpdateProjectTradeOpen, setIsUpdateProjectTradeOpen] = useState(false);
  const [isUpdateProjectLocationOpen, setIsUpdateProjectLocationOpen] = useState(false);
  const [isUpdateProjectCtrOpen, setIsUpdateProjectCtrOpen] = useState(false);
  const [isUpdateProjectManpowerPositionOpen, setIsUpdateProjectManpowerPositionOpen] = useState(false);
  const [isUpdateProjectPositionOpen, setIsUpdateProjectPositionOpen] = useState(false);
  const [isUpdateProjectSubPositionOpen, setIsUpdateProjectSubPositionOpen] = useState(false);
  const [isUpdateProjectUserRoleOpen, setIsUpdateProjectUserRoleOpen] = useState(false);
  const [isUpdateGlobalUserRoleOpen, setIsUpdateGlobalUserRoleOpen] = useState(false);
  const [isDocumentsFilterOpen, setIsDocumentsFilterOpen] = useState(false);
  const [isCreateProjectReportOpen, setIsCreateProjectReportOpen] = useState(false);
  const [isSelectUserForProjectRole, setIsSelectUserForProjectRole] = useState(false);

  const popupHandlerAsMap: PopupHandlerTypeAsMap<ProjectsPagePopups> = useMemo(() => {
    const initialValue = new PopupHandlerTypeAsMap<ProjectsPagePopups>();

    initialValue.set(ProjectsPagePopups.createProject, {
      opener: () => {
        setIsCreateProjectOpen(true);
      },
      closer: () => {
        setIsCreateProjectOpen(false);
      },
      isOpen: isCreateProjectOpen,
    });

    initialValue.set(ProjectsPagePopups.changeProjectEmployeeMobilizationStatus, {
      opener: () => {
        setIsChangeProjectEmployeeMobilizationStatusOpen(true);
      },
      closer: () => {
        setIsChangeProjectEmployeeMobilizationStatusOpen(false);
      },
      isOpen: isChangeProjectEmployeeMobilizationStatusOpen,
    });

    initialValue.set(ProjectsPagePopups.changeProjectEmployeeMobilizationStatusFollowup, {
      opener: () => {
        setIsChangeProjectEmployeeMobilizationStatusFollowupOpen(true);
      },
      closer: () => {
        setIsChangeProjectEmployeeMobilizationStatusFollowupOpen(false);
      },
      isOpen: isChangeProjectEmployeeMobilizationStatusFollowupOpen,
    });

    initialValue.set(ProjectsPagePopups.changeManpowerPlanStatus, {
      opener: () => {
        setIsChangeManpowerPlanStatusOpen(true);
      },
      closer: () => {
        setIsChangeManpowerPlanStatusOpen(false);
      },
      isOpen: isChangeManpowerPlanStatusOpen,
    });

    initialValue.set(ProjectsPagePopups.changeManpowerPlanStatusFollowup, {
      opener: () => {
        setIsChangeManpowerPlanStatusFollowupOpen(true);
      },
      closer: () => {
        setIsChangeManpowerPlanStatusFollowupOpen(false);
      },
      isOpen: isChangeManpowerPlanStatusFollowupOpen,
    });

    initialValue.set(ProjectsPagePopups.manpowerPlanAddReferenceFiles, {
      opener: () => {
        setIsManpowerPlanAddReferenceFilesOpen(true);
      },
      closer: () => {
        setIsManpowerPlanAddReferenceFilesOpen(false);
      },
      isOpen: isManpowerPlanAddReferenceFilesOpen,
    });

    initialValue.set(ProjectsPagePopups.manpowerPlanReferenceFilesList, {
      opener: () => {
        setIsManpowerPlanReferenceFilesListOpen(true);
      },
      closer: () => {
        setIsManpowerPlanReferenceFilesListOpen(false);
      },
      isOpen: isManpowerPlanReferenceFilesListOpen,
    });

    initialValue.set(ProjectsPagePopups.overtimeRequest, {
      opener: () => {
        setIsOvertimeRequestOpen(true);
      },
      closer: () => {
        setIsOvertimeRequestOpen(false);
      },
      isOpen: isOvertimeRequestOpen,
    });

    initialValue.set(ProjectsPagePopups.bulkMmrUpload, {
      opener: () => {
        setIsBulkMmrUploadOpen(true);
      },
      closer: () => {
        setIsBulkMmrUploadOpen(false);
      },
      isOpen: isBulkMmrUploadOpen,
    });

    initialValue.set(ProjectsPagePopups.addProjectEmployees, {
      opener: () => {
        setIsAddProjectEmployeesOpen(true);
      },
      closer: () => {
        setIsAddProjectEmployeesOpen(false);
      },
      isOpen: isAddProjectEmployeesOpen,
    });

    initialValue.set(ProjectsPagePopups.uploadManpowerPlanVersion, {
      opener: () => {
        setIsUploadManpowerPlanVersionOpen(true);
      },
      closer: () => {
        setIsUploadManpowerPlanVersionOpen(false);
      },
      isOpen: isUploadManpowerPlanVersionOpen,
    });

    initialValue.set(ProjectsPagePopups.addProjectEmployeesFilters, {
      opener: () => {
        setIsAddProjectEmployeesFiltersOpen(true);
      },
      closer: () => {
        setIsAddProjectEmployeesFiltersOpen(false);
      },
      isOpen: isAddProjectEmployeesFiltersOpen,
    });

    initialValue.set(ProjectsPagePopups.viewProjectEmployee, {
      opener: () => {
        setIsViewProjectEmployeeOpen(true);
      },
      closer: () => {
        setIsViewProjectEmployeeOpen(false);
      },
      isOpen: isViewProjectEmployeeOpen,
    });
    initialValue.set(ProjectsPagePopups.selectManpowerCode, {
      opener: () => {
        setIsSelectManpowerCodeOpen(true);
      },
      closer: () => {
        setIsSelectManpowerCodeOpen(false);
      },
      isOpen: isSelectManpowerCodeOpen,
    });

    initialValue.set(ProjectsPagePopups.timesheetGroupSettings, {
      opener: () => {
        setIsTimesheetGroupSettingsOpen(true);
      },
      closer: () => {
        setIsTimesheetGroupSettingsOpen(false);
      },
      isOpen: isTimesheetGroupSettingsOpen,
    });

    initialValue.set(ProjectsPagePopups.viewUngroupedEmployees, {
      opener: () => {
        setIsViewUngroupedEmployeesOpen(true);
      },
      closer: () => {
        setIsViewUngroupedEmployeesOpen(false);
      },
      isOpen: isViewUngroupedEmployeesOpen,
    });
    initialValue.set(ProjectsPagePopups.createProjectGroup, {
      opener: () => {
        setIsCreateProjectGroupOpen(true);
      },
      closer: () => {
        setIsCreateProjectGroupOpen(false);
      },
      isOpen: isCreateProjectGroupOpen,
    });
    initialValue.set(ProjectsPagePopups.updateProjectGroupName, {
      opener: () => {
        setIsUpdateProjectGroupNameOpen(true);
      },
      closer: () => {
        setIsUpdateProjectGroupNameOpen(false);
      },
      isOpen: isUpdateProjectGroupNameOpen,
    });

    initialValue.set(ProjectsPagePopups.confirmAddEmployeesToGroup, {
      opener: () => {
        setIsConfirmAddEmployeesToGroupOpen(true);
      },
      closer: () => {
        setIsConfirmAddEmployeesToGroupOpen(false);
      },
      isOpen: isConfirmAddEmployeesToGroupOpen,
    });

    initialValue.set(ProjectsPagePopups.confirmAddSectionHeadToGroup, {
      opener: () => {
        setIsConfirmAddSectionHeadToGroupOpen(true);
      },
      closer: () => {
        setIsConfirmAddSectionHeadToGroupOpen(false);
      },
      isOpen: isConfirmAddSectionHeadToGroupOpen,
    });

    initialValue.set(ProjectsPagePopups.selectGroup, {
      opener: () => {
        setIsSelectGroupOpen(true);
      },
      closer: () => {
        setIsSelectGroupOpen(false);
      },
      isOpen: isSelectGroupOpen,
    });

    initialValue.set(ProjectsPagePopups.selectCtrCode, {
      opener: () => {
        setIsSelectCtrCodeOpen(true);
      },
      closer: () => {
        setIsSelectCtrCodeOpen(false);
      },
      isOpen: isSelectCtrCodeOpen,
    });

    initialValue.set(ProjectsPagePopups.setNewTimesheet, {
      opener: () => {
        setIsSetNewTimesheetOpen(true);
      },
      closer: () => {
        setIsSetNewTimesheetOpen(false);
      },
      isOpen: isSetNewTimesheetOpen,
    });

    initialValue.set(ProjectsPagePopups.changeMmrStatus, {
      opener: () => {
        setIsChangeMmrStatusOpen(true);
      },
      closer: () => {
        setIsChangeMmrStatusOpen(false);
      },
      isOpen: isChangeMmrStatusOpen,
    });

    initialValue.set(ProjectsPagePopups.mmrActions, {
      opener: () => {
        setIsOpenMmrActions(true);
      },
      closer: () => {
        setIsOpenMmrActions(false);
      },
      isOpen: isOpenMmrActions,
    });

    initialValue.set(ProjectsPagePopups.setUpCalendar, {
      opener: () => {
        setIsSetUpCalendarOpen(true);
      },
      closer: () => {
        setIsSetUpCalendarOpen(false);
      },
      isOpen: isSetUpCalendarOpen,
    });

    initialValue.set(ProjectsPagePopups.addProjectPosition, {
      opener: () => {
        setIsAddProjectPositionOpen(true);
      },
      closer: () => {
        setIsAddProjectPositionOpen(false);
      },
      isOpen: isAddProjectPositionOpen,
    });
    initialValue.set(ProjectsPagePopups.addProjectSubPosition, {
      opener: () => {
        setIsAddProjectSubPositionOpen(true);
      },
      closer: () => {
        setIsAddProjectSubPositionOpen(false);
      },
      isOpen: isAddProjectSubPositionOpen,
    });

    initialValue.set(ProjectsPagePopups.addProjectLocation, {
      opener: () => {
        setIsAddProjectLocationOpen(true);
      },
      closer: () => {
        setIsAddProjectLocationOpen(false);
      },
      isOpen: isAddProjectLocationOpen,
    });

    initialValue.set(ProjectsPagePopups.addProjectTrade, {
      opener: () => {
        setIsAddProjectTradeOpen(true);
      },
      closer: () => {
        setIsAddProjectTradeOpen(false);
      },
      isOpen: isAddProjectTradeOpen,
    });

    initialValue.set(ProjectsPagePopups.addProjectCategory, {
      opener: () => {
        setIsAddProjectCategoryOpen(true);
      },
      closer: () => {
        setIsAddProjectCategoryOpen(false);
      },
      isOpen: isAddProjectCategoryOpen,
    });

    initialValue.set(ProjectsPagePopups.addProjectCtr, {
      opener: () => {
        setIsAddProjectCtrOpen(true);
      },
      closer: () => {
        setIsAddProjectCtrOpen(false);
      },
      isOpen: isAddProjectCtrOpen,
    });

    initialValue.set(ProjectsPagePopups.addProjectDiscipline, {
      opener: () => {
        setIsAddProjectDisciplineOpen(true);
      },
      closer: () => {
        setIsAddProjectDisciplineOpen(false);
      },
      isOpen: isAddProjectDisciplineOpen,
    });

    initialValue.set(ProjectsPagePopups.addProjectManpowerPosition, {
      opener: () => {
        setIsAddProjectManpowerPositionOpen(true);
      },
      closer: () => {
        setIsAddProjectManpowerPositionOpen(false);
      },
      isOpen: isAddProjectManpowerPositionOpen,
    });

    initialValue.set(ProjectsPagePopups.confirmCalendarCreation, {
      opener: () => {
        setIsConfirmCalendarCreateionOpen(true);
      },
      closer: () => {
        setIsConfirmCalendarCreateionOpen(false);
      },
      isOpen: isConfirmCalendarCreateionOpen,
    });

    initialValue.set(ProjectsPagePopups.updateProjectDiscipline, {
      opener: () => {
        setIsUpdateProjectDisciplineOpen(true);
      },
      closer: () => {
        setIsUpdateProjectDisciplineOpen(false);
      },
      isOpen: isUpdateProjectDisciplineOpen,
    });

    initialValue.set(ProjectsPagePopups.updateProjectCategory, {
      opener: () => {
        setIsUpdateProjectCategoryOpen(true);
      },
      closer: () => {
        setIsUpdateProjectCategoryOpen(false);
      },
      isOpen: isUpdateProjectCategoryOpen,
    });

    initialValue.set(ProjectsPagePopups.updateProjectTrade, {
      opener: () => {
        setIsUpdateProjectTradeOpen(true);
      },
      closer: () => {
        setIsUpdateProjectTradeOpen(false);
      },
      isOpen: isUpdateProjectTradeOpen,
    });

    initialValue.set(ProjectsPagePopups.updateProjectLocation, {
      opener: () => {
        setIsUpdateProjectLocationOpen(true);
      },
      closer: () => {
        setIsUpdateProjectLocationOpen(false);
      },
      isOpen: isUpdateProjectLocationOpen,
    });

    initialValue.set(ProjectsPagePopups.updateProjectCtr, {
      opener: () => {
        setIsUpdateProjectCtrOpen(true);
      },
      closer: () => {
        setIsUpdateProjectCtrOpen(false);
      },
      isOpen: isUpdateProjectCtrOpen,
    });

    initialValue.set(ProjectsPagePopups.updateProjectManpowerPosition, {
      opener: () => {
        setIsUpdateProjectManpowerPositionOpen(true);
      },
      closer: () => {
        setIsUpdateProjectManpowerPositionOpen(false);
      },
      isOpen: isUpdateProjectManpowerPositionOpen,
    });

    initialValue.set(ProjectsPagePopups.updateProjectPosition, {
      opener: () => {
        setIsUpdateProjectPositionOpen(true);
      },
      closer: () => {
        setIsUpdateProjectPositionOpen(false);
      },
      isOpen: isUpdateProjectPositionOpen,
    });

    initialValue.set(ProjectsPagePopups.updateProjectSubPosition, {
      opener: () => {
        setIsUpdateProjectSubPositionOpen(true);
      },
      closer: () => {
        setIsUpdateProjectSubPositionOpen(false);
      },
      isOpen: isUpdateProjectSubPositionOpen,
    });

    initialValue.set(ProjectsPagePopups.changeTimesheetStatus, {
      opener: () => {
        setIsChangeTimesheetStatusOpen(true);
      },
      closer: () => {
        setIsChangeTimesheetStatusOpen(false);
      },
      isOpen: isChangeTimesheetStatusOpen,
    });

    initialValue.set(ProjectsPagePopups.changeEntityStatus, {
      opener: () => {
        setIsChangeEntityStatusOpen(true);
      },
      closer: () => {
        setIsChangeEntityStatusOpen(false);
      },
      isOpen: isChangeEntityStatusOpen,
    });

    initialValue.set(ProjectsPagePopups.addProjectUser, {
      opener: () => {
        setIsAddProjectUserOpen(true);
      },
      closer: () => {
        setIsAddProjectUserOpen(false);
      },
      isOpen: isAddProjectUserOpen,
    });
    initialValue.set(ProjectsPagePopups.updateProjectUserRole, {
      opener: () => {
        setIsUpdateProjectUserRoleOpen(true);
      },
      closer: () => {
        setIsUpdateProjectUserRoleOpen(false);
      },
      isOpen: isUpdateProjectUserRoleOpen,
    });
    initialValue.set(ProjectsPagePopups.updateGlobalUserRole, {
      opener: () => {
        setIsUpdateGlobalUserRoleOpen(true);
      },
      closer: () => {
        setIsUpdateGlobalUserRoleOpen(false);
      },
      isOpen: isUpdateGlobalUserRoleOpen,
    });
    initialValue.set(ProjectsPagePopups.createProjectReport, {
      opener: () => {
        setIsCreateProjectReportOpen(true);
      },
      closer: () => {
        setIsCreateProjectReportOpen(false);
      },
      isOpen: isCreateProjectReportOpen,
    });

    initialValue.set(ProjectsPagePopups.documentsFilter, {
      opener: () => {
        setIsDocumentsFilterOpen(true);
      },
      closer: () => {
        setIsDocumentsFilterOpen(false);
      },
      isOpen: isDocumentsFilterOpen,
    });

    initialValue.set(ProjectsPagePopups.selectUserForProjectRole, {
      opener: () => {
        setIsSelectUserForProjectRole(true);
      },
      closer: () => {
        setIsSelectUserForProjectRole(false);
      },
      isOpen: isSelectUserForProjectRole,
    });

    return initialValue;
  }, [
    isCreateProjectOpen,
    isChangeProjectEmployeeMobilizationStatusOpen,
    isChangeProjectEmployeeMobilizationStatusFollowupOpen,
    isChangeManpowerPlanStatusOpen,
    isChangeManpowerPlanStatusFollowupOpen,
    isManpowerPlanAddReferenceFilesOpen,
    isManpowerPlanReferenceFilesListOpen,
    isOvertimeRequestOpen,
    isBulkMmrUploadOpen,
    isAddProjectEmployeesOpen,
    isUploadManpowerPlanVersionOpen,
    isAddProjectEmployeesFiltersOpen,
    isViewProjectEmployeeOpen,
    isSelectManpowerCodeOpen,
    isTimesheetGroupSettingsOpen,
    isViewUngroupedEmployeesOpen,
    isSetNewTimesheetOpen,
    isCreateProjectGroupOpen,
    isUpdateProjectGroupNameOpen,
    isConfirmAddEmployeesToGroupOpen,
    isConfirmAddSectionHeadToGroupOpen,
    isSelectGroupOpen,
    isSelectCtrCodeOpen,
    isChangeMmrStatusOpen,
    isOpenMmrActions,
    isChangeTimesheetStatusOpen,
    isChangeEntityStatusOpen,
    isSetUpCalendarOpen,
    isConfirmCalendarCreateionOpen,
    isAddProjectLocationOpen,
    isAddProjectCategoryOpen,
    isAddProjectTradeOpen,
    isAddProjectCtrOpen,
    isAddProjectPositionOpen,
    isAddProjectSubPositionOpen,
    isAddProjectDisciplineOpen,
    isAddProjectManpowerPositionOpen,
    isAddProjectUserOpen,
    isUpdateProjectDisciplineOpen,
    isUpdateProjectCategoryOpen,
    isUpdateProjectTradeOpen,
    isUpdateProjectLocationOpen,
    isUpdateProjectCtrOpen,
    isUpdateProjectManpowerPositionOpen,
    isUpdateProjectPositionOpen,
    isUpdateProjectSubPositionOpen,
    isUpdateProjectUserRoleOpen,
    isUpdateGlobalUserRoleOpen,
    isDocumentsFilterOpen,
    isCreateProjectReportOpen,
    isSelectUserForProjectRole,
  ]);

  return {
    popupHandler: popupHandlerAsMap,
    onOpenPopup,
    onClosePopup,
    popupHeaders: popupHeadersMap,
  } satisfies ProjectsPagePopupsReturnType;
};
