import { FC } from "react";
import MmrInformationInfoBox from "./mmrinformationinfobox/MmrInformationInfoBox.tsx";
import { MmrDataType } from "../../../../../../../types/projects/MmrTypes.ts";

interface ProjectEmployeeInfoListProps {
  mmrInformationItems: MmrDataType[];
}

const MmrInformationList: FC<ProjectEmployeeInfoListProps> = ({ mmrInformationItems }) => {
  return mmrInformationItems.map((item) => {
    return <MmrInformationInfoBox key={item.title} title={item.title} content={item.content} isLoading={item.isLoading} />;
  });
};

export default MmrInformationList;
