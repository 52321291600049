import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import CalendarManagementEmptyStyles from "./CalendarManagementEmpty.module.css";
import calendarConfigurationImg from "../../../../../assets/images/calendar-configuration.svg";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../projects/use-projects-popups.ts";
import ConfigurationSetUpCalendarPopup from "../../../../projects/popups/configurationsetupcalendarpopup/ConfigurationSetUpCalendarPopup.tsx";
import { FC } from "react";
import { EPCMInfoContainerDiv } from "../../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";

// interface CalendarManagementEmptyProps {
//   holidayDate?: Date | null;
//   setHolidayDate?: Dispatch<SetStateAction<Date | null>>;
//   holidayType?: string;
//   onHolidayTypeChange?: (eventValue: string | undefined) => void;
// }

export const CalendarManagementEmpty: FC = () => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3, GlobalStyles.overflowHiddenFullHeight)}>
      <EPCMInfoContainerDiv
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
          <div className={classNames(CalendarManagementEmptyStyles.calendarTitle)}>Calendar Management</div>
          <div className={classNames(GlobalStyles.flex6, GlobalStyles.flex3)} />
          <div className={classNames(GlobalStyles.flex2)}></div>
        </div>
        <div className={classNames(CalendarManagementEmptyStyles.projectMenuContainer, GlobalStyles.flex, GlobalStyles.gap)}></div>
        <div
          className={classNames(
            CalendarManagementEmptyStyles.noPendingPanel,
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.centerVertical,
            GlobalStyles.centerHorizontal,
            GlobalStyles.gap3,
          )}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerHorizontal, GlobalStyles.gap)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
                <img
                  className={classNames(CalendarManagementEmptyStyles.calendarConfigurationImg)}
                  src={calendarConfigurationImg}
                  alt={"calendar-config-icon"}
                />
              </div>
              <div className={classNames(CalendarManagementEmptyStyles.extraLargeScaleText)}>{"Calendar needs configuration."}</div>
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
              <div className={classNames(CalendarManagementEmptyStyles.largeScaleText)}>
                {"Fill in the 3-step configuration wizard to properly set up the project calendar."}
              </div>
              <div className={classNames(CalendarManagementEmptyStyles.subText)}>
                {"Certain project info is drawn from the system, you cannot change it here."}
              </div>
            </div>
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
            <div
              className={classNames(CalendarManagementEmptyStyles.actionText, GlobalStyles.elementWithCursor)}
              onClick={() => onOpenPopup(ProjectsPagePopups.setUpCalendar, popupHandler)}
            >
              {"Set up calendar"}
            </div>
          </div>
        </div>
      </EPCMInfoContainerDiv>
      {popupHandler.get(ProjectsPagePopups.setUpCalendar)!.isOpen && (
        <ConfigurationSetUpCalendarPopup
          isOpen={popupHandler.get(ProjectsPagePopups.setUpCalendar)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.setUpCalendar, popupHandler)}
          headerText={popupHeaders.get(ProjectsPagePopups.setUpCalendar)}
        />
      )}
    </div>
  );
};

export default CalendarManagementEmpty;
