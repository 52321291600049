import { EpcmSnackbarMessage } from "../../../types/EpcmToastProps.ts";

import { useCallback, useState } from "react";
import { useNotificationContext } from "../GlobalNotificationContext.ts";
import EpcmToastContent from "../epcmtoast/epcmtoastcontent/EpcmToastContent.tsx";
import { Snackbar } from "@mui/material";

type SnackbarToastProps = {
  toast: EpcmSnackbarMessage;
};
const TIMEOUT = 300;

const SnackbarToast: React.FC<SnackbarToastProps> = ({ toast }) => {
  const [open, setOpen] = useState<boolean>(true);
  const { removeNotification } = useNotificationContext();

  const handleClose = useCallback(
    (_event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
      setTimeout(() => {
        removeNotification(toast.fileId);
      }, TIMEOUT);
    },
    [toast.fileId, removeNotification],
  );

  const handleExited = () => {
    setOpen(false);
    setTimeout(() => {
      removeNotification(toast.fileId);
    }, TIMEOUT);
  };
  return (
    <Snackbar
      key={toast.fileId}
      open={open}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        position: "relative",
        mb: 2,
        pointerEvents: "auto",
      }}
    >
      <div>
        <EpcmToastContent toast={toast} handleClose={handleClose} />
      </div>
    </Snackbar>
  );
};

export default SnackbarToast;
