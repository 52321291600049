import { FrontendManpowerPositionDetailedType, FrontendManpowerPositionLimitedType } from "../../../../projects/ManpowerPositionTypes.ts";
import { ManpowerPositionDetailed, ManpowerPositionLimited } from "epcm-common/dist/Types/ManpowerPositionTypes";

export const convertProjectManpowerPositionsCodesData = (
  projectManpowerPositionsCodesData: ManpowerPositionLimited[],
): FrontendManpowerPositionLimitedType[] => {
  return projectManpowerPositionsCodesData.map((manpowerCodeItem) => {
    return {
      id: manpowerCodeItem.id,
      code: manpowerCodeItem.code,
    };
  });
};

export const convertProjectManpowerPositionsCodeSingleDetailedData = (
  projectManpowerPositionsCodeData: ManpowerPositionDetailed,
): FrontendManpowerPositionDetailedType => {
  return {
    id: projectManpowerPositionsCodeData.id,
    code: projectManpowerPositionsCodeData.code,
    rate: projectManpowerPositionsCodeData.rate,
    pricingScheduledManhours: projectManpowerPositionsCodeData.pricingScheduledManhours,
    categoryId: projectManpowerPositionsCodeData.categoryId,
    positionId: projectManpowerPositionsCodeData.positionId,
    locationId: projectManpowerPositionsCodeData.locationId,
    tradeId: projectManpowerPositionsCodeData.tradeId,
  };
};

export const convertProjectManpowerPositionsCodeSingleLimitedData = (
  projectManpowerPositionsCodeData: ManpowerPositionLimited,
): FrontendManpowerPositionLimitedType => {
  return {
    id: projectManpowerPositionsCodeData.id,
    code: projectManpowerPositionsCodeData.code,
  };
};
