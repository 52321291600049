import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../../config.ts";
import { QueryModelWithCountAndData, QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { AxiosRequestConfig } from "axios";
import { UserProject } from "epcm-common/dist/Types/UserTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getProjectUsers = async (
  id: number,
  pageOffset?: number,
  searchFilter?: string,
  pageSize: number = PAGINATION_PAGE_SIZE,
): Promise<QueryModelWithPagination<UserProject>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/users/roles`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);
  const responseData = response.data as QueryModelWithCountAndData<UserProject>;

  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;
  return { data: responseData.data, nextPage: nextPage, totalCount: responseData.count };
};
