import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config";
import { ApiResponseCtrTimesheetRecords } from "epcm-common/dist/Types/TimesheetRecordTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getGroupTimesheetActiveMmrCtrItemRecords = async (
  id: number,
  timesheetGroupId: number,
  timesheetId: number,
  timesheetEmployeeId: number,
  projectEmployeeId: number,
  mmrId: number,
  ctrId: number,
): Promise<ApiResponseCtrTimesheetRecords> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/timesheets/${timesheetId}/employee/${timesheetEmployeeId}/projectEmployee/${projectEmployeeId}/mmr/${mmrId}/ctrs/${ctrId}`,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  return response.data as ApiResponseCtrTimesheetRecords;
};
