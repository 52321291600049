import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { ApiRequestBodyCtrMap } from "epcm-common/dist/Types/CtrTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const createCtrMapping = async (projectId: number, ctrMappingData: ApiRequestBodyCtrMap[]) => {
  const config = {
    url: `${API}/${projectId}/ctrs-mapping`,
    method: "POST",
    data: ctrMappingData,
  };

  return epcmAxiosInstance(config);
};
