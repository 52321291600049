import UserRolesListStyles from "./UserRolesList.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { FrontendRoleNormal } from "../../../../../types/apicallstypes/UsersApiTypes.ts";
import { Dispatch, FC, SetStateAction } from "react";

interface UserRolesListProps {
  rolesList: FrontendRoleNormal[];

  locallySelectedRoles: number[];
  setLocallySelectedRoles: Dispatch<SetStateAction<number[]>>;
}

const UserRolesList: FC<UserRolesListProps> = ({ rolesList, locallySelectedRoles, setLocallySelectedRoles }) => {
  const isRoleInTheSelectedRoles = (roleId: number) => {
    return locallySelectedRoles.includes(roleId);
  };
  const handleRadioChange = (roleId: number) => {
    if (isRoleInTheSelectedRoles(roleId)) {
      setLocallySelectedRoles(locallySelectedRoles.filter((role) => role !== roleId));
    } else {
      setLocallySelectedRoles([...locallySelectedRoles, roleId]);
    }
  };

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.flexDirectionColumn, UserRolesListStyles.radioGroupContainer)}>
      {rolesList.map((role, index) => (
        <div
          key={index}
          onClick={() => handleRadioChange(role.id)}
          className={classNames(
            UserRolesListStyles.radioContainer,
            GlobalStyles.flex,
            GlobalStyles.gap,
            GlobalStyles.centerHorizontal,
            GlobalStyles.elementWithCursor,
          )}
        >
          <input
            type="radio"
            value={role.id}
            className={classNames(UserRolesListStyles.radioInput)}
            checked={isRoleInTheSelectedRoles(role.id)}
            onChange={() => handleRadioChange(role.id)}
          />
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap025)}>
            <div className={classNames(UserRolesListStyles.roleName)}>{role.name}</div>
            {role.description && <div className={classNames(UserRolesListStyles.roleDescription)}>{`(${role.description})`}</div>}
          </div>
        </div>
      ))}
    </div>
  );
};
export default UserRolesList;
