import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import TimesheetGroupsStyles from "./TimesheetGroupsStyles.module.css";

import { NavLink, Outlet, useParams } from "react-router-dom";
import TimesheetGroupItem from "./timesheetgroupitem/TimesheetGroupItem.tsx";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../apicalls/config.ts";
import { useEffect, useMemo } from "react";
import { useProjectContext } from "../../../ProjectsUtils.ts";
import { ProjectTimesheetTabStatusEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";
import { Skeleton } from "@mui/material";
import InfiniteScrollInViewElement from "../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useInView } from "react-intersection-observer";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";
import { useHandleUnauthorized } from "../../../../../utils/use-handle-unauthorized.ts";

const TimesheetGroups = () => {
  const { groupId } = useParams();
  const { ref, inView } = useInView();
  const { currentProject, searchQuery } = useProjectContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getAllTimesheetGroups } = useEpcmApiProjectsTimesheetsGroups();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { handleErrorRedirect } = useHandleUnauthorized();

  const timesheetQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectTimesheetGroups, currentProject.id, searchQuery],
    queryFn: ({ pageParam = 1 }) => getAllTimesheetGroups(currentProject.id, PAGINATION_PAGE_SIZE, pageParam, searchQuery).catch(handleErrorRedirect),
    initialPageParam: 1,
    enabled: isAuthorized,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
  });

  const timesheetsGroupsData = useMemo(() => {
    return timesheetQuery.data?.pages.flatMap((page) => page.data);
  }, [timesheetQuery.data]);

  const showSharedUI = useMemo(() => !groupId, [groupId]);

  const timesheetGroupListSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexWrap, GlobalStyles.gap2)}>
      {["timesheetGroup1", "timesheetGroup2", "timesheetGroup3"].map((key) => (
        <Skeleton key={key} variant="rounded" height={190} width={430} />
      ))}
    </div>
  );

  const canListTimesheetGroups = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupList);
  const canChangeToPrepared = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPendingToPrepared);
  const canChangeToReviewed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToReviewed);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, currentProject.id] }).then(() => {
        console.log(`In timesheets-groups component, ${QueryNames.ProjectTimesheetGroups} query cancelled`);
      });
    };
  }, [queryClient, currentProject]);

  useEffect(() => {
    if (inView && !timesheetQuery.isLoading && !timesheetQuery.isFetching && !timesheetQuery.isFetchingNextPage && timesheetQuery.hasNextPage) {
      timesheetQuery.fetchNextPage();
    }
  }, [inView, timesheetQuery]);

  return (
    <div className={classNames(TimesheetGroupsStyles.parentDiv, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      {showSharedUI ? (
        <div
          className={classNames(TimesheetGroupsStyles.containerDiv, GlobalStyles.flex, GlobalStyles.flexWrap, GlobalStyles.flex1, GlobalStyles.gap2)}
        >
          {timesheetQuery.isLoading ? (
            timesheetGroupListSkeletonLoader
          ) : timesheetsGroupsData && timesheetsGroupsData.length > 0 ? (
            <>
              {canListTimesheetGroups &&
                timesheetsGroupsData.map((group) => (
                  <NavLink
                    key={group.id}
                    to={`${group.id}?status=${canChangeToPrepared ? ProjectTimesheetTabStatusEnum.pending : canChangeToReviewed ? ProjectTimesheetTabStatusEnum.reviewed : ProjectTimesheetTabStatusEnum.pending}`}
                  >
                    <TimesheetGroupItem groupItem={group} />
                  </NavLink>
                ))}
              {canListTimesheetGroups && (
                <InfiniteScrollInViewElement
                  key={"InfiniteScrollInViewElement"}
                  reference={ref}
                  infiniteQueryResult={timesheetQuery}
                  loaderComponent={<></>}
                />
              )}
            </>
          ) : (
            <div
              className={classNames(
                GlobalStyles.emptyListMsg,
                GlobalStyles.centerHorizontal,
                GlobalStyles.centerVertical,
                TimesheetGroupsStyles.parentDiv,
                GlobalStyles.overflowHiddenFullHeight,
              )}
            >
              {"No timesheet groups found"}
            </div>
          )}
        </div>
      ) : (
        <div
          className={classNames(
            TimesheetGroupsStyles.outletContainer,
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.overflowHiddenFullHeight,
          )}
        >
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default TimesheetGroups;
