import { styled } from "@mui/material";

export const EPCMPopupHeaderDiv = styled("div")({
  borderBottom: "1px solid var(--cyan-gray)",
  color: "var(--gray-dark-sub)",
  fontFamily: "poppins",
  fontSize: "1.1em",
  fontWeight: "600",
  padding: "0em 0.8em 0.8em",
});
