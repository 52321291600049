import classNames from "classnames";

import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import { Dispatch, FC, SetStateAction } from "react";
import MmrContentInfoSectionStyles from "./MmrContentInfoSection.module.css";
import { Skeleton } from "@mui/material";
import MmrInformationContentRightSide from "./mmrinformationcontentrightside/MmrInformationContentRightSide.tsx";
import MmrInformationContentLeftSide from "./mmrinformationcontentleftside/MmrInformationContentLeftSide.tsx";
// import MmrSpecificInformation from "./mmrspecificinformation/MmrSpecificInformation.tsx";
import { MmrInformationActionBar } from "./mmrinformationactionbar/MmrInformationActionBar.tsx";
import { ProjectEmployeeDetailsPopupTypeEnum } from "../../../../../../types/projects/ProjectEmployeesTypes.ts";
import { FrontendProjectEmployeeMmrDetailedType } from "../../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import MmrSpecificInformation from "./mmrspecificinformation/MmrSpecificInformation.tsx";

interface MmrContentInfoSectionProps {
  employeeId: number;
  projectId: number;
  setIsCloned: Dispatch<SetStateAction<boolean>>;
  setPopupType: Dispatch<SetStateAction<ProjectEmployeeDetailsPopupTypeEnum>>;
  manpowerCodeName: string | null;
  mmrDataItem: FrontendProjectEmployeeMmrDetailedType | undefined;
  selectedMmrId: number | null;
  mmrDataLoading: boolean;
}

const MmrContentInfoSection: FC<MmrContentInfoSectionProps> = ({
  employeeId,
  projectId,
  setIsCloned,
  setPopupType,
  manpowerCodeName,
  selectedMmrId,
  mmrDataLoading,
  mmrDataItem,
}) => {
  const mmrDataLoader = (
    <div>
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.gap,
          GlobalStyles.flex1,
          GlobalStyles.overflowHiddenFullHeight,
          GlobalStyles.flexDirectionColumn,
        )}
      >
        <Skeleton variant={"rectangular"} width={700} height={50}></Skeleton>
        <Skeleton variant={"rectangular"} width={700} height={50}></Skeleton>
        <Skeleton variant={"rectangular"} width={700} height={50}></Skeleton>
        <Skeleton variant={"rectangular"} width={700} height={50}></Skeleton>
        <Skeleton variant={"rectangular"} width={700} height={50}></Skeleton>
        <Skeleton variant={"rectangular"} width={700} height={50}></Skeleton>
        <Skeleton variant={"rectangular"} width={700} height={50}></Skeleton>
        <Skeleton variant={"rectangular"} width={700} height={50}></Skeleton>
      </div>
    </div>
  );

  return (
    <div
      className={classNames(
        MmrContentInfoSectionStyles.employeeExtraInfoContainer,
        GlobalStyles.flex,
        GlobalStyles.flex2,
        GlobalStyles.gap075,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <MmrInformationActionBar
        mmrVersion={mmrDataItem?.revisionNumber}
        mmrId={mmrDataItem?.id}
        employeeId={employeeId}
        projectId={projectId}
        mmrStatus={mmrDataItem?.status ?? null}
        setIsCloned={setIsCloned}
        setPopupType={setPopupType}
      />
      <div className={classNames(GlobalStyles.flex, GlobalStyles.overflowHiddenFullHeight, MmrContentInfoSectionStyles.mmrContainer)}>
        {selectedMmrId ? (
          !mmrDataLoading && mmrDataItem ? (
            <>
              <MmrInformationContentLeftSide
                projectEmployeePositionId={mmrDataItem!.manpowerPositionDetailed.positionId}
                projectEmployeeSubPositionId={mmrDataItem.subPositionId ?? null}
                projectEmployeeTradeId={mmrDataItem!.manpowerPositionDetailed.tradeId}
                projectEmployeeCategoryId={mmrDataItem!.manpowerPositionDetailed.categoryId}
                projectEmployeeLocationOfAssignmentId={mmrDataItem!.manpowerPositionDetailed.locationId}
                projectEmployeeRate={mmrDataItem!.manpowerPositionDetailed.rate}
                projectEmployeeAgreementNo={mmrDataItem!.agreementNumber}
                projectSubcontractor={mmrDataItem?.subcontractor ?? "-"}
                projectMmrCreationDate={mmrDataItem!.creationDate}
                projectManpowerCode={manpowerCodeName}
                projectDisciplineId={mmrDataItem.disciplineId ?? null}
                projectId={projectId}
              />
              <MmrInformationContentRightSide
                projectEmployeeTypeOfMobilization={mmrDataItem!.typeOfMobilization}
                projectEmployeeActualMobilizationDate={mmrDataItem!.actualMobilizationDate}
                projectEmployeeAnticipatedDemobilizationDate={mmrDataItem!.anticipatedDemobilizationDate}
                projectEmployeeAnticipatedMobilizationDate={mmrDataItem!.anticipatedMobilizationDate}
                projectEmployeeActualDemobilizationDate={mmrDataItem!.actualDemobilizationDate}
                projectEmployeeApprovalRecord={mmrDataItem!.approvalRecord}
                projectEmployeeJustificationForRequest={mmrDataItem!.justificationForRequest}
                projectEmployeeIsKeyPersonnel={mmrDataItem!.keyPersonnel}
                projectEmployeeClientInterview={mmrDataItem!.clientInterviewDate}
              />
            </>
          ) : (
            mmrDataLoader
          )
        ) : (
          <div
            className={classNames(
              GlobalStyles.emptyListMsg,
              GlobalStyles.flex,
              GlobalStyles.flex1,
              GlobalStyles.centerVertical,
              GlobalStyles.centerHorizontal,
            )}
          >
            No Active Mmr
          </div>
        )}
      </div>
      {<MmrSpecificInformation overtimeDuration={"3h/month"} overTimePeriod={"13/09-18/10"} fileName={"Test.xlsx"} />}
    </div>
  );
};
export default MmrContentInfoSection;
