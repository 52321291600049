import { FC, useCallback, useState } from "react";
import TrashCanDarkIcon from "../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIconHover from "../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import EditIcon from "../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../assets/images/trashcan-icon-red.svg";

import GlobalConfigurationTradesRowStyles from "./GlobalConfigurationTradesRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { FrontendGlobalTradeType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import { DeletePopup } from "../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { useQueryClient } from "@tanstack/react-query";
import GlobalConfigurationTradesPopup from "../../popups/globalconfigurationctrpopup/GlobalConfigurationTradesPopup.tsx";
import { SettingsPagePopups, useSettingsPopups } from "../../../use-settings-pupups.ts";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../../types/Roles.ts";

interface ConfigurationTradesRowProps {
  tradeItem: FrontendGlobalTradeType;
}

const GlobalConfigurationTradesRow: FC<ConfigurationTradesRowProps> = ({ tradeItem }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();
  const { useDeleteGlobalEntityMutation } = useEpcmApiConfigurationsMutations();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const deleteGlobalTradeMutation = useDeleteGlobalEntityMutation(tradeItem.id, FrontendGlobalConfigurationsEntityEnum.Trade, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Global Trade was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalTrades] });
  }, [queryClient]);

  const canListConfigurations = canPerformGlobalAction(GlobalAction.ConfigList);
  const canEditConfigurations = canPerformGlobalAction(GlobalAction.ConfigUpdate);
  const canDeleteConfigurations = canPerformGlobalAction(GlobalAction.ConfigDelete);

  return (
    <>
      <tr
        key={tradeItem.id}
        className={classNames(
          GlobalConfigurationTradesRowStyles.itemContainer,
          isRowHovered && GlobalConfigurationTradesRowStyles.rowHoverEffect,

          GlobalConfigurationTradesRowStyles.Trow,
        )}
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
      >
        {tradeItem && canListConfigurations ? (
          <>
            <td
              className={classNames(
                GlobalConfigurationTradesRowStyles.tableCell,
                GlobalConfigurationTradesRowStyles.boldText,
                GlobalConfigurationTradesRowStyles.tD,
              )}
            >
              {tradeItem.code}
            </td>
            <td className={classNames(GlobalConfigurationTradesRowStyles.tableCell, GlobalConfigurationTradesRowStyles.tD)}>
              {tradeItem.description}
            </td>
            <td className={classNames(GlobalConfigurationTradesRowStyles.tableCellActions, GlobalConfigurationTradesRowStyles.tD)}>
              <div
                className={classNames(
                  GlobalConfigurationTradesRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                {canDeleteConfigurations && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();

                      onOpenUtilPopup();
                    }}
                    onMouseEnter={() => setDeleteIconHovered(true)}
                    onMouseLeave={() => setDeleteIconHovered(false)}
                  >
                    <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                  </div>
                )}
                {canEditConfigurations && (
                  <div
                    className={classNames(GlobalConfigurationTradesRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                    onClick={() => onOpenPopup(SettingsPagePopups.updateGlobalTrade, popupHandler)}
                    onMouseEnter={() => setEditIconHovered(true)}
                    onMouseLeave={() => setEditIconHovered(false)}
                  >
                    <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                  </div>
                )}
              </div>
            </td>
          </>
        ) : (
          <></>
        )}
      </tr>
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteGlobalTradeMutation.mutate();

            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      {popupHandler.get(SettingsPagePopups.updateGlobalTrade)!.isOpen && (
        <GlobalConfigurationTradesPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(SettingsPagePopups.updateGlobalTrade, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.updateGlobalTrade)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.updateGlobalTrade)}
          globalTradeItem={tradeItem}
        />
      )}
    </>
  );
};

export default GlobalConfigurationTradesRow;
