import GlobalStyles from "../../../assets/css/GlobalStyles.module.css";
import UploadedFilesListItemStyles from "./UploadedFilesListItem.module.css";
import React, { useState } from "react";
import deleteFileIcon from "../../../assets/images/delete-bin-cyan.svg";
import classNames from "classnames";

interface UploadedFilesListItemProps {
  uploadedFile: File;
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

export const UploadedFilesListItem: React.FC<UploadedFilesListItemProps> = ({ uploadedFile, setUploadedFiles }) => {
  const [showFileDelete, setShowFileDelete] = useState<boolean>(false);

  const removeFile = (fileName: string) => {
    setUploadedFiles((currentState) => {
      const uploadedFilesClone = currentState.slice();
      const chosenFileIndex = uploadedFilesClone.findIndex((file) => file.name === fileName);
      if (chosenFileIndex !== -1) {
        uploadedFilesClone.splice(chosenFileIndex, 1);
      }
      return uploadedFilesClone;
    });
  };

  return (
    <div
      className={classNames(GlobalStyles.flex, GlobalStyles.gap)}
      onMouseEnter={() => setShowFileDelete(true)}
      onMouseLeave={() => setShowFileDelete(false)}
    >
      <div className={classNames(GlobalStyles.centerVertical)}>{uploadedFile.name}</div>
      <div className={classNames(GlobalStyles.flex1)} />
      <div
        className={classNames(
          showFileDelete ? UploadedFilesListItemStyles.deleteFileIcon : UploadedFilesListItemStyles.deleteFileHiddenIcon,
          GlobalStyles.centerVertical,
        )}
        onClick={() => removeFile(uploadedFile.name)}
      >
        <img src={deleteFileIcon} alt="" className={classNames(UploadedFilesListItemStyles.deleteFileIconImg)} />
      </div>
    </div>
  );
};
