import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config";
import { ApiResponseGetActiveMmrs } from "epcm-common/dist/Types/MmrTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getGroupTimesheetProjectEmployeeActiveMMRs = async (
  id: number,
  timesheetGroupId: number,
  timesheetId: number,
  timesheetEmployeeId: number,
  projectEmployeeId: number,
): Promise<ApiResponseGetActiveMmrs[]> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/timesheets/${timesheetId}/employee/${timesheetEmployeeId}/projectEmployee/${projectEmployeeId}`,
    method: "GET",
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies ApiResponseGetActiveMmrs[];
};
