import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiResponseCtrMap } from "epcm-common/dist/Types/CtrTypes.ts";
import { FrontendCtrMappingRow } from "../../../types/apicallstypes/CtrMappingTypes.ts";

const URL = `${EPCM_API_URLS.projects}`;

export const getProjectCtrMapping = async (projectId: number): Promise<ApiResponseCtrMap[]> => {
  const params = new URLSearchParams();

  const config: AxiosRequestConfig = {
    url: `${URL}/${projectId}/ctrs-mapping`,
    method: "GET",
    params,
  };
  const response = await epcmAxiosInstance(config);

  return response.data as FrontendCtrMappingRow[];
};
