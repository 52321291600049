import classNames from "classnames";
import TimesheetReportListItemStyles from "./TimesheetReportsListItemStyles.module.css";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import reportIconPending from "../../../../../../../assets/images/report-pending-icon.svg";
import reportIconApproved from "../../../../../../../assets/images/report-approved-icon.svg";
import { capitalizeFirstLetter } from "../../../../../../../utils/StringManipulation.ts";
import arrowDownBlue from "../../../../../../../assets/images/arrow-down-blue-fill.svg";
import { formatDate } from "../../../../../../../utils/DateManipulation.ts";
import { Fade, Tooltip } from "@mui/material";
import downloadIconHovered from "../../../../../../../assets/images/download-icon-green-bg.svg";
import downloadIcon from "../../../../../../../assets/images/download-icon-dark-blue-bg.svg";
import addFilesIconHovered from "../../../../../../../assets/images/add-files-icon-blue-bg.svg";
import addFilesIcon from "../../../../../../../assets/images/add-files-icon-dark-blue-bg.svg";
import deleteIconHovered from "../../../../../../../assets/images/trashcan-icon-red.svg";
import deleteIcon from "../../../../../../../assets/images/trashcan-icon-dark-blue.svg";
import archiveIconHovered from "../../../../../../../assets/images/archive-yellow.svg";
import archiveIcon from "../../../../../../../assets/images/archive-dark-blue.svg";
import { FC, useMemo, useState } from "react";
import { TimesheetReportStatusEnum, TimesheetReportType } from "../../../../../../../types/projects/ProjectTimesheetReportTypes.ts";

interface TimesheetReportsListItemProps {
  timesheetReportItem: TimesheetReportType;
}
const TimesheetReportsListItem: FC<TimesheetReportsListItemProps> = ({ timesheetReportItem }) => {
  const [isDownloadButtonHovered, setIsDownloadButtonHovered] = useState<boolean>(false);
  const [isAddFilesButtonHovered, setIsAddFilesButtonHovered] = useState<boolean>(false);
  const [isDeleteButtonHovered, setIsDeleteButtonHovered] = useState<boolean>(false);
  const [isArchiveButtonHovered, setIsArchiveButtonHovered] = useState<boolean>(false);
  //const [openReferenceFilesPopupWithDnD, setOpenReferenceFilesPopupWithDnD] = useState<boolean | null>(null);

  const isPending = useMemo(() => {
    return timesheetReportItem.approvalStatus === TimesheetReportStatusEnum.pending;
  }, [timesheetReportItem]);
  const isRejected = useMemo(() => {
    return timesheetReportItem.approvalStatus === TimesheetReportStatusEnum.rejected;
  }, [timesheetReportItem]);

  return (
    <div
      className={classNames(
        TimesheetReportListItemStyles.timesheetReportItemContainer,
        isPending
          ? TimesheetReportListItemStyles.timesheetReportItemContainerPending
          : isRejected
            ? TimesheetReportListItemStyles.timesheetReportItemContainerRejected
            : undefined,
        GlobalStyles.flex,
        GlobalStyles.gap025,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img
              className={classNames(TimesheetReportListItemStyles.timesheetReportItemDocIconImg)}
              src={isPending ? reportIconPending : isRejected ? reportIconPending : reportIconApproved}
              alt="doc"
            />
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
          <div className={classNames()}>{`Report Period`}</div>
          <div
            className={classNames(TimesheetReportListItemStyles.detailsText, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}
          >
            <div>{formatDate(timesheetReportItem.periodStart)}</div>
            <div>-</div>
            <div>{formatDate(timesheetReportItem.periodEnd)}</div>
          </div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
          <div className={classNames()}>{"Client approval"}</div>
          <div
            className={classNames(
              TimesheetReportListItemStyles.detailsText,
              timesheetReportItem.approvalStatus === TimesheetReportStatusEnum.approved
                ? TimesheetReportListItemStyles.approvalStatusApproved
                : timesheetReportItem.approvalStatus === TimesheetReportStatusEnum.pending
                  ? TimesheetReportListItemStyles.approvalStatusPending
                  : timesheetReportItem.approvalStatus === TimesheetReportStatusEnum.rejected
                    ? TimesheetReportListItemStyles.approvalStatusRejected
                    : undefined,
            )}
          >
            {capitalizeFirstLetter(timesheetReportItem.approvalStatus)}
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        {timesheetReportItem.approvalStatus === TimesheetReportStatusEnum.pending && (
          <div
            className={classNames(GlobalStyles.elementWithCursor, GlobalStyles.flex, GlobalStyles.gap05)}
            // onClick={() => onOpenPopup(ProjectsPagePopups.changeManpowerPlanStatus, popupHandler)}
          >
            <div className={classNames(TimesheetReportListItemStyles.linkBlueText, GlobalStyles.centerVertical)}>{"Change"}</div>
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(TimesheetReportListItemStyles.arrowDownBlueIconImg)} src={arrowDownBlue} alt="arrowDown" />
            </div>
          </div>
        )}
      </div>

      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
        <div className={classNames()}>{"Generated on"}</div>
        <div className={classNames(TimesheetReportListItemStyles.detailsText)}>{formatDate(timesheetReportItem.dateUploaded)}</div>
      </div>
      {/*<div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>*/}
      {/*  <div className={classNames()}>{"Uploaded by"}</div>*/}
      {/*  <div className={classNames(TimesheetReportListItemStyles.detailsText)}>{timesheetReportItem.uploadedBy.name}</div>*/}
      {/*</div>*/}
      <div
        className={classNames(
          GlobalStyles.elementWithCursor,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.centerVertical,
        )}
        // onClick={() => {
        //   setOpenReferenceFilesPopupWithDnD(false);
        //   onOpenPopup(ProjectsPagePopups.manpowerPlanAddReferenceFiles, popupHandler);
        // }}
      >
        <div className={classNames()}>{"Reference files"}</div>
        {timesheetReportItem.referenceFiles.length > 0 ? (
          <div className={classNames(TimesheetReportListItemStyles.linkBlueText, GlobalStyles.flex, GlobalStyles.gap05)}>
            <div className={classNames(GlobalStyles.centerVertical)}>{timesheetReportItem.referenceFiles[0].fileName}</div>
            {timesheetReportItem.referenceFiles.length > 1 && (
              <div className={classNames(TimesheetReportListItemStyles.blueBorderedSquare, GlobalStyles.centerVertical)}>
                {`+${timesheetReportItem.referenceFiles.length - 1}`}
              </div>
            )}
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
        ) : (
          <div className={classNames()}>{"--"}</div>
        )}
      </div>

      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex05s, GlobalStyles.gap3)}>
        <div className={classNames(GlobalStyles.flex1)} />
        <div
          className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
          onMouseEnter={() => setIsDownloadButtonHovered(true)}
          onMouseLeave={() => setIsDownloadButtonHovered(false)}
        >
          <Tooltip TransitionComponent={Fade} title={"Download Version"} placement="top" arrow>
            <img
              className={classNames(TimesheetReportListItemStyles.utilIconImg)}
              src={isDownloadButtonHovered ? downloadIconHovered : downloadIcon}
              alt="download"
            />
          </Tooltip>
        </div>
        <div
          className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
          onMouseEnter={() => setIsAddFilesButtonHovered(true)}
          onMouseLeave={() => setIsAddFilesButtonHovered(false)}
          // onClick={() => {
          //   setOpenReferenceFilesPopupWithDnD(true);
          //   onOpenPopup(ProjectsPagePopups.manpowerPlanAddReferenceFiles, popupHandler);
          // }}
        >
          <Tooltip TransitionComponent={Fade} title={"Add Reference Files"} placement="top" arrow>
            <img
              className={classNames(TimesheetReportListItemStyles.utilIconImg)}
              src={isAddFilesButtonHovered ? addFilesIconHovered : addFilesIcon}
              alt="add"
            />
          </Tooltip>
        </div>
        {timesheetReportItem.approvalStatus !== TimesheetReportStatusEnum.rejected ? (
          <div
            className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
            onMouseEnter={() => setIsDeleteButtonHovered(true)}
            onMouseLeave={() => setIsDeleteButtonHovered(false)}
          >
            <Tooltip TransitionComponent={Fade} title={"Delete Version"} placement="top" arrow>
              <img
                className={classNames(TimesheetReportListItemStyles.utilIconImg)}
                src={isDeleteButtonHovered ? deleteIconHovered : deleteIcon}
                alt="delete"
              />
            </Tooltip>
          </div>
        ) : (
          <div
            className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
            onMouseEnter={() => setIsArchiveButtonHovered(true)}
            onMouseLeave={() => setIsArchiveButtonHovered(false)}
          >
            <Tooltip TransitionComponent={Fade} title={"Archive Version"} placement="top" arrow>
              <img
                className={classNames(TimesheetReportListItemStyles.utilIconImg)}
                src={isArchiveButtonHovered ? archiveIconHovered : archiveIcon}
                alt="archive"
              />
            </Tooltip>
          </div>
        )}
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
    </div>
  );
};

export default TimesheetReportsListItem;
