import MainMenuStyles from "./MainMenu.module.css";
import GlobalStyles from "../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { AppRoutesPaths } from "../../../types/AppRoutesTypes.ts";
import { MainMenuButtonsEnum, MainMenuItemType } from "../../../types/BannerSideTypes.ts";
import projectsIconActive from "../../../assets/images/project-icon-active.svg";
import projectsIconIdle from "../../../assets/images/project-icon-idle.svg";
import employeesIconActive from "../../../assets/images/employees-icon-active.svg";
import employeesIconIdle from "../../../assets/images/employees-icon-idle.svg";
import settingsIconActive from "../../../assets/images/settings-icon-active.svg";
import settingsIconIdle from "../../../assets/images/settings-icon-idle.svg";
import permissionsIconActive from "../../../assets/images/permissions_icon_filled.svg";
import permissionsIconIdle from "../../../assets/images/permissions_icon_outlined.svg";

import { FC, useCallback, useState } from "react";
import { GlobalAction } from "../../../types/Roles.ts";
import arrowUp from "../../../assets/images/arrow-up-gray.svg";
import { useRetrieveUserPermittedActions } from "../../../utils/useRetrieveUserPermittedActions.ts";

interface MainMenuProps {
  isBannerCollapsed: boolean;
}

const MAIN_MENU_ITEMS: MainMenuItemType[] = [
  { title: "Employees", url: AppRoutesPaths.employees, iconActive: employeesIconActive, iconIdle: employeesIconIdle, canExpand: false },
  { title: "Projects", url: AppRoutesPaths.projects, iconActive: projectsIconActive, iconIdle: projectsIconIdle, canExpand: false },
  { title: "Configurations", url: AppRoutesPaths.configurations, iconActive: settingsIconActive, iconIdle: settingsIconIdle, canExpand: false },
  {
    title: "Permissions",
    url: AppRoutesPaths.userPermissions,
    iconActive: permissionsIconActive,
    iconIdle: permissionsIconIdle,
    canExpand: false,
  },
];

export const MainMenu: FC<MainMenuProps> = ({ isBannerCollapsed }) => {
  const [isSettingsExpanded, setIsSettingsExpanded] = useState<boolean>(false);
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const canSeeEmployees = canPerformGlobalAction(GlobalAction.EmployeeList);
  const canSeeConfigurations = canPerformGlobalAction(GlobalAction.ConfigList);
  const canSeeProjects = canPerformGlobalAction(GlobalAction.ProjectList);
  const canSeePermissions = canPerformGlobalAction(GlobalAction.UserUpdateRoles);

  const canHideButton = (settingsButtonTitle: MainMenuButtonsEnum): boolean => {
    if (settingsButtonTitle === MainMenuButtonsEnum.Employees && !canSeeEmployees) {
      return true;
    } else if (settingsButtonTitle === MainMenuButtonsEnum.Projects && !canSeeProjects) {
      return true;
    }
    if (settingsButtonTitle === MainMenuButtonsEnum.Configurations && !canSeeConfigurations) {
      return true;
    }
    if (settingsButtonTitle === MainMenuButtonsEnum.Permissions && !canSeePermissions) {
      return true;
    }

    return false;
  };

  const onBannerClick = useCallback(() => {
    setIsSettingsExpanded(false);
  }, [isSettingsExpanded]);

  return (
    <div
      className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.gap4, GlobalStyles.centerVertical)}
    >
      {MAIN_MENU_ITEMS.map((bannerItem) => {
        if (canHideButton(bannerItem.title as MainMenuButtonsEnum)) {
          return null;
        }

        return (
          <div key={bannerItem.title}>
            <div
              className={classNames(
                MainMenuStyles.bannerItemAnchor,
                bannerItem.canExpand && canSeeConfigurations && canSeePermissions && MainMenuStyles.settingsBannerItemExpandable,
                bannerItem.canExpand && (!canSeeConfigurations || !canSeePermissions) && MainMenuStyles.settingsBannerItemExpandableSmall,
                !isBannerCollapsed && bannerItem.canExpand && isSettingsExpanded && MainMenuStyles.settingsBannerItemExpanded,
                !isBannerCollapsed && bannerItem.canExpand && isSettingsExpanded && MainMenuStyles.settingBannerItemActive,
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap05,
              )}
              onClick={() => onBannerClick()}
            >
              <NavLink to={bannerItem.url} key={bannerItem.title}>
                {({ isActive }) => {
                  return (
                    <div
                      className={classNames(
                        MainMenuStyles.bannerItem,
                        isActive && MainMenuStyles.bannerItemActive,
                        GlobalStyles.flex,
                        GlobalStyles.gap,
                      )}
                    >
                      <img
                        className={classNames(MainMenuStyles.itemIconImg, GlobalStyles.centerVertical)}
                        src={isActive ? bannerItem.iconActive : bannerItem.iconIdle}
                        alt="item-icon"
                      />
                      {!isBannerCollapsed && (
                        <>
                          <div className={classNames()}>{bannerItem.title}</div>
                          <div className={classNames(GlobalStyles.flex1)} />
                          <div
                            className={classNames(
                              MainMenuStyles.arrowIcon,
                              !bannerItem.canExpand && MainMenuStyles.arrowIconInvisible,
                              GlobalStyles.centerVertical,
                            )}
                          >
                            <img
                              className={classNames(MainMenuStyles.arrowIconImg, !isSettingsExpanded && MainMenuStyles.arrowIconImgReverted)}
                              src={arrowUp}
                              alt="arrow"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  );
                }}
              </NavLink>
            </div>
          </div>
        );
      })}
    </div>
  );
};
