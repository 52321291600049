import { FC } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import MMrInformationContentRightSideStyles from "./MmrInformationContentRightSide.module.css";
import MmrInformationList from "../mmrinformationlist/MmrInformationList.tsx";
import { formatDate } from "../../../../../../../utils/DateManipulation.ts";
import { MmrDataType } from "../../../../../../../types/projects/MmrTypes.ts";

interface MmrInformationContentLeftSideProps {
  projectEmployeeTypeOfMobilization: string | null | undefined;
  projectEmployeeAnticipatedMobilizationDate: Date | undefined;
  projectEmployeeActualMobilizationDate: Date | null | undefined;
  projectEmployeeAnticipatedDemobilizationDate: Date | undefined | null;
  projectEmployeeActualDemobilizationDate: Date | null | undefined;
  projectEmployeeIsKeyPersonnel: boolean | undefined;
  projectEmployeeJustificationForRequest: string | null | undefined;
  projectEmployeeClientInterview: Date | null | undefined;
  projectEmployeeApprovalRecord: string | null | undefined;
}

const MmrInformationContentRightSide: FC<MmrInformationContentLeftSideProps> = ({
  projectEmployeeTypeOfMobilization,
  projectEmployeeAnticipatedMobilizationDate,
  projectEmployeeActualMobilizationDate,
  projectEmployeeAnticipatedDemobilizationDate,
  projectEmployeeActualDemobilizationDate,
  projectEmployeeIsKeyPersonnel,
  projectEmployeeJustificationForRequest,
  projectEmployeeClientInterview,
  projectEmployeeApprovalRecord,
}) => {
  const sectionItems: MmrDataType[] = [
    { title: "Type of Mobilization", content: projectEmployeeTypeOfMobilization },
    { title: "Anticipated Mobilization Date", content: formatDate(projectEmployeeAnticipatedMobilizationDate!) },
    { title: "Actual Mobilization Date", content: projectEmployeeActualMobilizationDate ? formatDate(projectEmployeeActualMobilizationDate) : "" },
    { title: "Anticipated Demobilization Date", content: formatDate(projectEmployeeAnticipatedDemobilizationDate!) },
    {
      title: "Actual Demobilization Date",
      content: projectEmployeeActualDemobilizationDate ? formatDate(projectEmployeeActualDemobilizationDate) : "",
    },
    { title: "Key Personnel", content: `${projectEmployeeIsKeyPersonnel}` },
    { title: "Justification for Request", content: projectEmployeeJustificationForRequest },
    {
      title: "Client Interview",
      content: projectEmployeeClientInterview ? formatDate(projectEmployeeClientInterview) : "",
    },
    { title: "Approval Record", content: projectEmployeeApprovalRecord },
  ];
  return (
    <div
      className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15, MMrInformationContentRightSideStyles.container)}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15)}>
        <MmrInformationList mmrInformationItems={sectionItems} />
      </div>
    </div>
  );
};

export default MmrInformationContentRightSide;
