import React, { ReactNode, useState } from "react";
import { EpcmSnackbarMessage } from "../../../types/EpcmToastProps.ts";
import { GlobalNotificationContext } from "../GlobalNotificationContext.ts";
import StackSnackbar from "../StackSnackbar.tsx";

interface EpcmEventsNotifierProps {
  children: ReactNode;
}

export const EpcmEventsNotifier: React.FC<EpcmEventsNotifierProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<EpcmSnackbarMessage[]>(() => {
    const savedNotifications = localStorage.getItem("notifications");
    return savedNotifications ? JSON.parse(savedNotifications) : [];
  });

  const addNotification = (message: EpcmSnackbarMessage) => {
    //Add only if the message is not already in the notifications
    setNotifications((prev) => {
      if (!prev.some((notification) => notification.fileId === message.fileId)) {
        const updatedNotifications = [...prev, message];
        return updatedNotifications;
      }
      return prev;
    });
  };

  const updateNotification = (message: EpcmSnackbarMessage) => {
    setNotifications((prev) => {
      const index = prev.findIndex((notification) => notification.fileId === message.fileId);
      if (index !== -1) {
        const updatedNotifications = [...prev];
        updatedNotifications[index] = message;
        return updatedNotifications;
      }
      return prev;
    });
  };

  const removeNotification = (fileId: string) => {
    setNotifications((prev) => {
      return prev.filter((notification) => notification.fileId !== fileId);
    });
  };

  return (
    <GlobalNotificationContext.Provider value={{ addNotification, removeNotification, updateNotification, notifications, setNotifications }}>
      {children}
      <StackSnackbar />
    </GlobalNotificationContext.Provider>
  );
};
