import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import FormEmployeeInfoExtraStyles from "./FormEmployeeInfoExtra.module.css";
import React, { FC } from "react";
import qualificationIcon from "../../../../../../assets/images/graduation-hat-icon-gray-fill.svg";
import certificationIcon from "../../../../../../assets/images/certification-icon-gray-fill.svg";
import experienceIcon from "../../../../../../assets/images/suitcase-clock-icon-gray-fill.svg";
import { EmployeeExtraInfoEnum } from "../../../../../../types/employees/EmployeesTypes.ts";

interface FormEmployeeInfoExtraProps {
  extraInfoType: EmployeeExtraInfoEnum;
  employeeExtraInfo: string;
  onEmployeeExtraInfoChange: (eventValue: string) => void;
}

interface DisplayTextType {
  icon: string;
  label: string;
  noInfoText: string;
}

const employeeExtraInfoEnumMap: Map<EmployeeExtraInfoEnum, DisplayTextType> = new Map([
  [EmployeeExtraInfoEnum.qualifications, { icon: qualificationIcon, label: "Qualifications", noInfoText: "No qualifications" }],
  [EmployeeExtraInfoEnum.certifications, { icon: certificationIcon, label: "Certifications", noInfoText: "No qualifications" }],
  [EmployeeExtraInfoEnum.experience, { icon: experienceIcon, label: "Experience", noInfoText: "No experience info" }],
]);

export const FormEmployeeInfoExtra: FC<FormEmployeeInfoExtraProps> = ({ extraInfoType, employeeExtraInfo, onEmployeeExtraInfoChange }) => {
  const onChangeExtraInfo = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    onEmployeeExtraInfoChange(event.target.value);
  };

  return (
    <div
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.flex1,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap15,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap075)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img className={classNames(FormEmployeeInfoExtraStyles.labelIconImg)} src={employeeExtraInfoEnumMap.get(extraInfoType)!.icon} alt="label" />
        </div>
        <div className={classNames(FormEmployeeInfoExtraStyles.labelText, GlobalStyles.centerVertical)}>
          {employeeExtraInfoEnumMap.get(extraInfoType)!.label}
        </div>
      </div>
      <textarea
        value={employeeExtraInfo}
        onChange={onChangeExtraInfo}
        placeholder={"Type here..."}
        className={classNames(FormEmployeeInfoExtraStyles.textareaContainer)}
      />
      {/*{employeeExtraInfo ? (*/}
      {/*  <div*/}
      {/*    className={classNames(*/}
      {/*      FormEmployeeInfoExtraStyles.mainTextContainer,*/}
      {/*      GlobalStyles.flex,*/}
      {/*      GlobalStyles.flex1,*/}
      {/*      GlobalStyles.gap,*/}
      {/*      GlobalStyles.overflowHiddenFullHeight,*/}
      {/*    )}*/}
      {/*  >*/}
      {/*    <div className={classNames()}>*/}
      {/*      <div className={classNames(FormEmployeeInfoExtraStyles.bulletPoint)} />*/}
      {/*    </div>*/}
      {/*    <div className={classNames(FormEmployeeInfoExtraStyles.mainText, GlobalStyles.flex1)}>{employeeExtraInfo}</div>*/}
      {/*  </div>*/}
      {/*) : (*/}
      {/*  <div className={classNames(FormEmployeeInfoExtraStyles.noInfoContainer)}>{employeeExtraInfoEnumMap.get(extraInfoType)!.noInfoText}</div>*/}
      {/*)}*/}
    </div>
  );
};
