import React, { useMemo, useState } from "react";

interface DndComponentStatesType {
  chosenFileList: File[];
  setChosenFileList: React.Dispatch<React.SetStateAction<File[]>>;
  hasChosenFiles: boolean;
  isAnyLoading: boolean;
  setIsAnyLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useDndComponentStates = (): DndComponentStatesType => {
  const [chosenFileList, setChosenFileList] = useState<File[]>([]);
  const [isAnyLoading, setIsAnyLoading] = useState(false);

  const hasChosenFiles = useMemo(() => {
    return chosenFileList.length > 0;
  }, [chosenFileList]);

  return {
    chosenFileList,
    setChosenFileList,
    hasChosenFiles,
    isAnyLoading,
    setIsAnyLoading,
  } satisfies DndComponentStatesType;
};
