import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

export const createOvertimeRequest = async (projectId: number, overtimeFile: FormData) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/overtime`,
    method: "POST",
    data: overtimeFile,
  } as AxiosRequestConfig;

  return epcmAxiosInstance(config);
};
