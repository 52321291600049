import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

export const deleteProjectFile = async (projectId: number, sseRequestId: number) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/sseRequests/${sseRequestId}`,
    method: "DELETE",
  } as AxiosRequestConfig;
  return epcmAxiosInstance(config);
};
