import { FC, useEffect, useMemo } from "react";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import CtrListItemStyles from "./CtrListItem.module.css";
import { Skeleton } from "@mui/material";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useEpcmApiProjectsUtils } from "../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { FrontendCtrBasicType, FrontendCtrLimitedType } from "../../../../../types/apicallstypes/CtrTypes.ts";
import { useParams } from "react-router-dom";
import tickIcon from "../../../../../assets/images/tick-white.svg";

interface CtrListItemProps {
  ctrItem: FrontendCtrLimitedType;
  isSelected: boolean;
  onCtrToggle: (ctrItem: FrontendCtrLimitedType) => void;
}

export const CtrListItem: FC<CtrListItemProps> = ({ ctrItem, isSelected, onCtrToggle }) => {
  const { projectId } = useParams();
  const { getProjectCtr } = useEpcmApiProjectsUtils();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const ctrQuery = useQuery({
    queryKey: [QueryNames.ProjectCtrs, projectId, ctrItem.id, DetailLevel.BASIC],
    queryFn: () => getProjectCtr(parseInt(projectId!), ctrItem.id, DetailLevel.BASIC),

    enabled: isAuthorized,
    select: (data) => data as FrontendCtrBasicType,
  });

  const ctrData = useMemo(() => {
    return ctrQuery.data ?? undefined;
  }, [ctrQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectCtrs, projectId, ctrItem.id, DetailLevel.BASIC] })
        .then(() => console.log(`In select ctr for project, in ctr with code ${ctrItem.code}, ${QueryNames.ProjectCtrs} query canceled`));
    };
  }, [queryClient, projectId, ctrItem]);

  return ctrData ? (
    <div
      className={classNames(
        CtrListItemStyles.ctrItemContainer,
        isSelected && CtrListItemStyles.ctrItemContainerSelected,
        GlobalStyles.flex,
        GlobalStyles.gap075,
      )}
      onClick={() => onCtrToggle(ctrItem)}
    >
      {isSelected && (
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img className={classNames(CtrListItemStyles.tickIconImg)} src={tickIcon} alt="tick" />
        </div>
      )}
      <div className={classNames(CtrListItemStyles.ctrItemCode, isSelected && CtrListItemStyles.ctrItemCodeSelected)}>{ctrData.code}</div>
      <div className={classNames()}>{"-"}</div>
      <div className={classNames()}>{ctrData.description}</div>
    </div>
  ) : (
    <Skeleton variant="rounded" height={30} width={"100%"} />
  );
};
