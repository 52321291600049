import { FC } from "react";
import { Skeleton } from "@mui/material";
import { MmrDataType } from "../../../../../../../../../types/projects/MmrTypes.ts";

interface MmrDataContentProps {
  mmrDataItem: Pick<MmrDataType, "content" | "isLoading">;
}
const MmrDataContent: FC<MmrDataContentProps> = ({ mmrDataItem }) => {
  if (mmrDataItem.isLoading) {
    return <Skeleton variant="text" width={100} height={20} />;
  }
  if (!mmrDataItem.content) {
    return "-";
  }

  return mmrDataItem.content;
};

export default MmrDataContent;
