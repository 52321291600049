import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import { useInView } from "react-intersection-observer";
import { FC, useEffect, useMemo } from "react";

import ConfigurationGeneralTable from "../configurationgeneraltable/ConfigurationGeneralTable.tsx";
import { Skeleton } from "@mui/material";
import {
  configurationColumnDefinitions,
  configurationScreenGetQueryNameMap,
  ConfigurationScreenType,
  ProjectConfigurationFunctionType,
} from "../../../../../types/projects/ConfigurationTypes.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useProjectContext } from "../../../ProjectsUtils.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../apicalls/config.ts";
import useConfigurationFetchingFunctionMap from "../../../../../utils/configurations/useConfigurationsFetchingFunctionMap.ts";
import {
  ConfigurationTargetType,
  globalConfigurationColumnDefinitions,
  GlobalConfigurationFunctionType,
  globalConfigurationScreenGetQueryNameMap,
  GlobalConfigurationScreenType,
} from "../../../../../types/settings/GlobalConfigurationTypes.ts";
import useGlobalConfigurationFetchingFunctionMap from "../../../../../utils/configurations/useGlobalConfigurationFetchingFunctionMap.ts";
import { useHandleUnauthorized } from "../../../../../utils/use-handle-unauthorized.ts";

interface ConfigurationEntityTableProps {
  searchQuery: string;
  configurationScreenType?: ConfigurationScreenType;
  globalConfigurationScreenType?: GlobalConfigurationScreenType;
  target: ConfigurationTargetType;
}

const ConfigurationEntityTableManager: FC<ConfigurationEntityTableProps> = ({
  searchQuery,
  configurationScreenType,
  globalConfigurationScreenType,
  target,
}) => {
  const queryClient = useQueryClient();

  const { ref, inView } = useInView();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const projectContext = useProjectContext();
  const currentProject = projectContext?.currentProject;
  const configurationsFetchingFunctionMap = useConfigurationFetchingFunctionMap();
  const globalConfigurationsFetchingFunctionMap = useGlobalConfigurationFetchingFunctionMap();
  const { handleErrorRedirect } = useHandleUnauthorized();

  const queryName = useMemo(() => {
    if (configurationScreenType) {
      return configurationScreenGetQueryNameMap.get(configurationScreenType);
    }
    return globalConfigurationScreenGetQueryNameMap.get(globalConfigurationScreenType!);
  }, [configurationScreenType, globalConfigurationScreenType]);

  const fetchingFunction = useMemo(() => {
    if (configurationScreenType) {
      return configurationsFetchingFunctionMap[configurationScreenType];
    }
    return globalConfigurationsFetchingFunctionMap[globalConfigurationScreenType!];
  }, [configurationScreenType, globalConfigurationScreenType, globalConfigurationsFetchingFunctionMap, configurationsFetchingFunctionMap]);

  const tableColumns = useMemo(() => {
    if (configurationScreenType) {
      return configurationColumnDefinitions[configurationScreenType];
    }
    return globalConfigurationColumnDefinitions[globalConfigurationScreenType!];
  }, [configurationScreenType, globalConfigurationScreenType]);

  const allProjectEntitiesQuery = useInfiniteQuery({
    queryKey: [queryName, currentProject?.id ?? undefined, searchQuery],
    queryFn: ({ pageParam }) => {
      if (!fetchingFunction) {
        throw new Error("Fetching function is undefined");
      }
      return target === ConfigurationTargetType.project
        ? (fetchingFunction as ProjectConfigurationFunctionType)(currentProject?.id, pageParam, searchQuery, PAGINATION_PAGE_SIZE).catch(
            handleErrorRedirect,
          )
        : (fetchingFunction as GlobalConfigurationFunctionType)(pageParam, searchQuery, PAGINATION_PAGE_SIZE).catch(handleErrorRedirect);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const allProjectEntitiesData = useMemo(() => {
    return allProjectEntitiesQuery.data?.pages.flatMap((page) => page.data) || [];
  }, [allProjectEntitiesQuery.data]);

  useEffect(() => {
    if (
      inView &&
      !allProjectEntitiesQuery.isLoading &&
      !allProjectEntitiesQuery.isFetching &&
      !allProjectEntitiesQuery.isFetchingNextPage &&
      allProjectEntitiesQuery.hasNextPage
    ) {
      void allProjectEntitiesQuery.fetchNextPage();
    }
  }, [inView, allProjectEntitiesQuery]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [queryName, currentProject?.id ?? undefined, searchQuery] }).then(() => {
        console.log(`In configurations component, ${queryName} query cancelled`);
      });
    };
  }, [allProjectEntitiesData, currentProject, searchQuery, queryClient, queryName]);

  return (
    <ConfigurationGeneralTable
      data={allProjectEntitiesData}
      loading={allProjectEntitiesQuery.isLoading}
      columns={tableColumns}
      infiniteScrollingComponent={{
        infiniteQueryResult: allProjectEntitiesQuery,
        loaderComponent: <Skeleton variant={"rectangular"} width={"100%"} height={40}></Skeleton>,
        reference: ref,
      }}
      globalConfigurationScreenType={globalConfigurationScreenType}
      configurationScreenType={configurationScreenType}
      target={target}
    />
  );
};

export default ConfigurationEntityTableManager;
