import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "@mui/material";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ProjectUserDocumentStyles from "./ProjectUserDocuments.module.css";
import ProjectUserDocumentItem from "./projectuserdocumentitem/ProjectUserDocumentItem";
import InfiniteScrollInViewElement from "../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement";
import { QueryNames } from "../../../../types/apicallstypes/queryCommons";
import { useEpcmApiProjectFiles } from "../../../../apicalls/projects/projectfiles/useEpcmApiProjectFiles";
import { useImpersonationStore } from "../../../../store/use-impersonation-store";

import {
  convertBackendFileTransactionTypeToFrontend,
  ExtendedFrontendFileType,
  FileFiltersArray,
  FilesFilterEnum,
  FilesFilterType,
  FileStatusSubFilterIdEnum,
  FileTransactionTypeSubFilterIdEnum,
} from "../../../../types/projects/FileTypes";
import { PAGINATION_PAGE_SIZE } from "../../../../apicalls/config";
import { useInView } from "react-intersection-observer";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../types/Roles.ts";
import { useHandleUnauthorized } from "../../../../utils/use-handle-unauthorized.ts";
import { PageFilterContainer } from "../../../../ui/pagefiltercontainer/PageFilterContainer.tsx";
import { QuickFilterButton } from "../../../../ui/quickfilterbutton/QuickFilterButton.tsx";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import folderIcon from "../../../../assets/images/user-filter-documents-icon.svg";
import deleteIcon from "../../../../assets/images/x-mark-orange-icon.svg";
import ProjectFilesFilterPopup from "../../popups/projectfilesfilterpopup/ProjectFilesFilterPopup.tsx";
import { capitalizeFirstLetter } from "../../../../utils/StringManipulation.ts";
import { getFileEvents } from "../../../../apicalls/projects/getFileEvents.ts";
import { readFileEventStream, validateSseResponse } from "../../../../utils/validateSseResponse.ts";

const ProjectUserDocuments = () => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { inView, ref } = useInView();
  const { getAllProjectFiles } = useEpcmApiProjectFiles();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { handleErrorRedirect } = useHandleUnauthorized();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const [filterList, setFilterList] = useState<FilesFilterType[]>(FileFiltersArray);

  const [sseEvents, setSseEvents] = useState<ExtendedFrontendFileType[]>([]);
  const [completedFileIds, setCompletedFileIds] = useState<Set<string>>(new Set());
  const sseInitialized = useRef(false);
  const readerRef = useRef<ReadableStreamDefaultReader<string> | null>(null);

  const clearSpecificSubFilterInFilterList = useCallback(
    (filterType: FilesFilterEnum, subFilterId: number) => {
      setFilterList((prevFilterList) => {
        const newFilterList = [...prevFilterList];
        newFilterList
          .filter((filter) => filter.id === filterType)
          .forEach((filter) => {
            filter.subFilters.forEach((subFilter) => {
              if (subFilter.id === subFilterId) {
                subFilter.isActive = false;
              }
            });
          });
        return newFilterList;
      });
    },
    [setFilterList],
  );

  const isSubFilterActive = useCallback(
    (filterType: FilesFilterEnum, subFilterId: number) => {
      return filterList.find((filter) => filter.id === filterType)?.subFilters.find((subFilter) => subFilter.id === subFilterId)?.isActive || false;
    },
    [filterList],
  );

  const toggleShortcutSubFilter = useCallback(
    (filterType: FilesFilterEnum, subFilterId: number) => {
      setFilterList((prevFilterList) =>
        prevFilterList.map((filter) =>
          filter.id === filterType
            ? {
                ...filter,
                subFilters: filter.subFilters.map((subFilter) =>
                  subFilter.id === subFilterId ? { ...subFilter, isActive: !subFilter.isActive } : subFilter,
                ),
              }
            : filter,
        ),
      );
    },
    [setFilterList],
  );

  const getAllFilesQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectFiles, projectId, filterList],
    queryFn: ({ pageParam }) =>
      getAllProjectFiles(parseInt(projectId!), pageParam, PAGINATION_PAGE_SIZE, undefined, filterList).catch(handleErrorRedirect),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const getAllFilesData = useMemo(() => {
    const uploadedFiles = getAllFilesQuery.data?.pages?.flatMap((page) => page.data) ?? [];
    return [...sseEvents.filter((event) => !completedFileIds.has(event.fileId)), ...uploadedFiles];
  }, [getAllFilesQuery.data, sseEvents, completedFileIds]);

  const canListDocuments = canPerformProjectAction(ProjectAction.ProjectListDocuments);

  const fileFiltersMap = useMemo(() => new Map(filterList.map((filter) => [filter.id, filter])), [filterList]);

  useEffect(() => {
    if (
      inView &&
      !getAllFilesQuery.isLoading &&
      !getAllFilesQuery.isFetching &&
      !getAllFilesQuery.isFetchingNextPage &&
      getAllFilesQuery.hasNextPage
    ) {
      void getAllFilesQuery.fetchNextPage();
    }
  }, [inView, getAllFilesQuery]);

  useEffect(() => {
    if (!sseInitialized.current) {
      sseInitialized.current = true;

      const fetchFileEvents = async () => {
        try {
          const response = await getFileEvents(parseInt(projectId!));
          const reader: ReadableStreamDefaultReader<string> = readFileEventStream(response);
          readerRef.current = reader;

          while (true) {
            const { value, done } = await reader.read();

            if (done) {
              break;
            }

            if (value) {
              const messages = validateSseResponse(value);
              setSseEvents((prevEvents) => {
                const newEvents = [...prevEvents];
                messages.forEach((message) => {
                  const existingEventIndex = newEvents.findIndex((event) => event.fileId === message.fileId);
                  const updatedEvent: ExtendedFrontendFileType = {
                    ...newEvents[existingEventIndex],
                    fileId: message.fileId,
                    status: message.status,
                    progress: message.progress,
                    creationDateTimestamp: new Date(),
                    eventId: -1,
                    type: message.fileType,
                    userCode: "",
                    transactionType: convertBackendFileTransactionTypeToFrontend(message.transactionType),
                    fileName: message.fileName,
                  };

                  if (existingEventIndex >= 0) {
                    newEvents[existingEventIndex] = updatedEvent;
                  } else {
                    newEvents.push(updatedEvent);
                  }

                  if (message.progress === 100) {
                    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectFiles, projectId] });
                    setCompletedFileIds((prevIds) => new Set(prevIds.add(message.fileId)));
                  }
                });

                return newEvents;
              });
            }
          }
        } catch (error) {
          console.error("Error while fetching file events", error);
          await new Promise((resolve) => setTimeout(resolve, 5000)); // Retry after 5 seconds
        }
      };

      void fetchFileEvents();
    }
  }, [projectId, isAuthorized, getFileEvents, queryClient, completedFileIds]);

  useEffect(() => {
    return () => {
      if (readerRef.current) {
        readerRef.current
          .cancel()
          .then(() => {
            console.log("ReadableStreamDefaultReader cancelled");
          })
          .catch((error) => {
            console.error("Error while cancelling ReadableStreamDefaultReader", error);
          });
      }
    };
  }, [readerRef]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({
          queryKey: [[QueryNames.ProjectFiles, projectId, filterList]],
        })
        .then(() => {
          console.log(`In project-user-documents component, ${QueryNames.ProjectFiles} query cancelled`);
        });
    };
  }, [projectId, filterList, queryClient]);

  return (
    <div className={classNames(ProjectUserDocumentStyles.contentContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          {canListDocuments && (
            <PageFilterContainer
              smallerFontText={"Filter by"}
              mainText={"Files"}
              onFiltersClick={() => {
                onOpenPopup(ProjectsPagePopups.documentsFilter, popupHandler);
              }}
            >
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap15)}>
                <QuickFilterButton
                  isFilterActive={isSubFilterActive(FilesFilterEnum.fileTransactionType, FileTransactionTypeSubFilterIdEnum.exported)}
                  headerLineText={""}
                  smallerFontText={"Exported"}
                  mainText={"Files"}
                  onClickFn={() => {
                    toggleShortcutSubFilter(FilesFilterEnum.fileTransactionType, FileTransactionTypeSubFilterIdEnum.exported);
                  }}
                />
                <QuickFilterButton
                  isFilterActive={isSubFilterActive(FilesFilterEnum.fileStatus, FileStatusSubFilterIdEnum.failed)}
                  headerLineText={""}
                  smallerFontText={"Failed"}
                  mainText={"Files"}
                  onClickFn={() => {
                    toggleShortcutSubFilter(FilesFilterEnum.fileStatus, FileStatusSubFilterIdEnum.failed);
                  }}
                />
              </div>
            </PageFilterContainer>
          )}
        </div>
        <div
          className={classNames(
            ProjectUserDocumentStyles.activeFilterListContainer,
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.centerHorizontal,
            GlobalStyles.gap,
          )}
        >
          {Array.from(fileFiltersMap.entries()).map(([filterType, filterData]) =>
            filterData.subFilters
              .filter((subFilter) => subFilter.isActive)
              .map((subFilter) => (
                <div
                  key={`${filterType}-${subFilter.id}-${subFilter.title}`}
                  className={classNames(
                    GlobalStyles.flex,
                    GlobalStyles.centerHorizontal,
                    GlobalStyles.gap05,
                    ProjectUserDocumentStyles.activeFilterContainer,
                  )}
                  onClick={() => clearSpecificSubFilterInFilterList(filterType, subFilter.id)}
                >
                  <img src={folderIcon} className={classNames(ProjectUserDocumentStyles.activeFilterImgIcon)} alt="File filter icon" />
                  <div className={classNames(ProjectUserDocumentStyles.activeFilterTitle)}>
                    {filterType === FilesFilterEnum.fileStatus ? capitalizeFirstLetter(subFilter.title) : subFilter.title}
                  </div>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.elementWithCursor)}>
                    <img className={classNames(ProjectUserDocumentStyles.activeFilterDeleteImg)} src={deleteIcon} alt="Delete icon" />
                  </div>
                </div>
              )),
          )}
        </div>
      </div>
      <div
        className={classNames(
          ProjectUserDocumentStyles.documentListContainer,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
        )}
      >
        {getAllFilesData && canListDocuments && getAllFilesData.length > 0 ? (
          <>
            {getAllFilesData.map((documentItem, index) => (
              <ProjectUserDocumentItem
                key={documentItem.fileId + documentItem.creationTime + documentItem.status + index}
                documentItem={documentItem}
              />
            ))}
            <InfiniteScrollInViewElement
              key={"InfiniteScrollInViewElement"}
              reference={ref}
              infiniteQueryResult={getAllFilesQuery}
              loaderComponent={<Skeleton variant={"rounded"} height={50} width={"100%"} />}
            />
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          </>
        ) : (
          <div
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.centerVertical,
              GlobalStyles.overflowHiddenFullHeight,
              GlobalStyles.emptyListMsg,
            )}
          >
            {getAllFilesQuery.isFetching ? "Loading Document data" : "No Documents found"}
          </div>
        )}
      </div>
      {popupHandler.get(ProjectsPagePopups.documentsFilter)!.isOpen && (
        <ProjectFilesFilterPopup
          isOpen={popupHandler.get(ProjectsPagePopups.documentsFilter)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.documentsFilter, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.documentsFilter)}
          secondaryHeaderText={""}
          onApplyFilters={(filterList) => setFilterList(filterList)}
          filterList={filterList}
        />
      )}
    </div>
  );
};

export default ProjectUserDocuments;
