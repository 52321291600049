import { useEffect } from "react";
import { EPCM_IsImpersonationEnabled } from "./apicalls/config";
import { epcmAxiosInstance } from "./apicalls/config.ts";
import { useImpersonationStore } from "./store/use-impersonation-store.ts";

export const ArchirodonAxiosInterceptor = () => {
  const impersonateUserBase64 = useImpersonationStore((state) => state.impersonateUserBase64);

  useEffect(() => {
    const interceptor = epcmAxiosInstance.interceptors.request.use(
      async (config) => {
        if (EPCM_IsImpersonationEnabled) {
          config.headers.Authorization = `Basic ${impersonateUserBase64}`;
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );

    return () => epcmAxiosInstance.interceptors.response.eject(interceptor);
  }, [impersonateUserBase64]);

  return <></>;
};
