import { styled, Select } from "@mui/material";

// This styles the root of the Select component
export const EpcmSelect = styled(Select)({
  "&.MuiSelect-root": {
    fontFamily: "poppins", // Set the font for the select
    fontSize: "0.8em", // Set the font size for the select
    fontWeight: "400", // Set the font weight for the select
    color: "var(--black)", // Set the font color for the select
    borderRadius: "4px",
    borderBottomRadius: "4px",
    outlined: {
      "&:focus": {
        backgroundColor: "#FFF",
      },
    },
  },

  "& .MuiSelect-select": {
    // This targets the visible select element (not the dropdown)
    padding: "0.68em 0.8em",
    fontFamily: "poppins", // Set the font for the select
    fontSize: "0.8em", // Set the font size for the select
    fontWeight: "400",
    borderBottom: "1px solid var(--cyan-dark)",
    color: "var(--black)",
    borderRadius: "4px",
    borderBottomRadius: "4px",
    backgroundColor: "var(--white)",
    outlined: {
      "&:focus": {
        backgroundColor: "#FFF",
      },
    },
  },
  "& .MuiSelect-icon": {
    // This targets the dropdown icon

    color: "var(--cyan-dark)", // Set color of the dropdown icon
  },
  "& .MuiPaper-root": {
    // This targets the dropdown paper
    borderRadius: "4px", // Rounds the corners of the dropdown menu
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Set a shadow for the dropdown paper
    "&.Mui-selected, &.Mui-selected:hover": {
      fontWeight: "600",
      color: "var(--cyan-dark)",
    },
    outlined: {
      "&:focus": {
        backgroundColor: "#FFF",
      },
    },
  },
  "& .MuiMenuItem-root": {
    // This targets the menu items
    fontFamily: "poppins",
    fontSize: "0.95em",
    outlined: {
      "&:focus": {
        backgroundColor: "#FFF",
      },
    },
  },
  // Override styles for selected and focused item
  "& .MuiMenuItem-root.Mui-selected, & .MuiMenuItem-root.Mui-selected:focus": {
    backgroundColor: "#fff", // Set background color to transparent or any desired color
  },

  // Override styles for the focused state of the Select component
  "& .Mui-focused .MuiSelect-select": {
    backgroundColor: "#fff", // Set background color to transparent or any desired color
  },
});
