import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { AxiosRequestConfig } from "axios";
import { FrontendFileIdResponseType } from "../../../types/apicallstypes/GeneralTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getTimesheetFileExported = async (id: number, timesheetGroupId: number, timesheetId: number): Promise<FrontendFileIdResponseType> => {
  const params = new URLSearchParams();

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/timesheets/${timesheetId}/export`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  const responseData = response.data;

  return {
    fileId: responseData,
  };
};
