import { FC } from "react";
import CreateMmrDraftVersionStyles from "./CreateMmrDraftVersion.module.css";
//import MmrVersionAccordionGroupedStyles from "./CreateMmrDraftVersion.module.palette";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import InfoMessageIcon from "../../../../../../../assets/images/info-message-icon.svg";

const CreateMmrDraftVersion: FC = () => {
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05, CreateMmrDraftVersionStyles.container)}>
      <div className={classNames(GlobalStyles.flex, CreateMmrDraftVersionStyles.draftTitleContainer)}>
        <img src={InfoMessageIcon} alt="info message icon" className={classNames(CreateMmrDraftVersionStyles.infoImg)} />
      </div>

      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap15,
          CreateMmrDraftVersionStyles.contentTextContainer,
        )}
      >
        <div className={classNames(CreateMmrDraftVersionStyles.draftTitle)}>DRAFT VERSION</div>
        <div className={classNames(CreateMmrDraftVersionStyles.contentText)}>Draft versions remain Draft as long as you're editing them.</div>
        <div className={classNames(CreateMmrDraftVersionStyles.contentText)}>
          Once the "Issue MMR" button is clicked, the system will generate a normal version with Pending status; which you cannot edit further, but
          only to delete it if any mistake occurred.
        </div>
        <div className={classNames(CreateMmrDraftVersionStyles.contentText)}>
          Draft versions cannot be saved to the system. If you click the "Cancel" button while editing a Draft version, any changes will be lost.
        </div>
        <div className={classNames(CreateMmrDraftVersionStyles.contentText)}>
          Pay attention to all the fields of a cloned MMR, as they are prefilled with the data of the original version you cloned.
        </div>
        <div className={classNames(CreateMmrDraftVersionStyles.contentText)}>
          After issuing a new MMR, it is your responsibility to follow the process for approving an MMR. The system does not send automatically the
          MMR to client for approval.
        </div>
      </div>
    </div>
  );
};

export default CreateMmrDraftVersion;
