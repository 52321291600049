import { FC } from "react";
import classNames from "classnames";
import GeneratePlanLoaderStyles from "./GeneratePlanLoader.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import loaderGif from "../../../../../assets/images/Loader-figma.gif";

interface GeneratePlanLoaderProps {
  progress: number;
}

export const GeneratePlanLoader: FC<GeneratePlanLoaderProps> = ({ progress }) => {
  return (
    <div className={classNames(GeneratePlanLoaderStyles.contentContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(GeneratePlanLoaderStyles.title, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap)}>
        <div>Generating Plan</div>
        <div>{`${progress}%`}</div>
      </div>
      <div className={classNames(GlobalStyles.flex1)} />
      <img src={loaderGif} alt="loader" className={classNames(GeneratePlanLoaderStyles.gif)} />
      <div className={classNames(GlobalStyles.flex1)} />
    </div>
  );
};
