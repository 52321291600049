import {
  ConfigurationScreenType,
  ProjectCategoriesFunction,
  ProjectConfigurationsFunctionMap,
  ProjectCtrFunction,
  ProjectDisciplinesFunction,
  ProjectLocationsFunction,
  ProjectManpowerPositionFunction,
  ProjectPermissionsFunction,
  ProjectPositionsFunction,
  ProjectTradesFunction,
} from "../../types/projects/ConfigurationTypes.ts";
import { useConfigurationScreenMaps } from "../useConfigurationScreenMap.ts";

const useConfigurationFetchingFunctionMap = (): Record<ConfigurationScreenType, ProjectConfigurationsFunctionMap[ConfigurationScreenType]> => {
  const { functionMap } = useConfigurationScreenMaps();

  return {
    [ConfigurationScreenType.positions]: functionMap[ConfigurationScreenType.positions] as ProjectPositionsFunction,
    [ConfigurationScreenType.ctrManagement]: functionMap[ConfigurationScreenType.ctrManagement] as ProjectCtrFunction,
    [ConfigurationScreenType.trades]: functionMap[ConfigurationScreenType.trades] as ProjectTradesFunction,
    [ConfigurationScreenType.locations]: functionMap[ConfigurationScreenType.locations] as ProjectLocationsFunction,
    [ConfigurationScreenType.categories]: functionMap[ConfigurationScreenType.categories] as ProjectCategoriesFunction,
    [ConfigurationScreenType.disciplines]: functionMap[ConfigurationScreenType.disciplines] as ProjectDisciplinesFunction,
    [ConfigurationScreenType.manpowerPositions]: functionMap[ConfigurationScreenType.manpowerPositions] as ProjectManpowerPositionFunction,
    [ConfigurationScreenType.ctrLibrary]: functionMap[ConfigurationScreenType.ctrLibrary] as ProjectCtrFunction,
    [ConfigurationScreenType.permissions]: functionMap[ConfigurationScreenType.permissions] as ProjectPermissionsFunction,
  };
};

export default useConfigurationFetchingFunctionMap;
