import GlobalStyles from "./assets/css/GlobalStyles.module.css";
import AppStyles from "./App.module.css";
import classNames from "classnames";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BannerSide } from "./shared/bannerside/BannerSide.tsx";
import { Outlet } from "react-router-dom";
import { Snackbar } from "@mui/material";
import infoIcon from "./assets/images/info-icon-black-bg.svg";
import closeSnackbarIcon from "./assets/images/close-icon-light.svg";
import { useState } from "react";
import { EPCM_AppContext } from "./utils/AppContext.ts";
import { Impersonator } from "./auth/Impersonator.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
//import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ArchirodonAxiosInterceptor } from "./ArchirodonEPCMAxiosInterceptor.tsx";
import { EpcmEventsNotifier } from "./ui/globalnotificationcontext/epcmeventsnotifier/EpcmEventsNotifier.tsx";
import GlobalRolesAuthenticator from "./auth/GlobalRolesAuthenticator.tsx";

const queryClient = new QueryClient();
const theme = createTheme({
  zIndex: {
    snackbar: 2000,
    modal: 1300,
  },
});

function App() {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [snackbarText, setSnackbarText] = useState<string | null>(null);

  const openSnackbar = (snackbarText: string) => {
    setSnackbarText(snackbarText);
    setIsSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setIsSnackbarOpen(false);
    setSnackbarText(null);
  };

  const EPCM_AppSnackbar = snackbarText ? (
    <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={isSnackbarOpen} onClose={closeSnackbar} autoHideDuration={6000}>
      <div className={classNames(AppStyles.snackbarContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img src={infoIcon} alt="info" className={classNames(AppStyles.snackbarInfoIconImg)} />
          </div>
          <div className={classNames(GlobalStyles.centerVertical)}>{snackbarText}</div>
          <div className={classNames(AppStyles.snackbarCloseIcon)} onClick={closeSnackbar}>
            <img src={closeSnackbarIcon} alt="close" className={classNames(AppStyles.snackbarCloseIconImg)} />
          </div>
        </div>
      </div>
    </Snackbar>
  ) : undefined;

  return (
    <>
      <ArchirodonAxiosInterceptor />
      <EpcmEventsNotifier>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <Impersonator>
              <GlobalRolesAuthenticator>
                <EPCM_AppContext.Provider value={{ openSnackbar, EPCM_AppSnackbar }}>
                  <div className={classNames(AppStyles.content, GlobalStyles.flex, GlobalStyles.flex1)}>
                    <BannerSide />
                    <div className={classNames(AppStyles.outlet, GlobalStyles.flex1)}>
                      <Outlet />
                    </div>
                  </div>
                </EPCM_AppContext.Provider>
              </GlobalRolesAuthenticator>
            </Impersonator>
          </ThemeProvider>
        </QueryClientProvider>
      </EpcmEventsNotifier>
    </>
  );
}

export default App;
