import { useState } from "react";

import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";

import GlobalConfigurationCategoriesStyles from "./GlobalConfigurationCategories.module.css";
import NewCategoryIcon from "../../../../assets/images/configuration/new-position-category-icon.svg";
import SearchBarSubHeader from "../../../projects/project/configuration/searchbarsubheader/SearchBarSubHeader.tsx";
import ConfigurationEntityTableManager from "../../../projects/project/configuration/configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import { ConfigurationTargetType, GlobalConfigurationScreenType } from "../../../../types/settings/GlobalConfigurationTypes.ts";
import { SettingsPagePopups, useSettingsPopups } from "../../use-settings-pupups.ts";
import { configurationsPopupType } from "../../../../types/projects/ConfigurationTypes.ts";
import GlobalConfigurationCategoriesPopup from "../popups/globalconfigurationcategoriespopup/GlobalConfigurationCategoriesPopup.tsx";
import { GlobalAction } from "../../../../types/Roles.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";

const GlobalConfigurationCategories = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const canCreateConfiguration = canPerformGlobalAction(GlobalAction.ConfigCreate);

  return (
    <div
      className={classNames(
        GlobalConfigurationCategoriesStyles.paddingContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap3,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap3,
          GlobalStyles.overflowHiddenFullHeight,
          GlobalConfigurationCategoriesStyles.contentContainer,
        )}
      >
        <SearchBarSubHeader onSearchQueryChange={setSearchQuery} subheaderTitle={"Position Categories"} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
          <div
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
              GlobalConfigurationCategoriesStyles.mainContent,
            )}
          >
            <ConfigurationEntityTableManager
              searchQuery={searchQuery}
              globalConfigurationScreenType={GlobalConfigurationScreenType.categories}
              target={ConfigurationTargetType.global}
            />
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalConfigurationCategoriesStyles.actionBtnContainer)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          {canCreateConfiguration && (
            <div
              onClick={() => onOpenPopup(SettingsPagePopups.addGlobalCategory, popupHandler)}
              className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
            >
              <div>New Position Category</div>
              <div className={classNames(GlobalConfigurationCategoriesStyles.actionBtnImage)}>
                <img src={NewCategoryIcon} alt="New Category Icon" />
              </div>
            </div>
          )}
        </div>
      </div>
      {popupHandler.get(SettingsPagePopups.addGlobalCategory)!.isOpen && (
        <GlobalConfigurationCategoriesPopup
          mode={configurationsPopupType.create}
          closeFn={() => onClosePopup(SettingsPagePopups.addGlobalCategory, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.addGlobalCategory)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.addGlobalCategory)}
        />
      )}
    </div>
  );
};
export default GlobalConfigurationCategories;
