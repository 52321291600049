import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { FrontendGlobalConfigurationsEntityEnum } from "./useEpcmApiConfigurationsMutations.ts";

const API = `${EPCM_API_URLS.configurations}`;

export const updateGlobalConfigurationEntity = async <T>(
  globalConfigurationEntityId: number,
  globalConfigurationEntityType: FrontendGlobalConfigurationsEntityEnum,
  updatedGlobalPositionData: T,
  subEntityId?: number,
) => {
  const subEntityPath = subEntityId ? `/${subEntityId}` : "";
  if (globalConfigurationEntityType === FrontendGlobalConfigurationsEntityEnum.Subposition && subEntityId) {
    const config = {
      url: `${API}/position/${globalConfigurationEntityId}/${globalConfigurationEntityType}${subEntityPath}`,
      method: "PUT",
      data: updatedGlobalPositionData,
    };
    return epcmAxiosInstance(config);
  } else {
    const config = {
      url: `${API}/${globalConfigurationEntityType}/${globalConfigurationEntityId}${subEntityPath}`,
      method: "PUT",
      data: updatedGlobalPositionData,
    };
    return epcmAxiosInstance(config);
  }

  // return epcmAxiosInstance(config);
};
