import { QueryModelWithCountAndData, QueryModelWithPagination } from "../../../../types/apicallstypes/queryCommons.ts";
import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { TimesheetGroupProjectEmployee } from "epcm-common/dist/Types/TimesheetGroupTypes";
import { convertDateToMilSecs } from "../../../../utils/DateManipulation.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getAllTimesheetGroupsEmployees = async (
  id: number,
  timesheetGroupId: number,
  pageSize: number = PAGINATION_PAGE_SIZE,
  pageOffset?: number,
  searchFilter?: string,
  periodStart?: Date,
  periodEnd?: Date,
): Promise<QueryModelWithPagination<TimesheetGroupProjectEmployee>> => {
  const params = new URLSearchParams();
  pageOffset && params.append("pageOffset", `${pageOffset}`);
  pageSize && params.append("pageSize", `${pageSize}`);
  searchFilter && params.append("search", searchFilter);
  periodStart && params.append("periodStart", `${convertDateToMilSecs(periodStart)}`);
  periodEnd && params.append("periodEnd", `${convertDateToMilSecs(periodEnd)}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/employees`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);
  const responseData = response.data as QueryModelWithCountAndData<TimesheetGroupProjectEmployee>;
  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;
  return { data: responseData.data, nextPage: nextPage, totalCount: responseData.count };
};
