import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import MmrCreateFormStyles from "./MmrCreateFormStyles.module.css";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Menu, MenuItem, Switch } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { QueryNames, SubQueryNames } from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { useProjectContext } from "../../../../../ProjectsUtils.ts";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectsUtils } from "../../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { useEpcmApiProjectManpowerCodes } from "../../../../../../../apicalls/projects/projectmanpowercodes/useEpcmApiProjectManpowerCodes.ts";
import { FrontendManpowerPositionDetailedType } from "../../../../../../../types/projects/ManpowerPositionTypes.ts";
import SelectSubpositionMenu from "./selectsubpositionmenu/SelectSubpositionMenu.tsx";
import EpcmDatePickerMui from "../../../../../../../ui/epcmdatepickermui/EpcmDatepickerMui.tsx";
import { UiComponentGeneralEnum } from "../../../../../../../types/UiComponentGeneralType.ts";
import { MuiDateFormatEnum } from "../../../../../../../utils/DateManipulation.ts";
import FormEmployeeInfoDetailedStyles from "../../../../../../employees/popups/employeeprofilepopup/inputelements/formemployeeinfodetailed/FormEmployeeInfoDetailed.module.css";
import arrowSelectBox from "../../../../../../../assets/images/arrow-down-dark-blue-fill.svg";
import { JustificationForRequestOptionsArray } from "../../../../../../../types/settings/GlobalConfigurationTypes.ts";
import { useEpcmApiProjectEmployee } from "../../../../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import ConfigurationSelect from "../../../../../project/configuration/popups/configurationmanpowercodespopup/configurationselect/ConfigurationSelect.tsx";
import { getAllProjectDisciplines } from "../../../../../../../apicalls/projects/projectsutils/getAllProjectDisciplines.ts";
import { FrontendProjectDisciplineType } from "../../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";

const DEFAULT_PLACEHOLDER = "Autofilled from Manpower Code";

interface MmrCreateFormProps {
  selectedCode: number | null;
  projectId: number;
  projectEmployeeId: number;
  mmrId: number | null;
  selectedSubposition: number | undefined;
  setSelectedSubposition: Dispatch<SetStateAction<number | undefined>>;
  typeOfMobilization: string | null;
  setTypeOfMobilization: Dispatch<SetStateAction<string | null>>;
  anticipatedMobilizationDate: Date | null;
  setAnticipatedMobilizationDate: Dispatch<SetStateAction<Date | null>>;
  actualMobilizationDate: Date | null;
  setActualMobilizationDate: Dispatch<SetStateAction<Date | null>>;
  anticipatedDemobilizationDate: Date | null;
  setAnticipatedDemobilizationDate: Dispatch<SetStateAction<Date | null>>;
  actualDemobilizationDate: Date | null;
  setActualDemobilizationDate: Dispatch<SetStateAction<Date | null>>;
  keyPersonnel: boolean;
  setKeyPersonnel: Dispatch<SetStateAction<boolean>>;
  subcontractor: string | null;
  setSubcontractor: Dispatch<SetStateAction<string | null>>;
  justificationForRequest: string | null;
  setJustificationForRequest: Dispatch<SetStateAction<string | null>>;
  clientInterviewDate: Date | null;
  setClientInterviewDate: Dispatch<SetStateAction<Date | null>>;
  approvalRecord: string | null;
  setApprovalRecord: Dispatch<SetStateAction<string | null>>;
  disciplineId: number | null;
  setDisciplineId: Dispatch<SetStateAction<number | null>>;
  isEditMode: boolean;
  isCloned: boolean;
}

export const MmrCreateForm: FC<MmrCreateFormProps> = ({
  selectedCode,
  projectId,
  projectEmployeeId,
  mmrId,
  selectedSubposition,
  setSelectedSubposition,
  typeOfMobilization,
  setTypeOfMobilization,
  anticipatedMobilizationDate,
  setAnticipatedMobilizationDate,
  actualMobilizationDate,
  setActualMobilizationDate,
  anticipatedDemobilizationDate,
  setAnticipatedDemobilizationDate,
  actualDemobilizationDate,
  setActualDemobilizationDate,
  keyPersonnel,
  setKeyPersonnel,
  subcontractor,
  setSubcontractor,
  justificationForRequest,
  setJustificationForRequest,
  clientInterviewDate,
  setClientInterviewDate,
  approvalRecord,
  setApprovalRecord,
  disciplineId,
  setDisciplineId,
  isEditMode,
  isCloned,
}) => {
  const [subpositionAnchorEl, setSubpositionAnchorEl] = useState<null | HTMLElement>(null);

  const queryClient = useQueryClient();
  const { currentProject } = useProjectContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getProjectPosition, getProjectLocation, getProjectTrade, getProjectCategory, getProjectSubpositions, getProjectDiscipline } =
    useEpcmApiProjectsUtils();
  const [tempDisciplineItem, setTempDisciplineItem] = useState<FrontendProjectDisciplineType | undefined>(undefined);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(null);
  const isDropdownMenuOpen = Boolean(dropdownAnchorEl);
  const onDropdownMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };
  const onDropdownMenuClose = () => {
    setDropdownAnchorEl(null);
  };
  //Validation Logic for the form

  const handleInputChange = <T extends number | string | undefined>(setter: Dispatch<SetStateAction<T>>) => {
    return (newValue: T | ((prevState: T) => T)) => {
      setter(newValue);
    };
  };

  const { getManpowerPositionCode } = useEpcmApiProjectManpowerCodes();
  const { getProjectEmployeeMmrDateLimits } = useEpcmApiProjectEmployee();

  const getMmrDateLimits = useQuery({
    queryKey: [QueryNames.ProjectMmrDateLimits, projectId, projectEmployeeId, mmrId],
    queryFn: () => getProjectEmployeeMmrDateLimits(projectId, projectEmployeeId, mmrId ?? 0),
    enabled: isAuthorized,
  });

  const mmrDateLimits = useMemo(() => {
    return getMmrDateLimits.data;
  }, [getMmrDateLimits.data]);

  const manpowerCodeItemDataQuery = useQuery({
    queryKey: [QueryNames.ProjectPositionCode, currentProject.id, selectedCode],
    queryFn: () => getManpowerPositionCode(currentProject.id, selectedCode as number, DetailLevel.DETAILED),
    enabled: isAuthorized && selectedCode != null,
    select: (data) => data as FrontendManpowerPositionDetailedType,
  });

  const manpowerCodeItemData = useMemo(() => {
    return manpowerCodeItemDataQuery.data ?? null;
  }, [manpowerCodeItemDataQuery.data]);

  const manpowerCodesPositionQuery = useQuery({
    queryKey: [QueryNames.ProjectPositions, currentProject.id, selectedCode],
    queryFn: () => getProjectPosition(currentProject.id, manpowerCodeItemData?.positionId as number, DetailLevel.LIMITED),
    enabled: isAuthorized && manpowerCodeItemData?.positionId !== undefined && selectedCode != null,
  });

  const manpowerCodesPositionQueryData = useMemo(() => {
    return manpowerCodesPositionQuery.data?.description ?? "";
  }, [manpowerCodesPositionQuery.data]);

  const manpowerCodesSubpositionQuery = useQuery({
    queryKey: [QueryNames.ProjectSubpositions, currentProject.id, selectedCode],
    queryFn: () => getProjectSubpositions(currentProject.id, manpowerCodeItemData?.positionId as number),
    enabled: isAuthorized && manpowerCodeItemData?.positionId != null && selectedCode != null,
  });
  const manpowerCodesSubpositionQueryData = useMemo(() => {
    return manpowerCodesSubpositionQuery.data ?? [];
  }, [manpowerCodesSubpositionQuery.data]);

  const manpowerCodesTradeQuery = useQuery({
    queryKey: [QueryNames.ProjectTrades, currentProject.id, selectedCode],
    queryFn: () => getProjectTrade(currentProject.id, manpowerCodeItemData?.tradeId as number, DetailLevel.LIMITED),
    enabled: isAuthorized && manpowerCodeItemData?.tradeId !== undefined && selectedCode != null,
  });

  const manpowerCodesTradeQueryData = useMemo(() => {
    return manpowerCodesTradeQuery.data?.description ?? "";
  }, [manpowerCodesTradeQuery.data]);

  const manpowerCodesCategoryQuery = useQuery({
    queryKey: [QueryNames.ProjectCategories, currentProject.id, selectedCode],
    queryFn: () => getProjectCategory(currentProject.id, manpowerCodeItemData?.categoryId as number, DetailLevel.LIMITED),
    enabled: isAuthorized && manpowerCodeItemData?.categoryId !== undefined && selectedCode != null,
  });
  const manpowerCodesCategoryQueryData = useMemo(() => {
    return manpowerCodesCategoryQuery.data?.description ?? "";
  }, [manpowerCodesCategoryQuery.data]);

  const manpowerCodesLocationQuery = useQuery({
    queryKey: [QueryNames.ProjectLocations, currentProject.id, selectedCode],
    queryFn: () => getProjectLocation(currentProject.id, manpowerCodeItemData?.locationId as number, DetailLevel.LIMITED),
    enabled: isAuthorized && manpowerCodeItemData?.locationId !== undefined && selectedCode != null,
  });

  const manpowerCodesLocationQueryData = useMemo(() => {
    return manpowerCodesLocationQuery.data?.description ?? "";
  }, [manpowerCodesLocationQuery.data]);

  const mmrDisciplineDataQuery = useQuery({
    queryKey: [QueryNames.ProjectDisciplines, currentProject.id, disciplineId],
    queryFn: () => getProjectDiscipline(currentProject.id, disciplineId as number, DetailLevel.LIMITED),
    enabled: isAuthorized && !!disciplineId,
  });

  useEffect(() => {
    if (mmrDisciplineDataQuery.data && tempDisciplineItem !== null) {
      setTempDisciplineItem(mmrDisciplineDataQuery.data);
    }
  }, [mmrDisciplineDataQuery.data, setTempDisciplineItem]);

  const onDisciplineItemChange = useCallback(
    (disciplineItem: FrontendProjectDisciplineType | undefined) => {
      setTempDisciplineItem(disciplineItem);
      if (disciplineItem) {
        setDisciplineId(disciplineItem.id);
      }
    },
    [setDisciplineId, setTempDisciplineItem],
  );

  useEffect(() => {
    return () => {
      selectedCode &&
        queryClient.cancelQueries({ queryKey: [QueryNames.ProjectPositionCode, currentProject.id, selectedCode] }).then(() => {
          console.log(`Query ${QueryNames.ProjectPositionCode} cancelled`);
        });
    };
  }, [queryClient, currentProject.id, selectedCode]);
  useEffect(() => {
    return () => {
      manpowerCodeItemData &&
        queryClient.cancelQueries({ queryKey: [QueryNames.ProjectPositions, currentProject.id, selectedCode] }).then(() => {
          console.log(`Query ${QueryNames.ProjectPositions} cancelled`);
        });
    };
  }, [manpowerCodeItemData, queryClient, currentProject.id, selectedCode]);
  useEffect(() => {
    return () => {
      manpowerCodesPositionQueryData &&
        queryClient.cancelQueries({ queryKey: [QueryNames.ProjectSubpositions, currentProject.id, selectedCode] }).then(() => {
          console.log(`Query ${QueryNames.ProjectSubpositions} cancelled`);
        });
    };
  }, [currentProject.id, manpowerCodesPositionQueryData, queryClient, selectedCode]);
  useEffect(() => {
    return () => {
      manpowerCodeItemData &&
        queryClient.cancelQueries({ queryKey: [QueryNames.ProjectTrades, currentProject.id, selectedCode] }).then(() => {
          console.log(`Query ${QueryNames.ProjectTrades} cancelled`);
        });
    };
  }, [manpowerCodeItemData, queryClient, currentProject.id, selectedCode]);
  useEffect(() => {
    return () => {
      manpowerCodeItemData &&
        queryClient.cancelQueries({ queryKey: [QueryNames.ProjectPositionCode, currentProject.id, selectedCode] }).then(() => {
          console.log(`Query ${QueryNames.ProjectPositionCode} cancelled`);
        });
    };
  }, [manpowerCodeItemData, queryClient, currentProject.id, selectedCode]);

  useEffect(() => {
    return () => {
      manpowerCodeItemData &&
        queryClient.cancelQueries({ queryKey: [QueryNames.ProjectLocations, currentProject.id, selectedCode] }).then(() => {
          console.log(`Query ${QueryNames.ProjectLocations} cancelled`);
        });
    };
  }, [manpowerCodeItemData, queryClient, currentProject.id, selectedCode]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectMmrDateLimits, projectId, projectEmployeeId, mmrId] });
    };
  }, [queryClient, projectEmployeeId, mmrId]);

  useEffect(() => {
    return () => {
      mmrDisciplineDataQuery.data && void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectDisciplines, currentProject.id, disciplineId] });
    };
  }, [queryClient, currentProject.id, disciplineId, mmrDisciplineDataQuery.data]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap2, GlobalStyles.flex1, MmrCreateFormStyles.testContainer)}>
      <div className={classNames(MmrCreateFormStyles.container, MmrCreateFormStyles.inputsBox)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15, MmrCreateFormStyles.scrollableArea)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <label className={classNames(MmrCreateFormStyles.label)}>Position</label>
            {selectedCode ? (
              <div className={classNames(MmrCreateFormStyles.displayDiv)}>{manpowerCodesPositionQueryData}</div>
            ) : (
              <div className={classNames(MmrCreateFormStyles.displayPlaceholderDiv)}>{DEFAULT_PLACEHOLDER}</div>
            )}
          </div>
          <SelectSubpositionMenu
            label={"Subposition"}
            optionsMap={manpowerCodesSubpositionQueryData}
            currentValue={selectedSubposition}
            onChangeHandler={handleInputChange(setSelectedSubposition)}
            inputId={"subpositionInput"}
            isMpCodeEmpty={!selectedCode}
            anchorEl={subpositionAnchorEl}
            setAnchorEl={setSubpositionAnchorEl}
            disabled={isEditMode}
            isOptionsLoading={manpowerCodesSubpositionQuery.isLoading || !selectedCode}
          />
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <label className={classNames(MmrCreateFormStyles.label)}>Trade</label>
            {selectedCode && manpowerCodesTradeQueryData ? (
              <div className={classNames(MmrCreateFormStyles.displayDiv)}>{manpowerCodesTradeQueryData}</div>
            ) : (
              <div className={classNames(MmrCreateFormStyles.displayPlaceholderDiv)}>{DEFAULT_PLACEHOLDER}</div>
            )}
          </div>

          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <label className={classNames(MmrCreateFormStyles.label)}>Category</label>
            {selectedCode && manpowerCodesCategoryQueryData ? (
              <div className={classNames(MmrCreateFormStyles.displayDiv)}>{manpowerCodesCategoryQueryData}</div>
            ) : (
              <div className={classNames(MmrCreateFormStyles.displayPlaceholderDiv)}>{DEFAULT_PLACEHOLDER}</div>
            )}
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <label className={classNames(MmrCreateFormStyles.label)}>Rate</label>
            {selectedCode && manpowerCodeItemData ? (
              <div className={classNames(MmrCreateFormStyles.displayDiv)}>{`${manpowerCodeItemData.rate}$/hour`}</div>
            ) : (
              <div className={classNames(MmrCreateFormStyles.displayPlaceholderDiv)}>{DEFAULT_PLACEHOLDER}</div>
            )}
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <label className={classNames(MmrCreateFormStyles.label)}>Location</label>
            {selectedCode && manpowerCodesLocationQueryData ? (
              <div className={classNames(MmrCreateFormStyles.displayDiv)}>{manpowerCodesLocationQueryData}</div>
            ) : (
              <div className={classNames(MmrCreateFormStyles.displayPlaceholderDiv)}>{DEFAULT_PLACEHOLDER}</div>
            )}
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <label className={classNames(MmrCreateFormStyles.label)}>Subcontractor</label>
            <input
              value={subcontractor ?? ""}
              disabled={false}
              onChange={(event) => setSubcontractor(event.target.value)}
              placeholder={"Type here..."}
              className={classNames(GlobalStyles.inputContainer)}
            />
          </div>
          <ConfigurationSelect
            label={"Discipline"}
            queryName={`${QueryNames.ProjectDisciplines},${SubQueryNames.inMMR}`}
            getData={getAllProjectDisciplines}
            selectedItem={tempDisciplineItem ?? null}
            setSelectedItem={onDisciplineItemChange}
            projectId={projectId}
            diverseStyle={true}
          />
        </div>
      </div>
      <div className={classNames(MmrCreateFormStyles.container, MmrCreateFormStyles.inputsBox, MmrCreateFormStyles.largerInputsBox)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15, MmrCreateFormStyles.scrollableArea)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
            <label htmlFor="typeMobilizationInput" className={classNames(MmrCreateFormStyles.label)}>
              Type of Mobilization (Required)
            </label>
            <input
              id="typeMobilizationInput"
              value={typeOfMobilization ?? ""}
              disabled={isEditMode}
              onChange={(event) => setTypeOfMobilization(event.target.value)}
              placeholder={"Type here..."}
              className={isEditMode ? classNames(MmrCreateFormStyles.displayDivSmallPadding) : classNames(GlobalStyles.inputContainer)}
            />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
            <EpcmDatePickerMui
              label="Anticipated Mobilization Date (Required)"
              date={anticipatedMobilizationDate}
              minDate={mmrDateLimits?.calendarStartDate}
              maxDate={isCloned ? (mmrDateLimits?.latestMobilizationDate ?? mmrDateLimits?.calendarEndDate) : mmrDateLimits?.calendarEndDate}
              setDate={setAnticipatedMobilizationDate}
              theme={UiComponentGeneralEnum.FOURTH}
              placeholder={"Select a date"}
              formatType={MuiDateFormatEnum.DD_MMM_COMMA_YYYY}
            />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
            <EpcmDatePickerMui
              label="Actual Mobilization Date"
              date={actualMobilizationDate}
              setDate={setActualMobilizationDate}
              minDate={mmrDateLimits?.calendarStartDate}
              maxDate={isCloned ? (mmrDateLimits?.latestMobilizationDate ?? mmrDateLimits?.calendarEndDate) : mmrDateLimits?.calendarEndDate}
              showClearButton={!!actualMobilizationDate}
              theme={UiComponentGeneralEnum.FOURTH}
              placeholder={"Select a date"}
              formatType={MuiDateFormatEnum.DD_MMM_COMMA_YYYY}
            />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
            <EpcmDatePickerMui
              label="Anticipated Demobilization Date (Required)"
              minDate={isCloned ? (mmrDateLimits?.earliestDemobilizationDate ?? mmrDateLimits?.calendarStartDate) : mmrDateLimits?.calendarStartDate}
              maxDate={mmrDateLimits?.calendarEndDate}
              date={anticipatedDemobilizationDate}
              setDate={setAnticipatedDemobilizationDate}
              theme={UiComponentGeneralEnum.FOURTH}
              placeholder={"Select a date"}
              formatType={MuiDateFormatEnum.DD_MMM_COMMA_YYYY}
            />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
            <EpcmDatePickerMui
              label="Actual Demobilization Date"
              date={actualDemobilizationDate}
              minDate={isCloned ? (mmrDateLimits?.earliestDemobilizationDate ?? mmrDateLimits?.calendarStartDate) : mmrDateLimits?.calendarStartDate}
              maxDate={mmrDateLimits?.calendarEndDate}
              setDate={setActualDemobilizationDate}
              theme={UiComponentGeneralEnum.FOURTH}
              showClearButton={!!actualDemobilizationDate}
              placeholder={"Select a date"}
              formatType={MuiDateFormatEnum.DD_MMM_COMMA_YYYY}
            />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
            <label className={classNames(MmrCreateFormStyles.label)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
                <div>Key Personnel</div>
              </div>
            </label>
            <Switch disabled={isEditMode} checked={keyPersonnel} onChange={(e) => setKeyPersonnel(e.target.checked)} />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
            <div>{"Justification for request"}</div>
            <div
              className={classNames(
                MmrCreateFormStyles.label,
                MmrCreateFormStyles.justificationRequestContainer,
                GlobalStyles.flex,
                GlobalStyles.gap,
                GlobalStyles.flex1,
              )}
              onClick={onDropdownMenuOpen}
            >
              {justificationForRequest ? (
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>{justificationForRequest}</div>
              ) : (
                <div className={classNames(GlobalStyles.flex1, GlobalStyles.flex, MmrCreateFormStyles.inputPlaceholder)}>
                  {"Select justification"}
                </div>
              )}
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(FormEmployeeInfoDetailedStyles.selectBoxArrowIconImg)} src={arrowSelectBox} alt="arrow" />
              </div>
            </div>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={dropdownAnchorEl}
              open={isDropdownMenuOpen}
              onClose={onDropdownMenuClose}
              PaperProps={{
                style: {
                  maxHeight: "15em",
                  width: "20em",
                },
              }}
              transformOrigin={{ horizontal: "center", vertical: "top" }}
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
            >
              {JustificationForRequestOptionsArray.map((typeItem) => (
                <MenuItem
                  key={typeItem}
                  disabled={isEditMode}
                  selected={typeItem === justificationForRequest}
                  onClick={() => {
                    setJustificationForRequest(typeItem);
                    onDropdownMenuClose();
                  }}
                >
                  {typeItem}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
            <EpcmDatePickerMui
              label="Client Interview Date"
              date={clientInterviewDate}
              setDate={setClientInterviewDate}
              theme={UiComponentGeneralEnum.FOURTH}
              placeholder={"Select a date"}
              formatType={MuiDateFormatEnum.DD_MMM_COMMA_YYYY}
              disabled={isEditMode}
              showClearButton={!!clientInterviewDate}
            />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
            <label htmlFor="approvalRecordTextarea" className={classNames(MmrCreateFormStyles.label)}>
              Approval Record
            </label>
            <textarea
              id="approvalRecordTextarea"
              value={approvalRecord ?? ""}
              disabled={isEditMode}
              onChange={(event) => setApprovalRecord(event.target.value)}
              rows={3}
              className={isEditMode ? classNames(MmrCreateFormStyles.textAreaDisabled) : classNames(classNames(MmrCreateFormStyles.textArea))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MmrCreateForm;
