import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../types/apicallstypes/queryCommons.ts";
import { useEpcmApiUsers } from "../apicalls/users/useEpcmApiUsers.ts";
import { useImpersonationStore } from "../store/use-impersonation-store.ts";
import { FC, ReactNode, useEffect, useMemo } from "react";
import { useGlobalRoleStore } from "../store/use-global-role-store.ts";
import classNames from "classnames";
import GlobalStyles from "../assets/css/GlobalStyles.module.css";
import { Skeleton } from "@mui/material";

interface GlobalRolesAuthenticatorProps {
  children: ReactNode;
}

const GlobalRolesAuthenticator: FC<GlobalRolesAuthenticatorProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const { getUsersRoles } = useEpcmApiUsers();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const globalRolesState = useGlobalRoleStore((state) => state);

  const globalRolesQuery = useQuery({
    queryKey: [QueryNames.GlobalRoles],
    queryFn: () => getUsersRoles(),
    enabled: isAuthorized,
  });

  const globalRolesData = useMemo(() => {
    return globalRolesQuery.data;
  }, [globalRolesQuery.data]);

  const isGlobalRolesQueryLoading = useMemo(() => {
    return globalRolesQuery.isLoading;
  }, [globalRolesQuery.isLoading]);

  const rolesSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.overflowHiddenFullHeight)}>
      <Skeleton variant="rectangular" height={180} width={"100%"} />
      <Skeleton variant="rectangular" height={180} width={"100%"} />
      <Skeleton variant="rectangular" height={180} width={"100%"} />
    </div>
  );

  useEffect(() => {
    if (globalRolesData && JSON.stringify(globalRolesData) !== JSON.stringify(globalRolesState.globalRoles)) {
      globalRolesState.setGlobalRoles(globalRolesData.map((role) => role));
    }
  }, [globalRolesData, globalRolesState]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.GlobalRoles] }).then(() => {
        console.log(`Query ${QueryNames.GlobalRoles} canceled`);
      });
    };
  }, [queryClient]);

  return <>{isGlobalRolesQueryLoading ? rolesSkeletonLoader : children}</>;
};

export default GlobalRolesAuthenticator;
