import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../config.ts";
import { QueryModelWithPagination } from "../../types/apicallstypes/queryCommons.ts";
import { AxiosRequestConfig } from "axios";
import { GlobalConfigurationEndpointType, ResponseType } from "../../types/apicallstypes/ConfigurationsTypes.ts";

const URL = `${EPCM_API_URLS.configurations}`;

export const getAllGlobalEntity = async <T extends GlobalConfigurationEndpointType>(
  endpoint: T,
  pageOffset?: number,
  searchFilter?: string,
  pageSize: number = PAGINATION_PAGE_SIZE,
): Promise<QueryModelWithPagination<ResponseType<T>>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);

  const config: AxiosRequestConfig = {
    url: `${URL}/${endpoint}`,
    method: "GET",
    params,
  };
  const response = await epcmAxiosInstance(config);
  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);
  const responseData = response.data as QueryModelWithPagination<ResponseType<T>>;
  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;

  return {
    data: responseData.data,
    nextPage: nextPage,
    totalCount: responseData.totalCount,
  };
};
