import { PopupHandlerReturnType, PopupHandlerTypeAsMap } from "../../types/PopupHandlerTypes.ts";
import { useMemo, useState } from "react";
import { onClosePopup, onOpenPopup } from "../../utils/PopupHandlerUtils.ts";

export enum SettingsPagePopups {
  addGlobalCategory = "create-global-category",
  addGlobalTrade = "create-global-trade",
  addGlobalDiscipline = "create-global-discipline",
  addGlobalLocation = "create-global-location",
  addGlobalCtr = "create-global-ctr",
  addGlobalPosition = "create-global-position",
  addGlobalSubposition = "create-global-subposition",
  addUserGlobalRole = "add-user-global-role",
  updateGlobalTrade = "update-global-trade",
  updateGlobalCategory = "update-global-category",
  updateGlobalDiscipline = "update-global-discipline",
  updateGlobalLocation = "update-global-location",
  updateGlobalCtr = "update-global-ctr",
  updateGlobalPosition = "update-global-position",
  updateGlobalSubposition = "update-global-subposition",
}

export enum SettingsPagePopupsHeaders {
  addGlobalCategory = "Create Global Category",
  addGlobalTrade = "Create Global Trade",
  addGlobalDiscipline = "Create Global Discipline",
  addGlobalLocation = "Create Global Location",
  addGlobalCtr = "Create Global CTR",
  addGlobalPosition = "Create Global Position",
  addGlobalSubposition = "Create Global Subposition",
  addGlobalUserRole = "Add Role to Global User",
  updateGlobalTrade = "Update Global Trade",
  updateGlobalCategory = "Update Global Category",
  updateGlobalDiscipline = "Update Global Discipline",
  updateGlobalLocation = "Update Global Location",
  updateGlobalCtr = "Update Global CTR",
  updateGlobalPosition = "Update Global Position",
  updateGlobalSubposition = "Update Global Subposition",
}

const popupHeadersMap: Map<SettingsPagePopups, SettingsPagePopupsHeaders> = new Map([
  [SettingsPagePopups.addGlobalCategory, SettingsPagePopupsHeaders.addGlobalCategory],
  [SettingsPagePopups.addGlobalTrade, SettingsPagePopupsHeaders.addGlobalTrade],
  [SettingsPagePopups.addGlobalDiscipline, SettingsPagePopupsHeaders.addGlobalDiscipline],
  [SettingsPagePopups.addGlobalLocation, SettingsPagePopupsHeaders.addGlobalLocation],
  [SettingsPagePopups.addGlobalCtr, SettingsPagePopupsHeaders.addGlobalCtr],
  [SettingsPagePopups.addGlobalPosition, SettingsPagePopupsHeaders.addGlobalPosition],
  [SettingsPagePopups.addGlobalSubposition, SettingsPagePopupsHeaders.addGlobalSubposition],
  [SettingsPagePopups.updateGlobalTrade, SettingsPagePopupsHeaders.updateGlobalTrade],
  [SettingsPagePopups.updateGlobalCategory, SettingsPagePopupsHeaders.updateGlobalCategory],
  [SettingsPagePopups.updateGlobalDiscipline, SettingsPagePopupsHeaders.updateGlobalDiscipline],
  [SettingsPagePopups.updateGlobalLocation, SettingsPagePopupsHeaders.updateGlobalLocation],
  [SettingsPagePopups.updateGlobalCtr, SettingsPagePopupsHeaders.updateGlobalCtr],
  [SettingsPagePopups.updateGlobalPosition, SettingsPagePopupsHeaders.updateGlobalPosition],
  [SettingsPagePopups.updateGlobalSubposition, SettingsPagePopupsHeaders.updateGlobalSubposition],
  [SettingsPagePopups.addUserGlobalRole, SettingsPagePopupsHeaders.addGlobalUserRole],
]);

interface SettingsPagePopupsReturnType extends PopupHandlerReturnType<SettingsPagePopups> {
  popupHeaders: Map<SettingsPagePopups, SettingsPagePopupsHeaders>;
}

export const useSettingsPopups = (): SettingsPagePopupsReturnType => {
  const [isCreateGlobalCategoryOpen, setIsCreateGlobalCategoryOpen] = useState(false);
  const [isCreateGlobalTradeOpen, setIsCreateGlobalTradeOpen] = useState(false);
  const [isCreateGlobalDisciplineOpen, setIsCreateGlobalDisciplineOpen] = useState(false);
  const [isCreateGlobalLocationOpen, setIsCreateGlobalLocationOpen] = useState(false);
  const [isCreateGlobalCtrOpen, setIsCreateGlobalCtrOpen] = useState(false);
  const [isCreateGlobalPositionOpen, setIsCreateGlobalPositionOpen] = useState(false);
  const [isCreateGlobalSubpositionOpen, setIsCreateGlobalSubpositionOpen] = useState(false);
  const [isUpdateGlobalTradeOpen, setIsUpdateGlobalTradeOpen] = useState(false);
  const [isUpdateGlobalCategoryOpen, setIsUpdateGlobalCategoryOpen] = useState(false);
  const [isUpdateGlobalDisciplineOpen, setIsUpdateGlobalDisciplineOpen] = useState(false);
  const [isUpdateGlobalLocationOpen, setIsUpdateGlobalLocationOpen] = useState(false);
  const [isUpdateGlobalCtrOpen, setIsUpdateGlobalCtrOpen] = useState(false);
  const [isUpdateGlobalPositionOpen, setIsUpdateGlobalPositionOpen] = useState(false);
  const [isUpdateGlobalSubpositionOpen, setIsUpdateGlobalSubpositionOpen] = useState(false);
  const [isAddGlobalUserRoleOpen, setIsAddGlobalUserRoleOpen] = useState(false);

  const popupHandlerAsMap: PopupHandlerTypeAsMap<SettingsPagePopups> = useMemo(() => {
    const initialValue = new PopupHandlerTypeAsMap<SettingsPagePopups>();

    initialValue.set(SettingsPagePopups.addGlobalCategory, {
      opener: () => {
        setIsCreateGlobalCategoryOpen(true);
      },
      closer: () => {
        setIsCreateGlobalCategoryOpen(false);
      },
      isOpen: isCreateGlobalCategoryOpen,
    });

    initialValue.set(SettingsPagePopups.addGlobalTrade, {
      opener: () => {
        setIsCreateGlobalTradeOpen(true);
      },
      closer: () => {
        setIsCreateGlobalTradeOpen(false);
      },
      isOpen: isCreateGlobalTradeOpen,
    });

    initialValue.set(SettingsPagePopups.addGlobalDiscipline, {
      opener: () => {
        setIsCreateGlobalDisciplineOpen(true);
      },
      closer: () => {
        setIsCreateGlobalDisciplineOpen(false);
      },
      isOpen: isCreateGlobalDisciplineOpen,
    });

    initialValue.set(SettingsPagePopups.addGlobalLocation, {
      opener: () => {
        setIsCreateGlobalLocationOpen(true);
      },
      closer: () => {
        setIsCreateGlobalLocationOpen(false);
      },
      isOpen: isCreateGlobalLocationOpen,
    });

    initialValue.set(SettingsPagePopups.addGlobalCtr, {
      opener: () => {
        setIsCreateGlobalCtrOpen(true);
      },
      closer: () => {
        setIsCreateGlobalCtrOpen(false);
      },
      isOpen: isCreateGlobalCtrOpen,
    });

    initialValue.set(SettingsPagePopups.addGlobalPosition, {
      opener: () => {
        setIsCreateGlobalPositionOpen(true);
      },
      closer: () => {
        setIsCreateGlobalPositionOpen(false);
      },
      isOpen: isCreateGlobalPositionOpen,
    });

    initialValue.set(SettingsPagePopups.addGlobalSubposition, {
      opener: () => {
        setIsCreateGlobalSubpositionOpen(true);
      },
      closer: () => {
        setIsCreateGlobalSubpositionOpen(false);
      },
      isOpen: isCreateGlobalSubpositionOpen,
    });

    initialValue.set(SettingsPagePopups.updateGlobalTrade, {
      opener: () => {
        setIsUpdateGlobalTradeOpen(true);
      },
      closer: () => {
        setIsUpdateGlobalTradeOpen(false);
      },
      isOpen: isUpdateGlobalTradeOpen,
    });
    initialValue.set(SettingsPagePopups.updateGlobalCategory, {
      opener: () => {
        setIsUpdateGlobalCategoryOpen(true);
      },
      closer: () => {
        setIsUpdateGlobalCategoryOpen(false);
      },
      isOpen: isUpdateGlobalCategoryOpen,
    });
    initialValue.set(SettingsPagePopups.updateGlobalDiscipline, {
      opener: () => {
        setIsUpdateGlobalDisciplineOpen(true);
      },
      closer: () => {
        setIsUpdateGlobalDisciplineOpen(false);
      },
      isOpen: isUpdateGlobalDisciplineOpen,
    });
    initialValue.set(SettingsPagePopups.updateGlobalLocation, {
      opener: () => {
        setIsUpdateGlobalLocationOpen(true);
      },
      closer: () => {
        setIsUpdateGlobalLocationOpen(false);
      },
      isOpen: isUpdateGlobalLocationOpen,
    });
    initialValue.set(SettingsPagePopups.updateGlobalCtr, {
      opener: () => {
        setIsUpdateGlobalCtrOpen(true);
      },
      closer: () => {
        setIsUpdateGlobalCtrOpen(false);
      },
      isOpen: isUpdateGlobalCtrOpen,
    });
    initialValue.set(SettingsPagePopups.updateGlobalPosition, {
      opener: () => {
        setIsUpdateGlobalPositionOpen(true);
      },
      closer: () => {
        setIsUpdateGlobalPositionOpen(false);
      },
      isOpen: isUpdateGlobalPositionOpen,
    });
    initialValue.set(SettingsPagePopups.updateGlobalSubposition, {
      opener: () => {
        setIsUpdateGlobalSubpositionOpen(true);
      },
      closer: () => {
        setIsUpdateGlobalSubpositionOpen(false);
      },
      isOpen: isUpdateGlobalSubpositionOpen,
    });
    initialValue.set(SettingsPagePopups.addUserGlobalRole, {
      opener: () => {
        setIsAddGlobalUserRoleOpen(true);
      },
      closer: () => {
        setIsAddGlobalUserRoleOpen(false);
      },
      isOpen: isAddGlobalUserRoleOpen,
    });

    return initialValue;
  }, [
    isCreateGlobalCategoryOpen,
    isCreateGlobalTradeOpen,
    isCreateGlobalDisciplineOpen,
    isCreateGlobalLocationOpen,
    isCreateGlobalCtrOpen,
    isCreateGlobalPositionOpen,
    isCreateGlobalSubpositionOpen,
    isAddGlobalUserRoleOpen,
    isUpdateGlobalTradeOpen,
    isUpdateGlobalCategoryOpen,
    isUpdateGlobalDisciplineOpen,
    isUpdateGlobalLocationOpen,
    isUpdateGlobalCtrOpen,
    isUpdateGlobalPositionOpen,
    isUpdateGlobalSubpositionOpen,
  ]);

  return {
    popupHandler: popupHandlerAsMap,
    onOpenPopup,
    onClosePopup,
    popupHeaders: popupHeadersMap,
  } satisfies SettingsPagePopupsReturnType;
};
