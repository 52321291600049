import { ApiResponseProjectCalendarInfo, CalendarDay } from "epcm-common/dist/Types/CalendarTypes.ts";
import { FrontendCalendarManagement, FrontendCalendarManagementDays, FrontendCalendarManagementMonths } from "../CalendarManagementTypes.ts";
import { CalendarMonthsBasic } from "epcm-common/dist/Types/CalendarMonthsTypes";
import { convertDayTypeOptionToFrontend } from "./CalendarApiConverter.ts";

export const convertCalendarMonthBasicData = (month: CalendarMonthsBasic): FrontendCalendarManagementMonths => {
  return {
    calendarMonths: [month],
  };
};

export const convertCalendarDayData = (day: CalendarDay): FrontendCalendarManagementDays => {
  return {
    calendarDays: [
      {
        id: day.id,
        dayName: day.dayName,
        dayNumber: day.dayNumber,
        monthName: day.monthName,
        locationCalendarDays: day.locationCalendarDays.map((locationCalendarDay) => {
          return {
            locationId: locationCalendarDay.locationId,
            optionType: convertDayTypeOptionToFrontend(locationCalendarDay.optionType),
            holidayTitle: locationCalendarDay.holidayTitle,
          };
        }),
      },
    ],
  };
};
export const convertCalendarManagementToFrontendCalendarManagement = (
  apiResponseCalendarManagement: ApiResponseProjectCalendarInfo,
): FrontendCalendarManagement => {
  const duration = apiResponseCalendarManagement.duration;
  const dateOfSignTimestamp = String(apiResponseCalendarManagement.dateOfSignTimestamp);
  const cutOffDay = apiResponseCalendarManagement.cutOffDay;
  const locations = apiResponseCalendarManagement.locations;

  return { locations, duration, dateOfSignTimestamp, cutOffDay };
};

export const convertCalendarManagementToFrontendCalendarManagementMonths = (
  calendarMonthsData: CalendarMonthsBasic[],
): FrontendCalendarManagementMonths[] => {
  return calendarMonthsData?.map((month) => convertCalendarMonthBasicData(month)) || [];
};

export const convertCalendarManagementToFrontendCalendarManagementDays = (calendarDaysData: CalendarDay[]): FrontendCalendarManagementDays[] => {
  return calendarDaysData?.map((day) => convertCalendarDayData(day)) || [];
};
