import { FC, useEffect, useMemo, useState } from "react";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import EmployeeListItemStyles from "./EmployeeListItem.module.css";
import arrowDownBlackIcon from "../../../../../assets/images/arrow-down-black.svg";
import envelopeIcon from "../../../../../assets/images/envelope-gray-fill.svg";
import wrenchIcon from "../../../../../assets/images/wrench-gray-fill.svg";
import flagIcon from "../../../../../assets/images/flag-gray-fill.svg";
import cakeIcon from "../../../../../assets/images/cake-gray-fill.svg";
import checkIcon from "../../../../../assets/images/tick-white.svg";
import avatarDefault from "../../../../../assets/images/profile-default-avatar-white.svg";
import arrowDownGrayIcon from "../../../../../assets/images/arrow-down-gray.svg";
import { calculateAge, formatDate } from "../../../../../utils/DateManipulation.ts";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Fade, Skeleton, Tooltip } from "@mui/material";
import { FrontendEmployeeDetailedType, FrontendEmployeeLimitedType } from "../../../../../types/apicallstypes/EmployeesApiTypes.ts";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEpcmApiEmployees } from "../../../../../apicalls/employees/useEpcmApiEmployees.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { displayNumberWithSpecificNumberOfDigits } from "../../../../../utils/NumberManipulation.ts";

interface EmployeeListItemProps {
  employeeItem: FrontendEmployeeLimitedType;
  isSelected: (employeeId: number) => boolean;
  onEmployeeToggle: (employeeId: number) => void;
}

export const EmployeeListItem: FC<EmployeeListItemProps> = ({ employeeItem, isSelected, onEmployeeToggle }) => {
  const [parent] = useAutoAnimate();
  const { getEmployee } = useEpcmApiEmployees();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const [isDetailsExpanded, setIsDetailsExpanded] = useState<boolean>(false);
  const [isQualificationsExpanded, setIsQualificationsExpanded] = useState<boolean>(false);
  const [isCertificationsExpanded, setIsCertificationsExpanded] = useState<boolean>(false);
  const [isExperienceExpanded, setIsExperienceExpanded] = useState<boolean>(false);

  const employeeQuery = useQuery({
    queryKey: [QueryNames.Employees, employeeItem.id],
    queryFn: () => getEmployee(employeeItem.id, DetailLevel.DETAILED),
    enabled: isAuthorized,
  });
  const employeeData = useMemo(() => {
    return employeeQuery.data ? (employeeQuery.data as FrontendEmployeeDetailedType) : undefined;
  }, [employeeQuery]);

  const isEmployeeSelected = useMemo(() => {
    return employeeData && isSelected(employeeData.id);
  }, [employeeData, isSelected]);

  const employeeItemSkeletonLoader = (
    <div className={classNames(EmployeeListItemStyles.employeeItemContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="circular" height={30} width={30} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
          <Skeleton variant="circular" height={50} width={50} />
          <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.gap05)}>
            <Skeleton variant="rounded" height={16} width={200} />
            <Skeleton variant="rounded" height={16} width={150} />
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="circular" height={20} width={20} />
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <Skeleton variant="rounded" height={0} width={40} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <Skeleton variant="rounded" height={0} width={10} />
      </div>
    </div>
  );

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Employees, employeeItem.id] })
        .then(() => console.log(`In select employees for project, in employee with id ${employeeItem.id}, ${QueryNames.Employees} query canceled`));
    };
  }, [queryClient, employeeItem]);

  return employeeData ? (
    <div
      key={employeeData.id}
      className={classNames(EmployeeListItemStyles.employeeItemContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}
      onClick={() => onEmployeeToggle(employeeData.id)}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames(GlobalStyles.flex1)} />
          <div
            className={classNames(
              EmployeeListItemStyles.checkIcon,
              isEmployeeSelected && EmployeeListItemStyles.checkIconSelected,
              GlobalStyles.centerVertical,
            )}
          >
            {isEmployeeSelected && <img src={checkIcon} alt="check" className={classNames(EmployeeListItemStyles.checkIconImg)} />}{" "}
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        {/*TODO: get avatar from backend*/}
        {/*<div className={classNames(!false && EmployeeListItemStyles.defaultAvatar, GlobalStyles.centerVertical)}>*/}
        <div className={classNames(EmployeeListItemStyles.defaultAvatar, GlobalStyles.centerVertical)}>
          <img
            // className={classNames(!false ? EmployeeListItemStyles.defaultAvatarImg : EmployeeListItemStyles.avatarImg)}
            className={classNames(EmployeeListItemStyles.defaultAvatarImg)}
            src={avatarDefault}
            alt="avatar"
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames(EmployeeListItemStyles.nameText)}>{employeeData.name}</div>
          <div className={classNames(EmployeeListItemStyles.mainText)}>{`ID: ${employeeData.code}`}</div>
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div
          className={classNames(GlobalStyles.centerVertical)}
          onClick={(event) => {
            event.stopPropagation();
            setIsDetailsExpanded((currentState) => {
              if (currentState) {
                setIsQualificationsExpanded(false);
                setIsCertificationsExpanded(false);
                setIsExperienceExpanded(false);
              }
              return !currentState;
            });
          }}
        >
          <img
            className={classNames(EmployeeListItemStyles.arrowBlackImg, isDetailsExpanded && GlobalStyles.imgReverted)}
            src={arrowDownBlackIcon}
            alt="arrow"
          />
        </div>
      </div>
      <div
        ref={parent}
        className={classNames(EmployeeListItemStyles.employeeDetailsContainer, GlobalStyles.flex, GlobalStyles.gap)}
        onClick={(event) => event.stopPropagation()}
      >
        {!isDetailsExpanded ? (
          <>
            <div className={classNames(EmployeeListItemStyles.secondaryText, GlobalStyles.flex, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(EmployeeListItemStyles.detailsContainerIconImg)} src={envelopeIcon} alt="type" />
              </div>
              <div className={classNames(GlobalStyles.centerVertical)}>{employeeData.category}</div>
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(EmployeeListItemStyles.secondaryText, GlobalStyles.flex, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(EmployeeListItemStyles.detailsContainerIconImg)} src={wrenchIcon} alt="position" />
              </div>
              <div className={classNames(GlobalStyles.centerVertical)}>
                {employeeData?.globalPosition?.description ? employeeData.globalPosition.description : ""}
              </div>
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(EmployeeListItemStyles.secondaryText, GlobalStyles.flex, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(EmployeeListItemStyles.detailsContainerIconImg)} src={flagIcon} alt="flag" />
              </div>
              <div className={classNames(GlobalStyles.centerVertical)}>{employeeData.nationality}</div>
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(EmployeeListItemStyles.secondaryText, GlobalStyles.flex, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(EmployeeListItemStyles.detailsContainerIconImg)} src={cakeIcon} alt="dob" />
              </div>
              <div className={classNames(GlobalStyles.centerVertical)}>
                {employeeData.dateOfBirth ? `${displayNumberWithSpecificNumberOfDigits(calculateAge(employeeData.dateOfBirth))} Y/O` : ""}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn)}>
                  <div className={classNames(EmployeeListItemStyles.secondaryText)}>{"Employee Type"}</div>
                  <div className={classNames(EmployeeListItemStyles.mainText)}>{employeeData.category}</div>
                </div>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn)}>
                  <div className={classNames(EmployeeListItemStyles.secondaryText)}>{"Position"}</div>
                  {/*TODO Change to position data when information is available*/}
                  {<div className={classNames(EmployeeListItemStyles.mainText)}>{employeeData?.globalPosition?.description}</div>}
                </div>
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn)}>
                  <div className={classNames(EmployeeListItemStyles.secondaryText)}>{"Nationality"}</div>
                  <div className={classNames(EmployeeListItemStyles.mainText)}>{employeeData.nationality}</div>
                </div>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn)}>
                  <div className={classNames(EmployeeListItemStyles.secondaryText)}>{"Date of Birth"}</div>
                  <div className={classNames(EmployeeListItemStyles.mainText)}>
                    {employeeData.dateOfBirth ? `${formatDate(employeeData.dateOfBirth)} [${calculateAge(employeeData.dateOfBirth)} Y/O]` : ""}
                  </div>
                </div>
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn)}>
                  <div className={classNames(EmployeeListItemStyles.secondaryText)}>{"Languages"}</div>
                  {employeeData.languages && <div className={classNames(EmployeeListItemStyles.mainText)}>{employeeData.languages.join(", ")}</div>}
                </div>
              </div>
              <div className={classNames(EmployeeListItemStyles.specialDetailsContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
                <Tooltip TransitionComponent={Fade} title={!employeeData.qualifications ? "No qualifications" : ""} placement="top-start" arrow>
                  <div ref={parent} className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
                    <div
                      className={classNames(
                        !employeeData.qualifications && EmployeeListItemStyles.specialDetailsContainerDisabled,
                        GlobalStyles.flex,
                        GlobalStyles.gap05,
                      )}
                      onClick={(event) => {
                        event.stopPropagation();
                        employeeData.qualifications && setIsQualificationsExpanded((currentState) => !currentState);
                      }}
                    >
                      <div className={classNames(EmployeeListItemStyles.secondaryText)}>{"Qualifications"}</div>
                      <div className={classNames(GlobalStyles.flex1)} />
                      {employeeData.qualifications && (
                        <div className={classNames(GlobalStyles.centerVertical)}>
                          <img
                            className={classNames(EmployeeListItemStyles.arrowGrayImg, isQualificationsExpanded && GlobalStyles.imgReverted)}
                            src={arrowDownGrayIcon}
                            alt="arrow"
                          />
                        </div>
                      )}
                    </div>
                    {isQualificationsExpanded && employeeData.qualifications && (
                      <div
                        className={classNames(
                          EmployeeListItemStyles.specialDetailsList,
                          GlobalStyles.flex,
                          GlobalStyles.flexDirectionColumn,
                          GlobalStyles.gap025,
                        )}
                      >
                        {employeeData.qualifications}
                        {/*{employeeData.qualifications.map((qualificationItem) => (*/}
                        {/*  <li className={classNames(EmployeeListItemStyles.mainText)}>{qualificationItem}</li>*/}
                        {/*))}*/}
                      </div>
                    )}
                  </div>
                </Tooltip>
                <Tooltip TransitionComponent={Fade} title={!employeeData.certifications ? "No certifications" : ""} placement="top-start" arrow>
                  <div ref={parent} className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
                    <div
                      className={classNames(
                        !employeeData.certifications && EmployeeListItemStyles.specialDetailsContainerDisabled,
                        GlobalStyles.flex,
                        GlobalStyles.gap05,
                      )}
                      onClick={(event) => {
                        event.stopPropagation();
                        employeeData.certifications && setIsCertificationsExpanded((currentState) => !currentState);
                      }}
                    >
                      <div className={classNames(EmployeeListItemStyles.secondaryText)}>{"Certifications"}</div>
                      <div className={classNames(GlobalStyles.flex1)} />
                      {employeeData.certifications && (
                        <div className={classNames(GlobalStyles.centerVertical)}>
                          <img
                            className={classNames(EmployeeListItemStyles.arrowGrayImg, isCertificationsExpanded && GlobalStyles.imgReverted)}
                            src={arrowDownGrayIcon}
                            alt="arrow"
                          />
                        </div>
                      )}
                    </div>
                    {isCertificationsExpanded && employeeData.certifications && (
                      <div
                        className={classNames(
                          EmployeeListItemStyles.specialDetailsList,
                          GlobalStyles.flex,
                          GlobalStyles.flexDirectionColumn,
                          GlobalStyles.gap025,
                        )}
                      >
                        {employeeData.certifications}
                        {/*{employeeItem.certifications.map((certificationItem) => (*/}
                        {/*  <li className={classNames(EmployeeListItemStyles.mainText)}>{certificationItem}</li>*/}
                        {/*))}*/}
                      </div>
                    )}
                  </div>
                </Tooltip>

                <Tooltip TransitionComponent={Fade} title={!employeeData.experience ? "No experience info" : ""} placement="top-start" arrow>
                  <div ref={parent} className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
                    <div
                      className={classNames(
                        !employeeData.experience && EmployeeListItemStyles.specialDetailsContainerDisabled,
                        GlobalStyles.flex,
                        GlobalStyles.gap05,
                      )}
                      onClick={() => employeeData.experience && setIsExperienceExpanded((currentState) => !currentState)}
                    >
                      <div className={classNames(EmployeeListItemStyles.secondaryText)}>{"Experience"}</div>
                      <div className={classNames(GlobalStyles.flex1)} />
                      {employeeData.experience && (
                        <div className={classNames(GlobalStyles.centerVertical)}>
                          <img
                            className={classNames(EmployeeListItemStyles.arrowGrayImg, isExperienceExpanded && GlobalStyles.imgReverted)}
                            src={arrowDownGrayIcon}
                            alt="arrow"
                          />
                        </div>
                      )}
                    </div>
                    {isExperienceExpanded && employeeData.experience && (
                      <div
                        className={classNames(
                          EmployeeListItemStyles.specialDetailsList,
                          GlobalStyles.flex,
                          GlobalStyles.flexDirectionColumn,
                          GlobalStyles.gap2,
                        )}
                      >
                        {employeeData.experience}
                        {/*{employeeData.experience.map((experienceItem) => (*/}
                        {/*  <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>*/}
                        {/*    <div className={classNames(EmployeeListItemStyles.experienceListDate)}>{`${formatDate(*/}
                        {/*      experienceItem.startDate,*/}
                        {/*      DateFormatEnum.YYYY_MM,*/}
                        {/*    )} - ${formatDate(experienceItem.endDate, DateFormatEnum.YYYY_MM)}`}</div>*/}
                        {/*    <div className={classNames(EmployeeListItemStyles.experienceListTitle, EmployeeListItemStyles.mainText)}>*/}
                        {/*      {experienceItem.name}*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*      className={classNames(EmployeeListItemStyles.mainText)}*/}
                        {/*    >{`${experienceItem.project} | ${experienceItem.country}`}</div>*/}
                        {/*  </div>*/}
                        {/*))}*/}
                      </div>
                    )}
                  </div>
                </Tooltip>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    employeeItemSkeletonLoader
  );
};
