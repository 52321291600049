import { FrontendCalendarMMRType, FrontendDayTypeOptionsEnum } from "../CalendarApiTypes.ts";
import { OptionsTypes } from "epcm-common/dist/Types/OptionTypes";
import { calendarMmr } from "epcm-common/dist/Types/CalendarTypes";

export const convertDayTypeOptionToFrontend = (dayTypeOption: OptionsTypes): FrontendDayTypeOptionsEnum => {
  const dayTypeOptionsMap: Map<OptionsTypes, FrontendDayTypeOptionsEnum> = new Map([
    [OptionsTypes.WORKING_DAY, FrontendDayTypeOptionsEnum.WORKING_DAY],
    [OptionsTypes.NO_WORKING_DAY, FrontendDayTypeOptionsEnum.NO_WORKING_DAY],
    [OptionsTypes.HOLIDAY, FrontendDayTypeOptionsEnum.HOLIDAY],
  ]);
  return dayTypeOptionsMap.get(dayTypeOption)!;
};

export const convertCalendarMMRToFrontend = (calendarMMR: calendarMmr): FrontendCalendarMMRType => {
  return {
    id: calendarMMR.id,
    dayType: convertDayTypeOptionToFrontend(calendarMMR.dayType),
  };
};
