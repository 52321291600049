import { Dispatch, FC, SetStateAction, useState } from "react";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import selectManpowerCodePopupStyles from "./SelectManpowerCodePopup.module.css";
import classNames from "classnames";

import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import ManpowerCodeList from "./manpowercodelist/ManpowerCodeList.tsx";
import { SearchBar } from "../../../../ui/searchbar/SearchBar.tsx";

interface SelectManpowerCodePopupProps extends PopupType {
  setSelectedManpowerCode: Dispatch<SetStateAction<number | null>>;
  projectId: number;
  setSelectedManpowerCodeName: Dispatch<SetStateAction<string | null>>;
  selectedManpowerCode: number | null;
  setSubpositionId: Dispatch<SetStateAction<number | undefined>>;
}

export const SelectManpowerCodePopup: FC<SelectManpowerCodePopupProps> = ({
  isOpen,
  closeFn,
  headerText,
  secondaryHeaderText,
  setSelectedManpowerCode,
  projectId,
  setSelectedManpowerCodeName,
  selectedManpowerCode,
  setSubpositionId,
}) => {
  //const [currentlyHovered, setCurrentlyHovered] = useState<ButtonsWithHoverEnum | null>(null);
  const [tempManpowerCode, setTempManpowerCode] = useState<number | null>(selectedManpowerCode);
  const [tempManpowerCodeName, setTempManpowerCodeName] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const handleSelectManpowerCode = () => {
    setSelectedManpowerCode(tempManpowerCode);
    setSelectedManpowerCodeName(tempManpowerCodeName);
    if (selectedManpowerCode !== tempManpowerCode) {
      setSubpositionId(undefined);
    }
    closeFn();
  };
  return (
    <PopupStructure
      popupButtons={[
        { text: "Close", buttonType: PopupButtonTypeEnum.neutral, action: closeFn },
        {
          text: "Apply MP Code",
          tooltipText: tempManpowerCode
            ? tempManpowerCode === selectedManpowerCode
              ? "Please select a different MP Code"
              : ""
            : "Please select a MP Code",
          buttonType: PopupButtonTypeEnum.main,
          disabled: !tempManpowerCode || tempManpowerCode === selectedManpowerCode,
          action: () => handleSelectManpowerCode(),
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      overrideContentContainerStyleClass={""}
    >
      <div
        className={classNames(selectManpowerCodePopupStyles.contentContainer, GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.flexDirectionColumn)}
      >
        <SearchBar
          placeholder={"Search for MP Code"}
          setSearchQuery={setSearchValue}
          searchBarContainerStyles={selectManpowerCodePopupStyles.searchInputStyling}
        />
        <ManpowerCodeList
          projectId={projectId}
          setSelectedManpowerCodeName={setTempManpowerCodeName}
          selectedManpowerCodeId={tempManpowerCode}
          setTempManpowerCode={setTempManpowerCode}
          searchValue={searchValue}
        />
      </div>
    </PopupStructure>
  );
};
