import { FC, useCallback, useState } from "react";
import ConfigurationPermissionRowStyles from "./ConfigurationUserPermissionRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import TrashCanDarkIcon from "../../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIcon from "../../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../../assets/images/trashcan-icon-red.svg";
import EditIconHover from "../../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import orangeSeparator from "../../../../../../assets/images/orange-dot.svg";
import { FrontendProjectUserTypeWithRoleIds } from "../../../../../../types/projects/PermissionTypes.ts";
import { FrontendRoleNormal } from "../../../../../../types/apicallstypes/UsersApiTypes.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../use-projects-popups.ts";
import { useProjectContext } from "../../../../ProjectsUtils.ts";
import EditUserRolePopup from "../../../../popups/edituserrolepopup/EditUserRolePopup.tsx";
import { ConfigurationScopeEnum } from "../../../../../../types/projects/ConfigurationTypes.ts";

import { useEpcmApiProjectPermissionsMutation } from "../../../../../../apicalls/projects/projectpermissions/mutations/useEpcmApiProjectPermissionsMutation.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { useQueryClient } from "@tanstack/react-query";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import { DeletePopup } from "../../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { Fade, Skeleton, Tooltip } from "@mui/material";

interface ConfigurationPermissionRowProps {
  userItem: FrontendProjectUserTypeWithRoleIds;
  roles: FrontendRoleNormal[];
}

const ConfigurationUserPermissionRow: FC<ConfigurationPermissionRowProps> = ({ userItem, roles }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);

  const { useDeleteProjectPermissionMutation } = useEpcmApiProjectPermissionsMutation();

  const findRole = (roleId: number) => {
    if (roles) {
      return roles.find((role) => role.id === roleId);
    }
  };

  const { currentProject } = useProjectContext();
  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const deleteProjectUserFromPermissionsMutation = useDeleteProjectPermissionMutation(currentProject.id, userItem.code, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "User was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectConfigurationsPermissions, currentProject.id] });
  }, [queryClient]);

  return (
    <>
      <tr
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
        key={userItem.code}
        className={classNames(
          ConfigurationPermissionRowStyles.itemContainer,
          isRowHovered && ConfigurationPermissionRowStyles.rowHoverEffect,
          ConfigurationPermissionRowStyles.Trow,
        )}
      >
        {userItem ? (
          <>
            <td
              className={classNames(
                ConfigurationPermissionRowStyles.tableCell,
                ConfigurationPermissionRowStyles.boldText,
                ConfigurationPermissionRowStyles.tD,
              )}
            >
              {userItem.code}
            </td>
            <td className={classNames(ConfigurationPermissionRowStyles.tableCell, ConfigurationPermissionRowStyles.tD)}>
              <div
                className={classNames(ConfigurationPermissionRowStyles.dotMargin, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap)}
              >
                <div>
                  {userItem.projectRoleIds.length === 0 ? (
                    <Tooltip title={"User requires Roles"} arrow={true} placement={"bottom"}>
                      <div>
                        <img src={orangeSeparator} alt={"roles separator"}></img>
                      </div>
                    </Tooltip>
                  ) : (
                    <div className={classNames(ConfigurationPermissionRowStyles.seperatorFiller)}></div>
                  )}
                </div>
                <div>{userItem.name}</div>
              </div>
            </td>
            <td className={classNames(ConfigurationPermissionRowStyles.tableCell, ConfigurationPermissionRowStyles.tD)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap025)}>
                  {userItem.projectRoleIds.length > 0 ? (
                    userItem.projectRoleIds.map((role) => (
                      <Tooltip key={role} title={findRole(role)?.description ?? ""} arrow={true} placement={"right-end"} TransitionComponent={Fade}>
                        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap075)}>
                          <div className={classNames(ConfigurationPermissionRowStyles.rowHyphen)}>-</div>
                          <div key={role} className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                            <div>{findRole(role)?.name}</div>
                          </div>
                        </div>
                      </Tooltip>
                    ))
                  ) : (
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
                      <div key={0} className={classNames(ConfigurationPermissionRowStyles.italicText, GlobalStyles.flex, GlobalStyles.gap025)}>
                        No roles assigned
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </td>
            <td className={classNames(ConfigurationPermissionRowStyles.tableCellActions, ConfigurationPermissionRowStyles.tD)}>
              <div
                className={classNames(
                  ConfigurationPermissionRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    onOpenUtilPopup();
                  }}
                  onMouseEnter={() => setDeleteIconHovered(true)}
                  onMouseLeave={() => setDeleteIconHovered(false)}
                >
                  <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                </div>
                <div
                  className={classNames(ConfigurationPermissionRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                  onClick={() => onOpenPopup(ProjectsPagePopups.updateProjectUserRole, popupHandler)}
                  onMouseEnter={() => setEditIconHovered(true)}
                  onMouseLeave={() => setEditIconHovered(false)}
                >
                  <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                </div>
              </div>
            </td>
          </>
        ) : (
          <Skeleton width={"100%"} variant={"rectangular"}></Skeleton>
        )}
      </tr>

      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteProjectUserFromPermissionsMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}

      {popupHandler.get(ProjectsPagePopups.updateProjectUserRole)!.isOpen && (
        <EditUserRolePopup
          userItem={userItem}
          existingRolesIds={userItem.projectRoleIds}
          rolesList={roles}
          scope={ConfigurationScopeEnum.PROJECT}
          closeFn={() => onClosePopup(ProjectsPagePopups.updateProjectUserRole, popupHandler)}
          isOpen={popupHandler.get(ProjectsPagePopups.updateProjectUserRole)!.isOpen}
          secondaryHeaderText={currentProject.name}
          headerText={popupHeaders.get(ProjectsPagePopups.updateProjectUserRole)}
        />
      )}
    </>
  );
};

export default ConfigurationUserPermissionRow;
