import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ProjectEmployeesStyles from "./ProjectEmployees.module.css";
import classNames from "classnames";
import { EPCMInfoContainerDiv } from "../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import plusIcon from "../../../../assets/images/plus-icon-black.svg";
import plusIconHover from "../../../../assets/images/plus-icon-white.svg";
import employeeIcon from "../../../../assets/images/worker-icon-black.svg";
import clockIcon from "../../../../assets/images/clock-dotted.svg";
import clockWhiteIcon from "../../../../assets/images/clock-dotted-white.svg";
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ProjectEmployeeList } from "./projectemployeelist/ProjectEmployeeList.tsx";
import { QuickFilterButton } from "../../../../ui/quickfilterbutton/QuickFilterButton.tsx";
import { PageFilterContainer } from "../../../../ui/pagefiltercontainer/PageFilterContainer.tsx";
import { FrontendMmrStatusQueryEnum } from "../../../../types/projects/ProjectEmployeesTypes.ts";
import { useProjectContext } from "../../ProjectsUtils.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import { OvertimeRequestPopup } from "../../popups/overtimerequestpopup/OvertimeRequestPopup.tsx";
import { AddProjectEmployeesPopup } from "../../popups/addprojectemployeespopup/AddProjectEmployeesPopup.tsx";

import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResponseTypeEnum, QueryNames, SubQueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { Skeleton } from "@mui/material";
import { PAGINATION_PAGE_SIZE } from "../../../../apicalls/config.ts";
import { displayNumberWithSpecificNumberOfDigits } from "../../../../utils/NumberManipulation.ts";
import { useInView } from "react-intersection-observer";
import InfiniteScrollInViewElement from "../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useEpcmApiProjectEmployee } from "../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import { useEpcmApiProjects } from "../../../../apicalls/projects/useEpcmApiProjects.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { FrontendExportExcelResponseType } from "../../../../types/apicallstypes/GeneralTypes.ts";
import { fileSave } from "browser-fs-access";
import { useEpcmApiProjectsMutations } from "../../../../apicalls/projects/mutations/useEpcmApiProjectsMutations.ts";
import { FrontendOvertimeExportFileType, SuccessCallbackOvertimeDataType } from "../../../../types/projects/OvertimeTypes.ts";

import { useNotificationContext } from "../../../../ui/globalnotificationcontext/GlobalNotificationContext.ts";
import { useParams } from "react-router-dom";
// import { useHandleUnauthorized } from "../../../../utils/use-handle-unauthorized.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../types/Roles.ts";
import MmrBlackIcon from "../../../../assets/images/active-mmr-icon-bulk.svg";
import MMrWhiteIcon from "../../../../assets/images/mmr-icon-bulk.svg";
import useProcessFileEvents from "../../../../utils/useProcessFileEvents.ts";
import { FrontendFileAction } from "../../../../types/projects/FileTypes.ts";
import { MmrActionsPopup } from "../../popups/mmractionspopup/MmrActionsPopup.tsx";
import { ProjectEmployeeOperationsEnum } from "../../../../types/projects/ProjectsTypes.ts";
import useEpcmProcessFileEvents from "../../../../utils/useProcessFileEvents.ts";
import CreateReportPopup from "../../popups/createreportpopup/CreateReportPopup.tsx";

export const ProjectEmployees = () => {
  const { currentProject, searchQuery } = useProjectContext();
  const { projectId } = useParams();
  const { ref, inView } = useInView();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { getAllProjectEmployees, getAllProjectEmployeesMmrStatusCounts } = useEpcmApiProjectEmployee();
  const { getOvertimeTemplate } = useEpcmApiProjects();
  const { addNotification, updateNotification } = useNotificationContext();
  // const { handleErrorRedirect } = useHandleUnauthorized();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const { useCreateOvertimeRequestMutation } = useEpcmApiProjectsMutations();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const uploadOvertimeXlsxRef = useRef<HTMLInputElement | null>(null);
  const [selectedFilterType, setSelectedFilterType] = useState<FrontendMmrStatusQueryEnum | null>(null);
  const [projectEmployeesActionButtonHovered, setProjectEmployeesActionButtonHovered] = useState<ProjectEmployeeOperationsEnum | null>(null);
  const [isExportTemplateLoading, setIsExportTemplateLoading] = useState<boolean>(false);
  const [showDownloadView, setShowDownloadView] = useState<boolean>(false);
  const [generatedOvertimeTemplate, setGeneratedOvertimeTemplate] = useState<FrontendExportExcelResponseType | null>(null);
  // const [generatedBulkMmrExport, setGeneratedBulkMmrExport] = useState<FrontendExportExcelResponseType | null>(null);
  const [isTemplateDownloading, setIsTemplateDownloading] = useState<boolean>(false);
  const [overtimeXlsxFile, setOvertimeXlsxFile] = useState<File | null>();
  const [showUploadView, setShowUploadView] = useState<boolean>(false);
  const [isOvertimeRequestUploading, setIsOvertimeRequestUploading] = useState<boolean>(false);
  const { processFileEvents: processFileEventsImportFile } = useProcessFileEvents(
    FrontendFileAction.IMPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
    setShowDownloadView,
  );

  const { processFileEvents: processFileEventsExport } = useEpcmProcessFileEvents(
    FrontendFileAction.EXPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
    setShowDownloadView,
  );

  const handleFilterClick = useCallback(
    (filterType: FrontendMmrStatusQueryEnum | null) => {
      setSelectedFilterType((currentState) => (currentState === filterType ? null : filterType));
    },
    [setSelectedFilterType],
  );
  const allProjectEmployeesQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectEmployees, currentProject.id, searchQuery, selectedFilterType],
    queryFn: ({ pageParam = 1 }) =>
      getAllProjectEmployees(currentProject.id, pageParam, searchQuery, PAGINATION_PAGE_SIZE, undefined, selectedFilterType ?? undefined),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });
  const allProjectEmployeesCountQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployees, currentProject.id, searchQuery, SubQueryNames.count],
    queryFn: () => getAllProjectEmployees(currentProject.id, 1, searchQuery, PAGINATION_PAGE_SIZE),
    enabled: isAuthorized,
  });

  const allProjectEmployeesData = useMemo(() => {
    return allProjectEmployeesQuery.data?.pages.flatMap((page) => page.data);
  }, [allProjectEmployeesQuery.data]);

  const totalProjectEmployeesCount = useMemo(() => {
    if (allProjectEmployeesCountQuery.data) {
      return allProjectEmployeesCountQuery.data.totalCount;
    }
  }, [allProjectEmployeesCountQuery]);

  const allProjectEmployeesActiveMmrDataQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployeesMmrStatusCount, currentProject.id, FrontendMmrStatusQueryEnum.ACTIVE],
    queryFn: () => getAllProjectEmployeesMmrStatusCounts(currentProject.id, FrontendMmrStatusQueryEnum.ACTIVE),
    enabled: isAuthorized,
  });
  const allProjectEmployessActiveMmrData = useMemo(() => {
    return allProjectEmployeesActiveMmrDataQuery.data;
  }, [allProjectEmployeesActiveMmrDataQuery.data]);

  const allProjectEmployeesNotEligibleDataQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployeesMmrStatusCount, currentProject.id, FrontendMmrStatusQueryEnum.notEligible],
    queryFn: () => getAllProjectEmployeesMmrStatusCounts(currentProject.id, FrontendMmrStatusQueryEnum.notEligible),
    enabled: isAuthorized,
  });

  const allProjectEmployeesNotEligibleData = useMemo(() => {
    return allProjectEmployeesNotEligibleDataQuery.data;
  }, [allProjectEmployeesNotEligibleDataQuery.data]);

  const allProjectEmployeesFutureMmrDataQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployeesMmrStatusCount, currentProject.id, FrontendMmrStatusQueryEnum.FUTURE],
    queryFn: () => getAllProjectEmployeesMmrStatusCounts(currentProject.id, FrontendMmrStatusQueryEnum.FUTURE),
    enabled: isAuthorized,
  });

  const allProjectEmployeesFutureMmrData = useMemo(() => {
    return allProjectEmployeesFutureMmrDataQuery.data;
  }, [allProjectEmployeesFutureMmrDataQuery.data]);

  const allProjectEmployeesNotApprovedQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployeesMmrStatusCount, currentProject.id, FrontendMmrStatusQueryEnum.notApproved],
    queryFn: () => getAllProjectEmployeesMmrStatusCounts(currentProject.id, FrontendMmrStatusQueryEnum.notApproved),
    enabled: isAuthorized,
  });

  const allProjectEmployeesNotApprovedData = useMemo(() => {
    return allProjectEmployeesNotApprovedQuery.data;
  }, [allProjectEmployeesNotApprovedQuery.data]);

  const allProjectEmployeesDemobilizedQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployeesMmrStatusCount, currentProject.id, FrontendMmrStatusQueryEnum.demobilized],
    queryFn: () => getAllProjectEmployeesMmrStatusCounts(currentProject.id, FrontendMmrStatusQueryEnum.demobilized),
    enabled: isAuthorized,
  });

  const allProjectEmployeesDemobilizedData = useMemo(() => {
    return allProjectEmployeesDemobilizedQuery.data;
  }, [allProjectEmployeesDemobilizedQuery.data]);

  const startFileEventsExportListener = async (fileId: string) => {
    await processFileEventsExport(fileId);
  };
  const startFileEventsListener = async (fileId: string) => {
    await processFileEventsImportFile(fileId);
  };

  const uploadOvertimeRequestMutation = useCreateOvertimeRequestMutation(currentProject.id, {
    onSuccessCallback: (data: SuccessCallbackOvertimeDataType) => {
      setIsOvertimeRequestUploading(true);
      void startFileEventsListener(data.data as unknown as string).then(() => {
        setIsOvertimeRequestUploading(false);
      });
    },
    onErrorCallback: (error) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      setIsOvertimeRequestUploading(false);
    },
  });

  const onOvertimeXlsxFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    setOvertimeXlsxFile(event.target.files[0]);
    setShowUploadView(true);
  };

  const exportOvertimeTemplateToFile = useCallback(
    async (type: FrontendOvertimeExportFileType) => {
      getOvertimeTemplate(currentProject.id, type)
        .then((value) => {
          startFileEventsExportListener(value.fileId);
        })
        .catch((error) => {
          setResponseType(ApiResponseTypeEnum.error);
          setResponseObject(error.response.data);
          onOpenResponseAlertPopup();
          setIsExportTemplateLoading(false);
        });
    },
    [currentProject, getOvertimeTemplate, onOpenResponseAlertPopup, setResponseType, setResponseObject, startFileEventsListener],
  );

  const saveOvertimeTemplateFile = useCallback(() => {
    setShowDownloadView(false);

    if (generatedOvertimeTemplate) {
      fileSave(generatedOvertimeTemplate.responseData, {
        fileName: generatedOvertimeTemplate.fileName,
        extensions: [".xlsx"],
      })
        .then(() => {
          onOpenResponseAlertPopup();
          setResponseType(ApiResponseTypeEnum.success);
          setResponseObject({ status: 200, message: "File saved successfully!" });
          onOpenResponseAlertPopup();
        })
        .catch((error) => {
          setResponseType(ApiResponseTypeEnum.error);
          setResponseObject(error.response.data);
          onOpenResponseAlertPopup();
        })
        .finally(() => {
          setGeneratedOvertimeTemplate(null);
          setIsTemplateDownloading(false);
        });
    }
  }, [generatedOvertimeTemplate, setResponseType, setResponseObject, onOpenResponseAlertPopup, currentProject.id]);

  const cancelOvertimeTemplateFileDownload = useCallback(() => {
    setGeneratedOvertimeTemplate(null);
    setShowDownloadView(false);
  }, []);

  const onOvertimeXlsxFileUpload = async () => {
    if (!overtimeXlsxFile) {
      return;
    }
    setShowUploadView(false);
    setIsOvertimeRequestUploading(true);
    const formData = new FormData();
    formData.append("file", overtimeXlsxFile, "file");
    uploadOvertimeRequestMutation.mutate(formData);
  };

  const cancelOvertimeXlsxFileUpload = useCallback(() => {
    setOvertimeXlsxFile(null);
    setShowUploadView(false);
  }, [setOvertimeXlsxFile, setShowUploadView]);

  const projectEmployeeListItemSkeletonLoader = (
    <div className={classNames(ProjectEmployeesStyles.projectEmployeeItemContainerSkeletonLoader, GlobalStyles.flex, GlobalStyles.gap025)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
        <Skeleton variant="circular" height={40} width={40} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.gap05)}>
          <Skeleton variant="rounded" height={13} width={65} />
          <Skeleton variant="rounded" height={13} width={130} />
        </div>
      </div>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex1, GlobalStyles.gap05)}>
        <Skeleton variant="rounded" height={13} width={65} />
        <Skeleton variant="rounded" height={13} width={130} />
      </div>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex1, GlobalStyles.gap05)}>
        <Skeleton variant="rounded" height={13} width={65} />
        <Skeleton variant="rounded" height={13} width={130} />
      </div>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex1, GlobalStyles.gap05)}>
        <Skeleton variant="rounded" height={13} width={65} />
        <Skeleton variant="rounded" height={13} width={130} />
      </div>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex1, GlobalStyles.gap05)}>
        <Skeleton variant="rounded" height={13} width={65} />
        <Skeleton variant="rounded" height={13} width={130} />
      </div>
      <div className={classNames(GlobalStyles.flex)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={0} width={20} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={15} width={60} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={0} width={20} />
        </div>
      </div>
    </div>
  );

  const projectEmployeeListSkeletonLoader = (
    <div
      className={classNames(
        ProjectEmployeesStyles.projectEmployeeListContainerSkeletonLoader,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap15,
      )}
    >
      {["skeleton1", "skeleton2", "skeleton3", "skeleton4"].map((key) => (
        <div key={key}>{projectEmployeeListItemSkeletonLoader}</div>
      ))}
    </div>
  );

  const canListProjectEmployees = canPerformProjectAction(ProjectAction.ProjectEmployeeList);
  const canAddProjectEmployees = canPerformProjectAction(ProjectAction.ProjectEmployeeCreate);

  const isAddButtonHovered = useMemo(
    () => projectEmployeesActionButtonHovered === ProjectEmployeeOperationsEnum.ADD_EMPLOYEE,
    [projectEmployeesActionButtonHovered],
  );
  const isOvertimeButtonHovered = useMemo(
    () => projectEmployeesActionButtonHovered === ProjectEmployeeOperationsEnum.MANAGE_OVERTIME,
    [projectEmployeesActionButtonHovered],
  );
  const isMmrOperationsHovered = useMemo(
    () => projectEmployeesActionButtonHovered === ProjectEmployeeOperationsEnum.MANAGE_MMRS,
    [projectEmployeesActionButtonHovered],
  );
  const isCreateReportHovered = useMemo(
    () => projectEmployeesActionButtonHovered === ProjectEmployeeOperationsEnum.CREATE_REPORT,
    [projectEmployeesActionButtonHovered],
  );
  useEffect(() => {
    if (
      inView &&
      !allProjectEmployeesQuery.isLoading &&
      !allProjectEmployeesQuery.isFetching &&
      !allProjectEmployeesQuery.isFetchingNextPage &&
      allProjectEmployeesQuery.hasNextPage
    ) {
      void allProjectEmployeesQuery.fetchNextPage();
    }
  }, [inView, allProjectEmployeesQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectEmployees, currentProject.id, searchQuery, selectedFilterType] })
        .then(() => console.log(`In project with id ${currentProject.id}, ${QueryNames.ProjectEmployees} query canceled`));
      queryClient
        .cancelQueries({
          queryKey: [QueryNames.ProjectEmployeesMmrStatusCount, currentProject.id],
        })
        .then(() => {
          console.log(`In project with id ${currentProject.id}, ${QueryNames.ProjectEmployeesMmrStatusCount} query canceled`);
        });
      queryClient
        .cancelQueries({
          queryKey: [QueryNames.ProjectEmployees, currentProject.id, searchQuery, SubQueryNames.count],
        })
        .then(() => {
          console.log(`In project with id ${currentProject.id}, ${QueryNames.ProjectEmployees} count query canceled`);
        });
    };
  }, [queryClient, currentProject, searchQuery, selectedFilterType]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3, GlobalStyles.overflowHiddenFullHeight)}>
      <div className={classNames(ProjectEmployeesStyles.infoBarContainer, GlobalStyles.flex, GlobalStyles.gap)}>
        {canListProjectEmployees && (
          <EPCMInfoContainerDiv className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
            <div className={classNames()}>
              <img className={classNames(ProjectEmployeesStyles.employeeIconImg)} src={employeeIcon} alt="employee" />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(ProjectEmployeesStyles.employeesTextContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
              <div className={classNames(ProjectEmployeesStyles.employeesSmallerText)}>{"Project has"}</div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
                {!allProjectEmployeesCountQuery.isLoading ? (
                  totalProjectEmployeesCount || totalProjectEmployeesCount === 0 ? (
                    <div className={classNames(ProjectEmployeesStyles.employeesBolderText)}>{`${displayNumberWithSpecificNumberOfDigits(
                      totalProjectEmployeesCount!,
                    )}`}</div>
                  ) : (
                    <div className={classNames(ProjectEmployeesStyles.employeesBolderText)}>{"00"}</div>
                  )
                ) : (
                  <Skeleton variant="rounded" height={15} width={30} />
                )}
                <div className={classNames(ProjectEmployeesStyles.employeesBolderText)}>{"Employees"}</div>
              </div>
            </div>
          </EPCMInfoContainerDiv>
        )}
        {canAddProjectEmployees && (
          <EPCMInfoContainerDiv
            className={classNames(
              ProjectEmployeesStyles.largeButton,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
              GlobalStyles.elementWithCursor,
            )}
            onMouseEnter={() => setProjectEmployeesActionButtonHovered(ProjectEmployeeOperationsEnum.ADD_EMPLOYEE)}
            onMouseLeave={() => setProjectEmployeesActionButtonHovered(null)}
            onClick={() => onOpenPopup(ProjectsPagePopups.addProjectEmployees, popupHandler)}
          >
            <div className={classNames(ProjectEmployeesStyles.largeButtonIcon)}>
              <img className={classNames(ProjectEmployeesStyles.largeButtonIconImg)} src={isAddButtonHovered ? plusIconHover : plusIcon} alt="plus" />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(ProjectEmployeesStyles.largeButtonTextContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
              <div className={classNames(ProjectEmployeesStyles.largeButtonSmallerText)}>{"Add"}</div>
              <div className={classNames(ProjectEmployeesStyles.largeButtonBolderText)}>{"Employee"}</div>
            </div>
          </EPCMInfoContainerDiv>
        )}
        {canListProjectEmployees && (
          <EPCMInfoContainerDiv
            className={classNames(
              ProjectEmployeesStyles.largeButton,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
              GlobalStyles.elementWithCursor,
            )}
            onMouseEnter={() => setProjectEmployeesActionButtonHovered(ProjectEmployeeOperationsEnum.MANAGE_OVERTIME)}
            onMouseLeave={() => setProjectEmployeesActionButtonHovered(null)}
            onClick={() => onOpenPopup(ProjectsPagePopups.overtimeRequest, popupHandler)}
          >
            <div className={classNames(ProjectEmployeesStyles.largeButtonIcon)}>
              <img
                className={classNames(ProjectEmployeesStyles.largeButtonIconImg)}
                src={isOvertimeButtonHovered ? clockWhiteIcon : clockIcon}
                alt="clock"
              />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(ProjectEmployeesStyles.largeButtonTextContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
              <div className={classNames(ProjectEmployeesStyles.largeButtonSmallerText)}>{"Manage"}</div>
              <div className={classNames(ProjectEmployeesStyles.largeButtonBolderText)}>{"Overtime"}</div>
            </div>
          </EPCMInfoContainerDiv>
        )}
        {canListProjectEmployees && (
          <EPCMInfoContainerDiv
            className={classNames(
              ProjectEmployeesStyles.largeButton,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
              GlobalStyles.elementWithCursor,
            )}
            onMouseEnter={() => setProjectEmployeesActionButtonHovered(ProjectEmployeeOperationsEnum.MANAGE_MMRS)}
            onMouseLeave={() => setProjectEmployeesActionButtonHovered(null)}
            onClick={() => {
              if (isAuthorized) {
                onOpenPopup(ProjectsPagePopups.mmrActions, popupHandler);
              }
            }}
          >
            <div className={classNames(ProjectEmployeesStyles.largeButtonIcon)}>
              <img
                className={classNames(ProjectEmployeesStyles.largeButtonIconImg)}
                src={isMmrOperationsHovered ? MMrWhiteIcon : MmrBlackIcon}
                alt="clock"
              />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(ProjectEmployeesStyles.largeButtonTextContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
              <div className={classNames(ProjectEmployeesStyles.largeButtonSmallerText)}>{"Manage"}</div>
              <div className={classNames(ProjectEmployeesStyles.largeButtonBolderText)}>{"MMR Data"}</div>
            </div>
          </EPCMInfoContainerDiv>
        )}
        {canListProjectEmployees && (
          <EPCMInfoContainerDiv
            className={classNames(
              ProjectEmployeesStyles.largeButton,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
              GlobalStyles.elementWithCursor,
            )}
            onMouseEnter={() => setProjectEmployeesActionButtonHovered(ProjectEmployeeOperationsEnum.CREATE_REPORT)}
            onMouseLeave={() => setProjectEmployeesActionButtonHovered(null)}
            onClick={() => {
              if (isAuthorized) {
                onOpenPopup(ProjectsPagePopups.createProjectReport, popupHandler);
              }
            }}
          >
            <div className={classNames(ProjectEmployeesStyles.largeButtonIcon)}>
              <img
                className={classNames(ProjectEmployeesStyles.largeButtonIconImg)}
                src={isCreateReportHovered ? MMrWhiteIcon : MmrBlackIcon}
                alt="clock"
              />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(ProjectEmployeesStyles.largeButtonTextContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
              <div className={classNames(ProjectEmployeesStyles.largeButtonSmallerText)}>{"Create"}</div>
              <div className={classNames(ProjectEmployeesStyles.largeButtonBolderText)}>{"Report"}</div>
            </div>
          </EPCMInfoContainerDiv>
        )}

        <div className={classNames(GlobalStyles.flex1)} />
        {canListProjectEmployees && (
          <PageFilterContainer smallerFontText={"Filter by"} mainText={"MMRs"} onFiltersClick={() => {}}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap15)}>
              <QuickFilterButton
                isFilterActive={selectedFilterType === FrontendMmrStatusQueryEnum.ACTIVE}
                headerLineText={allProjectEmployessActiveMmrData?.count ? `${allProjectEmployessActiveMmrData?.count}` : "0"}
                smallerFontText={"Active"}
                mainText={"MMRs"}
                onClickFn={() => handleFilterClick(FrontendMmrStatusQueryEnum.ACTIVE)}
              />
              <QuickFilterButton
                isFilterActive={selectedFilterType === FrontendMmrStatusQueryEnum.FUTURE}
                headerLineText={allProjectEmployeesFutureMmrData?.count ? `${allProjectEmployeesFutureMmrData?.count}` : "0"}
                smallerFontText={"Future"}
                mainText={"MMRs"}
                onClickFn={() => handleFilterClick(FrontendMmrStatusQueryEnum.FUTURE)}
              />
              <QuickFilterButton
                isFilterActive={selectedFilterType === FrontendMmrStatusQueryEnum.notEligible}
                headerLineText={allProjectEmployeesNotEligibleData?.count ? `${allProjectEmployeesNotEligibleData?.count}` : "0"}
                smallerFontText={"Not Eligible"}
                mainText={"MMRs"}
                onClickFn={() => handleFilterClick(FrontendMmrStatusQueryEnum.notEligible)}
              />
              <QuickFilterButton
                isFilterActive={selectedFilterType === FrontendMmrStatusQueryEnum.notApproved}
                headerLineText={allProjectEmployeesNotApprovedData?.count ? `${allProjectEmployeesNotApprovedData?.count}` : "0"}
                smallerFontText={"Not Approved"}
                mainText={"MMRs"}
                onClickFn={() => handleFilterClick(FrontendMmrStatusQueryEnum.notApproved)}
              />
              <QuickFilterButton
                isFilterActive={selectedFilterType === FrontendMmrStatusQueryEnum.demobilized}
                headerLineText={allProjectEmployeesDemobilizedData?.count ? `${allProjectEmployeesDemobilizedData?.count}` : "0"}
                smallerFontText={"Demobilized"}
                mainText={"MMRs"}
                onClickFn={() => handleFilterClick(FrontendMmrStatusQueryEnum.demobilized)}
              />
            </div>
          </PageFilterContainer>
        )}
      </div>
      {canListProjectEmployees ? (
        allProjectEmployeesData && (totalProjectEmployeesCount || totalProjectEmployeesCount === 0) ? (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.overflowHiddenFullHeight, GlobalStyles.gap05)}>
            <ProjectEmployeeList projectEmployeeList={allProjectEmployeesData}>
              <InfiniteScrollInViewElement
                key={"InfiniteScrollInViewElement"}
                reference={ref}
                infiniteQueryResult={allProjectEmployeesQuery}
                loaderComponent={projectEmployeeListItemSkeletonLoader}
              />
              <div className={classNames(GlobalStyles.flex1)} />
            </ProjectEmployeeList>
          </div>
        ) : (
          projectEmployeeListSkeletonLoader
        )
      ) : (
        <></>
      )}

      {popupHandler.get(ProjectsPagePopups.overtimeRequest)!.isOpen && (
        <OvertimeRequestPopup
          isOpen={popupHandler.get(ProjectsPagePopups.overtimeRequest)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.overtimeRequest, popupHandler);
            setShowDownloadView(false);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.overtimeRequest)}
          secondaryHeaderText={"Please, select an action"}
          exportOvertimeTemplateToFile={exportOvertimeTemplateToFile}
          isExportTemplateLoading={isExportTemplateLoading}
          showDownloadView={showDownloadView}
          saveOvertimeTemplateFile={saveOvertimeTemplateFile}
          cancelOvertimeTemplateFileDownload={cancelOvertimeTemplateFileDownload}
          isTemplateDownloading={isTemplateDownloading}
          uploadOvertimeXlsxRef={uploadOvertimeXlsxRef}
          onOvertimeXlsxFileChange={onOvertimeXlsxFileChange}
          showUploadView={showUploadView}
          cancelOvertimeXlsxFileUpload={cancelOvertimeXlsxFileUpload}
          onOvertimeXlsxFileUpload={onOvertimeXlsxFileUpload}
          isOvertimeRequestUploading={isOvertimeRequestUploading}
        />
      )}

      {popupHandler.get(ProjectsPagePopups.addProjectEmployees)!.isOpen && (
        <AddProjectEmployeesPopup
          isOpen={popupHandler.get(ProjectsPagePopups.addProjectEmployees)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.addProjectEmployees, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.addProjectEmployees)}
          secondaryHeaderText={`${currentProject.name} Project`}
        />
      )}
      {popupHandler.get(ProjectsPagePopups.mmrActions)!.isOpen && (
        <MmrActionsPopup
          isOpen={popupHandler.get(ProjectsPagePopups.mmrActions)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.mmrActions, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.mmrActions)}
          secondaryHeaderText={`${currentProject.name} Project`}
        />
      )}
      {popupHandler.get(ProjectsPagePopups.createProjectReport)!.isOpen && (
        <CreateReportPopup
          isOpen={popupHandler.get(ProjectsPagePopups.createProjectReport)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.createProjectReport, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.createProjectReport)}
          secondaryHeaderText={"Please, select an action"}
          projectId={projectId!}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            if (uploadOvertimeXlsxRef && uploadOvertimeXlsxRef.current) {
              uploadOvertimeXlsxRef.current.value = "";
            }
            setOvertimeXlsxFile(null);
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
          }}
          responseType={responseType}
          responseObject={responseObject}
        />
      )}
    </div>
  );
};
