import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { ApiRequestBodyGlobalRoles } from "epcm-common/dist/Types/RoleTypes";
import { AxiosRequestConfig } from "axios";

const API = `${EPCM_API_URLS.configurations}`;

const updateGlobalUserPermissionMutation = async (userRoles: ApiRequestBodyGlobalRoles) => {
  const config = {
    url: `${API}/users/roles`,
    method: "POST",
    data: userRoles,
  } as AxiosRequestConfig;

  return await epcmAxiosInstance(config);
};

export default updateGlobalUserPermissionMutation;
