import classNames from "classnames";
import ConfigurationManpowerCodesPopupStyles from "./ConfigurationManpowerCodesPopup.module.css";

import TimesheetGroupSettingsMainIcon from "../../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";

import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import ConfigurationSelect from "./configurationselect/ConfigurationSelect.tsx";
import ConfigurationTradesPopupStyles from "../configurationdisciplinespopup/ConfigurationDisciplinesPopup.module.css";
import {
  FrontendProjectLocationLimited,
  FrontendProjectCategoryType,
  FrontendProjectManpowerPositionFullType,
  FrontendProjectPositionType,
  FrontendProjectTradeType,
} from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { PopupButtonTypeEnum, PopupType } from "../../../../../../types/PopupType.ts";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { useEpcmApiProjectsUtils } from "../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SubQueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { getInitials, getSubstringBasedOnChars } from "../../../../../../utils/StringManipulation.ts";
import { PopupStructure } from "../../../../../../ui/popupstructure/PopupStructure.tsx";
import { NumericFormat } from "react-number-format";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { ApiRequestBodyManpowerPositionUpdate } from "epcm-common/dist/Types/ManpowerPositionTypes";

interface ConfigurationManpowerCodesPopupProps extends PopupType {
  projectId: number;
  manpowerCodeItem?: FrontendProjectManpowerPositionFullType;
  mode: configurationsPopupType;
}

const ConfigurationManpowerCodesPopup: FC<ConfigurationManpowerCodesPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  projectId,
  manpowerCodeItem,
  mode,
}) => {
  const queryClient = useQueryClient();

  const [selectedLocation, setSelectedLocation] = useState<FrontendProjectLocationLimited | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<FrontendProjectCategoryType | null>(null);
  const [selectedPosition, setSelectedPosition] = useState<FrontendProjectPositionType | null>(null);
  const [selectedTrade, setSelectedTrade] = useState<FrontendProjectTradeType | null>(null);
  const [selectedRate, setSelectedRate] = useState<number>();
  const [pricingScheduledManhours, setPricingScheduledManhours] = useState<number>();
  const [manpowerCode, setManpowerCode] = useState<string>("");
  const [manpowerCodePosition, setManpowerCodePosition] = useState<string>("");
  const [hasSelectedPositionBeenInitialized, setHasSelectedPositionBeenInitialized] = useState<boolean>(false);

  const { useCreateProjectEntityMutation, useUpdateProjectEntityMutation } = useEpcmApiProjectUtilsMutations();
  const { getAllProjectLocations, getAllProjectCategories, getAllProjectPositions, getAllProjectTrades } = useEpcmApiProjectsUtils();

  //keep track of the hovered state of which delete subposition button
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  useEffect(() => {
    const extractedMPCodePosition = manpowerCodeItem?.code.split("-")[2];

    if (manpowerCodeItem) {
      setManpowerCodePosition(extractedMPCodePosition!);
      setHasSelectedPositionBeenInitialized(true);
      setManpowerCode(manpowerCodeItem.code);
      setSelectedLocation(manpowerCodeItem.location);
      setSelectedCategory(manpowerCodeItem.category);
      setSelectedPosition(manpowerCodeItem.position);
      setSelectedTrade(manpowerCodeItem.trade);
      setSelectedRate(manpowerCodeItem.rate);

      setPricingScheduledManhours(manpowerCodeItem.pricingScheduledManhours);
    }
  }, [
    manpowerCodeItem,
    setHasSelectedPositionBeenInitialized,
    setManpowerCode,
    setSelectedLocation,
    setSelectedCategory,
    setSelectedPosition,
    setSelectedTrade,
    setSelectedRate,
    setPricingScheduledManhours,
  ]);

  const createProjectManpowerPosition = useCreateProjectEntityMutation(projectId!, FrontendProjectConfigurationsEntityEnum.ManpowerPosition, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Manpower position was added to project successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateProjectManpowerPosition = useUpdateProjectEntityMutation(
    projectId!,
    manpowerCodeItem?.id ?? 0,
    FrontendProjectConfigurationsEntityEnum.ManpowerPosition,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: `Manpower position was updated successfully` });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );

  useEffect(() => {
    const locationDescription = selectedLocation?.description ? selectedLocation.description : "";
    const categoryCode = selectedCategory?.code ? selectedCategory.code : "";
    const positionCode = manpowerCodePosition
      ? manpowerCodePosition
      : hasSelectedPositionBeenInitialized
        ? ""
        : selectedPosition?.code
          ? selectedPosition.code
          : "";

    if (locationDescription || categoryCode || positionCode) {
      setManpowerCode(
        `${locationDescription ? getInitials(locationDescription) : "__"}-${categoryCode ? getSubstringBasedOnChars(categoryCode) : "__"}-${positionCode ? `${positionCode}` : "__"}`,
      );
    } else {
      setManpowerCode("");
    }
  }, [
    selectedLocation,
    selectedCategory,
    selectedPosition,
    manpowerCodePosition,
    setManpowerCode,
    hasSelectedPositionBeenInitialized,
    setHasSelectedPositionBeenInitialized,
  ]);

  const onCreateProjectCategory = useCallback(() => {
    createProjectManpowerPosition.mutate({
      code: manpowerCode,
      locationId: selectedLocation!.id,
      categoryId: selectedCategory!.id,
      positionId: selectedPosition!.id,
      tradeId: selectedTrade!.id,
      rate: selectedRate!,
      pricingScheduledManhours: pricingScheduledManhours!,
    });
    onOpenLoaderPopup();
  }, [
    createProjectManpowerPosition,
    onOpenLoaderPopup,
    manpowerCode,
    pricingScheduledManhours,
    selectedCategory,
    selectedLocation,
    selectedPosition,
    selectedRate,
    selectedTrade,
  ]);

  const onUpdateProjectCategory = useCallback(() => {
    updateProjectManpowerPosition.mutate({
      code: manpowerCode,
      locationId: selectedLocation!.id,
      categoryId: selectedCategory!.id,
      positionId: selectedPosition!.id,
      tradeId: selectedTrade!.id,
      rate: selectedRate!,
      pricingScheduledManhours: pricingScheduledManhours!,
    } satisfies ApiRequestBodyManpowerPositionUpdate);
    onOpenLoaderPopup();
  }, [
    updateProjectManpowerPosition,
    onOpenLoaderPopup,
    manpowerCode,
    pricingScheduledManhours,
    selectedCategory,
    selectedLocation,
    selectedPosition,
    selectedRate,
    selectedTrade,
  ]);

  const onLocationItemChange = useCallback(
    (location: FrontendProjectLocationLimited) => {
      setSelectedLocation(location);
    },
    [setSelectedLocation],
  );

  const onCategoryItemChange = useCallback(
    (category: FrontendProjectCategoryType) => {
      setSelectedCategory(category);
    },
    [setSelectedCategory],
  );

  const onPositionItemChange = useCallback(
    (position: FrontendProjectPositionType) => {
      setSelectedPosition(position);
    },
    [setSelectedPosition],
  );

  const onTradeItemChange = useCallback(
    (trade: FrontendProjectTradeType) => {
      setSelectedTrade(trade);
    },
    [setSelectedTrade],
  );

  const onManpowerCodePositionChange = useCallback(
    (position: string) => {
      if (!hasSelectedPositionBeenInitialized) {
        if (position) {
          setHasSelectedPositionBeenInitialized(true);
        }
      }
      setManpowerCodePosition(position);
    },
    [setManpowerCodePosition],
  );

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectPositionCodes, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectPositionCode, projectId] });
  }, [queryClient, projectId]);

  const hasInvalidInputs = useMemo(
    () =>
      (!manpowerCodePosition && hasSelectedPositionBeenInitialized) ||
      !selectedLocation?.id ||
      !selectedCategory?.id ||
      !selectedPosition?.id ||
      !selectedTrade?.id ||
      !selectedRate ||
      !pricingScheduledManhours,
    [
      hasSelectedPositionBeenInitialized,
      manpowerCodePosition,
      selectedLocation,
      selectedCategory,
      pricingScheduledManhours,
      selectedPosition,
      selectedRate,
      selectedTrade,
    ],
  );

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(ConfigurationManpowerCodesPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Manpower Code" : "Create New Manpower Code",
          action: () => {
            if (!hasInvalidInputs) {
              if (mode === configurationsPopupType.edit) {
                onUpdateProjectCategory();
              } else {
                onCreateProjectCategory();
              }
            }
          },
          tooltipText: hasInvalidInputs ? "Please enter all required fields" : "",
          disabled: hasInvalidInputs,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          ConfigurationManpowerCodesPopupStyles.mainContainer,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
          <div className={classNames(GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <div className={classNames(ConfigurationManpowerCodesPopupStyles.infoText)}>MP-CODE</div>
            <input
              className={classNames(ConfigurationTradesPopupStyles.infoContainerInput, ConfigurationTradesPopupStyles.manpowerInput)}
              value={manpowerCode}
              type={"text"}
              placeholder={"__-__-__"}
              readOnly
            ></input>
          </div>
          <div className={classNames(GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <input
              className={classNames(ConfigurationTradesPopupStyles.infoContainerInput, ConfigurationTradesPopupStyles.normalInput)}
              value={manpowerCodePosition}
              onChange={(e) => onManpowerCodePositionChange(e.target.value)}
              placeholder={"MP-CODE position..."}
            ></input>
          </div>
          <ConfigurationSelect
            label={"Location Type"}
            queryName={`${QueryNames.ProjectLocations},${projectId},${SubQueryNames.manpowerPopupContent}`}
            getData={getAllProjectLocations}
            selectedItem={selectedLocation}
            setSelectedItem={onLocationItemChange}
            projectId={projectId}
          />
          <ConfigurationSelect
            label={"Category Type"}
            queryName={`${QueryNames.ProjectCategories},${SubQueryNames.manpowerPopupContent}`}
            getData={getAllProjectCategories}
            selectedItem={selectedCategory}
            setSelectedItem={onCategoryItemChange}
            projectId={projectId}
          />
          <ConfigurationSelect
            label={"Position"}
            queryName={`${QueryNames.ProjectPositions},${SubQueryNames.manpowerPopupContent}`}
            getData={getAllProjectPositions}
            selectedItem={selectedPosition}
            setSelectedItem={onPositionItemChange}
            projectId={projectId}
          />
          <ConfigurationSelect
            label={"Trade"}
            queryName={`${QueryNames.ProjectTrades},${SubQueryNames.manpowerPopupContent}`}
            getData={getAllProjectTrades}
            selectedItem={selectedTrade}
            setSelectedItem={onTradeItemChange}
            projectId={projectId}
          />

          <div className={classNames(GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <div className={classNames(ConfigurationManpowerCodesPopupStyles.infoText)}>RATE/HOUR</div>
            <NumericFormat
              placeholder={"Type a rate/hour..."}
              className={classNames(ConfigurationTradesPopupStyles.infoContainer, ConfigurationTradesPopupStyles.infoContainerInput)}
              required
              value={selectedRate}
              onValueChange={(values) => {
                if (values.floatValue && values.floatValue > 0) {
                  setSelectedRate(values.floatValue);
                }
              }}
              allowNegative={false}
            ></NumericFormat>
          </div>
          <div className={classNames(GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <div className={classNames(ConfigurationManpowerCodesPopupStyles.infoText)}>PRICING MANHOURS SCHEDULE</div>
            <NumericFormat
              placeholder={"Type pricing manhours schedule ..."}
              className={classNames(ConfigurationTradesPopupStyles.infoContainer, ConfigurationTradesPopupStyles.infoContainerInput)}
              required
              value={pricingScheduledManhours}
              onValueChange={(values) => {
                if (values.floatValue && values.floatValue > 0) {
                  setPricingScheduledManhours(values.floatValue);
                }
              }}
              allowNegative={false}
            ></NumericFormat>
          </div>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ConfigurationManpowerCodesPopup;
