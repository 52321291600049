import classNames from "classnames";
import GlobalConfigurationPositionsPopupStyles from "./GlobalConfigurationPositionsPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

import { FC, useCallback, useMemo, useState, useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { PopupButtonTypeEnum, PopupType } from "../../../../../types/PopupType.ts";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PopupStructure } from "../../../../../ui/popupstructure/PopupStructure.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { FrontendGlobalPositionType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import ConfigurationPositionsPopupStyles from "../../../../projects/project/configuration/popups/configurationpositionspopup/ConfiguratioPositionsPopup.module.css";
import TimesheetGroupSettingsRemoveMemberHoveredIcon from "../../../../../assets/images/timesheet-group-settings-remove-user-hover-icon.svg";
import TimesheetGroupSettingsRemoveMemberIcon from "../../../../../assets/images/timesheet-group-settings-remove-user-icon.svg";
import BluePlusIcon from "../../../../../assets/images/blue-plus-icon.svg";
import { ApiRequestBodySubPositionCreate } from "epcm-common/dist/Types/SubPositionTypes";
import { ApiRequestBodyGlobalPositionCreate } from "epcm-common/dist/Types/GlobalPositionTypes";
import { ApiRequestBodyGlobalSubPositionUpdate } from "epcm-common/dist/Types/GlobalSubPositionTypes";

interface GlobalConfigurationCategoriesPopupProps extends PopupType {
  globalPositionItem?: FrontendGlobalPositionType;
  mode: configurationsPopupType;
}

const GlobalConfigurationPositionsPopup: FC<GlobalConfigurationCategoriesPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  globalPositionItem,
  mode,
}) => {
  const [positionName, setPositionName] = useState<string>(globalPositionItem?.description || "");
  const [positionDescription, setPositionDescription] = useState<string>(globalPositionItem?.code || "");
  const [subpositions, setSubpositions] = useState<ApiRequestBodySubPositionCreate[]>([]);

  const { useCreateGlobalEntityMutation, useUpdateGlobalEntityMutation } = useEpcmApiConfigurationsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createGlobalPositionMutation = useCreateGlobalEntityMutation(FrontendGlobalConfigurationsEntityEnum.Position, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global Position was added successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateGlobalPositionMutations = useUpdateGlobalEntityMutation(globalPositionItem?.id ?? 0, FrontendGlobalConfigurationsEntityEnum.Position, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global Position was updated successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onCreateGlobalPosition = useCallback(() => {
    createGlobalPositionMutation.mutate({
      code: positionDescription,
      description: positionName,
      globalSubPositions: subpositions,
    } satisfies ApiRequestBodyGlobalPositionCreate);
    onOpenLoaderPopup();
  }, [createGlobalPositionMutation, positionName, positionDescription, subpositions, onOpenLoaderPopup]);

  const onUpdateGlobalPosition = useCallback(() => {
    updateGlobalPositionMutations.mutate({
      code: positionDescription,
      description: positionName,
    } satisfies ApiRequestBodyGlobalSubPositionUpdate);
    onOpenLoaderPopup();
  }, [updateGlobalPositionMutations, positionName, positionDescription, onOpenLoaderPopup]);

  const [listOfSubpositionDeleteButtonHovered, setListOfSubpositionDeleteButtonHovered] = useState<boolean[]>(
    new Array(subpositions.length).fill(false),
  );

  const isSpecificSubpositionDeleteButtonHovered = (index: number) => listOfSubpositionDeleteButtonHovered[index];

  const handleSubpositionDeleteButtonHover = (index: number) => {
    const updatedListOfSubpositionDeleteButtonHovered = [...listOfSubpositionDeleteButtonHovered];
    updatedListOfSubpositionDeleteButtonHovered[index] = !updatedListOfSubpositionDeleteButtonHovered[index];
    setListOfSubpositionDeleteButtonHovered(updatedListOfSubpositionDeleteButtonHovered);
  };

  const handleAddSubposition = () => {
    setSubpositions([
      ...subpositions,
      {
        code: "",
        description: "",
      },
    ]);
  };

  const handleSubpositionChangeName = (index: number, value: string) => {
    const updatedSubpositions = [...subpositions];
    updatedSubpositions[index].description = value;
    setSubpositions(updatedSubpositions);
  };

  const handleSubpositionChangeCode = (index: number, value: string) => {
    const updatedSubpositions = [...subpositions];
    updatedSubpositions[index].code = value;
    setSubpositions(updatedSubpositions);
  };

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalPositions] });
  }, [queryClient]);

  const isEitherInputEmpty = useMemo(() => !positionName || !positionDescription, [positionName, positionDescription]);

  useEffect(() => {
    if (globalPositionItem) {
      setPositionName(globalPositionItem.description);
      setPositionDescription(globalPositionItem.code);
    }
  }, [globalPositionItem]);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(GlobalConfigurationPositionsPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Position" : "Create New Global Position",
          tooltipText: isEitherInputEmpty ? "Please enter both position code and position description" : "",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !isEitherInputEmpty && onUpdateGlobalPosition();
            } else {
              !isEitherInputEmpty && onCreateGlobalPosition();
            }
          },
          disabled: isEitherInputEmpty,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalConfigurationPositionsPopupStyles.mainContainer,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationPositionsPopupStyles.infoText)}>Position Code</div>
          <input
            placeholder={"Type a position code..."}
            className={classNames(GlobalConfigurationPositionsPopupStyles.infoContainer, GlobalConfigurationPositionsPopupStyles.infoContainerInput)}
            required
            value={positionDescription}
            onChange={(e) => setPositionDescription(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationPositionsPopupStyles.infoText)}>Position Description</div>
          <input
            placeholder={"Type a position description..."}
            className={classNames(GlobalConfigurationPositionsPopupStyles.infoContainer, GlobalConfigurationPositionsPopupStyles.infoContainerInput)}
            required
            value={positionName}
            onChange={(e) => setPositionName(e.target.value)}
          ></input>
        </div>
        {subpositions.map((subposition, index) => (
          <div key={index} className={classNames(GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <div className={classNames(ConfigurationPositionsPopupStyles.infoText)}>Subposition {index + 1}</div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap, GlobalStyles.flex1)}>
                <input
                  placeholder={`Type a subposition code...`}
                  className={classNames(
                    GlobalStyles.flex,
                    GlobalStyles.flex1,
                    ConfigurationPositionsPopupStyles.subPositionInput,
                    ConfigurationPositionsPopupStyles.infoContainerInput,
                  )}
                  onChange={(e) => handleSubpositionChangeCode(index, e.target.value)}
                  value={subposition.code}
                ></input>
                <input
                  placeholder={`Type a subposition name...`}
                  className={classNames(
                    GlobalStyles.flex,
                    GlobalStyles.flex1,
                    ConfigurationPositionsPopupStyles.subPositionInput,
                    ConfigurationPositionsPopupStyles.infoContainerInput,
                  )}
                  onChange={(e) => handleSubpositionChangeName(index, e.target.value)}
                  value={subposition.description}
                ></input>
              </div>
              <div
                key={index}
                onMouseEnter={() => handleSubpositionDeleteButtonHover(index)}
                onMouseLeave={() => handleSubpositionDeleteButtonHover(index)}
                onClick={() => {
                  const updatedSubpositions = [...subpositions];
                  updatedSubpositions.splice(index, 1);
                  setSubpositions(updatedSubpositions);
                }}
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.centerVertical,
                  ConfigurationPositionsPopupStyles.deleteSubpositionButtonContainer,
                  listOfSubpositionDeleteButtonHovered[index] && ConfigurationPositionsPopupStyles.deleteSubpositionButtonContainerHovered,
                )}
              >
                <img
                  src={
                    isSpecificSubpositionDeleteButtonHovered(index)
                      ? TimesheetGroupSettingsRemoveMemberHoveredIcon
                      : TimesheetGroupSettingsRemoveMemberIcon
                  }
                  alt="timesheet-group-member-action-icon"
                />
              </div>
            </div>
          </div>
        ))}
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          <div
            className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
            onClick={handleAddSubposition}
          >
            <div
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
                GlobalStyles.centerVertical,
                ConfigurationPositionsPopupStyles.plusIconContainer,
              )}
            >
              <img src={BluePlusIcon} alt={"Add Subposition"} />
            </div>
            <div className={classNames(ConfigurationPositionsPopupStyles.addGroupText)}>Add subposition</div>
          </div>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default GlobalConfigurationPositionsPopup;
