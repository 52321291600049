import { FrontendProjectPositionType } from "./ProjectsUtilsApiTypes.ts";

export enum FrontendEmployeeCategoryEnum {
  ARCHIRODON = "Archirodon",
  RENTED = "Rented",
  SUBCONTRACTOR = "Subcontractor",
}

export interface FrontendEmployeeLimitedType {
  id: number;
  name: string;
  code: string;
}

export interface FrontendEmployeeDetailedType extends FrontendEmployeeLimitedType {
  nationality: string | null;
  globalPosition: FrontendProjectPositionType | null;
  category: FrontendEmployeeCategoryEnum | null;
  dateOfBirth: Date | null;
  languages: string[] | null;
  qualifications: string | null;
  experience: string | null;
  certifications: string | null;
}
