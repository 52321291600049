import BannerSideStyles from "./BannerSide.module.css";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import logo from "../../assets/images/arch-logo-white.svg";
import avatar from "../../assets/images/employee-pic-2.png";
import arrowCollapse from "../../assets/images/arrow-banner-collapse.svg";
import { MainMenu } from "./mainmenu/MainMenu.tsx";
import { useEffect, useMemo } from "react";
import { useBannerSideStore } from "../../store/use-banner-side-store.ts";
import { useEpcmApiProfile } from "../../apicalls/profile/useEpcmApiProfile.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../store/use-impersonation-store.ts";
import { formatName, getSubstringBasedOnChars } from "../../utils/StringManipulation.ts";
import { Fade, Skeleton, Tooltip } from "@mui/material";

export const BannerSide = () => {
  const { getMe } = useEpcmApiProfile();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const bannerSideState = useBannerSideStore((state) => state);
  const isBannerCollapsed = useMemo(() => bannerSideState.isCollapsed, [bannerSideState.isCollapsed]);

  const userProfileQuery = useQuery({
    queryKey: [QueryNames.Profile],
    queryFn: () => getMe(),
    enabled: isAuthorized,
  });
  const userProfileData = useMemo(() => {
    return userProfileQuery.data;
  }, [userProfileQuery.data]);

  const profileInfoSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
      <Skeleton variant="circular" width={45} height={45} />
      {!isBannerCollapsed && (
        <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical, GlobalStyles.gap025)}>
          <Skeleton variant="rounded" height={18} />
          <Skeleton variant="rounded" height={15} width={"30%"} />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.Profile] }).then(() => console.log(`In side banner, ${QueryNames.Profile} query canceled`));
    };
  }, [queryClient]);

  return (
    <div
      className={classNames(
        BannerSideStyles.banner,
        !isBannerCollapsed && BannerSideStyles.bannerExpanded,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap7,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
        <div className={classNames(GlobalStyles.flex1)} />
        <img className={classNames(BannerSideStyles.logoImg, GlobalStyles.centerVertical)} src={logo} alt="logo" />
        <div className={classNames(GlobalStyles.flex1)} />
        {!isBannerCollapsed && (
          <div
            className={classNames(
              BannerSideStyles.wordingContainer,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.centerVertical,
            )}
          >
            <div className={classNames(BannerSideStyles.wordingUpper)}>{"ARCHIRODON"}</div>
            <div className={classNames(BannerSideStyles.wordingLower)}>{"EPCM"}</div>
          </div>
        )}
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      <MainMenu isBannerCollapsed={isBannerCollapsed} />
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
        {userProfileData ? (
          <div className={classNames(BannerSideStyles.accountPanel, GlobalStyles.flex, GlobalStyles.gap075)}>
            <img className={classNames(BannerSideStyles.avatarImg, GlobalStyles.centerVertical)} src={avatar} alt="avatar" />
            {!isBannerCollapsed && (
              <div
                className={classNames(BannerSideStyles.userInfo, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}
              >
                <Tooltip
                  TransitionComponent={Fade}
                  title={
                    getSubstringBasedOnChars(formatName(userProfileData.name)) !== formatName(userProfileData.name)
                      ? formatName(userProfileData.name)
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <div className={classNames(BannerSideStyles.userName)}>{getSubstringBasedOnChars(formatName(userProfileData.name))}</div>
                </Tooltip>
                <div className={classNames(BannerSideStyles.userRole)}>{userProfileData.code}</div>
              </div>
            )}
          </div>
        ) : (
          <div className={classNames(BannerSideStyles.accountPanel, GlobalStyles.flex, GlobalStyles.gap075)}>{profileInfoSkeletonLoader}</div>
        )}
        <div className={classNames(GlobalStyles.flex)}>
          <div
            className={classNames(BannerSideStyles.arrowCollapseIcon, isBannerCollapsed && BannerSideStyles.arrowCollapseIconReverted)}
            onClick={() => bannerSideState.toggleIsCollapsed(!bannerSideState.isCollapsed)}
          >
            <img className={classNames(BannerSideStyles.arrowCollapseIconImg, GlobalStyles.centerVertical)} src={arrowCollapse} alt="collapse" />
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
      </div>
    </div>
  );
};
