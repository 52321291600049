import { configurationRowItemType, ConfigurationScreenType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import ConfigurationPositionRow from "../../configurationpositions/configurationpositionrow/ConfigurationPositionRow.tsx";
import {
  FrontendProjectLocationLimited,
  FrontendProjectCategoryType,
  FrontendProjectDisciplineType,
  FrontendProjectManpowerPositionLimitedType,
  FrontendProjectPositionType,
  FrontendProjectTradeType,
} from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import ConfigurationTradesRow from "../../configurationtrades/configurationtradesrow/ConfigurationTradesRow.tsx";
import ConfigurationLocationsRow from "../../configurationlocations/configurationlocationsrow/ConfigurationLocationsRow.tsx";
import ConfigurationCategoriesRow from "../../configurationcategories/configurationcategoriesrow/ConfigurationCategoriesRow.tsx";
import ConfigurationCtrLibraryRow from "../../ctrmanagement/configurationctrlibrary/configurationctrlibraryrow/ConfigurationCtrLibraryRow.tsx";
import ConfigurationDisciplinesRow from "../../configurationdisciplines/configurationdisciplinesrow/ConfigurationDisciplinesRow.tsx";
import ConfigurationManpowerPositionRow from "../../configurationmanpowerpositions/configurationmanpowerpositionsrow/ConfigurationManpowerPositionRow.tsx";
import { FrontendCtrLimitedType } from "../../../../../../types/apicallstypes/CtrTypes.ts";
import ConfigurationUserPermissionRow from "../../configurationpermissions/configurationuserpermisssionsrow/ConfigurationUserPermissionRow.tsx";
import { FrontendProjectUserTypeWithRoleIds } from "../../../../../../types/projects/PermissionTypes.ts";
import { FrontendRoleNormal } from "../../../../../../types/apicallstypes/UsersApiTypes.ts";

type TableRowRendererProps = {
  item: configurationRowItemType;
  roles?: FrontendRoleNormal[]; // Allows passing additional props
};

const TableRowRenderer = {
  [ConfigurationScreenType.positions]: ({ item }: TableRowRendererProps) => (
    <ConfigurationPositionRow key={item.id} positionItem={item as FrontendProjectPositionType} />
  ),
  [ConfigurationScreenType.trades]: ({ item }: TableRowRendererProps) => (
    <ConfigurationTradesRow key={item.id} tradeItem={item as FrontendProjectTradeType} />
  ),
  [ConfigurationScreenType.locations]: ({ item }: TableRowRendererProps) => (
    <ConfigurationLocationsRow key={item.id} locationItem={item as FrontendProjectLocationLimited} />
  ),
  [ConfigurationScreenType.categories]: ({ item }: TableRowRendererProps) => (
    <ConfigurationCategoriesRow key={item.id} categoryItem={item as FrontendProjectCategoryType} />
  ),
  [ConfigurationScreenType.ctrLibrary]: ({ item }: TableRowRendererProps) => (
    <ConfigurationCtrLibraryRow key={item.id} ctrItem={item as FrontendCtrLimitedType} />
  ),
  [ConfigurationScreenType.disciplines]: ({ item }: TableRowRendererProps) => (
    <ConfigurationDisciplinesRow key={item.id} disciplineItem={item as FrontendProjectDisciplineType} />
  ),
  [ConfigurationScreenType.manpowerPositions]: ({ item }: TableRowRendererProps) => (
    <ConfigurationManpowerPositionRow key={item.id} manpowerPositionLimitedItem={item as FrontendProjectManpowerPositionLimitedType} />
  ),
  [ConfigurationScreenType.ctrManagement]: () => <></>,
  [ConfigurationScreenType.permissions]: ({ item, roles }: TableRowRendererProps) => (
    <ConfigurationUserPermissionRow userItem={item as FrontendProjectUserTypeWithRoleIds} roles={roles!} />
  ),
};

export default TableRowRenderer;
