import { FrontendTimesheetStatusEnum, FrontendTimesheetType } from "./apicallstypes/ProjectTimesheetsApiTypes.ts";
import { FrontendMmrStatusEnum, FrontendProjectEmployeeMmrDetailedType } from "./apicallstypes/ProjectEmployeesApiTypes.ts";
import { QueryKey } from "@tanstack/react-query";

export interface MmrInfo {
  type: ChangeStatusPopupEntityType.MMR;
  projectId: number;
  projectEmployeeId: number;
  mmrId: number;
}

export interface TimesheetInfo {
  type: ChangeStatusPopupEntityType.TIMESHEET;
  projectId: number;
  timesheetGroupId: number;
  timesheetId: number;
}

export type ChangeStatusPopupEntityInfo = MmrInfo | TimesheetInfo;

export enum ChangeStatusPopupEntityType {
  MMR = "mmr",
  TIMESHEET = "timesheet",
}

type EntityStatusMap = {
  [K in ChangeStatusPopupEntityType]: typeof FrontendMmrStatusEnum | typeof FrontendTimesheetStatusEnum;
};

export const entityStatusMapTypes: EntityStatusMap = {
  [ChangeStatusPopupEntityType.MMR]: FrontendMmrStatusEnum,
  [ChangeStatusPopupEntityType.TIMESHEET]: FrontendTimesheetStatusEnum,
};

export interface FrontendQueryConfig {
  queryKey: QueryKey;
  queryFn: () => Promise<FrontendTimesheetType | FrontendProjectEmployeeMmrDetailedType>;
  enabled: boolean;
}
