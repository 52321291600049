import { FC, useEffect, useMemo } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import MMrInformationContentLeftSideStyles from "./MmrInformationContentLeftSide.module.css";

import MmrInformationList from "../mmrinformationlist/MmrInformationList.tsx";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectsUtils } from "../../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { QueryNames } from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { MmrDataType } from "../../../../../../../types/projects/MmrTypes.ts";
import { formatDate } from "../../../../../../../utils/DateManipulation.ts";

interface MmrInformationContentLeftSideProps {
  projectEmployeePositionId: number;
  projectEmployeeSubPositionId: number | null;
  projectEmployeeTradeId: number;
  projectEmployeeCategoryId: number;
  projectEmployeeLocationOfAssignmentId: number;
  projectEmployeeRate: number | undefined;
  projectEmployeeAgreementNo: string | undefined;
  projectSubcontractor: string | undefined;
  projectMmrCreationDate: Date | undefined;
  projectManpowerCode: string | null;
  projectDisciplineId: number | null;
  projectId: number;
}

const MmrInformationContentLeftSide: FC<MmrInformationContentLeftSideProps> = ({
  projectEmployeePositionId,
  projectEmployeeSubPositionId,
  projectEmployeeCategoryId,
  projectEmployeeTradeId,
  projectEmployeeLocationOfAssignmentId,
  projectEmployeeRate,
  projectEmployeeAgreementNo,
  projectSubcontractor,
  projectMmrCreationDate,
  projectManpowerCode,
  projectDisciplineId,
  projectId,
}) => {
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getProjectPosition, getProjectCategory, getProjectTrade, getProjectSubposition, getProjectLocation, getProjectDiscipline } =
    useEpcmApiProjectsUtils();

  const manpowerPositionQuery = useQuery({
    queryKey: [QueryNames.ProjectPositions, Number(projectId), projectEmployeePositionId],
    queryFn: () => getProjectPosition(Number(projectId), projectEmployeePositionId, DetailLevel.LIMITED),
    enabled: isAuthorized,
  });
  const manpowerPositionData = useMemo(() => {
    return manpowerPositionQuery.data?.description ?? null;
  }, [manpowerPositionQuery.data]);

  const manpowerSubPositionQuery = useQuery({
    queryKey: [QueryNames.ProjectSubpositions, Number(projectId), projectEmployeePositionId, projectEmployeeSubPositionId],
    queryFn: () => getProjectSubposition(Number(projectId), projectEmployeePositionId, projectEmployeeSubPositionId ?? 0, DetailLevel.LIMITED),
    enabled: isAuthorized && projectEmployeeSubPositionId !== null,
  });

  const manpowerSubPositionData = useMemo(() => {
    return manpowerSubPositionQuery.data?.description ?? null;
  }, [manpowerSubPositionQuery.data]);

  const manpowerTradeQuery = useQuery({
    queryKey: [QueryNames.ProjectTrades, Number(projectId), projectEmployeeTradeId],
    queryFn: () => getProjectTrade(Number(projectId), projectEmployeeTradeId, DetailLevel.LIMITED),

    enabled: isAuthorized,
  });

  const manpowerTradeData = useMemo(() => {
    return manpowerTradeQuery.data?.description ?? null;
  }, [manpowerTradeQuery.data]);

  const manpowerCategoryQuery = useQuery({
    queryKey: [QueryNames.ProjectCategories, Number(projectId), projectEmployeeCategoryId],
    queryFn: () => getProjectCategory(Number(projectId), projectEmployeeCategoryId, DetailLevel.LIMITED),

    enabled: isAuthorized,
  });

  const manpowerCategoryData = useMemo(() => {
    return manpowerCategoryQuery.data?.description ?? null;
  }, [manpowerCategoryQuery.data]);

  const manpowerLocationQuery = useQuery({
    queryKey: [QueryNames.ProjectLocations, Number(projectId), projectEmployeeLocationOfAssignmentId],
    queryFn: () => getProjectLocation(Number(projectId), projectEmployeeLocationOfAssignmentId, DetailLevel.LIMITED),

    enabled: isAuthorized,
  });

  const manpowerLocationData = useMemo(() => {
    return manpowerLocationQuery.data?.description ?? null;
  }, [manpowerLocationQuery.data]);

  const disciplineQuery = useQuery({
    queryKey: [QueryNames.ProjectDisciplines, Number(projectId), projectDisciplineId],
    queryFn: () => getProjectDiscipline(Number(projectId), projectDisciplineId ?? 0, DetailLevel.LIMITED),
    enabled: isAuthorized && !!projectDisciplineId,
  });
  const disciplineData = useMemo(() => {
    return disciplineQuery.data?.description ?? null;
  }, [disciplineQuery.data]);

  useEffect(() => {
    return () => {
      const queriesToCancel = [
        [QueryNames.ProjectPositions, Number(projectId), projectEmployeePositionId],
        [QueryNames.ProjectSubpositions, Number(projectId), projectEmployeePositionId, projectEmployeeSubPositionId],
        [QueryNames.ProjectTrades, Number(projectId), projectEmployeeTradeId],
        [QueryNames.ProjectCategories, Number(projectId), projectEmployeeCategoryId],
        [QueryNames.ProjectLocations, Number(projectId), projectEmployeeLocationOfAssignmentId],
        [QueryNames.ProjectDisciplines, Number(projectId), projectDisciplineId],
      ];

      queriesToCancel.forEach((queryKey) => {
        queryClient.cancelQueries({ queryKey: queryKey }).then(() => {
          console.log(`Query ${queryKey[0]} canceled for project ${projectId}`);
        });
      });
    };
  }, [
    queryClient,
    projectEmployeePositionId,
    projectEmployeeSubPositionId,
    projectEmployeeCategoryId,
    projectEmployeeTradeId,
    projectEmployeeLocationOfAssignmentId,
    projectDisciplineId,
    projectId,
  ]);

  const manpowerPositionRelatedData: MmrDataType[] = [
    { title: "MP CODE", content: projectManpowerCode },
    { title: "Position", content: manpowerPositionData, isLoading: manpowerPositionQuery.isLoading },
    { title: "Sub-Position", content: manpowerSubPositionData ?? "-", isLoading: manpowerSubPositionQuery.isLoading },
    { title: "Trade", content: manpowerTradeData, isLoading: manpowerTradeQuery.isLoading },
    { title: "Category", content: manpowerCategoryData, isLoading: manpowerCategoryQuery.isLoading },
    { title: "Location of Assignment", content: manpowerLocationData, isLoading: manpowerLocationQuery.isLoading },
    { title: "Discipline", content: disciplineData, isLoading: disciplineQuery.isLoading },
  ] satisfies MmrDataType[];

  const mmrGeneralData = [
    { title: "Rates", content: `$${projectEmployeeRate}/hour` },
    { title: "Agreement No", content: projectEmployeeAgreementNo },
    { title: "Subcontractor", content: projectSubcontractor },
    { title: "Date", content: formatDate(projectMmrCreationDate!) },
  ];

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, MMrInformationContentLeftSideStyles.container)}>
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.flex1,
          GlobalStyles.gap15,
          MMrInformationContentLeftSideStyles.firstSection,
        )}
      >
        <MmrInformationList mmrInformationItems={manpowerPositionRelatedData} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>

        <MmrInformationList mmrInformationItems={mmrGeneralData} />
      </div>
    </div>
  );
};

export default MmrInformationContentLeftSide;
