import React from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { DaysOfWeek } from "epcm-common/dist/Types/GeneralTypes";
import { LocationWorkingDays } from "epcm-common/dist/Types/CalendarTypes";
import ConfigurationSetUpCalendarWorkingDaysPopupItemStyles from "./ConfigurationSetUpCalendarWorkingDaysPopupItemStyles.module.css";

interface ConfigurationSetUpCalendarWorkingDaysPopupItemProps {
  location: LocationWorkingDays;
  toggleDaySelection: (day: DaysOfWeek, id: number | undefined) => void;
}

const ConfigurationSetUpCalendarWorkingDaysPopupItem: React.FC<ConfigurationSetUpCalendarWorkingDaysPopupItemProps> = ({
  location,
  toggleDaySelection,
}) => {
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.overflowHiddenFullHeight, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(ConfigurationSetUpCalendarWorkingDaysPopupItemStyles.smallPadding, GlobalStyles.flex, GlobalStyles.gap)}>
        {Object.values(DaysOfWeek).map((day: DaysOfWeek, index: number) => {
          const dayLabel = day.substring(0, 3).toUpperCase();
          return (
            <div
              key={index}
              className={classNames(
                ConfigurationSetUpCalendarWorkingDaysPopupItemStyles.dayContainer,
                GlobalStyles.elementWithCursor,
                location.workingDays.includes(day) && ConfigurationSetUpCalendarWorkingDaysPopupItemStyles.selectedDay,
              )}
              onClick={() => toggleDaySelection(day, location.locationId)}
            >
              {dayLabel}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ConfigurationSetUpCalendarWorkingDaysPopupItem;
