import { EpcmMutationOptionsType } from "../../../config.ts";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { ApiRequestBodyMmrCreate } from "epcm-common/dist/Types/MmrTypes.ts";
import { ErrorCallbackDataType, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { createMmr } from "./createMmr.ts";
import { deleteMmr } from "./deleteMmr.ts";
import { ApiRequestBodyMmrUpdate } from "epcm-common/dist/Types/MmrTypes";
import { updateMmr } from "./updateMmr.ts";
import { createBulkMmrFile } from "./createBulkMmr.ts";
import { ApiRequestBodyReportsExcel } from "epcm-common/dist/Types/ExcelTypes";
import { createReport } from "./createReport.ts";

interface ApiDefinition {
  useCreateMmrMutation: (
    projectId: number,
    employeeId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyMmrCreate>;

  useUpdateMmrMutation: (
    projectId: number,
    employeeId: number,
    mmrId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyMmrUpdate>;

  useDeleteMmrMutation: (
    projectId: number,
    employeeId: number,
    mmrId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, void>;

  useImportBulkMmrMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, FormData>;

  useCreateReportsMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyReportsExcel>;
}

export const useEpcmApiProjectEmployeeMutations = (): ApiDefinition => {
  const useCreateMmrMutation = (projectId: number, employeeId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyMmrCreate>({
      mutationFn: async (newMmr: ApiRequestBodyMmrCreate) => {
        return createMmr(projectId, employeeId, newMmr);
      },
      onSuccess: (data) => {
        console.log("on post create mmr mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create mmr mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useUpdateMmrMutation = (projectId: number, employeeId: number, mmrId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyMmrUpdate, unknown>({
      mutationFn: (updatedData: ApiRequestBodyMmrUpdate) => {
        return updateMmr(projectId, employeeId, mmrId, updatedData);
      },

      onSuccess: async (data) => {
        console.log("on post updated mmr mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post update mmr mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useDeleteMmrMutation = (projectId: number, employeeId: number, mmrId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void>({
      mutationFn: async () => {
        return deleteMmr(projectId, employeeId, mmrId);
      },
      onSuccess: (data) => {
        console.log("on post delete mmr mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post delete mmr mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useImportBulkMmrMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, FormData>({
      mutationFn: async (mmrFile: FormData) => {
        return createBulkMmrFile(projectId, mmrFile);
      },
      onSuccess: (data) => {
        console.log("on post import bulk mmr mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post import bulk mmr mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useCreateReportsMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyReportsExcel>({
      mutationFn: async (timeDates: ApiRequestBodyReportsExcel) => {
        return createReport(projectId, timeDates);
      },
      onSuccess: (data) => {
        console.log("on post create report mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create report mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  return {
    useCreateMmrMutation: useCreateMmrMutation,
    useUpdateMmrMutation: useUpdateMmrMutation,
    useDeleteMmrMutation: useDeleteMmrMutation,
    useImportBulkMmrMutation: useImportBulkMmrMutation,
    useCreateReportsMutation: useCreateReportsMutation,
  };
};
