import { Link, Outlet, useParams } from "react-router-dom";
import { useEpcmApiProjects } from "../../../../../apicalls/projects/useEpcmApiProjects.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { Skeleton } from "@mui/material";
import ConfigurationTitleStyles from "./ConfigurationTitle.module.css";
import closeButtonX from "../../../../../assets/images/configuration/close_x_icon.svg";
import backToConfigButtonImg from "../../../../../assets/images/configuration/backToConfigButtonImg.svg";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { BackRouteType } from "./BackRouteType.tsx";
import { ProjectContextType } from "../../../ProjectsUtils.ts";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";

export interface ConfigurationTitleProps {
  routeType: BackRouteType;
}

export const ConfigurationTitle = ({ routeType }: ConfigurationTitleProps) => {
  const { projectId } = useParams();
  const { getProject } = useEpcmApiProjects();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const projectQuery = useQuery({
    queryKey: [QueryNames.Projects, projectId],
    queryFn: () => getProject(parseInt(projectId!)),
    enabled: isAuthorized,
  });

  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const canListConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canUpdateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigUpdate);
  const canListTimesheets = canPerformProjectAction(ProjectAction.ProjectTimesheetListList);
  const canViewProjectEmployees = canPerformProjectAction(ProjectAction.ProjectEmployeeList);
  const canViewManpower = canPerformProjectAction(ProjectAction.ProjectManpowerList);

  const shouldHideBackButton = useMemo(() => {
    if (canListConfiguration && canUpdateConfiguration && !canListTimesheets && !canViewProjectEmployees && !canViewManpower) {
      return true;
    }

    return false;
  }, [canListConfiguration, canUpdateConfiguration, canListTimesheets, canViewProjectEmployees, canViewManpower]);

  const projectData = useMemo(() => projectQuery.data, [projectQuery.data]);

  const buttonImage = routeType === BackRouteType.BackToConfig ? backToConfigButtonImg : closeButtonX;

  const isBackRouteTypeConfig = useMemo(() => {
    return routeType === BackRouteType.BackToConfig;
  }, [routeType]);

  return (
    <div
      className={classNames(
        ConfigurationTitleStyles.container,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      {projectData ? (
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
          <div className={classNames(ConfigurationTitleStyles.pageHeader, GlobalStyles.centerVertical)}>{projectData.name}</div>
          <div className={classNames(ConfigurationTitleStyles.pageHeader, GlobalStyles.centerVertical)}>Configuration</div>
          <div className={classNames(GlobalStyles.flex3)} />
          {isBackRouteTypeConfig && !canListConfiguration ? (
            <></>
          ) : (
            !shouldHideBackButton && (
              <Link to={routeType === BackRouteType.BackToConfig ? "." : ".."} className={classNames(GlobalStyles.elementWithCursor)}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
                  <div className={classNames(ConfigurationTitleStyles.exitText)}>{routeType}</div>
                  <div
                    className={classNames(
                      routeType !== BackRouteType.BackToConfig
                        ? ConfigurationTitleStyles.backRouteContainerBlack
                        : ConfigurationTitleStyles.backRouteContainer,
                      GlobalStyles.centerHorizontal,
                      GlobalStyles.centerVertical,
                      GlobalStyles.flex,
                    )}
                  >
                    <img className={classNames(GlobalStyles)} src={buttonImage} alt="back button" />
                  </div>
                </div>
              </Link>
            )
          )}
        </div>
      ) : (
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
          <Skeleton variant="rounded" height={30} width={250} />
          <Skeleton variant="rounded" height={55} width={480} />
          <Skeleton variant="rounded" height={55} width={55} />
        </div>
      )}
      {projectData && <Outlet context={{ currentProject: projectData, searchQuery: "" } satisfies ProjectContextType} />}
    </div>
  );
};
