import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC } from "react";
import ChangeManpowerPlanStatusFollowupPopupStyles from "./ChangeManpowerPlanStatusFollowupPopup.module.css";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import checkIcon from "../../../../assets/images/tick-black.svg";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import { ManpowerPlanStatusEnum } from "../../../../types/projects/ManpowerPlanTypes.ts";

interface ChangeManpowerPlanStatusFollowupPopupProps extends Omit<PopupType, "headerText"> {
  manpowerPlanStatus: ManpowerPlanStatusEnum;
  onOpenReferenceFilesPopup: () => void;
}

export const ChangeManpowerPlanStatusFollowupPopup: FC<ChangeManpowerPlanStatusFollowupPopupProps> = ({
  isOpen,
  closeFn,
  manpowerPlanStatus,
  onOpenReferenceFilesPopup,
}) => {
  return (
    <PopupStructure
      popupButtons={
        manpowerPlanStatus === ManpowerPlanStatusEnum.pending
          ? [
              {
                text: "Close",
                buttonType: PopupButtonTypeEnum.neutral,
                action: closeFn,
              },
            ]
          : [
              {
                text: "Maybe later",
                buttonType: PopupButtonTypeEnum.neutral,
                action: closeFn,
              },
              {
                text: "Upload",
                buttonType: PopupButtonTypeEnum.main,
                action: () => {
                  closeFn();
                  onOpenReferenceFilesPopup();
                },
              },
            ]
      }
      isOpen={isOpen}
      closeFn={closeFn}
    >
      <div
        className={classNames(
          ChangeManpowerPlanStatusFollowupPopupStyles.resultContentContainer,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap3,
        )}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img src={checkIcon} alt="check" className={classNames(ChangeManpowerPlanStatusFollowupPopupStyles.checkIconImg)} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
          <div className={classNames(ChangeManpowerPlanStatusFollowupPopupStyles.resultContentMainText)}>{"Status changed successfully"}</div>
          <div className={classNames(GlobalStyles.flex)}>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(ChangeManpowerPlanStatusFollowupPopupStyles.resultContentSecondaryText)}>
              {"Would you like to upload reference projectuserdocuments?"}
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
        </div>
      </div>
    </PopupStructure>
  );
};
