import classNames from "classnames";
import GlobalStyles from "../../../assets/css/GlobalStyles.module.css";
import ProjectStyles from "./Project.module.css";
import { Link, NavLink, Outlet, UIMatch, useLocation, useMatches, useParams } from "react-router-dom";
import { ProjectMenuHeaderEnum, ProjectMenuItemType } from "../../../types/projects/ProjectsTypes.ts";
import { AppRoutesPaths, TimesheetsExtraRouteHandleData } from "../../../types/AppRoutesTypes.ts";
import { ProjectContextType } from "../ProjectsUtils.ts";
import { useQuery } from "@tanstack/react-query";
import { QueryNames } from "../../../types/apicallstypes/queryCommons.ts";
import { useCallback, useMemo, useState } from "react";
import { useEpcmApiProjects } from "../../../apicalls/projects/useEpcmApiProjects.ts";
import { useImpersonationStore } from "../../../store/use-impersonation-store.ts";
import { Skeleton } from "@mui/material";
import { SearchBar } from "../../../ui/searchbar/SearchBar.tsx";
import editProjectIcon from "../../../assets/images/project-gear-icon.svg";
import arrowBackIconHoverd from "../../../assets/images/arrow-left-white.svg";
import arrowBackIcon from "../../../assets/images/arrow-left-black.svg";
import { useRetrieveUserPermittedActions } from "../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../types/Roles.ts";

const projectMenuHeaders: ProjectMenuItemType[] = [
  {
    title: ProjectMenuHeaderEnum.projectEmployees,
    url: AppRoutesPaths.projectSingleEmployees,
  },
  {
    title: ProjectMenuHeaderEnum.manpowerPlan,
    url: AppRoutesPaths.projectSingleManpowerPlan,
  },
  {
    title: ProjectMenuHeaderEnum.timesheets,
    url: AppRoutesPaths.projectSingleTimesheets,
  },
  {
    title: ProjectMenuHeaderEnum.documents,
    url: AppRoutesPaths.projectSingleDocuments,
  },
];

export const ProjectTabs = () => {
  const { projectId } = useParams();
  const { getProject } = useEpcmApiProjects();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isBackButtonHovered, setIsBackButtonHovered] = useState<boolean>(false);
  const location = useLocation();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const canListConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canListProjectEmployees = canPerformProjectAction(ProjectAction.ProjectEmployeeList);
  const canListTimesheets = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupList);
  const canListManpowerPlan = canPerformProjectAction(ProjectAction.ProjectManpowerList);
  const canListDocuments = canPerformProjectAction(ProjectAction.ProjectListDocuments);

  const projectQuery = useQuery({
    queryKey: [QueryNames.Projects, projectId],
    queryFn: () => getProject(parseInt(projectId!)),
    enabled: isAuthorized,
  });

  const projectData = useMemo(() => {
    return projectQuery.data;
  }, [projectQuery.data]);

  const searchBarPlaceholder: string | undefined = useMemo(() => {
    const pathSegments = location.pathname.split("/");

    if (pathSegments.includes(AppRoutesPaths.projectSingleEmployees)) {
      return "Search project employees...";
    } else if (pathSegments.includes(AppRoutesPaths.projectSingleTimesheets)) {
      return "Search employees, section heads etc...";
    } else {
      return undefined;
    }
  }, [location]);

  const canShowSearchBarInCurrentTab = useMemo(() => {
    const pathSegments = location.pathname.split("/");

    if (pathSegments.includes(AppRoutesPaths.projectSingleEmployees)) {
      if (canListProjectEmployees) {
        return true;
      }
      return false;
    }
    if (pathSegments.includes(AppRoutesPaths.projectSingleTimesheets)) {
      if (canListTimesheets) return true;
    }
    return false;
  }, [location, canListProjectEmployees, canListTimesheets]);

  const canShowNavigationUrl = (menuItem: ProjectMenuItemType) => {
    if (menuItem.url === AppRoutesPaths.projectSingleEmployees) {
      if (canListProjectEmployees) {
        return true;
      }
    }
    if (menuItem.url === AppRoutesPaths.projectSingleTimesheets) {
      if (canListTimesheets) {
        return true;
      }
    }
    if (menuItem.url === AppRoutesPaths.projectSingleManpowerPlan) {
      if (canListManpowerPlan) {
        return true;
      }
    }
    if (menuItem.url === AppRoutesPaths.projectSingleDocuments) {
      if (canListDocuments) {
        return true;
      }
    }
    return false;
  };

  const matches = useMatches() as UIMatch<unknown, TimesheetsExtraRouteHandleData>[];

  const parentPath = useMemo(() => {
    const segments = location.pathname.split("/");
    segments.pop(); // Remove the last segment of the url
    if (segments[1] === AppRoutesPaths.projects && !isNaN(Number(segments[2]))) {
      //Test in the case that the parent router  is /projects/${projectId} as in that case i need to go one more level up
      segments.pop();
    }
    return segments.join("/");
  }, [location.pathname]);

  const properBackRoute = useMemo(() => {
    if (matches.length > 0) {
      if (matches.find((match) => match.handle?.isProjectEmployees)?.handle?.isProjectEmployees && canListProjectEmployees) {
        return "..";
      }
      if (matches.find((match) => match.handle?.isTimesheets)?.handle?.isTimesheets && canListTimesheets) {
        return parentPath;
      }
      if (matches.find((match) => match.handle?.isManpowerPlan)?.handle?.isManpowerPlan && canListManpowerPlan && !canListProjectEmployees) {
        return "..";
      }
      if (matches.find((match) => match.handle?.isDocuments)?.handle?.isDocuments && canListDocuments) {
        return "..";
      }
    }
    return `/${AppRoutesPaths.projects}`;
  }, [matches, canListProjectEmployees, canListTimesheets, canListManpowerPlan, canListDocuments, parentPath]);

  const projectHeaderSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
      <div className={classNames(GlobalStyles.centerVertical)}>
        <Skeleton variant="rounded" height={30} width={250} />
      </div>
      <div className={classNames(GlobalStyles.flex1)} />
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap2)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={55} width={480} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={55} width={55} />
        </div>
      </div>
    </div>
  );

  const projectPageSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap5, GlobalStyles.overflowHiddenFullHeight)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={180} width={170} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={180} width={150} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={180} width={150} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={170} width={550} />
        </div>
      </div>
    </div>
  );

  const onSearchQueryChange = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
      {projectData ? (
        <div className={classNames(GlobalStyles.flex1, GlobalStyles.gap025, GlobalStyles.centerVertical)}>
          <div className={classNames(ProjectStyles.pageHeaderContainer, GlobalStyles.flex, GlobalStyles.gap)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
              <Link className={classNames(GlobalStyles.centerVertical)} to={properBackRoute} replace>
                <div
                  className={classNames(ProjectStyles.pageHeaderIcon, GlobalStyles.centerVertical)}
                  onMouseEnter={() => setIsBackButtonHovered(true)}
                  onMouseLeave={() => setIsBackButtonHovered(false)}
                >
                  <img
                    className={classNames(ProjectStyles.pageHeaderIconImg)}
                    src={isBackButtonHovered ? arrowBackIconHoverd : arrowBackIcon}
                    alt="back"
                  />
                </div>
              </Link>
            </div>
            <div className={classNames(ProjectStyles.pageHeader, GlobalStyles.centerVertical)}>{projectData.name}</div>
            <div className={classNames(GlobalStyles.flex3)} />
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerBoth, GlobalStyles.gap)}>
              {searchBarPlaceholder ? (
                canShowSearchBarInCurrentTab && (
                  <div>
                    <SearchBar placeholder={searchBarPlaceholder} setSearchQuery={onSearchQueryChange} />
                  </div>
                )
              ) : (
                <div className={classNames(GlobalStyles.flex1)} />
              )}
              {canListConfigurations && (
                <Link className={classNames(ProjectStyles.headerActionButton, GlobalStyles.centerVertical)} to={`${AppRoutesPaths.configurations}`}>
                  <img className={classNames(ProjectStyles.editProjectIconImg)} src={editProjectIcon} alt="edit" />
                </Link>
              )}
            </div>
          </div>
        </div>
      ) : (
        projectHeaderSkeletonLoader
      )}
      <div className={classNames(ProjectStyles.projectMenuContainer, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap2)}>
        {projectMenuHeaders.map((projectMenuItem) => {
          if (!canShowNavigationUrl(projectMenuItem)) {
            return null;
          }
          return (
            <NavLink to={`./${projectMenuItem.url}`} key={projectMenuItem.title}>
              {({ isActive }) => (
                <div className={classNames(ProjectStyles.projectMenuItem, isActive && ProjectStyles.projectMenuItemActive)}>
                  {projectMenuItem.title}
                </div>
              )}
            </NavLink>
          );
        })}
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
        {projectData ? (
          <Outlet context={{ currentProject: projectData, searchQuery: searchQuery } satisfies ProjectContextType} />
        ) : (
          projectPageSkeletonLoader
        )}
      </div>
    </div>
  );
};
