import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

const deleteUserFromProjectPermissions = async (projectId: number, userId: string) => {
  const config = {
    url: `${API}/${projectId}/users/${userId}`,
    method: "DELETE",
  } as AxiosRequestConfig;
  return await epcmAxiosInstance(config);
};

export default deleteUserFromProjectPermissions;
