import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import EmployeeLanguagesInfoStyles from "./EmployeeLanguagesInfo.module.css";
import { FC } from "react";

interface EmployeeLanguagesInfoProps {
  employeeLanguages: string[];
}

export const EmployeeLanguagesInfo: FC<EmployeeLanguagesInfoProps> = ({ employeeLanguages }) => {
  return (
    <div
      className={classNames(
        EmployeeLanguagesInfoStyles.container,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap075,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div className={classNames(EmployeeLanguagesInfoStyles.secondaryText)}>{"Languages"}</div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexWrap)}>
        {employeeLanguages.map((languageItem, index) => (
          <div key={index} className={classNames(EmployeeLanguagesInfoStyles.languageContainer)}>
            {languageItem}
          </div>
        ))}
      </div>
    </div>
  );
};
