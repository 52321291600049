import { FC, SetStateAction } from "react";
import classNames from "classnames";
import checkIcon from "../../../../../assets/images/tick-white.svg";
import EpcmDatePickerMui from "../../../../../ui/epcmdatepickermui/EpcmDatepickerMui.tsx";
import { UiComponentGeneralEnum } from "../../../../../types/UiComponentGeneralType.ts";
import { MuiDateFormatEnum } from "../../../../../utils/DateManipulation.ts";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import LocationHolidayItemStyles from "./LocationHolidayItem.module.css";
import { FrontendProjectLocationLimited } from "../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import deleteIcon from "../../../../../assets/images/x-mark-red-bg.svg";

interface LocationHolidayItemProps {
  holidayDate: Date | null;
  setHolidayDate: (date: SetStateAction<Date | null>) => void;
  deleteHolidayItem: () => void;
  holidayType: string;
  onHolidayTypeChange: (type: string) => void;
  isCheckSelected: boolean;
  setIsCheckSelected: (isSelected: boolean) => void;
  locationIds: number[];
  setLocationIds: (ids: number[]) => void;
  selectedLocations: FrontendProjectLocationLimited[];
  projectEndDate: Date;
}

const LocationHolidayItem: FC<LocationHolidayItemProps> = ({
  selectedLocations,
  holidayDate,
  setHolidayDate,
  deleteHolidayItem,
  locationIds,
  setLocationIds,
  holidayType,
  onHolidayTypeChange,
  isCheckSelected,
  setIsCheckSelected,
  projectEndDate,
}) => {
  const handleIndividualSelectionChange = (locationId: number) => {
    const updatedLocationIds = locationIds.includes(locationId) ? locationIds.filter((id) => id !== locationId) : [...locationIds, locationId];
    setLocationIds(updatedLocationIds);
  };

  const handleIsCheckSelectedChange = () => {
    if (isCheckSelected) {
      setLocationIds(selectedLocations.map((location) => location.id));
    }
    setIsCheckSelected(!isCheckSelected);
  };

  return (
    <div
      className={classNames(
        LocationHolidayItemStyles.containerDiv,
        GlobalStyles.flex,
        GlobalStyles.overflowHiddenFullHeight,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.centerHorizontal)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
          <EpcmDatePickerMui
            label="Holiday Date"
            date={holidayDate}
            setDate={(date) => date && setHolidayDate(date)}
            theme={UiComponentGeneralEnum.SECONDARY}
            placeholder="dd-mm-yyyy"
            formatType={MuiDateFormatEnum.DD_MMM_COMMA_YYYY}
            maxDate={projectEndDate}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={LocationHolidayItemStyles.secondaryLabelText}>Holiday Title</div>
          <input
            value={holidayType}
            onChange={(event) => onHolidayTypeChange(event.target.value)}
            placeholder="Holiday title or description ..."
            className={LocationHolidayItemStyles.inputContainer}
          />
        </div>

        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(LocationHolidayItemStyles.hiddenText)}>D</div>
          <div
            onClick={deleteHolidayItem}
            className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.overflowHiddenFullHeight)}
          >
            <img src={deleteIcon} alt="delete" className={LocationHolidayItemStyles.deleteIcon} />
          </div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionRow, GlobalStyles.gap)}>
        <div
          className={classNames(LocationHolidayItemStyles.checkBoxContainer, GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.centerHorizontal)}
          onClick={handleIsCheckSelectedChange}
        >
          <div
            className={classNames(
              LocationHolidayItemStyles.checkBox,
              isCheckSelected && LocationHolidayItemStyles.checkBoxChecked,
              GlobalStyles.centerVertical,
            )}
          >
            <div className={GlobalStyles.centerVertical}>
              <img src={checkIcon} alt="check" className={LocationHolidayItemStyles.checkIconImg} />
            </div>
          </div>
          <div>Applies to All Locations</div>
        </div>
        {!isCheckSelected &&
          selectedLocations.map((selectedLocation) => (
            <div
              key={selectedLocation.id}
              className={classNames(
                LocationHolidayItemStyles.checkBoxContainer,
                GlobalStyles.flex,
                GlobalStyles.gap05,
                GlobalStyles.centerHorizontal,
              )}
              onClick={() => handleIndividualSelectionChange(selectedLocation.id)}
            >
              <div
                className={classNames(
                  LocationHolidayItemStyles.checkBox,
                  locationIds.includes(selectedLocation.id) && LocationHolidayItemStyles.checkBoxChecked,
                  GlobalStyles.centerVertical,
                )}
              >
                <div className={GlobalStyles.centerVertical}>
                  <img src={checkIcon} alt="check" className={LocationHolidayItemStyles.checkIconImg} />
                </div>
              </div>
              <div>{selectedLocation.description}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default LocationHolidayItem;
