export enum ExtensionConstraintEnum {
  pdf = "pdf",
  docx = "docx",
  xlsx = "xlsx",
  xls = "xls",
}

enum FileTypeEnum {
  pdf = "application/pdf",
  docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls = "application/vnd.ms-excel",
}

export enum DnDErrorsEnum {
  maxNumber = "Exceeded maximum number of files",
  extension = "The selected files must be one of the following types",
}

export const extensionsMap: Map<ExtensionConstraintEnum, FileTypeEnum> = new Map([
  [ExtensionConstraintEnum.pdf, FileTypeEnum.pdf],
  [ExtensionConstraintEnum.docx, FileTypeEnum.docx],
  [ExtensionConstraintEnum.xlsx, FileTypeEnum.xlsx],
  [ExtensionConstraintEnum.xls, FileTypeEnum.xls],
]);

export interface ConstraintListType {
  validExtensions?: ExtensionConstraintEnum[];
  maxNumberOfFiles?: number;
}
