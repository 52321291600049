import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { EpcmMutationOptionsType } from "../../../config";
import { ApiRequestBodyCalendarCreate } from "epcm-common/dist/Types/CalendarTypes";
import { createNewCalendar } from "./createNewCalendar";
import { ErrorCallbackDataType, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons";

interface ApiDefinition {
  useCreateNewCalendarMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<unknown, unknown, ApiRequestBodyCalendarCreate, unknown>;
}

export const useEpcmApiProjectCalendarMutations = (): ApiDefinition => {
  const useCreateNewCalendarMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyCalendarCreate, unknown>({
      mutationFn: async (calendarData: ApiRequestBodyCalendarCreate) => {
        return createNewCalendar(projectId, calendarData);
      },
      onSuccess: async (data) => {
        console.log("on post create new calendar mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create new calendar mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };
  return {
    useCreateNewCalendarMutation,
  };
};
