import { FrontendProjectGroupEmployeeLimitedType } from "../../../../ProjectEmployeesApiTypes.ts";
import { QueryModelWithCount } from "../../../../queryCommons.ts";
import { EntityCountType } from "../../../../../GeneralTypes.ts";
import { TimesheetGroupProjectEmployee } from "epcm-common/dist/Types/TimesheetGroupTypes";

export const convertProjectTimesheetGroupsEmployeesData = (
  projectTimesheetGroupsEmployeesData: TimesheetGroupProjectEmployee[],
): FrontendProjectGroupEmployeeLimitedType[] => {
  return projectTimesheetGroupsEmployeesData.map((projectTimesheetGroupsEmployeesItem): FrontendProjectGroupEmployeeLimitedType => {
    return {
      id: projectTimesheetGroupsEmployeesItem.id,
      employeeId: projectTimesheetGroupsEmployeesItem.employeeId,
      canBeDeleted: projectTimesheetGroupsEmployeesItem.canBeDeleted,
    };
  });
};

export const convertProjectTimesheetGroupsEmployeesCount = (projectTimesheetGroupsEmployeesCount: QueryModelWithCount): EntityCountType => {
  return {
    count: Number(projectTimesheetGroupsEmployeesCount.count),
  };
};
