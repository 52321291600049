import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import checkIcon from "../../../../assets/images/tick-white.svg";
import InfoMessageIcon from "../../../../assets/images/info-message-icon.svg";
import ConfigurationSetUpCalendarPopupStyles from "../configurationsetupcalendarpopup/ConfigurationSetUpCalendarPopup.module.css";
import { FrontendProjectLocationLimited } from "../../../../types/apicallstypes/ProjectsUtilsApiTypes";
import InfiniteScrollInViewElement from "../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import plusIcon from "../../../../assets/images/add-icon-circled-blue.svg";
import { QueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { PAGINATION_PAGE_SIZE } from "../../../../apicalls/config.ts";
import { useEpcmApiProjectsUtils } from "../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import ConfigurationLocationsPopup from "../../../configurations/popups/configurationlocationspopup/ConfigurationLocationsPopup.tsx";
import { FrontendHolidayItem } from "../../../../types/projects/CalendarTypes.ts";

interface ConfigurationSetUpCalendarLocationsPopupProps {
  selectedLocations: FrontendProjectLocationLimited[];
  setSelectedLocations: Dispatch<SetStateAction<FrontendProjectLocationLimited[]>>;
  unselectedLocations: FrontendProjectLocationLimited[];
  setHolidayItems: Dispatch<SetStateAction<FrontendHolidayItem[]>>;
  setUnselectedLocations: Dispatch<SetStateAction<FrontendProjectLocationLimited[]>>;
  updateProjectLocations: (newLocation: FrontendProjectLocationLimited) => void;
}

const ConfigurationSetUpCalendarLocationsPopup: React.FC<ConfigurationSetUpCalendarLocationsPopupProps> = ({
  selectedLocations,
  unselectedLocations,
  setSelectedLocations,
  setHolidayItems,
  setUnselectedLocations,
  updateProjectLocations,
}) => {
  const { getAllProjectLocations } = useEpcmApiProjectsUtils();
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const { ref, inView } = useInView();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();

  const projectLocationsQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectLocations, projectId],
    queryFn: ({ pageParam }) => getAllProjectLocations(parseInt(projectId!), pageParam, undefined, PAGINATION_PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });
  const projectLocationsQueryData = useMemo(() => projectLocationsQuery.data?.pages.flatMap((page) => page.data) || [], [projectLocationsQuery.data]);

  const handleLocationClick = (location: FrontendProjectLocationLimited) => {
    setSelectedLocations((prevSelected) => {
      const isSelected = prevSelected.some((selectedLocation) => selectedLocation.id === location.id);
      const updatedSelectedLocations = isSelected
        ? prevSelected.filter((selectedLocation) => selectedLocation.id !== location.id)
        : [...prevSelected, location];
      console.log(`Updated Selected Locations are ${updatedSelectedLocations.map((location) => location.description)}`);

      setHolidayItems((prevHolidayItems) => {
        return prevHolidayItems.map((holidayItem) => ({
          ...holidayItem,
          locationIds: isSelected ? holidayItem.locationIds!.filter((id) => id !== location.id) : [...holidayItem.locationIds!, location.id],
        }));
      });

      setUnselectedLocations(
        isSelected ? [...unselectedLocations, location] : unselectedLocations.filter((unselectedLocation) => unselectedLocation.id !== location.id),
      );

      return updatedSelectedLocations;
    });
  };

  useEffect(() => {
    if (
      inView &&
      !projectLocationsQuery.isLoading &&
      !projectLocationsQuery.isFetching &&
      !projectLocationsQuery.isFetchingNextPage &&
      projectLocationsQuery.hasNextPage
    ) {
      projectLocationsQuery.fetchNextPage();
    }
  }, [inView, projectLocationsQuery]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectLocations, projectId] }).then(() => {
        console.log(`Query ${QueryNames.ProjectLocations} for ${projectId} cancelled`);
      });
    };
  }, [queryClient, projectId]);

  return (
    <>
      <div className={classNames(ConfigurationSetUpCalendarPopupStyles.descriptionText)}>
        Pick a location color to differentiate Location Types in the calendar, unselect Location Types to exclude them from the calendar.
      </div>
      <div className={classNames(GlobalStyles.flex03)}></div>
      <div
        className={classNames(
          ConfigurationSetUpCalendarPopupStyles.parentDiv,
          GlobalStyles.flex,
          GlobalStyles.gap2,
          GlobalStyles.flexDirectionColumn,
        )}
      >
        {projectLocationsQueryData.map((location) => (
          <React.Fragment key={location.id}>
            <div
              className={classNames(
                ConfigurationSetUpCalendarPopupStyles.checkBoxContainer,
                GlobalStyles.flex,
                GlobalStyles.gap05,
                GlobalStyles.centerHorizontal,
              )}
              onClick={() => handleLocationClick(location)}
            >
              <div
                className={classNames(
                  ConfigurationSetUpCalendarPopupStyles.checkBox,
                  selectedLocations.some((selectedLocation) => selectedLocation.id === location.id) &&
                    ConfigurationSetUpCalendarPopupStyles.checkBoxChecked,
                  unselectedLocations.some((unselectedLocation) => unselectedLocation.id === location.id) &&
                    ConfigurationSetUpCalendarPopupStyles.checkBoxUnchecked,
                  GlobalStyles.centerVertical,
                )}
              >
                {selectedLocations.some((selectedLocation) => selectedLocation.id === location.id) && (
                  <div className={classNames(GlobalStyles.centerVertical)}>
                    <img src={checkIcon} alt="check" className={classNames(ConfigurationSetUpCalendarPopupStyles.checkIconImg)} />
                  </div>
                )}
              </div>
              <div className={classNames(ConfigurationSetUpCalendarPopupStyles.secondaryText)}>{location.description}</div>
              <InfiniteScrollInViewElement
                key={"InfiniteScrollInViewElement"}
                reference={ref}
                infiniteQueryResult={projectLocationsQuery}
                loaderComponent={<></>}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className={classNames(GlobalStyles.flex3)}></div>
      <div
        className={classNames(ConfigurationSetUpCalendarPopupStyles.addLanguageButton, GlobalStyles.flex, GlobalStyles.gap05)}
        onClick={() => onOpenPopup(ProjectsPagePopups.addProjectLocation, popupHandler)}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img className={classNames(ConfigurationSetUpCalendarPopupStyles.plusIconImg)} src={plusIcon} alt="add" />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>{"Add Location"}</div>
      </div>
      <div className={classNames(GlobalStyles.flex1)}></div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
        <img src={InfoMessageIcon} alt="info message icon" />
        <div className={classNames(ConfigurationSetUpCalendarPopupStyles.infoText)}>{"You can change these settings later in Calendar Settings"}</div>
      </div>

      {popupHandler.get(ProjectsPagePopups.addProjectLocation)!.isOpen && (
        <ConfigurationLocationsPopup
          isOpen={popupHandler.get(ProjectsPagePopups.addProjectLocation)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.addProjectLocation, popupHandler)}
          headerText={popupHeaders.get(ProjectsPagePopups.addProjectLocation)}
          updateProjectLocations={updateProjectLocations}
        />
      )}
    </>
  );
};

export default ConfigurationSetUpCalendarLocationsPopup;
