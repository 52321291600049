import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { ManpowerPositionDetailed, ManpowerPositionLimited } from "epcm-common/dist/Types/ManpowerPositionTypes";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getManpowerPositionCode = async (
  id: number,
  manpowerPositionCodeId: number,
  detailLevel: DetailLevel = DetailLevel.DETAILED,
): Promise<ManpowerPositionDetailed | ManpowerPositionLimited> => {
  const params = new URLSearchParams();
  detailLevel && params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/manpower-position/${manpowerPositionCodeId}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  return response.data satisfies ManpowerPositionDetailed | ManpowerPositionLimited;
};
