import classNames from "classnames";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import { AppRoutesPaths } from "../../types/AppRoutesTypes.ts";
import { Link } from "react-router-dom";
import UnauthorizedStyles from "./Unauthorized.module.css";

const Unauthorized = () => {
  return (
    <div
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.overflowHiddenFullHeight,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.centerHorizontal,
        GlobalStyles.gap10,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.centerVertical, UnauthorizedStyles.title)}>
          Unauthorized
        </div>
        <div className={classNames(UnauthorizedStyles.subTitle)}>You are not authorized to view this page.</div>
      </div>

      <div className={classNames(UnauthorizedStyles.linkButton, GlobalStyles.elementWithCursor)}>
        <Link
          className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.centerHorizontal)}
          to={`/${AppRoutesPaths.projects}`}
        >
          Back to Projects
        </Link>
      </div>
    </div>
  );
};

export default Unauthorized;
