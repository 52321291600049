import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import MmrButtonRightBarStyles from "./MmrButtonRightBar.module.css";
import closeIcon from "../../../../../../assets/images/x-mark-white-lines.svg";
import { FC } from "react";
// import MmrDeleteUserIcon from "../../../../../../assets/images/mmr-delete-user-icon.svg";
// import DeleteProjectUser from "../../../../../../assets/images/delete-project-user-white.svg";

interface ButtonBarRightProps {
  onCloseFn: () => void;
}

export const MmrButtonRightBar: FC<ButtonBarRightProps> = ({ onCloseFn }) => {
  // const [isHovered, setIsHovered] = useState<boolean>(false);

  // hover detect
  return (
    <div className={classNames(MmrButtonRightBarStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      <div className={classNames(MmrButtonRightBarStyles.closeIcon, GlobalStyles.centerVertical)} onClick={onCloseFn}>
        <img className={classNames(MmrButtonRightBarStyles.closeIconImg)} src={closeIcon} alt="close" />
      </div>
      <div className={classNames(GlobalStyles.flex1)} />

      {/*<div*/}
      {/*  className={classNames(MmrButtonRightBarStyles.deleteIcon, GlobalStyles.centerVertical)}*/}
      {/*  onMouseEnter={() => setIsHovered(true)}*/}
      {/*  onMouseLeave={() => setIsHovered(false)}*/}
      {/*>*/}
      {/*  <img className={classNames(MmrButtonRightBarStyles.deleteIconImg)} src={isHovered ? DeleteProjectUser : MmrDeleteUserIcon} alt="delete" />*/}
      {/*</div>*/}
    </div>
  );
};
