import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import TimesheetReportsList from "./timesheetreportslist/TimesheetReportsList.tsx";
import { useMemo } from "react";
import TimesheetReportsStyles from "./TimesheetReportsStyles.module.css";
import { TIMESHEET_REPORTS_LIST } from "../../../../../types/projects/ProjectTimesheetReportTypes.ts";

const TimesheetReports = () => {
  const timesheetsReportList = useMemo(() => {
    return TIMESHEET_REPORTS_LIST;
  }, []);

  return (
    <div
      className={classNames(
        TimesheetReportsStyles.mainContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,

        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <TimesheetReportsList timesheetReportsList={timesheetsReportList} />
    </div>
  );
};
export default TimesheetReports;
