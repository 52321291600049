import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { ApiRequestBodyProjectCreate } from "epcm-common/dist/Types/ProjectTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const createProject = async (newProjectData: ApiRequestBodyProjectCreate) => {
  const config: AxiosRequestConfig = {
    url: `${API}`,
    method: "POST",
    data: newProjectData,
  } as AxiosRequestConfig;

  return epcmAxiosInstance(config);
};
