import { Dispatch, FC, SetStateAction } from "react";
import CreateMmrBottomBarStyles from "./CreateMmrBottomBar.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import MmrCancelPencilIcon from "../../../../../../../assets/images/create-mmr-cancel-icon.svg";
import { Fade, Tooltip } from "@mui/material";
import { ProjectEmployeeDetailsPopupTypeEnum } from "../../../../../../../types/projects/ProjectEmployeesTypes.ts";

interface CreateMmrBottomBarProps {
  setPopupType: Dispatch<SetStateAction<ProjectEmployeeDetailsPopupTypeEnum>>;
  setIsCloned: Dispatch<SetStateAction<boolean>>;
  isFormValid: boolean;
  issueNewMmrTooltipText: string;
  onSubmit: () => void;
  isEditMode: boolean;
}

const CreateMmrBottomBar: FC<CreateMmrBottomBarProps> = ({
  setPopupType,
  setIsCloned,
  isFormValid,
  issueNewMmrTooltipText,
  onSubmit,
  isEditMode,
}) => {
  return (
    <div className={classNames(CreateMmrBottomBarStyles.mainContainer, GlobalStyles.flex)}>
      <div className={classNames(CreateMmrBottomBarStyles.container, GlobalStyles.flex)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap075)}>
          <Tooltip TransitionComponent={Fade} title={issueNewMmrTooltipText} placement="top" arrow>
            <div
              className={classNames(
                CreateMmrBottomBarStyles.overtimeContainer,
                !isFormValid && CreateMmrBottomBarStyles.issueMmrDisabled,
                CreateMmrBottomBarStyles.issueMmrButtonContainer,
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
                isFormValid && GlobalStyles.elementWithCursor,
              )}
              onClick={() => {
                onSubmit();
              }}
            >
              {isEditMode ? `Update MMR` : `Issue New MMR`}
            </div>
          </Tooltip>
        </div>

        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap075)}>
          <div
            className={classNames(
              GlobalStyles.flex,
              CreateMmrBottomBarStyles.overtimeContainer,
              GlobalStyles.centerHorizontal,
              GlobalStyles.gap05,
              GlobalStyles.elementWithCursor,
              CreateMmrBottomBarStyles.cancelMmrButtonContainer,
            )}
            onClick={() => {
              setPopupType(ProjectEmployeeDetailsPopupTypeEnum.view);
              setIsCloned(false);
            }}
          >
            <div style={{ width: "1.5em", height: "1.5em" }} className={classNames(GlobalStyles.centerVertical, GlobalStyles.centerHorizontal)}>
              <img alt={"cancel-pencil-icon"} src={MmrCancelPencilIcon} className={classNames(CreateMmrBottomBarStyles.cancelIcon)} />
            </div>
            <div>{"Cancel"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateMmrBottomBar;
