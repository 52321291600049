import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import SearchBarSubHeaderStyles from "./SearchBarSubHeader.module.css";
import { FC } from "react";
import { SearchBar } from "../../../../../ui/searchbar/SearchBar.tsx";

interface SearchBarSubHeaderProps {
  onSearchQueryChange: (query: string) => void;
  subheaderTitle: string;
}
const SearchBarSubHeader: FC<SearchBarSubHeaderProps> = ({ onSearchQueryChange, subheaderTitle }) => {
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
      <div className={classNames(SearchBarSubHeaderStyles.ctrTitleItem)}>{subheaderTitle}</div>
      <div className={classNames(GlobalStyles.flex6, GlobalStyles.flex3)} />
      <div className={classNames(GlobalStyles.flex2)}>
        <SearchBar
          placeholder={`Search ${subheaderTitle} by code...`}
          setSearchQuery={onSearchQueryChange}
          searchBarContainerStyles={SearchBarSubHeaderStyles.searchInputContainer}
        />
      </div>
    </div>
  );
};
export default SearchBarSubHeader;
