import { PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ProjectEmployeeDetailsPopupStyles from "./ProjectEmployeeDetailsPopup.module.css";
import classNames from "classnames";
import { EPCMDialogCyan } from "../../../../ui/epcmdialog/EPCMDialogCyan.tsx";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { DialogContent, Skeleton } from "@mui/material";
import { ProjectEmployeeDetailsPopupTypeEnum } from "../../../../types/projects/ProjectEmployeesTypes.ts";

import { MmrButtonRightBar } from "./mmrversionactionsection/mmrbuttonrightbar/MmrButtonRightBar.tsx";
import { FrontendMmrDateStatusEnum, FrontendProjectEmployeeMmrDetailedType } from "../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { QueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjectEmployee } from "../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import GlobalEmployeeInfoSectionContainer from "./globalemployeeinfosectioncontainer/GlobalEmployeeInfoSectionContainer.tsx";

import { useProjectContext } from "../../ProjectsUtils.ts";
import MmrContentInfoSection from "./mmrversioninformationsection/mmrcontentinfosection/MmrContentInfoSection.tsx";
import CreateMmrSection from "./mmrversioninformationsection/createmmrelements/createmmrsection/CreateMmrSection.tsx";
import MmrVersionActionSection from "./mmrversionactionsection/MmrVersionActionSection.tsx";
import { useHandleUnauthorized } from "../../../../utils/use-handle-unauthorized.ts";

interface ProjectEmployeeDetailsPopupProps extends PopupType {
  employeeId: number;
  projectId: number;
  globalEmployeeId: number;
}

export const ProjectEmployeeDetailsPopup: FC<ProjectEmployeeDetailsPopupProps> = ({ isOpen, closeFn, employeeId, projectId, globalEmployeeId }) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { handleErrorRedirect } = useHandleUnauthorized();
  const { currentProject } = useProjectContext();
  const queryClient = useQueryClient();
  const { getProjectEmployeeMmr } = useEpcmApiProjectEmployee();
  const [popupType, setPopupType] = useState<ProjectEmployeeDetailsPopupTypeEnum>(ProjectEmployeeDetailsPopupTypeEnum.view);

  const [documentNumber, setDocumentNumber] = useState<string | null>(null);
  const [typeOfMobilization, setTypeOfMobilization] = useState<string | null>(null);
  const [anticipatedMobilizationDate, setAnticipatedMobilizationDate] = useState<Date | null>(null);
  const [actualMobilizationDate, setActualMobilizationDate] = useState<Date | null>(null);
  const [anticipatedDemobilizationDate, setAnticipatedDemobilizationDate] = useState<Date | null>(null);
  const [actualDemobilizationDate, setActualDemobilizationDate] = useState<Date | null>(null);
  const [justificationForRequest, setJustificationForRequest] = useState<string | null>(null);
  const [keyPersonnel, setKeyPersonnel] = useState<boolean>(false);
  const [subPositionId, setSubPositionId] = useState<number | undefined>(undefined);
  const [clientInterviewDate, setClientInterviewDate] = useState<Date | null>(null);
  const [approvalRecord, setApprovalRecord] = useState<string | null>(null);
  const [subcontractor, setSubcontractor] = useState<string | null>(null);
  const [mmrReference, setMmrReference] = useState<string | null>(null);
  const [disciplineId, setDisciplineId] = useState<number | null>(null);
  const [selectedManpowerPosition, setSelectedManpowerPosition] = useState<number | null>(null);
  const [selectedManpowerPositionName, setSelectedManpowerPositionName] = useState<string | null>(null);
  const [isCloned, setIsCloned] = useState<boolean>(false);
  const [selectedMmrId, setSelectedMmrId] = useState<number | null>(null);
  const [inCreateMode, setInCreateMode] = useState<boolean>(false);
  const [selectedMmrIdType, setSelectedMmrIdType] = useState<FrontendMmrDateStatusEnum | null>(null);

  const isViewOnlyMode = useMemo(() => {
    return popupType === ProjectEmployeeDetailsPopupTypeEnum.view;
  }, [popupType]);

  const isCreateMode = useMemo(() => {
    return popupType === ProjectEmployeeDetailsPopupTypeEnum.create;
  }, [popupType]);

  const mmrItemQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployeeMmrItem, projectId, employeeId, selectedMmrId],
    queryFn: () => getProjectEmployeeMmr(projectId, employeeId, selectedMmrId!, DetailLevel.DETAILED).catch(handleErrorRedirect),

    enabled: isAuthorized && !!selectedMmrId,
    select: (data) => data as FrontendProjectEmployeeMmrDetailedType,
  });
  const isEditMode = useMemo(() => {
    return popupType === ProjectEmployeeDetailsPopupTypeEnum.edit;
  }, [popupType]);

  const mmrItemQueryData = useMemo(() => {
    return mmrItemQuery.data ?? undefined;
  }, [mmrItemQuery]);

  const manpowerCodeId = useMemo(() => {
    return isCreateMode
      ? (selectedManpowerPosition ?? null) // Replace 'defaultCode' with a null so the request will not be enabled
      : (mmrItemQueryData?.manpowerPositionDetailed.id ?? null);
  }, [selectedManpowerPosition, mmrItemQueryData, isCreateMode, isCloned]);

  const manpowerCodeName = useMemo(() => {
    return isCreateMode
      ? (selectedManpowerPositionName ?? null) // Replace 'defaultCode' with a null so the request will not be enabled
      : (mmrItemQueryData?.manpowerPositionDetailed.code ?? null);
  }, [selectedManpowerPositionName, mmrItemQueryData, isCreateMode, isCloned]);

  const updateSelectedMmrInfo = useCallback(() => {
    if (isViewOnlyMode && mmrItemQueryData) {
      setDocumentNumber(mmrItemQueryData.agreementNumber);
      setTypeOfMobilization(mmrItemQueryData.typeOfMobilization);
      setAnticipatedMobilizationDate(mmrItemQueryData.anticipatedMobilizationDate);
      setActualMobilizationDate(mmrItemQueryData.actualMobilizationDate);
      setAnticipatedDemobilizationDate(mmrItemQueryData.anticipatedDemobilizationDate);
      setActualDemobilizationDate(mmrItemQueryData.actualDemobilizationDate);
      setJustificationForRequest(mmrItemQueryData.justificationForRequest);
      setKeyPersonnel(mmrItemQueryData.keyPersonnel);
      setSubPositionId(mmrItemQueryData.subPositionId ?? undefined);
      setClientInterviewDate(mmrItemQueryData.clientInterviewDate);
      setApprovalRecord(mmrItemQueryData.approvalRecord);
      setSubcontractor(mmrItemQueryData.subcontractor);
      setMmrReference(mmrItemQueryData.mmrReference);
      setDisciplineId(mmrItemQueryData.disciplineId);
      setInCreateMode(false);
    }
    if (isCreateMode && !isCloned && !inCreateMode) {
      setDocumentNumber(null);
      setTypeOfMobilization(null);
      setAnticipatedMobilizationDate(null);
      setActualMobilizationDate(null);
      setAnticipatedDemobilizationDate(null);
      setActualDemobilizationDate(null);
      setJustificationForRequest(null);
      setKeyPersonnel(false);
      setSelectedManpowerPosition(null);
      setSubPositionId(undefined);
      setClientInterviewDate(null);
      setApprovalRecord(null);
      setSubcontractor(null);
      setMmrReference(null);
      setSelectedManpowerPositionName(null);
      setSelectedManpowerPosition(null);
      setDisciplineId(null);
      setInCreateMode(true);
    }

    if (isCreateMode && isCloned && mmrItemQueryData && !inCreateMode) {
      setDocumentNumber(mmrItemQueryData.agreementNumber);
      setTypeOfMobilization(mmrItemQueryData?.typeOfMobilization);
      setAnticipatedMobilizationDate(mmrItemQueryData?.anticipatedMobilizationDate);
      setActualMobilizationDate(mmrItemQueryData?.actualMobilizationDate);
      setAnticipatedDemobilizationDate(mmrItemQueryData?.anticipatedDemobilizationDate);
      setActualDemobilizationDate(mmrItemQueryData?.actualDemobilizationDate);
      setJustificationForRequest(mmrItemQueryData?.justificationForRequest);
      setKeyPersonnel(mmrItemQueryData?.keyPersonnel);
      setSubcontractor(mmrItemQueryData?.subcontractor);
      setSelectedManpowerPosition(mmrItemQueryData?.manpowerPositionDetailed.id);
      setSubPositionId(mmrItemQueryData?.subPositionId ?? undefined);
      setClientInterviewDate(mmrItemQueryData?.clientInterviewDate);
      setApprovalRecord(mmrItemQueryData?.approvalRecord);
      setMmrReference(mmrItemQueryData?.mmrReference);
      setSelectedManpowerPositionName(mmrItemQueryData?.manpowerPositionDetailed.code);
      setDisciplineId(mmrItemQueryData?.disciplineId);
      setInCreateMode(true);
    }
  }, [isCreateMode, isViewOnlyMode, isCloned, mmrItemQueryData, inCreateMode]);

  const employeeProfileSkeletonLoader = (
    <div
      className={classNames(
        ProjectEmployeeDetailsPopupStyles.contentContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap025,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap15)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
          <Skeleton variant="rounded" height={"15%"} width={330} />
          <Skeleton variant="rounded" height={"85%"} width={330} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex2, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
          <Skeleton variant="rounded" height={"15%"} width={"100%"} />
          <Skeleton variant="rounded" height={"85%"} width={"100%"} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
          <Skeleton variant="rounded" height={"50%"} width={"100%"} />
          <Skeleton variant="rounded" height={"50%"} width={0} />
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex1)} />

        <Skeleton variant="rounded" height={50} width={150} />
      </div>
    </div>
  );

  useEffect(() => {
    return () => {
      selectedMmrId &&
        queryClient
          .cancelQueries({ queryKey: [QueryNames.ProjectEmployeeMmrItem, projectId, employeeId, selectedMmrId] })
          .then(() => console.log(`${QueryNames.ProjectEmployeeMmrItem} for project  ${projectId} and mmrId ${selectedMmrId} query canceled`));
    };
  }, [queryClient, employeeId, projectId, selectedMmrId]);

  useEffect(() => {
    if (!isViewOnlyMode && !isCreateMode) {
      return;
    }
    updateSelectedMmrInfo();
  }, [updateSelectedMmrInfo, isCreateMode, isViewOnlyMode]);

  return (
    <EPCMDialogCyan open={isOpen} onClose={closeFn} maxWidth={false}>
      {isCreateMode || isViewOnlyMode || isEditMode ? (
        <DialogContent
          className={classNames(
            ProjectEmployeeDetailsPopupStyles.contentContainer,
            isViewOnlyMode && ProjectEmployeeDetailsPopupStyles.viewOnlyContentContainer,
            GlobalStyles.flex,
            GlobalStyles.gap,
          )}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05, GlobalStyles.overflowHiddenFullHeight)}>
            <GlobalEmployeeInfoSectionContainer globalEmployeeId={globalEmployeeId} projectId={projectId} />
            {isViewOnlyMode && (
              <MmrContentInfoSection
                employeeId={employeeId}
                projectId={projectId}
                setIsCloned={setIsCloned}
                setPopupType={setPopupType}
                manpowerCodeName={manpowerCodeName}
                mmrDataItem={mmrItemQueryData}
                selectedMmrId={selectedMmrId}
                mmrDataLoading={mmrItemQuery.isLoading}
              />
            )}
            {(isCreateMode || isEditMode) && (
              <CreateMmrSection
                projectId={projectId}
                projectName={currentProject.name}
                employeeId={employeeId}
                isCloned={isCloned}
                manpowerCodeId={manpowerCodeId}
                manpowerCodeName={manpowerCodeName}
                actualMobilizationDate={actualMobilizationDate}
                setActualMobilizationDate={setActualMobilizationDate}
                actualDemobilizationDate={actualDemobilizationDate}
                setActualDemobilizationDate={setActualDemobilizationDate}
                anticipatedMobilizationDate={anticipatedMobilizationDate}
                setAnticipatedMobilizationDate={setAnticipatedMobilizationDate}
                anticipatedDemobilizationDate={anticipatedDemobilizationDate}
                setAnticipatedDemobilizationDate={setAnticipatedDemobilizationDate}
                approvalRecord={approvalRecord}
                setApprovalRecord={setApprovalRecord}
                subcontractor={subcontractor}
                setSubcontractor={setSubcontractor}
                clientInterviewDate={clientInterviewDate}
                setClientInterviewDate={setClientInterviewDate}
                keyPersonnel={keyPersonnel}
                setKeyPersonnel={setKeyPersonnel}
                typeOfMobilization={typeOfMobilization}
                setTypeOfMobilization={setTypeOfMobilization}
                documentNumber={documentNumber}
                setDocumentNumber={setDocumentNumber}
                justificationForRequest={justificationForRequest}
                setJustificationForRequest={setJustificationForRequest}
                subPositionId={subPositionId}
                setSubPositionId={setSubPositionId}
                setIsCloned={setIsCloned}
                setPopupType={setPopupType}
                setManpowerCodeName={setSelectedManpowerPositionName}
                setManpowerCodeId={setSelectedManpowerPosition}
                selectedMmrId={selectedMmrId}
                setSelectedMmrId={setSelectedMmrId}
                disciplineId={disciplineId}
                setDisciplineId={setDisciplineId}
                revisionNumber={mmrItemQueryData?.revisionNumber}
                isEditMode={isEditMode}
              />
            )}
            <MmrVersionActionSection
              projectId={projectId}
              employeeId={employeeId}
              mmrReference={mmrReference}
              popupType={popupType}
              setPopupType={setPopupType}
              selectedMmrId={selectedMmrId}
              setSelectedMmrId={setSelectedMmrId}
              setIsCloned={setIsCloned}
              selectedMmrIdType={selectedMmrIdType}
              setSelectedMmrIdType={setSelectedMmrIdType}
            />
          </div>
          {isViewOnlyMode && <MmrButtonRightBar onCloseFn={closeFn} />}
        </DialogContent>
      ) : (
        employeeProfileSkeletonLoader
      )}
    </EPCMDialogCyan>
  );
};
