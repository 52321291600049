import { useQuery, useQueryClient } from "@tanstack/react-query";

import { FC, useEffect, useMemo } from "react";
import classNames from "classnames";
import TimesheetGroupsTooltipStyles from "./TimesheetGroupsTooltip.module.css";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import { useEpcmProjectTimesheetAnalytics } from "../../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsanalytics/useEpcmProjectTimesheetAnalytics.ts";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { QueryModelWithCountAndData, QueryNames, SubQueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { convertDateToMilSecs } from "../../../../../../../../utils/DateManipulation.ts";
import { FrontendTimesheetGroupNotFinal } from "../../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { insertLineBreakAtLength } from "../../../../../../../../utils/StringLineBreakInsertion.ts";

interface TimesheetGroupsTooltipProps {
  projectId: number;
  periodStart: Date | null;
  periodEnd: Date | null;
}

const TimesheetGroupsTooltip: FC<TimesheetGroupsTooltipProps> = ({ projectId, periodStart, periodEnd }) => {
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllProjectTimesheetNotFinalGroups } = useEpcmProjectTimesheetAnalytics();

  const timesheetGroupsNotFinalQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsNotFinalGroups, projectId, periodStart, periodEnd, SubQueryNames.finalized],
    queryFn: () => getAllProjectTimesheetNotFinalGroups(projectId, convertDateToMilSecs(periodStart!), convertDateToMilSecs(periodEnd!), true, false),

    enabled: isAuthorized,
    select: (data) => data as QueryModelWithCountAndData<FrontendTimesheetGroupNotFinal>,
  });

  const timesheetGroupsNotFinalData = useMemo(() => timesheetGroupsNotFinalQuery.data?.data, [timesheetGroupsNotFinalQuery.data]);

  useEffect(() => {
    return () => {
      if (periodStart && periodEnd) {
        queryClient
          .cancelQueries({
            queryKey: [QueryNames.ProjectTimesheetAnalyticsNotFinalGroups, projectId, periodStart, periodEnd, SubQueryNames.finalized],
          })
          .then(() => {
            console.log(`Query with name ${QueryNames.ProjectTimesheetAnalyticsNotFinalGroups} for project ${projectId} was cancelled`);
          });
      }
    };
  }, [queryClient, projectId, periodStart, periodEnd]);

  return (
    <div
      className={classNames(TimesheetGroupsTooltipStyles.tooltipContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap005)}
    >
      {timesheetGroupsNotFinalData ? (
        timesheetGroupsNotFinalData.length > 0 &&
        timesheetGroupsNotFinalData.map((group, index) => {
          return (
            <div
              key={group.id + index}
              className={classNames(
                TimesheetGroupsTooltipStyles.textElement,
                TimesheetGroupsTooltipStyles.tooltipItemContainer,
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap025,
              )}
            >
              <div className={classNames(TimesheetGroupsTooltipStyles.subHeader)}>{group.name}</div>
              <pre className={classNames(TimesheetGroupsTooltipStyles.contentText)}>
                {insertLineBreakAtLength(group.message, 70)} {/* Assuming 100 is your desired max length before breaking */}
              </pre>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
};
export default TimesheetGroupsTooltip;
