import { FC } from "react";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import DownloadFileViewStyles from "./DownloadFileView.module.css";
import classNames from "classnames";
import successIcon from "../../../../../assets/images/tick-black.svg";

interface DownloadFileViewProps {}

export const DownloadFileView: FC<DownloadFileViewProps> = () => {
  return (
    <div className={classNames(DownloadFileViewStyles.downloadInfoContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
      <div className={classNames(GlobalStyles.flex1)} />
      <div className={classNames(GlobalStyles.centerVertical)}>
        <img src={successIcon} alt="result" className={classNames(DownloadFileViewStyles.downloadInfoIconImg)} />
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
        <div className={classNames(DownloadFileViewStyles.downloadInfoText)}>{"Download Finished"}</div>
        <div className={classNames(DownloadFileViewStyles.downloadInfoSecondaryText)}>{"Overtime Template ready!"}</div>
      </div>
      <div className={classNames(GlobalStyles.flex1)} />
    </div>
  );
};
