import { BaseErrorInterface } from "epcm-common/dist/Types/ErrorTypes";
import {
  convertBackendFileTransactionTypeToFrontend,
  FileTransactionType,
  FileUploadStatus,
  FrontendFileAction,
  ProjectFileNotificationMessage,
} from "../types/projects/FileTypes.ts";
import { EpcmSnackbarMessage, EpcmToastSeverityEnum } from "../types/EpcmToastProps.ts";
import { ApiResponseTypeEnum, QueryNames } from "../types/apicallstypes/queryCommons.ts";
import { readFileEventStream, validateSseResponse } from "./validateSseResponse.ts";
import { useQueryClient } from "@tanstack/react-query";
import { getFileEvents } from "../apicalls/projects/getFileEvents.ts"; // Adjust based on your notification context
import { useState } from "react";
import { FrontendManpowerPlanStates } from "../types/projects/ManpowerPlanTypes.ts";

interface ProcessFileEventsFunction {
  processFileEvents: (fileId: string) => Promise<void>;
}

const useEpcmProcessFileEvents = (
  fileAction: FrontendFileAction,
  projectId: string,
  isAuthorized: boolean,
  addNotification: (message: EpcmSnackbarMessage) => void,
  updateNotification: (message: EpcmSnackbarMessage) => void,
  onOpenResponseAlertPopup: () => void,
  setResponseType: React.Dispatch<React.SetStateAction<ApiResponseTypeEnum | null>>,
  setResponseObject: React.Dispatch<React.SetStateAction<BaseErrorInterface | null>>,
  setShowDownloadView?: React.Dispatch<React.SetStateAction<boolean>>,
  setIsExportTemplateLoading?: React.Dispatch<React.SetStateAction<boolean>>,
  setLoaderProgress?: React.Dispatch<React.SetStateAction<number>>,
  setFileId?: React.Dispatch<React.SetStateAction<string>>,
  setPlanState?: React.Dispatch<React.SetStateAction<string>>,
  setFileDatabaseId?: React.Dispatch<React.SetStateAction<number | undefined>>,
): ProcessFileEventsFunction => {
  const queryClient = useQueryClient();
  const [hasFailed, setHasFailed] = useState(false);

  const processFileEvents = async (fileId: string): Promise<void> => {
    setHasFailed(false);

    if (isAuthorized) {
      try {
        const response = await getFileEvents(parseInt(projectId), fileId);
        const reader = readFileEventStream(response);

        while (true) {
          const { value, done } = await reader.read();

          if (done) {
            void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectFiles, projectId] });
            break;
          }

          if (value) {
            const message = validateSseResponse(value)[0] as ProjectFileNotificationMessage;
            setIsExportTemplateLoading?.(true);
            if (message.fileId === fileId) {
              handleFileStatusUpdates(message, fileId);
            }
          }
        }

        if (hasFailed) {
          setIsExportTemplateLoading?.(false);
          setShowDownloadView?.(false);
        }
      } catch (error: unknown) {
        handleErrorResponse(error);
      }
    }
  };

  const handleFileStatusUpdates = (message: ProjectFileNotificationMessage, fileId: string) => {
    if (message.status === FileUploadStatus.inProgress && message.progress === 0) {
      setLoaderProgress?.(0);
      setFileDatabaseId?.(message.sseRequestId);
      addNotification({
        id: message.id,
        fileId: fileId,
        fileName: message.fileName,
        message: fileAction === FrontendFileAction.EXPORT ? `Generating ${message.fileType}...` : `Importing ${message.fileType} ...`,
        valid: false,
        severity: EpcmToastSeverityEnum.info,
        projectId: message.projectId,
        projectCode: message.projectCode,
        transactionType: convertBackendFileTransactionTypeToFrontend(message.transactionType)!,
        fileType: message.fileType,
        sseRequestId: message.sseRequestId,
      });
    }

    if (message.status === FileUploadStatus.inProgress && message.progress > 0 && message.progress < 100) {
      setLoaderProgress?.(message.progress);
      setFileDatabaseId?.(message.sseRequestId);
      updateNotification({
        id: message.id,
        fileId: fileId,
        fileName: message.fileName,
        message:
          fileAction === FrontendFileAction.EXPORT
            ? `Generating ${message.fileName} - ${message.progress}%`
            : `Importing ${message.fileName} - ${message.progress}%`,
        valid: false,
        severity: EpcmToastSeverityEnum.info,
        projectId: message.projectId,
        projectCode: message.projectCode,
        transactionType: convertBackendFileTransactionTypeToFrontend(message.transactionType)!,
        fileType: message.fileType,
        sseRequestId: message.sseRequestId,
      });
    }

    if (message.status === FileUploadStatus.failed && message.progress === 100) {
      setPlanState?.(FrontendManpowerPlanStates.ReadyToGenerate);
      setLoaderProgress?.(message.progress);
      setFileDatabaseId?.(message.sseRequestId);
      setIsExportTemplateLoading?.(false);
      handleErrorFileGeneration(fileId, message);
      setHasFailed(true);
    }

    if (message.status === FileUploadStatus.completed && message.progress === 100) {
      setLoaderProgress?.(message.progress);
      setFileId?.(fileId);
      setIsExportTemplateLoading?.(false);
      setPlanState?.(FrontendManpowerPlanStates.ReadyToDownload);
      setFileDatabaseId?.(message.sseRequestId);
      updateNotification({
        id: message.id,
        fileId: fileId,
        fileName: message.fileName,
        message:
          convertBackendFileTransactionTypeToFrontend(message.transactionType) === FileTransactionType.exported
            ? `${message.fileName} generated successfully`
            : `${message.fileName} imported successfully`,
        valid: true,
        severity: EpcmToastSeverityEnum.success,
        projectId: parseInt(projectId),
        projectCode: message.projectCode,
        transactionType: convertBackendFileTransactionTypeToFrontend(message.transactionType)!,
        fileType: message.fileType,
        sseRequestId: message.sseRequestId,
      });
    }
  };

  const handleErrorFileGeneration = (fileId: string, message: ProjectFileNotificationMessage) => {
    setShowDownloadView?.(false);
    setResponseType(ApiResponseTypeEnum.error);
    setResponseObject({
      status: 500,
      message:
        convertBackendFileTransactionTypeToFrontend(message.transactionType)! === FileTransactionType.exported
          ? `Error exporting ${message.fileType}`
          : `Error importing ${message.fileType}`,
    });
    onOpenResponseAlertPopup();
    setFileId?.("");
    setFileDatabaseId?.(undefined);
    updateNotification({
      id: message.id,
      fileId: fileId,
      fileName: message.fileName,
      message: fileAction === FrontendFileAction.EXPORT ? `Failed to generate ${message.fileType}` : `Failed to import ${message.fileType}`,
      valid: false,
      severity: EpcmToastSeverityEnum.error,
      projectId: parseInt(projectId),
      projectCode: message.projectCode,
      transactionType: convertBackendFileTransactionTypeToFrontend(message.transactionType)!,
      fileType: message.fileType,
      sseRequestId: message.sseRequestId,
    });
  };

  const handleErrorResponse = (error: unknown) => {
    setShowDownloadView?.(false);
    setFileDatabaseId?.(undefined);
    setResponseType(ApiResponseTypeEnum.error);
    setIsExportTemplateLoading?.(false);
    setFileId?.("");
    const errorResponse = error as BaseErrorInterface;
    setResponseObject(errorResponse);
    onOpenResponseAlertPopup();
  };

  return { processFileEvents };
};
export default useEpcmProcessFileEvents;
