import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC, useState } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ChangeManpowerPlanStatusPopupStyles from "./ChangeManpowerPlanStatusPopup.module.css";
import checkIcon from "../../../../assets/images/tick-white.svg";
import xMarkIcon from "../../../../assets/images/x-mark-white-lines.svg";
import uploadedIcon from "../../../../assets/images/upload-icon-black-lines.svg";
import checkCircledIcon from "../../../../assets/images/tick-circled.svg";
import xMarkCircledIcon from "../../../../assets/images/x-mark-circled.svg";
import userIcon from "../../../../assets/images/profile-blue-lines.svg";
import { capitalizeFirstLetter } from "../../../../utils/StringManipulation.ts";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import { ManpowerPlanStatusEnum, ManpowerPlanType } from "../../../../types/projects/ManpowerPlanTypes.ts";
import { formatDate } from "../../../../utils/DateManipulation.ts";
import { ChangeManpowerPlanStatusFollowupPopup } from "../changemanpowerplanstatusfollowuppopup/ChangeManpowerPlanStatusFollowupPopup.tsx";

interface ChangeManpowerPlanStatusPopupProps extends PopupType {
  currentStatus: ManpowerPlanStatusEnum;
  manpowerPlanItem: ManpowerPlanType;
  onChangeStatusFn: (manpowerPlanItem: ManpowerPlanType) => void;
  onOpenReferenceFilesPopup: () => void;
}

const STATUS_ARRAY: ManpowerPlanStatusEnum[] = [ManpowerPlanStatusEnum.approved, ManpowerPlanStatusEnum.pending, ManpowerPlanStatusEnum.rejected];

export const ChangeManpowerPlanStatusPopup: FC<ChangeManpowerPlanStatusPopupProps> = ({
  isOpen,
  closeFn,
  headerText,
  secondaryHeaderText,
  currentStatus,
  manpowerPlanItem,
  onChangeStatusFn,
  onOpenReferenceFilesPopup,
}) => {
  const { popupHandler, onOpenPopup } = useProjectsPopups();

  const [statusSelected, setStatusSelected] = useState<ManpowerPlanStatusEnum>(currentStatus);

  return (
    <>
      <PopupStructure
        popupButtons={[
          {
            text: "Cancel",
            buttonType: PopupButtonTypeEnum.neutral,
            action: closeFn,
          },
          {
            text: "Confirm",
            buttonType: PopupButtonTypeEnum.main,
            action: () => {
              if (statusSelected !== currentStatus) {
                onOpenPopup(ProjectsPagePopups.changeManpowerPlanStatusFollowup, popupHandler);
                onChangeStatusFn({ ...manpowerPlanItem, approvalStatus: statusSelected });
              }
            },
          },
        ]}
        isOpen={isOpen}
        closeFn={closeFn}
        headerText={headerText}
        secondaryHeaderText={secondaryHeaderText}
      >
        {STATUS_ARRAY.sort((a) => {
          if (a === currentStatus) {
            return -1;
          } else {
            return 1;
          }
        }).map((statusItem) => (
          <div
            key={statusItem}
            className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}
            onClick={() => setStatusSelected(statusItem)}
          >
            <div className={classNames(ChangeManpowerPlanStatusPopupStyles.statusContainer, GlobalStyles.flex, GlobalStyles.gap05)}>
              <div
                className={classNames(
                  ChangeManpowerPlanStatusPopupStyles.checkIcon,
                  statusItem === statusSelected &&
                    (statusSelected === ManpowerPlanStatusEnum.approved
                      ? ChangeManpowerPlanStatusPopupStyles.checkIconApproved
                      : statusSelected === ManpowerPlanStatusEnum.pending
                        ? ChangeManpowerPlanStatusPopupStyles.checkIconPending
                        : ChangeManpowerPlanStatusPopupStyles.checkIconRejected),
                  GlobalStyles.centerVertical,
                )}
              >
                <img
                  src={statusItem !== ManpowerPlanStatusEnum.rejected ? checkIcon : xMarkIcon}
                  alt="check"
                  className={classNames(ChangeManpowerPlanStatusPopupStyles.checkIconImg)}
                />
              </div>
              <div className={classNames(GlobalStyles.centerVertical)}>{capitalizeFirstLetter(statusItem)}</div>
            </div>
            <div
              className={classNames(
                ChangeManpowerPlanStatusPopupStyles.selectedStatusInfoContainer,
                statusSelected !== statusItem && ChangeManpowerPlanStatusPopupStyles.selectedStatusInfoContainerInvisible,
                GlobalStyles.flex,
                GlobalStyles.gap,
              )}
            >
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                <div className={classNames(GlobalStyles.centerVertical)}>
                  <img
                    src={
                      statusItem === ManpowerPlanStatusEnum.pending
                        ? uploadedIcon
                        : statusItem === ManpowerPlanStatusEnum.approved
                          ? checkCircledIcon
                          : xMarkCircledIcon
                    }
                    alt="fileUploadStatus"
                    className={classNames(ChangeManpowerPlanStatusPopupStyles.selectedStatusInfoImg)}
                  />
                </div>
                <div className={classNames(GlobalStyles.centerVertical)}>
                  {statusItem === ManpowerPlanStatusEnum.pending ? formatDate(manpowerPlanItem.dateUploaded) : "Just now"}
                </div>
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
                <div className={classNames(GlobalStyles.centerVertical)}>
                  <img src={userIcon} alt="user" className={classNames(ChangeManpowerPlanStatusPopupStyles.selectedStatusInfoImg)} />
                </div>
                <div className={classNames(GlobalStyles.centerVertical)}>
                  {statusItem === ManpowerPlanStatusEnum.pending ? manpowerPlanItem.uploadedBy.name : "By you"}
                </div>
              </div>
            </div>
          </div>
        ))}
      </PopupStructure>

      {popupHandler.get(ProjectsPagePopups.changeManpowerPlanStatusFollowup)!.isOpen && (
        <ChangeManpowerPlanStatusFollowupPopup
          isOpen={popupHandler.get(ProjectsPagePopups.changeManpowerPlanStatusFollowup)!.isOpen}
          closeFn={closeFn}
          manpowerPlanStatus={statusSelected}
          onOpenReferenceFilesPopup={onOpenReferenceFilesPopup}
        />
      )}
    </>
  );
};
