import classNames from "classnames";
import ConfigurationSetUpCalendarDatePopupStyles from "./ConfigurationSetUpCalendarDate.module.css";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import InfoMessageIcon from "../../../../assets/images/info-message-icon.svg";
import { Dispatch, SetStateAction } from "react";

interface ConfigurationSetUpCalendarDatePopupProps {
  selectedCutOffDay: number;
  setCutOffDay: Dispatch<SetStateAction<number>>;
}

const ConfigurationSetUpCalendarDatePopup = ({ selectedCutOffDay, setCutOffDay }: ConfigurationSetUpCalendarDatePopupProps) => {
  const daysArray = Array.from({ length: 30 }, (_, index) => index + 1);
  return (
    <>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.overflowHiddenFullHeight, GlobalStyles.flexDirectionColumn)}>
        <div className={classNames(ConfigurationSetUpCalendarDatePopupStyles.smallPadding)}>{"Select the month's Cut-Off date."}</div>
        <div className={classNames(ConfigurationSetUpCalendarDatePopupStyles.calendarContainer)}>
          {daysArray.map((day) => (
            <div
              key={day}
              className={classNames(
                GlobalStyles.flex,
                ConfigurationSetUpCalendarDatePopupStyles.day,
                GlobalStyles.elementWithCursor,
                selectedCutOffDay === day && ConfigurationSetUpCalendarDatePopupStyles.selected,
              )}
              onClick={() => setCutOffDay(day)}
            >
              {day}
            </div>
          ))}
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex1)}></div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
        <img src={InfoMessageIcon} alt="info message icon" />
        <div className={classNames(ConfigurationSetUpCalendarDatePopupStyles.infoText)}>
          {"You can change Cut-Off date later in Calendar Settings"}
        </div>
      </div>
    </>
  );
};

export default ConfigurationSetUpCalendarDatePopup;
