import { FC, useEffect, useMemo } from "react";
import { FrontendProjectDetailedType, FrontendProjectLimitedType } from "../../../../types/apicallstypes/ProjectsApiTypes.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjects } from "../../../../apicalls/projects/useEpcmApiProjects.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { Link } from "react-router-dom";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ProjectListItemStyles from "./ProjectListItem.module.css";
import projectItemIcon from "../../../../assets/images/project-icon-white-lines.svg";
import viewDetailsIcon from "../../../../assets/images/arrow-right-blue.svg";
import { formatDate } from "../../../../utils/DateManipulation.ts";
import { displayNumberWithSpecificNumberOfDigits } from "../../../../utils/NumberManipulation.ts";
import { Skeleton } from "@mui/material";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { capitalizeFirstLetter } from "../../../../utils/StringManipulation.ts";

interface ProjectListItemProps {
  projectItem: FrontendProjectLimitedType;
}

// const ZERO_OPACITY = "-invisible-";

export const ProjectListItem: FC<ProjectListItemProps> = ({ projectItem }) => {
  const { getProject } = useEpcmApiProjects();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const projectQuery = useQuery({
    queryKey: [QueryNames.Projects, projectItem.id],
    queryFn: () => getProject(projectItem.id, DetailLevel.DETAILED),
    enabled: isAuthorized,
  });
  const projectData: FrontendProjectDetailedType | undefined = useMemo(() => {
    return projectQuery.data as FrontendProjectDetailedType;
  }, [projectQuery.data]);

  const projectListItemSkeletonLoader = (
    <div className={classNames(ProjectListItemStyles.projectItemContainer, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap15)}>
        <Skeleton variant="rounded" height={60} width={60} />
        <div className={classNames(GlobalStyles.flex3, GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
        <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
        <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
        <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
        <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      <div className={classNames(GlobalStyles.centerVertical)}>
        <Skeleton variant="rounded" height={0} width={100} />
      </div>
    </div>
  );

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Projects, projectItem.id] })
        .then(() => console.log(`In project list item with id ${projectItem.id}, ${QueryNames.Projects} query canceled`));
    };
  }, [queryClient, projectItem]);

  return projectData ? (
    <Link to={`${projectData.id.toString()}`}>
      <div className={classNames(ProjectListItemStyles.projectItemContainer, GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <div className={classNames(GlobalStyles.flex1)} />
            <div
              className={classNames(
                ProjectListItemStyles.projectIcon,
                // DEPRECATED_projectItem.status === ProjectStatusEnum.active
                //   ?
                ProjectListItemStyles.projectIconActive,
                // : ProjectListItemStyles.projectIconInactive
                GlobalStyles.centerVertical,
              )}
            >
              <img className={classNames(ProjectListItemStyles.projectIconImg)} src={projectItemIcon} alt="project" />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div
            className={classNames(
              ProjectListItemStyles.projectItemPanel,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.centerVertical,
            )}
          >
            <div className={classNames(ProjectListItemStyles.projectItemPanelMainText, ProjectListItemStyles.projectItemName)}>
              {projectData.name}
            </div>
            <div className={classNames(ProjectListItemStyles.projectItemPanelSecondaryText)}>{projectData.code}</div>
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
          <div
            className={classNames(
              ProjectListItemStyles.projectItemPanel,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.centerVertical,
            )}
          >
            <div
              className={classNames(
                ProjectListItemStyles.projectItemPanelMainText,
                // DEPRECATED_projectItem.status === ProjectStatusEnum.inactive && ProjectListItemStyles.projectStatusInactive,
              )}
            >
              {/*{capitalizeFirstLetter(DEPRECATED_projectItem.status)}*/}
              {capitalizeFirstLetter(projectData.status)}
            </div>
            {/*<div className={classNames(ProjectListItemStyles.projectItemPanelSecondaryText, ProjectListItemStyles.invisiblePlaceHolder)}>*/}
            {/*  {ZERO_OPACITY}*/}
            {/*</div>*/}
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
          <div
            className={classNames(
              ProjectListItemStyles.projectItemPanel,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.centerVertical,
            )}
          >
            <div className={classNames(ProjectListItemStyles.projectItemPanelMainText)}>{formatDate(projectData.dateOfSign)}</div>
            {/*<div className={classNames(ProjectListItemStyles.projectItemPanelSecondaryText, ProjectListItemStyles.projectItemLink)}>*/}
            {/*  {"See Document"}*/}
            {/*</div>*/}
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
          <div
            className={classNames(
              ProjectListItemStyles.projectItemPanel,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.centerVertical,
            )}
          >
            <div className={classNames(ProjectListItemStyles.projectItemPanelMainText)}>
              {displayNumberWithSpecificNumberOfDigits(projectData.duration)}
            </div>
            {/*<div className={classNames(ProjectListItemStyles.projectItemPanelSecondaryText, ProjectListItemStyles.invisiblePlaceHolder)}>*/}
            {/*  {ZERO_OPACITY}*/}
            {/*</div>*/}
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
          <div
            className={classNames(
              ProjectListItemStyles.projectItemPanel,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.centerVertical,
            )}
          >
            {/*<div className={classNames(ProjectListItemStyles.projectItemPanelMainText)}>{projectData.creator.name}</div>*/}
            <div className={classNames(ProjectListItemStyles.projectItemPanelMainText)}>{projectData.creator.name}</div>
            {/*<div className={classNames(ProjectListItemStyles.projectItemPanelSecondaryText, ProjectListItemStyles.invisiblePlaceHolder)}>*/}
            {/*  {ZERO_OPACITY}*/}
            {/*</div>*/}
          </div>
        </div>
        <div className={classNames(ProjectListItemStyles.projectItemLink, GlobalStyles.flex, GlobalStyles.gap025)}>
          {/*<div*/}
          {/*  className={classNames(*/}
          {/*    ProjectListItemStyles.warningIcon,*/}
          {/*    !DEPRECATED_projectItem.isIncomplete && ProjectListItemStyles.warningIconInvisible,*/}
          {/*    GlobalStyles.flex1,*/}
          {/*    GlobalStyles.centerVertical,*/}
          {/*  )}*/}
          {/*>*/}
          {/*  <Tooltip TransitionComponent={Fade} title={DEPRECATED_projectItem.isIncomplete ? "Incomplete Configuration" : ""} placement="top" arrow>*/}
          {/*    <img className={classNames(ProjectListItemStyles.warningIconImg)} src={warningIcon} alt="warning" />*/}
          {/*  </Tooltip>*/}
          {/*</div>*/}
          <div className={classNames(GlobalStyles.centerVertical)}>View</div>
          <div className={classNames(ProjectListItemStyles.viewDetailsIcon, GlobalStyles.centerVertical)}>
            <img className={classNames(ProjectListItemStyles.viewDetailsIconImg)} src={viewDetailsIcon} alt="view" />
          </div>
        </div>
      </div>
    </Link>
  ) : (
    projectListItemSkeletonLoader
  );
};
