import classNames from "classnames";
import ConfigurationprojectInfoStyles from "./ConfigurationProjectInfo.module.css";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ViewProjectInfo from "./viewprojectinfo/ViewProjectInfo.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConfigurationInfoScreenType } from "../../../../types/projects/ConfigurationTypes.ts";
import EditProjectInfo from "./editprojectinfo/EditProjectInfo.tsx";
import EditProjectInfoIcon from "../../../../assets/images/configuration/edit-project-info.svg";
import DiscardChangesIcon from "../../../../assets/images/discard-changes-icon.svg";
import { useProjectContext } from "../../ProjectsUtils.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjects } from "../../../../apicalls/projects/useEpcmApiProjects.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { FrontendProjectDetailedType } from "../../../../types/apicallstypes/ProjectsApiTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import { useEpcmApiProjectsMutations } from "../../../../apicalls/projects/mutations/useEpcmApiProjectsMutations.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { Tooltip } from "@mui/material";
import { ApiRequestBodyProjectUpdate } from "epcm-common/dist/Types/ProjectTypes";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../types/Roles.ts";
import { convertFrontendProjectStatusToBackend } from "../../../../types/projects/ProjectsTypes.ts";

const ConfigurationProjectInfo = () => {
  const [mode, setMode] = useState<ConfigurationInfoScreenType>(ConfigurationInfoScreenType.view);
  const { currentProject } = useProjectContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getProject } = useEpcmApiProjects();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const { useUpdateProjectMutation } = useEpcmApiProjectsMutations();
  const queryClient = useQueryClient();
  const isViewMode = useMemo(() => mode === ConfigurationInfoScreenType.view, [mode]);
  const isEditMode = useMemo(() => mode === ConfigurationInfoScreenType.edit, [mode]);
  const [projectInfoObject, setProjectInfoObject] = useState<FrontendProjectDetailedType>();

  const projectDataQuery = useQuery({
    queryKey: [QueryNames.Projects, currentProject.id, DetailLevel.DETAILED],
    queryFn: () => getProject(currentProject.id, DetailLevel.DETAILED),
    enabled: isAuthorized,
    select: (data) => data as FrontendProjectDetailedType,
  });

  const projectData = useMemo(() => {
    return projectDataQuery.data;
  }, [projectDataQuery.data]);

  const toggleMode = () => {
    setMode((prevMode) => {
      if (prevMode === ConfigurationInfoScreenType.view) {
        return ConfigurationInfoScreenType.edit;
      }
      return ConfigurationInfoScreenType.view;
    });
  };

  const updateProjectInfoMutation = useUpdateProjectMutation(currentProject.id, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Project was updated successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const hasDataNoChanges = useMemo(() => {
    return (
      projectData?.code === projectInfoObject?.code &&
      projectData?.name === projectInfoObject?.name &&
      projectData?.status === projectInfoObject?.status &&
      projectData?.duration === projectInfoObject?.duration &&
      projectData?.projectLocation === projectInfoObject?.projectLocation &&
      projectData?.agreementNumber === projectInfoObject?.agreementNumber &&
      projectData?.projectManager?.code === projectInfoObject?.projectManager?.code &&
      projectData?.projectControlsManager?.code === projectInfoObject?.projectControlsManager?.code &&
      projectData?.projectCostControlEngineer?.code === projectInfoObject?.projectCostControlEngineer?.code &&
      projectData?.projectClientCostControlEngineer === projectInfoObject?.projectClientCostControlEngineer &&
      projectData?.projectClientControlsManager === projectInfoObject?.projectClientControlsManager &&
      projectData?.projectClientSeniorManager === projectInfoObject?.projectClientSeniorManager
    );
  }, [projectData, projectInfoObject]);

  const updateProjectInfo = useCallback(() => {
    if (projectInfoObject && projectData && !hasDataNoChanges) {
      updateProjectInfoMutation.mutate({
        code: projectInfoObject.code,
        name: projectInfoObject.name,
        status: convertFrontendProjectStatusToBackend(projectInfoObject.status),
        duration: projectInfoObject.duration,
        projectLocation: projectInfoObject.projectLocation,
        projectManagerCode: projectInfoObject.projectManager?.code ?? undefined,
        projectControlsManagerCode: projectInfoObject.projectControlsManager?.code ?? undefined,
        projectCostControlEngineerCode: projectInfoObject.projectCostControlEngineer?.code ?? undefined,
        projectClientCostControlEngineer: projectInfoObject.projectClientCostControlEngineer ?? undefined,
        projectClientControlsManager: projectInfoObject.projectClientControlsManager ?? undefined,
        projectClientSeniorManager: projectInfoObject.projectClientSeniorManager ?? undefined,
      } satisfies ApiRequestBodyProjectUpdate);
    }
    onOpenLoaderPopup();
  }, [projectInfoObject, updateProjectInfoMutation, onOpenLoaderPopup, projectData, hasDataNoChanges]);

  const handleSaveChanges = () => {
    if (mode === ConfigurationInfoScreenType.edit) {
      updateProjectInfo();
    }
  };

  const hasAnyFieldEmptyDuringEdit = useMemo(() => {
    if (projectInfoObject) {
      return (
        !projectInfoObject.code ||
        !projectInfoObject.name ||
        !projectInfoObject.duration ||
        projectInfoObject.duration === 0 ||
        !projectInfoObject.agreementNumber ||
        !projectInfoObject.projectLocation
      );
    }
    return false;
  }, [projectInfoObject]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.Projects, currentProject.id, DetailLevel.DETAILED] });
  }, [queryClient, currentProject.id]);

  const canListConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canEditConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigUpdate);

  useEffect(() => {
    if (mode === ConfigurationInfoScreenType.view && projectData) {
      setProjectInfoObject(projectData);
    } else {
      return;
    }
  }, [setProjectInfoObject, mode, projectData]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.Projects, currentProject.id, DetailLevel.DETAILED] });
    };
  }, [queryClient, currentProject.id]);

  return (
    <div
      className={classNames(
        ConfigurationprojectInfoStyles.mainContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.overflowAutoFullHeight,
        GlobalStyles.gap,
      )}
    >
      <div className={classNames(ConfigurationprojectInfoStyles.titleContainer, ConfigurationprojectInfoStyles.titleText)}>Project Info</div>

      {canListConfigurations && (
        <div
          className={classNames(
            ConfigurationprojectInfoStyles.firstContent,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap,
            GlobalStyles.overflowAutoFullHeight,
          )}
        >
          {isViewMode && projectInfoObject && <ViewProjectInfo projectInfoObject={projectInfoObject} />}
          {isEditMode && projectInfoObject && <EditProjectInfo projectInfoObject={projectInfoObject} setProjectInfoObject={setProjectInfoObject} />}
        </div>
      )}
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
      {canListConfigurations && canEditConfigurations && (
        <div className={classNames(ConfigurationprojectInfoStyles.actionBtnContainer)}>
          {isViewMode && (
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
              <div className={classNames(ConfigurationprojectInfoStyles.hiddenDiv)}>-</div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
              <div
                className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
                onClick={() => toggleMode()}
              >
                <div className={classNames()}>Edit Project Info</div>
                <div className={classNames(ConfigurationprojectInfoStyles.actionBtnImage)}>
                  <img src={EditProjectInfoIcon} alt={"edit-project-info icon"} />
                </div>
              </div>
            </div>
          )}
          {isEditMode && canEditConfigurations && (
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
              <Tooltip title={hasAnyFieldEmptyDuringEdit ? "Missing mandatory data" : hasDataNoChanges ? "No Changes" : ""} placement={"top"} arrow>
                <div
                  className={classNames(
                    hasAnyFieldEmptyDuringEdit || hasDataNoChanges
                      ? ConfigurationprojectInfoStyles.saveChangesButtonDisabled
                      : ConfigurationprojectInfoStyles.saveChangesButton,
                    GlobalStyles.elementWithCursor,
                  )}
                  onClick={handleSaveChanges}
                >
                  Save Changes
                </div>
              </Tooltip>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
              <div
                onClick={() => {
                  toggleMode();
                }}
                className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075, GlobalStyles.elementWithCursor)}
              >
                <div>Discard Changes</div>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical)}>
                  <img src={DiscardChangesIcon} alt={"discard-changes-icon"}></img>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              toggleMode();
              invalidateQueries();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </div>
  );
};
export default ConfigurationProjectInfo;
