import { QueryModelWithPagination, QueryNames } from "../apicallstypes/queryCommons.ts";
import {
  FrontendProjectCategoryType,
  FrontendProjectDisciplineType,
  FrontendProjectLocationLimited,
  FrontendProjectManpowerPositionLimitedType,
  FrontendProjectPositionType,
  FrontendProjectTradeType,
} from "../apicallstypes/ProjectsUtilsApiTypes.ts";
import { FrontendCtrLimitedType } from "../apicallstypes/CtrTypes.ts";
import { FrontendManpowerPositionLimitedType } from "../apicallstypes/ProjectEmployeesApiTypes.ts";
import { FrontendProjectUserTypeWithRoleIds } from "./PermissionTypes.ts";
import { FrontendRoleNormal } from "../apicallstypes/UsersApiTypes.ts";
import { globalConfigurationRowItemType } from "../settings/GlobalConfigurationTypes.ts";

export enum CtrMenuItemEnum {
  library = "Library",
  mapping = "Mapping",
}

export interface CtrMenuItemType {
  title: CtrMenuItemEnum;
  url: string;
}

export type CtrEntityDataType =
  | FrontendProjectPositionType
  | FrontendCtrLimitedType
  | FrontendProjectCategoryType
  | FrontendProjectTradeType
  | FrontendProjectLocationLimited
  | FrontendProjectDisciplineType
  | FrontendManpowerPositionLimitedType;

// Use the union type in an interface

export enum ConfigurationInfoScreenType {
  edit = "edit",
  view = "view",
}

export enum frontendConfigurationStatus {
  MISSING = "missing",
  NOT_MISSING = "not missing",
}

export interface FrontendMissingConfigurationType {
  positionConfiguration: frontendConfigurationStatus;
  tradeConfiguration: frontendConfigurationStatus;
  ctrMappingConfiguration: frontendConfigurationStatus;
  calendarConfiguration: frontendConfigurationStatus;
  locationCalendarMappingConfiguration: frontendConfigurationStatus;
  locationConfiguration: frontendConfigurationStatus;
  categoryConfiguration: frontendConfigurationStatus;
  disciplineConfiguration: frontendConfigurationStatus;
  ctrConfiguration: frontendConfigurationStatus;
  manpowerPositionConfiguration: frontendConfigurationStatus;
}

export enum ConfigurationScreenType {
  positions = "positions",
  locations = "locations",
  trades = "trades",
  disciplines = "disciplines",
  categories = "categories",
  ctrManagement = "ctr-management",
  ctrLibrary = "ctr-library",
  manpowerPositions = "manpower-positions",
  permissions = "permissions",
}

export type ProjectPositionsFunction = (
  projectId: number,
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendProjectPositionType>>;

export type ProjectCtrFunction = (
  id: number,
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendCtrLimitedType>>;

export type ProjectTradesFunction = (
  projectId: number,
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendProjectTradeType>>;

export type ProjectLocationsFunction = (
  projectId: number,
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendProjectLocationLimited>>;

export type ProjectCategoriesFunction = (
  projectId: number,
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendProjectCategoryType>>;

export type ProjectCtrLibraryFunction = (
  projectId: number,
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendCtrLimitedType>>;

export type ProjectDisciplinesFunction = (
  projectId: number,
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendProjectDisciplineType>>;

export type ProjectManpowerPositionFunction = (
  projectId: number,
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendProjectManpowerPositionLimitedType>>;

export type ProjectPermissionsFunction = (
  projectId: number,
  pageOffset: number,
  searchFilter?: string,
  pageSize?: number,
) => Promise<QueryModelWithPagination<FrontendProjectUserTypeWithRoleIds>>;

export type ProjectConfigurationFunctionType =
  | ProjectPositionsFunction
  | ProjectCtrFunction
  | ProjectTradesFunction
  | ProjectLocationsFunction
  | ProjectCategoriesFunction
  | ProjectCtrLibraryFunction
  | ProjectDisciplinesFunction
  | ProjectManpowerPositionFunction
  | ProjectPermissionsFunction;

export interface ProjectConfigurationsFunctionMap {
  [ConfigurationScreenType.positions]: ProjectPositionsFunction;
  [ConfigurationScreenType.ctrManagement]: ProjectCtrFunction;
  [ConfigurationScreenType.trades]: ProjectTradesFunction;
  [ConfigurationScreenType.locations]: ProjectLocationsFunction;
  [ConfigurationScreenType.categories]: ProjectCategoriesFunction;
  [ConfigurationScreenType.disciplines]: ProjectDisciplinesFunction;
  [ConfigurationScreenType.ctrLibrary]: ProjectCtrLibraryFunction;
  [ConfigurationScreenType.manpowerPositions]: ProjectManpowerPositionFunction;
  [ConfigurationScreenType.permissions]: ProjectPermissionsFunction;
  // [ConfigurationScreenType.trades]: void;
}

export const configurationScreenGetQueryNameMap = new Map<ConfigurationScreenType, string>([
  [ConfigurationScreenType.positions, QueryNames.ProjectPositions],
  [ConfigurationScreenType.ctrManagement, "ctrManagement"],
  [ConfigurationScreenType.trades, QueryNames.ProjectTrades],
  [ConfigurationScreenType.locations, QueryNames.ProjectLocations],
  [ConfigurationScreenType.categories, QueryNames.ProjectCategories],
  [ConfigurationScreenType.ctrLibrary, `${QueryNames.ProjectCtrs}`],
  [ConfigurationScreenType.disciplines, QueryNames.ProjectDisciplines],
  [ConfigurationScreenType.manpowerPositions, QueryNames.ProjectPositionCodes],
  [ConfigurationScreenType.permissions, QueryNames.ProjectConfigurationsPermissions],
]);

export enum ConfigurationTableColumns {
  positions = "positions",
  ctrManagement = "ctr-management",
  trades = "trades",
  locations = "locations",
  categories = "categories",
  ctrLibrary = "ctr-library",
  disciplines = "disciplines",
  manpowerPositions = "manpower-positions",
  permissions = "permissions",
}

export const configurationColumnDefinitions = {
  [ConfigurationTableColumns.positions]: [
    { key: "code", header: "POSITION CODE" },
    { key: "description", header: "POSITION DESCRIPTION" },
    { key: "subpositions", header: "" },
    { key: "actions", header: "ACTIONS" },
  ],
  [ConfigurationTableColumns.ctrManagement]: [
    { key: "code", header: "CODE" },
    { key: "description", header: "DESCRIPTION" },
  ],
  [ConfigurationTableColumns.trades]: [
    { key: "code", header: "TRADE CODE" },
    { key: "title", header: "TRADE DESCRIPTION" },
    { key: "actions", header: "ACTIONS" },
  ],
  [ConfigurationTableColumns.locations]: [
    { key: "code", header: "LOCATION CODE" },
    { key: "description", header: "LOCATION DESCRIPTION" },
    { key: "worked-hours", header: "WORKING HOURS" },
    { key: "actions", header: "ACTIONS" },
  ],
  [ConfigurationTableColumns.ctrLibrary]: [
    { key: "code", header: "CTR CODE" },
    { key: "description", header: "CTR DESCRIPTION" },
    { key: "type", header: "TYPE" },
    { key: "category", header: "CATEGORY" },
    { key: "actions", header: "ACTIONS" },
  ],
  [ConfigurationTableColumns.categories]: [
    { key: "code", header: "CATEGORY CODE" },
    { key: "title", header: "CATEGORY TITLE" },
    { key: "actions", header: "ACTIONS" },
  ],
  [ConfigurationTableColumns.disciplines]: [
    { key: "code", header: "DISCIPLINE CODE" },
    { key: "title", header: "DISCIPLINE TITLE" },
    { key: "actions", header: "ACTIONS" },
  ],
  [ConfigurationTableColumns.manpowerPositions]: [
    { key: "code", header: "MP-CODE" },
    { key: "location-type", header: "LOCATION TYPE" },
    { key: "category", header: "CATEGORY[CODE]" },
    { key: "position", header: "POSITION" },
    { key: "trade", header: "TRADE" },
    { key: "rate", header: "RATE/HOUR" },
    { key: "manhours-schedule", header: "MANHOURS SCHEDULE" },
    { key: "actions", header: "ACTIONS" },
  ],
  [ConfigurationTableColumns.permissions]: [
    { key: "code", header: "CODE" },
    { key: "name", header: "NAME" },
    { key: "roles", header: "ROLES" },
    { key: "actions", header: "ACTIONS" },
  ],
};

export const getColSpanByConfigurationScreen = new Map<ConfigurationScreenType, number>([
  [ConfigurationScreenType.positions, 3],
  [ConfigurationScreenType.trades, 3],
  [ConfigurationScreenType.locations, 4],
  [ConfigurationScreenType.categories, 3],
  [ConfigurationScreenType.ctrLibrary, 4],
  [ConfigurationScreenType.disciplines, 3],
  [ConfigurationScreenType.manpowerPositions, 8],
  [ConfigurationScreenType.ctrManagement, 4],
  [ConfigurationScreenType.permissions, 4],
]);

export type configurationRowItemType =
  | FrontendProjectPositionType
  | FrontendCtrLimitedType
  | FrontendProjectDisciplineType
  | FrontendProjectCategoryType
  | FrontendProjectTradeType
  | FrontendProjectLocationLimited
  | FrontendProjectManpowerPositionLimitedType
  | FrontendProjectUserTypeWithRoleIds;

export enum configurationsPopupType {
  edit = "edit",
  create = "create",
}

export enum ConfigurationProjectInfoStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export type TableRowRendererProps = {
  item: configurationRowItemType;
  roles?: FrontendRoleNormal[]; // Allows passing additional props
};

export interface GlobalTableRowRendererProps {
  item: globalConfigurationRowItemType;
  roles?: FrontendRoleNormal[];
}

export enum ConfigurationScopeEnum {
  PROJECT = "Project",
  GLOBAL = "Global",
}
