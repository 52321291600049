import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { FrontendManpowerPositionDetailedType, FrontendManpowerPositionLimitedType } from "../../../types/projects/ManpowerPositionTypes.ts";
import {
  convertProjectManpowerPositionsCodesData,
  convertProjectManpowerPositionsCodeSingleDetailedData,
  convertProjectManpowerPositionsCodeSingleLimitedData,
} from "../../../types/apicallstypes/apicallstypesconverters/projects/projectmanpowercodes/ProjectManpowerPositionsCodesApiConverter.ts";
import { getManpowerPositionCode } from "./getManpowerPositionCode.ts";
import { getAllManpowerPositionCodes } from "./getAllManpowerPositionCodes.ts";

interface ApiDefinition {
  getAllManpowerPositionCodes: (
    id: number,
    pageOffset?: number,
    pageSize?: number,
    searchFilter?: string,
  ) => Promise<QueryModelWithPagination<FrontendManpowerPositionLimitedType>>;

  getManpowerPositionCode: (
    id: number,
    manpowerPositionCodeId: number,
    detailLevel: DetailLevel,
  ) => Promise<FrontendManpowerPositionDetailedType | FrontendManpowerPositionLimitedType>;
}

export const useEpcmApiProjectManpowerCodes = (): ApiDefinition => {
  const getAllManpowerPositionCodesConverted = async (id: number, pageOffset?: number, pageSize?: number, searchFilter?: string) => {
    const manpowerPositionCodesData = await getAllManpowerPositionCodes(id, pageOffset, pageSize, searchFilter);

    return {
      data: convertProjectManpowerPositionsCodesData(manpowerPositionCodesData.data),
      nextPage: manpowerPositionCodesData.nextPage,
      totalCount: manpowerPositionCodesData.totalCount,
    };
  };
  const getManpowerPositionCodeConverted = async (id: number, manpowerPositionCodeId: number, detailLevel: DetailLevel) => {
    const manpowerPositionCodeData = await getManpowerPositionCode(id, manpowerPositionCodeId, detailLevel);
    return detailLevel == DetailLevel.LIMITED
      ? convertProjectManpowerPositionsCodeSingleLimitedData(manpowerPositionCodeData as FrontendManpowerPositionLimitedType)
      : convertProjectManpowerPositionsCodeSingleDetailedData(manpowerPositionCodeData as FrontendManpowerPositionDetailedType);
  };
  return {
    getAllManpowerPositionCodes: getAllManpowerPositionCodesConverted,
    getManpowerPositionCode: getManpowerPositionCodeConverted,
  };
};
