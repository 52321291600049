import { FrontendManpowerPositionDetailedType, FrontendManpowerPositionLimitedType } from "../../../../../types/projects/ManpowerPositionTypes.ts";
import { FC, useEffect, useMemo } from "react";
import ManpowerPositionCodeItemStyles from "./ManpowerCodeItemStyles.module.css";
import classNames from "classnames";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import InfoMessageIcon from "../../../../../assets/images/info-message-icon.svg";

import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useEpcmApiProjectManpowerCodes } from "../../../../../apicalls/projects/projectmanpowercodes/useEpcmApiProjectManpowerCodes.ts";

import { Fade, Skeleton, Tooltip } from "@mui/material";
import { useEpcmApiProjectsUtils } from "../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";

interface ManpowerCodeItemProps extends FrontendManpowerPositionLimitedType {
  projectId: number;
}

const ManpowerCodeItem: FC<ManpowerCodeItemProps> = ({ id, projectId }) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getManpowerPositionCode } = useEpcmApiProjectManpowerCodes();
  const { getProjectLocation, getProjectCategory, getProjectTrade, getProjectPosition } = useEpcmApiProjectsUtils();

  const manpowerCodeItemDataQuery = useQuery({
    queryKey: [QueryNames.ProjectPositionCode, projectId, id],
    queryFn: () => getManpowerPositionCode(projectId, id, DetailLevel.DETAILED),
    enabled: isAuthorized,
    select: (data) => data as FrontendManpowerPositionDetailedType,
  });
  const manpowerCodeItemData = useMemo(() => {
    return manpowerCodeItemDataQuery.data;
  }, [manpowerCodeItemDataQuery.data]);

  const manpowerCodesPositionQuery = useQuery({
    queryKey: [QueryNames.ProjectPositions, projectId, manpowerCodeItemData?.positionId],
    queryFn: () => getProjectPosition(projectId, manpowerCodeItemData?.positionId as number, DetailLevel.LIMITED),
    enabled: isAuthorized && manpowerCodeItemData?.positionId !== undefined,
  });

  const manpowerCodesPositionQueryData = useMemo(() => {
    return manpowerCodesPositionQuery.data?.description ?? "";
  }, [manpowerCodesPositionQuery.data]);

  const manpowerCodesLocationQuery = useQuery({
    queryKey: [QueryNames.ProjectLocations, projectId, manpowerCodeItemData?.locationId],
    queryFn: () => getProjectLocation(projectId, manpowerCodeItemData?.locationId as number, DetailLevel.LIMITED),
    enabled: isAuthorized && manpowerCodeItemData?.locationId !== undefined,
  });

  const manpowerCodesLocationQueryData = useMemo(() => {
    return manpowerCodesLocationQuery.data?.description ?? "";
  }, [manpowerCodesLocationQuery.data]);

  const manpowerCodesCategoryQuery = useQuery({
    queryKey: [QueryNames.ProjectCategories, projectId, manpowerCodeItemData?.categoryId],
    queryFn: () => getProjectCategory(projectId, manpowerCodeItemData?.categoryId as number, DetailLevel.LIMITED),
    enabled: isAuthorized && manpowerCodeItemData?.categoryId !== undefined,
  });

  const manpowerCodesCategoryQueryData = useMemo(() => {
    return manpowerCodesCategoryQuery.data?.description ?? "";
  }, [manpowerCodesCategoryQuery.data]);

  const manpowerCodesTradeQuery = useQuery({
    queryKey: [QueryNames.ProjectTrades, projectId, manpowerCodeItemData?.tradeId],
    queryFn: () => getProjectTrade(projectId, manpowerCodeItemData?.tradeId as number, DetailLevel.LIMITED),
    enabled: isAuthorized && manpowerCodeItemData?.tradeId !== undefined,
  });

  const manpowerCodesTradeQueryData = useMemo(() => {
    return manpowerCodesTradeQuery.data?.description ?? "";
  }, [manpowerCodesTradeQuery.data]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.ProjectPositionCode, projectId, id] }).then(() => {
        console.log(`Query ${QueryNames.ProjectPositionCode} cancelled`);
      });
    };
  }, [queryClient, id, projectId]);
  useEffect(() => {
    return () => {
      manpowerCodeItemData &&
        queryClient.cancelQueries({ queryKey: [QueryNames.ProjectPositions, projectId] }).then(() => {
          console.log(`Query ${QueryNames.ProjectPositions} cancelled`);
        });
    };
  }, [manpowerCodeItemData, queryClient, projectId]);
  useEffect(() => {
    return () => {
      manpowerCodeItemData &&
        queryClient.cancelQueries({ queryKey: [QueryNames.ProjectLocations, projectId, manpowerCodeItemData?.locationId] }).then(() => {
          console.log(`Query ${QueryNames.ProjectLocations} cancelled`);
        });
    };
  }, [projectId, queryClient, manpowerCodeItemData?.locationId, manpowerCodeItemData]);
  useEffect(() => {
    return () => {
      manpowerCodeItemData &&
        queryClient.cancelQueries({ queryKey: [QueryNames.ProjectCategories, projectId, manpowerCodeItemData?.categoryId] }).then(() => {
          console.log(`Query ${QueryNames.ProjectCategories} cancelled`);
        });
    };
  }, [projectId, queryClient, manpowerCodeItemData?.categoryId, manpowerCodeItemData]);

  useEffect(() => {
    return () => {
      manpowerCodeItemData &&
        queryClient.cancelQueries({ queryKey: [QueryNames.ProjectTrades, projectId, manpowerCodeItemData?.tradeId] }).then(() => {
          console.log(`Query ${QueryNames.ProjectTrades} cancelled`);
        });
    };
  }, [projectId, queryClient, manpowerCodeItemData?.tradeId, manpowerCodeItemData]);

  return (
    <div key={id} className={classNames(ManpowerPositionCodeItemStyles.mainContainer, GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
      {manpowerCodeItemDataQuery.isLoading ? (
        <Skeleton variant={"text"} width={60} height={30} />
      ) : (
        <div className={classNames(ManpowerPositionCodeItemStyles.codeText)}>{manpowerCodeItemData?.code}</div>
      )}
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
      <div>
        <Tooltip
          title={
            <div
              className={classNames(
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.flex,
                GlobalStyles.gap05,
                ManpowerPositionCodeItemStyles.tooltipContainer,
              )}
            >
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap01, GlobalStyles.flexDirectionColumn)}>
                <div className={classNames(ManpowerPositionCodeItemStyles.tooltipItemLabel)}>{"Position"}</div>
                {manpowerCodeItemDataQuery.isLoading ? (
                  <Skeleton variant={"text"} width={60} height={30} />
                ) : (
                  <div className={classNames(ManpowerPositionCodeItemStyles.tooltipItemData)}>{manpowerCodesPositionQueryData ?? ""}</div>
                )}
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap01, GlobalStyles.flexDirectionColumn)}>
                <div className={classNames(ManpowerPositionCodeItemStyles.tooltipItemLabel)}>{"Location"}</div>

                {manpowerCodeItemDataQuery.isLoading ? (
                  <Skeleton variant={"text"} width={60} height={30} />
                ) : (
                  <div className={classNames(ManpowerPositionCodeItemStyles.tooltipItemData)}>{manpowerCodesLocationQueryData ?? ""}</div>
                )}
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap01, GlobalStyles.flexDirectionColumn)}>
                <div className={classNames(ManpowerPositionCodeItemStyles.tooltipItemLabel)}>{"Category"}</div>
                {manpowerCodeItemDataQuery.isLoading ? (
                  <Skeleton variant={"text"} width={60} height={30} />
                ) : (
                  <div className={classNames(ManpowerPositionCodeItemStyles.tooltipItemData)}>{manpowerCodesCategoryQueryData ?? ""}</div>
                )}
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap01, GlobalStyles.flexDirectionColumn)}>
                <div className={classNames(ManpowerPositionCodeItemStyles.tooltipItemLabel)}>{"Trade"}</div>
                {manpowerCodeItemDataQuery.isLoading ? (
                  <Skeleton variant={"text"} width={60} height={30} />
                ) : (
                  <div className={classNames(ManpowerPositionCodeItemStyles.tooltipItemData)}>{manpowerCodesTradeQueryData ?? ""}</div>
                )}
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap01, GlobalStyles.flexDirectionColumn)}>
                <div className={classNames(ManpowerPositionCodeItemStyles.tooltipItemLabel)}>{"Rate/Hour"}</div>
              </div>
              {manpowerCodeItemDataQuery.isLoading ? (
                <Skeleton variant={"text"} width={60} height={30} />
              ) : (
                <div className={classNames(ManpowerPositionCodeItemStyles.tooltipItemData)}>{`$${manpowerCodeItemData?.rate}`}</div>
              )}
            </div>
          }
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "white",
                color: "black",
                padding: "0.5em 0.5em 0.5em 0.5em",
              },
            },
          }}
          placement="bottom-start"
          TransitionComponent={Fade}
        >
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img src={InfoMessageIcon} alt="info message icon" className={classNames(ManpowerPositionCodeItemStyles.infoImg)} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
export default ManpowerCodeItem;
