import { FC } from "react";
import { useNotificationContext } from "./GlobalNotificationContext.ts";
import { Box, Portal } from "@mui/material";
import SnackbarToast from "./epcmtoast/EpcmToast.tsx";

const StackSnackbar: FC = () => {
  const { notifications } = useNotificationContext();

  return (
    <Portal>
      <Box
        sx={{
          position: "fixed",
          bottom: 24,
          right: 24,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          zIndex: 9999,
        }}
      >
        {notifications.map((notification) => (
          <SnackbarToast key={notification.fileId} toast={notification} />
        ))}
      </Box>
    </Portal>
  );
};
export default StackSnackbar;
