import { FrontendEmployeeCategoryEnum, FrontendEmployeeDetailedType, FrontendEmployeeLimitedType } from "../EmployeesApiTypes.ts";
import { EmployeeDetailed, EmployeeLimited, EmployeeType } from "epcm-common/dist/Types/EmployeeTypes";

import { convertPositionData } from "./projects/projectsutils/ProjectsUtilsApiConverter.ts";

const convertEmployeeCategoryToFrontend = (employeeType: EmployeeType): FrontendEmployeeCategoryEnum => {
  const employeeCategoryMap: Map<EmployeeType, FrontendEmployeeCategoryEnum> = new Map([
    [EmployeeType.ARCHIRODON, FrontendEmployeeCategoryEnum.ARCHIRODON],
    [EmployeeType.RENTED, FrontendEmployeeCategoryEnum.RENTED],
    [EmployeeType.SUBCONTRACTOR, FrontendEmployeeCategoryEnum.SUBCONTRACTOR],
  ]);

  return employeeCategoryMap.get(employeeType)!;
};

export const convertEmployeeCategoryToBackend = (employeeType: FrontendEmployeeCategoryEnum): EmployeeType => {
  const employeeCategoryMap: Map<FrontendEmployeeCategoryEnum, EmployeeType> = new Map([
    [FrontendEmployeeCategoryEnum.ARCHIRODON, EmployeeType.ARCHIRODON],
    [FrontendEmployeeCategoryEnum.RENTED, EmployeeType.RENTED],
    [FrontendEmployeeCategoryEnum.SUBCONTRACTOR, EmployeeType.SUBCONTRACTOR],
  ]);

  return employeeCategoryMap.get(employeeType)!;
};

export const convertSingleEmployeeLimitedData = (employeeData: EmployeeLimited): FrontendEmployeeLimitedType => {
  return {
    id: employeeData.id,
    code: employeeData.code,
    name: employeeData.name,
  };
};

export const convertSingleEmployeeDetailedData = (employeeData: EmployeeDetailed): FrontendEmployeeDetailedType => {
  return {
    id: employeeData.id,
    name: employeeData.name,
    code: employeeData.code,
    nationality: employeeData.nationality ? employeeData.nationality : null,
    globalPosition: employeeData.globalPosition ? convertPositionData(employeeData.globalPosition) : null,
    category: employeeData.type ? convertEmployeeCategoryToFrontend(employeeData.type) : null,
    dateOfBirth: employeeData.dateOfBirth ? new Date(employeeData.dateOfBirth) : null,
    languages: employeeData.languages ? employeeData.languages.split(",") : null,
    qualifications: employeeData.qualifications ? employeeData.qualifications : null,
    experience: employeeData.experience ? employeeData.experience : null,
    certifications: employeeData.certifications ? employeeData.certifications : null,
  };
};

export const convertEmployeesData = (employeesData: EmployeeLimited[]): FrontendEmployeeLimitedType[] => {
  return employeesData.map((employeeItem): FrontendEmployeeLimitedType => {
    return convertSingleEmployeeLimitedData(employeeItem);
  });
};
