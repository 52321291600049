import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config";
import { ApiRequestBodyCalendarCreate } from "epcm-common/dist/Types/CalendarTypes";

const API = `${EPCM_API_URLS.projects}`;

export const createNewCalendar = async (projectId: number, newCalendarData: ApiRequestBodyCalendarCreate) => {
  const url = `${API}/${projectId}/calendar`;
  const config: AxiosRequestConfig = {
    url: `${url}`,
    method: "POST",
    data: newCalendarData,
  } as AxiosRequestConfig;
  return epcmAxiosInstance(config);
};
