import classNames from "classnames";
import GlobalConfigurationDisciplinesPopupStyles from "./GlobalConfigurationDisciplinesPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { PopupButtonTypeEnum, PopupType } from "../../../../../types/PopupType.ts";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PopupStructure } from "../../../../../ui/popupstructure/PopupStructure.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { FrontendGlobalDisciplineType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";

interface GlobalConfigurationDisciplinesPopupProps extends PopupType {
  globalDisciplineItem?: FrontendGlobalDisciplineType;
  mode: configurationsPopupType;
}

const GlobalConfigurationDisciplinesPopup: FC<GlobalConfigurationDisciplinesPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  globalDisciplineItem,
  mode,
}) => {
  const [disciplineDescription, setDisciplineDescription] = useState<string>(globalDisciplineItem?.description || "");
  const [disciplineCode, setDisciplineCode] = useState<string>(globalDisciplineItem?.code || "");

  useEffect(() => {
    if (globalDisciplineItem) {
      setDisciplineDescription(globalDisciplineItem.description);
      setDisciplineCode(globalDisciplineItem.code);
    }
  }, [globalDisciplineItem]);

  const { useCreateGlobalEntityMutation, useUpdateGlobalEntityMutation } = useEpcmApiConfigurationsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createGlobalDisciplineMutation = useCreateGlobalEntityMutation(FrontendGlobalConfigurationsEntityEnum.Discipline, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global Discipline was added successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateGlobalDisciplineMutation = useUpdateGlobalEntityMutation(
    globalDisciplineItem?.id ?? 0,
    FrontendGlobalConfigurationsEntityEnum.Discipline,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: `Global Discipline was updated successfully` });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );

  const onupdateGlobalDiscipline = useCallback(() => {
    updateGlobalDisciplineMutation.mutate({
      code: disciplineCode,
      description: disciplineDescription,
    });
    onOpenLoaderPopup();
  }, [updateGlobalDisciplineMutation, disciplineDescription, disciplineCode, onOpenLoaderPopup]);

  const onCreateGlobalDiscipline = useCallback(() => {
    createGlobalDisciplineMutation.mutate({
      code: disciplineCode,
      description: disciplineDescription,
    });
    onOpenLoaderPopup();
  }, [createGlobalDisciplineMutation, disciplineDescription, disciplineCode, onOpenLoaderPopup]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalDisciplines] });
  }, [queryClient]);

  const isEitherInputEmpty = useMemo(() => !disciplineDescription || !disciplineCode, [disciplineDescription, disciplineCode]);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(GlobalConfigurationDisciplinesPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Discipline" : "Create New Global Discipline",
          tooltipText: isEitherInputEmpty ? "Please enter both discipline code and discipline description" : "",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !isEitherInputEmpty && onupdateGlobalDiscipline();
            } else {
              !isEitherInputEmpty && !!disciplineDescription && onCreateGlobalDiscipline();
            }
          },
          disabled: isEitherInputEmpty,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalConfigurationDisciplinesPopupStyles.mainContainer,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationDisciplinesPopupStyles.infoText)}>Discipline Code</div>
          <input
            placeholder={"Type a discipline code..."}
            className={classNames(
              GlobalConfigurationDisciplinesPopupStyles.infoContainer,
              GlobalConfigurationDisciplinesPopupStyles.infoContainerInput,
            )}
            required
            value={disciplineCode}
            onChange={(e) => setDisciplineCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationDisciplinesPopupStyles.infoText)}>Discipline Description</div>
          <input
            placeholder={"Type a discipline description..."}
            className={classNames(
              GlobalConfigurationDisciplinesPopupStyles.infoContainer,
              GlobalConfigurationDisciplinesPopupStyles.infoContainerInput,
            )}
            required
            value={disciplineDescription}
            onChange={(e) => setDisciplineDescription(e.target.value)}
          ></input>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default GlobalConfigurationDisciplinesPopup;
