import { Fade, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const EpcmTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip TransitionComponent={Fade} {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFFFFF",
    boxShadow: "4px 0px 4px rgba(204, 204, 204, 0.75), 0px 4px 4px rgba(204, 204, 204, 0.75)",
    maxWidth: "none",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
  },
});
