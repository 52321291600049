import { FrontendMmrDateStatusEnum } from "../apicallstypes/ProjectEmployeesApiTypes.ts";

export interface MmrDataType {
  title: string;
  content: string | number | null | undefined;
  isLoading?: boolean;
}

export enum MmrInformationActionButtonEnum {
  DOWNLOAD = "DOWNLOAD",
  ADD_FILES = "ADD_FILES",
  CLONE = "CLONE",
  NONE = "NONE",
}

export interface MmrAccordionItem {
  title: string;
  type: FrontendMmrDateStatusEnum;
}

export interface FrontendMmrDateLimits {
  calendarStartDate: Date;
  calendarEndDate: Date;
  latestMobilizationDate: Date | null;
  earliestDemobilizationDate: Date | null;
}
