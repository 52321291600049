import { EpcmMutationOptionsType } from "../../../config.ts";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { ErrorCallbackDataType, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { createProjectConfigurationEntity } from "./createNewProjectConfigurationEntity.ts";
import { deleteProjectConfigurationEntity } from "./deleteProjectConfigurationEntity.ts";
import { updateProjectEntity } from "./updateProjectConfigurationEntity.ts";

export enum FrontendProjectConfigurationsEntityEnum {
  Position = "position",
  Subposition = "sub-positions",
  Trade = "trade",
  Category = "category",
  Location = "location",
  Discipline = "discipline",
  ManpowerPosition = "manpower-position",
  Ctr = "ctrs",
}

interface ApiDefinition {
  useCreateProjectEntityMutation: <T>(
    projectId: number,
    entityType: FrontendProjectConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subEntityId?: number,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, T>;

  useDeleteProjectConfigurationEntityMutation: (
    projectId: number,
    projectConfigurationEntityId: number,
    projectConfigurationEntityType: FrontendProjectConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subPositionId?: number,
  ) => UseMutationResult<unknown, unknown, void, unknown>;

  useUpdateProjectEntityMutation: <T>(
    projectId: number,
    entityId: number,
    entityType: FrontendProjectConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subEntityId?: number,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, T>;
}

export const useEpcmApiProjectUtilsMutations = (): ApiDefinition => {
  const useCreateProjectEntityMutation = <T>(
    projectId: number,
    entityType: FrontendProjectConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subEntityId?: number,
  ) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, T, unknown>({
      mutationFn: (newEntityData: T) => {
        return createProjectConfigurationEntity(projectId, entityType, newEntityData, subEntityId);
      },
      onSuccess: async (data) => {
        console.log(`on post create project ${entityType} mutation success: `, data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log(`on post create project ${entityType} mutation error: `, err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useDeleteProjectConfigurationEntityMutation = (
    projectId: number,
    projectConfigurationEntityId: number,
    projectConfigurationEntityType: FrontendProjectConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subpositionId?: number,
  ) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void, unknown>({
      mutationFn: () => {
        return deleteProjectConfigurationEntity(projectId, projectConfigurationEntityId, projectConfigurationEntityType, subpositionId);
      },

      onSuccess: async (data) => {
        console.log(`on post delete project ${projectConfigurationEntityType}  mutation success: `, data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log(`on post delete project ${projectConfigurationEntityType} mutation error: `, err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        //void queryClient.invalidateQueries([QueryNames.ProjectEmployees, projectId]);
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useUpdateProjectEntityMutation = <T>(
    projectId: number,
    entityId: number,
    entityType: FrontendProjectConfigurationsEntityEnum,
    mutationOptions: EpcmMutationOptionsType,
    subEntityId?: number,
  ) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, T>({
      mutationFn: async (updatedEntityData: T) => {
        return updateProjectEntity(projectId, entityId, entityType, updatedEntityData, subEntityId);
      },
      onSuccess: (data) => {
        console.log(`on post update project ${entityType} mutation success: `, data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log(`on post update project ${entityType} mutation error: `, err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  return {
    useCreateProjectEntityMutation: useCreateProjectEntityMutation,
    useDeleteProjectConfigurationEntityMutation: useDeleteProjectConfigurationEntityMutation,
    useUpdateProjectEntityMutation: useUpdateProjectEntityMutation,
  };
};
