import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import MonthListStyles from "./MonthList.module.css";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
// import MonthListItem from "./monthlistitem/MonthListItem.tsx";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";

import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import React from "react";
import { getInitials } from "../../../../../../utils/StringManipulation.ts";
import { useEpcmApiConfigurationCalendarInfo } from "../../../../../../apicalls/configurations/calendar/useEpcmApiConfigurationCalendarInfo.ts";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { QueryNames } from "../../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../../apicalls/config.ts";
import InfiniteScrollInViewElement from "../../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";

export const MonthList = () => {
  const { projectId } = useParams();
  const { ref, inView } = useInView();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [hasSetFirstMonth, setHasSetFirstMonth] = useState<boolean>(false);

  const { getGeneralCalendarInfo } = useEpcmApiConfigurationCalendarInfo();
  const { getAllCalendarMonths } = useEpcmApiConfigurationCalendarInfo();

  const [searchQuery] = useState<string>("");

  const projectCalendarManagementQuery = useQuery({
    queryKey: [QueryNames.ProjectCalendar, projectId],
    queryFn: () => getGeneralCalendarInfo(parseInt(projectId!)),
    enabled: isAuthorized,
  });

  const projectCalendarManagementData = useMemo(() => {
    return projectCalendarManagementQuery.data;
  }, [projectCalendarManagementQuery]);

  const projectCalendarMonthsQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectCalendarMonths, projectId, searchQuery],
    queryFn: ({ pageParam }) => getAllCalendarMonths(parseInt(projectId!), pageParam, searchQuery, PAGINATION_PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized && projectCalendarManagementData && projectCalendarManagementData.locations.length > 0,
  });

  const projectCalendarMonthsData = useMemo(() => {
    return projectCalendarMonthsQuery.data?.pages.flatMap((page) => page.data);
  }, [projectCalendarMonthsQuery.data]);

  const locationDescriptions = useMemo(() => {
    return projectCalendarManagementData ? projectCalendarManagementData.locations.map((location) => location.description) : [];
  }, [projectCalendarManagementData]);

  const projectCalendarMonthsLoading = useMemo(() => {
    return projectCalendarMonthsQuery.isLoading;
  }, [projectCalendarMonthsQuery.isLoading]);

  useEffect(() => {
    if (
      inView &&
      !projectCalendarMonthsQuery.isLoading &&
      !projectCalendarMonthsQuery.isFetching &&
      !projectCalendarMonthsQuery.isFetchingNextPage &&
      projectCalendarMonthsQuery.hasNextPage
    ) {
      projectCalendarMonthsQuery.fetchNextPage();
    }
  }, [inView, projectCalendarMonthsQuery]);

  const monthItemSkeletonLoader = <Skeleton width={"100%"} height={145} variant={"rounded"}></Skeleton>;

  const projectMonthsLoader = (
    <div className={classNames(MonthListStyles.containerDiv, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      <Skeleton className={classNames(MonthListStyles.monthListContainer)} width={210} height={145} variant={"rounded"}></Skeleton>
      <Skeleton className={classNames(MonthListStyles.monthListContainer)} width={210} height={145} variant={"rounded"}></Skeleton>
      <Skeleton className={classNames(MonthListStyles.monthListContainer)} width={210} height={145} variant={"rounded"}></Skeleton>
      <Skeleton className={classNames(MonthListStyles.monthListContainer)} width={210} height={145} variant={"rounded"}></Skeleton>
      <Skeleton className={classNames(MonthListStyles.monthListContainer)} width={210} height={145} variant={"rounded"}></Skeleton>
      <Skeleton className={classNames(MonthListStyles.monthListContainer)} width={210} height={145} variant={"rounded"}></Skeleton>
      <Skeleton className={classNames(MonthListStyles.monthListContainer)} width={210} height={145} variant={"rounded"}></Skeleton>
    </div>
  );

  useEffect(() => {
    if (projectCalendarMonthsData && projectCalendarMonthsData.length > 0 && !hasSetFirstMonth) {
      navigate(projectCalendarMonthsData[0].calendarMonths[0].id.toString());
      setHasSetFirstMonth(true);
    }
  }, [projectCalendarMonthsData, setHasSetFirstMonth]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectCalendarMonths, projectId] }).then(() => {
        console.log(`In month-list component, ${QueryNames.ProjectCalendarMonths} query for project ${projectId} cancelled`);
      });
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectCalendar, projectId] }).then(() => {
        console.log(`In month-list component, ${QueryNames.ProjectCalendar} query for project ${projectId} cancelled`);
      });
    };
  }, [queryClient, projectId]);

  return (
    <div className={classNames(MonthListStyles.parentDiv, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap)}>
      {projectCalendarMonthsData ? (
        <div
          className={classNames(
            MonthListStyles.containerDiv,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap,
            GlobalStyles.flex1,
          )}
        >
          {projectCalendarMonthsData &&
            projectCalendarMonthsData.map((monthData) =>
              monthData.calendarMonths.map((month) => (
                <React.Fragment key={month.id}>
                  <NavLink to={month.id.toString()}>
                    {({ isActive }) => (
                      <div
                        className={classNames(
                          MonthListStyles.monthListContainer,
                          GlobalStyles.elementWithCursor,
                          GlobalStyles.pointer,
                          isActive && MonthListStyles.selectedMonthContainer,
                        )}
                      >
                        <div className={classNames(MonthListStyles.largeScaleText, isActive && MonthListStyles.selectedLargeScaleText)}>
                          M+{month.monthNumber}
                        </div>
                        <div className={classNames(MonthListStyles.monthText, isActive && MonthListStyles.selectedMonthText)}>
                          {month.displayName}
                        </div>
                        <div className={classNames(MonthListStyles.locationText, isActive && MonthListStyles.selectedMonthLocation)}>
                          {month.locationsWorkingDays.map((locationWorkingDays, index) => (
                            <div className={classNames(MonthListStyles.locationTextContainer, GlobalStyles.flex, GlobalStyles.gap3)} key={index}>
                              <div>{getInitials(locationDescriptions[index])}</div>
                              <div>{locationWorkingDays.workingDays} Days</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </NavLink>
                </React.Fragment>
              )),
            )}
          <InfiniteScrollInViewElement
            key={"InfiniteScrollInViewElement"}
            reference={ref}
            infiniteQueryResult={projectCalendarMonthsQuery}
            loaderComponent={monthItemSkeletonLoader}
          />
        </div>
      ) : (
        projectMonthsLoader
      )}
      {!projectCalendarMonthsLoading && <Outlet />}
    </div>
  );
};
