import { ApiResponseGetMe } from "epcm-common/dist/Types/UserTypes";
import { FrontendProfileType } from "../ProfileApiTypes.ts";

import { convertUserRolesData } from "./UsersApiConverter.ts";

export const convertUserProfileData = (userProfileData: ApiResponseGetMe): FrontendProfileType => {
  return {
    name: `${userProfileData.name} ${userProfileData.surname}`,
    code: userProfileData.code,
    email: userProfileData.email,
    roles: convertUserRolesData([]), // TODO: Change this when roles are returned from backend
  };
};
