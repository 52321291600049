import { useNavigate } from "react-router-dom";
import { AppRoutesPaths } from "../types/AppRoutesTypes.ts";
import { AxiosError } from "axios";

interface HandleUnauthorizedType {
  handleErrorRedirect: (error: AxiosError) => never;
}

export const useHandleUnauthorized = (): HandleUnauthorizedType => {
  const navigate = useNavigate();

  const handleErrorRedirect = (error: AxiosError) => {
    if (error.response?.status === 403) {
      navigate(`/${AppRoutesPaths.unauthorized}`);
    }
    throw error;
  };

  return { handleErrorRedirect };
};
