import { FC, ReactNode } from "react";
import classNames from "classnames";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import PopupStructureStyles from "./PopupStructure.module.css";
import { PopupButtonType, PopupButtonTypeEnum, PopupHeaderHighlightedPartType, PopupType } from "../../types/PopupType.ts";
import { EPCMDialog } from "../epcmdialog/EPCMDialog.tsx";
import { EPCMPopupContentDiv } from "../epcmpopupcontentdiv/EPCMPopupContentDiv.tsx";
import { PopupHeader } from "../popupheader/PopupHeader.tsx";
import { Fade, Tooltip } from "@mui/material";

interface PopupStructureProps extends PopupType {
  headerHighlightedPart?: PopupHeaderHighlightedPartType;
  headerIcon?: string;
  showSubHeaderPersonIcon?: boolean;
  popupButtons: PopupButtonType[];
  hideCloseHeaderButton?: boolean;
  overrideContentContainerStyleClass?: string;
  children?: ReactNode;
}

export const PopupStructure: FC<PopupStructureProps> = ({
  isOpen,
  closeFn,
  headerText,
  headerIcon,
  secondaryHeaderText,
  headerHighlightedPart,
  showSubHeaderPersonIcon,
  popupButtons,
  hideCloseHeaderButton,
  overrideContentContainerStyleClass,
  children,
}) => {
  const buttonStyleMap: Map<PopupButtonTypeEnum, string> = new Map([
    [PopupButtonTypeEnum.neutral, PopupStructureStyles.neutralButton],
    [PopupButtonTypeEnum.main, PopupStructureStyles.mainButton],
    [PopupButtonTypeEnum.danger, PopupStructureStyles.dangerButton],
  ]);

  return (
    <EPCMDialog
      open={isOpen}
      maxWidth={false}
      onClose={() => {
        closeFn();
      }}
    >
      {headerText && (
        <PopupHeader
          headerText={headerText}
          hideCloseHeaderButton={hideCloseHeaderButton}
          headerIcon={headerIcon}
          headerHighlightedPart={headerHighlightedPart}
          secondaryHeaderText={secondaryHeaderText}
          showPersonIcon={showSubHeaderPersonIcon}
          closeFn={closeFn}
        />
      )}
      <EPCMPopupContentDiv
        className={classNames(
          overrideContentContainerStyleClass ?? PopupStructureStyles.contentContainer,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap2,
        )}
      >
        {children}
      </EPCMPopupContentDiv>
      {popupButtons.length > 0 && (
        <div className={classNames(PopupStructureStyles.buttonContainer, GlobalStyles.flex)}>
          {popupButtons.map((popupButton) => (
            <Tooltip key={popupButton.text} TransitionComponent={Fade} title={popupButton.tooltipText ?? ""} placement="top" arrow>
              <div
                className={classNames(
                  buttonStyleMap.get(popupButton.buttonType),
                  popupButton.disabled && PopupStructureStyles.disabledButton,
                  GlobalStyles.flex,
                  GlobalStyles.flex1,
                )}
                onClick={() => !popupButton.disabled && popupButton.action()}
              >
                {popupButton.text}
              </div>
            </Tooltip>
          ))}
        </div>
      )}
    </EPCMDialog>
  );
};
