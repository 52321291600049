import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config";
import { QueryModelWithCountAndData } from "../../../../types/apicallstypes/queryCommons.ts";
import { CtrLimited } from "epcm-common/dist/Types/CtrTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getGroupTimesheetActiveMmrCtrData = async (
  id: number,
  timesheetGroupId: number,
  timesheetId: number,
  timesheetEmployeeId: number,
  projectEmployeeId: number,
  mmrId: number,
): Promise<QueryModelWithCountAndData<CtrLimited>> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/timesheets/${timesheetId}/employee/${timesheetEmployeeId}/projectEmployee/${projectEmployeeId}/mmr/${mmrId}/ctrs`,
    method: "GET",
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);
  return response.data;
};
