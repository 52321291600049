import React from "react";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import PopupHeaderStyles from "./PopupHeader.module.css";
import closePopupIcon from "../../assets/images/close-popup.svg";
import classNames from "classnames";
import personIcon from "../../assets/images/profile-default-avatar-white.svg";
import { EPCMPopupHeaderDiv } from "../epcmpopupheaderdiv/EPCMPopupHeaderDiv.tsx";
import { PopupHeaderHighlightedPartType } from "../../types/PopupType.ts";

interface PopupHeaderProps {
  headerText: string;
  hideCloseHeaderButton?: boolean;
  headerIcon?: string;
  headerHighlightedPart?: PopupHeaderHighlightedPartType;
  secondaryHeaderText?: string;
  showPersonIcon?: boolean;
  closeFn: () => void;
}

export const PopupHeader: React.FC<PopupHeaderProps> = ({
  headerText,
  hideCloseHeaderButton,
  headerIcon,
  headerHighlightedPart,
  secondaryHeaderText,
  showPersonIcon,
  closeFn,
}) => {
  return (
    <EPCMPopupHeaderDiv className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
        {headerIcon ? (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
            <img src={headerIcon} alt="header-icon" className={classNames(PopupHeaderStyles.headerIcon)} />
            <div className={classNames(GlobalStyles.centerVertical)}>{headerText}</div>
          </div>
        ) : (
          <div className={classNames(GlobalStyles.centerVertical)}>{headerText}</div>
        )}
        {headerHighlightedPart && (
          <div className={classNames(headerHighlightedPart.styleClass, GlobalStyles.centerVertical)}>{headerHighlightedPart.text}</div>
        )}
        <div className={classNames(GlobalStyles.flex1)}></div>
        {!hideCloseHeaderButton && (
          <div className={classNames(PopupHeaderStyles.closeIcon, GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)} onClick={closeFn}>
            <img src={closePopupIcon} alt="close" className={classNames(PopupHeaderStyles.closeIconImg)} />
          </div>
        )}
      </div>
      {secondaryHeaderText && (
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
          {showPersonIcon && (
            <div className={classNames(PopupHeaderStyles.personIcon, GlobalStyles.centerVertical)}>
              <img src={personIcon} alt="person" className={classNames(PopupHeaderStyles.personIconImg)} />
            </div>
          )}
          <div className={classNames(PopupHeaderStyles.secondaryHeaderText, GlobalStyles.centerVertical)}>{secondaryHeaderText}</div>
        </div>
      )}
    </EPCMPopupHeaderDiv>
  );
};
