import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

export const deleteProjectEmployees = async (projectId: number, projectEmployeeId: number) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/employees/${projectEmployeeId}`,
    method: "DELETE",
  } as AxiosRequestConfig;
  return epcmAxiosInstance(config);
};
