import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import ProjectsStyles from "./Projects.module.css";
import classNames from "classnames";
import projectIcon from "../../assets/images/project-icon-black.svg";
import { ProjectList } from "./projectlist/ProjectList.tsx";
import { SearchBar } from "../../ui/searchbar/SearchBar.tsx";
import { EPCMInfoContainerDiv } from "../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
//import { QuickFilterButton } from "../../ui/quickfilterbutton/QuickFilterButton.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
//import { PageFilterContainer } from "../../ui/pagefiltercontainer/PageFilterContainer.tsx";
import { ProjectsPagePopups, useProjectsPopups } from "./use-projects-popups.ts";
import { CreateNewProjectPopup } from "./popups/createnewprojectpopup/CreateNewProjectPopup.tsx";
import { useEpcmApiProjects } from "../../apicalls/projects/useEpcmApiProjects.ts";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../store/use-impersonation-store.ts";
import { Skeleton } from "@mui/material";
import { displayNumberWithSpecificNumberOfDigits } from "../../utils/NumberManipulation.ts";

import { FrontendProjectLimitedType, FrontendProjectStatus } from "../../types/apicallstypes/ProjectsApiTypes.ts";
import { PAGINATION_PAGE_SIZE } from "../../apicalls/config.ts";
import { useInView } from "react-intersection-observer";
import InfiniteScrollInViewElement from "../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";

import { useResponseAlertPopupStateType } from "../../utils/use-response-alert-popup-state.ts";

import { ResponseAlertPopup } from "../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { useRetrieveUserPermittedActions } from "../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../types/Roles.ts";

import { useHandleUnauthorized } from "../../utils/use-handle-unauthorized.ts";
import { PageFilterContainer } from "../../ui/pagefiltercontainer/PageFilterContainer.tsx";
import { QuickFilterButton } from "../../ui/quickfilterbutton/QuickFilterButton.tsx";

export const Projects = () => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { ref, inView } = useInView();
  const { getAllProjects } = useEpcmApiProjects();

  const { isResponseAlertPopupOpen, onCloseResponseAlertPopup, responseType, responseObject, initializeResponseAlertPopup } =
    useResponseAlertPopupStateType();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { handleErrorRedirect } = useHandleUnauthorized();
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();
  const [selectedFilterType, setSelectedFilterType] = useState<FrontendProjectStatus | null>(null);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [previousProjectData, setPreviousProjectData] = useState<FrontendProjectLimitedType[] | undefined>();

  const allProjectsQuery = useInfiniteQuery({
    queryKey: [QueryNames.Projects, searchQuery, selectedFilterType],
    queryFn: ({ pageParam }) =>
      getAllProjects(pageParam, searchQuery, PAGINATION_PAGE_SIZE, selectedFilterType ?? undefined).catch(handleErrorRedirect),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const actualProjectsData = useMemo(() => {
    return allProjectsQuery.data?.pages.flatMap((page) => page.data);
  }, [allProjectsQuery.data]);

  const allProjectsData = useMemo(() => {
    return actualProjectsData ?? (allProjectsQuery.isError ? undefined : previousProjectData);
  }, [previousProjectData, allProjectsQuery.isError, actualProjectsData]);

  const totalProjectsCount = useMemo(() => {
    return allProjectsQuery.data?.pages[0].totalCount;
  }, [allProjectsQuery.data]);

  const onSearchQueryChange = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const handleFilterClick = useCallback(
    (filterType: FrontendProjectStatus | null) => {
      setSelectedFilterType((currentState) => (currentState === filterType ? null : filterType));
    },
    [setSelectedFilterType],
  );

  useEffect(() => {
    if (allProjectsData) {
      setPreviousProjectData(allProjectsData);
    }
  }, [allProjectsData]);

  const projectListSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(ProjectsStyles.skeletonLoaderHeaders, GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap15)}>
          <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
            <Skeleton variant="rounded" height={25} />
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
          <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
            <Skeleton variant="rounded" height={25} />
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
          <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
            <Skeleton variant="rounded" height={25} />
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
          <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
            <Skeleton variant="rounded" height={25} />
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
          <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
            <Skeleton variant="rounded" height={25} />
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={0} width={100} />
        </div>
      </div>
      {[0, 1, 2, 3].map((key) => (
        <div key={key} className={classNames(ProjectsStyles.skeletonLoader, GlobalStyles.flex, GlobalStyles.gap)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap15)}>
            <div className={classNames(GlobalStyles.centerVertical)}>
              <Skeleton variant="rounded" height={60} width={60} />
            </div>
            <div className={classNames(GlobalStyles.flex3, GlobalStyles.centerVertical)}>
              <Skeleton variant="rounded" height={20} />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
            <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
              <Skeleton variant="rounded" height={20} />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
            <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
              <Skeleton variant="rounded" height={20} />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
            <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
              <Skeleton variant="rounded" height={20} />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
            <div className={classNames(GlobalStyles.flex1, GlobalStyles.centerVertical)}>
              <Skeleton variant="rounded" height={20} />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.centerVertical)}>
            <Skeleton variant="rounded" height={0} width={100} />
          </div>
        </div>
      ))}
    </div>
  );

  const projectInfoSkeletonLoader = (
    <EPCMInfoContainerDiv className={classNames(ProjectsStyles.loaderWidth, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      <Skeleton variant="circular" height={30} width={30} />
      <div className={classNames(GlobalStyles.flex1)} />
      <div className={classNames(ProjectsStyles.projectsTextContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
        <Skeleton variant="rounded" height={15} width={80} />
        <Skeleton variant="rounded" height={20} width={80} />
      </div>
    </EPCMInfoContainerDiv>
  );

  const canPerformCreateProjectAction = useMemo(() => {
    return canPerformGlobalAction(GlobalAction.ProjectCreate);
  }, [canPerformGlobalAction]);

  const canPerformListProjectsAction = useMemo(() => {
    return canPerformGlobalAction(GlobalAction.ProjectList);
  }, [canPerformGlobalAction]);

  useEffect(() => {
    if (
      inView &&
      !allProjectsQuery.isLoading &&
      !allProjectsQuery.isFetching &&
      !allProjectsQuery.isFetchingNextPage &&
      allProjectsQuery.hasNextPage
    ) {
      void allProjectsQuery.fetchNextPage();
    }
  }, [inView, allProjectsQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({
          queryKey: [QueryNames.Projects, searchQuery, selectedFilterType],
        })
        .then(() => console.log(`In projects, ${QueryNames.Projects} query canceled`));
    };
  }, [queryClient, searchQuery, selectedFilterType]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3, GlobalStyles.overflowHiddenFullHeight)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
        <div className={classNames(ProjectsStyles.pageHeader, GlobalStyles.centerVertical)}>{"Projects"}</div>
        <div className={classNames(GlobalStyles.flex3)} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex2, GlobalStyles.gap2)}>
          {canPerformListProjectsAction && <SearchBar placeholder={"Search your projects"} setSearchQuery={onSearchQueryChange} />}
          {canPerformCreateProjectAction && (
            <div
              className={classNames(ProjectsStyles.newProjectButton, GlobalStyles.centerVertical)}
              onClick={() => onOpenPopup(ProjectsPagePopups.createProject, popupHandler)}
            >
              {"New Project"}
            </div>
          )}
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2, GlobalStyles.overflowHiddenFullHeight)}>
        {canPerformListProjectsAction && (
          <div className={classNames(ProjectsStyles.infoBarContainer, GlobalStyles.flex, GlobalStyles.gap)}>
            <EPCMInfoContainerDiv className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
              <div className={classNames()}>
                <img className={classNames(ProjectsStyles.projectsIconImg)} src={projectIcon} alt="projects" />
              </div>
              <div className={classNames(GlobalStyles.flex1)} />
              <div className={classNames(ProjectsStyles.projectsTextContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
                <div className={classNames(ProjectsStyles.projectsSmallerText)}>{"You manage"}</div>

                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
                  {!allProjectsQuery.isLoading ? (
                    totalProjectsCount || totalProjectsCount === 0 ? (
                      <div className={classNames(ProjectsStyles.projectsBolderText)}>{`${displayNumberWithSpecificNumberOfDigits(
                        totalProjectsCount,
                      )}`}</div>
                    ) : (
                      <div className={classNames(ProjectsStyles.projectsBolderText)}>{"00"}</div>
                    )
                  ) : (
                    <Skeleton variant="rounded" height={15} width={30} />
                  )}
                  <div className={classNames(ProjectsStyles.projectsBolderText)}>{"Projects"}</div>
                </div>
              </div>
            </EPCMInfoContainerDiv>
            <div className={classNames(GlobalStyles.flex1)} />
            <PageFilterContainer smallerFontText={"Filter by"} mainText={"Project Status"}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap15)}>
                <QuickFilterButton
                  isFilterActive={selectedFilterType === FrontendProjectStatus.ACTIVE}
                  headerLineText={""}
                  smallerFontText={"Active"}
                  mainText={"Projects"}
                  onClickFn={() => handleFilterClick(FrontendProjectStatus.ACTIVE)}
                />
                <QuickFilterButton
                  isFilterActive={selectedFilterType === FrontendProjectStatus.INACTIVE}
                  headerLineText={""}
                  smallerFontText={"Inactive"}
                  mainText={"Projects"}
                  onClickFn={() => handleFilterClick(FrontendProjectStatus.INACTIVE)}
                />
                <QuickFilterButton
                  isFilterActive={selectedFilterType === FrontendProjectStatus.COMPLETED}
                  headerLineText={""}
                  smallerFontText={"Completed"}
                  mainText={"Projects"}
                  onClickFn={() => handleFilterClick(FrontendProjectStatus.COMPLETED)}
                />
                <QuickFilterButton
                  isFilterActive={selectedFilterType === FrontendProjectStatus.CANCELLED}
                  headerLineText={""}
                  smallerFontText={"Cancelled"}
                  mainText={"Projects"}
                  onClickFn={() => handleFilterClick(FrontendProjectStatus.CANCELLED)}
                />
              </div>
            </PageFilterContainer>
          </div>
        )}
        {allProjectsData && (totalProjectsCount || totalProjectsCount === 0) ? (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.overflowHiddenFullHeight)}>
            {canPerformListProjectsAction && (
              <>
                <ProjectList projectList={allProjectsData!}>
                  <InfiniteScrollInViewElement
                    key={"InfiniteScrollInViewElementProjects"}
                    reference={ref}
                    infiniteQueryResult={allProjectsQuery}
                    loaderComponent={projectInfoSkeletonLoader}
                  />
                  <div className={classNames(GlobalStyles.flex1)} />
                </ProjectList>
              </>
            )}
          </div>
        ) : (
          projectListSkeletonLoader
        )}
        {popupHandler.get(ProjectsPagePopups.createProject)!.isOpen && (
          <CreateNewProjectPopup
            isOpen={popupHandler.get(ProjectsPagePopups.createProject)!.isOpen}
            closeFn={() => {
              onClosePopup(ProjectsPagePopups.createProject, popupHandler);
            }}
            headerText={popupHeaders.get(ProjectsPagePopups.createProject)}
          />
        )}
        {isResponseAlertPopupOpen && responseType && responseObject && (
          <ResponseAlertPopup
            responseType={responseType}
            responseObject={responseObject}
            isOpen={isResponseAlertPopupOpen}
            closeFn={() => {
              initializeResponseAlertPopup();
              onCloseResponseAlertPopup();
            }}
          />
        )}
      </div>
    </div>
  );
};
