import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config";
import { ApiResponseCalendarRange } from "epcm-common/dist/Types/CalendarTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getGroupTimesheetDates = async (id: number, timesheetGroupId: number, timesheetId: number): Promise<ApiResponseCalendarRange> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/timesheets/${timesheetId}/dates`,
    method: "GET",
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies ApiResponseCalendarRange;
};
