import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import EmployeeInfoBasicStyles from "./EmployeeInfoBasic.module.css";
import defaultAvatar from "../../../../../../assets/images/profile-default-avatar-white.svg";
import { FC } from "react";

interface EmployeeInfoBasicProps {
  employeeName: string;
  employeeCode: string;
}

export const EmployeeInfoBasic: FC<EmployeeInfoBasicProps> = ({ employeeName, employeeCode }) => {
  return (
    <div className={classNames(EmployeeInfoBasicStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
      <div className={classNames(GlobalStyles.flex)}>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(EmployeeInfoBasicStyles.avatar, GlobalStyles.centerVertical)}>
          <img className={classNames(EmployeeInfoBasicStyles.avatarImg)} src={defaultAvatar} alt="avatar" />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      <div
        className={classNames(EmployeeInfoBasicStyles.employeeCredentials, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}
      >
        <div className={classNames(EmployeeInfoBasicStyles.highlightedText)}>{employeeName}</div>
        <div className={classNames(EmployeeInfoBasicStyles.secondaryText)}>{`EmpCode: ${employeeCode}`}</div>
      </div>
    </div>
  );
};
