import { QueryModelWithCountAndData } from "../../../../types/apicallstypes/queryCommons.ts";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { TimesheetGroupNotFinal } from "epcm-common/dist/Types/TimesheetGroupTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getAllProjectTimesheetNotFinalGroups = async (
  id: number,
  periodStart: number,
  periodEnd: number,
  draft?: boolean,
  final?: boolean,
): Promise<QueryModelWithCountAndData<TimesheetGroupNotFinal>> => {
  const params = new URLSearchParams();
  params.set("periodStart", `${periodStart}`);
  params.set("periodEnd", `${periodEnd}`);
  draft && params.set("draft", `${draft}`);
  final && params.set("final", `${final}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/counters/timesheet-groups/not-final`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data as QueryModelWithCountAndData<TimesheetGroupNotFinal>;
};
