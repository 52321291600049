import { FrontendFileIdResponseType } from "../../../types/apicallstypes/GeneralTypes.ts";
import { getManpowerPlan } from "./getManpowerPlan.ts";

interface ApiDefinition {
  getManpowerPlan: (id: number) => Promise<FrontendFileIdResponseType>;
}

export const useEpcmApiProjectManpowerPlan = (): ApiDefinition => {
  const getManpowerPlanConverted = async (id: number): Promise<FrontendFileIdResponseType> => getManpowerPlan(id);

  return {
    getManpowerPlan: getManpowerPlanConverted,
  };
};
