import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import ProjectEmployeeListItemStyles from "./ProjectEmployeeListItem.module.css";
import classNames from "classnames";
import { FC, useEffect, useMemo, useState } from "react";
import viewDetailsIcon from "../../../../../../assets/images/arrow-right-blue.svg";
import deleteIcon from "../../../../../../assets/images/x-mark-black-bg.svg";
import deleteIconHovered from "../../../../../../assets/images/x-mark-red-bg.svg";
import warningIcon from "../../../../../../assets/images/exclamation-mark-red-bg.svg";
import { Fade, Skeleton, Tooltip } from "@mui/material";
import { useEpcmApiProjectsMutations } from "../../../../../../apicalls/projects/mutations/useEpcmApiProjectsMutations.ts";
import { useParams } from "react-router-dom";
import { DeletePopup } from "../../../../../../ui/deletepopup/DeletePopup.tsx";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SubQueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import {
  FrontendMmrStatusEnum,
  FrontendProjectEmployeeMmrDetailedType,
  FrontendProjectEmployeeToMmrType,
} from "../../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEpcmApiProjectEmployee } from "../../../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { capitalizeFirstLetter } from "../../../../../../utils/StringManipulation.ts";
import { useEpcmApiProjectsUtils } from "../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../use-projects-popups.ts";
import ArrowDownBlue from "../../../../../../assets/images/arrow-down-blue-fill.svg";
// import ChangeMmrStatusPopup from "../../../../popups/changemmrstatuspopup/ChangeMmrStatusPopup.tsx";
import { ProjectEmployeeDetailsPopup } from "../../../../popups/viewprojectemployeedetailspopup/ProjectEmployeeDetailsPopup.tsx";
import ChangeEntityStatusPopup from "../../../../popups/changeentitystatuspopup/ChangeEntityStatusPopup.tsx";
import { ChangeStatusPopupEntityType } from "../../../../../../types/PopupChangeEntityStatusType.ts";
import { DefaultAvatar } from "../../../../../../ui/defaultavatar/DefaultAvatar.tsx";
import { useRetrieveUserPermittedActions } from "../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../types/Roles.ts";

interface ProjectEmployeeListItemProps {
  projectEmployeeItem: FrontendProjectEmployeeToMmrType;
}

export const ProjectEmployeeListItem: FC<ProjectEmployeeListItemProps> = ({ projectEmployeeItem }) => {
  const { projectId } = useParams();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { useDeleteProjectEmployeesMutation } = useEpcmApiProjectsMutations();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const queryClient = useQueryClient();
  const [isDeleteButtonHovered, setIsDeleteButtonHovered] = useState<boolean>(false);
  const { getProjectEmployeeMmr } = useEpcmApiProjectEmployee();
  const { getProjectLocation, getProjectPosition } = useEpcmApiProjectsUtils();

  const projectEmployeeMmrQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployeeMmrItem, SubQueryNames.list, parseInt(projectId!), projectEmployeeItem.id, projectEmployeeItem.mmrId],
    queryFn: () => getProjectEmployeeMmr(parseInt(projectId!), projectEmployeeItem.id, projectEmployeeItem.mmrId!, DetailLevel.DETAILED),
    enabled: isAuthorized && projectEmployeeItem.mmrId !== undefined && projectEmployeeItem.mmrId !== null,
    select: (data) => data as FrontendProjectEmployeeMmrDetailedType,
  });
  const projectEmployeeMmrData = useMemo(() => {
    return projectEmployeeMmrQuery.data;
  }, [projectEmployeeMmrQuery.data]);

  const projectEmployeeLocationQuery = useQuery({
    queryKey: [QueryNames.ProjectLocations, parseInt(projectId!), projectEmployeeItem.id, projectEmployeeItem.mmrId],
    queryFn: () => getProjectLocation(parseInt(projectId!), projectEmployeeMmrData!.manpowerPositionDetailed.locationId),

    enabled:
      isAuthorized &&
      projectEmployeeItem.mmrId !== undefined &&
      projectEmployeeItem.mmrId !== null &&
      projectEmployeeMmrData?.manpowerPositionDetailed.locationId !== undefined &&
      projectEmployeeMmrData?.manpowerPositionDetailed.locationId !== null,
  });
  const projectEmployeeLocationData = useMemo(() => {
    return projectEmployeeLocationQuery.data;
  }, [projectEmployeeLocationQuery.data]);

  const projectEmployeePositionQuery = useQuery({
    queryKey: [QueryNames.ProjectPositions, parseInt(projectId!), projectEmployeeItem.id, projectEmployeeItem.mmrId],
    queryFn: () => getProjectPosition(parseInt(projectId!), projectEmployeeMmrData!.manpowerPositionDetailed.positionId!),

    enabled:
      isAuthorized &&
      projectEmployeeItem.mmrId !== undefined &&
      projectEmployeeItem.mmrId !== null &&
      projectEmployeeMmrData?.manpowerPositionDetailed.positionId !== undefined &&
      projectEmployeeMmrData?.manpowerPositionDetailed.positionId !== null,
  });

  const projectEmployeePositionData = useMemo(() => {
    return projectEmployeePositionQuery.data;
  }, [projectEmployeePositionQuery.data]);

  const isMmrLocationDataLoading = useMemo(() => {
    return projectEmployeeLocationQuery.isLoading;
  }, [projectEmployeeLocationQuery.isLoading]);

  const isMmrPositionDataLoading = useMemo(() => {
    return projectEmployeePositionQuery.isLoading;
  }, [projectEmployeePositionQuery.isLoading]);

  const isMmrDataLoading = useMemo(() => {
    return projectEmployeeMmrQuery.isLoading;
  }, [projectEmployeeMmrQuery.isLoading]);

  const deleteProjectEmployeesMutation = useDeleteProjectEmployeesMutation(parseInt(projectId!), projectEmployeeItem.id, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Project Employee was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeesMmrStatusCount, parseInt(projectId!)] });
      void queryClient.invalidateQueries({ queryKey: [QueryNames.Employees, parseInt(projectId!)] });
      onCloseLoaderPopup();
    },
  });

  const hasMissingData = useMemo(() => {
    return (
      !projectEmployeeMmrData?.status ||
      !projectEmployeeMmrData?.manpowerPositionDetailed.locationId ||
      !projectEmployeeMmrData?.manpowerPositionDetailed.positionId
    );
  }, [projectEmployeeMmrData]);

  const canDeleteProjectEmployee = canPerformProjectAction(ProjectAction.ProjectEmployeeDelete);
  const canEditProjectEmployee = canPerformProjectAction(ProjectAction.ProjectEmployeeUpdate);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({
          queryKey: [QueryNames.ProjectEmployeeMmrItem, SubQueryNames.list, parseInt(projectId!), projectEmployeeItem.id, projectEmployeeItem.mmrId],
        })
        .then(() => {
          console.log(`Query ${QueryNames.ProjectEmployeeMmrItem} for project ${projectId} and project employee ${projectEmployeeItem.id} cancelled`);
        });
    };
  }, [queryClient, projectId, projectEmployeeItem.id, projectEmployeeItem.mmrId]);

  useEffect(() => {
    return () => {
      projectEmployeeMmrData?.manpowerPositionDetailed.locationId &&
        queryClient
          .cancelQueries({ queryKey: [QueryNames.ProjectLocations, parseInt(projectId!), projectEmployeeItem.id, projectEmployeeItem.mmrId] })
          .then(() => console.log(`Query ${QueryNames.ProjectLocations} for project ${projectId} cancelled`));
    };
  }, [queryClient, projectId, projectEmployeeItem.id, projectEmployeeItem.mmrId, projectEmployeeMmrData?.manpowerPositionDetailed.locationId]);

  useEffect(() => {
    return () => {
      projectEmployeeMmrData?.manpowerPositionDetailed.locationId &&
        queryClient
          .cancelQueries({ queryKey: [QueryNames.ProjectPositions, parseInt(projectId!), projectEmployeeItem.id, projectEmployeeItem.mmrId] })
          .then(() => console.log(`Query ${QueryNames.ProjectPositions} for project ${projectId} cancelled`));
    };
  }, [queryClient, projectId, projectEmployeeItem.id, projectEmployeeItem.mmrId, projectEmployeeMmrData]);

  return (
    <>
      <div className={classNames(ProjectEmployeeListItemStyles.projectEmployeeItemContainer, GlobalStyles.flex, GlobalStyles.gap025)}>
        <div
          onClick={() => {
            onOpenPopup(ProjectsPagePopups.viewProjectEmployee, popupHandler);
          }}
          className={classNames(ProjectEmployeeListItemStyles.projectEmployeeNamePanel, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <div className={classNames(GlobalStyles.flex1)} />
            <DefaultAvatar avatarSizeInEm={3} avatarImgSizeInEm={1.8} />
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
            <div className={classNames(ProjectEmployeeListItemStyles.employeeDetailsText)}>{projectEmployeeItem.name}</div>

            <div className={classNames()}>{`Code: ${projectEmployeeItem.code}`}</div>
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
          {((hasMissingData && !projectEmployeeItem.mmrId) ||
            (projectEmployeeItem.mmrId && !projectEmployeeMmrQuery.isLoading && hasMissingData)) && (
            <div className={classNames(GlobalStyles.centerVertical)}>
              <Tooltip TransitionComponent={Fade} title={"Has missing data"} placement="top" arrow>
                <img className={classNames(ProjectEmployeeListItemStyles.deleteIconImg)} src={warningIcon} alt="warning" />
              </Tooltip>
            </div>
          )}
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
            <div className={classNames()}>{"Mobilization"}</div>
            {projectEmployeeMmrData && isMmrDataLoading ? (
              <Skeleton variant={"text"} width={120}></Skeleton>
            ) : (
              <div
                className={classNames(
                  projectEmployeeMmrData?.status === FrontendMmrStatusEnum.APPROVED && ProjectEmployeeListItemStyles.mobilizationStatusApproved,
                  projectEmployeeMmrData?.status === FrontendMmrStatusEnum.PENDING && ProjectEmployeeListItemStyles.mobilizationStatusPending,
                  projectEmployeeMmrData?.status === FrontendMmrStatusEnum.REJECTED && ProjectEmployeeListItemStyles.mobilizationStatusRejected,
                  ProjectEmployeeListItemStyles.boldText,
                )}
              >
                {projectEmployeeMmrData?.status ? capitalizeFirstLetter(projectEmployeeMmrData?.status) : "--"}
              </div>
            )}
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
          {projectEmployeeItem.mmrStatus === FrontendMmrStatusEnum.PENDING && canEditProjectEmployee && (
            <div
              className={classNames(
                ProjectEmployeeListItemStyles.changeMobcilizationContainer,
                GlobalStyles.flex,
                GlobalStyles.gap05,
                GlobalStyles.elementWithCursor,
              )}
              onClick={() => onOpenPopup(ProjectsPagePopups.changeEntityStatus, popupHandler)}
            >
              <div className={classNames(ProjectEmployeeListItemStyles.fileName, GlobalStyles.centerVertical)}>{"Change"}</div>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(ProjectEmployeeListItemStyles.arrowDownBlueIconImg)} src={ArrowDownBlue} alt="arrowDown" />
              </div>
            </div>
          )}
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
            <div className={classNames()}>{"MMR Reference"}</div>

            <div className={classNames(ProjectEmployeeListItemStyles.employeeDetailsText)}>{projectEmployeeMmrData?.mmrReference}</div>
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        <div
          onClick={() => {
            onOpenPopup(ProjectsPagePopups.viewProjectEmployee, popupHandler);
          }}
          className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}
        >
          <div className={classNames()}>{"Location"}</div>
          {projectEmployeeMmrData && isMmrLocationDataLoading ? (
            <Skeleton variant={"text"} width={120}></Skeleton>
          ) : projectEmployeeLocationData ? (
            <div className={classNames(ProjectEmployeeListItemStyles.employeeDetailsText)}>
              {capitalizeFirstLetter(projectEmployeeLocationData.description)}
            </div>
          ) : (
            <div>{"--"}</div>
          )}
        </div>
        <div
          onClick={() => {
            onOpenPopup(ProjectsPagePopups.viewProjectEmployee, popupHandler);
          }}
          className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}
        >
          <div className={classNames()}>{"Role"}</div>
          {projectEmployeeMmrData && isMmrPositionDataLoading ? (
            <Skeleton variant={"text"} width={120}></Skeleton>
          ) : projectEmployeePositionData ? (
            <div className={classNames(ProjectEmployeeListItemStyles.employeeDetailsText)}>
              {capitalizeFirstLetter(projectEmployeePositionData.description)}
            </div>
          ) : (
            <div>{"--"}</div>
          )}
        </div>
        <div
          className={classNames(ProjectEmployeeListItemStyles.viewDetailsContainer, GlobalStyles.flex, GlobalStyles.flex05, GlobalStyles.gap025)}
          onClick={() => {
            onOpenPopup(ProjectsPagePopups.viewProjectEmployee, popupHandler);
          }}
        >
          <div className={classNames(GlobalStyles.flex1)} />
          {!projectEmployeeItem.canBeDeleted || !canDeleteProjectEmployee ? (
            <div className={classNames(ProjectEmployeeListItemStyles.fillerDiv)}></div>
          ) : (
            <Tooltip TransitionComponent={Fade} title={"Remove from project"} placement="top" arrow>
              <div
                className={classNames(GlobalStyles.centerVertical)}
                onMouseEnter={() => setIsDeleteButtonHovered(true)}
                onMouseLeave={() => setIsDeleteButtonHovered(false)}
                onClick={(event) => {
                  event.stopPropagation();

                  onOpenUtilPopup();
                }}
              >
                <img
                  className={classNames(ProjectEmployeeListItemStyles.deleteIconImg)}
                  src={isDeleteButtonHovered ? deleteIconHovered : deleteIcon}
                  alt="delete"
                />
              </div>
            </Tooltip>
          )}
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.centerVertical)}>{"View"}</div>
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(ProjectEmployeeListItemStyles.viewDetailsIconImg)} src={viewDetailsIcon} alt="view" />
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
      </div>

      {popupHandler.get(ProjectsPagePopups.changeEntityStatus)!.isOpen && projectEmployeeMmrData && (
        <ChangeEntityStatusPopup
          isOpen={popupHandler.get(ProjectsPagePopups.changeEntityStatus)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.changeEntityStatus, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.changeEntityStatus)}
          secondaryHeaderText={projectEmployeeItem.employeeId.toString()}
          entityInfo={{
            type: ChangeStatusPopupEntityType.MMR,
            projectId: parseInt(projectId!),
            projectEmployeeId: projectEmployeeItem.id,
            mmrId: projectEmployeeItem.mmrId!,
          }}
          //currentStatus={projectEmployeeItem.mmrStatus!}
        />
      )}
      {projectEmployeeItem.id && projectEmployeeItem.employeeId && popupHandler.get(ProjectsPagePopups.viewProjectEmployee)!.isOpen && (
        <ProjectEmployeeDetailsPopup
          isOpen={popupHandler.get(ProjectsPagePopups.viewProjectEmployee)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.viewProjectEmployee, popupHandler);
          }}
          employeeId={projectEmployeeItem.id}
          globalEmployeeId={projectEmployeeItem.employeeId}
          projectId={parseInt(projectId!)}
        />
      )}
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteProjectEmployeesMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </>
  );
};
