import classNames from "classnames";
import DefaultAvatarStyles from "./DefaultAvatar.module.css";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import { FC } from "react";
import avatarDefault from "../../assets/images/profile-default-avatar-white.svg";

interface DefaultAvatarProps {
  avatarSizeInEm?: number;
  avatarImgSizeInEm?: number;
}

const DEFAULT_AVATAR_SIZE = 2;
const DEFAULT_AVATAR_IMG_SIZE = 1;

// TODO: When avatars are available from backend,
//  this should render only if the actual avatar does not exist
export const DefaultAvatar: FC<DefaultAvatarProps> = ({ avatarSizeInEm, avatarImgSizeInEm }) => {
  return (
    <div
      style={{
        height: `${avatarSizeInEm ?? DEFAULT_AVATAR_SIZE}em`,
        width: `${avatarSizeInEm ?? DEFAULT_AVATAR_SIZE}em`,
      }}
      className={classNames(DefaultAvatarStyles.defaultAvatar, GlobalStyles.centerVertical)}
    >
      <img
        style={{
          height: `${avatarImgSizeInEm ?? DEFAULT_AVATAR_IMG_SIZE}em`,
        }}
        src={avatarDefault}
        alt="avatar"
      />
    </div>
  );
};
