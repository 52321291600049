import classNames from "classnames";
import CreateMmrSectionStyles from "./CreateMmrSection.module.css";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import { CreateMmrActionBar } from "../createmmractionbar/CreateMmrActionBar.tsx";

import MmrCreateForm from "../mmrcreateform/MmrCreateForm.tsx";
import CreateMmrBottomBar from "../createmmrbottombar/CreateMmrBottomBar.tsx";
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";

import { ApiRequestBodyMmrCreate, ApiRequestBodyMmrUpdate } from "epcm-common/dist/Types/MmrTypes";

import { useQueryClient } from "@tanstack/react-query";
import { useResponseAlertPopupStateType } from "../../../../../../../utils/use-response-alert-popup-state.ts";
import { ProjectEmployeeDetailsPopupTypeEnum } from "../../../../../../../types/projects/ProjectEmployeesTypes.ts";
import { useEpcmApiProjectEmployeeMutations } from "../../../../../../../apicalls/projects/projectemployee/mutations/useEpcmApiProjectEmployeeMutations.ts";
import { usePopupState } from "../../../../../../../utils/use-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SubQueryNames,
  SuccessCallbackDataType,
} from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { convertDateToMilSecs } from "../../../../../../../utils/DateManipulation.ts";
import { SelectManpowerCodePopup } from "../../../../selectmanpowercodepopup/SelectManpowerCodePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../../use-projects-popups.ts";

interface CreateMmrSectionProps {
  projectId: number;
  projectName: string;
  employeeId: number;
  isCloned: boolean;
  isEditMode: boolean;
  manpowerCodeName: string | null;
  setManpowerCodeName: Dispatch<SetStateAction<string | null>>;
  manpowerCodeId: number | null;
  setManpowerCodeId: Dispatch<SetStateAction<number | null>>;
  selectedMmrId: number | null;
  setSelectedMmrId: Dispatch<SetStateAction<number | null>>;
  actualMobilizationDate: Date | null;
  setActualMobilizationDate: Dispatch<SetStateAction<Date | null>>;
  anticipatedMobilizationDate: Date | null;
  setAnticipatedMobilizationDate: Dispatch<SetStateAction<Date | null>>;
  actualDemobilizationDate: Date | null;
  setActualDemobilizationDate: Dispatch<SetStateAction<Date | null>>;
  anticipatedDemobilizationDate: Date | null;
  setAnticipatedDemobilizationDate: Dispatch<SetStateAction<Date | null>>;
  justificationForRequest: string | null;
  setJustificationForRequest: Dispatch<SetStateAction<string | null>>;
  keyPersonnel: boolean;
  setKeyPersonnel: Dispatch<SetStateAction<boolean>>;
  subcontractor: string | null;
  setSubcontractor: Dispatch<SetStateAction<string | null>>;
  clientInterviewDate: Date | null;
  setClientInterviewDate: Dispatch<SetStateAction<Date | null>>;
  approvalRecord: string | null;
  setApprovalRecord: Dispatch<SetStateAction<string | null>>;
  typeOfMobilization: string | null;
  setTypeOfMobilization: Dispatch<SetStateAction<string | null>>;
  subPositionId: number | undefined;
  setSubPositionId: Dispatch<SetStateAction<number | undefined>>;
  documentNumber: string | null;
  setDocumentNumber: Dispatch<SetStateAction<string | null>>;
  setPopupType: Dispatch<SetStateAction<ProjectEmployeeDetailsPopupTypeEnum>>;
  setIsCloned: Dispatch<SetStateAction<boolean>>;
  revisionNumber: number | undefined;
  disciplineId: number | null;
  setDisciplineId: Dispatch<SetStateAction<number | null>>;
}

const CreateMmrSection: FC<CreateMmrSectionProps> = ({
  projectId,
  projectName,
  employeeId,
  isEditMode,
  isCloned,
  manpowerCodeName,
  manpowerCodeId,
  actualMobilizationDate,
  setActualMobilizationDate,
  actualDemobilizationDate,
  setActualDemobilizationDate,
  anticipatedMobilizationDate,
  setAnticipatedMobilizationDate,
  anticipatedDemobilizationDate,
  setAnticipatedDemobilizationDate,
  justificationForRequest,
  setJustificationForRequest,
  keyPersonnel,
  setKeyPersonnel,
  subcontractor,
  setSubcontractor,
  clientInterviewDate,
  setClientInterviewDate,
  approvalRecord,
  setApprovalRecord,
  typeOfMobilization,
  setTypeOfMobilization,
  subPositionId,
  setSubPositionId,
  setIsCloned,
  setPopupType,
  setManpowerCodeId,
  setManpowerCodeName,
  setSelectedMmrId,
  selectedMmrId,
  revisionNumber,
  disciplineId,
  setDisciplineId,
}) => {
  const queryClient = useQueryClient();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();

  const { useCreateMmrMutation, useUpdateMmrMutation } = useEpcmApiProjectEmployeeMutations();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const isFormFieldsValid = useMemo(() => {
    if (isEditMode) {
      if (actualMobilizationDate && anticipatedDemobilizationDate) {
        if (actualDemobilizationDate) {
          {
            return actualMobilizationDate <= anticipatedDemobilizationDate && actualMobilizationDate <= actualDemobilizationDate;
          }
        } else {
          {
            return actualMobilizationDate <= anticipatedDemobilizationDate;
          }
        }
      }
      if (actualDemobilizationDate && anticipatedMobilizationDate) {
        if (actualMobilizationDate) {
          {
            return actualDemobilizationDate >= anticipatedMobilizationDate && actualDemobilizationDate >= actualMobilizationDate;
          }
        } else {
          {
            return actualDemobilizationDate >= anticipatedMobilizationDate;
          }
        }
      }

      return !!(
        selectedMmrId &&
        manpowerCodeId &&
        subPositionId &&
        anticipatedMobilizationDate &&
        anticipatedDemobilizationDate &&
        anticipatedMobilizationDate < anticipatedDemobilizationDate &&
        keyPersonnel !== null &&
        !!subcontractor &&
        typeOfMobilization &&
        disciplineId
      );
    }

    return !!(
      manpowerCodeId &&
      subPositionId &&
      anticipatedMobilizationDate &&
      anticipatedDemobilizationDate &&
      anticipatedMobilizationDate < anticipatedDemobilizationDate &&
      keyPersonnel !== null &&
      !!subcontractor &&
      typeOfMobilization &&
      justificationForRequest &&
      disciplineId
    );
  }, [
    isEditMode,
    selectedMmrId,
    subcontractor,
    manpowerCodeId,
    subPositionId,
    anticipatedMobilizationDate,
    anticipatedDemobilizationDate,
    keyPersonnel,
    typeOfMobilization,
    actualMobilizationDate,
    actualDemobilizationDate,
    justificationForRequest,
    disciplineId,
  ]);

  const issueNewMmrTooltipText = useMemo(() => {
    return isFormFieldsValid ? "" : "Please fill all the fields";
  }, [isFormFieldsValid]);

  const createMmrMutation = useCreateMmrMutation(projectId, employeeId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Project Employee Mmr was created successfully!" });
      setSelectedMmrId(data.data?.id ?? null);
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateMmrMutation = useUpdateMmrMutation(projectId, employeeId, selectedMmrId!, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "MMR updated successfully" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeeMmr, projectId, employeeId, selectedMmrId!] });
      onCloseLoaderPopup();
    },
  });

  const onUpdateMmr = useCallback(() => {
    updateMmrMutation.mutate({
      editDateTimestamp: convertDateToMilSecs(new Date()),
      actualDemobilizationDateTimestamp: actualDemobilizationDate ? convertDateToMilSecs(actualDemobilizationDate) : null,
      actualMobilizationDateTimestamp: actualMobilizationDate ? convertDateToMilSecs(actualMobilizationDate) : null,
      anticipatedDemobilizationDateTimestamp: convertDateToMilSecs(anticipatedDemobilizationDate!),
      anticipatedMobilizationDateTimestamp: convertDateToMilSecs(anticipatedMobilizationDate!),
      subcontractor: subcontractor ?? undefined,
    } satisfies ApiRequestBodyMmrUpdate);

    onOpenLoaderPopup();
  }, [
    updateMmrMutation,
    actualDemobilizationDate,
    actualMobilizationDate,
    anticipatedDemobilizationDate,
    anticipatedMobilizationDate,
    subcontractor,
    onOpenLoaderPopup,
  ]);

  const onSubmit = useCallback(() => {
    if (!isFormFieldsValid) {
      return;
    }

    const createMmrMutationData: ApiRequestBodyMmrCreate = {
      typeOfMobilization: typeOfMobilization ? typeOfMobilization : undefined,
      anticipatedMobilizationDate: convertDateToMilSecs(anticipatedMobilizationDate!),
      actualMobilizationDate: actualMobilizationDate ? convertDateToMilSecs(actualMobilizationDate) : undefined,
      anticipatedDemobilizationDate: convertDateToMilSecs(anticipatedDemobilizationDate!),
      actualDemobilizationDate: actualDemobilizationDate ? convertDateToMilSecs(actualDemobilizationDate) : undefined,
      justificationForRequest: justificationForRequest!,
      keyPersonnel: keyPersonnel!,
      manpowerPositionId: manpowerCodeId!,
      subPositionId: subPositionId!,
      approvalRecord: approvalRecord ? approvalRecord : undefined,
      clientInterview: clientInterviewDate ? convertDateToMilSecs(clientInterviewDate) : undefined,
      subcontractor: subcontractor!,
      disciplineId: disciplineId!,
    } satisfies ApiRequestBodyMmrCreate;

    if (isEditMode && selectedMmrId) {
      onUpdateMmr();
      return;
    }

    createMmrMutation.mutate(createMmrMutationData);
    onOpenLoaderPopup();
  }, [
    clientInterviewDate,
    onOpenLoaderPopup,
    typeOfMobilization,
    anticipatedMobilizationDate,
    actualMobilizationDate,
    anticipatedDemobilizationDate,
    actualDemobilizationDate,
    justificationForRequest,
    keyPersonnel,
    manpowerCodeId,
    subPositionId,
    isFormFieldsValid,
    approvalRecord,
    createMmrMutation,
    isEditMode,
    onUpdateMmr,
    selectedMmrId,
  ]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeeMmr, projectId, employeeId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeeMmrItem, projectId, employeeId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeeMmrItem, SubQueryNames.list, projectId, employeeId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, projectId] });
  }, [queryClient, projectId, employeeId]);

  return (
    <div
      className={classNames(
        CreateMmrSectionStyles.employeeExtraInfoContainer,
        GlobalStyles.flex,
        GlobalStyles.flex2,
        GlobalStyles.gap075,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <CreateMmrActionBar
        openSelectManpowerCodePopup={() => onOpenPopup(ProjectsPagePopups.selectManpowerCode, popupHandler)}
        manpowerCode={manpowerCodeName}
        isEditMode={isEditMode}
        revisionNumber={revisionNumber}
      />

      <div className={classNames(GlobalStyles.flex, GlobalStyles.overflowHiddenFullHeight, CreateMmrSectionStyles.mmrContainer)}>
        <MmrCreateForm
          selectedCode={manpowerCodeId}
          projectId={projectId}
          projectEmployeeId={employeeId}
          mmrId={selectedMmrId}
          actualMobilizationDate={actualMobilizationDate}
          setActualMobilizationDate={setActualMobilizationDate}
          anticipatedMobilizationDate={anticipatedMobilizationDate}
          setAnticipatedMobilizationDate={setAnticipatedMobilizationDate}
          actualDemobilizationDate={actualDemobilizationDate}
          setActualDemobilizationDate={setActualDemobilizationDate}
          anticipatedDemobilizationDate={anticipatedDemobilizationDate}
          setAnticipatedDemobilizationDate={setAnticipatedDemobilizationDate}
          justificationForRequest={justificationForRequest}
          setJustificationForRequest={setJustificationForRequest}
          keyPersonnel={keyPersonnel}
          setKeyPersonnel={setKeyPersonnel}
          subcontractor={subcontractor}
          setSubcontractor={setSubcontractor}
          clientInterviewDate={clientInterviewDate}
          setClientInterviewDate={setClientInterviewDate}
          approvalRecord={approvalRecord}
          setApprovalRecord={setApprovalRecord}
          typeOfMobilization={typeOfMobilization}
          setTypeOfMobilization={setTypeOfMobilization}
          selectedSubposition={subPositionId}
          setSelectedSubposition={setSubPositionId}
          disciplineId={disciplineId}
          setDisciplineId={setDisciplineId}
          isEditMode={isEditMode}
          isCloned={isCloned}
        />
      </div>

      <CreateMmrBottomBar
        setPopupType={setPopupType}
        setIsCloned={setIsCloned}
        onSubmit={onSubmit}
        isFormValid={isFormFieldsValid}
        issueNewMmrTooltipText={issueNewMmrTooltipText}
        isEditMode={isEditMode}
      />
      {popupHandler.get(ProjectsPagePopups.selectManpowerCode)!.isOpen && (
        <SelectManpowerCodePopup
          projectId={projectId}
          setSelectedManpowerCode={setManpowerCodeId}
          isOpen={popupHandler.get(ProjectsPagePopups.selectManpowerCode)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.selectManpowerCode, popupHandler);
          }}
          setSubpositionId={setSubPositionId}
          selectedManpowerCode={manpowerCodeId}
          setSelectedManpowerCodeName={setManpowerCodeName}
          secondaryHeaderText={projectName}
          headerText={popupHeaders.get(ProjectsPagePopups.selectManpowerCode)}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              setIsCloned(false);
              setPopupType(ProjectEmployeeDetailsPopupTypeEnum.view);
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </div>
  );
};
export default CreateMmrSection;
