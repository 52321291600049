import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ProjectEmployeeAccordionStyles from "./GlobalEmployeeAccordion.module.css";
import { FC } from "react";
import { EPCMAccordion } from "../../../../../../../ui/epcmaccordion/EPCMAccordion.tsx";
import classNames from "classnames";
import arrow_down from "../../../../../../../assets/images/arrow-down-dark-blue-fill.svg";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";

interface AccordionProps {
  title: string;
  content: string | undefined;
}

const GlobalEmployeeAccordion: FC<AccordionProps> = ({ title, content }) => {
  const contentArray = content?.split(",");
  return (
    <EPCMAccordion className={ProjectEmployeeAccordionStyles.container} square={true} disableGutters={true}>
      <AccordionSummary
        expandIcon={<img src={arrow_down} alt="expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classNames(ProjectEmployeeAccordionStyles.summary, GlobalStyles.flex1)}
      >
        <div className={classNames(ProjectEmployeeAccordionStyles.heading)}>{title}</div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          {contentArray ? (
            contentArray.map((item, index) => (
              <div key={index} className={classNames(ProjectEmployeeAccordionStyles.details)}>
                {item}
              </div>
            ))
          ) : (
            <div className={classNames(GlobalStyles.emptyListMsgSmall)}>No {title}</div>
          )}
        </div>
      </AccordionDetails>
    </EPCMAccordion>
  );
};

export default GlobalEmployeeAccordion;
