import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { ApiRequestBodyProjectUpdate } from "epcm-common/dist/Types/ProjectTypes";

const API = `${EPCM_API_URLS.projects}`;

export const updateProject = async (projectId: number, updatedProjectData: ApiRequestBodyProjectUpdate) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}`,
    method: "PUT",
    data: updatedProjectData,
  } as AxiosRequestConfig;

  return epcmAxiosInstance(config);
};
