import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import { FC, useState } from "react";
import ViewUngroupedEmployeesStyles from "./ViewUngroupedEmployeesStyles.module.css";

import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import SelectGroupPopup from "../selectgrouppopup/SelectGroupPopup.tsx";
import { ProjectTimesheetDisplayListTypeEnum } from "../../../../types/projects/ProjectTimesheetsTypes.ts";
import GroupTimesheetVersatileList from "../timesheetgroupsettingspopup/grouptimesheetversatilelist/GroupTimesheetVersatileList.tsx";
import { SearchBar } from "../../../../ui/searchbar/SearchBar.tsx";
import SearchInputStyles from "../../../../ui/searchbar/SearchInputStyles.module.css";

interface ViewUngroupedEmployeesProps extends PopupType {
  projectId: number;
  groupId: number;
}

const ViewUngroupedEmployees: FC<ViewUngroupedEmployeesProps> = ({ isOpen, closeFn, headerText, secondaryHeaderText, projectId, groupId }) => {
  const [searchMember, setSearchMember] = useState<string>("");
  const [newGroupMembers, setNewGroupMembers] = useState<number[]>([]);
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();

  return (
    <PopupStructure
      popupButtons={[
        { text: "Close", buttonType: PopupButtonTypeEnum.neutral, action: closeFn },
        {
          text: "Add to Group",
          buttonType: PopupButtonTypeEnum.main,
          tooltipText: newGroupMembers.length > 0 ? "" : "Please select at least one employee",
          disabled: newGroupMembers.length == 0,
          action: () => onOpenPopup(ProjectsPagePopups.selectGroup, popupHandler),
        },
      ]}
      overrideContentContainerStyleClass={""}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.overflowHiddenFullHeight,
          GlobalStyles.gap,
          ViewUngroupedEmployeesStyles.contentContainer,
        )}
      >
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ViewUngroupedEmployeesStyles.headerContainer)}
        >
          <div>Select ungrouped employees to add them in a new or existing group</div>
          <SearchBar
            setSearchQuery={setSearchMember}
            searchBarContainerStyles={classNames(
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.gap05,
              SearchInputStyles.searchInputContainer,
            )}
            placeholder={"Search for Employee"}
          />
          <GroupTimesheetVersatileList
            newGroupMembers={newGroupMembers}
            setNewGroupMembers={setNewGroupMembers}
            projectId={projectId}
            searchMember={searchMember}
            groupId={groupId}
            listType={ProjectTimesheetDisplayListTypeEnum.projectEmployees}
            ungrouped
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, ViewUngroupedEmployeesStyles.actionBtnContainer)}>
          <div
            className={classNames(ViewUngroupedEmployeesStyles.actionBtnItem, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}
          >
            <div className={classNames(ViewUngroupedEmployeesStyles.membersLength)}> {newGroupMembers.length}</div>
            <div className={classNames(ViewUngroupedEmployeesStyles.selectedText)}>Selected</div>
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          {newGroupMembers.length > 0 && (
            <div
              onClick={() => setNewGroupMembers([])}
              className={classNames(
                GlobalStyles.flex,
                ViewUngroupedEmployeesStyles.actionBtnItem,
                ViewUngroupedEmployeesStyles.clearBtn,
                GlobalStyles.elementWithCursor,
              )}
            >
              Clear All
            </div>
          )}
        </div>
      </div>
      {popupHandler.get(ProjectsPagePopups.selectGroup)!.isOpen && (
        <SelectGroupPopup
          newGroupMembers={newGroupMembers}
          setNewGroupMembers={setNewGroupMembers}
          projectId={projectId}
          isOpen={popupHandler.get(ProjectsPagePopups.selectGroup)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.selectGroup, popupHandler);
          }}
          closeOnSuccess={closeFn}
          headerText={popupHeaders.get(ProjectsPagePopups.selectGroup)}
        />
      )}
    </PopupStructure>
  );
};

export default ViewUngroupedEmployees;
