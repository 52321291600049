import React, { Dispatch, SetStateAction, useState } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ConfigurationSetUpCalendarWorkingDaysPopupStyles from "./ConfigurationSetUpCalendarWorkingDaysPopup.module.css";
import checkIcon from "../../../../assets/images/tick-white.svg";
import InfoMessageIcon from "../../../../assets/images/info-message-icon.svg";
import ConfigurationSetUpCalendarPopupStyles from "../configurationsetupcalendarpopup/ConfigurationSetUpCalendarPopup.module.css";
import { DaysOfWeek } from "epcm-common/dist/Types/GeneralTypes";
import ConfigurationSetUpCalendarWorkingDaysPopupItem from "./workingDaysPopupItem/ConfigurationSetUpCalendarWorkingDaysPopupItem";
import { FrontendProjectLocationLimited } from "../../../../types/apicallstypes/ProjectsUtilsApiTypes";
import { FrontendLocationWorkingDays } from "../../../../types/projects/CalendarTypes.ts";

interface ConfigurationSetUpCalendarWorkingDaysPopupProps {
  selectedLocations: FrontendProjectLocationLimited[];
  locationsWorkingDays: FrontendLocationWorkingDays[];
  setLocationsWorkingDays: Dispatch<SetStateAction<FrontendLocationWorkingDays[]>>;
}

const ConfigurationSetUpCalendarWorkingDaysPopup: React.FC<ConfigurationSetUpCalendarWorkingDaysPopupProps> = ({
  selectedLocations,
  locationsWorkingDays,
  setLocationsWorkingDays,
}) => {
  const [appliesToAllLocations, setAppliesToAllLocations] = useState(true);
  const [originalWorkingDays, setOriginalWorkingDays] = useState<FrontendLocationWorkingDays[]>(locationsWorkingDays);

  function toggleDaySelection(day: DaysOfWeek, id: number | undefined): void {
    const updatedLocations = locationsWorkingDays.map((location) => {
      if (location.locationId === id) {
        const workingDays = location.workingDays.includes(day)
          ? location.workingDays.filter((workingDay) => workingDay !== day)
          : [...location.workingDays, day];

        console.log({ ...location, workingDays });
        return { ...location, workingDays };
      }
      return location;
    });

    setLocationsWorkingDays(updatedLocations);
  }

  function handleAppliesToAllLocationsToggle(): void {
    setAppliesToAllLocations((prev) => {
      const newAppliesToAllLocations = !prev;

      if (newAppliesToAllLocations) {
        setOriginalWorkingDays(locationsWorkingDays.map((location) => ({ ...location })));

        const homeOfficeWorkingDays = locationsWorkingDays.find((location) => location.locationId === 0)?.workingDays || [];
        console.log(`Home Office Working Days: ${homeOfficeWorkingDays}`);

        const updatedLocations = locationsWorkingDays.map((location) => ({
          ...location,
          workingDays: homeOfficeWorkingDays,
          allSelectedLocations: newAppliesToAllLocations,
        }));

        setLocationsWorkingDays(updatedLocations);
      } else {
        const updatedLocationsWorkingDays = originalWorkingDays.map((location) => ({
          ...location,
          allSelectedLocations: newAppliesToAllLocations,
        }));

        setLocationsWorkingDays([...updatedLocationsWorkingDays]);
      }

      return newAppliesToAllLocations;
    });
  }

  return (
    <>
      <div className={classNames(ConfigurationSetUpCalendarWorkingDaysPopupStyles.descriptionText)}>{"Select locations' Working Days"}</div>
      <div
        className={classNames(
          ConfigurationSetUpCalendarWorkingDaysPopupStyles.node,
          GlobalStyles.flex,
          GlobalStyles.overflowHiddenFullHeight,
          GlobalStyles.flexDirectionColumn,
        )}
      >
        <div className={classNames(ConfigurationSetUpCalendarWorkingDaysPopupStyles.parentDiv)}>
          {appliesToAllLocations ? (
            <ConfigurationSetUpCalendarWorkingDaysPopupItem location={locationsWorkingDays[0]} toggleDaySelection={toggleDaySelection} />
          ) : (
            selectedLocations.map((location: FrontendProjectLocationLimited) => (
              <>
                <div className={classNames(ConfigurationSetUpCalendarWorkingDaysPopupStyles.headerText)}>{location.description}</div>
                <ConfigurationSetUpCalendarWorkingDaysPopupItem
                  key={location.id}
                  location={locationsWorkingDays.find((day) => day.locationId == location.id)!}
                  toggleDaySelection={toggleDaySelection}
                />
              </>
            ))
          )}
        </div>
      </div>
      <div
        className={classNames(
          ConfigurationSetUpCalendarWorkingDaysPopupStyles.checkBoxContainer,
          GlobalStyles.flex,
          GlobalStyles.gap05,
          GlobalStyles.centerHorizontal,
        )}
        onClick={handleAppliesToAllLocationsToggle}
      >
        <div
          className={classNames(
            ConfigurationSetUpCalendarWorkingDaysPopupStyles.checkBox,
            appliesToAllLocations && ConfigurationSetUpCalendarWorkingDaysPopupStyles.checkBoxChecked,
            GlobalStyles.centerVertical,
          )}
        >
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img src={checkIcon} alt="check" className={classNames(ConfigurationSetUpCalendarWorkingDaysPopupStyles.checkIconImg)} />
          </div>
        </div>
        <div>{"Applies to All Locations"}</div>
      </div>
      <div className={classNames(GlobalStyles.flex1)}></div>

      <div className={classNames(ConfigurationSetUpCalendarWorkingDaysPopupStyles.infoContainer, GlobalStyles.flex, GlobalStyles.gap025)}>
        <img src={InfoMessageIcon} alt="info message icon" />
        <div className={classNames(ConfigurationSetUpCalendarPopupStyles.infoText)}>
          {"Unselected days are considered days off. You can change them later in Calendar Settings."}
        </div>
      </div>
    </>
  );
};

export default ConfigurationSetUpCalendarWorkingDaysPopup;
