import { FC, useEffect, useMemo } from "react";
import InfiniteScrollInViewElement, {
  InfiniteScrollInViewElementProps,
} from "../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import ConfigurationGeneralTableStyles from "./ConfigurationGeneralTable.module.css";
import classNames from "classnames";
import {
  configurationRowItemType,
  ConfigurationScopeEnum,
  ConfigurationScreenType,
  CtrEntityDataType,
  getColSpanByConfigurationScreen,
  GlobalTableRowRendererProps,
  TableRowRendererProps,
} from "../../../../../types/projects/ConfigurationTypes.ts";

import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import {
  ConfigurationTargetType,
  getColSpanByGlobalConfigurationScreen,
  globalConfigurationRowItemType,
  GlobalConfigurationScreenType,
} from "../../../../../types/settings/GlobalConfigurationTypes.ts";
import TableRowRenderer from "./configurationtablerowrenderer/ConfigurationTableRowRenderer.tsx";
import GlobalConfigurationTableRowRenderer from "../../../../settings/globalconfigurations/globalconfigurationtablerowrenderer/GlobalConfigurationTableRowRenderer.tsx";
import { shouldHaveActionsClass } from "../../../../../utils/configurations/useShouldHaveActionClass.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useEpcmApiConfigurations } from "../../../../../apicalls/configurations/useEpcmApiConfigurations.ts";
import React from "react";
import { Skeleton } from "@mui/material";

interface ColumnConfig {
  key: string;
  header: string;
}

interface ConfigurationGeneralTableProps {
  data: CtrEntityDataType[];
  loading: boolean;
  columns: ColumnConfig[];
  infiniteScrollingComponent: InfiniteScrollInViewElementProps;
  configurationScreenType?: ConfigurationScreenType;
  globalConfigurationScreenType?: GlobalConfigurationScreenType;
  target: ConfigurationTargetType;
}

const ConfigurationGeneralTable: FC<ConfigurationGeneralTableProps> = ({
  data,
  columns,
  infiniteScrollingComponent,
  configurationScreenType,
  globalConfigurationScreenType,
  loading,
  target,
}) => {
  const queryClient = useQueryClient();
  const { getAllConfigurationRoles } = useEpcmApiConfigurations();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const scope = useMemo(() => {
    return target === ConfigurationTargetType.project ? ConfigurationScopeEnum.PROJECT : ConfigurationScopeEnum.GLOBAL;
  }, [target]);
  const userRolesQuery = useQuery({
    queryKey: [QueryNames.ConfigurationRoles, scope],
    queryFn: () => getAllConfigurationRoles(scope),
    enabled: isAuthorized,
  });

  const userRolesData = useMemo(() => {
    return userRolesQuery.data;
  }, [userRolesQuery.data]);

  const actualData = useMemo(() => data, [data]);

  const colSpan = useMemo(() => {
    if (target === ConfigurationTargetType.project && configurationScreenType) {
      return getColSpanByConfigurationScreen.get(configurationScreenType);
    }
    return getColSpanByGlobalConfigurationScreen.get(globalConfigurationScreenType!);
  }, [configurationScreenType, globalConfigurationScreenType, target]);

  const renderRow = (item: configurationRowItemType | globalConfigurationRowItemType) => {
    if (target === ConfigurationTargetType.project && configurationScreenType) {
      const renderer = TableRowRenderer[configurationScreenType];
      return renderer ? renderer({ item, roles: userRolesData?.data } as TableRowRendererProps) : null;
    }

    const renderer = GlobalConfigurationTableRowRenderer[globalConfigurationScreenType!];
    return renderer ? renderer({ item, roles: userRolesData?.data } as GlobalTableRowRendererProps) : null;
  };

  const SkeletonListLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap01)}>
      <Skeleton width={"100%"} height={100}></Skeleton>
      <Skeleton width={"100%"} height={100}></Skeleton>
      <Skeleton width={"100%"} height={100}></Skeleton>
      <Skeleton width={"100%"} height={100}></Skeleton>
      <Skeleton width={"100%"} height={100}></Skeleton>
    </div>
  );

  useEffect(() => {
    return () => {
      configurationScreenType === ConfigurationScreenType.permissions &&
        void queryClient.cancelQueries({ queryKey: [QueryNames.ConfigurationRoles, scope] });
    };
  }, [queryClient, configurationScreenType, scope]);

  return (
    <table className={classNames(ConfigurationGeneralTableStyles.table)}>
      <thead className={classNames(ConfigurationGeneralTableStyles.Thead)}>
        <tr className={classNames(ConfigurationGeneralTableStyles.tableHeader, ConfigurationGeneralTableStyles.Trow)}>
          {columns.map((column, index) => (
            <th
              key={column.key}
              className={classNames(
                ConfigurationGeneralTableStyles.tableHeaderCell,
                ConfigurationGeneralTableStyles.tH,
                shouldHaveActionsClass(configurationScreenType, globalConfigurationScreenType) &&
                  index === columns.length - 1 &&
                  ConfigurationGeneralTableStyles.tableCellActions,
              )}
            >
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={classNames(ConfigurationGeneralTableStyles.Tbody)}>
        <React.Fragment>
          {actualData && actualData.length > 0 ? (
            actualData!.map((item: configurationRowItemType | globalConfigurationRowItemType) => {
              return <React.Fragment key={item.id}>{renderRow(item)}</React.Fragment>;
            })
          ) : !loading ? (
            <tr
              className={classNames(
                GlobalStyles.emptyListMsg,
                ConfigurationGeneralTableStyles.emptyListContainer,
                ConfigurationGeneralTableStyles.Trow,
              )}
            >
              <td
                className={classNames(
                  GlobalStyles.emptyListMsg,
                  ConfigurationGeneralTableStyles.emptyListContainer,
                  ConfigurationGeneralTableStyles.tD,
                )}
                colSpan={colSpan}
              >
                No Records Yet
              </td>
            </tr>
          ) : (
            <tr className={classNames(ConfigurationGeneralTableStyles.Trow)}>
              <td className={classNames(ConfigurationGeneralTableStyles.tD)}>{SkeletonListLoader}</td>
            </tr>
          )}
          <tr className={classNames(ConfigurationGeneralTableStyles.Trow)}>
            <td className={classNames(ConfigurationGeneralTableStyles.tD)}>
              <InfiniteScrollInViewElement
                reference={infiniteScrollingComponent.reference}
                infiniteQueryResult={infiniteScrollingComponent.infiniteQueryResult}
                loaderComponent={infiniteScrollingComponent.loaderComponent}
              />
            </td>
          </tr>
        </React.Fragment>
      </tbody>
    </table>
  );
};
export default ConfigurationGeneralTable;
