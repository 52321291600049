import { PopupHandlerReturnType, PopupHandlerTypeAsMap } from "../../types/PopupHandlerTypes.ts";
import { useMemo, useState } from "react";
import { onClosePopup, onOpenPopup } from "../../utils/PopupHandlerUtils.ts";

export enum EmployeesPagePopups {
  createMMR = "create-mmr",
  employeeProfile = "employee-profile",
}

export enum EmployeesPagePopupHeaders {
  createMMR = "Create MMR",
  employeeProfile = "Employee Profile",
}

const popupHeadersMap: Map<EmployeesPagePopups, EmployeesPagePopupHeaders> = new Map([
  [EmployeesPagePopups.createMMR, EmployeesPagePopupHeaders.createMMR],
  [EmployeesPagePopups.employeeProfile, EmployeesPagePopupHeaders.employeeProfile],
]);

interface EmployeesPagePopupsReturnType extends PopupHandlerReturnType<EmployeesPagePopups> {
  popupHeaders: Map<EmployeesPagePopups, EmployeesPagePopupHeaders>;
}

export const useEmployeesPopups = (): EmployeesPagePopupsReturnType => {
  const [isCreateMMROpen, setIsCreateMMROpen] = useState(false);
  const [isEmployeeProfileOpen, setIsEmployeeProfileOpen] = useState(false);

  const popupHandlerAsMap: PopupHandlerTypeAsMap<EmployeesPagePopups> = useMemo(() => {
    const initialValue = new PopupHandlerTypeAsMap<EmployeesPagePopups>();

    initialValue.set(EmployeesPagePopups.createMMR, {
      opener: () => {
        setIsCreateMMROpen(true);
      },
      closer: () => {
        setIsCreateMMROpen(false);
      },
      isOpen: isCreateMMROpen,
    });

    initialValue.set(EmployeesPagePopups.employeeProfile, {
      opener: () => {
        setIsEmployeeProfileOpen(true);
      },
      closer: () => {
        setIsEmployeeProfileOpen(false);
      },
      isOpen: isEmployeeProfileOpen,
    });

    return initialValue;
  }, [isCreateMMROpen, isEmployeeProfileOpen]);

  return {
    popupHandler: popupHandlerAsMap,
    onOpenPopup,
    onClosePopup,
    popupHeaders: popupHeadersMap,
  } satisfies EmployeesPagePopupsReturnType;
};
