import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { FrontendExportExcelResponseType } from "../../../types/apicallstypes/GeneralTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getFile = async (projectId: number, sseRequestId: number): Promise<FrontendExportExcelResponseType> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/sseRequests/${sseRequestId}`,
    responseType: "blob",
    responseEncoding: "utf8",
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  const responseData = response.data as Blob;

  return {
    responseData: responseData,
    fileName: response.headers["content-disposition"]?.split('filename="')[1].split(".")[0] ?? `exported-file-${projectId}.xlsx`,
  };
};
