import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";

import { getAllProjectTimesheetCalendarMonths } from "./getAllProjectTimesheetCalendarMonths.ts";
import {
  convertProjectTimesheetCalendarMonthApiConverter,
  convertProjectTimesheetCalendarMonthPeriodApiConverter,
} from "../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/projecttimesheetcalendar/ProjectTimesheetCalendarApiConverter.ts";
import { FrontendCalendarMonthItemType } from "../../../types/apicallstypes/CalendarApiTypes.ts";
import { getProjectTimesheetMonthPeriods } from "./getProjectTimesheetMonthPeriods.ts";
import { FrontendTimesheetCreationDates } from "../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";

interface ApiDefinition {
  getAllProjectTimesheetCalendarMonths: (
    id: number,
    timesheetGroupId: number,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ) => Promise<QueryModelWithPagination<FrontendCalendarMonthItemType>>;

  getProjectTimesheetMonthPeriods: (id: number, timesheetGroupId: number, monthId: number) => Promise<FrontendTimesheetCreationDates>;
}

export const useEpcmApiProjectTimesheetCalendarMonths = (): ApiDefinition => {
  const getAllProjectTimesheetCalendarMonthsConverted = async (
    id: number,
    timesheetGroupId: number,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ): Promise<QueryModelWithPagination<FrontendCalendarMonthItemType>> => {
    const timesheetCalendarMonthsData = await getAllProjectTimesheetCalendarMonths(id, timesheetGroupId, pageSize, pageOffset, searchFilter);
    return {
      data: convertProjectTimesheetCalendarMonthApiConverter(timesheetCalendarMonthsData.data),
      nextPage: timesheetCalendarMonthsData.nextPage,
      totalCount: timesheetCalendarMonthsData.totalCount,
    };
  };
  const getProjectTimesheetMonthPeriodsConverted = async (
    id: number,
    timesheetGroupId: number,
    monthId: number,
  ): Promise<FrontendTimesheetCreationDates> => {
    const timesheetCalendarMonthsData = await getProjectTimesheetMonthPeriods(id, timesheetGroupId, monthId);
    return convertProjectTimesheetCalendarMonthPeriodApiConverter(timesheetCalendarMonthsData);
  };

  return {
    getAllProjectTimesheetCalendarMonths: getAllProjectTimesheetCalendarMonthsConverted,
    getProjectTimesheetMonthPeriods: getProjectTimesheetMonthPeriodsConverted,
  };
};
