import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import ManpowerPlanListStyles from "./ManpowerPlanList.module.css";
import classNames from "classnames";
import { FC, useMemo, useState } from "react";
import { ManpowerPlanListItem } from "./manpowerplanlistitem/ManpowerPlanListItem.tsx";
import arrowDownBlue from "../../../../../assets/images/arrow-down-blue.svg";
import { ManpowerPlanStatusEnum, ManpowerPlanType } from "../../../../../types/projects/ManpowerPlanTypes.ts";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { convertDateToMilSecs } from "../../../../../utils/DateManipulation.ts";

interface ManpowerPlanListProps {
  manpowerPlanList: ManpowerPlanType[];
  mostRecentApprovedPlanUniqueIdentifier: string;
}

export const ManpowerPlanList: FC<ManpowerPlanListProps> = ({ manpowerPlanList, mostRecentApprovedPlanUniqueIdentifier }) => {
  const [showPrevious, setShowPrevious] = useState<boolean>(false);
  const [editedManpowerPlan, setEditedManpowerPlan] = useState<ManpowerPlanType | null>(null);
  const [parent] = useAutoAnimate();

  const mostRecentApprovedPlan = useMemo(() => {
    return manpowerPlanList.filter((plan) => mostRecentApprovedPlanUniqueIdentifier === plan.code + plan.versionDisplay)[0];
  }, [manpowerPlanList, mostRecentApprovedPlanUniqueIdentifier]);

  const currentManpowerPlanList = useMemo(
    () => [mostRecentApprovedPlan, ...manpowerPlanList.filter((plan) => plan.approvalStatus === ManpowerPlanStatusEnum.pending)],
    [manpowerPlanList, mostRecentApprovedPlan],
  );

  const filterManpowerPlanList = useMemo(() => {
    const filteredList = showPrevious ? manpowerPlanList : currentManpowerPlanList;

    return filteredList.sort((a, b) => convertDateToMilSecs(b.dateUploaded) - convertDateToMilSecs(a.dateUploaded));
  }, [showPrevious, manpowerPlanList, currentManpowerPlanList]);

  const manpowerPlanListDisplay = useMemo(() => {
    const editedManpowerPlanList: ManpowerPlanType[] = filterManpowerPlanList.slice();
    if (editedManpowerPlan) {
      const planIndex = editedManpowerPlanList
        .map((plan) => plan.code + plan.versionDisplay)
        .indexOf(editedManpowerPlan.code + editedManpowerPlan.versionDisplay);
      if (planIndex !== -1) {
        editedManpowerPlanList[planIndex] = editedManpowerPlan;
      }
    }
    return editedManpowerPlanList;
  }, [filterManpowerPlanList, editedManpowerPlan]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.overflowHiddenFullHeight, GlobalStyles.gap05)}>
      <div
        ref={parent}
        className={classNames(
          ManpowerPlanListStyles.manpowerPlanListContainer,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap15,
        )}
      >
        {manpowerPlanListDisplay.map((manpowerPlanItem) => (
          <div key={manpowerPlanItem.code + manpowerPlanItem.version}>
            <ManpowerPlanListItem
              manpowerPlanItem={manpowerPlanItem}
              isHistory={!currentManpowerPlanList.includes(manpowerPlanItem)}
              onChangeStatusFn={(editedPlan) => setEditedManpowerPlan(editedPlan)}
            />
          </div>
        ))}
      </div>
      <div
        className={classNames(ManpowerPlanListStyles.seePreviousContainer, GlobalStyles.flex, GlobalStyles.gap)}
        onClick={() => setShowPrevious((currentState) => !currentState)}
      >
        <div className={classNames(ManpowerPlanListStyles.blueText, GlobalStyles.centerVertical)}>
          {showPrevious ? "Hide previous versions" : "See previous versions"}
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img
            className={classNames(ManpowerPlanListStyles.arrowDownBlueIconImg, showPrevious && ManpowerPlanListStyles.arrowDownBlueIconImgReverted)}
            src={arrowDownBlue}
            alt="arrow"
          />
        </div>
      </div>
    </div>
  );
};
