import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import deleteIcon from "../../../../../../assets/images/x-mark-circular-gray-background.svg";
import plusIcon from "../../../../../../assets/images/add-icon-circled-blue.svg";
import FormEmployeeLanguagesInfoStyles from "./FormEmployeeLanguagesInfo.module.css";
import { FC, useCallback, useState } from "react";
import { ClickAwayListener } from "@mui/material";

interface FormEmployeeLanguagesInfoProps {
  employeeLanguages: string[];
  onEmployeeLanguagesChange: (languagesNewState: string[]) => void;
}

enum LanguagesEditTypeEnum {
  add = "add",
  remove = "remove",
}

export const FormEmployeeLanguagesInfo: FC<FormEmployeeLanguagesInfoProps> = ({ employeeLanguages, onEmployeeLanguagesChange }) => {
  const [languageInput, setLanguageInput] = useState<string>("");
  const [isLanguageInputVisible, setIsLanguageInputVisible] = useState<boolean>(false);

  const editLanguages = useCallback(
    (language: string, editType: LanguagesEditTypeEnum) => {
      const indexOfLanguage = employeeLanguages.indexOf(language);
      if (indexOfLanguage !== -1 && editType === LanguagesEditTypeEnum.remove) {
        onEmployeeLanguagesChange(employeeLanguages.filter((languageItem) => languageItem !== language));
      }
      if (indexOfLanguage === -1 && editType === LanguagesEditTypeEnum.add) {
        onEmployeeLanguagesChange([...employeeLanguages, language]);
      }
    },
    [onEmployeeLanguagesChange, employeeLanguages],
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" || event.key === "Escape") {
        event.stopPropagation();
        event.key === "Enter" && languageInput !== "" && editLanguages(languageInput, LanguagesEditTypeEnum.add);
        setLanguageInput("");
        setIsLanguageInputVisible(false);
      }
    },
    [languageInput, editLanguages],
  );

  const handleClickAway = useCallback(() => {
    languageInput !== "" && editLanguages(languageInput, LanguagesEditTypeEnum.add);
    setLanguageInput("");
    setIsLanguageInputVisible(false);
  }, [languageInput, editLanguages]);

  return (
    <div className={classNames(FormEmployeeLanguagesInfoStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
      <div className={classNames(FormEmployeeLanguagesInfoStyles.secondaryText)}>{"Languages"}</div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexWrap)}>
        {employeeLanguages.map((languageItem, index) => (
          <div
            key={index}
            className={classNames(
              FormEmployeeLanguagesInfoStyles.languageContainer,
              FormEmployeeLanguagesInfoStyles.languageContainerHover,
              GlobalStyles.flex,
              GlobalStyles.gap05,
            )}
            onClick={() => editLanguages(languageItem, LanguagesEditTypeEnum.remove)}
          >
            <div className={classNames(GlobalStyles.centerVertical)}>{languageItem}</div>
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(FormEmployeeLanguagesInfoStyles.deleteIconImg)} src={deleteIcon} alt="delete" />
            </div>
          </div>
        ))}
      </div>
      {isLanguageInputVisible ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={classNames(FormEmployeeLanguagesInfoStyles.languageContainer, GlobalStyles.flex, GlobalStyles.gap05)}>
            <input
              autoFocus
              value={languageInput}
              onChange={(event) => setLanguageInput(event.target.value)}
              onKeyDown={(event) => handleKeyPress(event)}
              className={classNames(FormEmployeeLanguagesInfoStyles.languageInputContainer, GlobalStyles.flex1, GlobalStyles.centerVertical)}
            />
            <div className={classNames(GlobalStyles.centerVertical)} onClick={() => setIsLanguageInputVisible(false)}>
              <img className={classNames(FormEmployeeLanguagesInfoStyles.deleteIconImg)} src={deleteIcon} alt="delete" />
            </div>
          </div>
        </ClickAwayListener>
      ) : (
        <div
          className={classNames(FormEmployeeLanguagesInfoStyles.addLanguageButton, GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexWrap)}
          onClick={() => setIsLanguageInputVisible(true)}
        >
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(FormEmployeeLanguagesInfoStyles.plusIconImg)} src={plusIcon} alt="add" />
          </div>
          <div className={classNames(GlobalStyles.centerVertical)}>{"Add Language"}</div>
        </div>
      )}
    </div>
  );
};
