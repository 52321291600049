import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { ApiRequestBodyReportsExcel } from "epcm-common/dist/Types/ExcelTypes";

const API = `${EPCM_API_URLS.projects}`;

export const createReport = async (projectId: number, timeDates: ApiRequestBodyReportsExcel) => {
  const url = `${API}/${projectId}/reports`;

  const config: AxiosRequestConfig = {
    url: `${url}`,
    method: "POST",
    data: timeDates,
  } as AxiosRequestConfig;
  return epcmAxiosInstance(config);
};
