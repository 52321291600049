import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import FileUploaderStyles from "./UploadedFilesList.module.css";
import React, { useState } from "react";
import deleteFileIcon from "../../assets/images/delete-bin-cyan.svg";
import { UploadedFilesListItem } from "./uploadedfileslistitem/UploadedFilesListItem.tsx";
import classNames from "classnames";

interface UploadedFilesListProps {
  uploadedFiles: File[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

export const UploadedFilesList: React.FC<UploadedFilesListProps> = ({ uploadedFiles, setUploadedFiles }) => {
  const [showAllFilesDelete, setShowAllFilesDelete] = useState<boolean>(false);

  const removeAllFiles = () => {
    setUploadedFiles([]);
  };

  return (
    <div
      className={classNames(
        FileUploaderStyles.selectedFilesListContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.flex3,
        GlobalStyles.gap,
      )}
      onMouseEnter={() => setShowAllFilesDelete(true)}
      onMouseLeave={() => setShowAllFilesDelete(false)}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(FileUploaderStyles.selectedFilesListHeader, GlobalStyles.centerVertical)}>{"Selected Files"}</div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div
          className={classNames(
            showAllFilesDelete ? FileUploaderStyles.deleteFileIcon : FileUploaderStyles.deleteFileHiddenIcon,
            GlobalStyles.centerVertical,
          )}
          onClick={removeAllFiles}
        >
          <img src={deleteFileIcon} alt="" className={classNames(FileUploaderStyles.deleteFileIconImg)} />
        </div>
      </div>
      <div className={classNames(FileUploaderStyles.selectedFilesList, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
        {uploadedFiles.map((file) => (
          <UploadedFilesListItem key={file.name} uploadedFile={file} setUploadedFiles={setUploadedFiles} />
        ))}
      </div>
    </div>
  );
};
