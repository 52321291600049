import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../../config.ts";
import { ApiRequestBodyTimesheetSave } from "epcm-common/dist/Types/TimesheetRecordTypes";

const API = `${EPCM_API_URLS.projects}`;

export const updateTimesheet = async (
  projectId: number,
  timesheetGroupId: number,
  timesheetId: number,
  newTimesheetData: ApiRequestBodyTimesheetSave,
) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/timesheet-groups/${timesheetGroupId}/timesheets/${timesheetId}`,
    method: "POST",
    data: newTimesheetData,
  } as AxiosRequestConfig;
  return epcmAxiosInstance(config);
};
