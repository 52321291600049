import { FC, ReactNode } from "react";
import classNames from "classnames";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import PageFilterContainerStyles from "./PageFilterContainer.module.css";
import filtersIcon from "../../assets/images/filter-icon.svg";
import { EPCMInfoContainerDiv } from "../epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import settingsIcon from "../../assets/images/setting-dots-black.svg";

interface PageFilterContainerProps {
  smallerFontText: string;
  mainText: string;
  onFiltersClick?: () => void;
  onSettingsClick?: () => void;
  children: ReactNode;
}

export const PageFilterContainer: FC<PageFilterContainerProps> = ({ smallerFontText, mainText, onFiltersClick, onSettingsClick, children }) => {
  return (
    <EPCMInfoContainerDiv className={classNames(GlobalStyles.centerVertical)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap2)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
            {onFiltersClick && (
              <div className={classNames()} onClick={onFiltersClick}>
                <img className={classNames(PageFilterContainerStyles.filtersIconImg)} src={filtersIcon} alt="filters" />
              </div>
            )}
            <div className={classNames(GlobalStyles.flex1)} />
            {onSettingsClick && (
              <div className={classNames()} onClick={onSettingsClick}>
                <img className={classNames(PageFilterContainerStyles.filtersIconImg)} src={settingsIcon} alt="settings" />
              </div>
            )}
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)} />
          <div className={classNames(PageFilterContainerStyles.textContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <div className={classNames(PageFilterContainerStyles.smallerFontText)}>{smallerFontText}</div>
            <div className={classNames()}>{mainText}</div>
          </div>
        </div>
        {children}
      </div>
    </EPCMInfoContainerDiv>
  );
};
