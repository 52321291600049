export interface FrontendProjectEmployeeLimited {
  id: number;
  employeeId: number;
}

export interface FrontendProjectEmployeeNotInTimesheet {
  projectEmployeeId: number;
  employeeName: string;
  timesheetGroupId: number;
  timesheetGroupName: string;
}

export interface FrontendProjectEmployeeToMmrType extends FrontendProjectEmployeeLimited {
  code: string;
  name: string;
  mmrId: number | null;
  mmrStatus: FrontendMmrStatusEnum | null;
  canBeDeleted: boolean;
}

export enum FrontendMmrStatusEnum {
  APPROVED = "approved",
  REJECTED = "rejected",
  PENDING = "pending",
  NoMmr = "noMmr",
}

export enum FrontendMmrDateStatusEnum {
  ACTIVE = "active",
  UPCOMING = "upcoming",
  HISTORY = "history",
}

export interface FrontendProjectEmployeeMmrType {
  id: number;
  documentNumber: number;
  status: FrontendMmrStatusEnum;
  dateStatus: FrontendMmrDateStatusEnum;
  isEligible: boolean;
}

export interface FrontendManpowerPositionNormalType {
  id: number;
  code: string;
  rate: number;
  pricingScheduledManhours: number;
}

export interface FrontendManpowerPositionLimitedType extends Pick<FrontendManpowerPositionNormalType, "id" | "code"> {}

export interface FrontendManpowerPositionDetailedType extends FrontendManpowerPositionNormalType {
  locationId: number;
  positionId: number;
  tradeId: number;
  categoryId: number;
}

export interface FrontendProjectGroupEmployeeLimitedType {
  id: number;
  employeeId: number;
  canBeDeleted: boolean;
}

export interface FrontendProjectEmployeeMmrBasicType {
  id: number;
  status: FrontendMmrStatusEnum | null;
  agreementNumber: string;
  typeOfMobilization: string | null;
  actualMobilizationDate: Date | null;
  actualDemobilizationDate: Date | null;
  anticipatedMobilizationDate: Date;
  anticipatedDemobilizationDate: Date;
}

export interface FrontendProjectEmployeeMmrLimitedType extends Pick<FrontendProjectEmployeeMmrBasicType, "id" | "status" | "agreementNumber"> {}

export interface FrontendProjectEmployeeMmrNormalType extends FrontendProjectEmployeeMmrBasicType {
  creationDate: Date;
  manpowerPositionDetailed: FrontendManpowerPositionDetailedType;
  subPositionId: number | null;
  disciplineId: number | null;
}

export interface FrontendProjectEmployeeMmrDetailedType extends FrontendProjectEmployeeMmrNormalType {
  justificationForRequest: string | null;
  approvalRecord: string | null;
  clientInterviewDate: Date | null;
  keyPersonnel: boolean;
  subcontractor: string | null;
  mmrReference: string;
  revisionNumber: number;
  editDate: Date | null;
  editorCode: string;
  editorName: string;
  isEligible: boolean;
}
