import { FC, useEffect, useMemo } from "react";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEpcmApiEmployees } from "../../../../../../apicalls/employees/useEpcmApiEmployees.ts";
import { QueryNames } from "../../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { FrontendEmployeeDetailedType } from "../../../../../../types/apicallstypes/EmployeesApiTypes.ts";
import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import GlobalEmployeeInfoContainerStyles from "./GlobalEmployeeInfoContainer.module.css";
import { Skeleton } from "@mui/material";
import { GlobalEmployeeInfoDetailed } from "../globalemployeeinfodetailed/GlobalEmployeeInfoDetailed.tsx";
import { formatDate } from "../../../../../../utils/DateManipulation.ts";
import { GlobalEmployeeAdditionalInfo } from "../globalemployeeadditionalinfo/GlobalEmployeeAdditionalInfo.tsx";

// import GlobalEmployeeDownloadCV from "../globalemployeedownloadcv/GlobalEmployeeDownloadCV.tsx";

interface GlobalEmployeeInfoContainerProps {
  globalEmployeeId: number;
  projectId: number;
}

const GlobalEmployeeInfoContainer: FC<GlobalEmployeeInfoContainerProps> = ({ projectId, globalEmployeeId }) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getEmployee } = useEpcmApiEmployees();

  const employeeQuery = useQuery({
    queryKey: [QueryNames.Employees, projectId, DetailLevel.DETAILED, globalEmployeeId],
    queryFn: () => getEmployee(globalEmployeeId, DetailLevel.DETAILED),

    enabled: isAuthorized,
    select: (data) => data as FrontendEmployeeDetailedType,
  });

  const employeeData = useMemo(() => {
    return employeeQuery.data ?? undefined;
  }, [employeeQuery]);

  const globalEmployeeLoader = (
    <div
      className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap125, GlobalEmployeeInfoContainerStyles.loaderStyle)}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
        <div className={classNames(GlobalEmployeeInfoContainerStyles.secondaryText)}>Employee Type</div>
        <Skeleton variant={"text"} width={100} height={25}></Skeleton>
      </div>

      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
        <div className={classNames(GlobalEmployeeInfoContainerStyles.secondaryText)}>Position</div>
        <Skeleton variant={"text"} width={100} height={25}></Skeleton>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
        <div className={classNames(GlobalEmployeeInfoContainerStyles.secondaryText)}>Nationality</div>
        <Skeleton variant={"text"} width={100} height={25}></Skeleton>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
        <div className={classNames(GlobalEmployeeInfoContainerStyles.secondaryText)}>Date of Birth</div>
        <Skeleton variant={"text"} width={100} height={25}></Skeleton>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
        <div className={classNames(GlobalEmployeeInfoContainerStyles.secondaryText)}>Languages</div>
        <Skeleton variant={"text"} width={100} height={25}></Skeleton>
      </div>
    </div>
  );

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Employees, projectId, DetailLevel.DETAILED, globalEmployeeId] })
        .then(() => console.log(`In employee with id ${globalEmployeeId} profile card, ${QueryNames.Employees} query canceled`));
    };
  }, [queryClient, globalEmployeeId, projectId]);

  return (
    <>
      {!employeeQuery.isLoading && employeeData ? (
        <>
          <GlobalEmployeeInfoDetailed
            employeePositionId={employeeData?.globalPosition?.id ?? null}
            employeeType={employeeData!.category!}
            employeeNationality={employeeData!.nationality!}
            employeeDateOfBirth={formatDate(employeeData!.dateOfBirth!)}
            employeeLanguages={employeeData!.languages ?? []}
          />

          <div className={classNames(GlobalEmployeeInfoContainerStyles.employeeAdditionalInfoContainer, GlobalStyles.flex1)}>
            <GlobalEmployeeAdditionalInfo
              qualifications={employeeData?.qualifications ?? undefined}
              experience={employeeData?.experience ?? undefined}
              certifications={employeeData?.certifications ?? undefined}
            />
          </div>
        </>
      ) : (
        <>
          {globalEmployeeLoader}
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        </>
      )}
    </>
  );
};
export default GlobalEmployeeInfoContainer;
