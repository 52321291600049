import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../config";
import { ProjectLimited } from "epcm-common/dist/Types/ProjectTypes.ts";
import { QueryModelWithCountAndData, QueryModelWithPagination } from "../../types/apicallstypes/queryCommons.ts";
import { FrontendProjectStatus } from "../../types/apicallstypes/ProjectsApiTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getAllProjects = async (
  pageOffset?: number,
  searchFilter?: string,
  pageSize: number = PAGINATION_PAGE_SIZE,
  status?: FrontendProjectStatus,
): Promise<QueryModelWithPagination<ProjectLimited>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageOffset && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);
  status && params.set("status", `${status}`);

  const config: AxiosRequestConfig = {
    url: `${API}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);
  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);
  const responseData = response.data as QueryModelWithCountAndData<ProjectLimited>;

  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;
  return { data: responseData.data, nextPage: nextPage, totalCount: responseData.count };
};
