import TimesheetFormEmployeeListItemStyles from "./TimesheetFormEmployeeListItemStyles.module.css";
import classNames from "classnames";
import React, { FC, useEffect, useMemo, useState } from "react";
import TimesheetFormEmployeeInfo from "../timesheetformemployeeinfo/TimesheetFormEmployeeInfo.tsx";
import {
  FrontendCalendarProjectStatusEnum,
  FrontendGroupsTimesheetModeEnum,
  FrontendTimesheetEmployeeLimitedType,
  FrontendTimesheetFormHeaderDateType,
  FrontendTimesheetProjectEmployeeActiveMMRType,
} from "../../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { useParams, useSearchParams } from "react-router-dom";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import { Skeleton } from "@mui/material";
import TimesheetFormMMRInfo from "../timesheetformmmrinfo/TimesheetFormMMRInfo.tsx";
import { FrontendDayTypeOptionsEnum } from "../../../../../../../../types/apicallstypes/CalendarApiTypes.ts";
import {
  FrontendBodyTimesheetRecordSave,
  FrontendMmrBodyTimesheetRecordSaveTest,
  FrontendMmrCtrsBody,
  FrontendTimesheetEmployeeBodyType,
} from "../../../../../../../../types/apicallstypes/CtrTypes.ts";
import { FrontendMmrCalendarDayMapping, HoursPerDayValidationType } from "../../../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import TimesheetFormCtrRecordList from "../timesheetformctrrecordlist/TimesheetFormCtrRecordList.tsx";
import TimesheetFormDayColumn from "../timesheetformdaycolumn/TimesheetFormDayColumn.tsx";
import { MmrCtrsBody } from "epcm-common/dist/Types/TimesheetRecordTypes";
import { useRetrieveUserPermittedActions } from "../../../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../../../types/Roles.ts";

interface TimesheetFormEmployeeListItemProps {
  timesheetId: number;
  timesheetEmployee: FrontendTimesheetEmployeeLimitedType;
  groupTimesheetDates: FrontendTimesheetFormHeaderDateType[];
  setTimesheetMutationData: React.Dispatch<React.SetStateAction<FrontendTimesheetEmployeeBodyType[]>>;
  setHasAnyInvalidWorkedHourSum: React.Dispatch<React.SetStateAction<boolean>>;
  setHasLessThanMaxHoursRecord: React.Dispatch<React.SetStateAction<boolean>>;
  //setHasAnyCtrInvalidWorkedHours: React.Dispatch<React.SetStateAction<boolean>>;
}

const TimesheetFormEmployeeListItem: FC<TimesheetFormEmployeeListItemProps> = ({
  timesheetId,
  timesheetEmployee,
  groupTimesheetDates,
  setTimesheetMutationData,
  setHasAnyInvalidWorkedHourSum,
  setHasLessThanMaxHoursRecord,
  //setHasAnyCtrInvalidWorkedHours,
}) => {
  const { projectId, groupId } = useParams();
  const { getGroupTimesheetProjectEmployeeActiveMMRs } = useEpcmApiProjectsTimesheetsGroups();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const [mmrCtrRecords, setMmrCtrRecords] = useState<{ [mmrId: number]: FrontendMmrCtrsBody[] }>({});
  const [activeMmrs, setActiveMmrs] = useState<FrontendMmrBodyTimesheetRecordSaveTest[]>([]);
  const [mmrCalendarMappings, setMmrCalendarMappings] = useState<FrontendMmrCalendarDayMapping>({});
  const [mmrMutationData, setMmrMutationData] = useState<FrontendBodyTimesheetRecordSave[]>([]);
  const [searchParams] = useSearchParams();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const displayModeSearchQuery = searchParams.get("mode") || FrontendGroupsTimesheetModeEnum.VIEW;
  const isEditMode = useMemo(() => displayModeSearchQuery === FrontendGroupsTimesheetModeEnum.EDIT, [displayModeSearchQuery]);

  const [isHoursPerDayInvalid, setIsHoursPerDayInvalid] = useState<HoursPerDayValidationType[]>(
    groupTimesheetDates.map((dayItem) => {
      return { calendarId: dayItem.id, isInvalid: false };
    }),
  );
  // const [isHoursPerCtrPerDayInvalid, setIsHoursPerCtrPerDayInvalid] = useState<HoursPerDayValidationType[]>(
  //   groupTimesheetDates.map((dayItem) => {
  //     return { calendarId: dayItem.id, isInvalid: false };
  //   }),
  // );

  const timesheetProjectEmployeeActiveMMRsQuery = useQuery({
    queryKey: [
      QueryNames.ProjectTimesheetGroupTimesheetProjectEmployeeActiveMMRs,
      projectId,
      groupId,
      timesheetId,
      timesheetEmployee.timesheetEmployeeId,
      timesheetEmployee.projectEmployeeId,
    ],
    queryFn: () =>
      getGroupTimesheetProjectEmployeeActiveMMRs(
        parseInt(projectId!),
        parseInt(groupId!),
        timesheetId,
        timesheetEmployee.timesheetEmployeeId,
        timesheetEmployee.projectEmployeeId,
      ),

    enabled: isAuthorized,
  });

  const timesheetProjectEmployeeActiveMMRsData = useMemo(() => {
    return timesheetProjectEmployeeActiveMMRsQuery.data ?? undefined;
  }, [timesheetProjectEmployeeActiveMMRsQuery]);

  // const numberOfMMRs = useMemo(() => {
  //   return timesheetProjectEmployeeActiveMMRsData?.length;
  // }, [timesheetProjectEmployeeActiveMMRsData]);

  const groupTimesheetEmployeeMMRSkeletonLoader = (
    <div className={classNames(TimesheetFormEmployeeListItemStyles.skeletonLoaderContainer)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
        <Skeleton variant="rounded" height={15} width={150} />
        <Skeleton variant="rounded" height={15} width={120} />
      </div>
    </div>
  );

  // Update the parent boolean that enables the submit button
  // useEffect(() => {
  //   setHasAnyCtrInvalidWorkedHours(isHoursPerCtrPerDayInvalid.map((dayValidationItem) => dayValidationItem.isInvalid).some((value) => value));
  // }, [setHasAnyCtrInvalidWorkedHours, isHoursPerCtrPerDayInvalid]);

  // Update the parent boolean that enables the submit button

  const canFillTimesheet = canPerformProjectAction(ProjectAction.ProjectTimesheetFillData);

  useEffect(() => {
    //Check if the total hours per day is invalid but also if it is a working day
    setHasAnyInvalidWorkedHourSum(
      isHoursPerDayInvalid.map((dayValidationItem) => dayValidationItem.isInvalid).some((value) => value) &&
        isHoursPerDayInvalid.map((dayValidationItem) => dayValidationItem.isInvalid).some((value) => value),
    );
  }, [setHasAnyInvalidWorkedHourSum, isHoursPerDayInvalid]);

  // Initialize local array of mmrs with mmr data from server and empty records array
  // that will be later updated from the children components

  useEffect(() => {
    if (timesheetProjectEmployeeActiveMMRsData && activeMmrs.length === 0) {
      setActiveMmrs(
        timesheetProjectEmployeeActiveMMRsData.map((activeMmrItem) => {
          const mmrCalendarDays = activeMmrItem.calendars.map((calendar) => ({
            calendarDayId: calendar.id,
            isApplicable: calendar.dayType === FrontendDayTypeOptionsEnum.WORKING_DAY,
            isWorkingDay: calendar.dayType === FrontendDayTypeOptionsEnum.WORKING_DAY,
          }));
          //Only make the changes,keep existing

          return {
            mmrId: activeMmrItem.mmrId,
            mmrCalendarDays,
          };
        }),
      );
    }
  }, [timesheetProjectEmployeeActiveMMRsData, mmrCtrRecords, activeMmrs.length]);

  // useEffect(() => {
  //   const newMmrCalendarMappings: FrontendMmrCalendarMappings = {};
  //   if (!activeMmrs) return;
  //   activeMmrs.forEach((mmr) => {
  //     newMmrCalendarMappings[mmr.mmrId] = mmr.mmrCalendarDays.map((calendar) => ({
  //       calendarDayId: calendar.calendarDayId,
  //       isApplicable: calendar.isApplicable,
  //     }));
  //   });
  //   setMmrCalendarMappings(newMmrCalendarMappings);
  // }, [timesheetProjectEmployeeActiveMMRsData, activeMmrs]);

  // Update parent timesheet mutation data array with the local mmr data
  useEffect(() => {
    timesheetProjectEmployeeActiveMMRsData &&
      setTimesheetMutationData((currentState) => {
        const timesheetMutationDataClone = currentState.slice();
        const currentEmployeeIndex = timesheetMutationDataClone
          .map((employeeData) => employeeData.timesheetEmployeeId)
          .indexOf(timesheetEmployee.timesheetEmployeeId);
        if (currentEmployeeIndex !== -1) {
          timesheetMutationDataClone[currentEmployeeIndex].mmrs = mmrMutationData;
        }
        return timesheetMutationDataClone;
      });
  }, [setTimesheetMutationData, timesheetProjectEmployeeActiveMMRsData, timesheetEmployee.timesheetEmployeeId, activeMmrs, mmrMutationData]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({
          queryKey: [
            QueryNames.ProjectTimesheetGroupTimesheetProjectEmployeeActiveMMRs,
            projectId,
            groupId,
            timesheetId,
            timesheetEmployee.timesheetEmployeeId,
            timesheetEmployee.projectEmployeeId,
          ],
        })
        .then(() =>
          console.log(
            `In timesheet employees, timesheet employee with id ${timesheetEmployee.timesheetEmployeeId}, ${QueryNames.ProjectTimesheetGroupTimesheetProjectEmployeeActiveMMRs} query canceled`,
          ),
        );
    };
  }, [queryClient, projectId, groupId, timesheetId, timesheetEmployee]);

  //Add an empty element to this array as first element timesheetProjectEmployeeActiveMMRsData

  // Memoized data structure for MMRs and CTRs

  useEffect(() => {
    const validateTotalHoursPerDay = () => {
      // Initialize a structure to track summed hours for each calendar day across all MMRs
      const totalHoursPerDay: { [calendarDayId: number]: number } = {};

      Object.entries(mmrCtrRecords).forEach(([mmrId, ctrs]) => {
        if (mmrId) {
          mmrId.trim();
        }
        ctrs.forEach((ctr) => {
          ctr.calendarDayHours
            .filter((day) => day.workedHours > 0 && mmrCalendarMappings[Number(mmrId)][day.calendarDayId]?.isApplicable)
            .forEach((day) => {
              // Sum hours for each day across all MMRs
              totalHoursPerDay[day.calendarDayId] = (totalHoursPerDay[day.calendarDayId] || 0) + day.workedHours;
            });
        });
      });

      // Now validate each day's total hours against the max hours defined in mmrCalendarMappings
      const newValidationStates = groupTimesheetDates.map((dayItem) => {
        const totalHours = totalHoursPerDay[dayItem.id] || 0;
        //Max hours should include the overtime hours

        const maxHours = Object.keys(mmrCalendarMappings).reduce((max, mmrId) => {
          const mapping = mmrCalendarMappings[Number(mmrId)][dayItem.id];
          return mapping ? Math.max(max, mapping.maxHours) : max;
        }, 0);

        const isInvalid = totalHours > maxHours;

        return {
          calendarId: dayItem.id,
          isInvalid,
        };
      });

      // Update the state to reflect the new validation results

      setIsHoursPerDayInvalid(newValidationStates);
    };

    validateTotalHoursPerDay();
  }, [mmrCtrRecords, mmrCalendarMappings, setIsHoursPerDayInvalid, groupTimesheetDates]);

  useEffect(() => {
    if (mmrCtrRecords && mmrCalendarMappings) {
      const newMmrMutationData = Object.keys(mmrCtrRecords).map((mmrId) => {
        //Filter ctrs that are not null
        const ctrs = mmrCtrRecords[Number(mmrId)]
          .filter((ctr) => ctr.ctrId !== null)
          //Also filter the days that dont have workedHours greater than 0
          .map((ctr) => {
            return {
              ctrId: ctr.ctrId,
              calendarDayHours: ctr.calendarDayHours.filter((day) => day.workedHours >= -1),
            };
          });

        //Filter ctrs that are not null
        // I need to also check whether the day is working day or not

        const mmrCalendarDays = Object.keys(mmrCalendarMappings[Number(mmrId)])
          .filter((calendarDayId) => mmrCalendarMappings[Number(mmrId)][Number(calendarDayId)].isWorkingDay)
          .map((calendarDayId) => {
            const mapping = mmrCalendarMappings[Number(mmrId)][Number(calendarDayId)];
            return {
              calendarDayId: Number(calendarDayId),
              isApplicable: mapping.isApplicable,
            };
          });

        return {
          mmrId: Number(mmrId),
          mmrCtrs: ctrs as MmrCtrsBody[],
          mmrCalendarDays,
        };
      });

      setMmrMutationData(newMmrMutationData);
    }
  }, [mmrCtrRecords, mmrCalendarMappings, setMmrMutationData]);

  const calculateRowSpan = (mmrsData: FrontendTimesheetProjectEmployeeActiveMMRType[]): number => {
    let totalRowSpan = 0;
    mmrsData.forEach((mmr) => {
      totalRowSpan += 1; // For the MMR itself
      const ctrsArray = mmrCtrRecords[mmr.mmrId] || [];
      totalRowSpan += ctrsArray.length; // For the CTRs of the MMR
      totalRowSpan += 2; // For the additional rows
    });
    return totalRowSpan;
  };

  const handleAddCtr = (mmrId: number) => {
    setMmrCtrRecords((prevCtrs) => {
      const existingCtrs = prevCtrs[mmrId] || [];
      const updatedCtrs = [...existingCtrs, { ctrId: null, calendarDayHours: [] }];
      return { ...prevCtrs, [mmrId]: updatedCtrs };
    });
  };

  const updateWorkHours = (mmrId: number, ctrId: number, calendarDayId: number, newHours: number) => {
    setMmrCtrRecords((prev) => {
      const newRecords = { ...prev };
      // Find the specific CTR records array for the given MMR ID.
      const ctrs = newRecords[mmrId] ?? [];
      // Find the specific CTR within the array.
      const ctrIndex = ctrs.findIndex((c) => c.ctrId === ctrId);

      if (ctrIndex !== -1) {
        const ctr = ctrs[ctrIndex];
        // Attempt to find the specific day within the CTR's calendarDayHours.
        const dayIndex = ctr.calendarDayHours.findIndex((d) => d.calendarDayId === calendarDayId);

        if (dayIndex !== -1) {
          // If the day exists, update its worked hours.
          if (newHours < 0) {
            // Remove index completely
            ctr.calendarDayHours.splice(dayIndex, 1);
          } else {
            ctr.calendarDayHours[dayIndex].workedHours = newHours;
          }
        } else {
          // If the day does not exist, add it with the new worked hours.
          if (newHours >= 0) {
            ctr.calendarDayHours.push({
              calendarDayId,
              workedHours: newHours,
            });
          }
        }
      } else {
        // If the ctr does not exist, you should either handle this case specifically
        // or ensure that ctrs are initialized somewhere else in your application.
      }

      return newRecords;
    });
  };

  // Check if the mmr ctr records has any day for any mmr  that the sum of all ctrs is less than the max hour. All i need is one instance to use the boolean setter to set setHasLessThanMaxHoursRecord to true
  useEffect(() => {
    const validateTotalHoursPerDay = () => {
      // Initialize a structure to track summed hours for each calendar day across all MMRs
      const totalHoursPerDay: { [calendarDayId: number]: number } = {};

      Object.entries(mmrCtrRecords).forEach(([mmrId, ctrs]) => {
        if (mmrId) {
          mmrId.trim();
        }
        ctrs.forEach((ctr) => {
          ctr.calendarDayHours
            .filter((day) => day.workedHours > 0 && mmrCalendarMappings[Number(mmrId)][day.calendarDayId]?.isApplicable)
            .forEach((day) => {
              // Sum hours for each day across all MMRs
              totalHoursPerDay[day.calendarDayId] = (totalHoursPerDay[day.calendarDayId] || 0) + day.workedHours;
            });
        });
      });

      // Now validate each day's total hours against the max hours defined in mmrCalendarMappings
      const newValidationStates = groupTimesheetDates
        .filter((dayItem) => dayItem.calendarProjectStatus === FrontendCalendarProjectStatusEnum.VALID)
        .map((dayItem) => {
          const totalHours = totalHoursPerDay[dayItem.id] || 0;
          //Max hours should include the overtime hours

          const maxHours = Object.keys(mmrCalendarMappings).reduce((max, mmrId) => {
            const mappingWhenApplicable = mmrCalendarMappings[Number(mmrId)][dayItem.id]?.isApplicable
              ? mmrCalendarMappings[Number(mmrId)][dayItem.id]
              : 0;
            return mappingWhenApplicable ? Math.max(max, mappingWhenApplicable.maxHours) : max;
          }, 0);

          const isInvalid = totalHours < maxHours;

          return {
            calendarId: dayItem.id,
            isInvalid,
          };
        });
      setHasLessThanMaxHoursRecord(newValidationStates.map((dayValidationItem) => dayValidationItem.isInvalid).some((value) => value));
    };

    validateTotalHoursPerDay();
  }, [mmrCtrRecords, mmrCalendarMappings, setHasLessThanMaxHoursRecord, groupTimesheetDates]);

  return (
    <div key={timesheetEmployee.timesheetEmployeeId} className={classNames(TimesheetFormEmployeeListItemStyles.gridRow)}>
      <TimesheetFormEmployeeInfo
        employeeId={timesheetEmployee.employeeId}
        gridRowSpanNum={calculateRowSpan(timesheetProjectEmployeeActiveMMRsData ?? [])}
      />

      {timesheetProjectEmployeeActiveMMRsQuery.isLoading
        ? groupTimesheetEmployeeMMRSkeletonLoader
        : timesheetProjectEmployeeActiveMMRsData &&
          timesheetProjectEmployeeActiveMMRsData.map((MMRItem, index) => {
            const isLastMMRItem = index === timesheetProjectEmployeeActiveMMRsData.length - 1;
            return (
              <React.Fragment key={MMRItem.mmrId + index + timesheetEmployee.timesheetEmployeeId}>
                <React.Fragment key={MMRItem.mmrId + index}>
                  <TimesheetFormMMRInfo MMRId={MMRItem.mmrId} projectEmployeeId={timesheetEmployee.projectEmployeeId} />
                  {groupTimesheetDates.map((eachDayOfTheWeek, dayIndex) => {
                    if (eachDayOfTheWeek.calendarProjectStatus !== FrontendCalendarProjectStatusEnum.VALID) {
                      return (
                        <div
                          key={MMRItem.mmrId + eachDayOfTheWeek.id + dayIndex}
                          className={classNames(
                            TimesheetFormEmployeeListItemStyles.gridDateInfoCell,
                            TimesheetFormEmployeeListItemStyles.messageText,
                            GlobalStyles.emptyListMsgSmall,
                          )}
                        >
                          {eachDayOfTheWeek.calendarProjectStatus === FrontendCalendarProjectStatusEnum.BEFORE_PROJECT_START
                            ? "BEFORE PROJECT START"
                            : eachDayOfTheWeek.calendarProjectStatus === FrontendCalendarProjectStatusEnum.AFTER_PROJECT_END && "AFTER PROJECT END"}
                        </div>
                      );
                    }
                    const timesheetCalendarInfo = MMRItem.calendars.find((calendar) => calendar.id === eachDayOfTheWeek.id);
                    //const isDayApplicable = mmrCalendarMappings[MMRItem.mmrId].find((day) => day.calendarDayId === eachDayOfTheWeek.id)?.isApplicable;

                    //Get the days that exist in groupTimesheetDates and also in mmrCalendarMappings[MMRItem.mmrId], i need the id's

                    return (
                      <TimesheetFormDayColumn
                        key={MMRItem.mmrId + eachDayOfTheWeek.id + index}
                        weekDay={eachDayOfTheWeek}
                        mmrId={MMRItem.mmrId}
                        projectId={parseInt(projectId!)}
                        timesheetId={timesheetId}
                        timesheetGroupId={parseInt(groupId!)}
                        timesheetEmployeeId={timesheetEmployee.timesheetEmployeeId}
                        groupTimesheetDates={groupTimesheetDates}
                        timesheetCalendarInfo={timesheetCalendarInfo}
                        isEditMode={isEditMode && canFillTimesheet}
                        setMmrCalendarMappings={setMmrCalendarMappings}
                        isHoursPerDayInvalid={isHoursPerDayInvalid}
                      />
                    );
                  })}
                  {mmrCalendarMappings[MMRItem.mmrId] && (
                    <TimesheetFormCtrRecordList
                      key={MMRItem.mmrId + index}
                      MMRItem={MMRItem}
                      groupTimesheetDates={groupTimesheetDates}
                      mmrCtrRecords={mmrCtrRecords}
                      setMmrCtrRecords={setMmrCtrRecords}
                      onAddCtr={() => handleAddCtr(MMRItem.mmrId)}
                      applicableDates={mmrCalendarMappings[MMRItem.mmrId] ?? []}
                      onWorkHoursChange={updateWorkHours}
                      isEditMode={isEditMode && canFillTimesheet}
                      timesheetGroupId={parseInt(groupId!)}
                      timesheetId={timesheetId}
                      timesheetEmployeeId={timesheetEmployee.timesheetEmployeeId}
                      projectEmployeeId={timesheetEmployee.projectEmployeeId}
                      hoursPerDayRecordData={mmrCalendarMappings[MMRItem.mmrId]}
                    />
                  )}
                  {!isLastMMRItem &&
                    Array(8)
                      .fill(null)
                      .map((_, i) => <div key={i} className={classNames(TimesheetFormEmployeeListItemStyles.separator)}></div>)}
                </React.Fragment>
              </React.Fragment>
            );
          })}
    </div>
  );
};
export default TimesheetFormEmployeeListItem;
