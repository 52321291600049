import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import ManpowerCodePopupStyles from "./ManpowerCodeListStyles.module.css";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../apicalls/config.ts";
import InfiniteScrollInViewElement from "../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import ManpowerCodeItem from "../manpowercodeitem/ManpowerCodeItem.tsx";
import { useEpcmApiProjectManpowerCodes } from "../../../../../apicalls/projects/projectmanpowercodes/useEpcmApiProjectManpowerCodes.ts";
import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { AppRoutesPaths } from "../../../../../types/AppRoutesTypes.ts";
import BluePlusIcon from "../../../../../assets/images/blue-plus-icon.svg";

interface ManpowerCodeListProps {
  projectId: number;
  setTempManpowerCode: Dispatch<SetStateAction<number | null>>;
  selectedManpowerCodeId: number | null;
  setSelectedManpowerCodeName: Dispatch<SetStateAction<string | null>>;
  searchValue: string | undefined;
}

const ManpowerCodeList: FC<ManpowerCodeListProps> = ({
  projectId,
  setTempManpowerCode,
  selectedManpowerCodeId,
  setSelectedManpowerCodeName,
  searchValue,
}) => {
  const { inView, ref } = useInView();
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const { getAllManpowerPositionCodes } = useEpcmApiProjectManpowerCodes();

  const manpowerCodesQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectPositionCodes, projectId, searchValue],
    queryFn: ({ pageParam }) => getAllManpowerPositionCodes(projectId, pageParam, PAGINATION_PAGE_SIZE, searchValue),
    initialPageParam: 1,
    enabled: isAuthorized,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
  });

  const manpowerCodesQueryData = useMemo(() => {
    return manpowerCodesQuery.data?.pages?.flatMap((page) => page.data) ?? [];
  }, [manpowerCodesQuery.data]);

  const handleRadioChange = (optionId: number, optionCode: string) => {
    setTempManpowerCode(optionId);
    setSelectedManpowerCodeName(optionCode);
  };
  useEffect(() => {
    if (inView && manpowerCodesQuery.hasNextPage && !manpowerCodesQuery.isFetchingNextPage && !manpowerCodesQuery.isFetching) {
      manpowerCodesQuery.fetchNextPage();
    }
  }, [inView, manpowerCodesQuery]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.ProjectPositionCodes, projectId, searchValue] }).then(() => {
        console.log(`Query ${QueryNames.ProjectPositionCodes} cancelled`);
      });
    };
  }, [queryClient, projectId, searchValue]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.flexDirectionColumn, ManpowerCodePopupStyles.radioGroupContainer)}>
      {manpowerCodesQuery.isLoading ? (
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.flex1)}>
          <Skeleton variant={"rectangular"} width={"100%"} height={30}></Skeleton>
          <Skeleton variant={"rectangular"} width={"100%"} height={30}></Skeleton>
          <Skeleton variant={"rectangular"} width={"100%"} height={30}></Skeleton>
        </div>
      ) : manpowerCodesQueryData ? (
        <>
          <div
            className={classNames(
              ManpowerCodePopupStyles.manpowerListScrollingPadding,
              GlobalStyles.flex,
              GlobalStyles.gap,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.overflowAutoFullHeight,
            )}
          >
            {manpowerCodesQueryData.length > 0 ? (
              manpowerCodesQueryData.map((option, index) => (
                <div
                  onClick={() => handleRadioChange(option.id, option.code)}
                  key={index}
                  className={classNames(
                    ManpowerCodePopupStyles.radioContainer,
                    GlobalStyles.gap075,
                    GlobalStyles.centerHorizontal,
                    GlobalStyles.elementWithCursor,
                  )}
                >
                  <input
                    type="radio"
                    value={option.id}
                    className={classNames(ManpowerCodePopupStyles.radioInput)}
                    checked={selectedManpowerCodeId === option.id}
                    onChange={() => handleRadioChange(option.id, option.code)}
                  />
                  <ManpowerCodeItem id={option.id} code={option.code} projectId={projectId} />
                </div>
              ))
            ) : (
              <div className={classNames(GlobalStyles.flex, GlobalStyles.centerBoth, GlobalStyles.emptyListMsg)}>No MP Codes found</div>
            )}

            <InfiniteScrollInViewElement
              reference={ref}
              infiniteQueryResult={manpowerCodesQuery}
              loaderComponent={<Skeleton width={"100%"} height={20} />}
            />
          </div>
        </>
      ) : (
        <></>
      )}
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
        <div
          className={classNames(
            ManpowerCodePopupStyles.plusIconContainer,
            GlobalStyles.flex,
            GlobalStyles.centerHorizontal,
            GlobalStyles.centerVertical,
          )}
        >
          <img src={BluePlusIcon} alt={"Add New Mp-Code"} />
        </div>
        <Link
          to={`/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.configurations}/${AppRoutesPaths.projectConfigurationsManpowerPositions}`}
          className={classNames(ManpowerCodePopupStyles.linkToManpowerCode)}
        >
          Create MP Code In Configurations
        </Link>
      </div>
    </div>
  );
};
export default ManpowerCodeList;
