import { DaysOfWeek } from "epcm-common/src/Types/GeneralTypes.ts";

export enum FrontendCalendarTabs {
  Locations = 0,
  CutOffDate = 1,
  ProjectStartDayOfWeek = 2,
  WorkingDays = 3,
  Holidays = 4,
}

export const createNewCalendarErrorMessages = new Map<FrontendCalendarTabs, string>([
  [FrontendCalendarTabs.Locations, "Please select a location"],
  [FrontendCalendarTabs.CutOffDate, "Please select a cut off date"],
  [FrontendCalendarTabs.ProjectStartDayOfWeek, "Please select a project start day of the week"],
  [FrontendCalendarTabs.WorkingDays, "Please select working days"],
  [FrontendCalendarTabs.Holidays, "Please select holidays"],
]);

export interface FrontendHolidayItem {
  locationHolidays: FrontendLocationHoliday;
  allSelectedLocations?: boolean;
  locationIds?: number[];
}

export interface FrontendLocationHoliday {
  date: Date | null;
  holidayTitle: string;
}

export interface FrontendLocationWorkingDays {
  locationId?: number;
  /* locationMonthWorkingDays: LocationMonthWorkingDays[];*/
  workingDays: DaysOfWeek[];
  allSelectedLocations?: boolean;
}
