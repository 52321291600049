import { ApiResponseMmrDateLimits } from "epcm-common/dist/Types/MmrTypes";
import { FrontendMmrDateLimits } from "../../../../projects/MmrTypes.ts";

export const convertProjectEmployeeMmrDates = (mmrDates: ApiResponseMmrDateLimits): FrontendMmrDateLimits => {
  return {
    earliestDemobilizationDate: mmrDates.earliestDemobilizationDateTimestamp ? new Date(mmrDates.earliestDemobilizationDateTimestamp) : null,
    latestMobilizationDate: mmrDates.latestMobilizationDateTimestamp ? new Date(mmrDates.latestMobilizationDateTimestamp) : null,
    calendarEndDate: new Date(mmrDates.calendarEndDateTimestamp),
    calendarStartDate: new Date(mmrDates.calendarStartDateTimestamp),
  };
};
