import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import GlobalEmployeeInfoDetailedStyles from "./GlobalEmployeeInfoDetailed.module.css";
import { FC, useEffect, useMemo } from "react";
import { calculateAge } from "../../../../../../utils/DateManipulation.ts";
import { capitalizeFirstLetter } from "../../../../../../utils/StringManipulation.ts";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiConfigurations } from "../../../../../../apicalls/configurations/useEpcmApiConfigurations.ts";
import { FrontendGlobalPositionType } from "../../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import { Skeleton } from "@mui/material";

interface GlobalEmployeeInfoDetailedProps {
  employeeType: string | null;
  employeePositionId: number | null;
  employeeNationality: string | null;
  employeeDateOfBirth: string | null;
  employeeLanguages: string[] | null;
}

export const GlobalEmployeeInfoDetailed: FC<GlobalEmployeeInfoDetailedProps> = ({
  employeePositionId,
  employeeType,
  employeeNationality,
  employeeDateOfBirth,
  employeeLanguages,
}) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getGlobalPosition } = useEpcmApiConfigurations();

  const employeeGlobalPositionQuery = useQuery({
    queryKey: [QueryNames.GlobalPositions, employeePositionId],
    queryFn: () => getGlobalPosition(employeePositionId ?? 0),
    enabled: isAuthorized && !!employeePositionId,
    select: (data) => data as FrontendGlobalPositionType,
  });

  const employeeGlobalPositionData = useMemo(() => {
    return employeeGlobalPositionQuery.data ?? undefined;
  }, [employeeGlobalPositionQuery]);

  useEffect(() => {
    return () => {
      employeePositionId &&
        queryClient.cancelQueries({ queryKey: [QueryNames.GlobalPositions, employeePositionId] }).then(() => {
          console.log(`Query Name with ${QueryNames.GlobalPositions} and id ${employeePositionId} is cancelled`);
        });
    };
  }, [queryClient, employeePositionId]);

  const detailedInfo = useMemo(() => {
    return [
      {
        label: "Employee Type",
        value: employeeType,
      },
      {
        label: "Position",
        value: employeeGlobalPositionQuery.isLoading ? <Skeleton variant={"text"} width={80} height={30} /> : employeeGlobalPositionData?.description,
      },
      {
        label: "Nationality",
        value: employeeNationality
          ? employeeNationality
              .split(" ")
              .map((wordItem) => capitalizeFirstLetter(wordItem))
              .join(" ")
          : "",
      },
      {
        label: "Date of Birth",
        value: employeeDateOfBirth ? `${employeeDateOfBirth} [${calculateAge(new Date(employeeDateOfBirth))} Y/O]` : "",
      },
      {
        label: "Languages",
        value: employeeLanguages ? employeeLanguages : [""],
      },
    ];
  }, [employeeType, employeeNationality, employeeDateOfBirth, employeeLanguages, employeeGlobalPositionData, employeeGlobalPositionQuery.isLoading]);

  return (
    <div className={classNames(GlobalEmployeeInfoDetailedStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15)}>
      {detailedInfo.map((info) => (
        <div key={info.label} className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(GlobalEmployeeInfoDetailedStyles.secondaryText)}>{info.label}</div>
          {Array.isArray(info.value) ? (
            <div className={classNames(GlobalEmployeeInfoDetailedStyles.highlightedText)}>{info.value.join(", ")}</div>
          ) : (
            <div className={classNames(GlobalEmployeeInfoDetailedStyles.highlightedText)}>{info.value}</div>
          )}
        </div>
      ))}
    </div>
  );
};
