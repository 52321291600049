import { ApiResponseBodyTimesheetGroupGetSectionHead } from "epcm-common/dist/Types/TimesheetGroupTypes.ts";
import { FrontendSectionHeadType } from "../../../../ProjectTimesheetsApiTypes.ts";

export const convertProjectTimesheetGroupSectionHeadData = (
  sectionHeadData: ApiResponseBodyTimesheetGroupGetSectionHead,
): FrontendSectionHeadType => {
  return sectionHeadData.sectionHead
    ? {
        sectionHead: {
          code: sectionHeadData.sectionHead.code,
          name: ` ${sectionHeadData.sectionHead.surname} ${sectionHeadData.sectionHead.name}`,
          email: sectionHeadData.sectionHead.email,
        },
      }
    : {
        sectionHead: null,
      };
};
