import { AxiosRequestConfig } from "axios";
import { UserGlobal } from "epcm-common/dist/Types/UserTypes";
import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../../config.ts";
import { QueryModelWithCountAndData, QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";

const API = `${EPCM_API_URLS.configurations}`;

export const getGlobalUserRoles = async (
  pageOffset?: number,
  searchFilter?: string,
  pageSize: number = PAGINATION_PAGE_SIZE,
  notInProjectId?: number,
  hasGlobalRoles?: boolean,
): Promise<QueryModelWithPagination<UserGlobal>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);
  notInProjectId && params.set("notInProjectId", `${notInProjectId}`);
  hasGlobalRoles && params.set("hasGlobalRoles", `${hasGlobalRoles}`);

  const config: AxiosRequestConfig = {
    url: `${API}/users/roles`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);
  const responseData = response.data as QueryModelWithCountAndData<UserGlobal>;

  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;
  return { data: responseData.data, nextPage: nextPage, totalCount: responseData.count };
};
