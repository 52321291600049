import React, { LegacyRef, ReactNode } from "react";
import { UseInfiniteQueryResult } from "@tanstack/react-query";

export interface InfiniteScrollInViewElementProps {
  infiniteQueryResult: UseInfiniteQueryResult;
  reference: LegacyRef<HTMLDivElement>;
  loaderComponent: ReactNode;
}

const InfiniteScrollInViewElement: React.FC<InfiniteScrollInViewElementProps> = ({ infiniteQueryResult, reference, loaderComponent }) => {
  return <div ref={reference}>{infiniteQueryResult.isFetching && infiniteQueryResult.hasNextPage && loaderComponent}</div>;
};

export default InfiniteScrollInViewElement;
