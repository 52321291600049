import {
  FrontendOvertimeExportFileType,
  overtimeExportFileMappingBackend,
  overtimeExportFileMappingFrontend,
} from "../../../projects/OvertimeTypes.ts";
import { OvertimeRequestExportType } from "epcm-common/dist/Types/OvertimeRequestTypes";

export const convertToBackendOvertimeRequestExportType = (type: FrontendOvertimeExportFileType): OvertimeRequestExportType => {
  const backendOvertimeExportFileType = overtimeExportFileMappingBackend.get(type);
  return backendOvertimeExportFileType!;
};

export const convertToFrontendOvertimeRequestExportType = (type: OvertimeRequestExportType): FrontendOvertimeExportFileType => {
  const frontendOvertimeExportFileType = overtimeExportFileMappingFrontend.get(type);
  return frontendOvertimeExportFileType!;
};
