import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";

import { AxiosRequestConfig } from "axios";
import { ApiRequestBodyMmrUpdate } from "epcm-common/dist/Types/MmrTypes";

const URL = `${EPCM_API_URLS.projects}`;

export const updateMmr = async (projectId: number, employeeId: number, mmrId: number, updatedData: ApiRequestBodyMmrUpdate) => {
  const config: AxiosRequestConfig = {
    method: "put",
    url: `${URL}/${projectId}/employees/${employeeId}/mmr/${mmrId}`,
    data: updatedData,
  };

  return await epcmAxiosInstance(config);
};
