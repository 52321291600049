import { FrontendUserLimitedType } from "./UsersApiTypes.ts";
import { ProjectStatus } from "epcm-common/dist/Types/ProjectTypes";

export interface FrontendProjectLimitedType {
  id: number;
  name: string;
}

export enum FrontendProjectStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
}

export interface FrontendProjectBasicType extends FrontendProjectLimitedType {
  code: number;
  projectLocation: string;
}

export const convertBackendStatusToFrontend = (status: ProjectStatus): FrontendProjectStatus => {
  const statusMap: Map<ProjectStatus, FrontendProjectStatus> = new Map([
    [ProjectStatus.ACTIVE, FrontendProjectStatus.ACTIVE],
    [ProjectStatus.INACTIVE, FrontendProjectStatus.INACTIVE],
    [ProjectStatus.COMPLETED, FrontendProjectStatus.COMPLETED],
    [ProjectStatus.CANCELLED, FrontendProjectStatus.CANCELLED],
  ]);
  return statusMap.get(status)!;
};

export const convertFrontendStatusToBackend = (status: FrontendProjectStatus): ProjectStatus => {
  const statusMap: Map<FrontendProjectStatus, ProjectStatus> = new Map([
    [FrontendProjectStatus.ACTIVE, ProjectStatus.ACTIVE],
    [FrontendProjectStatus.INACTIVE, ProjectStatus.INACTIVE],
    [FrontendProjectStatus.COMPLETED, ProjectStatus.COMPLETED],
    [FrontendProjectStatus.CANCELLED, ProjectStatus.CANCELLED],
  ]);
  return statusMap.get(status)!;
};

export interface FrontendProjectNormalType extends FrontendProjectBasicType {
  dateOfSign: Date;
  duration: number;
  status: FrontendProjectStatus;
  endDate: Date;
}

export interface FrontendProjectDetailedType extends FrontendProjectNormalType {
  creator: FrontendUserLimitedType;
  agreementNumber: string;
  mmrPrefix: string;
  overtimePrefix: string;
  projectManager: FrontendUserLimitedType | null;
  projectClientSeniorManager: string | null;
  projectCostControlEngineer: FrontendUserLimitedType | null;
  projectClientCostControlEngineer: string | null;
  projectControlsManager: FrontendUserLimitedType | null;
  projectClientControlsManager: string | null;
}
