import { FC } from "react";
import CreateMmrActionBarStyles from "./CreateMmrActionBar.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";

interface CreateMmrActionBarProps {
  openSelectManpowerCodePopup: () => void;
  manpowerCode: string | null;
  isEditMode: boolean;
  revisionNumber: number | undefined;
}

export const CreateMmrActionBar: FC<CreateMmrActionBarProps> = ({ openSelectManpowerCodePopup, manpowerCode, isEditMode, revisionNumber }) => {
  return (
    <div className={classNames(CreateMmrActionBarStyles.mainContainer, GlobalStyles.flex)}>
      <div className={classNames(CreateMmrActionBarStyles.container, GlobalStyles.flex, GlobalStyles.flex2)}>
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap075,
            CreateMmrActionBarStyles.textContainer,
          )}
        >
          {isEditMode ? (
            <div
              className={classNames(CreateMmrActionBarStyles.mainText, GlobalStyles.flex, GlobalStyles.centerHorizontal)}
            >{`Edit MMR Version : ${revisionNumber}`}</div>
          ) : (
            <div className={classNames(CreateMmrActionBarStyles.mainText, GlobalStyles.flex, GlobalStyles.centerHorizontal)}>{`New MMR Version`}</div>
          )}

          <div className={classNames(CreateMmrActionBarStyles.pending, CreateMmrActionBarStyles.secondaryText)}>
            {isEditMode ? "Draft - Edit MMR Version" : "Draft - New MMR Version"}
          </div>
        </div>
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.centerHorizontal,
            GlobalStyles.gap075,
            CreateMmrActionBarStyles.actionButtonsContainer,
          )}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap01)}>
            {!isEditMode && (
              <div
                className={classNames(CreateMmrActionBarStyles.selectContainer, GlobalStyles.elementWithCursor)}
                onClick={openSelectManpowerCodePopup}
              >
                Select MP Code
              </div>
            )}

            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
              {manpowerCode && (
                <div
                  className={classNames(CreateMmrActionBarStyles.textContainer, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}
                >
                  <div className={classNames(CreateMmrActionBarStyles.mainText)}>{`MP CODE:`}</div>
                  <div className={classNames(CreateMmrActionBarStyles.mainText)}>{manpowerCode}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
