import { PopupButtonType, PopupButtonTypeEnum, PopupType } from "../../types/PopupType.ts";
import { FC, useMemo } from "react";
import ResponseAlertPopupStyles from "./ResponseAlertPopup.module.css";
import { PopupStructure } from "../popupstructure/PopupStructure.tsx";
import classNames from "classnames";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import { ApiResponseTypeEnum } from "../../types/apicallstypes/queryCommons.ts";
import warningIcon from "../../assets/images/warning-icon-circled-red-background.svg";
import orangeWarningIcon from "../../assets/images/warning-icon-circled-orange-background.svg";
import successIcon from "../../assets/images/tick-black.svg";
import { BaseErrorInterface } from "epcm-common/dist/Types/ErrorTypes.ts";

interface ResponseAlertPopupProps extends PopupType {
  responseType: ApiResponseTypeEnum;
  responseObject: BaseErrorInterface;
  popupButtons?: PopupButtonType[];
}

export const ResponseAlertPopup: FC<ResponseAlertPopupProps> = ({ isOpen, closeFn, responseType, responseObject, popupButtons }) => {
  const isSuccess = useMemo(() => {
    return responseType === ApiResponseTypeEnum.success;
  }, [responseType]);

  const isError = useMemo(() => {
    return responseType === ApiResponseTypeEnum.error;
  }, [responseType]);

  const isWarning = useMemo(() => {
    if (responseType === ApiResponseTypeEnum.warning) {
      return true;
    }
  }, [responseType]);

  return (
    <PopupStructure
      popupButtons={
        popupButtons ?? [
          {
            text: "Close",
            buttonType: PopupButtonTypeEnum.neutral,
            action: closeFn,
          },
        ]
      }
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={undefined}
    >
      <div className={classNames(ResponseAlertPopupStyles.contentContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img
            src={isWarning ? orangeWarningIcon : isSuccess ? successIcon : isError ? warningIcon : undefined}
            alt="result"
            className={classNames(ResponseAlertPopupStyles.resultIconImg)}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div
            className={classNames(
              ResponseAlertPopupStyles.contentText,
              isSuccess && ResponseAlertPopupStyles.contentSuccessText,
              isError && ResponseAlertPopupStyles.contentDangerText,
              isWarning && ResponseAlertPopupStyles.contentWarningText,
            )}
          >
            {isWarning ? "Warning" : isSuccess ? "Success" : isError ? "Error" : undefined}
          </div>
          <div
            className={classNames(
              responseObject.details && responseObject.details.length > 0
                ? ResponseAlertPopupStyles.contentMainText
                : ResponseAlertPopupStyles.contentSecondaryText,
            )}
          >
            {responseObject.message}
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
            {responseObject.details &&
              responseObject.details.map((detailsItem, index) => (
                <div key={index} className={classNames(ResponseAlertPopupStyles.detailsItemContainer, GlobalStyles.flex, GlobalStyles.gap)}>
                  <div className={classNames(GlobalStyles.flex1)} />
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                    <div className={classNames(GlobalStyles.centerVertical)}>{`${detailsItem.entity}:`}</div>
                    <div className={classNames(GlobalStyles.centerVertical)}>{detailsItem.value}</div>
                  </div>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
                    <div className={classNames(GlobalStyles.flex1)} />
                    <div className={classNames(ResponseAlertPopupStyles.dotSeparator)} />
                    <div className={classNames(GlobalStyles.flex1)} />
                  </div>
                  <div
                    className={classNames(
                      ResponseAlertPopupStyles.contentSecondaryText,
                      ResponseAlertPopupStyles.errorMessageContainer,
                      GlobalStyles.centerVertical,
                    )}
                  >
                    {detailsItem.message}
                  </div>
                  <div className={classNames(GlobalStyles.flex1)} />
                </div>
              ))}
          </div>
          {/* TODO: display excel error details */}
        </div>
      </div>
    </PopupStructure>
  );
};
