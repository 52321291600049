import { FC } from "react";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import MmrInformationInfoBoxStyles from "./MmrInformationInfoBox.module.css";
import MmrDataContent from "./mmrdatacontent/MmrDataContent.tsx";

interface MmrInformationInfoBoxProps {
  title: string;
  content: string | number | null | undefined;
  isLoading?: boolean;
}

const MmrInformationInfoBox: FC<MmrInformationInfoBoxProps> = ({ title, content, isLoading }) => {
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
      <div className={classNames(MmrInformationInfoBoxStyles.title)}>{title}</div>
      <div className={classNames(MmrInformationInfoBoxStyles.content)}>
        <MmrDataContent mmrDataItem={{ content, isLoading }} />
      </div>
    </div>
  );
};
export default MmrInformationInfoBox;
