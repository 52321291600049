import { FC } from "react";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import UploadFileViewStyles from "./UploadFileView.module.css";
import classNames from "classnames";
import uploadIcon from "../../../../../assets/images/upload-icon-black-lines.svg";

interface UploadFileViewProps {}

export const UploadFileView: FC<UploadFileViewProps> = () => {
  return (
    <div className={classNames(UploadFileViewStyles.uploadInfoContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
      <div className={classNames(GlobalStyles.flex1)} />
      <div className={classNames(GlobalStyles.centerVertical)}>
        <img src={uploadIcon} alt="upload" className={classNames(UploadFileViewStyles.uploadInfoIconImg)} />
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
        <div className={classNames(UploadFileViewStyles.uploadInfoText)}>{"Are you sure you want to upload file?"}</div>
        <div className={classNames(UploadFileViewStyles.uploadInfoSecondaryText)}>{"Overtime request file ready!"}</div>
      </div>
      <div className={classNames(GlobalStyles.flex1)} />
    </div>
  );
};
