import classNames from "classnames";
import SearchBarStyles from "./SearchBar.module.css";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import searchLens from "../../assets/images/search-bar-magnifing.svg";
import eraseIcon from "../../assets/images/close-popup.svg";
import { FC, useEffect, useRef, useState } from "react";

interface SearchBarProps {
  placeholder: string;
  setSearchQuery?: (query: string) => void;
  searchBarContainerStyles?: string;
}

export const SearchBar: FC<SearchBarProps> = ({ placeholder, setSearchQuery, searchBarContainerStyles }) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [isQueryReset, setIsQueryReset] = useState(false);

  const onFilterInputChange = () => {
    setIsQueryReset(false);
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        if (setSearchQuery) {
          searchInputRef.current ? setSearchQuery(searchInputRef.current.value) : setSearchQuery("");
        }
      }, 500),
    );
  };

  useEffect(() => {
    if (setSearchQuery) {
      searchInputRef.current!.value = "";
      setSearchQuery("");
    }
  }, [isQueryReset, setSearchQuery]);

  return (
    <div
      className={classNames(
        searchBarContainerStyles ?? SearchBarStyles.searchBarContainerDefault,
        GlobalStyles.flex,
        GlobalStyles.flex1,
        GlobalStyles.gap05,
      )}
    >
      <div className={classNames(GlobalStyles.centerVertical)}>
        <img className={classNames(SearchBarStyles.searchBarLensIconImg)} src={searchLens} alt="search" />
      </div>
      <input
        ref={searchInputRef}
        className={classNames(SearchBarStyles.searchBarInput, GlobalStyles.flex1)}
        placeholder={placeholder}
        onChange={onFilterInputChange}
      />
      {searchInputRef.current && searchInputRef.current.value !== "" && (
        <div className={classNames(GlobalStyles.centerVertical)} onClick={() => setIsQueryReset(true)}>
          <img className={classNames(SearchBarStyles.eraseIconImg)} src={eraseIcon} alt="delete" />
        </div>
      )}
    </div>
  );
};
