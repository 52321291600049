import { FC, useCallback, useState } from "react";
import ConfigurationsDisciplinesRowStyles from "./GlobalConfigurationDisciplinesRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

import TrashCanDarkIcon from "../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIconHover from "../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import EditIcon from "../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../assets/images/trashcan-icon-red.svg";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { SettingsPagePopups, useSettingsPopups } from "../../../use-settings-pupups.ts";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { useQueryClient } from "@tanstack/react-query";
import { FrontendGlobalDisciplineType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { DeletePopup } from "../../../../../ui/deletepopup/DeletePopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import GlobalConfigurationDisciplinesPopup from "../../popups/globalconfigurationdisciplinespopup/GlobalConfigurationDisciplinesPopup.tsx";
import { GlobalAction } from "../../../../../types/Roles.ts";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";

interface GlobalConfigurationDisciplineRowProps {
  globalDisciplineItem: FrontendGlobalDisciplineType;
}

const GlobalConfigurationDisciplinesRow: FC<GlobalConfigurationDisciplineRowProps> = ({ globalDisciplineItem }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();

  const queryClient = useQueryClient();
  const { useDeleteGlobalEntityMutation } = useEpcmApiConfigurationsMutations();

  const canListConfigurations = canPerformGlobalAction(GlobalAction.ConfigList);
  const canEditConfigurations = canPerformGlobalAction(GlobalAction.ConfigUpdate);
  const canDeleteConfigurations = canPerformGlobalAction(GlobalAction.ConfigDelete);

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const deleteGlobalDisciplineMutation = useDeleteGlobalEntityMutation(
    globalDisciplineItem?.id ?? 0,
    FrontendGlobalConfigurationsEntityEnum.Discipline,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: "Global Discipline was deleted successfully!" });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalDisciplines] });
  }, [queryClient]);

  return (
    <>
      <tr
        key={globalDisciplineItem.id}
        className={classNames(
          ConfigurationsDisciplinesRowStyles.itemContainer,
          isRowHovered && ConfigurationsDisciplinesRowStyles.rowHoverEffect,
          ConfigurationsDisciplinesRowStyles.Trow,
        )}
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
      >
        {globalDisciplineItem && canListConfigurations ? (
          <>
            <td
              className={classNames(
                ConfigurationsDisciplinesRowStyles.tableCell,
                ConfigurationsDisciplinesRowStyles.boldText,
                ConfigurationsDisciplinesRowStyles.tD,
              )}
            >
              {globalDisciplineItem.code}
            </td>
            <td className={classNames(ConfigurationsDisciplinesRowStyles.tableCell, ConfigurationsDisciplinesRowStyles.tD)}>
              {globalDisciplineItem.description}
            </td>
            <td className={classNames(ConfigurationsDisciplinesRowStyles.tableCellActions, ConfigurationsDisciplinesRowStyles.tD)}>
              <div
                className={classNames(
                  ConfigurationsDisciplinesRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                {canDeleteConfigurations && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();

                      onOpenUtilPopup();
                    }}
                    onMouseEnter={() => setDeleteIconHovered(true)}
                    onMouseLeave={() => setDeleteIconHovered(false)}
                  >
                    <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                  </div>
                )}
                {canEditConfigurations && (
                  <div
                    className={classNames(ConfigurationsDisciplinesRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                    onClick={() => onOpenPopup(SettingsPagePopups.updateGlobalDiscipline, popupHandler)}
                    onMouseEnter={() => setEditIconHovered(true)}
                    onMouseLeave={() => setEditIconHovered(false)}
                  >
                    <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                  </div>
                )}
              </div>
            </td>
          </>
        ) : (
          <></>
        )}
      </tr>
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteGlobalDisciplineMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}

      {popupHandler.get(SettingsPagePopups.updateGlobalDiscipline)!.isOpen && (
        <GlobalConfigurationDisciplinesPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(SettingsPagePopups.updateGlobalDiscipline, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.updateGlobalDiscipline)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.updateGlobalDiscipline)}
          globalDisciplineItem={globalDisciplineItem}
        />
      )}
    </>
  );
};

export default GlobalConfigurationDisciplinesRow;
