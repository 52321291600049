import { FC, PropsWithChildren } from "react";
import GlobalStyles from "../../../assets/css/GlobalStyles.module.css";
import EmployeeListStyles from "./EmployeeList.module.css";
import classNames from "classnames";
import { FrontendEmployeeLimitedType } from "../../../types/apicallstypes/EmployeesApiTypes.ts";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { EmployeeListItem } from "./employeelistitem/EmployeeListItem.tsx";
import { EmployeeProfilePopupTypeEnum } from "../../../types/employees/EmployeesTypes.ts";

interface EmployeeListProps {
  employeeList: FrontendEmployeeLimitedType[];
  onOpenEmployeeProfile: (employeeId: number, popupType: EmployeeProfilePopupTypeEnum) => void;
}

export const EmployeeList: FC<PropsWithChildren<EmployeeListProps>> = ({ employeeList, onOpenEmployeeProfile, children }) => {
  const [parent] = useAutoAnimate();

  return employeeList.length > 0 ? (
    <div
      ref={parent}
      className={classNames(
        GlobalStyles.flex1,
        EmployeeListStyles.employeeListContainer,
        GlobalStyles.flex,
        GlobalStyles.flexWrap,
        GlobalStyles.gap3,
      )}
    >
      {employeeList.map((employeeItem) => (
        <EmployeeListItem key={employeeItem.id} employeeItem={employeeItem} onOpenEmployeeProfile={onOpenEmployeeProfile} />
      ))}
      {children}
    </div>
  ) : (
    <div className={classNames(GlobalStyles.emptyListMsg, GlobalStyles.flex)}>
      <div className={classNames(GlobalStyles.flex1)} />
      <div>{"No Employees"}</div>
      <div className={classNames(GlobalStyles.flex1)} />
    </div>
  );
};
