import { FrontendTimesheetLimitedType, FrontendTimesheetPeriodType } from "../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { convertProjectTimesheetPeriodsData } from "../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/ProjectTimesheetsApiConverters.ts";
import { getAllTimesheetPeriods } from "./getAllTimesheetPeriods.ts";
import { getAllTimesheets } from "./getAllTimesheets.ts";
import { convertTimesheetsGroupTimesheetsLimitedData } from "../../../types/apicallstypes/apicallstypesconverters/projects/projecttimesheets/projecttimesheetgroups/ProjectTimesheetGroupsApiConverters.ts";
import { FrontendFileIdResponseType } from "../../../types/apicallstypes/GeneralTypes.ts";
import { getTimesheetFileExported } from "./getTimesheetFileExported.ts";

interface ApiDefinition {
  getAllTimesheetPeriods: (
    id: number,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ) => Promise<QueryModelWithPagination<FrontendTimesheetPeriodType>>;
  getAllTimesheets: (
    id: number,
    periodStart: Date,
    periodEnd: Date,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ) => Promise<QueryModelWithPagination<FrontendTimesheetLimitedType>>;

  getTimesheetFileExported: (id: number, timesheetGroupId: number, timesheetId: number) => Promise<FrontendFileIdResponseType>;
}

export const useEpcmApiProjectsTimesheets = (): ApiDefinition => {
  const getAllTimesheetPeriodsConverted = async (
    id: number,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ): Promise<QueryModelWithPagination<FrontendTimesheetPeriodType>> => {
    const timesheetPeriodsData = await getAllTimesheetPeriods(id, pageSize, pageOffset, searchFilter);
    return {
      data: convertProjectTimesheetPeriodsData(timesheetPeriodsData.data),
      nextPage: timesheetPeriodsData.nextPage,
      totalCount: timesheetPeriodsData.totalCount,
    };
  };

  const getAllTimesheetsConverted = async (
    id: number,
    periodStart: Date,
    periodEnd: Date,
    pageSize: number,
    pageOffset?: number,
    searchFilter?: string,
  ): Promise<QueryModelWithPagination<FrontendTimesheetLimitedType>> => {
    const timesheetsData = await getAllTimesheets(id, periodStart, periodEnd, pageSize, pageOffset, searchFilter);
    return {
      data: convertTimesheetsGroupTimesheetsLimitedData(timesheetsData.data),
      nextPage: timesheetsData.nextPage,
      totalCount: timesheetsData.totalCount,
    };
  };

  const getTimesheetFileExportedConverted = async (
    id: number,
    timesheetGroupId: number,
    timesheetId: number,
  ): Promise<FrontendFileIdResponseType> => {
    const timesheetFileData = await getTimesheetFileExported(id, timesheetGroupId, timesheetId);

    return {
      fileId: timesheetFileData.fileId,
    };
  };

  return {
    getAllTimesheetPeriods: getAllTimesheetPeriodsConverted,
    getAllTimesheets: getAllTimesheetsConverted,
    getTimesheetFileExported: getTimesheetFileExportedConverted,
  };
};
