import { FrontendUserRolesType } from "../types/apicallstypes/UsersApiTypes.ts";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface GlobalRolesState {
  globalRoles: FrontendUserRolesType[];
  setGlobalRoles: (newGlobalRoles: FrontendUserRolesType[]) => void;
}

export const useGlobalRoleStore = create<GlobalRolesState>()(
  persist(
    (set, get) => ({
      globalRoles: get()?.globalRoles,
      setGlobalRoles: (newGlobalRoles: FrontendUserRolesType[]) => {
        set((state) => ({
          ...state,
          globalRoles: newGlobalRoles,
        }));
      },
    }),
    {
      name: "global-roles-storage",
    },
  ),
);
