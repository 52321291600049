import { EPCMInfoContainerDiv } from "../../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import TimesheetReportButtonsStyles from "./TimesheetReportButtonsStyles.module.css";

const TimesheetReportButtons = () => {
  return (
    <EPCMInfoContainerDiv
      className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05, GlobalStyles.overflowHiddenFullHeight)}
    >
      <div className={classNames(TimesheetReportButtonsStyles.generateTimesheetText)}>Generate Fused Report</div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
      <div
        className={classNames(
          TimesheetReportButtonsStyles.generateTimesheetButton,
          GlobalStyles.flex,
          GlobalStyles.centerHorizontal,
          GlobalStyles.centerVertical,
          GlobalStyles.elementWithCursor,
        )}
      >
        Generate
      </div>
    </EPCMInfoContainerDiv>
  );
};
export default TimesheetReportButtons;
