import { useOutletContext } from "react-router-dom";
import { FrontendProjectLimitedType } from "../../types/apicallstypes/ProjectsApiTypes.ts";
import { Dispatch, SetStateAction } from "react";

export type ProjectContextType = {
  currentProject: FrontendProjectLimitedType;
  searchQuery: string;
  selectedPeriodStart?: Date | null;
  setSelectedPeriodStart?: Dispatch<SetStateAction<Date | null>>;
  selectedPeriodEnd?: Date | null;
  setSelectedPeriodEnd?: Dispatch<SetStateAction<Date | null>>;
};

export function useProjectContext() {
  return useOutletContext<ProjectContextType>();
}
