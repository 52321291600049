import classNames from "classnames";
import ConfigurationSubpositionStyles from "./GlobalConfigurationSubpositionTooltip.module.css";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import settingGearIcon from "../../../../../../assets/images/settings-icon-blue-dark.svg";
import deleteIcon from "../../../../../../assets/images/trashcan-icon-red.svg";

import { FC, useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { FrontendSubPositionType } from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";

import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import GlobalConfigurationSubpositionsPopup from "../../../popups/globalconfigurationsubpositionspopup/GlobalConfigurationSubpositionsPopup.tsx";
import { DeletePopup } from "../../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { SettingsPagePopups, useSettingsPopups } from "../../../../use-settings-pupups.ts";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { GlobalAction } from "../../../../../../types/Roles.ts";
import { useRetrieveUserPermittedActions } from "../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { Tooltip } from "@mui/material";
import DeleteMmrItemGrayIcon from "../../../../../../assets/images/delete-bin-light-grey.svg";

interface ConfigurationSubpositionTooltipProps {
  positionId: number;
  subpositionItem: FrontendSubPositionType;
  canBeDeleted: boolean;
}

const GlobalConfigurationSubpositionTooltip: FC<ConfigurationSubpositionTooltipProps> = ({ subpositionItem, positionId, canBeDeleted }) => {
  const queryClient = useQueryClient();
  const { useDeleteGlobalEntityMutation } = useEpcmApiConfigurationsMutations();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();

  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const canEditConfigurations = canPerformGlobalAction(GlobalAction.ConfigUpdate);
  const canDeleteConfigurations = canPerformGlobalAction(GlobalAction.ConfigDelete);

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const deleteGlobalSubpositionMutation = useDeleteGlobalEntityMutation(
    positionId,
    FrontendGlobalConfigurationsEntityEnum.Subposition,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: "Sub-position  was deleted successfully!" });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
    subpositionItem.id ?? 0,
  );

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalSubpositions, positionId] });
  }, [queryClient, positionId]);

  return (
    <div className={classNames(ConfigurationSubpositionStyles.employeeTooltipContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      {canEditConfigurations && (
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.gap)}
          onClick={(event) => {
            event.preventDefault();
            onOpenPopup(SettingsPagePopups.updateGlobalSubposition, popupHandler);
          }}
        >
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(ConfigurationSubpositionStyles.employeeTooltipIconImg)} src={settingGearIcon} alt="gear" />
          </div>
          <div className={classNames(GlobalStyles.centerVertical, ConfigurationSubpositionStyles.toolTipTextBase)}>{"Update sub-position"}</div>
        </div>
      )}
      {canDeleteConfigurations && (
        <Tooltip title={canBeDeleted ? "" : "This sub-position cannot be deleted"} placement="right" arrow>
          <div
            className={classNames(GlobalStyles.flex, GlobalStyles.gap, !canBeDeleted && ConfigurationSubpositionStyles.toolTipNotClickableText)}
            onClick={(event) => {
              if (canBeDeleted) {
                event.preventDefault();
                onOpenUtilPopup();
              }
            }}
          >
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img
                className={classNames(ConfigurationSubpositionStyles.employeeTooltipIconImg)}
                src={canBeDeleted ? deleteIcon : DeleteMmrItemGrayIcon}
                alt="deleteIcon"
              />
            </div>
            <div
              className={classNames(
                GlobalStyles.centerVertical,
                ConfigurationSubpositionStyles.toolTipTextBase,
                canBeDeleted ? ConfigurationSubpositionStyles.toolTipTextRed : ConfigurationSubpositionStyles.toolTipItemNotClickable,
                !canBeDeleted && ConfigurationSubpositionStyles.toolTipNotClickableText,
              )}
            >
              {"Delete sub-position"}
            </div>
          </div>
        </Tooltip>
      )}
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteGlobalSubpositionMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      {popupHandler.get(SettingsPagePopups.updateGlobalSubposition)!.isOpen && (
        <GlobalConfigurationSubpositionsPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(SettingsPagePopups.updateGlobalSubposition, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.updateGlobalSubposition)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.updateGlobalSubposition)}
          positionId={positionId}
          subpositionItem={subpositionItem}
        />
      )}
    </div>
  );
};
export default GlobalConfigurationSubpositionTooltip;
