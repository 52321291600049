import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiRequestBodyProjectRoles } from "epcm-common/dist/Types/RoleTypes";

const API = `${EPCM_API_URLS.projects}`;

const updateProjectPermissionMutation = async (projectId: number, userProjectRoles: ApiRequestBodyProjectRoles) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/users/roles`,
    method: "POST",
    data: userProjectRoles,
  } as AxiosRequestConfig;

  return await epcmAxiosInstance(config);
};

export default updateProjectPermissionMutation;
