import { FilesFilterEnum, FilesFilterType, FileTypeOptions, FileUploadStatus } from "../types/projects/FileTypes.ts";

export const extractFileFilters = (params: URLSearchParams, fileFilters: FilesFilterType[]): void => {
  const fileTypeFilter = fileFilters
    .find((filter) => filter.id === FilesFilterEnum.fileType)
    ?.subFilters.filter((subFilter) => subFilter.isActive)
    .map((subFilter) => subFilter.title) as FileTypeOptions[] | undefined;

  const fileTypeFilterUnifiedString = fileTypeFilter ? fileTypeFilter.join(",") : undefined;

  const fileUploadStatusFilter = fileFilters
    .find((filter) => filter.id === FilesFilterEnum.fileStatus)
    ?.subFilters.filter((subFilter) => subFilter.isActive)
    .map((subFilter) => subFilter.title) as FileUploadStatus[] | undefined;

  const fileUploadStatusFilterUnifiedString = fileUploadStatusFilter ? fileUploadStatusFilter.join(",") : undefined;

  const fileTransactionTypeFilter = fileFilters
    .find((filter) => filter.id === FilesFilterEnum.fileTransactionType)
    ?.subFilters.filter((subFilter) => subFilter.isActive)
    .map((subFilter) => subFilter.title);

  const fileTransactionTypeFilterUnifiedString = fileTransactionTypeFilter ? fileTransactionTypeFilter.join(",") : undefined;

  if (fileTypeFilterUnifiedString) {
    params.set("type", fileTypeFilterUnifiedString);
  }
  if (fileUploadStatusFilterUnifiedString) {
    params.set("status", fileUploadStatusFilterUnifiedString);
  }

  if (fileTransactionTypeFilterUnifiedString) {
    params.set("transactionType", fileTransactionTypeFilterUnifiedString);
  }
  return;
};
