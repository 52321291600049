import { FrontendProjectPositionType } from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { FC, useEffect, useMemo, useState } from "react";
import ConfigurationPositionsRowStyles from "./ConfigurationPositionRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";

import TrashCanDarkIcon from "../../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIcon from "../../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../../assets/images/trashcan-icon-red.svg";
import EditIconHover from "../../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import ArrowDropDownIcon from "../../../../../../assets/images/arrow-down-black.svg";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { useProjectContext } from "../../../../ProjectsUtils.ts";
import { useEpcmApiProjectsUtils } from "../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { ApiResponseTypeEnum, QueryNames } from "../../../../../../types/apicallstypes/queryCommons.ts";
import { EpcmTooltip } from "../../../../../../ui/epcmtooltip/EpcmTooltip.tsx";
import settingsIcon from "../../../../../../assets/images/setting-dots-black.svg";
import { ClickAwayListener } from "@mui/material";

import { ProjectsPagePopups, useProjectsPopups } from "../../../../use-projects-popups.ts";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import ConfigurationPositionsPopup from "../../popups/configurationpositionspopup/ConfigurationPositionsPopup.tsx";
import { DeletePopup } from "../../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import BluePlusIcon from "../../../../../../assets/images/blue-plus-icon.svg";
import ConfigurationSubpositionTooltip from "./configurationsubpositiontooltip/ConfigurationSubpositionTooltip.tsx";
import ConfigurationSubpositionsPopup from "../../popups/configurationsubpositionspopup/ConfigurationSubpositionsPopup.tsx";
import { useRetrieveUserPermittedActions } from "../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../types/Roles.ts";

interface ConfigurationPositionRowProps {
  positionItem: FrontendProjectPositionType;
}

const ConfigurationPositionRow: FC<ConfigurationPositionRowProps> = ({ positionItem }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const [isTooltipOpenMap, setIsTooltipOpenMap] = useState<Record<number, boolean>>({});

  const toggleExpand = () => setIsExpanded(!isExpanded);
  const { currentProject } = useProjectContext();
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { getProjectSubpositions } = useEpcmApiProjectsUtils();
  const { useDeleteProjectConfigurationEntityMutation } = useEpcmApiProjectUtilsMutations();

  const getProjectSubpositionQuery = useQuery({
    queryKey: [QueryNames.ProjectSubpositions, currentProject.id, positionItem.id],
    queryFn: () => getProjectSubpositions(currentProject.id, positionItem.id),
    enabled: isAuthorized,
  });

  const subPositions = useMemo(() => {
    return getProjectSubpositionQuery.data;
  }, [getProjectSubpositionQuery.data]);

  const handleTooltipClose = (subPositionId: number) => {
    setIsTooltipOpenMap((prev) => ({ ...prev, [subPositionId]: false }));
  };

  const handleSpecificSubpositionTooltipClick = (event: React.MouseEvent<HTMLDivElement>, subPositionId: number) => {
    event.preventDefault();
    handleSubpositionTooltipOpen(subPositionId);
  };

  const handleSubpositionTooltipOpen = (subPositionId: number) => {
    setIsTooltipOpenMap((prev) => ({ ...prev, [subPositionId]: true }));
  };

  const deleteProjectPositionMutation = useDeleteProjectConfigurationEntityMutation(
    currentProject.id,
    positionItem.id,
    FrontendProjectConfigurationsEntityEnum.Position,
    {
      onSuccessCallback: (data) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: "Project Position was deleted successfully!" });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );

  const canListConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canUpdateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigUpdate);
  const canDeleteConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigDelete);
  const canCreateConfiguration = canPerformProjectAction(ProjectAction.ProjectConfigCreate);

  const invalidateQueries = () => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectPositions, currentProject.id] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectSubpositions, currentProject.id, positionItem.id] });
  };

  useEffect(() => {
    if (subPositions) {
      // Initialize tooltip state for new subpositions
      setIsTooltipOpenMap((prev) => {
        const newTooltipState = { ...prev };
        subPositions.forEach((subPosition) => {
          if (!(subPosition.id in newTooltipState)) {
            newTooltipState[subPosition.id] = false;
          }
        });
        return newTooltipState;
      });
    }
  }, [subPositions]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectSubpositions, currentProject.id, positionItem.id] }).then(() => {
        console.log(`In configuration-position-row component, ${QueryNames.ProjectSubpositions} query for position ${positionItem.id} cancelled`);
      });
    };
  }, [queryClient, currentProject.id, positionItem.id]);

  return (
    <>
      <tr
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
        key={positionItem.id}
        className={classNames(
          !isExpanded && ConfigurationPositionsRowStyles.itemContainer,
          isRowHovered && ConfigurationPositionsRowStyles.rowHoverEffect,
          ConfigurationPositionsRowStyles.Trow,
        )}
      >
        {positionItem && canListConfiguration ? (
          <>
            <td
              className={classNames(
                ConfigurationPositionsRowStyles.tableCell,
                ConfigurationPositionsRowStyles.boldText,
                ConfigurationPositionsRowStyles.tD,
              )}
            >
              {positionItem.code}
            </td>
            <td className={(ConfigurationPositionsRowStyles.tableCell, ConfigurationPositionsRowStyles.tD)}>{positionItem.description}</td>
            <td className={classNames(ConfigurationPositionsRowStyles.tableCell, ConfigurationPositionsRowStyles.tD)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, ConfigurationPositionsRowStyles.subpositionsCell)}>
                <div className={classNames(ConfigurationPositionsRowStyles.subpositionText)}>Sub-Positions</div>
                <div
                  className={classNames(ConfigurationPositionsRowStyles.subpositionCount, GlobalStyles.centerVertical, GlobalStyles.centerHorizontal)}
                >
                  {subPositions?.length}
                </div>
                <div onClick={() => toggleExpand()} className={classNames(GlobalStyles.elementWithCursor)}>
                  <img
                    src={ArrowDropDownIcon}
                    alt={"Arrow dropdown icon"}
                    className={classNames(isExpanded && ConfigurationPositionsRowStyles.rotateImg, ConfigurationPositionsRowStyles.duration1)}
                  ></img>
                </div>
              </div>
            </td>
            <td className={classNames(ConfigurationPositionsRowStyles.tableCellActions, ConfigurationPositionsRowStyles.tD)}>
              <div
                className={classNames(
                  ConfigurationPositionsRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                {canDeleteConfiguration && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      onOpenUtilPopup();
                    }}
                    onMouseEnter={() => setDeleteIconHovered(true)}
                    onMouseLeave={() => setDeleteIconHovered(false)}
                  >
                    <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                  </div>
                )}
                {canUpdateConfiguration && (
                  <div
                    className={classNames(ConfigurationPositionsRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                    onClick={() => onOpenPopup(ProjectsPagePopups.updateProjectPosition, popupHandler)}
                    onMouseEnter={() => setEditIconHovered(true)}
                    onMouseLeave={() => setEditIconHovered(false)}
                  >
                    <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                  </div>
                )}
              </div>
            </td>
          </>
        ) : (
          <></>
        )}
      </tr>
      {isExpanded && (
        <tr
          className={classNames(
            ConfigurationPositionsRowStyles.itemContainer,
            isRowHovered && ConfigurationPositionsRowStyles.rowHoverEffect,
            ConfigurationPositionsRowStyles.Trow,
          )}
          onMouseEnter={() => setIsRowHovered(true)}
          onMouseLeave={() => setIsRowHovered(false)}
        >
          <td colSpan={4} className={ConfigurationPositionsRowStyles.expandedContent}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap, GlobalStyles)}>
              {/* Sub-Positions label positioned absolutely to the left */}
              {subPositions && (
                <div className={ConfigurationPositionsRowStyles.subPositionLabelContainer}>
                  <div className={ConfigurationPositionsRowStyles.subPositionLabel}>SUB-POSITIONS:</div>
                </div>
              )}
              <div
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.gap,
                  ConfigurationPositionsRowStyles.subpositionsContainer,
                )}
              >
                {subPositions?.map((subPosition, index) => (
                  <div
                    key={subPosition.id}
                    className={classNames(
                      GlobalStyles.flex,
                      GlobalStyles.centerHorizontal,
                      GlobalStyles.gap075,
                      ConfigurationPositionsRowStyles.subpositionItemCell,
                      { [ConfigurationPositionsRowStyles.subpositionItemCellFirst]: index === 0 }, // Additional styling for the first subposition
                    )}
                  >
                    <div className={ConfigurationPositionsRowStyles.subPositionCode}>{subPosition.code}</div>
                    <div className={ConfigurationPositionsRowStyles.subPositionDescription}>{subPosition.description}</div>
                    {(canUpdateConfiguration || canDeleteConfiguration) && (
                      <div
                        className={classNames(
                          ConfigurationPositionsRowStyles.settingsSubpositionContainer,
                          GlobalStyles.flex,
                          GlobalStyles.elementWithCursor,
                          GlobalStyles.centerVertical,
                        )}
                        onClick={(event) => {
                          isTooltipOpenMap[subPosition.id] && event.stopPropagation();
                        }}
                      >
                        <ClickAwayListener onClickAway={() => handleTooltipClose(subPosition.id)}>
                          <div
                            className={classNames(ConfigurationPositionsRowStyles.settingsIcon, GlobalStyles.flex, GlobalStyles.centerVertical)}
                            onClick={(event) =>
                              (canUpdateConfiguration || canDeleteConfiguration) && handleSpecificSubpositionTooltipClick(event, subPosition.id)
                            }
                          >
                            <EpcmTooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={() => handleTooltipClose(subPosition.id)}
                              open={isTooltipOpenMap[subPosition.id] ?? false}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                canListConfiguration ? (
                                  <ConfigurationSubpositionTooltip
                                    positionId={positionItem.id}
                                    subpositionItem={subPosition}
                                    canBeDeleted={subPositions?.length > 1}
                                  />
                                ) : (
                                  ""
                                )
                              }
                              arrow
                              placement={"top-end"}
                            >
                              <img
                                className={classNames(ConfigurationPositionsRowStyles.settingsSubpositionIcon)}
                                src={settingsIcon}
                                alt="settings"
                              />
                            </EpcmTooltip>
                          </div>
                        </ClickAwayListener>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {canCreateConfiguration && (
                <div className={classNames(ConfigurationPositionsRowStyles.addSubpositionContainerPadding)}>
                  <div
                    className={classNames(
                      GlobalStyles.flex,
                      GlobalStyles.centerHorizontal,
                      GlobalStyles.gap05,
                      GlobalStyles.elementWithCursor,
                      ConfigurationPositionsRowStyles.addSubpositionContainer,
                    )}
                    onClick={() => onOpenPopup(ProjectsPagePopups.addProjectSubPosition, popupHandler)}
                  >
                    <div
                      className={classNames(
                        GlobalStyles.flex,
                        GlobalStyles.centerHorizontal,
                        GlobalStyles.centerVertical,
                        ConfigurationPositionsRowStyles.plusIconContainer,
                      )}
                    >
                      <img src={BluePlusIcon} alt={"Add Subposition"} />
                    </div>
                    <div className={classNames(ConfigurationPositionsRowStyles.addGroupText)}>Add subposition</div>
                  </div>
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteProjectPositionMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      {popupHandler.get(ProjectsPagePopups.updateProjectPosition)!.isOpen && (
        <ConfigurationPositionsPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(ProjectsPagePopups.updateProjectPosition, popupHandler)}
          projectId={currentProject.id}
          isOpen={popupHandler.get(ProjectsPagePopups.updateProjectPosition)!.isOpen}
          secondaryHeaderText={currentProject.name}
          headerText={popupHeaders.get(ProjectsPagePopups.updateProjectPosition)}
          positionItem={positionItem}
        />
      )}
      {popupHandler.get(ProjectsPagePopups.addProjectSubPosition)!.isOpen && (
        <ConfigurationSubpositionsPopup
          mode={configurationsPopupType.create}
          closeFn={() => onClosePopup(ProjectsPagePopups.addProjectSubPosition, popupHandler)}
          projectId={currentProject.id}
          isOpen={popupHandler.get(ProjectsPagePopups.addProjectSubPosition)!.isOpen}
          secondaryHeaderText={currentProject.name}
          headerText={popupHeaders.get(ProjectsPagePopups.addProjectSubPosition)}
          positionId={positionItem.id}
        />
      )}
    </>
  );
};

export default ConfigurationPositionRow;
