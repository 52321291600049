import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config";
import { ApiResponseGetTimesheetRecords } from "epcm-common/dist/Types/TimesheetRecordTypes";
import { ApiResponseTimesheetRecordsCalendarDayInfo } from "epcm-common/src/Types/TimesheetRecordTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getGroupTimesheetDateRecords = async (
  id: number,
  timesheetGroupId: number,
  timesheetId: number,
  timesheetEmployeeId: number,
  calendarId: number,
): Promise<ApiResponseTimesheetRecordsCalendarDayInfo> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/timesheets/${timesheetId}/employee/${timesheetEmployeeId}/calendar/${calendarId}`,
    method: "GET",
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data satisfies ApiResponseGetTimesheetRecords;
};
