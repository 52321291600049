import { FC } from "react";
import { useParams } from "react-router-dom";
import MonthListItem from "../calendarmanagement/monthlist/monthlistitem/MonthListItem.tsx";

export const CalendarManagementSingle: FC = () => {
  const { monthId } = useParams();

  return <MonthListItem monthId={Number(monthId)} />;
};

export default CalendarManagementSingle;
