import { UserProject, UserProjectRoleLimited, UserRoleLimited } from "epcm-common/dist/Types/UserTypes";
import { FrontendProjectUserTypeWithRoleIds, FrontendUserProjectRolesType } from "../../../../projects/PermissionTypes.ts";
import { FrontendUserRolesType } from "../../../UsersApiTypes.ts";

export const convertProjectSingleUserData = (userData: UserProject): FrontendProjectUserTypeWithRoleIds => {
  return {
    id: parseInt(userData.code),
    code: userData.code,
    name: `${userData.name} ${userData.surname}`,
    position: userData.position,
    positionCode: userData.positionCode,
    projectRoleIds: userData.projectRoleIds,
  };
};
export const convertProjectUserData = (userData: UserProject[]): FrontendProjectUserTypeWithRoleIds[] => {
  return userData.map((user) => {
    return convertProjectSingleUserData(user);
  });
};
export const convertUserSingleRole = (userRoles: UserRoleLimited): FrontendUserRolesType => {
  return {
    role: {
      id: userRoles.role.id,
      name: userRoles.role.name,
      description: userRoles.role.description,
      dbName: userRoles.role.dbName,
    },
  };
};
export const convertUserRolesList = (userRoles: UserRoleLimited[]): FrontendUserRolesType[] => {
  // return role items from userRoles
  return userRoles.map((role) => {
    return convertUserSingleRole(role);
  });
};

export const convertUserProjectRolesList = (userProjectRoles: UserProjectRoleLimited[]): FrontendUserProjectRolesType[] => {
  return userProjectRoles.map((role) => {
    return {
      projectId: role.projectId,
      role: convertUserSingleRole(role).role,
    };
  });
};
