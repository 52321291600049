import { FrontendProjectEmployeeBasicType } from "../../../../projects/ProjectEmployeesTypes.ts";
import { ProjectEmployeeBasic } from "epcm-common/dist/Types/ProjectEmployeeTypes";

export const convertSingleProjectEmployeeData = (projectEmployeeData: ProjectEmployeeBasic): FrontendProjectEmployeeBasicType => {
  return {
    id: projectEmployeeData.id,
    name: projectEmployeeData.name,
    employeeId: projectEmployeeData.employeeId,
    code: projectEmployeeData.code,
  };
};
