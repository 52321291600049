import { EPCM_API_URLS, epcmAxiosInstance } from "../../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiRequestBodyTimesheetGroupAddSectionHead } from "epcm-common/dist/Types/TimesheetGroupTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const createTimesheetGroupSectionHead = async (
  projectId: number,
  timesheetGroupId: number,
  sectionHeadCode: ApiRequestBodyTimesheetGroupAddSectionHead,
) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/timesheet-groups/${timesheetGroupId}/section-head`,
    method: "POST",
    data: sectionHeadCode,
  } as AxiosRequestConfig;

  return epcmAxiosInstance(config);
};
