import { Dispatch, FC, SetStateAction, useMemo, useRef } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import SelectSubpositionMenuStyles from "./SelectSubpositionMenu.module.css";
import { Fade, Menu, MenuItem, Tooltip } from "@mui/material";
import ArrowIcon from "../../../../../../../../assets/images/arrow-down-dark-blue-fill.svg";
import { FrontendSubPositionType } from "../../../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { Link, useParams } from "react-router-dom";
import { AppRoutesPaths } from "../../../../../../../../types/AppRoutesTypes.ts";

interface SelectSubpositionMenuProps<T extends number | undefined> {
  label: string;
  optionsMap: FrontendSubPositionType[];
  currentValue: T;
  onChangeHandler: Dispatch<SetStateAction<T>>;
  inputId: string;
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  isMpCodeEmpty: boolean;
  isOptionsLoading: boolean;
  disabled?: boolean;
}

const SelectSubpositionMenu: FC<SelectSubpositionMenuProps<number | undefined>> = ({
  label,
  optionsMap,
  currentValue,
  onChangeHandler,
  inputId,
  anchorEl,
  setAnchorEl,
  isMpCodeEmpty,
  disabled,
  isOptionsLoading,
}) => {
  const { projectId } = useParams();
  const isValueEmpty = useMemo(() => {
    return currentValue === null || currentValue === 0 || currentValue === undefined;
  }, [currentValue]);

  const inputRef = useRef<HTMLInputElement>(null);

  const onDropdownMenuOpen = () => {
    if (inputRef.current) {
      setAnchorEl(inputRef.current);
    }
  };

  const onDropdownMenuClose = () => {
    setAnchorEl(null);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isOptionsMapEmpty = useMemo(() => {
    return !optionsMap || optionsMap.length === 0;
  }, [optionsMap]);

  const handleMenuItemClick = (id: number) => {
    onChangeHandler(id as unknown as typeof currentValue);
    onDropdownMenuClose();
  };

  const findOptionDescription = (id: typeof currentValue) => optionsMap.find((option) => option.id === id)?.description || "";

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
        <label htmlFor={inputId}>{label}</label>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        {currentValue && !disabled && (
          <div
            className={classNames(SelectSubpositionMenuStyles.clearButton, GlobalStyles.elementWithCursor)}
            onClick={() => onChangeHandler(undefined as unknown as typeof currentValue)}
          >
            Clear
          </div>
        )}
      </div>
      <Tooltip title={isValueEmpty ? "" : isOptionsMapEmpty ? "No options available" : ""} placement="top" arrow TransitionComponent={Fade}>
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.centerHorizontal,
            disabled ? SelectSubpositionMenuStyles.displayDiv : GlobalStyles.inputContainer,
            isValueEmpty && isMpCodeEmpty && SelectSubpositionMenuStyles.inputContainerEmpty,
            isOptionsMapEmpty && isMpCodeEmpty && SelectSubpositionMenuStyles.inputContainerWithoutOptions,
            !disabled && GlobalStyles.elementWithCursor,
          )}
        >
          {!isMpCodeEmpty && !isOptionsMapEmpty ? (
            <input
              readOnly
              ref={inputRef}
              onClick={!isOptionsMapEmpty ? onDropdownMenuOpen : undefined}
              value={isValueEmpty ? "" : findOptionDescription(currentValue)}
              disabled={isMpCodeEmpty ? false : disabled}
              placeholder={isOptionsMapEmpty ? "Select Manpower Code first" : isValueEmpty ? `Select ${label}` : ""}
              className={classNames(
                isOptionsMapEmpty && SelectSubpositionMenuStyles.inputContainerWithoutOptions,
                isValueEmpty ? SelectSubpositionMenuStyles.inputContainerEmpty : disabled && SelectSubpositionMenuStyles.inputContainerNonEmpty,
                GlobalStyles.flex,
                GlobalStyles.flex1,
                !disabled && GlobalStyles.elementWithCursor,
              )}
            ></input>
          ) : !isOptionsLoading && !currentValue ? (
            <Link
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
                GlobalStyles.flex1,
                SelectSubpositionMenuStyles.linkToSubpositionPage,
              )}
              to={`/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.configurations}/${AppRoutesPaths.configurationsPositions}`}
            >
              Create Sub-position First
            </Link>
          ) : (
            <div>-</div>
          )}
          {!isOptionsMapEmpty && !disabled && (
            <div
              className={classNames(SelectSubpositionMenuStyles.arrowContainer, GlobalStyles.elementWithCursor)}
              onClick={!isOptionsMapEmpty ? onDropdownMenuOpen : undefined}
            >
              <img src={ArrowIcon} alt={"arrow-down"} className={SelectSubpositionMenuStyles.arrowIcon} />
            </div>
          )}
        </div>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={onDropdownMenuClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: "12em",
              minWidth: "16em",
              maxWidth: "35em",
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        {Array.isArray(optionsMap) &&
          optionsMap.map((option) => (
            <MenuItem
              key={option.id}
              className={classNames(GlobalStyles.elementWithCursor)}
              selected={option.id === currentValue}
              onClick={() => handleMenuItemClick(option.id)}
            >
              {option.description}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

export default SelectSubpositionMenu;
