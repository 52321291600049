import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { ApiRequestBodyEmployeeUpdate } from "epcm-common/dist/Types/EmployeeTypes.ts";

const API = `${EPCM_API_URLS.employees}`;

export const updateEmployee = async (employeeId: number, employeeData: ApiRequestBodyEmployeeUpdate) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${employeeId}`,
    method: "PUT",
    data: employeeData,
  } as AxiosRequestConfig;
  return epcmAxiosInstance(config);
};
