import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { EPCMInfoContainerDiv } from "../../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import TimesheetListButtonsStyles from "./TimesheetListButtonsStyles.module.css";
import { TimesheetAnalytics } from "./timesheetanalytics/TimesheetAnalytics.tsx";
import TimesheetAnalyticsListButtons from "./timesheetanalytics/timesheetanalyticslistbuttons/TimesheetAnalyticsListButtons.tsx";
import { FC } from "react";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";

interface TimesheetListButtonProps {
  selectedPeriodStart: Date | null;
  selectedPeriodEnd: Date | null;
  projectId: number;
}

const TimesheetListButtons: FC<TimesheetListButtonProps> = ({ selectedPeriodStart, selectedPeriodEnd, projectId }) => {
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const canGenerateFusedReport = canPerformProjectAction(ProjectAction.ProjectTimesheetGenerateReport);
  return (
    <div
      className={classNames(
        TimesheetListButtonsStyles.timesheetListButtonsContainer,
        GlobalStyles.flex,
        GlobalStyles.gap,
        GlobalStyles.centerHorizontal,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.overflowAutoFullHeight)}>
        <TimesheetAnalytics mainText={"Overview"} onFiltersClick={() => {}}>
          <TimesheetAnalyticsListButtons projectId={projectId} selectedPeriodEnd={selectedPeriodEnd} selectedPeriodStart={selectedPeriodStart} />
        </TimesheetAnalytics>
      </div>

      {canGenerateFusedReport && (
        <EPCMInfoContainerDiv
          className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05, GlobalStyles.overflowHiddenFullHeight)}
        >
          <div className={classNames(TimesheetListButtonsStyles.generateTimesheetText)}>Generate Fused Report</div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          <div
            className={classNames(
              TimesheetListButtonsStyles.generateTimesheetButton,
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.centerVertical,
              GlobalStyles.elementWithCursor,
            )}
          >
            Generate
          </div>
        </EPCMInfoContainerDiv>
      )}
    </div>
  );
};
export default TimesheetListButtons;
