import GlobalStyles from "../../../assets/css/GlobalStyles.module.css";
import ProjectListStyles from "./ProjectList.module.css";
import classNames from "classnames";
import projectHeaderIcon from "../../../assets/images/house-window.svg";
import statusHeaderIcon from "../../../assets/images/status-project-list.svg";
import calendarHeaderIcon from "../../../assets/images/calendar-project-list.svg";
import hourglassHeaderIcon from "../../../assets/images/hourglass-project-list.svg";
import userGearHeaderIcon from "../../../assets/images/user-gear-project-list.svg";
import sortListIcon from "../../../assets/images/sort-icon-project-list.svg";
import { ProjectListHeaderType } from "../../../types/projects/ProjectsTypes.ts";
import { ProjectListItem } from "./projectlistitem/ProjectListItem.tsx";
import { FC, PropsWithChildren } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { FrontendProjectLimitedType } from "../../../types/apicallstypes/ProjectsApiTypes.ts";

interface ProjectListProps {
  projectList: FrontendProjectLimitedType[];
}

const PROJECT_LIST_HEADERS: ProjectListHeaderType[] = [
  {
    title: "PROJECT",
    icon: projectHeaderIcon,
    isSortable: false,
  },
  {
    title: "STATUS",
    icon: statusHeaderIcon,
    isSortable: false,
  },
  {
    title: "CONTRACT DATE",
    icon: calendarHeaderIcon,
    isSortable: false,
  },
  {
    title: "DURATION",
    icon: hourglassHeaderIcon,
    isSortable: false,
  },
  {
    title: "CREATED BY",
    icon: userGearHeaderIcon,
    isSortable: false,
  },
];

export const ProjectList: FC<PropsWithChildren<ProjectListProps>> = ({ projectList, children }) => {
  const [parent] = useAutoAnimate();

  return (
    <div
      className={classNames(ProjectListStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.overflowHiddenFullHeight)}
    >
      <div className={classNames(ProjectListStyles.headersBar, GlobalStyles.flex, GlobalStyles.gap)}>
        {PROJECT_LIST_HEADERS.map((header) => (
          <div key={header.title} className={classNames(ProjectListStyles.headerContainer, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(ProjectListStyles.headerIconImg)} src={header.icon} alt="headerIcon" />
            </div>
            <div className={classNames(ProjectListStyles.headerTitle, GlobalStyles.centerVertical)}>{header.title}</div>
            {header.isSortable && (
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(ProjectListStyles.headerSortIconImg)} src={sortListIcon} alt="sort" />
              </div>
            )}
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
        ))}
        <div className={classNames(ProjectListStyles.headerInvisiblePlaceholder)}>{"Clear"}</div>
      </div>
      {projectList.length > 0 ? (
        <div
          ref={parent}
          className={classNames(ProjectListStyles.projectListContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}
        >
          {projectList.map((projectItem) => (
            <ProjectListItem key={projectItem.id} projectItem={projectItem} />
          ))}
          {children}
        </div>
      ) : (
        <div className={classNames(GlobalStyles.emptyListMsg, GlobalStyles.flex)}>
          <div className={classNames(GlobalStyles.flex1)} />
          <div>{"No Projects"}</div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
      )}
    </div>
  );
};
