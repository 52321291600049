import { useState } from "react";

import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";

import GlobalConfigurationPositionStyles from "./GlobalConfigurationPositions.module.css";
import NewPositionWrenchIcon from "../../../../assets/images/configuration/new-position-wrench-icon.svg";
import SearchBarSubHeader from "../../../projects/project/configuration/searchbarsubheader/SearchBarSubHeader.tsx";
import ConfigurationEntityTableManager from "../../../projects/project/configuration/configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import { ConfigurationTargetType, GlobalConfigurationScreenType } from "../../../../types/settings/GlobalConfigurationTypes.ts";
import { configurationsPopupType } from "../../../../types/projects/ConfigurationTypes.ts";
import GlobalConfigurationPositionsPopup from "../popups/globalconfigurationpositionspopup/GlobalConfigurationPositionsPopup.tsx";
import { SettingsPagePopups, useSettingsPopups } from "../../use-settings-pupups.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../types/Roles.ts";

const GlobalConfigurationPositions = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const canCreateConfiguration = canPerformGlobalAction(GlobalAction.ConfigCreate);

  return (
    <div
      className={classNames(
        GlobalConfigurationPositionStyles.paddingContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap3,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.overflowHiddenFullHeight,
          GlobalConfigurationPositionStyles.contentContainer,
        )}
      >
        <SearchBarSubHeader onSearchQueryChange={setSearchQuery} subheaderTitle={"Search by user code"} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
          <div
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
              GlobalConfigurationPositionStyles.mainContent,
            )}
          >
            <ConfigurationEntityTableManager
              searchQuery={searchQuery}
              globalConfigurationScreenType={GlobalConfigurationScreenType.positions}
              target={ConfigurationTargetType.global}
            />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalConfigurationPositionStyles.actionBtnContainer)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
            {canCreateConfiguration && (
              <div
                onClick={() => onOpenPopup(SettingsPagePopups.addGlobalPosition, popupHandler)}
                className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
              >
                <div>New Position</div>
                <div className={classNames(GlobalConfigurationPositionStyles.actionBtnImage)}>
                  <img src={NewPositionWrenchIcon} alt="New Position " />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {popupHandler.get(SettingsPagePopups.addGlobalPosition)!.isOpen && (
        <GlobalConfigurationPositionsPopup
          mode={configurationsPopupType.create}
          closeFn={() => onClosePopup(SettingsPagePopups.addGlobalPosition, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.addGlobalPosition)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.addGlobalPosition)}
        />
      )}
    </div>
  );
};
export default GlobalConfigurationPositions;
