import { QueryModelWithPagination } from "../../types/apicallstypes/queryCommons.ts";
import { FrontendEmployeeDetailedType, FrontendEmployeeLimitedType } from "../../types/apicallstypes/EmployeesApiTypes.ts";
import { getAllEmployees } from "./getAllEmployees.ts";
import {
  convertEmployeesData,
  convertSingleEmployeeDetailedData,
  convertSingleEmployeeLimitedData,
} from "../../types/apicallstypes/apicallstypesconverters/EmployeesApiConverter.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { getEmployee } from "./getEmployee.ts";
import { EmployeeDetailed } from "epcm-common/dist/Types/EmployeeTypes.ts";

interface ApiDefinition {
  getAllEmployees: (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
    notInProjectId?: number,
  ) => Promise<QueryModelWithPagination<FrontendEmployeeLimitedType>>;
  getEmployee: (id: number, detailLevel?: DetailLevel) => Promise<FrontendEmployeeLimitedType | FrontendEmployeeDetailedType>;
}

export const useEpcmApiEmployees = (): ApiDefinition => {
  const getAllEmployeesConverted = async (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
    notInProjectId?: number,
  ): Promise<QueryModelWithPagination<FrontendEmployeeLimitedType>> => {
    const employeesData = await getAllEmployees(pageOffset, searchFilter, pageSize, notInProjectId);
    return {
      data: convertEmployeesData(employeesData.data),
      nextPage: employeesData.nextPage,
      totalCount: employeesData.totalCount,
    };
  };

  const getEmployeeConverted = async (id: number, detailLevel?: DetailLevel): Promise<FrontendEmployeeLimitedType | FrontendEmployeeDetailedType> => {
    const employeeData = await getEmployee(id, detailLevel);
    return detailLevel === DetailLevel.DETAILED
      ? convertSingleEmployeeDetailedData(employeeData as EmployeeDetailed)
      : convertSingleEmployeeLimitedData(employeeData);
  };

  return {
    getAllEmployees: getAllEmployeesConverted,
    getEmployee: getEmployeeConverted,
  };
};
