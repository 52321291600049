import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { FrontendProjectConfigurationsEntityEnum } from "./useEpcmApiProjectUtilsMutations.ts";

const API = `${EPCM_API_URLS.projects}`;

export const deleteProjectConfigurationEntity = async (
  projectId: number,
  projectConfigurationEntityId: number,
  configurationType: FrontendProjectConfigurationsEntityEnum,
  subPositionId?: number,
) => {
  if (subPositionId) {
    const config: AxiosRequestConfig = {
      url: `${API}/${projectId}/position/${projectConfigurationEntityId}/${configurationType}/${subPositionId}`,
      method: "DELETE",
    } as AxiosRequestConfig;
    return epcmAxiosInstance(config);
  } else {
    const config: AxiosRequestConfig = {
      url: `${API}/${projectId}/${configurationType}/${projectConfigurationEntityId}`,
      method: "DELETE",
    } as AxiosRequestConfig;
    return epcmAxiosInstance(config);
  }
};
