import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import ButtonBarRightStyles from "./ButtonBarRight.module.css";
import closeIcon from "../../../../../../assets/images/x-mark-white-lines.svg";
//import deleteIcon from "../../../../../../assets/images/trashcan-icon-red.svg";
import deleteIconHovered from "../../../../../../assets/images/trashcan-icon-gray.svg";
// import deleteIconHovered from "../../../../../../assets/images/trashcan-icon-white.svg";
import { FC } from "react";
import { Fade, Tooltip } from "@mui/material";

interface ButtonBarRightProps {
  onCloseFn: () => void;
  canDeleteEmployee: boolean;
}

export const ButtonBarRight: FC<ButtonBarRightProps> = ({ onCloseFn, canDeleteEmployee }) => {
  // const [isDeleteIconHovered, setIsDeleteIconHovered] = useState<boolean>(false);

  return (
    <div className={classNames(ButtonBarRightStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      <div className={classNames(ButtonBarRightStyles.closeIcon, GlobalStyles.centerVertical)} onClick={onCloseFn}>
        <img className={classNames(ButtonBarRightStyles.closeIconImg)} src={closeIcon} alt="close" />
      </div>
      <div className={classNames(GlobalStyles.flex1)} />
      {canDeleteEmployee && (
        <Tooltip title={"Delete employee not yet implemented"} arrow placement={"top-end"} TransitionComponent={Fade}>
          <div
            className={classNames(ButtonBarRightStyles.deleteIcon, GlobalStyles.centerVertical)}
            //onMouseEnter={() => setIsDeleteIconHovered(true)}
            //onMouseLeave={() => setIsDeleteIconHovered(false)}
          >
            <img className={classNames(ButtonBarRightStyles.deleteIconImg)} src={deleteIconHovered} alt="delete" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
