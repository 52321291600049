import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import PeriodTimesheetListStyles from "./PeriodTimesheetListStyles.module.css";
import { useProjectContext } from "../../../../ProjectsUtils.ts";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useEpcmApiProjectsTimesheets } from "../../../../../../apicalls/projects/projecttimesheets/useEpcmApiProjectsTimesheets.ts";
import { QueryNames } from "../../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../../apicalls/config.ts";
import { FC, useEffect, useMemo } from "react";
import { Skeleton } from "@mui/material";
import InfiniteScrollInViewElement from "../../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useInView } from "react-intersection-observer";
import TimesheetListItem from "./periodtimesheetlistitem/TimesheetListItem.tsx";
import { useRetrieveUserPermittedActions } from "../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../types/Roles.ts";
import { useHandleUnauthorized } from "../../../../../../utils/use-handle-unauthorized.ts";

interface PeriodTimesheetListProps {
  periodStart: Date;
  periodEnd: Date;
  keepCurrentPeriodOnly: boolean;
}

const PeriodTimesheetList: FC<PeriodTimesheetListProps> = ({ periodStart, periodEnd, keepCurrentPeriodOnly }) => {
  const { currentProject, searchQuery } = useProjectContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllTimesheets } = useEpcmApiProjectsTimesheets();
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { handleErrorRedirect } = useHandleUnauthorized();

  const canListTimesheets = canPerformProjectAction(ProjectAction.ProjectTimesheetListList);

  const periodTimesheetsQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectTimesheets, currentProject.id, periodStart, periodEnd, searchQuery, keepCurrentPeriodOnly],
    queryFn: ({ pageParam }) =>
      getAllTimesheets(currentProject.id, periodStart, periodEnd, PAGINATION_PAGE_SIZE, pageParam, searchQuery).catch(handleErrorRedirect),
    initialPageParam: 1,
    enabled: isAuthorized,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
  });

  const periodTimesheetsData = useMemo(() => {
    return periodTimesheetsQuery.data?.pages.flatMap((page) => page.data);
  }, [periodTimesheetsQuery.data]);

  const projectTimesheetItemSkeletonLoader = (
    <div className={classNames(PeriodTimesheetListStyles.timesheetListItemSkeletonContainer, GlobalStyles.flex, GlobalStyles.gap)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap2)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={38} width={30} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.gap05)}>
          <Skeleton variant="rounded" height={13} width={150} />
          <Skeleton variant="rounded" height={13} width={100} />
        </div>
      </div>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex1, GlobalStyles.gap075)}>
        <Skeleton variant="rounded" height={13} width={150} />
        <Skeleton variant="rounded" height={13} width={100} />
      </div>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex1, GlobalStyles.gap075)}>
        <Skeleton variant="rounded" height={13} width={150} />
        <Skeleton variant="rounded" height={13} width={100} />
      </div>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex1, GlobalStyles.gap075)}>
        <Skeleton variant="rounded" height={13} width={150} />
        <Skeleton variant="rounded" height={13} width={100} />
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap3)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={0} width={17} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={25} width={25} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={25} width={25} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={0} width={17} />
        </div>
      </div>
    </div>
  );

  const projectTimesheetListSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      {["timesheet1", "timesheet2", "timesheet3"].map((key) => (
        <div key={key}>{projectTimesheetItemSkeletonLoader}</div>
      ))}
    </div>
  );

  useEffect(() => {
    if (
      inView &&
      !periodTimesheetsQuery.isLoading &&
      !periodTimesheetsQuery.isFetching &&
      !periodTimesheetsQuery.isFetchingNextPage &&
      periodTimesheetsQuery.hasNextPage
    ) {
      void periodTimesheetsQuery.fetchNextPage();
    }
  }, [inView, periodTimesheetsQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .invalidateQueries({
          queryKey: [QueryNames.ProjectTimesheets, currentProject.id, periodStart, periodEnd, searchQuery, keepCurrentPeriodOnly],
        })
        .then(() => {
          console.log(`In period timesheet list component, ${QueryNames.ProjectTimesheets} query cancelled`);
        });
    };
  }, [queryClient, currentProject, periodStart, periodEnd, searchQuery, keepCurrentPeriodOnly]); //TODO INVESTIGATE HERE

  return (
    <div className={classNames(PeriodTimesheetListStyles.container, GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.flexDirectionColumn)}>
      {periodTimesheetsQuery.isLoading && projectTimesheetListSkeletonLoader}
      {periodTimesheetsData?.length === 0 && <div className={classNames(GlobalStyles.emptyListMsg)}>{"There are no timesheets for this period"}</div>}
      {canListTimesheets &&
        periodTimesheetsData &&
        periodTimesheetsData.map((periodTimesheetItem) => (
          <TimesheetListItem
            key={`${periodTimesheetItem.id},${keepCurrentPeriodOnly}`}
            timesheetItem={periodTimesheetItem}
            keepCurrentPeriodOnly={keepCurrentPeriodOnly}
          />
        ))}
      <InfiniteScrollInViewElement
        key={"InfiniteScrollInViewElement"}
        reference={ref}
        infiniteQueryResult={periodTimesheetsQuery}
        loaderComponent={projectTimesheetItemSkeletonLoader}
      />
    </div>
  );
};

export default PeriodTimesheetList;
