import GlobalStyles from "../assets/css/GlobalStyles.module.css";
import FileUploaderStyles from "./FileUploader.module.css";
import React, { useCallback, useRef, useState } from "react";
import { Fade, Tooltip } from "@mui/material";
import fileIcon from "../assets/images/xls-file-icon.svg";
import { UploadedFilesList } from "./uploadedfileslist/UploadedFilesList.tsx";
import classNames from "classnames";
import SettingsStyles from "../pages/settings/Settings.module.css";
import { ResponseAlertPopup } from "../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../ui/loaderpopup/LoaderPopup.tsx";
import { usePopupState } from "../utils/use-popup-state.ts";
import { useEpcmApiConfigurationsMutations } from "../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { useResponseAlertPopupStateType } from "../utils/use-response-alert-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, SuccessCallbackDataType } from "../types/apicallstypes/queryCommons.ts";

export const FileUploader = () => {
  const uploadCsvRef = useRef<HTMLInputElement | null>(null);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [fileTypeError, setFileTypeError] = useState<boolean>(false);

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const { useCreateSampleDataMutation } = useEpcmApiConfigurationsMutations();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const createSampleDataMutation = useCreateSampleDataMutation({
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Project was initialized with data successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onInitializeSampleData = useCallback(() => {
    onOpenLoaderPopup();
    void createSampleDataMutation.mutate();
  }, [createSampleDataMutation, onOpenLoaderPopup]);

  const handleUploadedFiles = (files: File[]) => {
    const uploadedFilesLocal = [...uploadedFiles];
    files.some((file) => {
      if (uploadedFiles.findIndex((f) => f.name === file.name) === -1) {
        uploadedFilesLocal.push(file);
      }
    });
    setUploadedFiles(uploadedFilesLocal);

    let hasWrongFileType = false;
    files.some((file) => {
      if (
        file &&
        file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
        file.type !== "application/vnd.ms-excel" &&
        file.type !== "application/vnd.oasis.opendocument.spreadsheet"
      ) {
        hasWrongFileType = true;
        return;
      }
    });

    setFileTypeError(hasWrongFileType);
  };

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.from(e.target.files as unknown as File[]).slice();
    handleUploadedFiles(chosenFiles);
  };

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap2)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
        <Tooltip TransitionComponent={Fade} title={"Select files to upload"} placement="top" arrow>
          <div
            className={classNames(FileUploaderStyles.addFilesButton, GlobalStyles.flex, GlobalStyles.gap)}
            onClick={() => uploadCsvRef.current?.click()}
          >
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img src={fileIcon} alt="" className={classNames(FileUploaderStyles.addFilesIconImg)} />
            </div>
            <input ref={uploadCsvRef} type="file" multiple onChange={handleFileEvent} hidden accept={".xlsx, .xls, .ods"} />
            <div className={classNames(GlobalStyles.centerVertical)}>{uploadedFiles.length > 0 ? "Upload More Files" : "Upload Files"}</div>
          </div>
        </Tooltip>
        {fileTypeError && (
          <div
            className={classNames(FileUploaderStyles.fileInputErrorMsg)}
          >{`The selected file must be one of the following types, ".xlsx, .xls, .ods"`}</div>
        )}
      </div>
      <div className={classNames(GlobalStyles.flex1, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.centerVertical)}>
        <div
          className={classNames(SettingsStyles.addSampleDataButton, GlobalStyles.elementWithCursor)}
          onClick={() => {
            onInitializeSampleData();
          }}
        >
          Init Sample Data
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}

      {uploadedFiles.length > 0 && <UploadedFilesList uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />}
      <div className={classNames(GlobalStyles.flex2)} />
    </div>
  );
};
