import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../../config.ts";
import { ApiRequestBodyTimesheetGroupUpdate } from "epcm-common/dist/Types/TimesheetGroupTypes";

const API = `${EPCM_API_URLS.projects}`;

export const updateTimesheetGroup = async (projectId: number, timesheetGroupId: number, newGroupData: ApiRequestBodyTimesheetGroupUpdate) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/timesheet-groups/${timesheetGroupId}`,
    method: "PUT",
    data: newGroupData,
  } as AxiosRequestConfig;
  return epcmAxiosInstance(config);
};
