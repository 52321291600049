import { LimitedEmployeeType } from "../GeneralTypes.ts";

export enum ManpowerPlanStatusEnum {
  approved = "approved",
  pending = "pending",
  rejected = "rejected",
}

export interface PlanReferenceFileType {
  fileName: string;
  uploadedBy: LimitedEmployeeType;
  dateUploaded: Date;
}

export interface ManpowerPlanType {
  code: string;
  version: number;
  versionDisplay: string;
  approvalStatus: ManpowerPlanStatusEnum;
  month: string;
  dateUploaded: Date;
  uploadedBy: LimitedEmployeeType;
  referenceFiles: PlanReferenceFileType[];
}

export const FrontendManpowerPlanStates = {
  ReadyToGenerate: "ReadyToGenerate",
  Generating: "Generating",
  ReadyToDownload: "ReadyToDownload",
};

//const VERSION_NUMBER_OF_DIGITS = 4;

export const MANPOWER_PLAN_LIST: ManpowerPlanType[] = [
  // {
  //   code: "WAVE-23-08-ABC",
  //   version: 7,
  //   versionDisplay: displayNumberWithSpecificNumberOfDigits(7, VERSION_NUMBER_OF_DIGITS),
  //   approvalStatus: ManpowerPlanStatusEnum.pending,
  //   month: "M+7",
  //   dateUploaded: new Date("28, Jul 2023"),
  //   uploadedBy: {
  //     name: "Peter Jenkins",
  //     code: 443084,
  //   },
  //   referenceFiles: [
  //     {
  //       fileName: "signed_plan_approval.xlsx",
  //       uploadedBy: {
  //         name: "Peter Jenkins",
  //         code: 443084,
  //       },
  //       dateUploaded: new Date("28, Jul 2023"),
  //     },
  //     {
  //       fileName: "project_requirements.pdf",
  //       uploadedBy: {
  //         name: "John Smith",
  //         code: 654345,
  //       },
  //       dateUploaded: new Date("29, Jul 2023"),
  //     },
  //   ],
  // },
  // {
  //   code: "WAVE-23-08-ABC",
  //   version: 5,
  //   versionDisplay: displayNumberWithSpecificNumberOfDigits(5, VERSION_NUMBER_OF_DIGITS),
  //   approvalStatus: ManpowerPlanStatusEnum.approved,
  //   month: "M+6",
  //   dateUploaded: new Date("21, Jun 2023"),
  //   uploadedBy: {
  //     name: "John Smith",
  //     code: 654345,
  //   },
  //   referenceFiles: [
  //     {
  //       fileName: "project_requirements.pdf",
  //       uploadedBy: {
  //         name: "John Smith",
  //         code: 654345,
  //       },
  //       dateUploaded: new Date("21, Jun 2023"),
  //     },
  //     {
  //       fileName: "signed_plan_approval.xlsx",
  //       uploadedBy: {
  //         name: "Peter Jenkins",
  //         code: 443084,
  //       },
  //       dateUploaded: new Date("20, Jun 2023"),
  //     },
  //   ],
  // },
  // {
  //   code: "WAVE-23-08-ABC",
  //   version: 6,
  //   versionDisplay: displayNumberWithSpecificNumberOfDigits(6, VERSION_NUMBER_OF_DIGITS),
  //   approvalStatus: ManpowerPlanStatusEnum.rejected,
  //   month: "M+7",
  //   dateUploaded: new Date("15, Jul 2023"),
  //   uploadedBy: {
  //     name: "John Smith",
  //     code: 654345,
  //   },
  //   referenceFiles: [
  //     {
  //       fileName: "project_requirements.pdf",
  //       uploadedBy: {
  //         name: "John Smith",
  //         code: 654345,
  //       },
  //       dateUploaded: new Date("15, Jul 2023"),
  //     },
  //   ],
  // },
  // {
  //   code: "WAVE-23-08-ABC",
  //   version: 4,
  //   versionDisplay: displayNumberWithSpecificNumberOfDigits(4, VERSION_NUMBER_OF_DIGITS),
  //   approvalStatus: ManpowerPlanStatusEnum.approved,
  //   month: "M+5",
  //   dateUploaded: new Date("27, May 2023"),
  //   uploadedBy: {
  //     name: "John Smith",
  //     code: 654345,
  //   },
  //   referenceFiles: [
  //     {
  //       fileName: "project_requirements.pdf",
  //       uploadedBy: {
  //         name: "John Smith",
  //         code: 654345,
  //       },
  //       dateUploaded: new Date("27, May 2023"),
  //     },
  //     {
  //       fileName: "signed_plan_approval.xlsx",
  //       uploadedBy: {
  //         name: "Peter Jenkins",
  //         code: 443084,
  //       },
  //       dateUploaded: new Date("28, May 2023"),
  //     },
  //   ],
  // },
  //
  // {
  //   code: "WAVE-23-08-ABC",
  //   version: 3,
  //   versionDisplay: displayNumberWithSpecificNumberOfDigits(3, VERSION_NUMBER_OF_DIGITS),
  //   approvalStatus: ManpowerPlanStatusEnum.rejected,
  //   month: "M+5",
  //   dateUploaded: new Date("24, May 2023"),
  //   uploadedBy: {
  //     name: "John Smith",
  //     code: 654345,
  //   },
  //   referenceFiles: [
  //     {
  //       fileName: "project_requirements.pdf",
  //       uploadedBy: {
  //         name: "John Smith",
  //         code: 654345,
  //       },
  //       dateUploaded: new Date("24, May 2023"),
  //     },
  //   ],
  // },
];
