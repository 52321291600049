import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../../config.ts";
import { FilesFilterType, SseRequestExtended } from "../../../types/projects/FileTypes.ts";
import { AxiosRequestConfig } from "axios";
import { QueryModelWithCountAndData, QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { SseRequestBasic } from "epcm-common/src/Types/SseTypes.ts";
import { extractFileFilters } from "../../../utils/extractFileFilters.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getAllFiles = async (
  id: number,
  pageOffset?: number,
  pageSize: number = PAGINATION_PAGE_SIZE,
  search?: string,
  filtersList?: FilesFilterType[],
): Promise<QueryModelWithPagination<SseRequestExtended>> => {
  const params = new URLSearchParams();
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  search && params.set("search", `${search}`);

  extractFileFilters(params, filtersList!);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/sseRequests`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);
  const responseData = response.data as QueryModelWithCountAndData<SseRequestBasic>;

  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;

  const dataWithFileName = responseData.data.map((projectFile) => {
    return {
      ...projectFile,
      fileName: projectFile.fileName,
    };
  });
  return { data: dataWithFileName, nextPage: nextPage, totalCount: responseData.count };
};
