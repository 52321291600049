import axios from "axios";
import { ErrorCallbackDataType, SuccessCallbackDataType } from "../types/apicallstypes/queryCommons.ts";

export const EPCM_IsImpersonationEnabled = Boolean(import.meta.env.VITE_EPCM_ENABLE_IMPERSONATION === "true");

export const epcmAxiosInstance = axios.create();
export const EPCM_Api_Base_Url = import.meta.env.VITE_EPCM_API_URL;
export const EPCM_SENTRY_ENVIRONMENT = import.meta.env.VITE_EPCM_SENTRY_ENVIRONMENT;
export const EPCM_SENTRY_DSN = import.meta.env.VITE_EPCM_SENTRY_DSN;
export const EPCM_API_URLS = {
  employees: `${EPCM_Api_Base_Url}/employees`,
  profile: `${EPCM_Api_Base_Url}/me`,
  projects: `${EPCM_Api_Base_Url}/projects`,
  users: `${EPCM_Api_Base_Url}/users`,
  configurations: `${EPCM_Api_Base_Url}/configurations`,
};

export interface EpcmMutationOptionsType {
  onSuccessCallback?: (data: SuccessCallbackDataType) => void;
  onErrorCallback?: (error: ErrorCallbackDataType) => void;
  onSettledCallback?: () => void;
}

export const PAGINATION_PAGE_SIZE = 5;
