import { EPCM_API_URLS, epcmAxiosInstance } from "../config.ts";
import { AxiosRequestConfig } from "axios";

const API = `${EPCM_API_URLS.projects}`;

export const getFileEvents = async (projectId: number, fileId?: string): Promise<ReadableStream> => {
  const params = new URLSearchParams();
  fileId && params.set("fileId", fileId);
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/events`,
    responseType: "stream",
    adapter: "fetch",
    headers: {
      Accept: "text/event-stream",
    },
    method: "GET",
    params: params,
  };

  const response = await epcmAxiosInstance(config);

  return response.data;
};
