import { FC, ReactNode, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useImpersonationStore } from "../store/use-impersonation-store.ts";
import { useEpcmApiProfile } from "../apicalls/profile/useEpcmApiProfile.ts";
import { QueryNames } from "../types/apicallstypes/queryCommons.ts";
import * as Sentry from "@sentry/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

interface ImpersonatorProps {
  children: ReactNode;
}

enum QueryParamsAsString {
  "impersonate" = "asUser",
}

export const Impersonator: FC<ImpersonatorProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const { getMe } = useEpcmApiProfile();
  const { search } = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
  const userImpersonationFromQueryParams = urlSearchParams.get(QueryParamsAsString.impersonate);
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const userProfileQuery = useQuery({
    queryKey: [QueryNames.Profile],
    queryFn: () => getMe(),
    enabled: isAuthorized,
  });
  const userProfileData = useMemo(() => {
    return userProfileQuery.data;
  }, [userProfileQuery.data]);

  Sentry.setUser({
    id: userProfileData?.code,
    email: userProfileData?.email,
    username: userProfileData?.name,
  });

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.Profile] }).then(() => console.log(`In side banner, ${QueryNames.Profile} query canceled`));
    };
  }, [queryClient]);

  const impersonateState = useImpersonationStore((state) => state);

  if (userImpersonationFromQueryParams && !impersonateState.isLoggedIn) {
    impersonateState.loginUser({
      username: userImpersonationFromQueryParams,
      password: "archispectre2000",
    });
  } else if (userImpersonationFromQueryParams && userImpersonationFromQueryParams !== impersonateState.impersonateUser) {
    impersonateState.setImpersonationUsername(userImpersonationFromQueryParams);
  }

  return <>{children}</>;
};
