import { useState, useCallback, useMemo, MouseEvent, useEffect } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import ConfigurationManpowerCodesPopupStyles from "../ConfigurationManpowerCodesPopup.module.css";
import arrowSelectBox from "../../../../../../../assets/images/arrow-down-dark-blue-fill.svg";
import { Menu, MenuItem } from "@mui/material";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import { useInView } from "react-intersection-observer";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { QueryModelWithPagination } from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../../../apicalls/config.ts";
import InfiniteScrollInViewElement from "../../../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";

interface ConfigurationSelectProps<T> {
  label: string;
  queryName: string;
  getData: (projectId: number, pageParam: number, searchQuery: string | undefined, pageSize: number) => Promise<QueryModelWithPagination<T>>;
  selectedItem: T | null;
  setSelectedItem: (item: T) => void;
  projectId: number;
  diverseStyle?: boolean;
}

const ConfigurationSelect = <T extends { id: number; description: string }>({
  label,
  queryName,
  getData,
  selectedItem,
  setSelectedItem,
  projectId,
  diverseStyle,
}: ConfigurationSelectProps<T>) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(null);

  const dropdownMenuOpen = Boolean(dropdownAnchorEl);

  const onDropdownMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const onDropdownMenuClose = () => {
    setDropdownAnchorEl(null);
  };

  const onItemChange = useCallback(
    (item: T) => {
      setSelectedItem(item);
      onDropdownMenuClose();
    },
    [setSelectedItem],
  );

  const allItemsQuery = useInfiniteQuery({
    queryKey: [queryName, projectId],
    queryFn: ({ pageParam }) => getData(projectId, pageParam, undefined, PAGINATION_PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage: QueryModelWithPagination<T>) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const allItemsData = useMemo(() => allItemsQuery.data?.pages.flatMap((page) => page.data) || [], [allItemsQuery.data]);

  const hasItems = useMemo(() => allItemsData.length > 0, [allItemsData]);
  const isLoading = useMemo(() => allItemsQuery.isLoading || allItemsQuery.isFetching, [allItemsQuery.isLoading, allItemsQuery.isFetching]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [queryName, projectId] });
    };
  }, [queryName, projectId, queryClient]);

  useEffect(() => {
    if (inView && !allItemsQuery.isLoading && !allItemsQuery.isFetching && !allItemsQuery.isFetchingNextPage && allItemsQuery.hasNextPage) {
      void allItemsQuery.fetchNextPage();
    }
  }, [inView, allItemsQuery]);

  return (
    <div
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        diverseStyle ? GlobalStyles.gap05 : GlobalStyles.gap025,
        GlobalStyles.elementWithCursor,
      )}
    >
      <div className={classNames(ConfigurationManpowerCodesPopupStyles.infoText)}>{label}</div>
      <div
        className={classNames(
          !isLoading
            ? hasItems
              ? diverseStyle
                ? ConfigurationManpowerCodesPopupStyles.secondVersionInputContainer
                : ConfigurationManpowerCodesPopupStyles.inputContainer
              : ConfigurationManpowerCodesPopupStyles.emptyContainer
            : diverseStyle
              ? ConfigurationManpowerCodesPopupStyles.secondLoadingPlaceholder
              : ConfigurationManpowerCodesPopupStyles.loadingPlaceholder,
          GlobalStyles.flex,
          GlobalStyles.gap,
        )}
        onClick={hasItems ? onDropdownMenuOpen : undefined}
      >
        {selectedItem?.id ? (
          <div>{selectedItem.description}</div>
        ) : hasItems ? (
          <div className={classNames(ConfigurationManpowerCodesPopupStyles.inputPlaceholder)}>{"Select " + label.toLowerCase()}</div>
        ) : allItemsQuery.isLoading ? (
          <div className={classNames(ConfigurationManpowerCodesPopupStyles.emptyInputPlaceholder)}>{"Loading " + label.toLowerCase() + "..."}</div>
        ) : (
          <div className={classNames(ConfigurationManpowerCodesPopupStyles.emptyInputPlaceholder)}>{"No " + label.toLowerCase() + " available"}</div>
        )}
        <div className={classNames(GlobalStyles.flex1)} />
        {hasItems && (
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(ConfigurationManpowerCodesPopupStyles.selectBoxArrowIconImg)} src={arrowSelectBox} alt="arrow" />
          </div>
        )}
      </div>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={dropdownAnchorEl}
        open={dropdownMenuOpen}
        onClose={onDropdownMenuClose}
        PaperProps={{
          style: {
            maxHeight: "15em",
            width: "20em",
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        {hasItems &&
          allItemsData.map((item) => (
            <MenuItem key={item.id} selected={item.id === selectedItem?.id} onClick={() => onItemChange(item)}>
              {item.description}
            </MenuItem>
          ))}
        <InfiniteScrollInViewElement reference={ref} infiniteQueryResult={allItemsQuery} loaderComponent={<></>} />
      </Menu>
    </div>
  );
};

export default ConfigurationSelect;
