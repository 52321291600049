import { EpcmMutationOptionsType } from "../../../config.ts";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { ErrorCallbackDataType, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { uploadManpowerPlan } from "./uploadManpowerPlan.ts";

interface ApiDefinition {
  useUploadManpowerPlan: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, FormData>;
}

export const useEpcmApiProjectManpowerPlanMutations = (): ApiDefinition => {
  const useUploadProjectManpowerPlan = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, FormData>({
      mutationFn: async (manpowerPlanFile: FormData) => {
        return uploadManpowerPlan(projectId, manpowerPlanFile);
      },
      onSuccess: (data) => {
        console.log("on post create manpower plan mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post create manpower plan mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        //void queryClient.invalidateQueries({ queryKey: [QueryNames.Projects] });
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  return {
    useUploadManpowerPlan: useUploadProjectManpowerPlan,
  };
};
