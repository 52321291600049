import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import TimesheetPeriodsStyles from "./TimesheetPeriodsStyles.module.css";
import { DateFormatEnum, formatDate } from "../../../../../utils/DateManipulation.ts";
import { useProjectContext } from "../../../ProjectsUtils.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useEpcmApiProjectsTimesheets } from "../../../../../apicalls/projects/projecttimesheets/useEpcmApiProjectsTimesheets.ts";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../apicalls/config.ts";
import { FC, memo, useEffect, useMemo } from "react";
import { Skeleton } from "@mui/material";
import InfiniteScrollInViewElement from "../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useInView } from "react-intersection-observer";
import PeriodTimesheetList from "./periodtimesheetlist/PeriodTimesheetList.tsx";
import { FrontendTimesheetPeriodType } from "../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { useHandleUnauthorized } from "../../../../../utils/use-handle-unauthorized.ts";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";

interface TimesheetPeriodsProps {
  keepCurrentPeriodOnly: boolean;
}

interface TimesheetPeriodsDataListInnerListItemProps {
  periodItem: FrontendTimesheetPeriodType;
  keepCurrentPeriodOnly: boolean;
}

interface TimesheetPeriodsDataListProps {
  projectTimesheetPeriodsData: FrontendTimesheetPeriodType[] | undefined;
  keepCurrentPeriodOnly: boolean;
}

const TimesheetPeriods: FC<TimesheetPeriodsProps> = ({ keepCurrentPeriodOnly }) => {
  const { currentProject, searchQuery, selectedPeriodStart, selectedPeriodEnd, setSelectedPeriodEnd, setSelectedPeriodStart } = useProjectContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllTimesheetPeriods } = useEpcmApiProjectsTimesheets();
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { handleErrorRedirect } = useHandleUnauthorized();

  const projectTimesheetPeriodsQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectTimesheetPeriods, currentProject.id, searchQuery, keepCurrentPeriodOnly],
    queryFn: ({ pageParam }) =>
      getAllTimesheetPeriods(currentProject.id, keepCurrentPeriodOnly ? 1 : PAGINATION_PAGE_SIZE, pageParam, searchQuery).catch(handleErrorRedirect),
    initialPageParam: 1,
    enabled: isAuthorized,
    getNextPageParam: (lastPage) => {
      // If keepCurrentPeriodOnly is true, we prevent further pagination by returning undefined
      if (keepCurrentPeriodOnly) {
        return undefined;
      }

      return lastPage.nextPage ?? undefined;
    },
  });

  const projectTimesheetPeriodsData = useMemo(() => {
    return projectTimesheetPeriodsQuery.data?.pages.flatMap((page) => page.data);
  }, [projectTimesheetPeriodsQuery.data, keepCurrentPeriodOnly]);

  useEffect(() => {
    if (projectTimesheetPeriodsData) {
      if (setSelectedPeriodStart) {
        setSelectedPeriodStart(projectTimesheetPeriodsData[0]?.periodStart);
      }
      if (setSelectedPeriodEnd) {
        setSelectedPeriodEnd(projectTimesheetPeriodsData[0]?.periodEnd);
      }
    }
  }, [projectTimesheetPeriodsData, selectedPeriodStart, selectedPeriodEnd, setSelectedPeriodStart, setSelectedPeriodEnd]);

  const projectTimesheetItemSkeletonLoader = (
    <div className={classNames(TimesheetPeriodsStyles.timesheetListItemSkeletonContainer, GlobalStyles.flex, GlobalStyles.gap)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap2)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={38} width={30} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.gap05)}>
          <Skeleton variant="rounded" height={13} width={150} />
          <Skeleton variant="rounded" height={13} width={100} />
        </div>
      </div>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex1, GlobalStyles.gap075)}>
        <Skeleton variant="rounded" height={13} width={150} />
        <Skeleton variant="rounded" height={13} width={100} />
      </div>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex1, GlobalStyles.gap075)}>
        <Skeleton variant="rounded" height={13} width={150} />
        <Skeleton variant="rounded" height={13} width={100} />
      </div>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex1, GlobalStyles.gap075)}>
        <Skeleton variant="rounded" height={13} width={150} />
        <Skeleton variant="rounded" height={13} width={100} />
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap3)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={0} width={17} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={25} width={25} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={25} width={25} />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={0} width={17} />
        </div>
      </div>
    </div>
  );

  const projectTimesheetListSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      {["timesheet1", "timesheet2", "timesheet3"].map((key) => (
        <div key={key}>{projectTimesheetItemSkeletonLoader}</div>
      ))}
    </div>
  );

  const projectTimesheetPeriodSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.gap05)}>
        <Skeleton variant="rounded" height={14} width={120} />
        <Skeleton variant="rounded" height={23} width={230} />
      </div>
      {projectTimesheetListSkeletonLoader}
    </div>
  );

  const canListTimesheets = canPerformProjectAction(ProjectAction.ProjectTimesheetListList);

  useEffect(() => {
    if (
      inView &&
      !projectTimesheetPeriodsQuery.isLoading &&
      !projectTimesheetPeriodsQuery.isFetching &&
      !projectTimesheetPeriodsQuery.isFetchingNextPage &&
      projectTimesheetPeriodsQuery.hasNextPage
    ) {
      void projectTimesheetPeriodsQuery.fetchNextPage();
    }
  }, [projectTimesheetPeriodsQuery, inView]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectTimesheetPeriods, currentProject.id, searchQuery, keepCurrentPeriodOnly] })
        .then(() => {
          console.log(`In project timesheet list component, ${QueryNames.ProjectTimesheetPeriods} query cancelled`);
        });
    };
  }, [queryClient, currentProject, searchQuery, keepCurrentPeriodOnly]);

  return (
    <div className={classNames(TimesheetPeriodsStyles.container, GlobalStyles.flex, GlobalStyles.gap, GlobalStyles.flexDirectionColumn)}>
      {projectTimesheetPeriodsQuery.isLoading && projectTimesheetPeriodSkeletonLoader}
      {projectTimesheetPeriodsData && projectTimesheetPeriodsData.length === 0 && (
        <div className={classNames(GlobalStyles.flex)}>
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.emptyListMsg)}>{canListTimesheets ? "" : "No periods"}</div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
      )}
      {canListTimesheets && projectTimesheetPeriodsData && (
        <TimesheetPeriodsDataList keepCurrentPeriodOnly={keepCurrentPeriodOnly} projectTimesheetPeriodsData={projectTimesheetPeriodsData} />
      )}
      {canListTimesheets && projectTimesheetPeriodsData && (
        <InfiniteScrollInViewElement
          key={"InfiniteScrollInViewElement"}
          reference={ref}
          infiniteQueryResult={projectTimesheetPeriodsQuery}
          loaderComponent={projectTimesheetPeriodSkeletonLoader}
        />
      )}
    </div>
  );
};

const TimesheetPeriodsDataListInnerListItem: FC<TimesheetPeriodsDataListInnerListItemProps> = memo(
  ({ periodItem, keepCurrentPeriodOnly }) => {
    return (
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(TimesheetPeriodsStyles.timesheetPeriod, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <div className={classNames(TimesheetPeriodsStyles.timesheetPeriodLabel)}>{"Timesheets Period"}</div>
            <div className={classNames(TimesheetPeriodsStyles.timesheetPeriodValue)}>{`${formatDate(
              periodItem.periodStart,
              DateFormatEnum.DD_COMMA_MMM_YYYY,
            )} - ${formatDate(periodItem.periodEnd, DateFormatEnum.DD_COMMA_MMM_YYYY)}`}</div>
          </div>
        </div>
        <PeriodTimesheetList keepCurrentPeriodOnly={keepCurrentPeriodOnly} periodStart={periodItem.periodStart} periodEnd={periodItem.periodEnd} />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.periodItem.periodStart.getDate() === nextProps.periodItem.periodStart.getDate() &&
      prevProps.periodItem.periodEnd.getDate() === nextProps.periodItem.periodEnd.getDate() &&
      prevProps.keepCurrentPeriodOnly === nextProps.keepCurrentPeriodOnly
    );
  },
);

const TimesheetPeriodsDataList: FC<TimesheetPeriodsDataListProps> = memo(
  ({ projectTimesheetPeriodsData, keepCurrentPeriodOnly }) => {
    return (
      <>
        {projectTimesheetPeriodsData &&
          projectTimesheetPeriodsData?.map((periodItem) => (
            <TimesheetPeriodsDataListInnerListItem
              keepCurrentPeriodOnly={keepCurrentPeriodOnly}
              key={`start${periodItem.periodStart}-end${periodItem.periodEnd}`}
              periodItem={periodItem}
            />
          ))}
      </>
    );
  },
  (prevProps, nextProps) => {
    // Check just the actual Date of the periodStart and periodEnd, ignoring the time
    return (
      (prevProps.projectTimesheetPeriodsData?.length === nextProps.projectTimesheetPeriodsData?.length &&
        prevProps.projectTimesheetPeriodsData?.every((prevPeriodItem, index) => {
          const nextPeriodItem = nextProps.projectTimesheetPeriodsData?.[index];
          return (
            prevPeriodItem.periodStart.getDate() === nextPeriodItem?.periodStart.getDate() &&
              prevPeriodItem.periodEnd.getDate() === nextPeriodItem?.periodEnd.getDate(),
            prevProps.keepCurrentPeriodOnly === nextProps.keepCurrentPeriodOnly
          );
        })) ??
      false
    );
  },
);

export default TimesheetPeriods;
