import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import GroupsList from "./groupslist/GroupsList.tsx";
import classNames from "classnames";
import SelectGroupStyles from "./SelectGroupPopupStyles.module.css";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SubQueryNames,
  SuccessCallbackDataType,
} from "../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../apicalls/config.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";

import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import { ApiRequestBodyTimesheetGroupAddProjectEmployees } from "epcm-common/dist/Types/TimesheetGroupTypes.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import BluePlusIcon from "../../../../assets/images/blue-plus-icon.svg";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import CreateNewProjectGroup from "../createnewprojectgroup/CreateNewProjectGroup.tsx";
import InfiniteScrollInViewElement from "../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useInView } from "react-intersection-observer";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../types/Roles.ts";
import { ProjectTimesheetGroupModificationTypeEnum } from "../../../../types/projects/ProjectTimesheetsTypes.ts";

interface SelectGroupPopupProps extends PopupType {
  projectId: number;
  newGroupMembers: number[];
  setNewGroupMembers: Dispatch<SetStateAction<number[]>>;
  closeOnSuccess: () => void;
}

const SelectGroupPopup: FC<SelectGroupPopupProps> = ({
  isOpen,
  closeFn,
  headerText,
  projectId,
  newGroupMembers,
  setNewGroupMembers,
  closeOnSuccess,
}) => {
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();
  const { getAllTimesheetGroups } = useEpcmApiProjectsTimesheetsGroups();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const timesheetGroupsQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectTimesheetGroups, projectId],
    queryFn: ({ pageParam }) => getAllTimesheetGroups(projectId, PAGINATION_PAGE_SIZE, pageParam),
    initialPageParam: 1,
    enabled: isAuthorized,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
  });

  const timesheetsGroupsData = useMemo(() => {
    return timesheetGroupsQuery.data?.pages.flatMap((page) => page.data);
  }, [timesheetGroupsQuery.data]);

  const timesheetGroupsCount = useMemo(() => {
    return timesheetGroupsQuery.data?.pages[0]?.totalCount ?? 0;
  }, [timesheetGroupsQuery.data]);

  const canCreateGroup = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupCreate);
  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId] }).then(() => {
        console.log(`In select group popup component, ${QueryNames.ProjectTimesheetGroups} query cancelled`);
      });
    };
  }, [queryClient, projectId]);

  useEffect(() => {
    if (
      inView &&
      !timesheetGroupsQuery.isLoading &&
      !timesheetGroupsQuery.isFetching &&
      !timesheetGroupsQuery.isFetchingNextPage &&
      timesheetGroupsQuery.hasNextPage
    ) {
      void timesheetGroupsQuery.fetchNextPage();
    }
  }, [inView, timesheetGroupsQuery]);

  const { useCreateTimesheetGroupEmployeesMutation } = useEpcmApiProjectTimesheetsGroupsMutations();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const createTimesheetGroupEmployeesMutation = useCreateTimesheetGroupEmployeesMutation(projectId, selectedGroupId!, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Employee was added to group successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onConfirmAddEmployeesToGroup = useCallback(() => {
    createTimesheetGroupEmployeesMutation.mutate({ projectEmployeesIds: newGroupMembers } satisfies ApiRequestBodyTimesheetGroupAddProjectEmployees);
    onOpenLoaderPopup();
  }, [createTimesheetGroupEmployeesMutation, onOpenLoaderPopup, newGroupMembers]);

  const invalidateQueries = useCallback(() => {
    void queryClient.resetQueries({ queryKey: [QueryNames.ProjectTimesheetGroupEmployeeCount, projectId, selectedGroupId] }).then(() => {
      console.log(`In select group popup component, ${QueryNames.ProjectTimesheetGroupEmployeeCount} query cancelled`);
    });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroupEmployeeCount, SubQueryNames.list, projectId, selectedGroupId] });

    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId.toString(), selectedGroupId!.toString()] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployees, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.TimesheetGroupEmployees, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, SubQueryNames.count] });
  }, [projectId, queryClient, selectedGroupId]);

  return (
    <PopupStructure
      popupButtons={[
        { text: "Back", buttonType: PopupButtonTypeEnum.neutral, action: closeFn },
        {
          text: "Add to Group",
          buttonType: PopupButtonTypeEnum.main,
          tooltipText: selectedGroupId ? "" : "Please select a group",
          disabled: !selectedGroupId,
          action: () => onConfirmAddEmployeesToGroup(),
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      overrideContentContainerStyleClass={""}
      secondaryHeaderText={`To add ${newGroupMembers.length} employees`}
    >
      <div className={classNames(SelectGroupStyles.contentContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
        {timesheetsGroupsData && timesheetGroupsCount && timesheetGroupsCount > 0 ? (
          <div className={classNames(SelectGroupStyles.headerContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <GroupsList
              groupsList={timesheetsGroupsData}
              projectId={projectId}
              selectedGroupId={selectedGroupId}
              setSelectedGroupId={setSelectedGroupId}
            >
              <InfiniteScrollInViewElement
                key={"InfiniteScrollInViewElement"}
                reference={ref}
                infiniteQueryResult={timesheetGroupsQuery}
                loaderComponent={<></>}
              />
            </GroupsList>
          </div>
        ) : timesheetGroupsQuery.isLoading ? (
          <div className={classNames(GlobalStyles.emptyListMsgSmall)}>Loading Groups</div>
        ) : (
          <div className={classNames(GlobalStyles.emptyListMsg, GlobalStyles.centerHorizontal, GlobalStyles.centerVertical)}>No Groups </div>
        )}
        {canCreateGroup && (
          <div
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.gap075,
              GlobalStyles.elementWithCursor,
              SelectGroupStyles.actionBtnContainer,
            )}
            onClick={() => onOpenPopup(ProjectsPagePopups.createProjectGroup, popupHandler)}
          >
            <div
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.centerHorizontal,
                GlobalStyles.centerVertical,
                SelectGroupStyles.plusIconContainer,
              )}
            >
              <img src={BluePlusIcon} alt={"Add New Group"} />
            </div>
            <div className={classNames(SelectGroupStyles.addGroupText)}>Create New Group</div>
          </div>
        )}
      </div>
      {popupHandler.get(ProjectsPagePopups.createProjectGroup)!.isOpen && (
        <CreateNewProjectGroup
          mode={ProjectTimesheetGroupModificationTypeEnum.create}
          projectId={projectId}
          isOpen={popupHandler.get(ProjectsPagePopups.createProjectGroup)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.createProjectGroup, popupHandler)}
          headerText={popupHeaders.get(ProjectsPagePopups.createProjectGroup)}
          selectGroup={setSelectedGroupId}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            setNewGroupMembers([]);
            if (responseType === ApiResponseTypeEnum.success) {
              setSelectedGroupId(null);
              invalidateQueries();
              closeFn();

              closeOnSuccess();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};
export default SelectGroupPopup;
