import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import { SearchBar } from "../../../../ui/searchbar/SearchBar.tsx";
import AddProjectEmployeesPopupStyles from "./AddProjectEmployeesPopup.module.css";
// import filterIcon from "../../../../assets/images/filter-icon.svg";
import { EmployeeFilterEnum, EmployeeFilterType, SortEnum } from "../../../../types/employees/EmployeesTypes.ts";
import { EmployeeListItem } from "./employeelistItem/EmployeeListItem.tsx";
import { displayNumberWithSpecificNumberOfDigits } from "../../../../utils/NumberManipulation.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import { AddProjectEmployeesFiltersPopup } from "../addprojectemployeesfilterspopup/AddProjectEmployeesFiltersPopup.tsx";
import folderIcon from "../../../../assets/images/envelope-orange-fill.svg";
import wrenchIcon from "../../../../assets/images/wrench-orange-fill.svg";
import flagIcon from "../../../../assets/images/flag-orange-fill.svg";
import messagesIcon from "../../../../assets/images/message-icon-orange-fill.svg";
import workerIcon from "../../../../assets/images/worker-icon-orange-fill.svg";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useEpcmApiEmployees } from "../../../../apicalls/employees/useEpcmApiEmployees.ts";
import { useEpcmApiProjectsMutations } from "../../../../apicalls/projects/mutations/useEpcmApiProjectsMutations.ts";
import { useParams } from "react-router-dom";
import InfiniteScrollInViewElement from "../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useInView } from "react-intersection-observer";
import { Skeleton } from "@mui/material";
import { ApiRequestProjectEmployeeCreate } from "epcm-common/dist/Types/ProjectEmployeeTypes";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../apicalls/config.ts";

interface AddProjectEmployeesPopupProps extends PopupType {}

interface SortByInfoType {
  filterType: EmployeeFilterEnum;
  sortType: SortEnum;
}

const SortByIconMap: Map<EmployeeFilterEnum, string> = new Map([
  [EmployeeFilterEnum.employeeName, workerIcon],
  [EmployeeFilterEnum.employeeType, folderIcon],
  [EmployeeFilterEnum.position, wrenchIcon],
  [EmployeeFilterEnum.nationality, flagIcon],
  [EmployeeFilterEnum.languages, messagesIcon],
]);

export const AddProjectEmployeesPopup: FC<AddProjectEmployeesPopupProps> = ({ isOpen, closeFn, headerText, secondaryHeaderText }) => {
  const { projectId } = useParams();
  const { popupHandler, onClosePopup, popupHeaders } = useProjectsPopups();
  const [parent] = useAutoAnimate();
  const { getAllEmployees } = useEpcmApiEmployees();
  const { useCreateProjectEmployeesMutation } = useEpcmApiProjectsMutations();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [employeeIdsSelected, setEmployeeIdsSelected] = useState<number[]>([]);
  const [filterList, setFilterList] = useState<EmployeeFilterType[]>([]);

  const allEmployeesQuery = useInfiniteQuery({
    queryKey: [QueryNames.Employees, parseInt(projectId!), searchQuery],
    queryFn: ({ pageParam }) => getAllEmployees(pageParam, searchQuery, PAGINATION_PAGE_SIZE, parseInt(projectId!)),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const allEmployeesData = useMemo(() => {
    return allEmployeesQuery.data?.pages.flatMap((page) => page.data);
  }, [allEmployeesQuery.data]);

  const createProjectEmployeesMutation = useCreateProjectEmployeesMutation(parseInt(projectId!), {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Employee was added to project successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeesMmrStatusCount, parseInt(projectId!)] });
      onCloseLoaderPopup();
    },
  });

  const sortByFilter: SortByInfoType | undefined = useMemo(() => {
    const activeSubFilters = filterList.find((filter) => filter.sortStatus !== SortEnum.inactive);

    return activeSubFilters ? ({ filterType: activeSubFilters.id, sortType: activeSubFilters.sortStatus } satisfies SortByInfoType) : undefined;
  }, [filterList]);

  const getActiveSubFilters = useCallback(
    (filterType: EmployeeFilterEnum): string[] => {
      const activeSubFilters = filterList
        .find((filter) => filter.id === filterType)
        ?.subFilters.filter((subFilter) => subFilter.isActive)
        .map((subFilter) => subFilter.title);

      return activeSubFilters ?? [];
    },
    [filterList],
  );

  const activeEmployeeTypeSubFilters = useMemo(() => getActiveSubFilters(EmployeeFilterEnum.employeeType), [getActiveSubFilters]);
  const activePositionSubFilters = useMemo(() => getActiveSubFilters(EmployeeFilterEnum.position), [getActiveSubFilters]);
  const activeNationalitySubFilters = useMemo(() => getActiveSubFilters(EmployeeFilterEnum.nationality), [getActiveSubFilters]);
  const activeLanguagesSubFilters = useMemo(() => getActiveSubFilters(EmployeeFilterEnum.languages), [getActiveSubFilters]);

  const onEmployeeToggle = useCallback((employeeId: number) => {
    setEmployeeIdsSelected((currentState) => {
      const employeeIdsSelectedClone = currentState.slice();
      const employeeIdIndex = employeeIdsSelectedClone.indexOf(employeeId);
      if (employeeIdIndex !== -1) {
        employeeIdsSelectedClone.splice(employeeIdIndex, 1);
      } else {
        employeeIdsSelectedClone.push(employeeId);
      }
      return employeeIdsSelectedClone;
    });
  }, []);

  const isEmployeeSelected = useCallback(
    (employeeId: number) => {
      return employeeIdsSelected.includes(employeeId);
    },
    [employeeIdsSelected],
  );

  const onSearchQueryChange = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  // const filterEmployeesBySearchQuery = useCallback(
  //   (listToFilter: EmployeeType[]) => {
  //     if (searchQuery === "") return listToFilter;
  //     const queryWordsSplit = searchQuery.split(" ").filter((w) => w !== "");
  //     const queryMatches = queryWordsSplit.map((word) =>
  //       listToFilter.filter(
  //         (employeeItem) =>
  //           employeeItem.name.toUpperCase().includes(word.toUpperCase()) ||
  //           employeeItem.id.toUpperCase().includes(word.toUpperCase()) ||
  //           employeeItem.position.toString().toUpperCase().includes(word.toUpperCase()) ||
  //           employeeItem.nationality.toUpperCase().includes(word.toUpperCase()) ||
  //           employeeItem.category.toUpperCase().includes(word.toUpperCase()),
  //       ),
  //     );
  //     const matchesFound = [] as EmployeeType[];
  //     queryMatches.forEach((matchArray) => {
  //       matchArray.forEach((match) => matchesFound.push(match));
  //     });
  //
  //     return matchesFound.filter((m, index) => matchesFound.indexOf(m) === index);
  //   },
  //   [searchQuery],
  // );

  // const filteredEmployeeList = useMemo(() => {
  //   const sortByIndex = filterList.map((filterItem) => filterItem.sortStatus).indexOf(SortEnum.active || SortEnum.reverted);
  //   let list = filterEmployeesBySearchQuery(EMPLOYEE_LIST.slice());
  //   list =
  //     activeEmployeeTypeSubFilters.length > 0 ? list.filter((employeeItem) => activeEmployeeTypeSubFilters.includes(employeeItem.category)) : list;
  //   list = activePositionSubFilters.length > 0 ? list.filter((employeeItem) => activePositionSubFilters.includes(employeeItem.position)) : list;
  //   list =
  //     activeNationalitySubFilters.length > 0 ? list.filter((employeeItem) => activeNationalitySubFilters.includes(employeeItem.nationality)) : list;
  //   list =
  //     activeLanguagesSubFilters.length > 0
  //       ? list.filter(
  //           (employeeItem) =>
  //             employeeItem.languages.map((languageItem) => activeLanguagesSubFilters.includes(languageItem)).filter((hasLanguage) => hasLanguage)
  //               .length > 0,
  //         )
  //       : list;
  //
  //   if (sortByIndex !== -1) {
  //     filterList[sortByIndex].id === EmployeeFilterEnum.employeeName
  //       ? list.sort((a, b) => a.name.localeCompare(b.name))
  //       : filterList[sortByIndex].id === EmployeeFilterEnum.employeeType
  //       ? list.sort((a, b) => a.category.localeCompare(b.category))
  //       : filterList[sortByIndex].id === EmployeeFilterEnum.position
  //       ? list.sort((a, b) => a.position.localeCompare(b.position))
  //       : filterList[sortByIndex].id === EmployeeFilterEnum.nationality
  //       ? list.sort((a, b) => a.nationality.localeCompare(b.nationality))
  //       : list;
  //   }
  //   return list;
  // }, [
  //   filterList,
  //   filterEmployeesBySearchQuery,
  //   activeEmployeeTypeSubFilters,
  //   activeLanguagesSubFilters,
  //   activeNationalitySubFilters,
  //   activePositionSubFilters,
  // ]);

  const onCreateProjectEmployees = useCallback(() => {
    createProjectEmployeesMutation.mutate({ employeesIds: employeeIdsSelected } satisfies ApiRequestProjectEmployeeCreate);
    onOpenLoaderPopup();
  }, [createProjectEmployeesMutation, employeeIdsSelected, onOpenLoaderPopup]);

  const employeeItemSkeletonLoader = (
    <div
      className={classNames(
        AddProjectEmployeesPopupStyles.employeeItemContainerSkeletonLoader,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="circular" height={30} width={30} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
          <Skeleton variant="circular" height={50} width={50} />
          <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.gap05)}>
            <Skeleton variant="rounded" height={16} width={200} />
            <Skeleton variant="rounded" height={16} width={150} />
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="circular" height={20} width={20} />
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <Skeleton variant="rounded" height={0} width={40} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <Skeleton variant="rounded" height={0} width={10} />
      </div>
    </div>
  );

  useEffect(() => {
    if (
      inView &&
      !allEmployeesQuery.isLoading &&
      !allEmployeesQuery.isFetching &&
      !allEmployeesQuery.isFetchingNextPage &&
      allEmployeesQuery.hasNextPage
    ) {
      void allEmployeesQuery.fetchNextPage();
    }
  }, [inView, allEmployeesQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Employees, parseInt(projectId!), searchQuery] })
        .then(() => console.log(`In select employees for project, ${QueryNames.Employees} query canceled`));
    };
  }, [queryClient, searchQuery, projectId]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.Employees, parseInt(projectId!), searchQuery] });
  }, [queryClient, projectId, searchQuery]);

  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel ",
          buttonType: PopupButtonTypeEnum.neutral,
          action: closeFn,
        },
        {
          text: "Add to Project",
          buttonType: PopupButtonTypeEnum.main,
          disabled: employeeIdsSelected.length === 0,
          tooltipText: employeeIdsSelected.length === 0 ? "Please select some employees first" : undefined,
          action: () => employeeIdsSelected.length > 0 && onCreateProjectEmployees(),
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
          <SearchBar
            placeholder={"Search employees..."}
            setSearchQuery={onSearchQueryChange}
            searchBarContainerStyles={AddProjectEmployeesPopupStyles.searchBarContainer}
          />
          {/*<div*/}
          {/*  className={classNames(GlobalStyles.centerVertical)}*/}
          {/*  onClick={() => onOpenPopup(ProjectsPagePopups.addProjectEmployeesFilters, popupHandler)}*/}
          {/*>*/}
          {/*  <img className={classNames(AddProjectEmployeesPopupStyles.filterIconImg)} src={filterIcon} alt="filter" />*/}
          {/*</div>*/}
        </div>
        <div ref={parent} className={classNames(AddProjectEmployeesPopupStyles.activeFilterListContainer, GlobalStyles.flex, GlobalStyles.gap15)}>
          {activeEmployeeTypeSubFilters.length > 0 && (
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(AddProjectEmployeesPopupStyles.activeFilterIconImg)} src={folderIcon} alt="folder" />
              </div>
              <div className={classNames(GlobalStyles.centerVertical)}>{activeEmployeeTypeSubFilters[0]}</div>
              {activeEmployeeTypeSubFilters.length > 1 && (
                <div className={classNames(GlobalStyles.centerVertical)}>{`+${activeEmployeeTypeSubFilters.length - 1}`}</div>
              )}
            </div>
          )}
          {activePositionSubFilters.length > 0 && (
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(AddProjectEmployeesPopupStyles.activeFilterIconImg)} src={wrenchIcon} alt="position" />
              </div>
              <div className={classNames(GlobalStyles.centerVertical)}>{activePositionSubFilters[0]}</div>
              {activePositionSubFilters.length > 1 && (
                <div className={classNames(GlobalStyles.centerVertical)}>{`+${activePositionSubFilters.length - 1}`}</div>
              )}
            </div>
          )}
          {activeNationalitySubFilters.length > 0 && (
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(AddProjectEmployeesPopupStyles.activeFilterIconImg)} src={flagIcon} alt="country" />
              </div>
              <div className={classNames(GlobalStyles.centerVertical)}>{activeNationalitySubFilters[0]}</div>
              {activeNationalitySubFilters.length > 1 && (
                <div className={classNames(GlobalStyles.centerVertical)}>{`+${activeNationalitySubFilters.length - 1}`}</div>
              )}
            </div>
          )}
          {activeLanguagesSubFilters.length > 0 && (
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(AddProjectEmployeesPopupStyles.activeFilterIconImg)} src={messagesIcon} alt="lang" />
              </div>
              <div className={classNames(GlobalStyles.centerVertical)}>{activeLanguagesSubFilters[0]}</div>
              {activeLanguagesSubFilters.length > 1 && (
                <div className={classNames(GlobalStyles.centerVertical)}>{`+${activeLanguagesSubFilters.length - 1}`}</div>
              )}
            </div>
          )}
          {sortByFilter && (
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img
                  className={classNames(AddProjectEmployeesPopupStyles.activeFilterIconImg)}
                  src={SortByIconMap.get(sortByFilter.filterType)}
                  alt="worker"
                />
              </div>
              <div className={classNames(GlobalStyles.centerVertical)}>{sortByFilter.sortType === SortEnum.active ? "A-Z" : "Z-A"}</div>
            </div>
          )}
        </div>
        <div
          ref={parent}
          className={classNames(
            AddProjectEmployeesPopupStyles.employeeListContainer,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap,
          )}
        >
          {allEmployeesData && allEmployeesData.length > 0 ? (
            <>
              {allEmployeesData.map((employeeItem) => (
                <EmployeeListItem
                  key={employeeItem.id}
                  employeeItem={employeeItem}
                  isSelected={isEmployeeSelected}
                  onEmployeeToggle={onEmployeeToggle}
                />
              ))}
              <div className={classNames(GlobalStyles.flex1)} />
              <InfiniteScrollInViewElement reference={ref} infiniteQueryResult={allEmployeesQuery} loaderComponent={employeeItemSkeletonLoader} />
            </>
          ) : (
            <div className={classNames(GlobalStyles.emptyListMsg, GlobalStyles.flex)}>
              <div className={classNames(GlobalStyles.flex1)} />
              <div>{"No Employees"}</div>
              <div className={classNames(GlobalStyles.flex1)} />
            </div>
          )}
        </div>

        <div className={classNames(AddProjectEmployeesPopupStyles.selectedInfoContainer, GlobalStyles.flex, GlobalStyles.gap05)}>
          <div className={classNames(GlobalStyles.centerVertical)}>{"Selected:"}</div>
          <div className={classNames(AddProjectEmployeesPopupStyles.selectedCount, GlobalStyles.centerVertical)}>
            {displayNumberWithSpecificNumberOfDigits(employeeIdsSelected.length)}
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
          <div
            className={classNames(AddProjectEmployeesPopupStyles.selectedClearButton, GlobalStyles.centerVertical)}
            onClick={() => setEmployeeIdsSelected([])}
          >
            {"Clear All"}
          </div>
        </div>
      </div>

      {popupHandler.get(ProjectsPagePopups.addProjectEmployeesFilters)!.isOpen && (
        <AddProjectEmployeesFiltersPopup
          isOpen={popupHandler.get(ProjectsPagePopups.addProjectEmployeesFilters)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.addProjectEmployeesFilters, popupHandler);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.addProjectEmployeesFilters)}
          onApplyFilters={(filterList) => setFilterList(filterList)}
          initialFilterList={filterList.length > 0 ? filterList : undefined}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};
