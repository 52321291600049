import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { FrontendGlobalUserTypeWithRoleIds } from "../../../types/projects/PermissionTypes.ts";
import { getGlobalUserRoles } from "./getGlobalUserRoles.ts";
import { convertGlobalUserRolesData } from "../../../types/apicallstypes/apicallstypesconverters/ConfigurationsApiConverter.ts";

interface ApiDefinition {
  getAllGlobalUserRoles: (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
    notInProjectId?: number,
    hasGlobalRoles?: boolean,
  ) => Promise<QueryModelWithPagination<FrontendGlobalUserTypeWithRoleIds>>;
}

export const useEpcmApiConfigurationRoles = (): ApiDefinition => {
  const getAllGlobalUserRolesConverted = async (
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
    notInProjectId?: number,
    hasGlobalRoles?: boolean,
  ): Promise<QueryModelWithPagination<FrontendGlobalUserTypeWithRoleIds>> => {
    const globalUserRolesData = await getGlobalUserRoles(pageOffset, searchFilter, pageSize, notInProjectId, hasGlobalRoles);
    return {
      data: convertGlobalUserRolesData(globalUserRolesData.data),
      nextPage: globalUserRolesData.nextPage,
      totalCount: globalUserRolesData.totalCount,
    };
  };

  return {
    getAllGlobalUserRoles: getAllGlobalUserRolesConverted,
  };
};
