import { FC, useEffect, useMemo } from "react";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import UserListItemStyles from "./UserListItem.module.css";

import checkIcon from "../../../../../assets/images/tick-white.svg";
import avatarDefault from "../../../../../assets/images/profile-default-avatar-white.svg";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FrontendUserLimitedType } from "../../../../../types/apicallstypes/UsersApiTypes.ts";
import { useEpcmApiUsers } from "../../../../../apicalls/users/useEpcmApiUsers.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { Skeleton } from "@mui/material";

interface UserListItemProps {
  userItem: FrontendUserLimitedType;
  isSelected: boolean;
  onUserToggle: (userId: string) => void;
}

export const UserListItem: FC<UserListItemProps> = ({ userItem, isSelected, onUserToggle }) => {
  const { getUser } = useEpcmApiUsers();

  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const userQuery = useQuery({
    queryKey: [QueryNames.User, userItem.code],
    queryFn: () => getUser(userItem.code),
    enabled: isAuthorized,
  });
  const userData = useMemo(() => {
    return userQuery.data ? (userQuery.data as FrontendUserLimitedType) : undefined;
  }, [userQuery]);

  const isUserSelected = useMemo(() => {
    return userData && isSelected;
  }, [userData, isSelected]);

  const userItemSkeletonLoader = (
    <div className={classNames(UserListItemStyles.userItemContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="circular" height={30} width={30} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
          <Skeleton variant="circular" height={50} width={50} />
          <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.gap05)}>
            <Skeleton variant="rounded" height={16} width={200} />
            <Skeleton variant="rounded" height={16} width={150} />
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="circular" height={20} width={20} />
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <Skeleton variant="rounded" height={0} width={40} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <Skeleton variant="rounded" height={0} width={10} />
      </div>
    </div>
  );

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.User, userItem.code] })
        .then(() => console.log(`In select users for project, in user with id ${userItem.code}, ${QueryNames.User} query canceled`));
    };
  }, [queryClient, userItem]);

  return userData ? (
    <div
      key={userData.code}
      className={classNames(UserListItemStyles.userItemContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}
      onClick={() => onUserToggle(userData?.code)}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames(GlobalStyles.flex1)} />
          <div
            className={classNames(UserListItemStyles.checkIcon, isUserSelected && UserListItemStyles.checkIconSelected, GlobalStyles.centerVertical)}
          >
            {isUserSelected && <img src={checkIcon} alt="check" className={classNames(UserListItemStyles.checkIconImg)} />}{" "}
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
        </div>
        {/*TODO: get avatar from backend*/}
        <div className={classNames(UserListItemStyles.defaultAvatar, GlobalStyles.centerVertical)}>
          <img className={classNames(UserListItemStyles.defaultAvatarImg)} src={avatarDefault} alt="avatar" />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames(UserListItemStyles.nameText)}>{userData.name}</div>
          <div className={classNames(UserListItemStyles.mainText)}>{`CODE: ${userData.code}`}</div>
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
    </div>
  ) : (
    userItemSkeletonLoader
  );
};
