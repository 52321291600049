import { FrontendTimesheetRecordType } from "../apicallstypes/ProjectTimesheetsApiTypes.ts";

export enum ProjectTimesheetsSubMenuButtonTypesEnum {
  timesheets = "timesheets",
  reports = "reports",
  groups = "groups",
  archive = "archive",
}

export enum ProjectTimesheetAnalyticsListButtonTypesEnum {
  groups = "groups",
  timesheetItems = "timesheetItems",
  employees = "employees",
  none = "none", // or you might want to use a string like 'none' depending on the usage
}

export enum ProjectTimesheetAnalyticsEmployeeListButtonTypesEnum {
  employeesInTimesheets = "employeesInTimesheets",
  employeesNotInTimesheets = "employeesNotInTimesheets",
}

export enum TimesheetGroupSingleButtonHoveredTypeEnum {
  add = "add",
}

export enum ProjectTimesheetItemActionTypesEnum {
  viewEdit = "viewEdit",
  download = "download",
}

export enum ProjectTimesheetGroupPopupModeEnum {
  addSection = "addSection",
  view = "view",
  addGroupMember = "addGroupMember",
}

export enum ProjectTimesheetGroupModificationTypeEnum {
  create = "create",
  update = "update",
}

export enum ProjectTimesheetTabStatusEnum {
  pending = "pending",
  reviewed = "reviewed",
  prepared = "prepared",
}

export enum ProjectGroupTimesheetItemActionTypesEnum {
  clone = "clone",
  download = "download",
}

export enum ProjectTimesheetDisplayListTypeEnum {
  groupEmployess = "groupEmployess",
  projectEmployees = "projectEmployees",
}

export enum ProjectGroupTimesheetSettingsActionTypesEnum {
  VIEW = "view",
  addSection = "addSection",
  addGroupMember = "addGroupMember",
  renameGroup = "renameGroup",
  none = "none", // or you might want to use a string like 'none' depending on the usage
}

export enum CtrTooltipOptionHoverEnum {
  clear = "clear",
  delete = "delete",
}

export enum TimesheetFormSubmitActionEnum {
  final = "final",
  draft = "draft",
}

export enum ActionButtonTooltipTextEnum {
  exceedMaxHoursViolation = "CTR worked hour sum cannot exceed the max hours of a day",
  lessThanMinHoursViolation = "There is at least one day without any CTR worked hours",
  noViolation = "",
}

export enum WarningMessagesEnum {
  lessThanMaxHoursDayWarning = " On at least one calendar day, the total CTR worked hours are less than the maximum hours allowed for that day.",
}

export interface HoursPerDayValidationType {
  calendarId: number;
  isInvalid: boolean;
}

// export interface HoursPerCtrValidationType {
//   ctrId: number;
//   isInvalid: boolean;
// }

export interface FrontendMmrCalendarDayMapping {
  [mmrId: number]: {
    [calendarDayId: number]: FrontendTimesheetRecordType;
  };
}

export interface FrontendMmrCalendarDayRecords {
  [calendarDayId: number]: FrontendTimesheetRecordType;
}
