import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import MmrVersionAccordionItemStyles from "./MmrVersionAccItem.module.css";

import GlobalStyles from "../../../../../../../../../assets/css/GlobalStyles.module.css";
import ChangeMmrStatusGrayIcon from "../../../../../../../../../assets/images/mmr-item-change-status-gray.svg";
import MmrChangeStatusBlueIcon from "../../../../../../../../../assets/images/mmr-item-change-status-blue.svg";
import MmrItemStatusIcon from "../../../../../../../../../assets/images/mmr-item-status-icon.svg";
import CloneMmrHoverIcon from "../../../../../../../../../assets/images/clone_mmr_hover_icon.svg";
import MmrItemCloneVersionIcon from "../../../../../../../../../assets/images/mmr-item-clone-version-icon.svg";
import DeleteMmrItemGrayIcon from "../../../../../../../../../assets/images/delete-bin-light-grey.svg";
import DeleteMmrItemRedIcon from "../../../../../../../../../assets/images/delete-bin-red.svg";
import MmrDeleteIcon from "../../../../../../../../../assets/images/delete-bin-dark-grey.svg";
import ActiveMmrIcon from "../../../../../../../../../assets/images/active-mmr-icon.svg";
import PendingMmrIcon from "../../../../../../../../../assets/images/pending-mmr-icon.svg";
import EditMmrHoverIcon from "../../../../../../../../../assets/images/edit-mmr-hover-icon.svg";
import EditMmrIcon from "../../../../../../../../../assets/images/edit-mmr-icon.svg";
import WarningIcon from "../../../../../../../../../assets/images/warning-red-small-icon.svg";

import { format } from "date-fns";

import { ClickAwayListener, Skeleton, Tooltip } from "@mui/material";
import settingsIcon from "../../../../../../../../../assets/images/setting-dots-black.svg";
import MmrDownloadIconBlue from "../../../../../../../../../assets/images/mmr-download-blue-icon.svg";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import MmrRejectedIcon from "../../../../../../../../../assets/images/mmr-rejected-icon.svg";

import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { MmrToolTipItem, ProjectEmployeeDetailsPopupTypeEnum } from "../../../../../../../../../types/projects/ProjectEmployeesTypes.ts";
import {
  FrontendMmrDateStatusEnum,
  FrontendMmrStatusEnum,
  FrontendProjectEmployeeMmrDetailedType,
} from "../../../../../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { useImpersonationStore } from "../../../../../../../../../store/use-impersonation-store.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../../../../use-projects-popups.ts";
import { useEpcmApiProjectEmployee } from "../../../../../../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../../../../types/apicallstypes/queryCommons.ts";
import { capitalizeFirstLetter } from "../../../../../../../../../utils/StringManipulation.ts";
import { EpcmTooltip } from "../../../../../../../../../ui/epcmtooltip/EpcmTooltip.tsx";
import { usePopupState } from "../../../../../../../../../utils/use-popup-state.ts";
import { useResponseAlertPopupStateType } from "../../../../../../../../../utils/use-response-alert-popup-state.ts";
import { DeletePopup } from "../../../../../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { useEpcmApiProjectEmployeeMutations } from "../../../../../../../../../apicalls/projects/projectemployee/mutations/useEpcmApiProjectEmployeeMutations.ts";
import ChangeEntityStatusPopup from "../../../../../../changeentitystatuspopup/ChangeEntityStatusPopup.tsx";
import { ChangeStatusPopupEntityType } from "../../../../../../../../../types/PopupChangeEntityStatusType.ts";
import { useRetrieveUserPermittedActions } from "../../../../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../../../../types/Roles.ts";

type MmrVersionAccordionItemProps = {
  mmrDateStatus: FrontendMmrDateStatusEnum;
  mmrId: number;
  projectId: number;
  employeeId: number;
  setPopupType: Dispatch<SetStateAction<ProjectEmployeeDetailsPopupTypeEnum>>;
  setSelectedMmrId: Dispatch<SetStateAction<number | null>>;
  selectedMmrId: number | null;
  setIsCloned: Dispatch<SetStateAction<boolean>>;
  isEligible: boolean;
  selectedMmrIdType: FrontendMmrDateStatusEnum | null;
  setSelectedMmrIdType: Dispatch<SetStateAction<FrontendMmrDateStatusEnum | null>>;
};

const MMrVersionAccordionItem: FC<MmrVersionAccordionItemProps> = ({
  mmrDateStatus,
  mmrId,
  projectId,
  employeeId,
  setPopupType,
  setSelectedMmrId,
  selectedMmrId,
  setIsCloned,
  isEligible,
  selectedMmrIdType,
  setSelectedMmrIdType,
}) => {
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { getProjectEmployeeMmr } = useEpcmApiProjectEmployee();
  const { useDeleteMmrMutation } = useEpcmApiProjectEmployeeMutations();

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [hoveredOption, setHoveredOption] = useState<MmrToolTipItem>(MmrToolTipItem.NONE);
  const mainDivClasses = useMemo(() => {
    return classNames(
      selectedMmrId === mmrId ? MmrVersionAccordionItemStyles.mmrItemContainerActive : MmrVersionAccordionItemStyles.mmrItemContainer,
      isHovered && mmrId != selectedMmrId && MmrVersionAccordionItemStyles.hoverBackground,
      mmrId !== selectedMmrId && MmrVersionAccordionItemStyles.hoverClass,
    );
  }, [isHovered, mmrId, selectedMmrId]);

  const mmrItemIconClasses = useMemo(() => {
    return classNames(MmrVersionAccordionItemStyles.mmrItemIcon, mmrId === selectedMmrId && MmrVersionAccordionItemStyles.hoverClass);
  }, [mmrId, selectedMmrId]);

  const mmrItemQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployeeMmrItem, projectId, employeeId, mmrId, mmrDateStatus],
    queryFn: () => getProjectEmployeeMmr(projectId, employeeId, mmrId, DetailLevel.DETAILED),
    enabled: isAuthorized,
    select: (data) => data as FrontendProjectEmployeeMmrDetailedType,
  });

  const mmrItemQueryData = useMemo(() => {
    return mmrItemQuery.data ?? undefined;
  }, [mmrItemQuery]);

  const isActive = useMemo(() => {
    return mmrDateStatus == FrontendMmrDateStatusEnum.ACTIVE;
  }, [mmrDateStatus]);

  const isHistory = useMemo(() => {
    return mmrDateStatus == FrontendMmrDateStatusEnum.HISTORY;
  }, [mmrDateStatus]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };
  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const deleteMmrMutation = useDeleteMmrMutation(projectId, employeeId, mmrId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "MMR deleted successfully" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const canEditProjectEmployee = canPerformProjectAction(ProjectAction.ProjectEmployeeUpdate);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeeMmr, projectId, employeeId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectEmployeeMmrItem, projectId, employeeId, mmrId, mmrDateStatus] });
  }, [queryClient, projectId, employeeId, mmrId, mmrDateStatus]);

  const employeeSettingsTooltip = (
    <div
      className={classNames(
        MmrVersionAccordionItemStyles.mmrTooltipContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap075,
      )}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.gap,
          MmrVersionAccordionItemStyles.mmrTooltipText,
          (isActive || mmrItemQueryData?.status == FrontendMmrStatusEnum.APPROVED) && MmrVersionAccordionItemStyles.toolTipItemNotClickable,
          (isActive || mmrItemQueryData?.status == FrontendMmrStatusEnum.APPROVED) && MmrVersionAccordionItemStyles.toolTipNotClickableText,
        )}
        onMouseEnter={() => setHoveredOption(MmrToolTipItem.CHANGE_STATUS)}
        onMouseLeave={() => setHoveredOption(MmrToolTipItem.NONE)}
        onClick={() => {
          if (!(isActive || mmrItemQueryData?.status === FrontendMmrStatusEnum.APPROVED)) {
            onOpenPopup(ProjectsPagePopups.changeEntityStatus, popupHandler);
          }
        }}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img
            className={classNames(MmrVersionAccordionItemStyles.employeeTooltipIconImg)}
            src={
              isActive || mmrItemQueryData?.status == FrontendMmrStatusEnum.APPROVED
                ? ChangeMmrStatusGrayIcon
                : hoveredOption === MmrToolTipItem.CHANGE_STATUS
                  ? MmrChangeStatusBlueIcon
                  : MmrItemStatusIcon
            }
            alt="downloadTemplate-version"
          />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>{"Change Status"}</div>
      </div>
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.gap, MmrVersionAccordionItemStyles.mmrTooltipText)}
        onMouseEnter={() => setHoveredOption(MmrToolTipItem.EDIT)}
        onMouseLeave={() => setHoveredOption(MmrToolTipItem.EDIT)}
        onClick={() => {
          if (mmrItemQueryData) {
            setSelectedMmrId(mmrItemQueryData.id);
            setPopupType(ProjectEmployeeDetailsPopupTypeEnum.edit);
          }
        }}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img
            className={classNames(MmrVersionAccordionItemStyles.employeeTooltipIconImg)}
            src={hoveredOption === MmrToolTipItem.EDIT ? EditMmrHoverIcon : EditMmrIcon}
            alt="edit-version"
          />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>{"Edit Version"}</div>
      </div>
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.gap, MmrVersionAccordionItemStyles.mmrTooltipText)}
        onMouseEnter={() => setHoveredOption(MmrToolTipItem.CLONE)}
        onMouseLeave={() => setHoveredOption(MmrToolTipItem.NONE)}
        onClick={() => {
          if (mmrItemQueryData) {
            setSelectedMmrId(mmrItemQueryData.id);
            setPopupType(ProjectEmployeeDetailsPopupTypeEnum.create);
            setIsCloned(true);
          }
        }}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img
            className={classNames(MmrVersionAccordionItemStyles.employeeTooltipIconImg)}
            src={hoveredOption === MmrToolTipItem.CLONE ? CloneMmrHoverIcon : MmrItemCloneVersionIcon}
            alt="clone-version"
          />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>{"Clone Version"}</div>
      </div>
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.gap,
          MmrVersionAccordionItemStyles.mmrTooltipText,
          (isActive || mmrItemQueryData?.status === FrontendMmrStatusEnum.APPROVED) && MmrVersionAccordionItemStyles.toolTipItemNotClickable,
          (isActive || mmrItemQueryData?.status === FrontendMmrStatusEnum.APPROVED) && MmrVersionAccordionItemStyles.toolTipNotClickableText,
        )}
        onMouseEnter={() => setHoveredOption(MmrToolTipItem.DELETE)}
        onMouseLeave={() => setHoveredOption(MmrToolTipItem.NONE)}
        onClick={() => {
          onOpenUtilPopup();
        }}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img
            className={classNames(MmrVersionAccordionItemStyles.employeeTooltipIconImg)}
            src={
              mmrItemQueryData?.status === FrontendMmrStatusEnum.APPROVED || isActive
                ? DeleteMmrItemGrayIcon
                : hoveredOption === MmrToolTipItem.DELETE
                  ? DeleteMmrItemRedIcon
                  : MmrDeleteIcon
            }
            alt="delete-version"
          />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>{"Delete Version"}</div>
      </div>
    </div>
  );
  useEffect(() => {
    if (selectedMmrId === mmrId) {
      setSelectedMmrIdType(mmrDateStatus);
    }
  }, [selectedMmrId, setSelectedMmrId, mmrDateStatus, mmrId, selectedMmrIdType, setSelectedMmrIdType]);

  useEffect(() => {
    if (mmrItemQueryData && isActive && !selectedMmrId) {
      setSelectedMmrId(mmrItemQueryData.id);
    }
  }, [mmrItemQueryData, isActive, selectedMmrId, setSelectedMmrId]);

  useEffect(() => {
    return () => {
      if (selectedMmrId || selectedMmrId === mmrId) {
        queryClient
          .cancelQueries({ queryKey: [QueryNames.ProjectEmployeeMmrItem, projectId, employeeId, mmrId, mmrDateStatus] })
          .then(() => console.log(`${QueryNames.ProjectEmployeeMmrItem} query canceled`));
      }
    };
  }, [queryClient, employeeId, projectId, mmrId, selectedMmrId, mmrDateStatus]);

  return (
    <>
      {mmrItemQueryData ? (
        <div
          key={`${mmrId} + ${mmrItemQueryData?.id} + ${mmrItemQueryData?.agreementNumber} + ${mmrItemQueryData.revisionNumber}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => {
            if (mmrItemQueryData) {
              setSelectedMmrId(mmrItemQueryData.id);
            }
          }}
          className={mainDivClasses}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
            <div className={classNames(MmrVersionAccordionItemStyles.mmrItemIcon)}>
              <div className={classNames(MmrVersionAccordionItemStyles.mmrStatusFileIcon)}>
                {isActive || mmrItemQueryData.status === FrontendMmrStatusEnum.APPROVED ? (
                  <img src={ActiveMmrIcon} alt="active" />
                ) : mmrItemQueryData.status == FrontendMmrStatusEnum.PENDING ? (
                  <img src={PendingMmrIcon} alt="pending" />
                ) : mmrItemQueryData.status == FrontendMmrStatusEnum.REJECTED ? (
                  <img src={MmrRejectedIcon} alt="rejected" />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.flex1)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
                <div className={classNames(MmrVersionAccordionItemStyles.dateContainer)}>{`Version ${mmrItemQueryData.revisionNumber}`}</div>
                {!isEligible && (
                  <Tooltip title={"Non Eligible because no Actual Mobilization Date exists"} arrow placement={"right-end"}>
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, MmrVersionAccordionItemStyles.warningIcon)}>
                      <img src={WarningIcon} alt={"non eligible icon"}></img>
                    </div>
                  </Tooltip>
                )}
              </div>
              {isHistory && (
                <div className={classNames(MmrVersionAccordionItemStyles.statusText, MmrVersionAccordionItemStyles.isHistory)}>
                  {capitalizeFirstLetter(mmrItemQueryData.status ?? "")}
                </div>
              )}
              {!isHistory && (
                <div
                  className={
                    mmrItemQueryData.status === FrontendMmrStatusEnum.APPROVED
                      ? classNames(MmrVersionAccordionItemStyles.statusText, MmrVersionAccordionItemStyles.isActive)
                      : mmrItemQueryData.status === FrontendMmrStatusEnum.PENDING
                        ? classNames(MmrVersionAccordionItemStyles.statusText, MmrVersionAccordionItemStyles.isPending)
                        : classNames(MmrVersionAccordionItemStyles.statusText, MmrVersionAccordionItemStyles.isRejected)
                  }
                >
                  {capitalizeFirstLetter(mmrItemQueryData.status ?? "")}
                </div>
              )}
              <div
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.gap05,
                  MmrVersionAccordionItemStyles.dateContainer,
                )}
              >
                {mmrItemQueryData && mmrItemQueryData.anticipatedMobilizationDate ? (
                  <div>{format(new Date(mmrItemQueryData.anticipatedMobilizationDate), "dd/MM/yyyy")}</div>
                ) : (
                  <div>{"No Info"}</div>
                )}
                <div>-</div>
                {mmrItemQueryData && mmrItemQueryData.anticipatedDemobilizationDate ? (
                  <div>{format(new Date(mmrItemQueryData.anticipatedDemobilizationDate), "dd/MM/yyyy")}</div>
                ) : (
                  <div>{"No Info"}</div>
                )}
              </div>
            </div>
            <div className={mmrItemIconClasses}>
              {!isHistory && canEditProjectEmployee && (
                <EpcmTooltip
                  PopperProps={{
                    disablePortal: false,
                  }}
                  onClose={handleTooltipClose}
                  open={isTooltipOpen}
                  disableFocusListener
                  disableTouchListener
                  disableHoverListener
                  title={employeeSettingsTooltip}
                  arrow
                  placement={"bottom-end"}
                >
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <div
                        className={classNames(
                          MmrVersionAccordionItemStyles.mmrStatusFileIcon,
                          GlobalStyles.centerHorizontal,
                          GlobalStyles.centerVertical,
                        )}
                        onClick={handleTooltipOpen}
                      >
                        <img src={settingsIcon} alt="settings" />
                      </div>
                    </ClickAwayListener>
                  </div>
                </EpcmTooltip>
              )}
              {isHistory && (
                <div
                  className={classNames(MmrVersionAccordionItemStyles.mmrStatusFileIcon, GlobalStyles.centerHorizontal, GlobalStyles.centerVertical)}
                >
                  <img src={MmrDownloadIconBlue} alt="downloadTemplate" />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Skeleton width={300} height={70} variant={"rectangular"}></Skeleton>
      )}

      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteMmrMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              setSelectedMmrId(null);
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      {popupHandler.get(ProjectsPagePopups.changeEntityStatus)!.isOpen && mmrItemQueryData && (
        <ChangeEntityStatusPopup
          entityInfo={{
            type: ChangeStatusPopupEntityType.MMR,
            projectId: projectId,
            projectEmployeeId: employeeId,
            mmrId: mmrId,
          }}
          //currentStatus={mmrItemQueryData.status!}
          isOpen={popupHandler.get(ProjectsPagePopups.changeEntityStatus)!.isOpen}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.changeEntityStatus, popupHandler);
          }}
          secondaryHeaderText={`Employee : ${employeeId}`}
          headerText={popupHeaders.get(ProjectsPagePopups.changeEntityStatus)}
        />
      )}
    </>
  );
};

export default MMrVersionAccordionItem;
