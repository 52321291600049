import { globalConfigurationRowItemType, GlobalConfigurationScreenType } from "../../../../types/settings/GlobalConfigurationTypes.ts";
import {
  FrontendGlobalCategoryType,
  FrontendGlobalCtrType,
  FrontendGlobalDisciplineType,
  FrontendGlobalLocationType,
  FrontendGlobalPositionType,
  FrontendGlobalTradeType,
} from "../../../../types/apicallstypes/ConfigurationsTypes.ts";
import GlobalConfigurationLocationsRow from "../globalconfigurationlocations/globalconfigurationlocationsrow/GlobalConfigurationLocationsRow.tsx";
import GlobalConfigurationPositionsRow from "../globalconfigurationspositions/globalconfigurationpositionsrow/GlobalConfigurationPositionsRow.tsx";
import GlobalConfigurationTradesRow from "../globalconfigurationtrades/globalconfigurationtradesrow/GlobalConfigurationTradesRow.tsx";
import GlobalConfigurationCategoriesRow from "../globalconfigurationcategories/globalconfigurationcategoriesrow/GlobalConfigurationCategoriesRow.tsx";
import GlobalConfigurationDisciplinesRow from "../globalconfigurationsdisciplines/configurationdisciplinesrow/GlobalConfigurationDisciplinesRow.tsx";
import GlobalConfigurationCtrLibraryRow from "../globalconfigurationctrlibrary/configurationctrlibraryrow/GlobalConfigurationCtrLibraryRow.tsx";
import { FrontendGlobalUserTypeWithRoleIds } from "../../../../types/projects/PermissionTypes.ts";
import GlobalConfigurationUserPermissionRow from "../globalconfigurationpermissions/globalconfigurationuserpermisssionsrow/GlobalConfigurationUserPermissionRow.tsx";
import { FrontendRoleNormal } from "../../../../types/apicallstypes/UsersApiTypes.ts";

type GlobalTableRowRendererProps = {
  item: globalConfigurationRowItemType;
  roles?: FrontendRoleNormal[]; // Allows passing additional props
};

const GlobalConfigurationTableRowRenderer = {
  [GlobalConfigurationScreenType.locations]: ({ item }: GlobalTableRowRendererProps) => (
    <GlobalConfigurationLocationsRow key={item.id} globalLocationItem={item as FrontendGlobalLocationType} />
  ),
  [GlobalConfigurationScreenType.positions]: ({ item }: GlobalTableRowRendererProps) => (
    <GlobalConfigurationPositionsRow key={item.id} globalPositionItem={item as FrontendGlobalPositionType} />
  ),
  [GlobalConfigurationScreenType.trades]: ({ item }: GlobalTableRowRendererProps) => (
    <GlobalConfigurationTradesRow key={item.id} tradeItem={item as FrontendGlobalTradeType} />
  ),
  [GlobalConfigurationScreenType.categories]: ({ item }: GlobalTableRowRendererProps) => (
    <GlobalConfigurationCategoriesRow key={item.id} globalCategoryItem={item as FrontendGlobalCategoryType} />
  ),
  [GlobalConfigurationScreenType.disciplines]: ({ item }: GlobalTableRowRendererProps) => (
    <GlobalConfigurationDisciplinesRow key={item.id} globalDisciplineItem={item as FrontendGlobalDisciplineType} />
  ),
  [GlobalConfigurationScreenType.ctrLibrary]: ({ item }: GlobalTableRowRendererProps) => (
    <GlobalConfigurationCtrLibraryRow key={item.id} globalCtrItem={item as FrontendGlobalCtrType} />
  ),
  [GlobalConfigurationScreenType.permissions]: ({ item, roles }: GlobalTableRowRendererProps) => (
    <GlobalConfigurationUserPermissionRow key={item.id} globalUserItem={item as FrontendGlobalUserTypeWithRoleIds} roles={roles!} />
  ),
};

export default GlobalConfigurationTableRowRenderer;
