import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../config.ts";
import { FrontendGlobalSubPositionType } from "../../types/apicallstypes/ConfigurationsTypes.ts";

const URL = `${EPCM_API_URLS.configurations}`;

export const getAllGlobalSubpositions = async (positionId: number): Promise<FrontendGlobalSubPositionType[]> => {
  const config: AxiosRequestConfig = {
    url: `${URL}/positions/${positionId}/sub-positions`,
    method: "GET",
  };

  const response = await epcmAxiosInstance(config);

  return response.data satisfies FrontendGlobalSubPositionType[];
};
