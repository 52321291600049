import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import TimesheetGroupSetNewTimesheetPopupStyles from "./TimesheetGroupSetNewTimesheetPopupStyles.module.css";
import classNames from "classnames";
import { UiComponentGeneralEnum } from "../../../../types/UiComponentGeneralType.ts";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjectTimesheetCalendarMonths } from "../../../../apicalls/projects/projectcalendar/useEpcmApiProjectTimesheetCalendarMonths.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../apicalls/config.ts";
import { useInView } from "react-intersection-observer";
import InfiniteScrollInViewElement from "../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { Fade, FormControl, MenuItem, SelectChangeEvent, Tooltip } from "@mui/material";

import { EpcmSelect } from "../../../../ui/epcmselect/EpcmSelect.tsx";

import { AntSwitch } from "../../../../ui/epcmswitch/EpcmSwitch.tsx";
import { ProjectTimesheetDisplayListTypeEnum } from "../../../../types/projects/ProjectTimesheetsTypes.ts";
import GroupTimesheetVersatileList from "../timesheetgroupsettingspopup/grouptimesheetversatilelist/GroupTimesheetVersatileList.tsx";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../utils/use-popup-state.ts";

import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { ApiRequestBodyTimesheetCreate } from "epcm-common/dist/Types/TimesheetTypes";
import { getNextDay } from "../../../../utils/NextDay.ts";
import { convertDateToMilSecs, formatDate, MuiDateFormatEnum } from "../../../../utils/DateManipulation.ts";
import { SearchBar } from "../../../../ui/searchbar/SearchBar.tsx";
import SearchInputStyles from "../../../../ui/searchbar/SearchInputStyles.module.css";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import { EpcmFormControl } from "../../../../ui/epcmformcontrol/EpcmFormControl.tsx";
import EpcmDatePickerMui from "../../../../ui/epcmdatepickermui/EpcmDatepickerMui.tsx";

interface TimesheetGroupSetNewTimesheetPopupProps extends PopupType {
  projectId: number;
  groupId: number;
}

const TimesheetGroupSetNewTimesheetPopup: FC<TimesheetGroupSetNewTimesheetPopupProps> = ({
  isOpen,
  closeFn,
  headerText,
  secondaryHeaderText,
  projectId,
  groupId,
}) => {
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const [searchMember, setSearchMember] = useState<string>("");
  const [newGroupMembers, setNewGroupMembers] = useState<number[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [deadlineDate, setDeadlineDate] = useState<Date | null>(null);
  const { getAllProjectTimesheetCalendarMonths } = useEpcmApiProjectTimesheetCalendarMonths();
  const { getProjectTimesheetMonthPeriods } = useEpcmApiProjectTimesheetCalendarMonths();
  const [selectedStartDateIndex, setSelectedStartDateIndex] = useState("");

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { useCreateProjectTimesheetMutation } = useEpcmApiProjectTimesheetsGroupsMutations();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const onChangeStartDate = (event: SelectChangeEvent<unknown>) => {
    const selectedIndex = event.target.value as string; // Explicitly casting to string

    const index = parseInt(selectedIndex, 10);
    if (!isNaN(index) && index >= 0 && index < availableDates.length) {
      setSelectedStartDateIndex(selectedIndex);
      setStartDate(availableDates[index]);
    }
  };

  const createProjectTimesheetMutation = useCreateProjectTimesheetMutation(projectId, groupId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Timesheet created successfully" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onCreateProjectTimesheet = useCallback(() => {
    createProjectTimesheetMutation.mutate({
      startDate: convertDateToMilSecs(startDate!),
      deadlineDate: convertDateToMilSecs(deadlineDate!),
      projectEmployeesIds: selectAll ? undefined : newGroupMembers,
    } satisfies ApiRequestBodyTimesheetCreate);
    onOpenLoaderPopup();
  }, [createProjectTimesheetMutation, newGroupMembers, selectAll, startDate, deadlineDate, onOpenLoaderPopup]);

  const allTimesheetCalendarMonthsQuery = useInfiniteQuery({
    queryKey: [QueryNames.TimesheetCalendarMonths, projectId, groupId],
    queryFn: ({ pageParam }) => getAllProjectTimesheetCalendarMonths(projectId, groupId, PAGINATION_PAGE_SIZE, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const allTimesheetCalendarMonthsData = useMemo(
    () => allTimesheetCalendarMonthsQuery.data?.pages.flatMap((page) => page.data),
    [allTimesheetCalendarMonthsQuery.data],
  );

  const timesheetCalendarMonthPeriodsQuery = useQuery({
    queryKey: [QueryNames.TimesheetCalendarMonthPeriods, projectId, groupId, selectedMonth],
    queryFn: () => getProjectTimesheetMonthPeriods(projectId, groupId, selectedMonth!),
    enabled: isAuthorized && !!selectedMonth,
  });
  const timesheetCalendarMonthPeriodsData = useMemo(() => timesheetCalendarMonthPeriodsQuery.data, [timesheetCalendarMonthPeriodsQuery.data]);

  const onChangeSelectedMonth = (event: SelectChangeEvent<unknown>) => {
    const selectedMonthValue = event.target.value as string; // Explicitly casting to string
    setSelectedMonth(parseInt(selectedMonthValue, 10));
    setSelectedStartDateIndex("");
    setStartDate(null);
    setDeadlineDate(null);
    setSelectAll(false);
  };

  const toggleSelectAll = () => {
    setSelectAll((currentState) => {
      if (currentState) {
        setNewGroupMembers([]);
      }
      return !currentState;
    });
  };

  const endDate = useMemo(() => {
    if (startDate) {
      const matchingPeriod = timesheetCalendarMonthPeriodsData?.periods.find((period) => period.periodStart.getDate() === startDate.getDate());

      return matchingPeriod ? new Date(matchingPeriod.periodEnd) : null;
    }

    return null; // Return null if startDate is not set
  }, [startDate, timesheetCalendarMonthPeriodsData]);

  const deadlineMinDate = useMemo(() => {
    const currentDate = new Date();
    if (!startDate) {
      return null;
    }
    const matchingPeriod = timesheetCalendarMonthPeriodsData?.periods.find((period) => period.periodStart.getDate() === startDate.getDate());

    if (matchingPeriod) {
      if (matchingPeriod.periodEnd < currentDate) {
        return currentDate;
      } else if (matchingPeriod.periodEnd >= currentDate) {
        return getNextDay(matchingPeriod.periodEnd);
      }
    }

    return null;
  }, [startDate, timesheetCalendarMonthPeriodsData]);

  const availableDates = useMemo(() => {
    return timesheetCalendarMonthPeriodsData?.periods.map((period) => new Date(period.periodStart)) || [];
  }, [timesheetCalendarMonthPeriodsData]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.TimesheetCalendarMonths, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.TimesheetCalendarMonthPeriods, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId.toString(), groupId.toString()] });
    void queryClient.resetQueries({ queryKey: [QueryNames.ProjectTimesheets, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroupTimesheets, projectId.toString(), groupId.toString()] });
  }, [queryClient, projectId, groupId]);

  const hideEmployeesList = useMemo(() => {
    if (!startDate || !deadlineDate || !selectedMonth) {
      return true;
    }
  }, [startDate, deadlineDate, selectedMonth]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.TimesheetCalendarMonths, projectId, groupId] })
        .then(() => console.log(`In set new timesheet popup, ${QueryNames.TimesheetCalendarMonths} query canceled`));
    };
  }, [queryClient, projectId, groupId]);

  useEffect(() => {
    return () => {
      selectedMonth &&
        queryClient
          .cancelQueries({ queryKey: [QueryNames.TimesheetCalendarMonthPeriods, projectId, groupId, selectedMonth] })
          .then(() => console.log(`In set new timesheet popup, ${QueryNames.TimesheetCalendarMonthPeriods} query canceled`));
    };
  }, [queryClient, projectId, groupId, selectedMonth]);

  useEffect(() => {
    if (
      inView &&
      !allTimesheetCalendarMonthsQuery.isLoading &&
      !allTimesheetCalendarMonthsQuery.isFetching &&
      !allTimesheetCalendarMonthsQuery.isFetchingNextPage &&
      allTimesheetCalendarMonthsQuery.hasNextPage
    ) {
      void allTimesheetCalendarMonthsQuery.fetchNextPage();
    }
  }, [inView, allTimesheetCalendarMonthsQuery]);

  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel",
          buttonType: PopupButtonTypeEnum.neutral,
          action: closeFn,
        },
        {
          text: "Create Timesheet",
          buttonType: PopupButtonTypeEnum.main,
          tooltipText: !selectedMonth || !startDate || !deadlineDate || (!newGroupMembers.length && !selectAll) ? "Mandatory fields missing" : "",
          disabled: !selectedMonth || !startDate || !deadlineDate || (!newGroupMembers.length && !selectAll),
          action: onCreateProjectTimesheet,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      overrideContentContainerStyleClass={TimesheetGroupSetNewTimesheetPopupStyles.popupContainer}
    >
      <div className={classNames(GlobalStyles.flex, TimesheetGroupSetNewTimesheetPopupStyles.mainContainer, GlobalStyles.overflowHiddenFullHeight)}>
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap15,
            TimesheetGroupSetNewTimesheetPopupStyles.contentContainer,
          )}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
              <div className={classNames(TimesheetGroupSetNewTimesheetPopupStyles.infoText)}>Month concerned</div>
              <EpcmFormControl
                fullWidth
                variant="standard"
                aria-placeholder={"Select month"}
                className={TimesheetGroupSetNewTimesheetPopupStyles.calendarInput}
              >
                <EpcmSelect
                  labelId="month-select-label"
                  id="month-select"
                  displayEmpty
                  value={selectedMonth || ""}
                  className={classNames(TimesheetGroupSetNewTimesheetPopupStyles.selectOption)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 150, // Set the maximum height here
                      },
                    },
                  }}
                  renderValue={(selected: unknown) => {
                    if (!selected) {
                      return <div className={classNames(TimesheetGroupSetNewTimesheetPopupStyles.subText)}>Select month</div>;
                    }
                    return <div className={classNames(TimesheetGroupSetNewTimesheetPopupStyles.selectOption)}>{`M + ${selected as number}`}</div>;
                  }}
                  placeholder={"Select month"}
                  onChange={onChangeSelectedMonth}
                  disableUnderline
                >
                  {allTimesheetCalendarMonthsData?.map((month) => (
                    <MenuItem key={month.id} value={month.monthNumber}>
                      {`M + ${month.monthNumber} (${month.displayName})`}
                    </MenuItem>
                  ))}
                  <InfiniteScrollInViewElement reference={ref} infiniteQueryResult={allTimesheetCalendarMonthsQuery} loaderComponent={<></>} />
                </EpcmSelect>
              </EpcmFormControl>
            </div>
          </div>
          <div
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.gap,
              TimesheetGroupSetNewTimesheetPopupStyles.fullWidth,
            )}
          >
            <div
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap2,
                TimesheetGroupSetNewTimesheetPopupStyles.halfWidth,
              )}
            >
              <Tooltip title={selectedMonth ? "" : "Select Project Month first"} placement={"bottom-end"} TransitionComponent={Fade} arrow>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
                  <div className={classNames(TimesheetGroupSetNewTimesheetPopupStyles.infoText)}>Start Date</div>
                  <FormControl
                    fullWidth
                    variant="standard"
                    aria-placeholder={"Select month"}
                    className={TimesheetGroupSetNewTimesheetPopupStyles.calendarInput}
                    sx={{
                      // Targeting the focused state of the input within FormControl
                      "& .MuiInputBase-input:focus": {
                        backgroundColor: "#fff",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#fff",
                      },
                      "& .Mui-focused": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <EpcmSelect
                      labelId="start-date-select-label"
                      id="start-date-select"
                      displayEmpty
                      disabled={!selectedMonth}
                      value={selectedStartDateIndex}
                      onChange={onChangeStartDate}
                      renderValue={(selected) => {
                        // Check if the selected index is a number and within range
                        if (typeof selected === "number" && selected >= 0) {
                          return formatDate(availableDates[selected]);
                        }
                        return "Select Start Date";
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 150,
                          },
                        },
                      }}
                      disableUnderline
                    >
                      {availableDates.map((date, index) => (
                        <MenuItem key={index} value={index}>
                          {formatDate(date)}
                        </MenuItem>
                      ))}
                    </EpcmSelect>
                  </FormControl>
                </div>
              </Tooltip>
            </div>
            <div
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap2,
                TimesheetGroupSetNewTimesheetPopupStyles.halfWidth,
              )}
            >
              <Tooltip title={""} placement={"bottom-end"} TransitionComponent={Fade} arrow>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
                  <div className={classNames(TimesheetGroupSetNewTimesheetPopupStyles.infoText)}>End Date</div>
                  <div
                    className={classNames(
                      TimesheetGroupSetNewTimesheetPopupStyles.calendarInput,
                      TimesheetGroupSetNewTimesheetPopupStyles.disabledInput,
                    )}
                  >
                    {endDate ? (
                      <div className={classNames(TimesheetGroupSetNewTimesheetPopupStyles.selectedOption)}>{formatDate(endDate)}</div>
                    ) : (
                      <div className={classNames(TimesheetGroupSetNewTimesheetPopupStyles.disabledInputEmptyText)}>{"--"}</div>
                    )}
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
            <Tooltip
              title={selectedMonth ? (startDate ? "" : "Select Start Date first") : "Select project month first"}
              placement={"bottom-end"}
              TransitionComponent={Fade}
              arrow
            >
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
                <EpcmDatePickerMui
                  theme={UiComponentGeneralEnum.THIRD}
                  minDate={deadlineMinDate ?? undefined}
                  disabled={!selectedMonth || !startDate}
                  label={"Deadline Date"}
                  date={deadlineDate ?? null}
                  setDate={setDeadlineDate}
                  placeholder={"Select a deadline date"}
                  formatType={MuiDateFormatEnum.DD_MMM_COMMA_YYYY}
                />
              </div>
            </Tooltip>
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
            <AntSwitch
              checked={selectAll}
              onClick={toggleSelectAll}
              inputProps={{ "aria-label": "ant design" }}
              disabled={!selectedMonth || !startDate || !deadlineDate}
            />
            <div className={classNames(TimesheetGroupSetNewTimesheetPopupStyles.selectAllText)}>It concerns all group employees</div>
          </div>
          {!selectAll &&
            (!hideEmployeesList ? (
              <div
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.flexDirectionColumn,
                  GlobalStyles.gap,
                  TimesheetGroupSetNewTimesheetPopupStyles.overflowContainer,
                )}
              >
                <SearchBar
                  setSearchQuery={setSearchMember}
                  placeholder={"Search for Employee"}
                  searchBarContainerStyles={classNames(
                    GlobalStyles.flex,
                    GlobalStyles.centerHorizontal,
                    GlobalStyles.gap05,
                    SearchInputStyles.searchInputContainer,
                  )}
                />
                <div className={classNames(TimesheetGroupSetNewTimesheetPopupStyles.overflowContainer)}>
                  <GroupTimesheetVersatileList
                    inSettings={false}
                    newGroupMembers={newGroupMembers}
                    ungrouped={false}
                    groupId={groupId}
                    setNewGroupMembers={setNewGroupMembers}
                    projectId={projectId}
                    searchMember={searchMember}
                    listType={ProjectTimesheetDisplayListTypeEnum.groupEmployess}
                    periodStart={startDate ?? undefined}
                    periodEnd={endDate ?? undefined}
                  />
                </div>
              </div>
            ) : (
              <div
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.centerVertical,
                  TimesheetGroupSetNewTimesheetPopupStyles.infoText,
                  TimesheetGroupSetNewTimesheetPopupStyles.overflowContainer,
                )}
              >
                {`Select month, start date and deadline date to see the list of group employees with Active MMR`}
              </div>
            ))}
        </div>
        {isResponseAlertPopupOpen && responseType && responseObject && (
          <ResponseAlertPopup
            responseType={responseType}
            responseObject={responseObject}
            isOpen={isResponseAlertPopupOpen}
            closeFn={() => {
              initializeResponseAlertPopup();
              onCloseResponseAlertPopup();
              responseType === ApiResponseTypeEnum.success && invalidateQueries();
              setSelectedStartDateIndex("");
              setStartDate(null);
              setDeadlineDate(null);
              setSelectAll(false);
              setNewGroupMembers([]);
              closeFn();
            }}
          />
        )}
        {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      </div>
    </PopupStructure>
  );
};
export default TimesheetGroupSetNewTimesheetPopup;
