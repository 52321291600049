import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import TimesheetListItemStyles from "./TimesheetListItemStyles.module.css";
import classNames from "classnames";
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import TimesheetGroupListItemPendingGrayIcon from "../../../../../../../assets/images/timesheet-group-list-item-pending-gray-icon.svg";
import TimesheetGroupListItemReviewedIcon from "../../../../../../../assets/images/timesheet-group-list-item-reviewed-icon.svg";
import TimesheetGroupListItemPreparedIcon from "../../../../../../../assets/images/timesheet-group-list-item-prepared-icon.svg";
import arrowDownBlue from "../../../../../../../assets/images/arrow-down-blue-fill.svg";

import {
  FrontendGroupsTimesheetModeEnum,
  FrontendTimesheetLimitedType,
  FrontendTimesheetStatusEnum,
  FrontendTimesheetType,
} from "../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { DateFormatEnum, formatDate, getTimeFromDate, isCurrentDate } from "../../../../../../../utils/DateManipulation.ts";
import { capitalizeFirstLetter } from "../../../../../../../utils/StringManipulation.ts";
import { Fade, Skeleton, Tooltip } from "@mui/material";
import DownloadMmrGreenIcon from "../../../../../../../assets/images/download-mmr-green-icon.svg";
import DownloadIconBlue from "../../../../../../../assets/images/project_employee_download_icon.svg";
import clockIcon from "../../../../../../../assets/images/clock-gray.svg";
import TimesheetListItemEditIcon from "../../../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TimesheetListItemViewIcon from "../../../../../../../assets/images/timesheet-list-item-view-icon.svg";
import TimesheetListItemViewHoverIcon from "../../../../../../../assets/images/timesheet-list-item-view-hover-icon.svg";
import TimesheetListItemEditHoverIcon from "../../../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import { ProjectTimesheetItemActionTypesEnum } from "../../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import { useParams } from "react-router-dom";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ApiResponseTypeEnum, QueryNames, SubQueryNames } from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useEpcmApiUsers } from "../../../../../../../apicalls/users/useEpcmApiUsers.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../../use-projects-popups.ts";
import ChangeEntityStatusPopup from "../../../../../popups/changeentitystatuspopup/ChangeEntityStatusPopup.tsx";
import { ChangeStatusPopupEntityType } from "../../../../../../../types/PopupChangeEntityStatusType.ts";
import { AppRoutesPaths } from "../../../../../../../types/AppRoutesTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../../types/Roles.ts";
import { useHandleUnauthorized } from "../../../../../../../utils/use-handle-unauthorized.ts";
import { useNotificationContext } from "../../../../../../../ui/globalnotificationcontext/GlobalNotificationContext.ts";
import { useResponseAlertPopupStateType } from "../../../../../../../utils/use-response-alert-popup-state.ts";
import useProcessFileEvents from "../../../../../../../utils/useProcessFileEvents.ts";
import { FrontendFileAction } from "../../../../../../../types/projects/FileTypes.ts";
import { useEpcmApiProjectsTimesheets } from "../../../../../../../apicalls/projects/projecttimesheets/useEpcmApiProjectsTimesheets.ts";
import { ResponseAlertPopup } from "../../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";

interface PendingTimesheetListItemProps {
  timesheetItem: FrontendTimesheetLimitedType;
  keepCurrentPeriodOnly: boolean;
}

const TimesheetListItem: FC<PendingTimesheetListItemProps> = ({ timesheetItem, keepCurrentPeriodOnly }) => {
  const { projectId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getTimesheetGroupTimesheet } = useEpcmApiProjectsTimesheetsGroups();
  const { getUser } = useEpcmApiUsers();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const { handleErrorRedirect } = useHandleUnauthorized();
  const { addNotification, updateNotification } = useNotificationContext();
  const { getTimesheetFileExported } = useEpcmApiProjectsTimesheets();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { processFileEvents: processFileEventsTimesheetFile } = useProcessFileEvents(
    FrontendFileAction.EXPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
  );

  const canChangeTimesheetStatus = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatus);
  const canListTimesheets = canPerformProjectAction(ProjectAction.ProjectTimesheetListList);
  const canFillTimesheetsData = canPerformProjectAction(ProjectAction.ProjectTimesheetFillData);

  const [actionHovered, setActionHovered] = useState<ProjectTimesheetItemActionTypesEnum | null>(null);

  const projectTimesheetQuery = useQuery({
    queryKey: [
      QueryNames.ProjectTimesheetGroupTimesheets,
      projectId,
      timesheetItem.timesheetGroupId.toString(),
      timesheetItem.id,
      keepCurrentPeriodOnly,
    ],
    queryFn: () =>
      getTimesheetGroupTimesheet(parseInt(projectId!), timesheetItem.timesheetGroupId, timesheetItem.id, DetailLevel.NORMAL).catch(
        handleErrorRedirect,
      ),

    enabled: isAuthorized,
    select: (data) => data as FrontendTimesheetType,
  });

  const projectTimesheetData = useMemo(() => {
    return projectTimesheetQuery.data ?? undefined;
  }, [projectTimesheetQuery]);

  const reviewerUserQuery = useQuery({
    queryKey: [QueryNames.Users, projectTimesheetData?.reviewer?.user.code],
    queryFn: () => getUser(projectTimesheetData!.reviewer!.user.code).catch(handleErrorRedirect),
    enabled: isAuthorized && !!projectTimesheetData && !!projectTimesheetData.reviewer,
  });

  const reviewerUserData = useMemo(() => {
    return reviewerUserQuery.data ?? undefined;
  }, [reviewerUserQuery]);

  const submitterUserQuery = useQuery({
    queryKey: [QueryNames.Users, projectTimesheetData?.submitter?.user.code],
    queryFn: () => getUser(projectTimesheetData!.submitter!.user.code),

    enabled: isAuthorized && !!projectTimesheetData && !!projectTimesheetData.submitter,
  });

  const submitterUserData = useMemo(() => {
    return submitterUserQuery.data ?? undefined;
  }, [submitterUserQuery]);

  const isPending = useMemo(() => {
    return projectTimesheetData?.status === FrontendTimesheetStatusEnum.PENDING;
  }, [projectTimesheetData]);

  const isReviewed = useMemo(() => {
    return projectTimesheetData?.status === FrontendTimesheetStatusEnum.REVIEWED;
  }, [projectTimesheetData]);

  const isPrepared = useMemo(() => {
    return projectTimesheetData?.status === FrontendTimesheetStatusEnum.PREPARED;
  }, [projectTimesheetData]);

  const startFileEventsExportListener = async (fileId: string) => {
    await processFileEventsTimesheetFile(fileId);
  };

  const downloadTimesheetFile = useCallback(async () => {
    getTimesheetFileExported(parseInt(projectId!), timesheetItem.timesheetGroupId, timesheetItem.id)
      .then((value) => {
        startFileEventsExportListener(value.fileId);
      })
      .catch((error) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      });
  }, [
    getTimesheetFileExported,
    projectId,
    timesheetItem,
    startFileEventsExportListener,
    setResponseType,
    setResponseObject,
    onOpenResponseAlertPopup,
  ]);

  const navigateToUrl = useMemo(() => {
    if (isReviewed && canListTimesheets) {
      return `/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${AppRoutesPaths.projectSingleTimesheetsGroups}/${timesheetItem.timesheetGroupId}?status=${FrontendTimesheetStatusEnum.REVIEWED}&mode=${FrontendGroupsTimesheetModeEnum.VIEW}&timesheetId=${timesheetItem.id}`;
    } else if (canFillTimesheetsData) {
      return `/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${AppRoutesPaths.projectSingleTimesheetsGroups}/${timesheetItem.timesheetGroupId}?status=${FrontendTimesheetStatusEnum.PENDING}&mode=${FrontendGroupsTimesheetModeEnum.EDIT}&timesheetId=${timesheetItem.id}`;
    } else {
      return `/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}/${AppRoutesPaths.projectSingleTimesheetsGroups}/${timesheetItem.timesheetGroupId}?status=${FrontendTimesheetStatusEnum.PENDING}&mode=${FrontendGroupsTimesheetModeEnum.VIEW}&timesheetId=${timesheetItem.id}`;
    }
  }, [isReviewed, projectId, timesheetItem.id, timesheetItem.timesheetGroupId]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({
          queryKey: [
            QueryNames.ProjectTimesheetGroupTimesheets,
            projectId,
            timesheetItem.timesheetGroupId.toString(),
            timesheetItem.id,
            keepCurrentPeriodOnly,
          ],
        })
        .then(() =>
          console.log(`In timesheet group timesheet with id ${timesheetItem.id}, ${QueryNames.ProjectTimesheetGroupTimesheets} query canceled`),
        );
    };
  }, [queryClient, projectId, timesheetItem.timesheetGroupId, timesheetItem.id, keepCurrentPeriodOnly]);

  return (
    <div
      className={classNames(
        TimesheetListItemStyles.timesheetListItemContainer,
        isPending && TimesheetListItemStyles.timesheetListItemContainerPending,
        GlobalStyles.flex,
        GlobalStyles.gap025,
      )}
    >
      <div
        className={classNames(
          TimesheetListItemStyles.timesheetListItemTimesheetBaseInfoSection,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.gap,
          GlobalStyles.centerHorizontal,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img
              className={classNames(TimesheetListItemStyles.timesheetListItemDocIconImg)}
              src={
                isReviewed
                  ? TimesheetGroupListItemReviewedIcon
                  : isPrepared
                    ? TimesheetGroupListItemPreparedIcon
                    : TimesheetGroupListItemPendingGrayIcon
              }
              alt="pending-timesheet-list-item-icon"
            />
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
          <div className={classNames()}>{"Timesheet"}</div>
          <div className={classNames(GlobalStyles.flex1)} />
          {projectTimesheetQuery.isLoading && <Skeleton variant={"rounded"} height={16} width={200} />}
          <div className={classNames(TimesheetListItemStyles.detailsText)}>{projectTimesheetData?.timesheetGroup.name}</div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.centerVertical, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames()}>{"Status"}</div>
          <div className={classNames(GlobalStyles.flex1)} />
          {projectTimesheetData && (
            <div
              className={classNames(
                TimesheetListItemStyles.detailsText,
                isReviewed
                  ? TimesheetListItemStyles.approvalStatusApproved
                  : isPending
                    ? TimesheetListItemStyles.approvalStatusPending
                    : isPrepared
                      ? TimesheetListItemStyles.approvalStatusPrepared
                      : undefined,
              )}
            >
              {capitalizeFirstLetter(projectTimesheetData.status)}
            </div>
          )}
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        {canChangeTimesheetStatus && (
          <div // TODO: REVERT TO BEING ABLE TO CHANGE STATUS ONLY IN PREPARED-PENDING IN THE FUTURE
            className={classNames(TimesheetListItemStyles.changeStatusButton, GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
            onClick={() => onOpenPopup(ProjectsPagePopups.changeEntityStatus, popupHandler)}
          >
            <div className={classNames(TimesheetListItemStyles.linkBlueText, GlobalStyles.centerVertical)}>{"Change"}</div>
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img className={classNames(TimesheetListItemStyles.arrowDownBlueIconImg)} src={arrowDownBlue} alt="arrowDown" />
            </div>
          </div>
        )}
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
        <div className={classNames()}>{"Reviewed By"}</div>
        {(projectTimesheetQuery.isLoading || (reviewerUserQuery.isLoading && !!projectTimesheetData?.reviewer)) && (
          <Skeleton variant={"rounded"} height={16} width={150} />
        )}
        {projectTimesheetData && (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
            <div className={classNames(projectTimesheetData.reviewer && TimesheetListItemStyles.detailsText, GlobalStyles.centerVertical)}>
              {!projectTimesheetData.reviewer && `- -`}
              {projectTimesheetData.reviewer && reviewerUserData && reviewerUserData.name}
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            {projectTimesheetData.reviewer && (
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                <div className={classNames(GlobalStyles.centerVertical)}>
                  <img className={classNames(TimesheetListItemStyles.clockIconImg)} src={clockIcon} alt="clock" />
                </div>
                <div className={classNames(TimesheetListItemStyles.actionDateContainer, GlobalStyles.centerVertical)}>
                  {isCurrentDate(projectTimesheetData.reviewer.actionDate)
                    ? getTimeFromDate(projectTimesheetData.reviewer.actionDate)
                    : formatDate(projectTimesheetData.reviewer.actionDate, DateFormatEnum.DD_MM_YYYY)}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.centerVertical)}>
        <div className={classNames()}>{"Submitted By"}</div>
        {(projectTimesheetQuery.isLoading || (submitterUserQuery.isLoading && !!projectTimesheetData?.submitter)) && (
          <Skeleton variant={"rounded"} height={16} width={150} />
        )}
        {projectTimesheetData && (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
            <div className={classNames(projectTimesheetData.submitter && TimesheetListItemStyles.detailsText, GlobalStyles.centerVertical)}>
              {!projectTimesheetData.submitter && `- -`}
              {projectTimesheetData.submitter && submitterUserData && submitterUserData.name}
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
            {projectTimesheetData.submitter && (
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                <div className={classNames(GlobalStyles.centerVertical)}>
                  <img className={classNames(TimesheetListItemStyles.clockIconImg)} src={clockIcon} alt="clock" />
                </div>
                <div className={classNames(TimesheetListItemStyles.actionDateContainer, GlobalStyles.centerVertical)}>
                  {isCurrentDate(projectTimesheetData.submitter.actionDate)
                    ? getTimeFromDate(projectTimesheetData.submitter.actionDate)
                    : formatDate(projectTimesheetData.submitter.actionDate, DateFormatEnum.DD_MM_YYYY)}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={classNames(TimesheetListItemStyles.timesheetListItemLastSection, GlobalStyles.flex, GlobalStyles.gap3)}>
        <div
          className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
          onMouseEnter={() => setActionHovered(ProjectTimesheetItemActionTypesEnum.download)}
          onMouseLeave={() => setActionHovered(null)}
          onClick={downloadTimesheetFile}
        >
          <Tooltip TransitionComponent={Fade} title={"Download"} placement="top" arrow>
            <img
              className={classNames(TimesheetListItemStyles.utilIconImg)}
              src={actionHovered === ProjectTimesheetItemActionTypesEnum.download ? DownloadMmrGreenIcon : DownloadIconBlue}
              alt="Download"
            />
          </Tooltip>
        </div>
        {canListTimesheets && (
          <div
            className={classNames(GlobalStyles.centerVertical, GlobalStyles.elementWithCursor)}
            onClick={() =>
              navigate(navigateToUrl, {
                replace: true,
                state: {
                  from: SubQueryNames.timesheetList,
                },
              })
            }
          >
            <Tooltip
              TransitionComponent={Fade}
              title={isReviewed ? "View" : "Edit"}
              placement="top"
              arrow
              onMouseEnter={() => setActionHovered(ProjectTimesheetItemActionTypesEnum.viewEdit)}
              onMouseLeave={() => setActionHovered(null)}
            >
              <img
                className={classNames(TimesheetListItemStyles.utilIconImg)}
                src={
                  actionHovered === ProjectTimesheetItemActionTypesEnum.viewEdit
                    ? isReviewed
                      ? TimesheetListItemViewHoverIcon
                      : TimesheetListItemEditHoverIcon
                    : isReviewed
                      ? TimesheetListItemViewIcon
                      : TimesheetListItemEditIcon
                }
                alt="ViewEdit"
              />
            </Tooltip>
          </div>
        )}
      </div>
      {popupHandler.get(ProjectsPagePopups.changeEntityStatus)!.isOpen && projectTimesheetData && (
        <ChangeEntityStatusPopup
          isOpen={popupHandler.get(ProjectsPagePopups.changeEntityStatus)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.changeEntityStatus, popupHandler)}
          headerText={popupHeaders.get(ProjectsPagePopups.changeEntityStatus)}
          secondaryHeaderText={`Timesheet: ${projectTimesheetData?.id}`}
          //currentStatus={projectTimesheetData?.status}
          entityInfo={{
            type: ChangeStatusPopupEntityType.TIMESHEET,
            projectId: parseInt(projectId!),
            timesheetGroupId: timesheetItem.timesheetGroupId,
            timesheetId: timesheetItem.id,
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
          }}
        />
      )}
    </div>
  );
};
export default TimesheetListItem;
