import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import SubmittedTimesheetsListStyles from "./GroupTimesheetsListStyles.module.css";
import classNames from "classnames";
import { FC, PropsWithChildren } from "react";

import { GroupTimesheetListItem } from "./grouptimesheetslistitem/GroupTimesheetsListItem.tsx";
import { FrontendTimesheetLimitedType } from "../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { ProjectTimesheetTabStatusEnum } from "../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import ProjectListStyles from "../../../../projectlist/ProjectList.module.css";
import { useAutoAnimate } from "@formkit/auto-animate/react";

interface GroupTimesheetListProps {
  timesheetListStatus: ProjectTimesheetTabStatusEnum;
  groupTimesheetList: FrontendTimesheetLimitedType[];
}

export const GroupTimesheetList: FC<PropsWithChildren<GroupTimesheetListProps>> = ({ timesheetListStatus, groupTimesheetList, children }) => {
  const [parent] = useAutoAnimate();
  return (
    <div className={classNames(SubmittedTimesheetsListStyles.reviewedTimesheetListContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div
        ref={parent}
        className={classNames(ProjectListStyles.projectListContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}
      >
        {groupTimesheetList.map((groupTimesheetItem) => (
          <div key={groupTimesheetItem.id}>
            <GroupTimesheetListItem inList={true} timesheetListStatus={timesheetListStatus} groupTimesheetItem={groupTimesheetItem} />
          </div>
        ))}
        {children}
      </div>
    </div>
  );
};
