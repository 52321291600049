import { FC } from "react";
import classNames from "classnames";
import DownloadPlanViewStyles from "./DownloadPlanView.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

interface DownloadPlanViewProps {
  downloadHandler: () => void;
}

export const DownloadPlanView: FC<DownloadPlanViewProps> = ({ downloadHandler }) => {
  return (
    <div
      className={classNames(DownloadPlanViewStyles.downloadButtonContainer, GlobalStyles.flexDirectionColumn, GlobalStyles.flex, GlobalStyles.gap)}
    >
      <div className={classNames()}>{"Plan ready!"}</div>
      <div className={classNames(GlobalStyles.flex1)} />
      <div className={classNames(GlobalStyles.flex1)} />
      <div
        className={classNames(DownloadPlanViewStyles.downloadButton)}
        onClick={() => {
          downloadHandler();
        }}
      >
        {"Download"}
      </div>
    </div>
  );
};
