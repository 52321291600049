import { EPCM_API_URLS, epcmAxiosInstance } from "../config.ts";
import { AxiosRequestConfig } from "axios";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { GlobalConfigurationEndpointType } from "../../types/apicallstypes/ConfigurationsTypes.ts";
import { GlobalConfigurationSingleResponseType } from "../../types/settings/GlobalConfigurationTypes.ts";

const URL = `${EPCM_API_URLS.configurations}`;

export const getGlobalEntity = async <T extends GlobalConfigurationEndpointType>(
  endpoint: T,
  id: number,
  detailLevel: DetailLevel = DetailLevel.LIMITED,
): Promise<GlobalConfigurationSingleResponseType<T>> => {
  const params = new URLSearchParams();
  params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${URL}/${endpoint}/${id}`,
    method: "GET",
    params,
  };
  const response = await epcmAxiosInstance(config);

  return response.data as GlobalConfigurationSingleResponseType<T>;
};
