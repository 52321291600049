import { LimitedEmployeeType } from "../GeneralTypes.ts";
import { PlanReferenceFileType } from "./ManpowerPlanTypes.ts";

export interface TimesheetReportType {
  code: string;
  approvalStatus: TimesheetReportStatusEnum;
  periodStart: Date;
  periodEnd: Date;
  dateUploaded: Date;
  dateModified: Date;
  uploadedBy: LimitedEmployeeType;
  referenceFiles: PlanReferenceFileType[];
}

export enum TimesheetReportStatusEnum {
  approved = "approved",
  pending = "pending",
  rejected = "rejected",
}

export const TIMESHEET_REPORTS_LIST: TimesheetReportType[] = [
  // {
  //   code: "WAVE-23-08-ABC",
  //   approvalStatus: TimesheetReportStatusEnum.pending,
  //   periodStart: new Date("01, Aug 2023"),
  //   periodEnd: new Date("31, Aug 2023"),
  //   dateUploaded: new Date("28, Aug 2023"),
  //   dateModified: new Date("28, Aug 2023"),
  //   uploadedBy: {
  //     name: "Peter Jenkins",
  //     code: 443084,
  //   },
  //   referenceFiles: [
  //     {
  //       fileName: "signed_plan_approval.xlsx",
  //       uploadedBy: {
  //         name: "Peter Jenkins",
  //         code: 443084,
  //       },
  //       dateUploaded: new Date("28, Aug 2023"),
  //     },
  //     {
  //       fileName: "project_requirements.pdf",
  //       uploadedBy: {
  //         name: "John Smith",
  //         code: 654345,
  //       },
  //       dateUploaded: new Date("29, Aug 2023"),
  //     },
  //   ],
  // },
  //
  // //One approved
  //
  // {
  //   code: "WAVE-23-09-ABC",
  //   approvalStatus: TimesheetReportStatusEnum.approved,
  //   periodStart: new Date("01, Sep 2023"),
  //   periodEnd: new Date("30, Sep 2023"),
  //   dateUploaded: new Date("28, Sep 2023"),
  //   dateModified: new Date("28, Sep 2023"),
  //   uploadedBy: {
  //     name: "Peter Jenkins",
  //     code: 443084,
  //   },
  //   referenceFiles: [
  //     {
  //       fileName: "signed_plan_approval.xlsx",
  //       uploadedBy: {
  //         name: "Peter Jenkins",
  //         code: 443084,
  //       },
  //       dateUploaded: new Date("28, Sep 2023"),
  //     },
  //     {
  //       fileName: "project_requirements.pdf",
  //       uploadedBy: {
  //         name: "John Smith",
  //         code: 654345,
  //       },
  //       dateUploaded: new Date("29, Sep 2023"),
  //     },
  //   ],
  // },
  //
  // {
  //   code: "WAVE-23-09-ABC",
  //   approvalStatus: TimesheetReportStatusEnum.pending,
  //   periodStart: new Date("01, Sep 2023"),
  //   periodEnd: new Date("30, Sep 2023"),
  //   dateUploaded: new Date("28, Sep 2023"),
  //   dateModified: new Date("28, Sep 2023"),
  //   uploadedBy: {
  //     name: "Peter Jenkins",
  //     code: 443084,
  //   },
  //   referenceFiles: [
  //     {
  //       fileName: "signed_plan_approval.xlsx",
  //       uploadedBy: {
  //         name: "Peter Jenkins",
  //         code: 443084,
  //       },
  //       dateUploaded: new Date("28, Sep 2023"),
  //     },
  //     {
  //       fileName: "project_requirements.pdf",
  //       uploadedBy: {
  //         name: "John Smith",
  //         code: 654345,
  //       },
  //       dateUploaded: new Date("29, Sep 2023"),
  //     },
  //   ],
  // },
];
