import { styled, FormControl } from "@mui/material";

export const EpcmFormControl = styled(FormControl)({
  "& .MuiInputBase-input:focus": {
    backgroundColor: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .Mui-focused": {
    backgroundColor: "transparent",
  },
});
