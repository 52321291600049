import { useAutoAnimate } from "@formkit/auto-animate/react";
import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import TimesheetReportsListStyles from "./TimesheetReportsListStyles.module.css";
import { FC } from "react";
import TimesheetReportsListItem from "./timesheetreportslistitem/TimesheetReportsListItem.tsx";
import { TimesheetReportType } from "../../../../../../types/projects/ProjectTimesheetReportTypes.ts";

interface TimesheetReportsListProps {
  timesheetReportsList: TimesheetReportType[];
}
const TimesheetReportsList: FC<TimesheetReportsListProps> = ({ timesheetReportsList }) => {
  const [parent] = useAutoAnimate();

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.overflowHiddenFullHeight, GlobalStyles.gap05)}>
      <div
        ref={parent}
        className={classNames(
          TimesheetReportsListStyles.timesheetListContainer,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap15,
        )}
      >
        {timesheetReportsList.map((manpowerPlanItem) => (
          <div key={`${manpowerPlanItem.code}${manpowerPlanItem.uploadedBy}`}>
            <TimesheetReportsListItem timesheetReportItem={manpowerPlanItem} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default TimesheetReportsList;
