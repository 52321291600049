import { QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { FrontendProjectUserTypeWithRoleIds, FrontendUserProjectRolesType } from "../../../types/projects/PermissionTypes.ts";
import { PAGINATION_PAGE_SIZE } from "../../config.ts";
import { getProjectUsers } from "./getProjectUsers.ts";
import {
  convertProjectUserData,
  convertUserProjectRolesList,
} from "../../../types/apicallstypes/apicallstypesconverters/projects/projectpermissions/ProjectPermissionsApiConverter.ts";
import { getUserProjectRoles } from "./getUserProjectRoles.ts";

interface ApiDefinition {
  getProjectUsers: (id: number) => Promise<QueryModelWithPagination<FrontendProjectUserTypeWithRoleIds>>;
  getUserProjectRoles: (projectId: number) => Promise<FrontendUserProjectRolesType[]>;
}

export const useEpcmApiProjectPermissions = (): ApiDefinition => {
  const getProjectUsersConverted = async (
    id: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize: number = PAGINATION_PAGE_SIZE,
  ): Promise<QueryModelWithPagination<FrontendProjectUserTypeWithRoleIds>> => {
    const response = await getProjectUsers(id, pageOffset, searchFilter, pageSize);

    return {
      data: convertProjectUserData(response.data),
      nextPage: response.nextPage,
      totalCount: response.totalCount,
    };
  };

  const getUserProjectRolesConverted = async (projectId: number): Promise<FrontendUserProjectRolesType[]> => {
    const response = await getUserProjectRoles(projectId);

    return convertUserProjectRolesList(response);
  };

  return {
    getProjectUsers: getProjectUsersConverted,
    getUserProjectRoles: getUserProjectRolesConverted,
  };
};
