import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import EmployeeInfoExtraStyles from "./EmployeeInfoExtra.module.css";
import { FC } from "react";
import qualificationIcon from "../../../../../../assets/images/graduation-hat-icon-gray-fill.svg";
import certificationIcon from "../../../../../../assets/images/certification-icon-gray-fill.svg";
import experienceIcon from "../../../../../../assets/images/suitcase-clock-icon-gray-fill.svg";
import { EmployeeExtraInfoEnum } from "../../../../../../types/employees/EmployeesTypes.ts";

interface EmployeeInfoExtraProps {
  extraInfoType: EmployeeExtraInfoEnum;
  employeeExtraInfo: string | null;
}

interface DisplayTextType {
  icon: string;
  label: string;
  noInfoText: string;
}

const employeeExtraInfoEnumMap: Map<EmployeeExtraInfoEnum, DisplayTextType> = new Map([
  [EmployeeExtraInfoEnum.qualifications, { icon: qualificationIcon, label: "Qualifications", noInfoText: "No qualifications" }],
  [EmployeeExtraInfoEnum.certifications, { icon: certificationIcon, label: "Certifications", noInfoText: "No qualifications" }],
  [EmployeeExtraInfoEnum.experience, { icon: experienceIcon, label: "Experience", noInfoText: "No experience info" }],
]);

export const EmployeeInfoExtra: FC<EmployeeInfoExtraProps> = ({ extraInfoType, employeeExtraInfo }) => {
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15, GlobalStyles.overflowHiddenFullHeight)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap075)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img className={classNames(EmployeeInfoExtraStyles.labelIconImg)} src={employeeExtraInfoEnumMap.get(extraInfoType)!.icon} alt="label" />
        </div>
        <div className={classNames(EmployeeInfoExtraStyles.labelText, GlobalStyles.centerVertical)}>
          {employeeExtraInfoEnumMap.get(extraInfoType)!.label}
        </div>
      </div>
      {employeeExtraInfo ? (
        <div
          className={classNames(
            EmployeeInfoExtraStyles.mainTextContainer,
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.gap,
            GlobalStyles.overflowHiddenFullHeight,
          )}
        >
          <div className={classNames()}>
            <div className={classNames(EmployeeInfoExtraStyles.bulletPoint)} />
          </div>
          <div className={classNames(EmployeeInfoExtraStyles.mainText, GlobalStyles.flex1)}>{employeeExtraInfo}</div>
        </div>
      ) : (
        <div className={classNames(EmployeeInfoExtraStyles.noInfoContainer)}>{employeeExtraInfoEnumMap.get(extraInfoType)!.noInfoText}</div>
      )}
    </div>
  );
};
