import { OvertimeRequestExportType } from "epcm-common/dist/Types/OvertimeRequestTypes";
import { SuccessCallbackDataType } from "../apicallstypes/queryCommons.ts";

export enum FrontendOvertimeExportFileType {
  TEMPLATE = "template",
  ACTIVE = "active",
  OVERVIEW = "overview",
}

export const overtimeExportFileMappingBackend = new Map<FrontendOvertimeExportFileType, OvertimeRequestExportType>([
  [FrontendOvertimeExportFileType.TEMPLATE, OvertimeRequestExportType.TEMPLATE],
  [FrontendOvertimeExportFileType.ACTIVE, OvertimeRequestExportType.ACTIVE],
  [FrontendOvertimeExportFileType.OVERVIEW, OvertimeRequestExportType.OVERVIEW],
]);
export const overtimeExportFileMappingFrontend = new Map<OvertimeRequestExportType, FrontendOvertimeExportFileType>([
  [OvertimeRequestExportType.TEMPLATE, FrontendOvertimeExportFileType.TEMPLATE],
  [OvertimeRequestExportType.ACTIVE, FrontendOvertimeExportFileType.ACTIVE],
  [OvertimeRequestExportType.OVERVIEW, FrontendOvertimeExportFileType.OVERVIEW],
]);

export interface SuccessCallbackOvertimeDataType extends SuccessCallbackDataType {
  data: {
    status: number;
    id?: number;
    affected?: number;
  };
}

export enum OvertimeButtonTypeEnum {
  importOvertimeRequest = "ImportOvertimeRequest",
  exportOvertimeRequestTemplate = "ExportOvertimeRequestTemplate",
  exportOvertimeRequestActive = "ExportOvertimeRequestActive",
  exportOvertimeRequestOverview = "exportOvertimeRequestOverview",
}

export enum OvertimeButtonsWithHoverEnum {
  upload = "upload",
  downloadTemplate = "downloadTemplate",
  downloadActive = "downloadActive",
  downloadOverview = "downloadOverview",
}
