import MmrVersionAccordionStyles from "./MmrVersionAccordionGrouped.module.css";
import { Dispatch, FC, SetStateAction } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import MmrVersionAccordion from "../mmrversionaccordion/MmrVersionAccordion.tsx";
import { ProjectEmployeeDetailsPopupTypeEnum } from "../../../../../../../../types/projects/ProjectEmployeesTypes.ts";
import { MmrAccordionItem } from "../../../../../../../../types/projects/MmrTypes.ts";
import { FrontendMmrDateStatusEnum } from "../../../../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";

const MMR_LIST_TYPES: MmrAccordionItem[] = [
  { title: "ACTIVE VERSION", type: FrontendMmrDateStatusEnum.ACTIVE },
  { title: "UPCOMING VERSIONS", type: FrontendMmrDateStatusEnum.UPCOMING },
  { title: "VERSIONS HISTORY", type: FrontendMmrDateStatusEnum.HISTORY },
];

interface MmrVersionAccordionGroupedProps {
  projectId: number;
  employeeId: number;
  setPopupType: Dispatch<SetStateAction<ProjectEmployeeDetailsPopupTypeEnum>>;
  setSelectedMmrId: Dispatch<SetStateAction<number | null>>;
  selectedMmrId: number | null;
  setIsCloned: Dispatch<SetStateAction<boolean>>;
  selectedMmrIdType: FrontendMmrDateStatusEnum | null;
  setSelectedMmrIdType: Dispatch<SetStateAction<FrontendMmrDateStatusEnum | null>>;
}

const MmrVersionAccordionGrouped: FC<MmrVersionAccordionGroupedProps> = ({
  setPopupType,
  projectId,
  employeeId,
  setSelectedMmrId,
  selectedMmrId,

  setIsCloned,
  selectedMmrIdType,
  setSelectedMmrIdType,
}) => {
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025, MmrVersionAccordionStyles.container)}>
      {MMR_LIST_TYPES.map((item, index) => (
        <MmrVersionAccordion
          key={index}
          title={item.title}
          setPopupType={setPopupType}
          mmrDateStatus={item.type}
          projectId={projectId}
          employeeId={employeeId}
          setSelectedMmrId={setSelectedMmrId}
          selectedMmrId={selectedMmrId}
          setIsCloned={setIsCloned}
          selectedMmrIdType={selectedMmrIdType}
          setSelectedMmrIdType={setSelectedMmrIdType}
        />
      ))}
    </div>
  );
};

export default MmrVersionAccordionGrouped;
