import { FrontendTimesheetFormHeaderDateType } from "../../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { FrontendCalendarMMRType, FrontendDayTypeOptionsEnum } from "../../../../../../../../types/apicallstypes/CalendarApiTypes.ts";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import TimesheetFormDayColumnStyles from "./TimesheetFormDayColumn.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import { Fade, Skeleton, Tooltip } from "@mui/material";
import { CustomSwitch } from "../../../../../../../../ui/epcmswitch/EpcmSwitch.tsx";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { FrontendMmrCalendarDayMapping, HoursPerDayValidationType } from "../../../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import { EpcmTooltip } from "../../../../../../../../ui/epcmtooltip/EpcmTooltip.tsx";
import OvertimeTooltip from "../timesheetformdaterecord/overtimetooltip/OvertimeTooltip.tsx";

interface TimesheetFormDayColumnProps {
  weekDay: FrontendTimesheetFormHeaderDateType;
  setMmrCalendarMappings: Dispatch<SetStateAction<FrontendMmrCalendarDayMapping>>;
  groupTimesheetDates: FrontendTimesheetFormHeaderDateType[];
  mmrId: number;
  projectId: number;
  timesheetId: number;
  timesheetGroupId: number;
  timesheetEmployeeId: number;
  timesheetCalendarInfo: FrontendCalendarMMRType | undefined;
  isEditMode: boolean;
  isHoursPerDayInvalid: HoursPerDayValidationType[];
}

const TimesheetFormDayColumn: FC<TimesheetFormDayColumnProps> = ({
  weekDay,
  setMmrCalendarMappings,
  mmrId,
  projectId,
  timesheetId,
  timesheetGroupId,
  timesheetEmployeeId,
  groupTimesheetDates,
  timesheetCalendarInfo,
  isEditMode,
  isHoursPerDayInvalid,
}) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getGroupTimesheetDateRecords } = useEpcmApiProjectsTimesheetsGroups();
  const [isApplicableLocal, setIsApplicableLocal] = useState<boolean>(true);

  const timesheetDateRecordsQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetGroupTimesheetDateRecords, projectId, timesheetGroupId, timesheetId, timesheetEmployeeId, weekDay.id],
    queryFn: () => getGroupTimesheetDateRecords(projectId, timesheetGroupId, timesheetId, timesheetEmployeeId, weekDay.id),
    enabled: isAuthorized && !!timesheetCalendarInfo,
  });

  const timesheetDateRecordsData = useMemo(() => {
    return timesheetDateRecordsQuery.data ?? undefined;
  }, [timesheetDateRecordsQuery]);

  // const hasOvertime = useMemo(() => !!timesheetDateRecordsData && timesheetDateRecordsData.overtimeHours > 0, [timesheetDateRecordsData]);
  //
  // const totalHours = useMemo(() => {
  //   if (!timesheetDateRecordsData) {
  //     return false;
  //   }
  //   return hasOvertime ? timesheetDateRecordsData.maxHours + timesheetDateRecordsData.overtimeHours : timesheetDateRecordsData.maxHours;
  // }, [timesheetDateRecordsData, hasOvertime]);

  //When data are fetched get the mmrCalendarMappings and update the isApplicable, maxHours and overtimeHours and create the calendarId entries/or update them

  useEffect(() => {
    if (timesheetDateRecordsData) {
      setMmrCalendarMappings((prevMappings) => {
        const updatedMappings = { ...prevMappings };
        updatedMappings[mmrId] = {
          ...updatedMappings[mmrId],
          [weekDay.id]: {
            isApplicable: timesheetDateRecordsData.isApplicable,
            maxHours: timesheetDateRecordsData.maxHours,
            overtimeHours: timesheetDateRecordsData.overtimeHours,
            locationWorkingHours: timesheetDateRecordsData.locationWorkingHours,
            isWorkingDay: timesheetCalendarInfo?.dayType === FrontendDayTypeOptionsEnum.WORKING_DAY,
          },
        };
        return updatedMappings;
      });

      setIsApplicableLocal(timesheetDateRecordsData.isApplicable);
    }
  }, [timesheetDateRecordsData, setMmrCalendarMappings, mmrId, weekDay.id, timesheetCalendarInfo?.dayType]);

  // Toggle the local state but also update the mmrCalendarMappings
  const toggleIsApplicable = () => {
    setIsApplicableLocal((prev) => {
      setMmrCalendarMappings((prevMappings) => {
        const updatedMappings = { ...prevMappings };

        updatedMappings[mmrId] = {
          ...updatedMappings[mmrId],
          [weekDay.id]: {
            ...updatedMappings[mmrId][weekDay.id],
            isApplicable: !prev,
            isWorkingDay: timesheetCalendarInfo?.dayType === FrontendDayTypeOptionsEnum.WORKING_DAY,
          },
        };
        return updatedMappings;
      });

      return !prev;
    });
  };

  useEffect(() => {
    return () => {
      !!timesheetCalendarInfo &&
        queryClient.invalidateQueries({
          queryKey: [QueryNames.ProjectTimesheetGroupTimesheetDateRecords, projectId, timesheetGroupId, timesheetId, timesheetEmployeeId, weekDay.id],
        });
    };
  }, [queryClient, projectId, timesheetGroupId, timesheetId, timesheetEmployeeId, weekDay.id, timesheetCalendarInfo]);

  const isInvalidDayWorkedHoursSum = useMemo(() => {
    if (isHoursPerDayInvalid.length === 0) {
      return false;
    } else {
      return isHoursPerDayInvalid.some((invalidDay) => invalidDay.calendarId === weekDay.id && invalidDay.isInvalid);
    }
  }, [isHoursPerDayInvalid, weekDay.id]);

  const hasOvertime = useMemo(() => !!timesheetDateRecordsData && timesheetDateRecordsData.overtimeHours > 0, [timesheetDateRecordsData]);

  return (
    <>
      {timesheetDateRecordsData ? (
        timesheetCalendarInfo && timesheetCalendarInfo.dayType === FrontendDayTypeOptionsEnum.WORKING_DAY ? (
          <Tooltip
            TransitionComponent={Fade}
            title={isInvalidDayWorkedHoursSum && isApplicableLocal ? "Work hours sum cannot exceed the max hours" : ""}
            placement="top"
            arrow
          >
            <div
              key={mmrId + weekDay.id}
              className={classNames(
                isInvalidDayWorkedHoursSum && isApplicableLocal && TimesheetFormDayColumnStyles.invalidDay,
                TimesheetFormDayColumnStyles.toggleSwitchDiv,
                weekDay.id !== groupTimesheetDates[0].id && TimesheetFormDayColumnStyles.workingDayBorder,
                GlobalStyles.flex,
                GlobalStyles.gap05,
                GlobalStyles.centerHorizontal,
              )}
            >
              <div className={classNames(TimesheetFormDayColumnStyles.dayWorkingHours)}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
                  <div className={classNames(GlobalStyles.flex, TimesheetFormDayColumnStyles.maxHoursLabel)}>MAX HOURS:</div>
                  <EpcmTooltip
                    TransitionComponent={Fade}
                    title={
                      hasOvertime ? (
                        <OvertimeTooltip
                          maxHours={timesheetDateRecordsData.maxHours}
                          locationWorkingHours={timesheetDateRecordsData.locationWorkingHours}
                          overtimeHours={timesheetDateRecordsData.overtimeHours}
                        />
                      ) : (
                        ""
                      )
                    }
                    placement="top"
                    arrow
                  >
                    <div className={classNames(TimesheetFormDayColumnStyles.maxHours, GlobalStyles.centerVertical)}>
                      {hasOvertime
                        ? ` ${timesheetDateRecordsData.locationWorkingHours}+${timesheetDateRecordsData.overtimeHours}`
                        : `${timesheetDateRecordsData.locationWorkingHours}`}
                    </div>
                  </EpcmTooltip>
                </div>
              </div>
              {isEditMode && (
                <Tooltip TransitionComponent={Fade} title={"Set if day is applicable"} placement="top" arrow>
                  <div>
                    <CustomSwitch
                      disabled={!isEditMode}
                      className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical)}
                      isChecked={isApplicableLocal}
                      toggleSwitch={toggleIsApplicable}
                      sx={{
                        margin: "2px 0 0 0",
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </Tooltip>
        ) : (
          <div
            key={mmrId + weekDay.id}
            className={classNames(
              TimesheetFormDayColumnStyles.dayWorkingHours,
              weekDay.id !== groupTimesheetDates[0].id && TimesheetFormDayColumnStyles.workingDayBorder,
            )}
          >
            {timesheetCalendarInfo ? (timesheetCalendarInfo.dayType === FrontendDayTypeOptionsEnum.HOLIDAY ? "HOLIDAY" : "NON WORKING DAY") : ""}
          </div>
        )
      ) : timesheetCalendarInfo ? (
        <div className={classNames(TimesheetFormDayColumnStyles.dayWorkingHours)}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </div>
      ) : (
        <div className={classNames(TimesheetFormDayColumnStyles.dayWorkingHours)}>{`MMR NOT ACTIVE`}</div>
      )}
    </>
  );
};

export default TimesheetFormDayColumn;
