import { FC, useCallback, useState } from "react";
import ConfigurationPermissionRowStyles from "./GlobalConfigurationUserPermissionRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import TrashCanDarkIcon from "../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIcon from "../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../assets/images/trashcan-icon-red.svg";
import EditIconHover from "../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import orangeSeparator from "../../../../../assets/images/orange-dot.svg";
import { FrontendGlobalUserTypeWithRoleIds } from "../../../../../types/projects/PermissionTypes.ts";
import { FrontendRoleNormal } from "../../../../../types/apicallstypes/UsersApiTypes.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../projects/use-projects-popups.ts";
import EditUserRolePopup from "../../../../projects/popups/edituserrolepopup/EditUserRolePopup.tsx";
import { ConfigurationScopeEnum } from "../../../../../types/projects/ConfigurationTypes.ts";
import { useEpcmApiUserPermissionsMutation } from "../../../../../apicalls/configurations/permissions/mutations/useEpcmApiUserPermissionMutation.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { DeletePopup } from "../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { useQueryClient } from "@tanstack/react-query";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { Tooltip } from "@mui/material";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../../types/Roles.ts";

interface GlobalConfigurationPermissionRowProps {
  globalUserItem: FrontendGlobalUserTypeWithRoleIds;
  roles: FrontendRoleNormal[];
}

const GlobalConfigurationUserPermissionRow: FC<GlobalConfigurationPermissionRowProps> = ({ globalUserItem, roles }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const { useDeleteGlobalUserFromPermissionsMutation } = useEpcmApiUserPermissionsMutation();

  const findRole = (roleId: number) => {
    return roles.find((role) => role.id === roleId);
  };

  const canListConfigurations = canPerformGlobalAction(GlobalAction.ConfigList);
  const canEditConfigurations = canPerformGlobalAction(GlobalAction.ConfigUpdate);
  const canDeleteConfigurations = canPerformGlobalAction(GlobalAction.ConfigDelete);

  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();

  const deleteGlobalUserFromPermissionsMutation = useDeleteGlobalUserFromPermissionsMutation(globalUserItem.code, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "User was deleted successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalPermissions] });
  }, [queryClient]);

  return (
    <>
      <tr
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
        key={globalUserItem.code}
        className={classNames(
          ConfigurationPermissionRowStyles.itemContainer,
          isRowHovered && ConfigurationPermissionRowStyles.rowHoverEffect,
          ConfigurationPermissionRowStyles.Trow,
        )}
      >
        {globalUserItem && canListConfigurations ? (
          <>
            <td
              className={classNames(
                ConfigurationPermissionRowStyles.tableCell,
                ConfigurationPermissionRowStyles.boldText,
                ConfigurationPermissionRowStyles.tD,
              )}
            >
              {globalUserItem.code}
            </td>
            <td className={classNames(ConfigurationPermissionRowStyles.tableCell, ConfigurationPermissionRowStyles.tD)}>
              <div
                className={classNames(ConfigurationPermissionRowStyles.dotMargin, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap)}
              >
                <div>
                  {globalUserItem.globalRoleIds.length === 0 ? (
                    <Tooltip title={"User requires Roles"} arrow={true} placement={"bottom"}>
                      <div>
                        <img src={orangeSeparator} alt={"roles separator"}></img>
                      </div>
                    </Tooltip>
                  ) : (
                    <div className={classNames(ConfigurationPermissionRowStyles.seperatorFiller)}></div>
                  )}
                </div>
                <div>{globalUserItem.name}</div>
              </div>
            </td>
            <td className={classNames(ConfigurationPermissionRowStyles.tableCell, ConfigurationPermissionRowStyles.tD)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap025)}>
                  {globalUserItem.globalRoleIds.length > 0 ? (
                    globalUserItem.globalRoleIds.map((role) => (
                      <Tooltip key={role} title={findRole(role)?.description} arrow={true} placement={"right-end"}>
                        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
                          {findRole(role)?.description && <div className={classNames(ConfigurationPermissionRowStyles.rowHyphen)}>-</div>}
                          <div key={role} className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
                            <div>{findRole(role)?.name}</div>
                          </div>
                        </div>
                      </Tooltip>
                    ))
                  ) : (
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
                      <div className={classNames(ConfigurationPermissionRowStyles.italicText)}>No roles assigned</div>
                    </div>
                  )}
                </div>
              </div>
            </td>
            <td className={classNames(ConfigurationPermissionRowStyles.tableCellActions, ConfigurationPermissionRowStyles.tD)}>
              <div
                className={classNames(
                  ConfigurationPermissionRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                {canDeleteConfigurations && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      onOpenUtilPopup();
                    }}
                    onMouseEnter={() => setDeleteIconHovered(true)}
                    onMouseLeave={() => setDeleteIconHovered(false)}
                  >
                    <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                  </div>
                )}
                {canEditConfigurations && (
                  <div
                    className={classNames(ConfigurationPermissionRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                    onClick={() => onOpenPopup(ProjectsPagePopups.updateGlobalUserRole, popupHandler)}
                    onMouseEnter={() => setEditIconHovered(true)}
                    onMouseLeave={() => setEditIconHovered(false)}
                  >
                    <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                  </div>
                )}
              </div>
            </td>
          </>
        ) : (
          <></>
        )}
      </tr>

      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteGlobalUserFromPermissionsMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            responseType === ApiResponseTypeEnum.success && invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}

      {popupHandler.get(ProjectsPagePopups.updateGlobalUserRole)!.isOpen && (
        <EditUserRolePopup
          userItem={globalUserItem}
          existingRolesIds={globalUserItem.globalRoleIds}
          rolesList={roles}
          scope={ConfigurationScopeEnum.GLOBAL}
          closeFn={() => onClosePopup(ProjectsPagePopups.updateGlobalUserRole, popupHandler)}
          isOpen={popupHandler.get(ProjectsPagePopups.updateGlobalUserRole)!.isOpen}
          secondaryHeaderText={""}
          headerText={popupHeaders.get(ProjectsPagePopups.updateGlobalUserRole)}
        />
      )}
    </>
  );
};

export default GlobalConfigurationUserPermissionRow;
