import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { ProjectEmployeeBasic } from "epcm-common/dist/Types/ProjectEmployeeTypes";
import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getProjectEmployee = async (
  id: number,
  projectEmployeeId: number,
  detailLevel: DetailLevel = DetailLevel.BASIC,
): Promise<ProjectEmployeeBasic> => {
  const params = new URLSearchParams();
  params.set("detailLevel", `${detailLevel}`);
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/employees/${projectEmployeeId}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  return response.data satisfies ProjectEmployeeBasic;
};
