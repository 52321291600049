import { FrontendCtrMappingRow } from "../../../types/apicallstypes/CtrMappingTypes.ts";
import { getProjectCtrMapping } from "./getProjectCtrMapping.ts";
import { convertCtrMappingToFrontendCtrMappingRow } from "../../../types/apicallstypes/apicallstypesconverters/CtrMappingApiConverter.ts";

interface ApiDefinition {
  getProjectCtrMapping: (projectId: number) => Promise<FrontendCtrMappingRow[]>;
}

export const useEpcmApiProjectCtrMapping = (): ApiDefinition => {
  const getProjectCtrMappingConverted = async (projectId: number): Promise<FrontendCtrMappingRow[]> => {
    const ctrMappingData = await getProjectCtrMapping(projectId);

    return convertCtrMappingToFrontendCtrMappingRow(ctrMappingData);
  };

  return {
    getProjectCtrMapping: getProjectCtrMappingConverted,
  };
};
