import { FC, useMemo, useState } from "react";
import { EPCMInfoContainerDiv } from "../../../../../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import TimesheetAnalyticsListButtonItemStyles from "./TimesheetAnalyticsListButtonItem.module.css";
import {
  ProjectTimesheetAnalyticsEmployeeListButtonTypesEnum,
  ProjectTimesheetAnalyticsListButtonTypesEnum,
} from "../../../../../../../../types/projects/ProjectTimesheetsTypes.ts";

import { EpcmTimesheetTooltip } from "../../../../../../../../ui/epcmtimesheettooltip/EpcmTimesheetTooltip.tsx";
import TimesheetListItemTooltip from "../../timesheetlistitemtooltip/TimesheetListItemTooltip.tsx";
import { Skeleton } from "@mui/material";

interface TimesheetAnalyticsListButtonItemProps {
  mainText: string;
  primaryCount: number | undefined;
  firstSubtext: string;
  firstSubtextCount: number | undefined;
  secondSubtext: string;
  secondSubtextCount: number | undefined;
  timesheetAnalyticsButtonType: ProjectTimesheetAnalyticsListButtonTypesEnum;
  projectId: number;
  selectedPeriodStart: Date | null;
  selectedPeriodEnd: Date | null;
}

const TimesheetAnalyticsListButtonItem: FC<TimesheetAnalyticsListButtonItemProps> = ({
  mainText,
  primaryCount,
  firstSubtextCount,
  secondSubtextCount,
  secondSubtext,
  firstSubtext,
  timesheetAnalyticsButtonType,
  projectId,
  selectedPeriodStart,
  selectedPeriodEnd,
}) => {
  const [isPrimaryTooltipOpen, setIsPrimaryTooltipOpen] = useState<boolean>(false);
  const [isSecondaryTooltipOpen, setIsSecondaryTooltipOpen] = useState<boolean>(false);

  const handlePrimaryTooltipClose = () => {
    setIsPrimaryTooltipOpen(false);
  };
  const handlePrimaryTooltipOpen = () => {
    setIsPrimaryTooltipOpen(true);
  };
  const handleSecondaryTooltipClose = () => {
    setIsSecondaryTooltipOpen(false);
  };

  const handleSecondaryTooltipOpen = () => {
    setIsSecondaryTooltipOpen(true);
  };

  const isTimesheetGroupsButton = useMemo(() => {
    return timesheetAnalyticsButtonType === ProjectTimesheetAnalyticsListButtonTypesEnum.groups;
  }, [timesheetAnalyticsButtonType]);

  const isTimesheetEmployeesInTimesheetsButton = useMemo(() => {
    return timesheetAnalyticsButtonType === ProjectTimesheetAnalyticsListButtonTypesEnum.employees;
  }, [timesheetAnalyticsButtonType]);

  const mainTextFormatted = mainText.split("\n").map((text, index) => {
    return <div key={index}>{text}</div>;
  });
  const firstSubtextFormatted = firstSubtext.split("\n").map((text, index) => {
    return <div key={index}>{text}</div>;
  });
  const secondSubtextFormatted = secondSubtext.split("\n").map((text, index) => {
    return <div key={index}>{text}</div>;
  });

  return (
    <EPCMInfoContainerDiv
      className={classNames(
        TimesheetAnalyticsListButtonItemStyles.container,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.overflowHiddenFullHeight,
        GlobalStyles.gap025,
      )}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.centerHorizontal,
          GlobalStyles.gap,
          TimesheetAnalyticsListButtonItemStyles.fullWidthItem,
        )}
      >
        <div
          className={classNames(
            TimesheetAnalyticsListButtonItemStyles.mainText,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap01,
          )}
        >
          {mainTextFormatted}
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        <div className={classNames(TimesheetAnalyticsListButtonItemStyles.countDiv, GlobalStyles.flex)}>
          {primaryCount != undefined && primaryCount >= 0 ? primaryCount : <Skeleton variant={"rounded"} width={10} height={20}></Skeleton>}
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
        <div
          className={classNames(
            TimesheetAnalyticsListButtonItemStyles.fullWidthItem,
            TimesheetAnalyticsListButtonItemStyles.firstSubtext,
            GlobalStyles.centerHorizontal,
            GlobalStyles.flex,
            GlobalStyles.gap,
          )}
          onMouseEnter={handleSecondaryTooltipOpen}
          onMouseLeave={handleSecondaryTooltipClose}
        >
          <div
            className={classNames(
              TimesheetAnalyticsListButtonItemStyles.firstSubtext,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap01,
            )}
          >
            {firstSubtextFormatted}
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>

          {isTimesheetEmployeesInTimesheetsButton ? (
            <EpcmTimesheetTooltip
              open={isSecondaryTooltipOpen}
              onClose={handleSecondaryTooltipClose}
              placement={"bottom-end"}
              title={
                <TimesheetListItemTooltip
                  tooltipType={timesheetAnalyticsButtonType}
                  projectId={projectId}
                  periodStart={selectedPeriodStart}
                  periodEnd={selectedPeriodEnd}
                  secondaryTooltipType={ProjectTimesheetAnalyticsEmployeeListButtonTypesEnum.employeesInTimesheets}
                />
              }
            >
              <div className={classNames(TimesheetAnalyticsListButtonItemStyles.countDiv, GlobalStyles.flex)}>
                {firstSubtextCount != undefined && firstSubtextCount >= 0 ? (
                  firstSubtextCount
                ) : (
                  <Skeleton variant={"rounded"} width={15} height={20}></Skeleton>
                )}
              </div>
            </EpcmTimesheetTooltip>
          ) : (
            <div className={classNames(TimesheetAnalyticsListButtonItemStyles.countDiv, GlobalStyles.flex)}>
              {firstSubtextCount != undefined && firstSubtextCount >= 0 ? (
                firstSubtextCount
              ) : (
                <Skeleton variant={"rounded"} width={15} height={20}></Skeleton>
              )}
            </div>
          )}
        </div>
        <div
          className={classNames(
            TimesheetAnalyticsListButtonItemStyles.fullWidthItem,
            TimesheetAnalyticsListButtonItemStyles.secondSubtext,
            GlobalStyles.centerHorizontal,
            GlobalStyles.flex,
            GlobalStyles.gap,
          )}
          onMouseEnter={handlePrimaryTooltipOpen}
          onMouseLeave={handlePrimaryTooltipClose}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>{secondSubtextFormatted}</div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>

          {isTimesheetGroupsButton || isTimesheetEmployeesInTimesheetsButton ? (
            <EpcmTimesheetTooltip
              open={isPrimaryTooltipOpen}
              onClose={handlePrimaryTooltipClose}
              placement={"bottom-end"}
              title={
                <TimesheetListItemTooltip
                  tooltipType={timesheetAnalyticsButtonType}
                  projectId={projectId}
                  periodStart={selectedPeriodStart}
                  periodEnd={selectedPeriodEnd}
                  secondaryTooltipType={
                    isTimesheetEmployeesInTimesheetsButton ? ProjectTimesheetAnalyticsEmployeeListButtonTypesEnum.employeesNotInTimesheets : undefined
                  }
                />
              }
            >
              <div className={classNames(TimesheetAnalyticsListButtonItemStyles.countDiv, GlobalStyles.flex)}>
                {secondSubtextCount != undefined && secondSubtextCount >= 0 ? (
                  secondSubtextCount
                ) : (
                  <Skeleton variant={"rounded"} width={15} height={20}></Skeleton>
                )}
              </div>
            </EpcmTimesheetTooltip>
          ) : (
            <div className={classNames(TimesheetAnalyticsListButtonItemStyles.countDiv, GlobalStyles.flex)}>
              {secondSubtextCount != undefined && secondSubtextCount >= 0 ? (
                secondSubtextCount
              ) : (
                <Skeleton variant={"rounded"} width={15} height={20}></Skeleton>
              )}
            </div>
          )}
        </div>
      </div>
    </EPCMInfoContainerDiv>
  );
};
export default TimesheetAnalyticsListButtonItem;
