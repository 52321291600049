import { AxiosRequestConfig } from "axios";
import { ApiResponseMmrDateLimits } from "epcm-common/dist/Types/MmrTypes";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

const getProjectEmployeeMmrDateLimits = async (projectId: number, projectEmployeeId: number, mmrId: number): Promise<ApiResponseMmrDateLimits> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/employees/${projectEmployeeId}/mmr/${mmrId}/limits`,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  return response.data as ApiResponseMmrDateLimits;
};

export default getProjectEmployeeMmrDateLimits;
