import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import dndUploadIcon from "../../assets/images/dnd-upload-icon.svg";
import { ConstraintListType, DnDErrorsEnum, extensionsMap } from "../../types/DnDComponentTypes.ts";
import DnDComponentStyles from "./DnDComponent.module.css";
import { EPCM_AppContext } from "../../utils/AppContext.ts";
import { DnDChosenFileList } from "./dndchosenfilelist/DnDChosenFileList.tsx";

interface DnDComponentProps {
  chosenFileList: File[];
  setChosenFileList: React.Dispatch<React.SetStateAction<File[]>>;
  setIsAnyLoading: React.Dispatch<React.SetStateAction<boolean>>;
  constraintOptions?: ConstraintListType;
}

export const DnDComponent: FC<DnDComponentProps> = ({ chosenFileList, setChosenFileList, setIsAnyLoading, constraintOptions }) => {
  const { openSnackbar, EPCM_AppSnackbar } = useContext(EPCM_AppContext);

  const [filesInProgress, setFilesInProgress] = useState<boolean[]>([]);

  const uploadFileRef = useRef<HTMLInputElement | null>(null);

  const hasChosenFiles = useMemo(() => {
    return chosenFileList.length > 0;
  }, [chosenFileList]);

  const onSetFileLoading = useCallback((fileIndex: number) => {
    setFilesInProgress((currentState) => {
      const filesInProgressClone = currentState.slice();
      filesInProgressClone[fileIndex] = false;
      return filesInProgressClone;
    });
  }, []);

  const handleFiles = useCallback(
    (fileList: File[]) => {
      if (constraintOptions && constraintOptions.maxNumberOfFiles && constraintOptions.maxNumberOfFiles < fileList.length) {
        openSnackbar(DnDErrorsEnum.maxNumber);
        return;
      }
      if (constraintOptions && constraintOptions.validExtensions) {
        let hasWrongFileType = false;
        fileList.forEach((file) => {
          const validTypes = constraintOptions.validExtensions!.map((ext) => extensionsMap.get(ext) as string);
          if (!validTypes.includes(file.type)) {
            hasWrongFileType = true;
          }
        });

        hasWrongFileType && openSnackbar(`${DnDErrorsEnum.extension}, "${constraintOptions!.validExtensions!.map((ext) => ext).join(", ")}"`);
        if (hasWrongFileType) {
          return;
        }
      }

      setChosenFileList(fileList);
      setFilesInProgress(fileList.map(() => true));
      setIsAnyLoading(true);
    },
    [constraintOptions, setChosenFileList, setIsAnyLoading, openSnackbar],
  );

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFilesLocal = Array.from(e.target.files as unknown as File[]).slice();
    e.target.value = "";
    handleFiles(chosenFilesLocal);
  };

  const onFileDrop = useCallback(
    <T extends File>(acceptedFiles: T[]) => {
      handleFiles(acceptedFiles);
    },
    [handleFiles],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileDrop,
    noClick: true,
  });

  useEffect(() => {
    if (filesInProgress.filter((progress) => !progress).length === chosenFileList.length) {
      setIsAnyLoading(false);
    }
  }, [chosenFileList, filesInProgress, setIsAnyLoading]);

  return (
    <>
      <div
        {...getRootProps()}
        className={classNames(
          DnDComponentStyles.dndContainer,
          !hasChosenFiles && DnDComponentStyles.dndContainerHover,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          !hasChosenFiles && GlobalStyles.centerVertical,
        )}
        onClick={() => !hasChosenFiles && uploadFileRef.current?.click()}
      >
        {hasChosenFiles ? (
          chosenFileList.map((fileItem, index) => (
            <DnDChosenFileList
              key={fileItem.name}
              chosenFile={fileItem}
              setChosenFileList={setChosenFileList}
              setIsDoneLoading={onSetFileLoading}
              fileIndexInList={index}
            />
          ))
        ) : (
          <>
            <div className={classNames(GlobalStyles.centerVertical)}>
              <img src={dndUploadIcon} alt="upload" className={classNames(DnDComponentStyles.dndUploadIconImg)} />
            </div>
            <div className={classNames(DnDComponentStyles.approvedContentMainText, GlobalStyles.centerVertical)}>
              <div className={classNames()}>{isDragActive ? "Drop files here..." : "Drag & Drop files here or"}</div>
              <div className={classNames(GlobalStyles.flex)}>
                <div className={classNames(GlobalStyles.flex1)} />
                <div className={classNames(DnDComponentStyles.browseText, isDragActive && DnDComponentStyles.browseTextInvisible)}>{"browse"}</div>
                <input
                  {...getInputProps({ style: { border: "unset" } })}
                  ref={uploadFileRef}
                  type="file"
                  onChange={handleFileEvent}
                  hidden
                  accept={constraintOptions?.validExtensions?.map((extItem) => `.${extItem}`).join(", ")}
                  multiple={!constraintOptions || !constraintOptions.maxNumberOfFiles || constraintOptions.maxNumberOfFiles > 1}
                />
                <div className={classNames(GlobalStyles.flex1)} />
              </div>
            </div>
          </>
        )}
      </div>
      {EPCM_AppSnackbar}
    </>
  );
};
