import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import classNames from "classnames";
import TimesheetsStyles from "./TimesheetsStyles.module.css";
import { EPCMInfoContainerDiv } from "../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import TimesheetsIcon from "../../../../assets/images/timesheet-icon.svg";
import TimesheetsActiveIcon from "../../../../assets/images/timesheet-active-icon.svg";
import TimesheetsGroupIcon from "../../../../assets/images/timesheets-group-icon.svg";
import TimesheetsGroupActiveIcon from "../../../../assets/images/timesheet-group-active-icon.svg";
import TimesheetsReportIcon from "../../../../assets/images/timesheets-report-icon.svg";
import TimesheetsArchiveIcon from "../../../../assets/images/timesheet-archive-icon.svg";
import TimesheetsArchiveHoverIcon from "../../../../assets/images/timesheet-archive-hover-icon.svg";
import TimesheetsReportActiveIcon from "../../../../assets/images/timesheet-report-active-icon.svg";
import { useMemo, useState } from "react";

import { NavLink, Outlet, UIMatch, useLocation, useMatches, useParams } from "react-router-dom";
import { AppRoutesPaths, TimesheetButtonsType, TimesheetsExtraRouteHandleData } from "../../../../types/AppRoutesTypes.ts";
import { getRouteWithId } from "../../../../utils/StringManipulation.ts";

import TimesheetListButtons from "./timesheetlistbuttons/TimesheetListButtons.tsx";
import TimesheetGroupsButtons from "./timesheetgroupsbuttons/TimesheetGroupsButtons.tsx";
import { ProjectTimesheetsSubMenuButtonTypesEnum } from "../../../../types/projects/ProjectTimesheetsTypes.ts";
import { ProjectContextType, useProjectContext } from "../../ProjectsUtils.ts";
import TimesheetReportButtons from "./timesheetreportbuttons/TimesheetReportButtons.tsx";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../types/Roles.ts";

export const Timesheets = () => {
  const { currentProject, searchQuery } = useProjectContext();
  const { projectId } = useParams();
  const [selectedPeriodStart, setSelectedPeriodStart] = useState<Date | null>(null);
  const [selectedPeriodEnd, setSelectedPeriodEnd] = useState<Date | null>(null);

  const { canPerformProjectAction } = useRetrieveUserPermittedActions();
  const [subMenuButtonHoveredType, setSubMenuButtonHoveredType] = useState<ProjectTimesheetsSubMenuButtonTypesEnum | null>(null);

  const location = useLocation();
  const matches = useMatches() as UIMatch<unknown, TimesheetsExtraRouteHandleData>[];

  const showTimesheetExtraComponents = matches.find((match) => match.handle?.showTimesheetExtraComponents)?.handle?.showTimesheetExtraComponents;

  const canListTimesheets = canPerformProjectAction(ProjectAction.ProjectTimesheetListList);
  const canListTimesheetGroups = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupList);
  const canListTimesheetStatistics = canPerformProjectAction(ProjectAction.ProjectTimesheetStatisticsList);
  const canGenerateTimesheetReport = canPerformProjectAction(ProjectAction.ProjectTimesheetGenerateReport);

  const showHeaderBar = useMemo(() => {
    const currentPathSegment = location.pathname.split("/").pop();
    return (
      location.state?.from == AppRoutesPaths.projectSingleTimesheets ||
      currentPathSegment == AppRoutesPaths.projectSingleTimesheets ||
      location.state?.from == AppRoutesPaths.projectSingleTimesheetsReports ||
      currentPathSegment == AppRoutesPaths.projectSingleTimesheetsReports ||
      location.state?.from == AppRoutesPaths.projectSingleTimesheetsGroups ||
      currentPathSegment == AppRoutesPaths.projectSingleTimesheetsGroups ||
      location.state?.from == AppRoutesPaths.projectSingleTimesheetsArchive ||
      currentPathSegment == AppRoutesPaths.projectSingleTimesheetsArchive
    );
  }, [location]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2, GlobalStyles.overflowHiddenFullHeight)}>
      {showHeaderBar ? (
        <div className={classNames(TimesheetsStyles.infoBarContainer, GlobalStyles.flex, GlobalStyles.gap)}>
          <div
            className={classNames(
              TimesheetsStyles.navLinkContainer,
              GlobalStyles.flex,
              GlobalStyles.gap,
              GlobalStyles.centerHorizontal,
              GlobalStyles.flex1,
            )}
          >
            {canListTimesheets && (
              <NavLink
                className={classNames(TimesheetsStyles.navLinkStyle, GlobalStyles.overflowAutoFullHeight)}
                to={getRouteWithId(`/${AppRoutesPaths.projects}/${projectId}/${AppRoutesPaths.projectSingleTimesheets}`, ":projectId", projectId!)}
                replace
                end
                key={`${AppRoutesPaths.projectSingleTimesheets}`}
                state={{ from: AppRoutesPaths.projectSingleTimesheets }}
              >
                {({ isActive }) => (
                  <EPCMInfoContainerDiv
                    className={classNames(
                      TimesheetsStyles.largeButton,
                      isActive && TimesheetsStyles.selectedButton,
                      GlobalStyles.flex,
                      GlobalStyles.flexDirectionColumn,
                      GlobalStyles.gap,
                      GlobalStyles.overflowHiddenFullHeight,
                    )}
                    onMouseEnter={() => setSubMenuButtonHoveredType(ProjectTimesheetsSubMenuButtonTypesEnum.timesheets)}
                    onMouseLeave={() => setSubMenuButtonHoveredType(null)}
                  >
                    <div>
                      <img
                        src={
                          subMenuButtonHoveredType === ProjectTimesheetsSubMenuButtonTypesEnum.timesheets || isActive
                            ? TimesheetsActiveIcon
                            : TimesheetsIcon
                        }
                        alt="Timesheets"
                      />
                    </div>
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
                    <div className={classNames(TimesheetsStyles.textContainer)}>
                      <div>Timesheets</div>
                    </div>
                  </EPCMInfoContainerDiv>
                )}
              </NavLink>
            )}
            <NavLink
              className={classNames(TimesheetsStyles.navLinkStyle, GlobalStyles.overflowAutoFullHeight)}
              to={AppRoutesPaths.projectSingleTimesheetsReports}
              key={`${AppRoutesPaths.projectSingleTimesheetsReports}`}
              state={{ from: AppRoutesPaths.projectSingleTimesheetsReports }}
            >
              {({ isActive }) => (
                <EPCMInfoContainerDiv
                  className={classNames(
                    TimesheetsStyles.largeButton,
                    isActive && TimesheetsStyles.selectedButton,
                    GlobalStyles.flex,
                    GlobalStyles.flexDirectionColumn,
                    GlobalStyles.gap,
                    GlobalStyles.overflowHiddenFullHeight,
                  )}
                  onMouseEnter={() => setSubMenuButtonHoveredType(ProjectTimesheetsSubMenuButtonTypesEnum.reports)}
                  onMouseLeave={() => setSubMenuButtonHoveredType(null)}
                >
                  <div>
                    <img
                      src={
                        subMenuButtonHoveredType === ProjectTimesheetsSubMenuButtonTypesEnum.reports || isActive
                          ? TimesheetsReportActiveIcon
                          : TimesheetsReportIcon
                      }
                      alt="Timesheets"
                    />
                  </div>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
                  <div className={classNames(TimesheetsStyles.textContainer)}>
                    <div>Reports</div>
                  </div>
                </EPCMInfoContainerDiv>
              )}
            </NavLink>
            {canListTimesheetGroups && (
              <NavLink
                className={classNames(TimesheetsStyles.navLinkStyle, GlobalStyles.overflowAutoFullHeight)}
                to={AppRoutesPaths.projectSingleTimesheetsGroups}
                key={`${AppRoutesPaths.projectSingleTimesheetsGroups}`}
                state={{ from: AppRoutesPaths.projectSingleTimesheetsGroups }}
              >
                {({ isActive }) => (
                  <EPCMInfoContainerDiv
                    className={classNames(
                      TimesheetsStyles.largeButton,
                      isActive && TimesheetsStyles.selectedButton,
                      GlobalStyles.flex,
                      GlobalStyles.flexDirectionColumn,
                      GlobalStyles.gap,
                      GlobalStyles.overflowHiddenFullHeight,
                    )}
                    onMouseEnter={() => setSubMenuButtonHoveredType(ProjectTimesheetsSubMenuButtonTypesEnum.groups)}
                    onMouseLeave={() => setSubMenuButtonHoveredType(null)}
                  >
                    <div>
                      <img
                        src={
                          subMenuButtonHoveredType === ProjectTimesheetsSubMenuButtonTypesEnum.groups || isActive
                            ? TimesheetsGroupActiveIcon
                            : TimesheetsGroupIcon
                        }
                        alt="Timesheets"
                      />
                    </div>
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>

                    <div className={classNames(TimesheetsStyles.textContainer)}>
                      <div>Groups</div>
                    </div>
                  </EPCMInfoContainerDiv>
                )}
              </NavLink>
            )}
            {canListTimesheets && (
              <NavLink
                className={classNames(TimesheetsStyles.navLinkStyle, GlobalStyles.overflowAutoFullHeight)}
                to={AppRoutesPaths.projectSingleTimesheetsArchive}
                key={`${AppRoutesPaths.projectSingleTimesheetsArchive}`}
                state={{ from: AppRoutesPaths.projectSingleTimesheetsArchive }}
              >
                {({ isActive }) => (
                  <EPCMInfoContainerDiv
                    className={classNames(
                      TimesheetsStyles.largeButton,
                      isActive && TimesheetsStyles.selectedButton,
                      GlobalStyles.flex,
                      GlobalStyles.flexDirectionColumn,
                      GlobalStyles.gap,
                      GlobalStyles.overflowHiddenFullHeight,
                    )}
                    onMouseEnter={() => setSubMenuButtonHoveredType(ProjectTimesheetsSubMenuButtonTypesEnum.archive)}
                    onMouseLeave={() => setSubMenuButtonHoveredType(null)}
                  >
                    <div>
                      <img
                        src={
                          subMenuButtonHoveredType === ProjectTimesheetsSubMenuButtonTypesEnum.archive || isActive
                            ? TimesheetsArchiveHoverIcon
                            : TimesheetsArchiveIcon
                        }
                        alt="Timesheets"
                      />
                    </div>
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>

                    <div className={classNames(TimesheetsStyles.textContainer)}>
                      <div>Archive</div>
                    </div>
                  </EPCMInfoContainerDiv>
                )}
              </NavLink>
            )}
          </div>
          <div className={classNames(TimesheetsStyles.buttonsContainer)}>
            {showTimesheetExtraComponents === TimesheetButtonsType.ListButtons ? (
              canListTimesheetStatistics && (
                <TimesheetListButtons
                  projectId={parseInt(projectId!)}
                  selectedPeriodEnd={selectedPeriodEnd}
                  selectedPeriodStart={selectedPeriodStart}
                />
              )
            ) : showTimesheetExtraComponents === TimesheetButtonsType.GroupButtons ? (
              <TimesheetGroupsButtons />
            ) : showTimesheetExtraComponents === TimesheetButtonsType.ReportButtons ? (
              canGenerateTimesheetReport && <TimesheetReportButtons />
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, TimesheetsStyles.outletContainer)}>
        <Outlet
          context={
            {
              currentProject: currentProject,
              searchQuery: searchQuery,
              selectedPeriodStart: selectedPeriodStart,
              setSelectedPeriodStart,
              selectedPeriodEnd: selectedPeriodEnd,
              setSelectedPeriodEnd,
            } satisfies ProjectContextType
          }
        />
      </div>
    </div>
  );
};
