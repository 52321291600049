import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";

const API = `${EPCM_API_URLS.configurations}`;

const deleteUserFromPermissionList = async (userCode: string) => {
  const config = {
    url: `${API}/users/${userCode}`,
    method: "DELETE",
  } as AxiosRequestConfig;

  return await epcmAxiosInstance(config);
};
export default deleteUserFromPermissionList;
