import { FC } from "react";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import OvertimeRequestLoaderStyles from "./OvertimeRequestLoader.module.css";
import classNames from "classnames";
import loaderGif from "../../../../../assets/images/Loader-figma.gif";

interface OvertimeRequestLoaderProps {}

export const OvertimeRequestLoader: FC<OvertimeRequestLoaderProps> = () => {
  return (
    <div className={classNames(OvertimeRequestLoaderStyles.loaderContainer, GlobalStyles.flex)}>
      <div className={classNames(GlobalStyles.flex1)} />
      <div className={classNames(GlobalStyles.centerVertical)}>
        <img src={loaderGif} alt="loader" className={classNames()} />
      </div>
      <div className={classNames(GlobalStyles.flex1)} />
    </div>
  );
};
