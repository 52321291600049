import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import GlobalEmployeeInfoBasicStyles from "./GlobalEmployeeInfoBasic.module.css";
import defaultAvatar from "../../../../../../assets/images/profile-default-avatar-white.svg";
import { FC, useEffect, useMemo } from "react";
import { useEpcmApiEmployees } from "../../../../../../apicalls/employees/useEpcmApiEmployees.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { FrontendEmployeeLimitedType } from "../../../../../../types/apicallstypes/EmployeesApiTypes.ts";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { Skeleton } from "@mui/material";

interface GlobalEmployeeInfoBasicProps {
  projectId: number;
  globalEmployeeId: number;
}

export const GlobalEmployeeInfoBasic: FC<GlobalEmployeeInfoBasicProps> = ({ projectId, globalEmployeeId }) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getEmployee } = useEpcmApiEmployees();

  const employeeQuery = useQuery({
    queryKey: [QueryNames.Employees, projectId, DetailLevel.LIMITED, globalEmployeeId],
    queryFn: () => getEmployee(globalEmployeeId, DetailLevel.LIMITED),
    enabled: isAuthorized,
    select: (data) => data as FrontendEmployeeLimitedType,
  });

  const { isLoading } = employeeQuery;
  const employeeData = useMemo(() => {
    return employeeQuery.data ?? undefined;
  }, [employeeQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Employees, projectId, DetailLevel.LIMITED, globalEmployeeId] })
        .then(() => console.log(`In employee with id ${globalEmployeeId} profile card, ${QueryNames.Employees} query canceled`));
    };
  }, [queryClient, globalEmployeeId, projectId]);

  return (
    <div className={classNames(GlobalEmployeeInfoBasicStyles.container, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap2)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
        <div className={classNames(GlobalEmployeeInfoBasicStyles.avatar, GlobalStyles.centerVertical)}>
          <img className={classNames(GlobalEmployeeInfoBasicStyles.avatarImg)} src={defaultAvatar} alt="avatar" />
        </div>
      </div>
      <div
        className={classNames(
          GlobalEmployeeInfoBasicStyles.employeeCredentials,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap075,
        )}
      >
        {!isLoading ? (
          <div className={classNames(GlobalEmployeeInfoBasicStyles.highlightedText)}>{employeeData?.name}</div>
        ) : (
          <Skeleton variant="text" width={100} height={20} />
        )}
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025, GlobalEmployeeInfoBasicStyles.secondaryText)}
        >
          <div className={GlobalEmployeeInfoBasicStyles.secondaryText}>{`Code:`}</div>
          {!isLoading ? (
            <div className={GlobalEmployeeInfoBasicStyles.secondaryText}>{employeeData?.code} </div>
          ) : (
            <Skeleton variant="text" width={100} height={20} />
          )}
        </div>
      </div>
    </div>
  );
};
