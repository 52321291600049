import { FrontendCtrCategoryEnum, FrontendCtrTypeEnum } from "./CtrTypes.ts";
import {
  convertGlobalCategoriesData,
  convertGlobalCtrLimitedData,
  convertGlobalDisciplinesData,
  convertGlobalLocationLimitedData,
  convertGlobalPositionsData,
  convertGlobalTradesData,
} from "./apicallstypesconverters/ConfigurationsApiConverter.ts";
import { GlobalCategoryLimited } from "epcm-common/dist/Types/GlobalCategoryTypes";
import { GlobalCtrLimited } from "epcm-common/dist/Types/GlobalCtrTypes";
import { GlobalDisciplineLimited } from "epcm-common/dist/Types/GlobalDisciplineTypes";
import { GlobalLocationLimited } from "epcm-common/dist/Types/GlobalLocation";
import { GlobalPositionLimited } from "epcm-common/dist/Types/GlobalPositionTypes";
import { GlobalTradeLimited } from "epcm-common/dist/Types/GlobalTradeTypes";

export interface FrontendGlobalPositionType {
  id: number;
  code: string;
  description: string;
}

export interface FrontendGlobalSubPositionType {
  id: number;
  code: string;
  description: string;
}

export interface FrontendGlobalLocationType {
  id: number;
  code: string;
  description: string;
  workingHours: number;
}

export interface FrontendGlobalTradeType {
  id: number;
  code: string;
  description: string;
}

export interface FrontendGlobalCategoryType {
  id: number;
  code: string;
  description: string;
}

export interface FrontendGlobalDisciplineType {
  id: number;
  code: string;
  description: string;
}

export interface FrontendGlobalCtrType {
  id: number;
  code: string;
  description: string;
  type: FrontendCtrTypeEnum;
  category: FrontendCtrCategoryEnum;
}

export enum GlobalConfigurationEndpointType {
  Categories = "categories",
  Ctrs = "ctrs",
  Trades = "trades",
  Disciplines = "disciplines",
  Positions = "positions",
  Locations = "locations",
  Permissions = "permissions",
}

export type FrontendType<T extends GlobalConfigurationEndpointType> = T extends GlobalConfigurationEndpointType.Categories
  ? FrontendGlobalCategoryType
  : T extends GlobalConfigurationEndpointType.Ctrs
    ? FrontendGlobalCtrType
    : T extends GlobalConfigurationEndpointType.Disciplines
      ? FrontendGlobalDisciplineType
      : T extends GlobalConfigurationEndpointType.Locations
        ? FrontendGlobalLocationType
        : T extends GlobalConfigurationEndpointType.Positions
          ? FrontendGlobalPositionType
          : T extends GlobalConfigurationEndpointType.Trades
            ? FrontendGlobalTradeType
            : never;

export const GlobalConfigurationEndpointTypeConverter = {
  [GlobalConfigurationEndpointType.Categories]: convertGlobalCategoriesData,
  [GlobalConfigurationEndpointType.Ctrs]: convertGlobalCtrLimitedData,
  [GlobalConfigurationEndpointType.Disciplines]: convertGlobalDisciplinesData,
  [GlobalConfigurationEndpointType.Locations]: convertGlobalLocationLimitedData,
  [GlobalConfigurationEndpointType.Positions]: convertGlobalPositionsData,
  [GlobalConfigurationEndpointType.Trades]: convertGlobalTradesData,
};

export type ResponseType<T extends GlobalConfigurationEndpointType> = T extends GlobalConfigurationEndpointType.Categories
  ? GlobalCategoryLimited
  : T extends GlobalConfigurationEndpointType.Ctrs
    ? GlobalCtrLimited
    : T extends GlobalConfigurationEndpointType.Disciplines
      ? GlobalDisciplineLimited
      : T extends GlobalConfigurationEndpointType.Locations
        ? GlobalLocationLimited
        : T extends GlobalConfigurationEndpointType.Positions
          ? GlobalPositionLimited
          : T extends GlobalConfigurationEndpointType.Trades
            ? GlobalTradeLimited
            : never;
