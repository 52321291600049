import { useState } from "react";

import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";

import ConfigurationLocationsStyles from "./GlobalConfigurationLocations.module.css";
import NewCtrIcon from "../../../../assets/images/configuration/new-ctr-icon.svg";
import SearchBarSubHeader from "../../../projects/project/configuration/searchbarsubheader/SearchBarSubHeader.tsx";

import { SettingsPagePopups, useSettingsPopups } from "../../use-settings-pupups.ts";
import { configurationsPopupType } from "../../../../types/projects/ConfigurationTypes.ts";
import GlobalConfigurationLocationsPopup from "../popups/globalconfigurationlocationspopup/GlobalConfigurationLocationsPopup.tsx";
import { ConfigurationTargetType, GlobalConfigurationScreenType } from "../../../../types/settings/GlobalConfigurationTypes.ts";
import ConfigurationEntityTableManager from "../../../projects/project/configuration/configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../types/Roles.ts";

const GlobalConfigurationLocations = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const canCreateConfiguration = canPerformGlobalAction(GlobalAction.ConfigCreate);
  return (
    <div
      className={classNames(
        ConfigurationLocationsStyles.paddingContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap3,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalStyles.overflowHiddenFullHeight,
          ConfigurationLocationsStyles.contentContainer,
        )}
      >
        <SearchBarSubHeader onSearchQueryChange={setSearchQuery} subheaderTitle={"Search Locations"} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
          <div
            className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationLocationsStyles.mainContent)}
          >
            <ConfigurationEntityTableManager
              searchQuery={searchQuery}
              globalConfigurationScreenType={GlobalConfigurationScreenType.locations}
              target={ConfigurationTargetType.global}
            />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, ConfigurationLocationsStyles.actionBtnContainer)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
            {canCreateConfiguration && (
              <div
                onClick={() => onOpenPopup(SettingsPagePopups.addGlobalLocation, popupHandler)}
                className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
              >
                <div>New Location Type</div>
                <div className={classNames(ConfigurationLocationsStyles.actionBtnImage)}>
                  <img src={NewCtrIcon} alt="New Location Type" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {popupHandler.get(SettingsPagePopups.addGlobalLocation)!.isOpen && (
        <GlobalConfigurationLocationsPopup
          mode={configurationsPopupType.create}
          closeFn={() => onClosePopup(SettingsPagePopups.addGlobalLocation, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.addGlobalLocation)!.isOpen}
          headerText={popupHeaders.get(SettingsPagePopups.addGlobalLocation)}
        />
      )}
    </div>
  );
};
export default GlobalConfigurationLocations;
