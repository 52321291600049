import { useState } from "react";

import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";

import NewDisciplineIcon from "../../../../assets/images/configuration/new-discipline-icon.svg";

import GlobalConfigurationPermissionStyles from "./GlobalConfigurationPermissions.module.css";
import SearchBarSubHeader from "../../../projects/project/configuration/searchbarsubheader/SearchBarSubHeader.tsx";
import ConfigurationEntityTableManager from "../../../projects/project/configuration/configurationentitytablemanager/ConfigurationEntityTableManager.tsx";
import { ConfigurationTargetType, GlobalConfigurationScreenType } from "../../../../types/settings/GlobalConfigurationTypes.ts";
import { SettingsPagePopups, useSettingsPopups } from "../../use-settings-pupups.ts";
import { ConfigurationScopeEnum } from "../../../../types/projects/ConfigurationTypes.ts";
import AddUserPopup from "../../../projects/popups/adduserpopup/AddUserPopup.tsx";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../types/Roles.ts";

const GlobalConfigurationPermissions = () => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useSettingsPopups();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const canUpdateRoles = canPerformGlobalAction(GlobalAction.UserUpdateRoles);
  return (
    <div
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap3,
        GlobalStyles.overflowHiddenFullHeight,
        GlobalConfigurationPermissionStyles.contentContainer,
      )}
    >
      <SearchBarSubHeader onSearchQueryChange={setSearchQuery} subheaderTitle={"Global Users"} />
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, GlobalStyles.overflowHiddenFullHeight)}>
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap,
            GlobalConfigurationPermissionStyles.mainContent,
          )}
        >
          <ConfigurationEntityTableManager
            searchQuery={searchQuery}
            globalConfigurationScreenType={GlobalConfigurationScreenType.permissions}
            target={ConfigurationTargetType.global}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalConfigurationPermissionStyles.actionBtnContainer)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
          {canUpdateRoles && (
            <div
              onClick={() => onOpenPopup(SettingsPagePopups.addUserGlobalRole, popupHandler)}
              className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
            >
              <div>Add User</div>
              <div className={classNames(GlobalConfigurationPermissionStyles.actionBtnImage)}>
                <img src={NewDisciplineIcon} alt="Add User" />
              </div>
            </div>
          )}
        </div>
      </div>
      {popupHandler.get(SettingsPagePopups.addUserGlobalRole)!.isOpen && (
        <AddUserPopup
          scope={ConfigurationScopeEnum.GLOBAL}
          closeFn={() => onClosePopup(SettingsPagePopups.addUserGlobalRole, popupHandler)}
          isOpen={popupHandler.get(SettingsPagePopups.addUserGlobalRole)!.isOpen}
          secondaryHeaderText={""}
          headerText={popupHeaders.get(SettingsPagePopups.addUserGlobalRole)}
        />
      )}
    </div>
  );
};
export default GlobalConfigurationPermissions;
