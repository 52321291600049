import classNames from "classnames";
import ConfigurationLocationsPopupStyles from "./ConfigurationLocationsPopup.module.css";
import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import TimesheetGroupSettingsMainIcon from "../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import { FC, useCallback, useState } from "react";
import InfoIconBlack from "../../../../assets/images/info-icon-black-bg.svg";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { FrontendProjectLocationLimited, ProjectLocationData } from "../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjectLocationMutation } from "../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectLocationMutation.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { usePopupState } from "../../../../utils/use-popup-state.ts";

interface ConfigurationLocationsPopupProps extends PopupType {
  updateProjectLocations: (newLocation: FrontendProjectLocationLimited) => void;
}

const ConfigurationLocationsPopup: FC<ConfigurationLocationsPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  updateProjectLocations,
}) => {
  const [newLocationName, setNewLocationName] = useState<string>("");
  const [newLocationCode, setNewLocationCode] = useState<string>("");
  const [newLocationWorkingHours, setNewLocationWorkingHours] = useState<number>();

  const { projectId } = useParams();
  const { useCreateNewProjectLocationMutation } = useEpcmApiProjectLocationMutation();
  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createProjectEmployeesMutation = useCreateNewProjectLocationMutation(parseInt(projectId!), {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "New project location was created successfully!" });
      onOpenResponseAlertPopup();
      updateProjectLocations(data.data as unknown as FrontendProjectLocationLimited);
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectLocations, projectId] });
      onCloseLoaderPopup();
    },
  });

  const onCreateProjectLocation = useCallback(() => {
    onOpenLoaderPopup();

    createProjectEmployeesMutation.mutate({
      description: newLocationName,
      code: newLocationCode,
      workingHours: newLocationWorkingHours,
    } as ProjectLocationData);
  }, [createProjectEmployeesMutation, newLocationCode, newLocationName, newLocationWorkingHours, onOpenLoaderPopup]);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(ConfigurationLocationsPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: "Create New Location",
          action: onCreateProjectLocation,
          tooltipText: "Please enter a Location name",
          disabled: false,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationLocationsPopupStyles.mainContainer)}
      >
        <div className={classNames(GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(ConfigurationLocationsPopupStyles.infoText)}>Location Code</div>
          <input
            placeholder={"Type a location code..."}
            className={classNames(ConfigurationLocationsPopupStyles.infoContainerSmallInput)}
            required
            value={newLocationCode}
            onChange={(e) => setNewLocationCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(ConfigurationLocationsPopupStyles.infoText)}>Location Name</div>
          <input
            placeholder={"Type a location name..."}
            className={classNames(ConfigurationLocationsPopupStyles.infoContainer, ConfigurationLocationsPopupStyles.infoContainerInput)}
            required
            value={newLocationName}
            onChange={(e) => setNewLocationName(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(ConfigurationLocationsPopupStyles.infoText)}> Working Hours</div>
          <NumericFormat
            placeholder={"Type location working hours"}
            className={classNames(ConfigurationLocationsPopupStyles.infoContainer, ConfigurationLocationsPopupStyles.infoContainerInput)}
            required
            value={newLocationWorkingHours}
            allowNegative={false}
            onValueChange={(values) => {
              const { floatValue } = values;
              setNewLocationWorkingHours(floatValue);
            }}
          ></NumericFormat>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
          <div className={classNames(ConfigurationLocationsPopupStyles.locationInfoIcon)}>
            <img src={InfoIconBlack} alt={"Info icon"} />
          </div>
          <div className={classNames(ConfigurationLocationsPopupStyles.locationInfoText)}>
            New Location Types will be reflected across the entire project.
          </div>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            responseType === ApiResponseTypeEnum.success && closeFn();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ConfigurationLocationsPopup;
