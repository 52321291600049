import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import FormButtonBarBottomStyles from "./FormButtonBarBottom.module.css";
import { FC } from "react";
import { EmployeeProfilePopupTypeEnum } from "../../../../../../types/employees/EmployeesTypes.ts";
import pencilIcon from "../../../../../../assets/images/pencil-white-x-marked.svg";
import { Fade, Tooltip } from "@mui/material";

interface FormButtonBarBottomProps {
  formType: Omit<EmployeeProfilePopupTypeEnum, EmployeeProfilePopupTypeEnum.view>;
  hasFormChanges: boolean;
  isSubmitDisabled: boolean;
  submitButtonTooltipText: string;
  submitFn: () => void;
  cancelFn: () => void;
}

export const FormButtonBarBottom: FC<FormButtonBarBottomProps> = ({
  formType,
  hasFormChanges,
  isSubmitDisabled,
  submitButtonTooltipText,
  submitFn,
  cancelFn,
}) => {
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
      <Tooltip TransitionComponent={Fade} title={submitButtonTooltipText} placement="top" arrow>
        <div
          className={classNames(
            FormButtonBarBottomStyles.button,
            isSubmitDisabled ? FormButtonBarBottomStyles.buttonDisabled : FormButtonBarBottomStyles.buttonHover,
            FormButtonBarBottomStyles.submitButton,
          )}
          onClick={submitFn}
        >
          <div className={classNames(FormButtonBarBottomStyles.buttonText, GlobalStyles.centerVertical)}>
            {formType === EmployeeProfilePopupTypeEnum.create ? "Create Employee" : "Save Changes"}
          </div>
        </div>
      </Tooltip>
      <div className={classNames(GlobalStyles.flex1)} />
      <Tooltip TransitionComponent={Fade} title={hasFormChanges ? "If you continue, all changes will be discarded" : ""} placement="top" arrow>
        <div
          className={classNames(
            FormButtonBarBottomStyles.button,
            FormButtonBarBottomStyles.cancelButton,
            hasFormChanges ? FormButtonBarBottomStyles.cancelButtonHoverWarning : FormButtonBarBottomStyles.cancelButtonHover,
          )}
          onClick={cancelFn}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
            {formType === EmployeeProfilePopupTypeEnum.edit && (
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(FormButtonBarBottomStyles.editIconImg)} src={pencilIcon} alt="edit" />
              </div>
            )}
            <div
              className={classNames(FormButtonBarBottomStyles.buttonText, FormButtonBarBottomStyles.cancelButtonText, GlobalStyles.centerVertical)}
            >
              {"Cancel"}
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
