import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { FrontendProjectConfigurationsEntityEnum } from "./useEpcmApiProjectUtilsMutations.ts";

const API = `${EPCM_API_URLS.projects}`;

export const updateProjectEntity = async <T>(
  projectId: number,
  entityId: number,
  entityType: FrontendProjectConfigurationsEntityEnum,
  updatedEntityData: T,
  subEntityId?: number,
) => {
  const subEntityPath = subEntityId ? `/${subEntityId}` : "";

  if (entityType === FrontendProjectConfigurationsEntityEnum.Subposition) {
    const config: AxiosRequestConfig = {
      url: `${API}/${projectId}/position/${entityId}/sub-positions/${subEntityId}`,
      method: "PUT",
      data: updatedEntityData,
    };
    return epcmAxiosInstance(config);
  }
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/${entityType}/${entityId}${subEntityPath}`,
    method: "PUT",
    data: updatedEntityData,
  };

  return epcmAxiosInstance(config);
};
