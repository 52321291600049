import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiResponseBodyTimesheetGroupGetSectionHead } from "epcm-common/dist/Types/TimesheetGroupTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getTimesheetGroupSectionHead = async (id: number, timesheetGroupId: number): Promise<ApiResponseBodyTimesheetGroupGetSectionHead> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheet-groups/${timesheetGroupId}/section-head`,
    method: "GET",
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  const responseData = response.data as ApiResponseBodyTimesheetGroupGetSectionHead;
  // const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;
  return { sectionHead: responseData.sectionHead };
};
