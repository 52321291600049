import { FC, useCallback, useMemo, useState } from "react";
import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import CreateNewProjectPopupStyles from "./CreateNewProjectPopup.module.css";
import infoIcon from "../../../../assets/images/info-icon-black-bg.svg";
import "react-datepicker/dist/react-datepicker.css";
import { convertDateToMilSecs, MuiDateFormatEnum } from "../../../../utils/DateManipulation.ts";
import { NumericFormat } from "react-number-format";
import { useEpcmApiProjectsMutations } from "../../../../apicalls/projects/mutations/useEpcmApiProjectsMutations.ts";
import { ApiRequestBodyProjectCreate } from "epcm-common/dist/Types/ProjectTypes.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { ApiResponseTypeEnum, ErrorCallbackDataType, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import EpcmDatepickerMui from "../../../../ui/epcmdatepickermui/EpcmDatepickerMui.tsx";
import { UiComponentGeneralEnum } from "../../../../types/UiComponentGeneralType.ts";
import { FrontendProjectStatus } from "../../../../types/apicallstypes/ProjectsApiTypes.ts";
import {
  convertFrontendProjectStatusToBackend,
  FrontendProjectFieldsEnum,
  ProjectManagerInputFieldsEnum,
} from "../../../../types/projects/ProjectsTypes.ts";
import { capitalizeFirstLetter } from "../../../../utils/StringManipulation.ts";
import arrowDownBlack from "../../../../assets/images/arrow-down-black.svg";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { FrontendUserLimitedType } from "../../../../types/apicallstypes/UsersApiTypes.ts";
import SelectUserInput from "../../project/selectuserinput/SelectUserInput.tsx";

interface CreateNewProjectPopupProps extends PopupType {}

interface FormFillingProjectType {
  code: number;
  name: string;
  contractSignDate: Date;
  duration: number;
  agreementNumber: string;
  projectLocation: string;
  mmrPrefix: string;
  overtimePrefix: string;
  status?: FrontendProjectStatus;
}

const FORM_FILLING_PROJECT_LIST: FormFillingProjectType[] = [
  {
    code: 54822,
    name: "NW-PRJCT",
    contractSignDate: new Date("03-14-2023"),
    duration: 8,
    agreementNumber: "24357645",
    projectLocation: "New York",
    mmrPrefix: "PROJECT-54822-MMR-0006",
    overtimePrefix: "OVERTIME-54822-0006",
  },
  {
    code: 74345,
    name: "WAVE-eu",
    contractSignDate: new Date("08-03-2022"),
    duration: 16,
    agreementNumber: "xcr_234AU",
    projectLocation: "Los Angleles",
    mmrPrefix: "PROJECT-74345-MMR-0002",
    overtimePrefix: "OVERTIME-74345-0002",
  },
];

export const CreateNewProjectPopup: FC<CreateNewProjectPopupProps> = ({ isOpen, closeFn, headerText, secondaryHeaderText }) => {
  const { useCreateProjectMutation } = useEpcmApiProjectsMutations();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const [parent] = useAutoAnimate();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const [isPopupExpanded, setIsPopupExpanded] = useState<boolean>(false);
  const [projectCode, setProjectCode] = useState<number | undefined>();
  const [projectName, setProjectName] = useState<string>("");
  const [projectLocation, setProjectLocation] = useState<string>("");
  const [contractSignDate, setContractSignDate] = useState<Date | null>(null);
  const [projectDuration, setProjectDuration] = useState<number | undefined>();
  const [agreementNumber, setAgreementNumber] = useState<string>("");
  const [mmrPrefix, setMmrPrefix] = useState<string>("");
  const [overtimePrefix, setOvertimePrefix] = useState<string>("");
  const [hasProjectCodeError, setHasProjectCodeError] = useState<boolean>(false);
  const [projectManager, setProjectManager] = useState<FrontendUserLimitedType | undefined>();
  const [projectClientSeniorManager, setProjectClientSeniorManager] = useState<string | undefined>();
  const [projectCostControlEngineer, setProjectCostControlEngineer] = useState<FrontendUserLimitedType | undefined>();
  const [projectClientCostControlEngineer, setProjectClientCostControlEngineer] = useState<string | undefined>();
  const [projectControlsManager, setProjectControlsManager] = useState<FrontendUserLimitedType | undefined>();
  const [projectClientControlsManager, setProjectClientControlsManager] = useState<string | undefined>();
  const [projectStatus, setProjectStatus] = useState<FrontendProjectStatus | undefined>(FrontendProjectStatus.ACTIVE);

  const createProjectMutation = useCreateProjectMutation({
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Project was created successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const handleProjectStatusChange = useCallback(
    (status: FrontendProjectStatus) => {
      setProjectStatus(status);
    },
    [setProjectStatus],
  );

  const handleInputChange = (name: string, value: string | number | FrontendProjectStatus | undefined) => {
    const projectRefCode = FORM_FILLING_PROJECT_LIST.find((project) => project.code === value);
    const projectRefName = FORM_FILLING_PROJECT_LIST.find((project) => project.name === value);

    const fillForm = (projectRef: FormFillingProjectType) => {
      setProjectCode(projectRef.code);
      setProjectName(projectRef.name);
      setContractSignDate(projectRef.contractSignDate);
      setProjectLocation(projectRef.projectLocation);
      setProjectDuration(projectRef.duration);
      setAgreementNumber(projectRef.agreementNumber);
      setMmrPrefix(projectRef.mmrPrefix);
      setOvertimePrefix(projectRef.overtimePrefix);
      setProjectStatus(projectRef.status);
    };

    switch (name) {
      case "projectCode":
        if (projectRefCode) {
          fillForm(projectRefCode);
        } else {
          typeof value !== "string" && setProjectCode(value);
        }
        break;
      case "projectName":
        if (projectRefName) {
          fillForm(projectRefName);
        } else {
          typeof value === "string" && setProjectName(value);
        }
        break;
      case "contractNumber":
        typeof value === "string" && setProjectLocation(value);
        break;
      case "projectDuration":
        typeof value !== "string" && setProjectDuration(value);
        break;
      case "projectLocation":
        typeof value === "string" && setProjectLocation(value);
        break;
      case "agreementNumber":
        typeof value === "string" && setAgreementNumber(value);
        break;
      case "mmrPrefix":
        typeof value === "string" && setMmrPrefix(value);
        break;
      case "overtimePrefix":
        typeof value === "string" && setOvertimePrefix(value);
        break;
      case "projectClientControlsManager":
        typeof value === "string" && setProjectClientControlsManager(value);
        break;
      case "projectClientCostControlEngineer":
        typeof value === "string" && setProjectClientCostControlEngineer(value);
        break;
      case "projectClientSeniorManager":
        typeof value === "string" && setProjectClientSeniorManager(value);
        break;

      default:
        break;
    }
  };

  const isFormValid = useMemo(() => {
    return (
      !hasProjectCodeError &&
      !!projectCode &&
      projectName !== "" &&
      projectLocation !== "" &&
      !!contractSignDate &&
      !!projectDuration &&
      agreementNumber !== "" &&
      !!projectStatus &&
      mmrPrefix !== "" &&
      overtimePrefix !== ""
    );
  }, [hasProjectCodeError, projectCode, projectName, projectLocation, contractSignDate, projectDuration, agreementNumber, mmrPrefix, overtimePrefix]);

  const onSubmitForm = useCallback(() => {
    if (!isFormValid) {
      return;
    }
    const mutationData: ApiRequestBodyProjectCreate = {
      code: projectCode!,
      name: projectName,
      dateOfSign: convertDateToMilSecs(contractSignDate!),
      duration: projectDuration!,
      agreementNumber: agreementNumber,
      mmrPrefix: mmrPrefix,
      overtimeRequestPrefix: overtimePrefix,
      projectLocation: projectLocation,
      status: projectStatus ? convertFrontendProjectStatusToBackend(projectStatus) : undefined,
      projectManagerCode: projectManager?.code,
      projectClientSeniorManager: projectClientSeniorManager,
      projectCostControlEngineerCode: projectCostControlEngineer?.code,
      projectClientCostControlEngineer: projectClientCostControlEngineer,
      projectControlsManagerCode: projectControlsManager?.code,
      projectClientControlsManager: projectClientControlsManager,
    } satisfies ApiRequestBodyProjectCreate;
    createProjectMutation.mutate(mutationData);
    onOpenLoaderPopup();
  }, [
    createProjectMutation,
    isFormValid,
    projectCode,
    projectName,
    projectLocation,
    contractSignDate,
    projectDuration,
    agreementNumber,
    mmrPrefix,
    overtimePrefix,
    projectStatus,
    projectManager,
    projectClientSeniorManager,
    projectCostControlEngineer,
    projectControlsManager,
    projectClientControlsManager,
    projectClientCostControlEngineer,

    onOpenLoaderPopup,
  ]);

  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel ",
          buttonType: PopupButtonTypeEnum.neutral,
          action: closeFn,
        },
        {
          text: "Create Project",
          buttonType: PopupButtonTypeEnum.main,
          disabled: !isFormValid,
          tooltipText: !isFormValid ? (hasProjectCodeError ? "Project code must be a 5-digit number" : "All fields are mandatory") : undefined,
          action: () => {
            onSubmitForm();
          },
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
    >
      <div className={classNames(CreateNewProjectPopupStyles.formContainerPopup, GlobalStyles.flex, GlobalStyles.gap3)}>
        <div
          ref={parent}
          className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2, GlobalStyles.overflowHiddenFullHeight)}
        >
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap15)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames()}>{FrontendProjectFieldsEnum.PROJECT_CODE}</div>
                <NumericFormat
                  name={"projectCode"}
                  className={classNames(
                    CreateNewProjectPopupStyles.inputContainer,
                    hasProjectCodeError && CreateNewProjectPopupStyles.inputContainerWithError,
                  )}
                  value={projectCode}
                  placeholder={"Enter 5-digit code"}
                  onValueChange={(values) => handleInputChange("projectCode", values.floatValue)}
                  allowNegative={false}
                  onBlur={() => {
                    if (projectCode && !/^\d{5}$/.test(projectCode.toString())) {
                      setProjectCode(undefined);
                      setHasProjectCodeError(true);
                    } else if (projectCode && /^\d{5}$/.test(projectCode.toString())) {
                      setHasProjectCodeError(false);
                    }
                  }}
                />
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames()}>{FrontendProjectFieldsEnum.PROJECT_NAME}</div>
                <input
                  name="projectName"
                  value={projectName}
                  onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                  className={classNames(CreateNewProjectPopupStyles.inputContainer)}
                  placeholder={"Enter project name"}
                />
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames()}>{FrontendProjectFieldsEnum.AGREEMENT_NUMBER}</div>
                <input
                  name="agreementNumber"
                  value={agreementNumber}
                  onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                  className={classNames(CreateNewProjectPopupStyles.inputContainer)}
                  placeholder={"Enter agreement number"}
                />
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames()}>{FrontendProjectFieldsEnum.DATE_OF_SIGN}</div>
                <EpcmDatepickerMui
                  date={contractSignDate}
                  setDate={setContractSignDate}
                  theme={UiComponentGeneralEnum.SECONDARY}
                  placeholder={"Select Date"}
                  formatType={MuiDateFormatEnum.DD_COMMA_MMM_YYYY}
                />
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames(CreateNewProjectPopupStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_STATUS}</div>
                <div
                  className={classNames(
                    CreateNewProjectPopupStyles.radioBoxContainer,
                    GlobalStyles.flex,
                    GlobalStyles.gap05,
                    GlobalStyles.centerHorizontal,
                  )}
                >
                  <div
                    className={classNames(
                      projectStatus === FrontendProjectStatus.INACTIVE
                        ? CreateNewProjectPopupStyles.radioBoxItemSelected
                        : CreateNewProjectPopupStyles.radioBox,
                      GlobalStyles.flex1,
                      GlobalStyles.centerHorizontal,
                      GlobalStyles.centerVertical,
                      GlobalStyles.elementWithCursor,
                    )}
                    onClick={() => handleProjectStatusChange(FrontendProjectStatus.INACTIVE)}
                  >
                    {capitalizeFirstLetter(FrontendProjectStatus.INACTIVE)}
                  </div>
                  <div
                    className={classNames(
                      projectStatus === FrontendProjectStatus.ACTIVE
                        ? CreateNewProjectPopupStyles.radioBoxItemSelected
                        : CreateNewProjectPopupStyles.radioBox,
                      GlobalStyles.flex1,
                      GlobalStyles.centerHorizontal,
                      GlobalStyles.centerVertical,
                      GlobalStyles.elementWithCursor,
                    )}
                    onClick={() => handleProjectStatusChange(FrontendProjectStatus.ACTIVE)}
                  >
                    {capitalizeFirstLetter(FrontendProjectStatus.ACTIVE)}
                  </div>
                  <div
                    className={classNames(
                      projectStatus === FrontendProjectStatus.CANCELLED
                        ? CreateNewProjectPopupStyles.radioBoxItemSelected
                        : CreateNewProjectPopupStyles.radioBox,
                      GlobalStyles.flex1,
                      GlobalStyles.centerHorizontal,
                      GlobalStyles.centerVertical,
                      GlobalStyles.elementWithCursor,
                    )}
                    onClick={() => handleProjectStatusChange(FrontendProjectStatus.CANCELLED)}
                  >
                    {capitalizeFirstLetter(FrontendProjectStatus.CANCELLED)}
                  </div>
                  <div
                    className={classNames(
                      projectStatus === FrontendProjectStatus.COMPLETED
                        ? CreateNewProjectPopupStyles.radioBoxItemSelected
                        : CreateNewProjectPopupStyles.radioBox,
                      GlobalStyles.flex1,
                      GlobalStyles.centerHorizontal,
                      GlobalStyles.centerVertical,
                      GlobalStyles.elementWithCursor,
                    )}
                    onClick={() => handleProjectStatusChange(FrontendProjectStatus.COMPLETED)}
                  >
                    {capitalizeFirstLetter(FrontendProjectStatus.COMPLETED)}
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15)}>
              <div
                className={classNames(
                  CreateNewProjectPopupStyles.secondColumnMinorPadding,
                  GlobalStyles.flex,
                  GlobalStyles.flexDirectionColumn,
                  GlobalStyles.gap025,
                )}
              >
                <div className={classNames()}>{FrontendProjectFieldsEnum.PROJECT_LOCATION}</div>
                <input
                  name="projectLocation"
                  value={projectLocation}
                  onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                  className={classNames(CreateNewProjectPopupStyles.inputContainer)}
                  placeholder={"Enter project location"}
                />
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames()}>{FrontendProjectFieldsEnum.MMR_PREFIX}</div>
                <input
                  name="mmrPrefix"
                  value={mmrPrefix}
                  onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                  className={classNames(CreateNewProjectPopupStyles.inputContainer)}
                  placeholder={"E.g., PROJECT-12345-MMR-xxxx"}
                />
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames()}>{FrontendProjectFieldsEnum.OVERTIME_PREFIX}</div>
                <input
                  name="overtimePrefix"
                  value={overtimePrefix}
                  onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                  className={classNames(CreateNewProjectPopupStyles.inputContainer)}
                  placeholder={"E.g., OVERTIME-12345-xxxx"}
                />
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames()}>{FrontendProjectFieldsEnum.DURATION}</div>
                <NumericFormat
                  name={"projectDuration"}
                  className={classNames(CreateNewProjectPopupStyles.inputDurationContainer)}
                  value={projectDuration}
                  placeholder={"Integer number of months"}
                  onValueChange={(values) => handleInputChange("projectDuration", values.floatValue)}
                  allowNegative={false}
                  isAllowed={({ value }) => {
                    return /^\d*$/.test(value);
                  }}
                />
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, CreateNewProjectPopupStyles.infoTextContainer)}>
                <div className={classNames()}>
                  <img className={classNames(CreateNewProjectPopupStyles.infoIconImg)} src={infoIcon} alt="info" />
                </div>
                <div
                  className={classNames(
                    CreateNewProjectPopupStyles.infoText,
                    GlobalStyles.flex,
                    GlobalStyles.flexDirectionColumn,
                    GlobalStyles.gap15,
                  )}
                >
                  <div>
                    {"By typing the Project Code or Project Name, " +
                      "the system will try to draw the rest of the information about the project automatically. " +
                      "However, a check and correction if necessary are strongly suggested. "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isPopupExpanded && (
            <div ref={parent} className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap15)}>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap05)}>
                <div className={classNames(CreateNewProjectPopupStyles.managersLabel)}>ARCHIRODON</div>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15)}>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                    <div className={classNames()}>{FrontendProjectFieldsEnum.PROJECT_MANAGER}</div>
                    <SelectUserInput
                      projectManagerInputEntity={projectManager}
                      setProjectManagerInputEntity={setProjectManager}
                      projectManagerInputType={ProjectManagerInputFieldsEnum.PROJECT_MANAGER}
                    />
                  </div>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                    <div className={classNames()}>{FrontendProjectFieldsEnum.PROJECT_CONTROLS_MANAGER}</div>
                    <SelectUserInput
                      projectManagerInputEntity={projectControlsManager}
                      setProjectManagerInputEntity={setProjectControlsManager}
                      projectManagerInputType={ProjectManagerInputFieldsEnum.PROJECT_CONTROLS_MANAGER}
                    />
                  </div>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                    <div className={classNames()}>{FrontendProjectFieldsEnum.PROJECT_COST_CONTROL_ENGINEER}</div>
                    <SelectUserInput
                      projectManagerInputEntity={projectCostControlEngineer}
                      setProjectManagerInputEntity={setProjectCostControlEngineer}
                      projectManagerInputType={ProjectManagerInputFieldsEnum.PROJECT_COST_CONTROL_ENGINEER}
                    />
                  </div>
                </div>
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap05)}>
                <div className={classNames(CreateNewProjectPopupStyles.managersLabel)}>CLIENT</div>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15)}>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                    <div className={classNames()}>{FrontendProjectFieldsEnum.PROJECT_CLIENT_SENIOR_MANAGER}</div>
                    <input
                      name={"projectClientSeniorManager"}
                      value={projectClientSeniorManager}
                      onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                      placeholder={FrontendProjectFieldsEnum.PROJECT_CLIENT_SENIOR_MANAGER}
                      className={classNames(CreateNewProjectPopupStyles.inputContainer)}
                    ></input>
                  </div>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                    <div className={classNames()}>{FrontendProjectFieldsEnum.PROJECT_CONTROLS_MANAGER}</div>
                    <input
                      name={"projectClientControlsManager"}
                      value={projectClientControlsManager}
                      onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                      placeholder={FrontendProjectFieldsEnum.PROJECT_CONTROLS_MANAGER}
                      className={classNames(CreateNewProjectPopupStyles.inputContainer)}
                    ></input>
                  </div>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                    <div className={classNames()}>{FrontendProjectFieldsEnum.PROJECT_COST_CONTROL_ENGINEER}</div>
                    <input
                      name={"projectClientCostControlEngineer"}
                      value={projectClientCostControlEngineer}
                      onChange={(event) => handleInputChange(event.target.name, event.target.value)}
                      placeholder={FrontendProjectFieldsEnum.PROJECT_COST_CONTROL_ENGINEER}
                      className={classNames(CreateNewProjectPopupStyles.inputContainer)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            ref={parent}
            className={classNames(
              CreateNewProjectPopupStyles.expandText,
              GlobalStyles.flex,
              GlobalStyles.centerBoth,
              GlobalStyles.gap05,
              GlobalStyles.elementWithCursor,
            )}
            onClick={() => setIsPopupExpanded((currentState) => !currentState)}
          >
            {isPopupExpanded ? "Show Less" : "Show More"}
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical)}>
              <img className={classNames(isPopupExpanded && CreateNewProjectPopupStyles.rotateImg)} src={arrowDownBlack} alt="arrow" />
            </div>
          </div>
        </div>
      </div>

      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            responseType === ApiResponseTypeEnum.success && closeFn();
          }}
        />
      )}

      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};
