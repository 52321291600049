import { FC } from "react";
import MmrSpecificInformationStyles from "./MmrSpecificInformation.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";

import MmrOvertimeIcon from "../../../../../../../assets/images/mmr_overtime_icon.svg";
import MmrReferenceFileIcon from "../../../../../../../assets/images/mmr_reference_file_icon.svg";

interface MmrSpecificInformationProps {
  overtimeDuration: string;
  overTimePeriod: string;
  fileName: string;
}

const MmrSpecificInformation: FC<MmrSpecificInformationProps> = ({ overtimeDuration, overTimePeriod, fileName }) => {
  return (
    <div className={classNames(MmrSpecificInformationStyles.mainContainer, GlobalStyles.flex)}>
      <div className={classNames(MmrSpecificInformationStyles.container, MmrSpecificInformationStyles.hideElement, GlobalStyles.flex)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap075, MmrSpecificInformationStyles.textContainer)}>
          <div
            className={classNames(
              GlobalStyles.flex,
              MmrSpecificInformationStyles.overtimeContainer,
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
            )}
          >
            <img src={MmrOvertimeIcon} alt="overtime" />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025, GlobalStyles.centerVertical)}>
            <div className={classNames(MmrSpecificInformationStyles.overtimeLabel)}>Overtime</div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.centerHorizontal)}>
              <div>
                {"+ "}
                {overtimeDuration}
              </div>
              <div>|</div>
              <div>{overTimePeriod}</div>
            </div>
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap075, MmrSpecificInformationStyles.textContainer)}>
          <div
            className={classNames(
              GlobalStyles.flex,
              MmrSpecificInformationStyles.overtimeContainer,
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
            )}
          >
            <img src={MmrReferenceFileIcon} alt="reference file" className={classNames(MmrSpecificInformationStyles.icon)} />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025, GlobalStyles.centerVertical)}>
            <div className={classNames(MmrSpecificInformationStyles.overtimeLabel)}>Reference File</div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.centerHorizontal, MmrSpecificInformationStyles.fileName)}>
              {fileName}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MmrSpecificInformation;
