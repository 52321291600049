import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import ButtonBarBottomStyles from "./ButtonBarBottom.module.css";
import editIcon from "../../../../../../assets/images/pencil-black.svg";
import editIconHovered from "../../../../../../assets/images/pencil-white.svg";
import { FC, useState } from "react";

interface ButtonBarBottomProps {
  switchToEditMode: () => void;
  canEditEmployee: boolean;
}

export const ButtonBarBottom: FC<ButtonBarBottomProps> = ({ switchToEditMode, canEditEmployee }) => {
  const [isEditIconHovered, setIsEditIconHovered] = useState<boolean>(false);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
      <div className={classNames(ButtonBarBottomStyles.downloadButton)}>
        <div className={classNames(ButtonBarBottomStyles.downloadButtonText, GlobalStyles.centerVertical)}>{"Download CV"}</div>
      </div>

      <div className={classNames(GlobalStyles.flex1)} />
      {canEditEmployee && (
        <div
          className={classNames(ButtonBarBottomStyles.editIcon, GlobalStyles.centerVertical)}
          onMouseEnter={() => setIsEditIconHovered(true)}
          onMouseLeave={() => setIsEditIconHovered(false)}
          onClick={switchToEditMode}
        >
          <img className={classNames(ButtonBarBottomStyles.editIconImg)} src={isEditIconHovered ? editIconHovered : editIcon} alt="edit" />
        </div>
      )}
    </div>
  );
};
