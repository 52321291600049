import { FC, ReactNode } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import TimesheetAnalyticsStyles from "./TimesheetAnalytics.module.css";
import TimesheetAnalyticsIcon from "../../../../../../assets/images/timesheet-analytics-icon.svg";
import { EPCMInfoContainerDiv } from "../../../../../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";

interface PageFilterContainerProps {
  mainText: string;
  onFiltersClick: () => void;
  children: ReactNode;
}

export const TimesheetAnalytics: FC<PageFilterContainerProps> = ({ mainText, onFiltersClick, children }) => {
  return (
    <EPCMInfoContainerDiv className={GlobalStyles.overflowAutoFullHeight}>
      <div className={classNames(TimesheetAnalyticsStyles.fullHeightContainer, GlobalStyles.flex, GlobalStyles.gap2)}>
        <div className={classNames(TimesheetAnalyticsStyles.containerPadding, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
            <div className={classNames()} onClick={onFiltersClick}>
              <img className={classNames(TimesheetAnalyticsStyles.filtersIconImg)} src={TimesheetAnalyticsIcon} alt="filters" />
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)} />
          <div className={classNames(TimesheetAnalyticsStyles.textContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
            <div>{mainText}</div>
          </div>
        </div>
        {children}
      </div>
    </EPCMInfoContainerDiv>
  );
};
