import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { ProjectLocationData } from "../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const createProjectLocation = async (projectId: number, locationData: ProjectLocationData) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/location`,
    method: "POST",
    data: locationData,
  } as AxiosRequestConfig;

  return epcmAxiosInstance(config);
};
