import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useMemo } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import UploadBulkMmrPopupStyles from "./UploadBulkMmrPopup.module.css";
import classNames from "classnames";
import { DnDComponent } from "../../../../ui/dndcomponent/DnDComponent.tsx";
import { ExtensionConstraintEnum } from "../../../../types/DnDComponentTypes.ts";
import { useDndComponentStates } from "../../../../utils/use-dnd-component-states.ts";
import { useNotificationContext } from "../../../../ui/globalnotificationcontext/GlobalNotificationContext.ts";
import { useEpcmApiProjectEmployeeMutations } from "../../../../apicalls/projects/projectemployee/mutations/useEpcmApiProjectEmployeeMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { ApiResponseTypeEnum } from "../../../../types/apicallstypes/queryCommons.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import useProcessFileEvents from "../../../../utils/useProcessFileEvents.ts";
import { FrontendFileAction } from "../../../../types/projects/FileTypes.ts";
import { useParams } from "react-router-dom";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";

interface UploadBulkMMrPopupProps extends PopupType {}

export const UploadBulkMmrPopup: FC<UploadBulkMMrPopupProps> = ({ isOpen, closeFn, headerText, secondaryHeaderText }) => {
  const { chosenFileList, setChosenFileList, hasChosenFiles, isAnyLoading, setIsAnyLoading } = useDndComponentStates();
  const popupHeaderText = useMemo(() => headerText, [headerText]);
  const popupSecondaryHeaderText = useMemo(() => secondaryHeaderText, [secondaryHeaderText]);

  const { projectId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const { addNotification, updateNotification } = useNotificationContext();
  const { useImportBulkMmrMutation } = useEpcmApiProjectEmployeeMutations();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { processFileEvents: processFileEventsMmrImport } = useProcessFileEvents(
    FrontendFileAction.IMPORT,
    projectId!,
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
  );

  const startFileEventsListener = async (fileId: string) => {
    await processFileEventsMmrImport(fileId);
  };

  const importBulkMmrMutation = useImportBulkMmrMutation(parseInt(projectId!), {
    onSuccessCallback: (data) => {
      void startFileEventsListener(data.data as unknown as string).then(() => {});
    },
    onErrorCallback: (err) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(err.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {},
  });

  const importBulkMmr = useCallback(() => {
    if (chosenFileList) {
      const formData = new FormData();
      formData.append("file", chosenFileList[0], "file");
      importBulkMmrMutation.mutate(formData);
    }
  }, [chosenFileList, importBulkMmrMutation]);

  const cancelBulkMMRFileUpload = () => {
    setChosenFileList([]);
  };

  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel",
          buttonType: PopupButtonTypeEnum.neutral,
          action: () => {
            cancelBulkMMRFileUpload();
            closeFn();
          },
        },
        {
          text: "Upload",
          buttonType: PopupButtonTypeEnum.main,
          disabled: !hasChosenFiles || isAnyLoading,
          action: () => {
            importBulkMmr();
            closeFn();
          },
        },
      ]}
      isOpen={isOpen}
      closeFn={() => closeFn()}
      headerText={popupHeaderText}
      secondaryHeaderText={popupSecondaryHeaderText}
      overrideContentContainerStyleClass={""}
    >
      <div className={classNames(UploadBulkMmrPopupStyles.dndContainer)}>
        <DnDComponent
          chosenFileList={chosenFileList}
          setChosenFileList={setChosenFileList}
          setIsAnyLoading={setIsAnyLoading}
          constraintOptions={{
            validExtensions: [ExtensionConstraintEnum.xlsx, ExtensionConstraintEnum.xls],
            maxNumberOfFiles: 1,
          }}
        />
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (chosenFileList) {
              setChosenFileList([]);
            }
            if (responseType === ApiResponseTypeEnum.success) {
              closeFn();
            }
          }}
          responseType={responseType}
          responseObject={responseObject}
        />
      )}
    </PopupStructure>
  );
};
