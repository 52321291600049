import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../config.ts";
import { UserRoleLimited } from "epcm-common/dist/Types/UserTypes";

const API = `${EPCM_API_URLS.users}`;

export const getUsersRoles = async (): Promise<UserRoleLimited[]> => {
  const config = {
    url: `${API}/roles`,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  return response.data satisfies UserRoleLimited[];
};
