import { QueryModelWithCountAndData, QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { EPCM_API_URLS, epcmAxiosInstance, PAGINATION_PAGE_SIZE } from "../../config.ts";
import { AxiosRequestConfig } from "axios";
import { TimesheetLimited } from "epcm-common/dist/Types/TimesheetTypes";
import { convertDateToMilSecs } from "../../../utils/DateManipulation.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getAllTimesheets = async (
  id: number,
  periodStart: Date,
  periodEnd: Date,
  pageSize: number = PAGINATION_PAGE_SIZE,
  pageOffset?: number,
  searchFilter?: string,
): Promise<QueryModelWithPagination<TimesheetLimited>> => {
  const params = new URLSearchParams();
  params.set("periodStart", `${convertDateToMilSecs(periodStart)}`);
  params.set("periodEnd", `${convertDateToMilSecs(periodEnd)}`);
  pageOffset && params.set("pageOffset", `${pageOffset}`);
  pageSize && params.set("pageSize", `${pageSize}`);
  searchFilter && params.set("search", `${searchFilter}`);
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/timesheets`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  const numberOfTotalPages = Math.ceil(response.data.count / pageSize);
  const responseData = response.data as QueryModelWithCountAndData<TimesheetLimited>;
  const nextPage = pageOffset && pageOffset < numberOfTotalPages ? pageOffset + 1 : null;
  return { data: responseData.data, nextPage: nextPage, totalCount: responseData.count };
};
