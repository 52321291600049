import employeeAvatar1 from "../../assets/images/employee-pic-1.svg";
import employeeAvatar2 from "../../assets/images/employee-pic-2.svg";
import employeeAvatar3 from "../../assets/images/employee-pic-3.svg";

export enum EmployeeProfilePopupTypeEnum {
  create = "create",
  edit = "edit",
  view = "view",
}

export enum EmployeeExtraInfoEnum {
  qualifications = "qualifications",
  certifications = "certifications",
  experience = "experience",
}

export enum EmployeeFilterEnum {
  employeeName = "employee-name",
  employeeType = "employee-type",
  position = "position",
  nationality = "nationality",
  languages = "languages",
}

export const employeeFilterNamesMap: Map<EmployeeFilterEnum, string> = new Map([
  [EmployeeFilterEnum.employeeName, "Employee Name"],
  [EmployeeFilterEnum.employeeType, "Employee Type"],
  [EmployeeFilterEnum.position, "Position"],
  [EmployeeFilterEnum.nationality, "Nationality"],
  [EmployeeFilterEnum.languages, "Languages"],
]);

export interface SubFilterType {
  title: string;
  isActive: boolean;
}

export enum SortEnum {
  active = "active",
  reverted = "reverted",
  inactive = "inactive",
}

export interface EmployeeFilterType {
  id: EmployeeFilterEnum;
  title: string;
  sortStatus: SortEnum;
  subFilters: SubFilterType[];
}

export interface EmployeeExperienceType {
  name: string;
  project: string;
  country: string;
  startDate: Date;
  endDate: Date;
}

export const EMPLOYEE_LIST = [
  {
    id: "AR-123456788",
    name: "Fletch Skinner",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
      {
        name: "Assistant Project Manager",
        project: "IGB Energy Project, AVAX",
        country: "Greece",
        startDate: new Date("2019/10"),
        endDate: new Date("2021/03"),
      },
    ],
  },
  {
    id: "AR-124536788",
    name: "Jake Weary",
    category: "Archirodon",
    position: "Engineer",
    nationality: "Ireland",
    dob: new Date("16 September, 1976"),
    languages: ["English"],
    qualifications: ["Team management", "Budget management", "Communications Planning"],
    certifications: [],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-35768241",
    name: "Alan Fresco",
    avatar: employeeAvatar2,
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-6576321987",
    name: "Barry Tone",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-6574566987",
    name: "Dominic Emente",
    avatar: employeeAvatar1,
    category: "Subcontractor",
    position: "Project Executive",
    nationality: "Belgium",
    dob: new Date("05 January, 1988"),
    languages: ["French", "English", "Greek"],
    qualifications: [],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-654321987",
    name: "Larry Edwards",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-123753788",
    name: "Nicolas Smith",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-756453788",
    name: "Chen Li",
    avatar: employeeAvatar3,
    category: "Local Hired",
    position: "Management",
    nationality: "China",
    dob: new Date("25 March, 1985"),
    languages: ["English", "Chinese"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: ["MIM in Project Management - Université Paris Cité"],
    experience: [],
  },
  {
    id: "AR-654852158",
    name: "Jarvis Deppers",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-165456784",
    name: "Phillip Anthropy",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-453456789",
    name: "Hugh Saturation",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-436556780",
    name: "John Willies",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-654546159",
    name: "George Michael",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-35776441",
    name: "Theodore Mantis",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-165452389",
    name: "Jakob Downy",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
  {
    id: "AR-453478586",
    name: "Stephen Derek",
    category: "Archirodon",
    position: "Civil Engineer",
    nationality: "USA",
    dob: new Date("25 March, 1985"),
    languages: ["French", "English", "German", "Greek", "Swahili"],
    qualifications: ["Team management", "Risk Management", "Budget management", "Communications Planning", "Problem-solving"],
    certifications: [
      "MIM in Project Management - Université Paris Cité",
      "Master in Management Sciences - Université Libre de Bruxelles",
      "PMP Certification - IPAG Business School, Nice, France",
    ],
    experience: [
      {
        name: "Project Manager",
        project: "DPSSI Project, Building & Co",
        country: "Qatar",
        startDate: new Date("2021/06"),
        endDate: new Date("2023/04"),
      },
    ],
  },
];
