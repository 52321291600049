import { FC } from "react";
import classNames from "classnames";
import GroupEmployeeListItemStyles from "./GroupUserItemStyles.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
//import TimesheetWrenchIcon from "../../../../../assets/images/timesheet-wrench-icon.svg";
//import TimesheetLocationIcon from "../../../../../assets/images/timesheet-location-icon.svg";

import EmployeeNameSkeletonLoader from "../loaders/EmployeeNameSkeletonLoader.tsx";
import CodeSkeletonLoader from "../loaders/CodeSkeletonLoader.tsx";
import { DefaultAvatar } from "../../../../../ui/defaultavatar/DefaultAvatar.tsx";

interface GroupUserItemProps {
  name: string;

  code: string;
}
const GroupUserItem: FC<GroupUserItemProps> = ({ name, code }) => {
  return (
    <div
      key={code}
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.centerVertical,
        GroupEmployeeListItemStyles.container,
        GroupEmployeeListItemStyles.mainContainer,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
        <DefaultAvatar avatarImgSizeInEm={1.4} avatarSizeInEm={2.3} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap025, GroupEmployeeListItemStyles.bottomPadding)}>
          {!name ? (
            <EmployeeNameSkeletonLoader />
          ) : (
            <div className={classNames(GroupEmployeeListItemStyles.infoText, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap025)}>
              <div>{name}</div>
            </div>
          )}
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GroupEmployeeListItemStyles.infoSubText, GlobalStyles.gap05)}>
            <div className={classNames(GroupEmployeeListItemStyles.idTextDiv)}>CODE</div>
            {code ? <div className={classNames(GroupEmployeeListItemStyles.codeText)}>{code}</div> : <CodeSkeletonLoader />}
          </div>
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GroupEmployeeListItemStyles.iconsGap, GroupEmployeeListItemStyles.infoContainer)}>
        {/* <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GroupEmployeeListItemStyles.infoSubDiv)}>
            <div>
              <img src={TimesheetWrenchIcon} alt={"timesheet-wrench-icon"} />
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GroupEmployeeListItemStyles.infoSubText)}>
              Civil Engineer
            </div>
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GroupEmployeeListItemStyles.infoSubDiv)}>
            <div>
              <img src={TimesheetLocationIcon} alt={"timesheet-location-icon"} />
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GroupEmployeeListItemStyles.infoSubText)}>HQ</div>
          </div> */}
      </div>
    </div>
  );
};
export default GroupUserItem;
