import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";

import GroupMemberItem from "../groupmemberitem/GroupMemberItem.tsx";

import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";

import AddEntityActionButton from "../addentityactionbutton/AddEntityActionButton.tsx";
import TimesheetGroupMemberSectionGrayIcon from "../../../../../assets/images/timesheet-group-member-section-gray-icon.svg";
import { ProjectTimesheetGroupPopupModeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";
import { Skeleton } from "@mui/material";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";

import { useHandleUnauthorized } from "../../../../../utils/use-handle-unauthorized.ts";

interface SectionHeadProps {
  projectId: number;
  groupId: number;
  setPopupMode: Dispatch<SetStateAction<ProjectTimesheetGroupPopupModeEnum>>;
  popupMode: ProjectTimesheetGroupPopupModeEnum;
}

const SectionHead: FC<SectionHeadProps> = ({ projectId, groupId, popupMode, setPopupMode }) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getTimesheetGroupSectionHead } = useEpcmApiProjectsTimesheetsGroups();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const { handleErrorRedirect } = useHandleUnauthorized();

  const canUpdateTimesheetGroups = canPerformProjectAction(ProjectAction.ProjectTimesheetGroupUpdate);

  const timesheetGroupSectionHeadQuery = useQuery({
    queryKey: [QueryNames.TimesheetGroupSectionHead, projectId, groupId],
    queryFn: () => getTimesheetGroupSectionHead(Number(projectId), groupId).catch(handleErrorRedirect),
    enabled: isAuthorized,
  });

  const timesheetGroupSectionHeadData = useMemo(() => {
    return timesheetGroupSectionHeadQuery.data ? timesheetGroupSectionHeadQuery.data : null;
  }, [timesheetGroupSectionHeadQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.TimesheetGroupSectionHead, projectId, groupId] })
        .then(() => console.log(`Query ${QueryNames.TimesheetGroupSectionHead} cancelled`));
    };
  }, [queryClient, projectId, groupId]);

  return (
    <>
      <div>
        {timesheetGroupSectionHeadData?.sectionHead ? (
          <GroupMemberItem
            projectId={projectId}
            groupId={groupId}
            isSectionHead
            projectEmployeeId={Number(timesheetGroupSectionHeadData.sectionHead?.code)}
            globalUserName={timesheetGroupSectionHeadData.sectionHead?.name}
            globalUserCode={timesheetGroupSectionHeadData.sectionHead?.code}
            canBeDeleted={false}
          />
        ) : timesheetGroupSectionHeadQuery.isLoading ? (
          <Skeleton variant="rounded" width={495} height={60} />
        ) : (
          popupMode === ProjectTimesheetGroupPopupModeEnum.view &&
          canUpdateTimesheetGroups && (
            <AddEntityActionButton
              icon={TimesheetGroupMemberSectionGrayIcon}
              text={"Set a section head from the available users." + `\n` + "You can replace it later"}
              actionBtnText={"Add"}
              actionFn={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.addSection)}
            />
          )
        )}
      </div>
    </>
  );
};
export default SectionHead;
