import { QueryModelWithCount } from "../../../../types/apicallstypes/queryCommons.ts";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { AxiosRequestConfig } from "axios";

const API = `${EPCM_API_URLS.projects}`;

export const getAllProjectEmployeesByPeriodCount = async (
  id: number,
  periodStart?: number,
  periodEnd?: number,
  active?: boolean,
  notActive?: boolean,
  inTimesheetReviewed?: boolean,
  inTimesheetPrepared?: boolean,
  inTimesheetPending?: boolean,
  notInTimesheet?: boolean,
  ungrouped?: boolean,
): Promise<QueryModelWithCount> => {
  const params = new URLSearchParams();
  periodStart && params.set("periodStart", `${periodStart}`);
  periodEnd && params.set("periodEnd", `${periodEnd}`);
  active && params.set("active", `${active}`);
  notActive && params.set("notActive", `${notActive}`);
  inTimesheetReviewed && params.set("inTimesheetReviewed", `${inTimesheetReviewed}`);
  inTimesheetPrepared && params.set("inTimesheetPrepared", `${inTimesheetPrepared}`);
  inTimesheetPending && params.set("inTimesheetPending", `${inTimesheetPending}`);
  notInTimesheet && params.set("notInTimesheet", `${notInTimesheet}`);
  ungrouped && params.set("ungrouped", `${ungrouped}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/counters/employees`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data as QueryModelWithCount;
};
