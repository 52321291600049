import { CalendarMonthsLimited } from "epcm-common/dist/Types/CalendarMonthsTypes.ts";
import { FrontendCalendarMonthItemType } from "../../../../CalendarApiTypes.ts";
import { ApiResponseTimesheetCreationDates } from "epcm-common/dist/Types/TimesheetTypes";
import { FrontendTimesheetCreationDates, FrontendTimesheetPeriodType } from "../../../../ProjectTimesheetsApiTypes.ts";

export const convertProjectTimesheetCalendarMonthApiConverter = (
  projectTimesheetCalendarMonths: CalendarMonthsLimited[],
): FrontendCalendarMonthItemType[] => {
  return projectTimesheetCalendarMonths.map((projectTimesheetCalendarMonthItem): FrontendCalendarMonthItemType => {
    return {
      id: projectTimesheetCalendarMonthItem.id,
      monthNumber: projectTimesheetCalendarMonthItem.monthNumber,
      rangeStartDate: new Date(projectTimesheetCalendarMonthItem.rangeStartDate),
      rangeEndDate: new Date(projectTimesheetCalendarMonthItem.rangeEndDate),
      displayName: projectTimesheetCalendarMonthItem.displayName,
    };
  });
};

export const convertProjectTimesheetCalendarMonthPeriodApiConverter = (
  timesheetPeriods: ApiResponseTimesheetCreationDates,
): FrontendTimesheetCreationDates => {
  return {
    periods: timesheetPeriods.periods.map((periodItem): FrontendTimesheetPeriodType => {
      return {
        periodStart: new Date(periodItem.periodStart),
        periodEnd: new Date(periodItem.periodEnd),
      };
    }),
  };
};
