import classNames from "classnames";
import GlobalConfigurationCategoriesPopupStyles from "./GlobalConfigurationCategoriesPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { PopupButtonTypeEnum, PopupType } from "../../../../../types/PopupType.ts";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PopupStructure } from "../../../../../ui/popupstructure/PopupStructure.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { FrontendGlobalCategoryType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";

interface GlobalConfigurationCategoriesPopupProps extends PopupType {
  categoryItem?: FrontendGlobalCategoryType;
  mode: configurationsPopupType;
}

const GlobalConfigurationCategoriesPopup: FC<GlobalConfigurationCategoriesPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  categoryItem,
  mode,
}) => {
  const [categoryName, setCategoryName] = useState<string>(categoryItem?.description || "");
  const [categoryCode, setCategoryCode] = useState<string>(categoryItem?.code || "");

  useEffect(() => {
    if (categoryItem) {
      setCategoryName(categoryItem.description);
      setCategoryCode(categoryItem.code);
    }
  }, [categoryItem]);

  const { useCreateGlobalEntityMutation, useUpdateGlobalEntityMutation } = useEpcmApiConfigurationsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createGlobalCategoryMutation = useCreateGlobalEntityMutation(FrontendGlobalConfigurationsEntityEnum.Category, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global Category was added successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onCreateGlobalCategory = useCallback(() => {
    createGlobalCategoryMutation.mutate({
      code: categoryCode,
      description: categoryName,
    });
    onOpenLoaderPopup();
  }, [createGlobalCategoryMutation, categoryName, categoryCode, onOpenLoaderPopup]);

  const updateGlobalCategoryMutation = useUpdateGlobalEntityMutation(categoryItem?.id ?? 0, FrontendGlobalConfigurationsEntityEnum.Category, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global Category was updated successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onUpdateGlobalCategory = useCallback(() => {
    updateGlobalCategoryMutation.mutate({
      code: categoryCode,
      description: categoryName,
    });
    onOpenLoaderPopup();
  }, [updateGlobalCategoryMutation, categoryName, categoryCode, onOpenLoaderPopup]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalCategories] });
  }, [queryClient]);

  const isEitherInputEmpty = useMemo(() => !categoryName || !categoryCode, [categoryName, categoryCode]);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(GlobalConfigurationCategoriesPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Category" : "Create New Category",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !isEitherInputEmpty && onUpdateGlobalCategory();
            } else {
              !isEitherInputEmpty && onCreateGlobalCategory();
            }
          },
          tooltipText: isEitherInputEmpty ? "Please enter both category code and category description" : "",
          disabled: isEitherInputEmpty,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalConfigurationCategoriesPopupStyles.mainContainer,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationCategoriesPopupStyles.infoText)}>Category Code</div>
          <input
            placeholder={"Type a category code..."}
            className={classNames(
              GlobalConfigurationCategoriesPopupStyles.infoContainer,
              GlobalConfigurationCategoriesPopupStyles.infoContainerInput,
            )}
            required
            value={categoryCode}
            onChange={(e) => setCategoryCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationCategoriesPopupStyles.infoText)}>Category Name</div>
          <input
            placeholder={"Type a category name..."}
            className={classNames(
              GlobalConfigurationCategoriesPopupStyles.infoContainer,
              GlobalConfigurationCategoriesPopupStyles.infoContainerInput,
            )}
            required
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          ></input>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default GlobalConfigurationCategoriesPopup;
