import { create } from "zustand";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { persist } from "zustand/middleware";
import { EPCM_IsImpersonationEnabled } from "../apicalls/config.ts";

interface ImpersonationState {
  impersonateUser: string | null;
  impersonateUserBase64: string | null;
  isLoggedIn: boolean;
  isAuthorized: () => boolean;
  setImpersonationUsername: (impersonateUsername: string) => void;
  loginUser: (impersonateCredentials: UserCredentials) => void;
}

interface UserCredentials {
  username: string;
  password: string;
}

export const useImpersonationStore = create<ImpersonationState>()(
  persist(
    (set, get) => ({
      impersonateUser: null,
      impersonateUserBase64: null,
      isLoggedIn: get()?.isLoggedIn,
      isAuthorized: () => !!get().impersonateUserBase64 || !EPCM_IsImpersonationEnabled,
      setImpersonationUsername: (impersonateUsername: string) => {
        const loggedUserToken = get().impersonateUserBase64;
        if (loggedUserToken) {
          const savedPassword = base64_decode(loggedUserToken).split(":")[1];
          const impersonateUserBase64 = base64_encode(`${impersonateUsername}:${savedPassword}`);
          console.log("impersonationSlice::setImpersonationUsername", "New impersonation :", impersonateUsername);
          set((state) => ({
            ...state,
            impersonateUser: impersonateUsername,
            impersonateUserBase64: impersonateUserBase64,
          }));
        }
      },
      loginUser: (impersonateCredentials: UserCredentials) => {
        set((state) => ({
          ...state,
          impersonateUser: impersonateCredentials.username,
          impersonateUserBase64: base64_encode(`${impersonateCredentials.username}:${impersonateCredentials.password}`),
          isLoggedIn: true,
        }));
      },
    }),
    {
      name: "impersonation-storage",
    },
  ),
);
