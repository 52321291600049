import { FrontendProjectEmployeeLimited, FrontendProjectEmployeeNotInTimesheet } from "../../../ProjectEmployeesApiTypes.ts";
import { ProjectEmployeeLimited } from "epcm-common/dist/Types/ProjectEmployeeTypes";
import { ProjectEmployeeNotInTimesheet } from "epcm-common/dist/Types/ProjectEmployeeTypes.ts";

export const convertProjectEmployeeLimitedData = (projectEmployeeData: ProjectEmployeeLimited): FrontendProjectEmployeeLimited => {
  return {
    id: projectEmployeeData.id,
    employeeId: projectEmployeeData.employeeId,
  };
};

export const convertProjectEmployeesLimitedData = (projectEmployeesData: ProjectEmployeeLimited[]): FrontendProjectEmployeeLimited[] => {
  return projectEmployeesData.map((projectEmployee) => {
    return convertProjectEmployeeLimitedData(projectEmployee);
  });
};
export const convertProjectEmployeesNotInTimesheetLimitedData = (
  projectEmployeesData: ProjectEmployeeNotInTimesheet[],
): FrontendProjectEmployeeNotInTimesheet[] => {
  return projectEmployeesData.map((projectEmployee) => {
    return {
      projectEmployeeId: projectEmployee.projectEmployeeId,
      employeeName: projectEmployee.employeeName,
      timesheetGroupId: projectEmployee.timesheetGroupId,
      timesheetGroupName: projectEmployee.timesheetGroupName,
    };
  });
};
