import employeeAvatar1 from "../../assets/images/employee-pic-1.svg";
import employeeAvatar2 from "../../assets/images/employee-pic-2.svg";
import employeeAvatar3 from "../../assets/images/employee-pic-3.svg";
import employeeAvatar4 from "../../assets/images/employee-pic-4.svg";
import employeeAvatar5 from "../../assets/images/employee-pic-5.svg";
import employeeAvatar6 from "../../assets/images/employee-pic-6.svg";
import employeeAvatar7 from "../../assets/images/employee-pic-7.svg";
import employeeAvatar8 from "../../assets/images/employee-pic-8.svg";
import employeeAvatar9 from "../../assets/images/employee-pic-9.svg";
import employeeAvatar10 from "../../assets/images/employee-pic-10.svg";
import employeeAvatar11 from "../../assets/images/employee-pic-11.svg";
import employeeAvatar12 from "../../assets/images/employee-pic-12.svg";
import { MmrProjectEmployeeStatus, MmrStatus } from "epcm-common/dist/Types/MmrTypes.ts";

export enum MobilizationStatusEnum {
  approved = "approved",
  pending = "pending",
  rejected = "rejected",
  noMMR = "no MMR",
}

export enum FrontendMmrStatusQueryEnum {
  notEligible = "notEligible",
  FUTURE = "future",
  ACTIVE = "active",
  notApproved = "notApproved",
  demobilized = "demobilized",
}

export interface FrontendProjectEmployee {
  id: number;
  name: string;
  mmrId: number;
  mmrStatus: MmrStatus;
  projectEmployeeToMmrStatus: MmrProjectEmployeeStatus;
  employeeId: number;
  employeeCode: string;
}

export interface ProjectEmployeeType {
  id: string;
  name: string;
  mobilizationStatus: MobilizationStatusEnum;
  location: string;
  role: string;
  avatar?: string;
  mmrFile?: string;
  hasMissingData?: boolean;
}

export interface FrontendProjectEmployeeBasicType {
  id: number;
  employeeId: number;
  name: string;
  code: string;
}

export enum ProjectEmployeeDetailsPopupTypeEnum {
  view = "view",
  create = "create",
  edit = "edit",
}

const avatarList = [
  employeeAvatar1,
  employeeAvatar2,
  employeeAvatar3,
  employeeAvatar4,
  employeeAvatar5,
  employeeAvatar6,
  employeeAvatar7,
  employeeAvatar8,
  employeeAvatar9,
  employeeAvatar10,
  employeeAvatar11,
  employeeAvatar12,
];

const getRandomAvatar = (): string | undefined => {
  return Math.random() > 0.5 ? avatarList[Math.floor(Math.random() * avatarList.length)] : undefined;
};

export const PROJECT_EMPLOYEE_LIST: ProjectEmployeeType[] = [
  {
    id: "AR-123456788",
    name: "Fletch Skinner",
    mobilizationStatus: MobilizationStatusEnum.approved,
    mmrFile: "WAVE-18421-MMR-057643-Ume.xlsx",
    location: "HO",
    role: "Project Engineer",
    avatar: getRandomAvatar(),
  },
  {
    id: "AR-35768241",
    name: "Alan Fresco",
    mobilizationStatus: MobilizationStatusEnum.pending,
    mmrFile: "WAVE-18421-MMR-044456-Topr.xlsx",
    location: "HO",
    role: "Deputy Project Manager",
    avatar: getRandomAvatar(),
    hasMissingData: true,
  },
  {
    id: "AR-6576321987",
    name: "Barry Tone",
    mobilizationStatus: MobilizationStatusEnum.approved,
    mmrFile: "WAVE-18421-MMR-057643-EMU.xlsx",
    location: "SO",
    role: "Electrician",
    avatar: getRandomAvatar(),
  },
  {
    id: "AR-654321987",
    name: "Larry Edwards",
    mobilizationStatus: MobilizationStatusEnum.rejected,
    mmrFile: "WAVE-18421-MMR-057643-EMULe.xlsx",
    location: "SO",
    role: "Electrician",
    avatar: getRandomAvatar(),
    hasMissingData: true,
  },
  {
    id: "AR-123753788",
    name: "Nicolas Smith",
    mobilizationStatus: MobilizationStatusEnum.noMMR,
    mmrFile: undefined,
    location: "HO",
    role: "Secretary",
    avatar: getRandomAvatar(),
  },
  {
    id: "AR-654852158",
    name: "Jarvis Deppers",
    mobilizationStatus: MobilizationStatusEnum.approved,
    mmrFile: "WAVE-18421-MMR-057621-EMI.xlsx",
    location: "SO",
    role: "Project Management",
    avatar: getRandomAvatar(),
  },
  {
    id: "AR-165456784",
    name: "Phillip Anthropy",
    mobilizationStatus: MobilizationStatusEnum.pending,
    mmrFile: "WAVE-18421-MMR-057643-Ume.xlsx",
    location: "HO",
    role: "Project Engineer",
    avatar: getRandomAvatar(),
  },
  {
    id: "AR-453456789",
    name: "Hugh Saturation",
    mobilizationStatus: MobilizationStatusEnum.approved,
    mmrFile: "WAVE-18421-MMR-457643-Ume.xlsx",
    location: "Some location",
    role: "Project Management",
    avatar: getRandomAvatar(),
  },
  {
    id: "AR-436556780",
    name: "John Willies",
    mobilizationStatus: MobilizationStatusEnum.rejected,
    mmrFile: "WAVE-18421-MMR-057665-Ame.xlsx",
    location: "HO",
    role: "Project Engineer",
    avatar: getRandomAvatar(),
    hasMissingData: true,
  },
  {
    id: "AR-654546159",
    name: "George Michael",
    mobilizationStatus: MobilizationStatusEnum.approved,
    mmrFile: "WAVE-18421-MMR-057621-EMIRE.xlsx",
    location: "SO",
    role: "Project Management",
    avatar: getRandomAvatar(),
  },
  {
    id: "AR-35776441",
    name: "Theodore Mantis",
    mobilizationStatus: MobilizationStatusEnum.approved,
    mmrFile: "WAVE-18421t45-MMR-044456-Topr.xlsx",
    location: "HO",
    role: "Deputy Project Manager",
    avatar: getRandomAvatar(),
  },
  {
    id: "AR-165452389",
    name: "Jakob Downy",
    mobilizationStatus: MobilizationStatusEnum.approved,
    mmrFile: "WAVE-18421-MMR-057643-Ume.xlsx",
    location: "HO",
    role: "Project Engineer",
    avatar: getRandomAvatar(),
    hasMissingData: true,
  },
  {
    id: "AR-453478586",
    name: "Stephen Derek",
    mobilizationStatus: MobilizationStatusEnum.pending,
    mmrFile: "WAVE-18421-MMR-457643re-Ume.xlsx",
    location: "Some Other location (SOL)",
    role: "Project Management",
    avatar: getRandomAvatar(),
  },
];

export enum MmrStatusEnum {
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
}

export enum MmrToolTipItem {
  CLONE = "clone",
  CHANGE_STATUS = "change_status",
  EDIT = "edit",
  DELETE = "delete",
  NONE = "none",
}
