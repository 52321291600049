import { FC, useEffect, useMemo, useState } from "react";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import EmployeeListItemStyles from "./EmployeeListItem.module.css";
import classNames from "classnames";
import { FrontendEmployeeDetailedType, FrontendEmployeeLimitedType } from "../../../../types/apicallstypes/EmployeesApiTypes.ts";
import avatarDefault from "../../../../assets/images/profile-default-avatar-white.svg";
import envelopeIcon from "../../../../assets/images/envelope-gray-fill.svg";
import settingsIcon from "../../../../assets/images/setting-dots-black.svg";
import flagIcon from "../../../../assets/images/flag-gray-fill.svg";
import cakeIcon from "../../../../assets/images/cake-gray-fill.svg";
import downloadIcon from "../../../../assets/images/download-icon-dark-blue-bg.svg";
import downloadIconHovered from "../../../../assets/images/download-icon-green-bg.svg";
import editIcon from "../../../../assets/images/pencil-black.svg";
import editIconHovered from "../../../../assets/images/pencil-green.svg";
import { useEpcmApiEmployees } from "../../../../apicalls/employees/useEpcmApiEmployees.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { calculateAge } from "../../../../utils/DateManipulation.ts";
import { capitalizeFirstLetter, getSubstringBasedOnChars } from "../../../../utils/StringManipulation.ts";
import { displayNumberWithSpecificNumberOfDigits } from "../../../../utils/NumberManipulation.ts";
import { ClickAwayListener, Fade, Skeleton, Tooltip } from "@mui/material";
import { EpcmTooltip } from "../../../../ui/epcmtooltip/EpcmTooltip.tsx";
import { EmployeeProfilePopupTypeEnum } from "../../../../types/employees/EmployeesTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../../../types/Roles.ts";

interface EmployeeListItemProps {
  employeeItem: FrontendEmployeeLimitedType;
  onOpenEmployeeProfile: (employeeId: number, popupType: EmployeeProfilePopupTypeEnum) => void;
}

enum EmployeeTooltipOptionHoverEnum {
  downloadCV = "downloadCV",
  editProfile = "editProfile",
  deleteEmployee = "deleteEmployee",
}

export const EmployeeListItem: FC<EmployeeListItemProps> = ({ employeeItem, onOpenEmployeeProfile }) => {
  const { getEmployee } = useEpcmApiEmployees();
  // const { useDeleteEmployeeMutation } = useEpcmApiEmployeesMutations();
  // const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();

  const canEditEmployee = canPerformGlobalAction(GlobalAction.EmployeeUpdate);

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const [employeeTooltipOptionHovered, setEmployeeTooltipOptionHovered] = useState<EmployeeTooltipOptionHoverEnum | null>(null);

  const employeeQuery = useQuery({
    queryKey: [QueryNames.Employees, employeeItem.id],
    queryFn: () => getEmployee(employeeItem.id, DetailLevel.DETAILED),
    enabled: isAuthorized,
    select: (data) => data as FrontendEmployeeDetailedType,
  });
  const employeeData = useMemo(() => {
    return employeeQuery.data ?? undefined;
  }, [employeeQuery]);

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };
  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const employeeSettingsTooltip = (
    <div className={classNames(EmployeeListItemStyles.employeeTooltipContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.gap)}
        onMouseEnter={() => setEmployeeTooltipOptionHovered(EmployeeTooltipOptionHoverEnum.downloadCV)}
        onMouseLeave={() => setEmployeeTooltipOptionHovered(null)}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img
            className={classNames(EmployeeListItemStyles.employeeTooltipIconImg)}
            src={employeeTooltipOptionHovered === EmployeeTooltipOptionHoverEnum.downloadCV ? downloadIconHovered : downloadIcon}
            alt="downloadTemplate"
          />
        </div>
        <div className={classNames(GlobalStyles.centerVertical, EmployeeListItemStyles.toolTipText)}>{"Download CV"}</div>
      </div>
      {canEditEmployee && (
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.gap)}
          onMouseEnter={() => setEmployeeTooltipOptionHovered(EmployeeTooltipOptionHoverEnum.editProfile)}
          onMouseLeave={() => setEmployeeTooltipOptionHovered(null)}
          onClick={() => onOpenEmployeeProfile(employeeItem.id, EmployeeProfilePopupTypeEnum.edit)}
        >
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img
              className={classNames(EmployeeListItemStyles.employeeTooltipIconImg)}
              src={employeeTooltipOptionHovered === EmployeeTooltipOptionHoverEnum.editProfile ? editIconHovered : editIcon}
              alt="edit"
            />
          </div>
          <div className={classNames(GlobalStyles.centerVertical, EmployeeListItemStyles.toolTipText)}>{"Edit Profile"}</div>
        </div>
      )}
    </div>
  );

  const employeeItemSkeletonLoader = (
    <div className={classNames(EmployeeListItemStyles.employeeItemContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      <div className={classNames(EmployeeListItemStyles.settingsBar, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="circular" height={20} width={20} />
        </div>
        <div className={classNames(EmployeeListItemStyles.settingsBarText, GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={15} width={120} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div>
          <div className={classNames(GlobalStyles.centerVertical)}>
            <Skeleton variant="circular" height={20} width={20} />
          </div>
        </div>
      </div>
      <div className={classNames(EmployeeListItemStyles.employeeInfoPanel, GlobalStyles.flex, GlobalStyles.flex1)}>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
          <div className={classNames(GlobalStyles.flex)}>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(GlobalStyles.centerVertical)}>
              <Skeleton variant="circular" height={80} width={80} />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.flex)}>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(GlobalStyles.flex3)}>
              <Skeleton variant="rounded" height={90} />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap15)}>
              <div className={classNames(GlobalStyles.flex1)} />
              <div className={classNames(GlobalStyles.flex2)}>
                <Skeleton variant="rounded" height={20} />
              </div>
              <div className={classNames(GlobalStyles.flex2)}>
                <Skeleton variant="rounded" height={20} />
              </div>
              <div className={classNames(GlobalStyles.flex1)} />
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
              <Skeleton variant="rounded" height={20} width={90} />
              <Skeleton variant="rounded" height={20} width={90} />
              <Skeleton variant="rounded" height={20} width={90} />
            </div>
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
        <Skeleton variant="rounded" height={0} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
          <div className={classNames(GlobalStyles.flex1)}>
            <Skeleton variant="rounded" height={0} />
          </div>
          <div className={classNames(GlobalStyles.flex1)}>
            <Skeleton variant="rounded" height={20} />
          </div>
          <div className={classNames(GlobalStyles.flex1)}>
            <Skeleton variant="rounded" height={0} />
          </div>
        </div>
        <Skeleton variant="rounded" height={15} width={0} />
      </div>
    </div>
  );

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Employees, employeeItem.id] })
        .then(() => console.log(`In employees, employee with id ${employeeItem.id}, ${QueryNames.Employees} query canceled`));
    };
  }, [queryClient, employeeItem]);

  return (
    <div>
      {employeeData ? (
        <div
          className={classNames(EmployeeListItemStyles.employeeItemContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
        >
          <EpcmTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={isTooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={employeeSettingsTooltip}
            arrow
            placement={"right"}
          >
            <div className={classNames(EmployeeListItemStyles.settingsBar, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img className={classNames(EmployeeListItemStyles.smallIconImg)} src={envelopeIcon} alt="category" />
              </div>
              <div className={classNames(EmployeeListItemStyles.settingsBarText, GlobalStyles.centerVertical)}>{employeeData.category}</div>
              <div className={classNames(GlobalStyles.flex1)} />
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div className={classNames(EmployeeListItemStyles.settingsIcon, GlobalStyles.centerVertical)} onClick={handleTooltipOpen}>
                  <img className={classNames(EmployeeListItemStyles.settingsIconImg)} src={settingsIcon} alt="settings" />
                </div>
              </ClickAwayListener>
            </div>
          </EpcmTooltip>
          <div className={classNames(EmployeeListItemStyles.employeeInfoPanel, GlobalStyles.flex, GlobalStyles.flex1)}>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15)}>
              <div className={classNames(GlobalStyles.flex)}>
                <div className={classNames(GlobalStyles.flex1)} />
                <div className={classNames(EmployeeListItemStyles.defaultAvatar, GlobalStyles.centerVertical)}>
                  <img className={classNames(EmployeeListItemStyles.defaultAvatarImg)} src={avatarDefault} alt="avatar" />
                </div>
                <div className={classNames(GlobalStyles.flex1)} />
              </div>
              <div
                className={classNames(
                  EmployeeListItemStyles.employeeBasicInfoContainer,
                  GlobalStyles.flex,
                  GlobalStyles.flexDirectionColumn,
                  GlobalStyles.gap05,
                )}
              >
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
                  <Tooltip
                    TransitionComponent={Fade}
                    title={employeeData.name !== getSubstringBasedOnChars(employeeData.name, 25) ? employeeData.name : ""}
                    placement="top"
                    arrow
                  >
                    <div className={classNames(EmployeeListItemStyles.highlightedText)}>{getSubstringBasedOnChars(employeeData.name, 25)}</div>
                  </Tooltip>
                  <div className={classNames(EmployeeListItemStyles.secondaryText)}>{`EmpCode:${employeeData.code}`}</div>
                </div>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}>
                  <div className={classNames(GlobalStyles.flex1)} />
                  {employeeData.globalPosition ? (
                    <Tooltip
                      TransitionComponent={Fade}
                      title={
                        employeeData.globalPosition
                          ? employeeData.globalPosition.description !== getSubstringBasedOnChars(employeeData.globalPosition.description, 20)
                            ? employeeData.globalPosition.description
                            : ""
                          : ""
                      }
                      placement="top"
                      arrow
                    >
                      {
                        <div className={classNames(EmployeeListItemStyles.circularContainer)}>
                          {getSubstringBasedOnChars(employeeData.globalPosition.description, 20)}
                        </div>
                      }
                    </Tooltip>
                  ) : (
                    <div className={classNames(EmployeeListItemStyles.circularContainer)}>{`No position`}</div>
                  )}
                  <div className={classNames(GlobalStyles.flex1)} />
                </div>
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap15)}>
                  <div className={classNames(GlobalStyles.flex1)} />
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flex2, GlobalStyles.gap05)}>
                    <div className={classNames(GlobalStyles.centerVertical)}>
                      <img className={classNames(EmployeeListItemStyles.smallIconImg)} src={flagIcon} alt="nationality" />
                    </div>
                    {employeeData.nationality && (
                      <Tooltip
                        TransitionComponent={Fade}
                        title={
                          employeeData.nationality
                            .split(" ")
                            .map((wordItem) => capitalizeFirstLetter(wordItem))
                            .join(" ") !==
                          getSubstringBasedOnChars(
                            employeeData.nationality
                              .split(" ")
                              .map((wordItem) => capitalizeFirstLetter(wordItem))
                              .join(" "),
                          )
                            ? employeeData.nationality
                                .split(" ")
                                .map((wordItem) => capitalizeFirstLetter(wordItem))
                                .join(" ")
                            : ""
                        }
                        placement="top"
                        arrow
                      >
                        <div className={classNames(GlobalStyles.centerVertical)}>
                          {getSubstringBasedOnChars(
                            employeeData.nationality
                              .split(" ")
                              .map((wordItem) => capitalizeFirstLetter(wordItem))
                              .join(" "),
                          )}
                        </div>
                      </Tooltip>
                    )}
                  </div>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flex2, GlobalStyles.gap05)}>
                    <div className={classNames(GlobalStyles.centerVertical)}>
                      <img className={classNames(EmployeeListItemStyles.smallIconImg)} src={cakeIcon} alt="dob" />
                    </div>
                    <div className={classNames(GlobalStyles.centerVertical)}>
                      {employeeData.dateOfBirth ? `${displayNumberWithSpecificNumberOfDigits(calculateAge(employeeData.dateOfBirth))} Y/O` : ""}
                    </div>
                  </div>
                  <div className={classNames(GlobalStyles.flex1)} />
                </div>
                <div className={classNames(EmployeeListItemStyles.languageListContainer, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
                  {employeeData.languages ? (
                    employeeData.languages.slice(0, 3).map((languageItem, index) => (
                      <div key={index} className={classNames(EmployeeListItemStyles.circularContainerLight, GlobalStyles.centerVertical)}>
                        {languageItem}
                      </div>
                    ))
                  ) : (
                    <div className={classNames(EmployeeListItemStyles.circularContainerLight, GlobalStyles.centerVertical)}>{"No languages"}</div>
                  )}
                  {employeeData.languages && employeeData.languages.length > 3 && (
                    <div
                      className={classNames(EmployeeListItemStyles.circularContainerLight, GlobalStyles.centerVertical)}
                    >{`+${displayNumberWithSpecificNumberOfDigits(employeeData.languages.length - 3)}`}</div>
                  )}
                </div>
              </div>
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div
            className={classNames(EmployeeListItemStyles.viewProfileButton)}
            onClick={() => onOpenEmployeeProfile(employeeItem.id, EmployeeProfilePopupTypeEnum.view)}
          >
            {"View Profile"}
          </div>
        </div>
      ) : (
        employeeItemSkeletonLoader
      )}

      {/*{isUtilPopupOpen && (*/}
      {/*  <DeletePopup*/}
      {/*    isOpen={isUtilPopupOpen}*/}
      {/*    closeFn={() => {*/}
      {/*      onCloseUtilPopup();*/}
      {/*    }}*/}
      {/*    // TODO: set onDeleteFn to mutation when implemented in backend*/}
      {/*    // onDeleteFn={() => deleteEmployeesMutation.mutate()}*/}
      {/*    onDeleteFn={() => {}}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};
