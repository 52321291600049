import classNames from "classnames";
import ConfigurationTradesPopupStyles from "./ConfigurationCategoriesPopup.module.css";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import TimesheetGroupSettingsMainIcon from "../../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";

import { FC, useCallback, useMemo, useState, useEffect } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { PopupButtonTypeEnum, PopupType } from "../../../../../../types/PopupType.ts";
import { FrontendProjectCategoryType } from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { PopupStructure } from "../../../../../../ui/popupstructure/PopupStructure.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";

interface ConfigurationCategoriesPopupProps extends PopupType {
  projectId: number;
  categoryItem?: FrontendProjectCategoryType;
  mode: configurationsPopupType;
}

const ConfigurationCategoriesPopup: FC<ConfigurationCategoriesPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  projectId,
  categoryItem,
  mode,
}) => {
  const [categoryName, setCategoryName] = useState<string>(categoryItem?.description || "");
  const [categoryCode, setCategoryCode] = useState<string>(categoryItem?.code || "");

  useEffect(() => {
    if (categoryItem) {
      setCategoryName(categoryItem.description);
      setCategoryCode(categoryItem.code);
    }
  }, [categoryItem]);

  const { useCreateProjectEntityMutation, useUpdateProjectEntityMutation } = useEpcmApiProjectUtilsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createProjectCategoryMutation = useCreateProjectEntityMutation(projectId!, FrontendProjectConfigurationsEntityEnum.Category, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Category was added to project successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateProjectCategoryMutation = useUpdateProjectEntityMutation(
    projectId!,
    categoryItem?.id ?? 0,
    FrontendProjectConfigurationsEntityEnum.Category,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: `Category was updated successfully` });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );

  const onCreateProjectCategory = useCallback(() => {
    createProjectCategoryMutation.mutate({
      code: categoryCode,
      description: categoryName,
    });
    onOpenLoaderPopup();
  }, [createProjectCategoryMutation, categoryName, categoryCode, onOpenLoaderPopup]);

  const onUpdateProjectCategory = useCallback(() => {
    updateProjectCategoryMutation.mutate({
      code: categoryCode,
      description: categoryName,
    });
    onOpenLoaderPopup();
  }, [updateProjectCategoryMutation, categoryName, categoryCode, onOpenLoaderPopup]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectCategories, projectId] });
  }, [queryClient, projectId]);

  const isEitherInputEmpty = useMemo(() => !categoryName || !categoryCode, [categoryName, categoryCode]);

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(ConfigurationTradesPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Category" : "Create New Category",
          tooltipText: isEitherInputEmpty ? "Please enter both category code and category description " : "",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !!categoryCode && !!categoryName && onUpdateProjectCategory();
            } else {
              !!categoryCode && !!categoryName && onCreateProjectCategory();
            }
          },
          disabled: isEitherInputEmpty,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationTradesPopupStyles.mainContainer)}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationTradesPopupStyles.infoText)}>Category Code</div>
          <input
            placeholder={"Type a category code..."}
            className={classNames(ConfigurationTradesPopupStyles.infoContainer, ConfigurationTradesPopupStyles.infoContainerInput)}
            required
            value={categoryCode}
            onChange={(e) => setCategoryCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationTradesPopupStyles.infoText)}>Category Description</div>
          <input
            placeholder={"Type a category description..."}
            className={classNames(ConfigurationTradesPopupStyles.infoContainer, ConfigurationTradesPopupStyles.infoContainerInput)}
            required
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          ></input>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ConfigurationCategoriesPopup;
