import { EpcmMutationOptionsType } from "../../../config.ts";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { ErrorCallbackDataType, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { ApiRequestBodyProjectRoles } from "epcm-common/dist/Types/RoleTypes";
import updateProjectPermissionMutation from "./updateProjectPermissionMutation.ts";
import deleteUserFromProjectPermissions from "./deleteUserFromProjectPermissions.ts";

interface ApiDefinition {
  useUpdateProjectPermissionMutation: (
    projectId: number,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyProjectRoles>;

  useDeleteProjectPermissionMutation: (
    projectId: number,
    userId: string,
    mutationOptions: EpcmMutationOptionsType,
  ) => UseMutationResult<SuccessCallbackDataType, ErrorCallbackDataType, void>;
}

export const useEpcmApiProjectPermissionsMutation = (): ApiDefinition => {
  const useUpdateProjectPermissionMutation = (projectId: number, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, ApiRequestBodyProjectRoles>({
      mutationFn: async (userProjectRoles: ApiRequestBodyProjectRoles) => {
        return updateProjectPermissionMutation(projectId, userProjectRoles);
      },
      onSuccess: (data) => {
        console.log("on post update project permission mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post update project permission mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  const useDeleteProjectPermissionMutation = (projectId: number, userId: string, mutationOptions: EpcmMutationOptionsType) => {
    return useMutation<SuccessCallbackDataType, ErrorCallbackDataType, void>({
      mutationFn: async () => {
        return deleteUserFromProjectPermissions(projectId, userId);
      },
      onSuccess: (data) => {
        console.log("on post delete project permission mutation success: ", data);
        mutationOptions.onSuccessCallback && mutationOptions.onSuccessCallback(data);
      },
      onError: (err) => {
        console.log("on post delete project permission mutation error: ", err);
        mutationOptions.onErrorCallback && mutationOptions.onErrorCallback(err);
      },
      onSettled: () => {
        mutationOptions.onSettledCallback && mutationOptions.onSettledCallback();
      },
    });
  };

  return {
    useUpdateProjectPermissionMutation: useUpdateProjectPermissionMutation,
    useDeleteProjectPermissionMutation: useDeleteProjectPermissionMutation,
  };
};
