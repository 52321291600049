import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import classNames from "classnames";
import plusIcon from "../../../../assets/images/add-icon-circled-blue.svg";
import InfoMessageIcon from "../../../../assets/images/info-message-icon.svg";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ConfigurationSetUpCalendarHolidaysPopupStyles from "./ConfigurationSetUpCalendarHoildaysPopup.module.css";
import ConfigurationSetUpCalendarPopupStyles from "../configurationsetupcalendarpopup/ConfigurationSetUpCalendarPopup.module.css";
import LocationHolidayItem from "./locationholiday/LocationHolidayItem";
import { FrontendProjectLocationLimited } from "../../../../types/apicallstypes/ProjectsUtilsApiTypes";
import { FrontendHolidayItem } from "../../../../types/projects/CalendarTypes.ts";
import { useParams } from "react-router-dom";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjects } from "../../../../apicalls/projects/useEpcmApiProjects.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { FrontendProjectDetailedType } from "../../../../types/apicallstypes/ProjectsApiTypes.ts";

interface ConfigurationSetUpCalendarHolidaysPopupProps {
  selectedLocations: FrontendProjectLocationLimited[];
  holidayItems: FrontendHolidayItem[];
  setHolidayItems: Dispatch<SetStateAction<FrontendHolidayItem[]>>;
}

export const ConfigurationSetUpCalendarHolidaysPopup = ({
  selectedLocations,
  holidayItems,
  setHolidayItems,
}: ConfigurationSetUpCalendarHolidaysPopupProps) => {
  const { projectId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getProject } = useEpcmApiProjects();

  const projectDataQuery = useQuery({
    queryKey: [QueryNames.Projects, projectId],
    queryFn: () => getProject(parseInt(projectId!), DetailLevel.DETAILED),
    enabled: isAuthorized,
    select: (data) => data as FrontendProjectDetailedType,
  });

  const projectData = useMemo(() => projectDataQuery.data, [projectDataQuery.data]);

  const addHolidayItem = () => {
    setHolidayItems([
      ...holidayItems,
      {
        locationIds: selectedLocations.map((location) => location.id),
        locationHolidays: { holidayTitle: "", date: null },
        allSelectedLocations: true,
      },
    ]);
  };

  const updateHolidayItem = (index: number, updatedItem: Partial<FrontendHolidayItem>) => {
    setHolidayItems(holidayItems.map((item, i) => (i === index ? { ...item, ...updatedItem } : item)));
  };

  const deleteHolidayItem = (index: number) => {
    //if index==1 just clear, dont delete

    setHolidayItems(holidayItems.filter((_, i) => i !== index));
  };
  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.Projects, projectId] });
    };
  }, [queryClient, projectId]);

  return (
    <>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
        <div className={classNames(ConfigurationSetUpCalendarHolidaysPopupStyles.descriptionText)}>{"Determine calendar Holidays"}</div>
        <div className={classNames(ConfigurationSetUpCalendarHolidaysPopupStyles.parentDiv)}>
          {projectData &&
            holidayItems.map((holidayItem, index) => (
              <LocationHolidayItem
                key={index}
                selectedLocations={selectedLocations}
                holidayDate={holidayItem.locationHolidays.date}
                deleteHolidayItem={() => deleteHolidayItem(index)}
                setHolidayDate={(date) =>
                  updateHolidayItem(index, {
                    locationHolidays: {
                      ...holidayItem.locationHolidays,
                      date: date as Date,
                    },
                  })
                }
                locationIds={holidayItem.locationIds!}
                setLocationIds={(ids) => updateHolidayItem(index, { locationIds: ids })}
                holidayType={holidayItem.locationHolidays.holidayTitle}
                onHolidayTypeChange={(type) =>
                  updateHolidayItem(index, {
                    ...holidayItem,
                    locationHolidays: { ...holidayItem.locationHolidays, holidayTitle: type },
                  })
                }
                isCheckSelected={holidayItem.allSelectedLocations ?? false}
                setIsCheckSelected={(isSelected) => updateHolidayItem(index, { allSelectedLocations: isSelected })}
                projectEndDate={projectData.endDate}
              />
            ))}
        </div>
      </div>
      <div
        className={classNames(ConfigurationSetUpCalendarHolidaysPopupStyles.addHolidayButton, GlobalStyles.flex, GlobalStyles.gap05)}
        onClick={addHolidayItem}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img className={classNames(ConfigurationSetUpCalendarHolidaysPopupStyles.plusIconImg)} src={plusIcon} alt="add" />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>{"Add Holiday"}</div>
      </div>
      <div className={classNames(GlobalStyles.flex1)} />
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
        <img src={InfoMessageIcon} alt="info message icon" />
        <div className={classNames(ConfigurationSetUpCalendarPopupStyles.infoText)}>
          {"You can add or remove Holidays later in the calendar or in the Calendar Settings."}
        </div>
      </div>
    </>
  );
};

export default ConfigurationSetUpCalendarHolidaysPopup;
