import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import ProjectEmployeeAdditionalInfoStyles from "./GlobalEmployeeAdditionalInfo.module.css";
import { FC } from "react";
import GlobalEmployeeAccordionGrouped from "../globalemployeeinfodetailed/globalemployeeaccordiongrouped/GlobalEmployeeAccordionGrouped.tsx";

interface ProjectEmployeeAdditionalInfoProps {
  qualifications: string | undefined;
  certifications: string | undefined;
  experience: string | undefined;
}

export const GlobalEmployeeAdditionalInfo: FC<ProjectEmployeeAdditionalInfoProps> = ({ qualifications, experience, certifications }) => {
  return (
    <div
      className={classNames(
        ProjectEmployeeAdditionalInfoStyles.container,
        GlobalStyles.flex,
        GlobalStyles.flex1,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap025,
        GlobalStyles.overflowHiddenFullHeight,
      )}
    >
      <GlobalEmployeeAccordionGrouped certifications={certifications} experience={experience} qualifications={qualifications} />
    </div>
  );
};
