import classNames from "classnames";
import ChangeMmrStatusContentStyles from "./ChangeMmrStatusContent.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { FrontendMmrStatusEnum } from "../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import checkIcon from "../../../../../assets/images/tick-white.svg";
import xMarkIcon from "../../../../../assets/images/x-mark-white-lines.svg";
import { capitalizeFirstLetter } from "../../../../../utils/StringManipulation.ts";
import uploadedIcon from "../../../../../assets/images/upload-icon-black-lines.svg";
import checkCircledIcon from "../../../../../assets/images/tick-circled.svg";
import xMarkCircledIcon from "../../../../../assets/images/x-mark-circled.svg";
import ChangeMmrStatusStyles from "../../changeentitystatuspopup/changemmrstatuscontent/ChangeMmrStatusContent.module.css";
import { formatDate } from "../../../../../utils/DateManipulation.ts";
import userIcon from "../../../../../assets/images/profile-blue-lines.svg";
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjectEmployee } from "../../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import EpcmDatepickerMui from "../../../../../ui/epcmdatepickermui/EpcmDatepickerMui.tsx";
import { UiComponentGeneralEnum } from "../../../../../types/UiComponentGeneralType.ts";

interface ChangeMmrStatusContentProps {
  currentStatus: FrontendMmrStatusEnum;
  editorCode: string;
  projectEmployeeId: number;
  projectId: number;
  mmrId: number;
  editorName: string;
  editDate: Date | null;
  statusSelected: FrontendMmrStatusEnum;
  statusItem: FrontendMmrStatusEnum;
  customEditDate: Date | null;
  setCustomEditDate: Dispatch<SetStateAction<Date | null>>;
}

const ChangeMmrStatusContent: FC<ChangeMmrStatusContentProps> = ({
  currentStatus,
  editorCode,
  projectEmployeeId,
  projectId,
  mmrId,
  editorName,
  editDate,
  statusSelected,
  statusItem,
  customEditDate,
  setCustomEditDate,
}) => {
  const { getProjectEmployeeMmrDateLimits } = useEpcmApiProjectEmployee();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const getMmrDateLimits = useQuery({
    queryKey: [QueryNames.ProjectMmrDateLimits, projectId, projectEmployeeId, mmrId],
    queryFn: () => getProjectEmployeeMmrDateLimits(projectId, projectEmployeeId, mmrId),
    enabled: isAuthorized,
  });

  const mmrDateLimits = useMemo(() => {
    return getMmrDateLimits.data;
  }, [getMmrDateLimits.data]);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectMmrDateLimits, projectId, projectEmployeeId, mmrId] });
    };
  }, [projectId, projectEmployeeId, mmrId]);

  return (
    <>
      <div className={classNames(ChangeMmrStatusContentStyles.statusContainer, GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
        <div
          className={classNames(
            ChangeMmrStatusContentStyles.checkIcon,
            statusItem === statusSelected &&
              (statusSelected === FrontendMmrStatusEnum.APPROVED
                ? ChangeMmrStatusContentStyles.checkIconApproved
                : statusSelected === FrontendMmrStatusEnum.PENDING
                  ? ChangeMmrStatusContentStyles.checkIconPending
                  : ChangeMmrStatusContentStyles.checkIconRejected),
            GlobalStyles.centerVertical,
          )}
        >
          <img
            src={statusItem !== FrontendMmrStatusEnum.REJECTED ? checkIcon : xMarkIcon}
            alt="check"
            className={classNames(ChangeMmrStatusContentStyles.checkIconImg)}
          />
        </div>
        <div className={classNames(GlobalStyles.centerVertical)}>{capitalizeFirstLetter(statusItem)}</div>
      </div>
      <div
        className={classNames(
          statusItem === currentStatus && ChangeMmrStatusContentStyles.selectedStatusInfoContainer,
          GlobalStyles.flex,
          GlobalStyles.gap05,
        )}
      >
        {statusItem === currentStatus && (
          <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
            {editDate && (
              <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                <div className={classNames(GlobalStyles.centerVertical)}>
                  <img
                    src={
                      statusItem === FrontendMmrStatusEnum.PENDING
                        ? uploadedIcon
                        : statusItem === FrontendMmrStatusEnum.APPROVED
                          ? checkCircledIcon
                          : xMarkCircledIcon
                    }
                    alt="fileUploadStatus"
                    className={classNames(ChangeMmrStatusStyles.selectedStatusInfoImg)}
                  />
                </div>
                <div>{editDate ? `${formatDate(editDate)}` : ""}</div>
              </div>
            )}
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img src={userIcon} alt="user" className={classNames(ChangeMmrStatusContentStyles.selectedStatusInfoImg)} />
              </div>
              <div className={classNames(ChangeMmrStatusContentStyles.currentStatus, GlobalStyles.centerVertical)}>{`By ${editorName}`}</div>
            </div>
          </div>
        )}

        {statusItem !== currentStatus && (
          <div
            key={editorCode}
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.gap05,
              GlobalStyles.centerHorizontal,
              statusItem !== statusSelected && ChangeMmrStatusContentStyles.selectedStatusInfoContainerInvisible,
            )}
          >
            <div className={classNames(ChangeMmrStatusContentStyles.muiPadding)}>Edit Date :</div>
            <EpcmDatepickerMui
              date={customEditDate}
              theme={UiComponentGeneralEnum.SECONDARY}
              setDate={setCustomEditDate}
              minDate={mmrDateLimits?.calendarStartDate}
              maxDate={mmrDateLimits?.calendarEndDate}
            ></EpcmDatepickerMui>
          </div>
        )}
      </div>
    </>
  );
};
export default ChangeMmrStatusContent;
