import { EPCM_API_URLS, epcmAxiosInstance } from "../../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiRequestBodyTimesheetGroupCreate } from "epcm-common/dist/Types/TimesheetGroupTypes";

const API = `${EPCM_API_URLS.projects}`;

export const createTimesheetGroup = async (projectId: number, timesheetGroupName: ApiRequestBodyTimesheetGroupCreate) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/timesheet-groups`,
    method: "POST",
    data: timesheetGroupName,
  } as AxiosRequestConfig;

  return epcmAxiosInstance(config);
};
