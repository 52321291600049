import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { ApiRequestProjectEmployeeCreate } from "epcm-common/dist/Types/ProjectEmployeeTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const createProjectEmployees = async (projectId: number, projectEmployeesData: ApiRequestProjectEmployeeCreate) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/employees`,
    method: "POST",
    data: projectEmployeesData,
  } as AxiosRequestConfig;
  return epcmAxiosInstance(config);
};
