import { QueryModelWithCountAndData } from "../../../../types/apicallstypes/queryCommons.ts";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ProjectEmployeeNotInTimesheet } from "epcm-common/dist/Types/ProjectEmployeeTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getAllProjectNotInTimesheetEmployees = async (
  id: number,
  periodStart: number,
  periodEnd: number,
): Promise<QueryModelWithCountAndData<ProjectEmployeeNotInTimesheet>> => {
  const params = new URLSearchParams();
  params.set("periodStart", `${periodStart}`);
  params.set("periodEnd", `${periodEnd}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/counters/employees/not-in-timesheet`,
    method: "GET",
    params,
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);

  return response.data as QueryModelWithCountAndData<ProjectEmployeeNotInTimesheet>;
};
