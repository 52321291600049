import classNames from "classnames";
import GlobalConfigurationLocationsPopupStyles from "./GlobalConfigurationLocationsPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";

import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { PopupButtonTypeEnum, PopupType } from "../../../../../types/PopupType.ts";
import { configurationsPopupType } from "../../../../../types/projects/ConfigurationTypes.ts";
import { useResponseAlertPopupStateType } from "../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PopupStructure } from "../../../../../ui/popupstructure/PopupStructure.tsx";
import { ResponseAlertPopup } from "../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../ui/loaderpopup/LoaderPopup.tsx";
import {
  FrontendGlobalConfigurationsEntityEnum,
  useEpcmApiConfigurationsMutations,
} from "../../../../../apicalls/configurations/mutations/useEpcmApiConfigurationsMutations.ts";
import { FrontendGlobalLocationType } from "../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import { NumericFormat } from "react-number-format";

interface GlobalConfigurationLocationsPopupProps extends PopupType {
  globalLocationItem?: FrontendGlobalLocationType;
  mode: configurationsPopupType;
}

const GlobalConfigurationLocationsPopup: FC<GlobalConfigurationLocationsPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  globalLocationItem,
  mode,
}) => {
  const [locationDescription, setLocationDescription] = useState<string>(globalLocationItem?.description || "");
  const [locationCode, setLocationCode] = useState<string>(globalLocationItem?.code || "");
  const [locationWorkingHours, setLocationWorkingHours] = useState<number | undefined>(globalLocationItem?.workingHours);

  useEffect(() => {
    if (globalLocationItem) {
      setLocationDescription(globalLocationItem.description);
      setLocationCode(globalLocationItem.code);
    }
  }, [globalLocationItem]);

  const { useCreateGlobalEntityMutation, useUpdateGlobalEntityMutation } = useEpcmApiConfigurationsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createGlobalLocationMutation = useCreateGlobalEntityMutation(FrontendGlobalConfigurationsEntityEnum.Location, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global Location was added successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onCreateGlobalLocation = useCallback(() => {
    createGlobalLocationMutation.mutate({
      code: locationCode,
      description: locationDescription,
      workingHours: locationWorkingHours ?? 0,
    });
    onOpenLoaderPopup();
  }, [createGlobalLocationMutation, locationDescription, locationCode, locationWorkingHours, onOpenLoaderPopup]);

  const updateGlobalLocationMutation = useUpdateGlobalEntityMutation(globalLocationItem?.id ?? 0, FrontendGlobalConfigurationsEntityEnum.Location, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Global Location was updated successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onUpdateGlobalLocation = useCallback(() => {
    updateGlobalLocationMutation.mutate({
      code: locationCode,
      description: locationDescription,
      workingHours: locationWorkingHours ?? 0,
    });
    onOpenLoaderPopup();
  }, [updateGlobalLocationMutation, locationCode, locationDescription, locationWorkingHours, onOpenLoaderPopup]);
  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalLocations] });
  }, [queryClient]);

  const isEitherInputEmpty = useMemo(
    () => !locationDescription || !locationCode || (locationWorkingHours && locationWorkingHours <= 0) || !locationWorkingHours,
    [locationDescription, locationCode, locationWorkingHours],
  );

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(GlobalConfigurationLocationsPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Location" : "Create New Global Location",
          tooltipText: isEitherInputEmpty ? "Please fill all required fields" : "",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !isEitherInputEmpty && onUpdateGlobalLocation();
            } else {
              !isEitherInputEmpty && onCreateGlobalLocation();
            }
          },
          disabled: isEitherInputEmpty,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      secondaryHeaderText={secondaryHeaderText}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
          GlobalConfigurationLocationsPopupStyles.mainContainer,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationLocationsPopupStyles.infoText)}>Location Code</div>
          <input
            placeholder={"Type a location code..."}
            className={classNames(GlobalConfigurationLocationsPopupStyles.infoContainer, GlobalConfigurationLocationsPopupStyles.infoContainerInput)}
            required
            value={locationCode}
            onChange={(e) => setLocationCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationLocationsPopupStyles.infoText)}>Location Description</div>
          <input
            placeholder={"Type a location description..."}
            className={classNames(GlobalConfigurationLocationsPopupStyles.infoContainer, GlobalConfigurationLocationsPopupStyles.infoContainerInput)}
            required
            value={locationDescription}
            onChange={(e) => setLocationDescription(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(GlobalConfigurationLocationsPopupStyles.infoText)}>Location Working Hours</div>
          <NumericFormat
            placeholder={"Type location working hours"}
            className={classNames(GlobalConfigurationLocationsPopupStyles.infoContainer, GlobalConfigurationLocationsPopupStyles.infoContainerInput)}
            required
            value={locationWorkingHours}
            allowNegative={false}
            onValueChange={(values) => {
              const { floatValue } = values;
              if (!floatValue || floatValue <= 0) {
                return;
              }
              setLocationWorkingHours(floatValue);
            }}
          ></NumericFormat>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default GlobalConfigurationLocationsPopup;
