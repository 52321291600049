export interface BasicItemType {
  title: string;
  url: string;
}

export interface MainMenuItemType extends BasicItemType {
  iconActive: string;
  iconIdle: string;
  canExpand: boolean;
}

export enum MainMenuButtonsEnum {
  Employees = "Employees",
  Projects = "Projects",
  Configurations = "Configurations",
  Permissions = "Permissions",
}

export enum SettingsButtonsEnum {
  Configurations = "CONFIGURATIONS",
  Permissions = "PERMISSIONS",
}
