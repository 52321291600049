import { FileTransactionType, FileTypeOptions } from "./projects/FileTypes.ts";

export interface EpcmSnackbarMessage extends EpcmToastProps {
  id: number;
  fileId: string;
  fileName: string;
  message: string;
  valid: boolean;
  projectId: number;
  fileType: FileTypeOptions;
  projectCode: number;
  transactionType: FileTransactionType;
  sseRequestId?: number;
}

interface EpcmToastProps {
  duration?: number;
  severity?: EpcmToastSeverityEnum;
}

export enum EpcmToastSeverityEnum {
  success = "success",
  info = "info",
  warning = "warning",
  error = "error",
}
