import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { FrontendGlobalConfigurationsEntityEnum } from "./useEpcmApiConfigurationsMutations.ts";

const API = `${EPCM_API_URLS.configurations}`;

export const createGlobalConfigurationEntity = async <T>(
  entityType: FrontendGlobalConfigurationsEntityEnum,
  newEntityData: T,
  subEntityId?: number,
) => {
  const subEntityPath = subEntityId ? `/${subEntityId}` : "";

  if (entityType === FrontendGlobalConfigurationsEntityEnum.Subposition) {
    const config: AxiosRequestConfig = {
      url: `${API}/position/${subEntityId}/${entityType}`,
      method: "POST",
      data: newEntityData,
    };
    return epcmAxiosInstance(config);
  }
  const config: AxiosRequestConfig = {
    url: `${API}/${entityType}${subEntityPath}`,
    method: "POST",
    data: newEntityData,
  };

  return epcmAxiosInstance(config);
};
