import { EPCM_API_URLS, epcmAxiosInstance } from "../../../../config.ts";

import { AxiosRequestConfig } from "axios";
import { ApiRequestBodyTimesheetUpdate } from "epcm-common/dist/Types/TimesheetTypes";

const URL = `${EPCM_API_URLS.projects}`;

export const changeTimesheetStatus = async (
  projectId: number,
  timesheetGroupId: number,
  timesheetId: number,
  status: ApiRequestBodyTimesheetUpdate,
) => {
  const config: AxiosRequestConfig = {
    method: "put",
    url: `${URL}/${projectId}/timesheet-groups/${timesheetGroupId}/timesheets/${timesheetId}`,
    data: status,
  };

  return await epcmAxiosInstance(config);
};
