import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import EmployeesStyles from "./Employees.module.css";
import classNames from "classnames";
import { SearchBar } from "../../ui/searchbar/SearchBar.tsx";
import { EPCMInfoContainerDiv } from "../../ui/epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../types/apicallstypes/queryCommons.ts";
import { useImpersonationStore } from "../../store/use-impersonation-store.ts";
import { Skeleton } from "@mui/material";
import { useEpcmApiEmployees } from "../../apicalls/employees/useEpcmApiEmployees.ts";
import { displayNumberWithSpecificNumberOfDigits } from "../../utils/NumberManipulation.ts";
import { PAGINATION_PAGE_SIZE } from "../../apicalls/config.ts";
import { EmployeeList } from "./employeelist/EmployeeList.tsx";
import { EmployeesPagePopups, useEmployeesPopups } from "./use-employees-popups.ts";
import { EmployeeProfilePopup } from "./popups/employeeprofilepopup/EmployeeProfilePopup.tsx";
import { EmployeeProfilePopupTypeEnum } from "../../types/employees/EmployeesTypes.ts";
import { FrontendEmployeeLimitedType } from "../../types/apicallstypes/EmployeesApiTypes.ts";
import { useInView } from "react-intersection-observer";
import InfiniteScrollInViewElement from "../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";

import { useRetrieveUserPermittedActions } from "../../utils/useRetrieveUserPermittedActions.ts";
import { GlobalAction } from "../../types/Roles.ts";

import { useHandleUnauthorized } from "../../utils/use-handle-unauthorized.ts";

export const Employees = () => {
  const { popupHandler, onOpenPopup, onClosePopup } = useEmployeesPopups();
  const { ref, inView } = useInView();
  const { getAllEmployees } = useEpcmApiEmployees();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { canPerformGlobalAction } = useRetrieveUserPermittedActions();
  const queryClient = useQueryClient();
  const { handleErrorRedirect } = useHandleUnauthorized();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null>(null);
  const [employeeProfilePopupType, setEmployeeProfilePopupType] = useState<EmployeeProfilePopupTypeEnum | null>(null);
  const [previousEmployeeData, setPreviousEmployeeData] = useState<FrontendEmployeeLimitedType[] | undefined>();

  const allEmployeesQuery = useInfiniteQuery({
    queryKey: [QueryNames.Employees, searchQuery],
    queryFn: ({ pageParam = 1 }) => getAllEmployees(pageParam, searchQuery, PAGINATION_PAGE_SIZE).catch(handleErrorRedirect),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const actualEmployeesData = useMemo(() => {
    return allEmployeesQuery.data?.pages.flatMap((page) => page.data);
  }, [allEmployeesQuery.data]);

  const allEmployeesData = useMemo(() => {
    return actualEmployeesData ?? (allEmployeesQuery.isError ? undefined : previousEmployeeData);
  }, [actualEmployeesData, allEmployeesQuery.isError, previousEmployeeData]);

  const totalEmployeesCount = useMemo(() => {
    return allEmployeesQuery.data?.pages[0].totalCount;
  }, [allEmployeesQuery.data]);

  const onSearchQueryChange = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const onOpenEmployeeProfile = useCallback(
    (employeeId: number | null, popupType: EmployeeProfilePopupTypeEnum) => {
      setSelectedEmployeeId(employeeId);
      setEmployeeProfilePopupType(popupType);
      onOpenPopup(EmployeesPagePopups.employeeProfile, popupHandler);
    },
    [onOpenPopup, popupHandler],
  );

  const employeeInfoSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
      <EPCMInfoContainerDiv className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
        <Skeleton variant="circular" height={30} width={30} />
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
          <div className={classNames(EmployeesStyles.infoSecondaryText)}>{"Registered"}</div>
          <div className={classNames(EmployeesStyles.infoHighlightedText)}>{"Employees"}</div>
        </div>
      </EPCMInfoContainerDiv>
    </div>
  );

  const employeeItemSkeletonLoader = (
    <div
      className={classNames(
        EmployeesStyles.employeeItemContainerSkeletonLoader,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
      )}
    >
      <div className={classNames(EmployeesStyles.settingsBarSkeletonLoader, GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="circular" height={20} width={20} />
        </div>
        <div className={classNames(EmployeesStyles.settingsBarTextSkeletonLoader, GlobalStyles.centerVertical)}>
          <Skeleton variant="rounded" height={15} width={120} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div>
          <div className={classNames(GlobalStyles.centerVertical)}>
            <Skeleton variant="circular" height={20} width={20} />
          </div>
        </div>
      </div>
      <div className={classNames(EmployeesStyles.employeeInfoPanelSkeletonLoader, GlobalStyles.flex, GlobalStyles.flex1)}>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
          <div className={classNames(GlobalStyles.flex)}>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(GlobalStyles.centerVertical)}>
              <Skeleton variant="circular" height={80} width={80} />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.flex)}>
            <div className={classNames(GlobalStyles.flex1)} />
            <div className={classNames(GlobalStyles.flex3)}>
              <Skeleton variant="rounded" height={90} />
            </div>
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap15)}>
              <div className={classNames(GlobalStyles.flex1)} />
              <div className={classNames(GlobalStyles.flex2)}>
                <Skeleton variant="rounded" height={20} />
              </div>
              <div className={classNames(GlobalStyles.flex2)}>
                <Skeleton variant="rounded" height={20} />
              </div>
              <div className={classNames(GlobalStyles.flex1)} />
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
              <Skeleton variant="rounded" height={20} width={90} />
              <Skeleton variant="rounded" height={20} width={90} />
              <Skeleton variant="rounded" height={20} width={90} />
            </div>
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
        <Skeleton variant="rounded" height={0} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05)}>
          <div className={classNames(GlobalStyles.flex1)}>
            <Skeleton variant="rounded" height={0} />
          </div>
          <div className={classNames(GlobalStyles.flex1)}>
            <Skeleton variant="rounded" height={20} />
          </div>
          <div className={classNames(GlobalStyles.flex1)}>
            <Skeleton variant="rounded" height={0} />
          </div>
        </div>
        <Skeleton variant="rounded" height={15} width={0} />
      </div>
    </div>
  );

  const employeeListSkeletonLoader = (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexWrap, GlobalStyles.gap3)}>
      {["skeleton1", "skeleton2", "skeleton3", "skeleton4"].map((key) => (
        <div key={key}>{employeeItemSkeletonLoader}</div>
      ))}
    </div>
  );

  const canSeeEmployeeRelatedElements = canPerformGlobalAction(GlobalAction.EmployeeList);
  const canSeeEmployeeCreate = canPerformGlobalAction(GlobalAction.EmployeeCreate);

  useEffect(() => {
    if (allEmployeesData) {
      setPreviousEmployeeData(allEmployeesData);
    }
  }, [allEmployeesData]);

  useEffect(() => {
    if (
      inView &&
      !allEmployeesQuery.isLoading &&
      !allEmployeesQuery.isFetching &&
      !allEmployeesQuery.isFetchingNextPage &&
      allEmployeesQuery.hasNextPage
    ) {
      allEmployeesQuery.fetchNextPage();
    }
  }, [inView, allEmployeesQuery]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.Employees] }).then(() => console.log(`In employees, ${QueryNames.Employees} query canceled`));
    };
  }, [queryClient]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3, GlobalStyles.overflowHiddenFullHeight)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap)}>
        {allEmployeesData && <div className={classNames(EmployeesStyles.pageHeader, GlobalStyles.centerVertical)}>{"Employees"}</div>}
        <div className={classNames(GlobalStyles.flex3)} />
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex3, GlobalStyles.gap2)}>
          {canSeeEmployeeRelatedElements && (
            <>
              <SearchBar placeholder={"Search employees..."} setSearchQuery={onSearchQueryChange} />
              {canSeeEmployeeCreate && (
                <div
                  className={classNames(EmployeesStyles.newEmployeeButton, GlobalStyles.centerVertical)}
                  onClick={() => onOpenEmployeeProfile(null, EmployeeProfilePopupTypeEnum.create)}
                >
                  {"New Employee"}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {canSeeEmployeeRelatedElements ? (
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3, GlobalStyles.overflowHiddenFullHeight)}>
          <div className={classNames(EmployeesStyles.infoBarContainer, GlobalStyles.flex, GlobalStyles.gap)}>
            {totalEmployeesCount || totalEmployeesCount === 0 ? (
              <EPCMInfoContainerDiv className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
                <div className={classNames(EmployeesStyles.infoHighlightedText)}>{displayNumberWithSpecificNumberOfDigits(totalEmployeesCount)}</div>
                <div className={classNames(GlobalStyles.flex1)} />
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
                  <div className={classNames(EmployeesStyles.infoSecondaryText)}>{"Registered"}</div>
                  <div className={classNames(EmployeesStyles.infoHighlightedText)}>{"Employees"}</div>
                </div>
              </EPCMInfoContainerDiv>
            ) : (
              employeeInfoSkeletonLoader
            )}
            <div className={classNames(GlobalStyles.flex1)} />
          </div>
          {allEmployeesData ? (
            <div
              className={classNames(
                GlobalStyles.flex,
                GlobalStyles.flex1,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.overflowHiddenFullHeight,
                GlobalStyles.gap05,
              )}
            >
              <EmployeeList employeeList={allEmployeesData} onOpenEmployeeProfile={onOpenEmployeeProfile}>
                <InfiniteScrollInViewElement
                  key={"InfiniteScrollInViewElement"}
                  reference={ref}
                  infiniteQueryResult={allEmployeesQuery}
                  loaderComponent={employeeItemSkeletonLoader}
                />
              </EmployeeList>
            </div>
          ) : (
            employeeListSkeletonLoader
          )}
        </div>
      ) : (
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap3, GlobalStyles.overflowHiddenFullHeight)}
        ></div>
      )}

      {employeeProfilePopupType &&
        (employeeProfilePopupType === EmployeeProfilePopupTypeEnum.create || selectedEmployeeId) &&
        popupHandler.get(EmployeesPagePopups.employeeProfile)!.isOpen && (
          <EmployeeProfilePopup
            isOpen={popupHandler.get(EmployeesPagePopups.employeeProfile)!.isOpen}
            closeFn={() => {
              onClosePopup(EmployeesPagePopups.employeeProfile, popupHandler);
            }}
            popupTypeFromParent={employeeProfilePopupType}
            employeeId={selectedEmployeeId}
          />
        )}
    </div>
  );
};
