import React from "react";
import ReactDOM from "react-dom/client";
import { EPCMApp } from "./CustomRoutes.tsx";
import "./assets/css/GlobalFonts.module.css"; // THIS is required for the fonts to work
import "./assets/css/ResetCSS.css"; // THIS is required to avoid unpredicted behaviours, due to preexisting styling
import "./assets/css/GlobalStyles.module.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <EPCMApp />
  </React.StrictMode>,
);
