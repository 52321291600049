import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import { SearchBar } from "../../../../ui/searchbar/SearchBar.tsx";
import AddProjectUserRolePopupStyles from "./AddUserPopup.module.css";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames } from "../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../apicalls/config.ts";
import { useParams } from "react-router-dom";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { displayNumberWithSpecificNumberOfDigits } from "../../../../utils/NumberManipulation.ts";
// import { useEpcmApiUsers } from "../../../../apicalls/users/useEpcmApiUsers.ts";
// import { UserListItem } from "./userlistItem/UserListItem.tsx";
import InfiniteScrollInViewElement from "../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { Skeleton } from "@mui/material";
import { UserListItem } from "./userlistItem/UserListItem.tsx";
import { useEpcmApiUsers } from "../../../../apicalls/users/useEpcmApiUsers.ts";
import { useEpcmApiProjectPermissionsMutation } from "../../../../apicalls/projects/projectpermissions/mutations/useEpcmApiProjectPermissionsMutation.ts";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { ConfigurationScopeEnum } from "../../../../types/projects/ConfigurationTypes.ts";
import { useEpcmApiUserPermissionsMutation } from "../../../../apicalls/configurations/permissions/mutations/useEpcmApiUserPermissionMutation.ts";

interface AddProjectUserRolePopupProps extends PopupType {
  scope: ConfigurationScopeEnum;
}

const AddUserPopup: FC<AddProjectUserRolePopupProps> = ({ isOpen, closeFn, headerText, secondaryHeaderText, scope }) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();
  const { projectId } = useParams();
  const [parent] = useAutoAnimate();
  const { getAllUsers } = useEpcmApiUsers();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [userIdsSelected, setUserIdsSelected] = useState<string[]>([]);

  const { useUpdateProjectPermissionMutation } = useEpcmApiProjectPermissionsMutation();

  const { useUpdateGlobalPermissionMutation } = useEpcmApiUserPermissionsMutation();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const isScopeProject = useMemo(() => scope === ConfigurationScopeEnum.PROJECT, [scope]);
  const isScopeGlobal = useMemo(() => scope === ConfigurationScopeEnum.GLOBAL, [scope]);

  const updateProjectPermissionMutation = useUpdateProjectPermissionMutation(projectId ? parseInt(projectId!) : 0, {
    onSuccessCallback: (data) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `User was given project permissions` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateGlobalPermissionMutation = useUpdateGlobalPermissionMutation({
    onSuccessCallback: (data) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `User added for role assignment` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const onUpdateProjectUserRole = useCallback(() => {
    const userProjectRoles = userIdsSelected.map((userId) => {
      return {
        userCode: userId,
        roleIds: [],
      };
    });
    updateProjectPermissionMutation.mutate({
      userProjectRoles: [...userProjectRoles],
    });
    onOpenLoaderPopup();
  }, [userIdsSelected, updateProjectPermissionMutation]);

  const onUpdateGlobalUserRole = useCallback(() => {
    const globalUsersRoles = userIdsSelected.map((userId) => {
      return {
        userCode: userId,
        roleIds: [],
      };
    });
    updateGlobalPermissionMutation.mutate({
      userGlobalRoles: [...globalUsersRoles],
    });

    onOpenLoaderPopup();
  }, [userIdsSelected, updateGlobalPermissionMutation]);

  const allUsersQuery = useInfiniteQuery({
    queryKey: [QueryNames.Users, isScopeProject ? projectId : undefined, searchQuery],
    queryFn: ({ pageParam }) =>
      getAllUsers(
        pageParam,
        PAGINATION_PAGE_SIZE,
        searchQuery,
        parseInt(projectId!),
        isScopeProject ? false : undefined,
        isScopeGlobal ? false : undefined,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const allUsersData = useMemo(() => {
    return allUsersQuery.data?.pages.flatMap((page) => page.data);
  }, [allUsersQuery.data]);

  const onUserToggle = useCallback((userId: string) => {
    setUserIdsSelected((currentState) => {
      const userIdsSelectedClone = currentState.slice();
      const userIdIndex = userIdsSelectedClone.indexOf(userId);
      if (userIdIndex !== -1) {
        userIdsSelectedClone.splice(userIdIndex, 1);
      } else {
        userIdsSelectedClone.push(userId);
      }
      return userIdsSelectedClone;
    });
  }, []);

  const isUserSelected = useCallback(
    (userId: string) => {
      return userIdsSelected.includes(userId);
    },
    [userIdsSelected],
  );

  const userItemSkeletonLoader = (
    <div
      className={classNames(
        AddProjectUserRolePopupStyles.userItemContainerSkeletonLoader,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="circular" height={30} width={30} />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
          <Skeleton variant="circular" height={50} width={50} />
          <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.gap05)}>
            <Skeleton variant="rounded" height={16} width={200} />
            <Skeleton variant="rounded" height={16} width={150} />
          </div>
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical)}>
          <Skeleton variant="circular" height={20} width={20} />
        </div>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
        <Skeleton variant="rounded" height={0} width={40} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical, GlobalStyles.flex3)}>
          <Skeleton variant="rounded" height={20} />
        </div>
        <Skeleton variant="rounded" height={0} width={10} />
      </div>
    </div>
  );

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectConfigurationsPermissions, parseInt(projectId!)] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.UserProjectRoles, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalPermissions] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.GlobalRoles] });
    void queryClient.resetQueries({ queryKey: [QueryNames.Users, isScopeProject ? projectId : undefined, searchQuery] });
  }, [queryClient, projectId, isScopeProject]);

  useEffect(() => {
    if (
      inView &&
      !allUsersQuery.isLoading &&
      !allUsersQuery.isFetching &&
      !allUsersQuery.isFetchingNextPage &&
      !allUsersQuery.isFetchingNextPage &&
      allUsersQuery.hasNextPage
    ) {
      void allUsersQuery.fetchNextPage();
    }
  }, [inView, allUsersQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.Users, isScopeProject ? projectId : undefined, searchQuery] })
        .then(() => console.log(`In select users for project permissions, ${QueryNames.Users} query canceled`));
    };
  }, [queryClient, searchQuery, projectId, isScopeProject]);

  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel",
          buttonType: PopupButtonTypeEnum.neutral,
          action: closeFn,
        },
        {
          text: "Give Access to User(s)",
          buttonType: PopupButtonTypeEnum.main,
          action: isScopeProject ? onUpdateProjectUserRole : onUpdateGlobalUserRole,
          disabled: false,
          tooltipText: "",
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap075)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap)}>
          <SearchBar
            placeholder={"Search users by code..."}
            setSearchQuery={setSearchQuery}
            searchBarContainerStyles={AddProjectUserRolePopupStyles.searchBarContainer}
          />
        </div>
        <div
          ref={parent}
          className={classNames(
            AddProjectUserRolePopupStyles.userListContainer,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap,
          )}
        >
          {allUsersData && allUsersData.length > 0 ? (
            <>
              {allUsersData.map((userItem) => (
                <UserListItem key={userItem.code} userItem={userItem} isSelected={isUserSelected(userItem.code)} onUserToggle={onUserToggle} />
              ))}
              <InfiniteScrollInViewElement reference={ref} infiniteQueryResult={allUsersQuery} loaderComponent={userItemSkeletonLoader} />
              <div className={classNames(GlobalStyles.flex1)} />
            </>
          ) : (
            <div className={classNames(GlobalStyles.emptyListMsg, GlobalStyles.flex)}>
              <div className={classNames(GlobalStyles.flex1)} />
              <div>{"No Users"}</div>
              <div className={classNames(GlobalStyles.flex1)} />
            </div>
          )}
        </div>
        <div className={classNames(AddProjectUserRolePopupStyles.selectedInfoContainer, GlobalStyles.flex, GlobalStyles.gap05)}>
          <div className={classNames(GlobalStyles.centerVertical)}>{"Selected:"}</div>
          <div className={classNames(AddProjectUserRolePopupStyles.selectedCount, GlobalStyles.centerVertical)}>
            {displayNumberWithSpecificNumberOfDigits(userIdsSelected.length)}
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
          <div
            className={classNames(AddProjectUserRolePopupStyles.selectedClearButton, GlobalStyles.centerVertical)}
            onClick={() => setUserIdsSelected([])}
          >
            {"Clear All"}
          </div>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};
export default AddUserPopup;
