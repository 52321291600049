import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MmrVersionAccordionStyles from "./MmrVersionAccordion.module.css";
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import arrow_down from "../../../../../../../../assets/images/arrow-down-dark-blue-fill.svg";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";

import MMrVersionAccordionItem from "./mmrversionaccordionitem/MmrVersionAccordionItem.tsx";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import { Skeleton } from "@mui/material";
import { ProjectEmployeeDetailsPopupTypeEnum } from "../../../../../../../../types/projects/ProjectEmployeesTypes.ts";
import { FrontendMmrDateStatusEnum } from "../../../../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectEmployee } from "../../../../../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import { useInView } from "react-intersection-observer";
import { QueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../../../../apicalls/config.ts";
import { EPCMAccordionCyan } from "../../../../../../../../ui/epcmaccordion/EPCMAccordionCyan.tsx";
import InfiniteScrollInViewElement from "../../../../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";

interface MmrVersionAccordionProps {
  title: string;
  setPopupType: Dispatch<SetStateAction<ProjectEmployeeDetailsPopupTypeEnum>>;
  employeeId: number;
  projectId: number;
  setSelectedMmrId: Dispatch<SetStateAction<number | null>>;
  selectedMmrId: number | null;
  mmrDateStatus: FrontendMmrDateStatusEnum;
  setIsCloned: Dispatch<SetStateAction<boolean>>;
  selectedMmrIdType: FrontendMmrDateStatusEnum | null;
  setSelectedMmrIdType: Dispatch<SetStateAction<FrontendMmrDateStatusEnum | null>>;
}

const MmrVersionAccordion: FC<MmrVersionAccordionProps> = ({
  title,
  setPopupType,
  employeeId,
  projectId,
  setSelectedMmrId,
  selectedMmrId,
  mmrDateStatus,
  setIsCloned,
  selectedMmrIdType,
  setSelectedMmrIdType,
}) => {
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllProjectEmployeeMmr } = useEpcmApiProjectEmployee();
  const queryClient = useQueryClient();
  const { inView, ref } = useInView();
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const mmrQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectEmployeeMmr, projectId, employeeId, mmrDateStatus],
    queryFn: ({ pageParam }) => getAllProjectEmployeeMmr(projectId, employeeId, pageParam, PAGINATION_PAGE_SIZE, mmrDateStatus),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: !!mmrDateStatus && isAuthorized, // Query is enabled only if a status is set
  });

  const { isLoading } = mmrQuery;
  const mmrQueryData = useMemo(() => {
    return mmrQuery.data?.pages.flatMap((page) => page.data);
  }, [mmrQuery.data]);

  const mmrQueryIds = useMemo(() => {
    return mmrQueryData?.map((item) => item.id) ?? [];
  }, [mmrQueryData]);

  const isActive = useMemo(() => {
    return mmrDateStatus == FrontendMmrDateStatusEnum.ACTIVE;
  }, [mmrDateStatus]);

  const isHistory = useMemo(() => {
    return mmrDateStatus == FrontendMmrDateStatusEnum.HISTORY;
  }, [mmrDateStatus]);

  if (inView && !isLoading && !mmrQuery.isFetching && !mmrQuery.isFetchingNextPage && mmrQuery.hasNextPage) {
    void mmrQuery.fetchNextPage();
  }

  const initialShouldBeExpanded = useMemo(() => {
    return Boolean(selectedMmrIdType === mmrDateStatus || mmrDateStatus === FrontendMmrDateStatusEnum.ACTIVE);
  }, [selectedMmrIdType, mmrDateStatus]);

  const [isExpanded, setIsExpanded] = useState(initialShouldBeExpanded);

  const handleExpandIconClick: React.MouseEventHandler<HTMLImageElement> = (event) => {
    event.stopPropagation();
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectEmployeeMmr, projectId, employeeId, mmrDateStatus] })
        .then(() => console.log(`In  project employee with id ${employeeId} profile card, ${QueryNames.ProjectEmployeeMmr} query canceled`));
    };
  }, [queryClient, employeeId, projectId, mmrDateStatus]);

  useEffect(() => {
    setIsExpanded(initialShouldBeExpanded);
  }, [initialShouldBeExpanded]);

  useEffect(() => {
    // Reset refs array to match the number of mmrQueryIds
    itemRefs.current = mmrQueryIds.map((_, index) => itemRefs.current[index] || null);
  }, [mmrQueryIds.length, mmrQueryIds]);

  useEffect(() => {
    const index = mmrQueryIds.findIndex((id) => id === selectedMmrId);
    const selectedRef = itemRefs.current[index];
    if (selectedRef) {
      selectedRef.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedMmrId, mmrQueryIds]);

  return (
    <EPCMAccordionCyan expanded={isExpanded} className={classNames(MmrVersionAccordionStyles.container)} square={true} disableGutters={true}>
      <AccordionSummary
        expandIcon={
          !isActive ? (
            <div className={classNames(MmrVersionAccordionStyles.iconContainer)}>
              <img src={arrow_down} alt="expand" />
            </div>
          ) : (
            <></>
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classNames(MmrVersionAccordionStyles.summary, GlobalStyles.flex1, isActive && MmrVersionAccordionStyles.activeNoHover)}
        onClick={handleExpandIconClick} // This prevents the accordion from toggling when the summary area is clicked but allows it for the icon
      >
        <div className={classNames(MmrVersionAccordionStyles.heading)}>{title}</div>
      </AccordionSummary>
      {!isLoading ? (
        <AccordionDetails
          className={
            isHistory
              ? classNames(
                  MmrVersionAccordionStyles.details,
                  MmrVersionAccordionStyles.heightClass,
                  GlobalStyles.flexDirectionColumn,
                  GlobalStyles.gap05,
                  GlobalStyles.flex,
                )
              : classNames(MmrVersionAccordionStyles.details, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05, GlobalStyles.flex)
          }
        >
          {mmrQueryIds.length > 0 && mmrQueryData ? (
            mmrQueryIds.map((item, index) => (
              <div ref={(el) => (itemRefs.current[index] = el)} key={index}>
                <MMrVersionAccordionItem
                  key={`${item} + ${mmrDateStatus} + ${index}`}
                  mmrId={item}
                  mmrDateStatus={mmrDateStatus}
                  setPopupType={setPopupType}
                  projectId={projectId}
                  employeeId={employeeId}
                  setSelectedMmrId={setSelectedMmrId}
                  selectedMmrId={selectedMmrId}
                  setIsCloned={setIsCloned}
                  selectedMmrIdType={selectedMmrIdType}
                  setSelectedMmrIdType={setSelectedMmrIdType}
                  isEligible={mmrQueryData[index].isEligible}
                />
              </div>
            ))
          ) : (
            <div className={classNames(GlobalStyles.emptyListMsgSmall, MmrVersionAccordionStyles.loadingDiv)}>NO {title}</div>
          )}
        </AccordionDetails>
      ) : (
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.centerHorizontal,
            GlobalStyles.centerVertical,
            GlobalStyles.emptyListMsg,
            MmrVersionAccordionStyles.loadingDiv,
          )}
        >
          <Skeleton width={300} height={90} variant={"rounded"}></Skeleton>
        </div>
      )}
      <InfiniteScrollInViewElement reference={ref} infiniteQueryResult={mmrQuery} loaderComponent={<></>} />
    </EPCMAccordionCyan>
  );
};

export default MmrVersionAccordion;
