import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../../config.ts";

const API = `${EPCM_API_URLS.projects}`;

export const uploadManpowerPlan = async (projectId: number, manpowerPlanFile: FormData) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/manpower`,
    method: "POST",
    data: manpowerPlanFile,
  };

  return epcmAxiosInstance(config);
};
