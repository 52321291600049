import { FC } from "react";
import classNames from "classnames";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import QuickFilterButtonStyles from "./QuickFilterButton.module.css";
import activeIcon from "../../assets/images/eye-open-icon.svg";
import inactiveIcon from "../../assets/images/eye-closed-icon.svg";
import { EPCMInfoContainerDiv } from "../epcminfocontainerdiv/EPCMInfoContainerDiv.tsx";

interface QuickFilterContainerProps {
  isFilterActive: boolean;
  headerLineText: string;
  smallerFontText?: string;
  mainText: string;
  onClickFn: () => void;
}

export const QuickFilterButton: FC<QuickFilterContainerProps> = ({ isFilterActive, headerLineText, smallerFontText, mainText, onClickFn }) => {
  return (
    <EPCMInfoContainerDiv
      className={classNames(
        QuickFilterButtonStyles.container,
        isFilterActive ? QuickFilterButtonStyles.containerActive : QuickFilterButtonStyles.containerInactive,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap025,
      )}
      onClick={onClickFn}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
          <div
            className={classNames(
              QuickFilterButtonStyles.headerLineText,
              isFilterActive ? QuickFilterButtonStyles.headerLineTextActive : QuickFilterButtonStyles.headerLineTextInactive,
            )}
          >
            {headerLineText}
          </div>
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(QuickFilterButtonStyles.statusIconImg)} src={isFilterActive ? activeIcon : inactiveIcon} alt="approved" />
          </div>
        </div>
        <div
          className={classNames(
            QuickFilterButtonStyles.containerTextSection,
            isFilterActive ? QuickFilterButtonStyles.containerTextSectionActive : QuickFilterButtonStyles.containerTextSectionInactive,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap025,
          )}
        >
          <div className={classNames(QuickFilterButtonStyles.smallerFontText)}>{smallerFontText}</div>
          <div className={classNames()}>{mainText}</div>
        </div>
      </div>
    </EPCMInfoContainerDiv>
  );
};
