import classNames from "classnames";
import CreateNewProjectGroupStyles from "./CreateNewProjectGroup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";

import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { ApiRequestBodyTimesheetGroupCreate } from "epcm-common/dist/Types/TimesheetGroupTypes";
import { useEpcmApiProjectTimesheetsGroupsMutations } from "../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/mutations/useEpcmApiProjectTimesheetsGroupsMutations.ts";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";

import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { useQueryClient } from "@tanstack/react-query";
import { ProjectTimesheetGroupModificationTypeEnum } from "../../../../types/projects/ProjectTimesheetsTypes.ts";

interface CreateNewProjectGroupProps extends PopupType {
  mode: ProjectTimesheetGroupModificationTypeEnum;
  projectId: number;
  timesheetGroupId?: number;
  existingGroupName?: string;
  selectGroup?: Dispatch<SetStateAction<number | null>>;
}

const CreateNewProjectGroup: FC<CreateNewProjectGroupProps> = ({
  isOpen,
  closeFn,
  headerText,
  projectId,
  selectGroup,
  timesheetGroupId,
  existingGroupName,
  mode,
}) => {
  const [newGroupName, setNewGroupName] = useState<string>(existingGroupName ?? "");

  const queryClient = useQueryClient();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const { useCreateTimesheetGroupMutation } = useEpcmApiProjectTimesheetsGroupsMutations();
  const { useUpdateTimesheetGroupNameMutation } = useEpcmApiProjectTimesheetsGroupsMutations();
  const isInputEmpty = useMemo(() => {
    return newGroupName.trim().length === 0;
  }, [newGroupName]);

  const isGroupNameChanged = useMemo(() => {
    return newGroupName.trim() !== existingGroupName?.trim();
  }, [newGroupName, existingGroupName]);

  const createTimesheetGroupMutation = useCreateTimesheetGroupMutation(projectId, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Group was created successfully!" });
      if (selectGroup) {
        selectGroup(data.data.id ? data.data.id : null);
      }
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateTimesheetGroupNameMutation = useUpdateTimesheetGroupNameMutation(projectId, timesheetGroupId ?? 0, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Group name was updated successfully!" });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateGroupName = useCallback(() => {
    updateTimesheetGroupNameMutation.mutate({ name: newGroupName } satisfies ApiRequestBodyTimesheetGroupCreate);
    onOpenLoaderPopup();
  }, [updateTimesheetGroupNameMutation, onOpenLoaderPopup, newGroupName]);

  const invalidateQuery = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, projectId] });
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectTimesheetGroups, `${projectId}`] });
  }, [queryClient, projectId, timesheetGroupId]);

  const onAddNewGroup = useCallback(() => {
    createTimesheetGroupMutation.mutate({ name: newGroupName } satisfies ApiRequestBodyTimesheetGroupCreate);
    onOpenLoaderPopup();
  }, [createTimesheetGroupMutation, onOpenLoaderPopup, newGroupName]);

  const isCreationMode = useMemo(() => mode === ProjectTimesheetGroupModificationTypeEnum.create, [mode]);
  const isUpdateMode = useMemo(() => mode === ProjectTimesheetGroupModificationTypeEnum.update, [mode]);
  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(CreateNewProjectGroupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: isCreationMode ? "Create Group" : "Update Group Name",
          action: () => {
            isCreationMode ? onAddNewGroup() : isUpdateMode ? updateGroupName() : null;
          },
          tooltipText: isInputEmpty ? "Please enter a group name" : !isGroupNameChanged ? "Please change the group name" : "",
          disabled: isInputEmpty || !isGroupNameChanged,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap075,
          CreateNewProjectGroupStyles.mainContainer,
          GlobalStyles.overflowHiddenFullHeight,
        )}
      >
        <div className={classNames(CreateNewProjectGroupStyles.infoText)}>NAME YOUR GROUP</div>
        <input
          placeholder={"Type a convenient group name..."}
          className={classNames(CreateNewProjectGroupStyles.infoContainer, CreateNewProjectGroupStyles.infoContainerInput)}
          required
          value={newGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
        ></input>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            invalidateQuery();
            responseType === ApiResponseTypeEnum.success && closeFn();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default CreateNewProjectGroup;
