import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { FC, useState } from "react";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import { ManpowerPlanType } from "../../../../types/projects/ManpowerPlanTypes.ts";
import { DnDComponent } from "../../../../ui/dndcomponent/DnDComponent.tsx";
import { ExtensionConstraintEnum } from "../../../../types/DnDComponentTypes.ts";
import { useDndComponentStates } from "../../../../utils/use-dnd-component-states.ts";
import classNames from "classnames";
import ManpowerPlanReferenceFilesPopupStyles from "./ManpowerPlanReferenceFilesPopup.module.css";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import documentIcon from "../../../../assets/images/document-icon-single-page.svg";
import downloadIcon from "../../../../assets/images/download-icon-blue.svg";
import uploadedIcon from "../../../../assets/images/upload-icon-black-lines.svg";
import personIcon from "../../../../assets/images/profile-blue-lines.svg";
import { formatDate } from "../../../../utils/DateManipulation.ts";
import { useAutoAnimate } from "@formkit/auto-animate/react";

interface ManpowerPlanReferenceFilesPopupProps extends PopupType {
  showDnD: boolean;
  manpowerPlanItem: ManpowerPlanType;
  onChangeStatusFn: (manpowerPlanItem: ManpowerPlanType) => void;
}

export const ManpowerPlanReferenceFilesPopup: FC<ManpowerPlanReferenceFilesPopupProps> = ({
  isOpen,
  closeFn,
  headerText,
  secondaryHeaderText,
  showDnD,
  manpowerPlanItem,
  onChangeStatusFn,
}) => {
  const { chosenFileList, setChosenFileList, hasChosenFiles, isAnyLoading, setIsAnyLoading } = useDndComponentStates();
  const [parent] = useAutoAnimate();

  const [showDnDComponent, setShowDnDComponent] = useState<boolean>(showDnD);

  const fileInputValidation = (): boolean => {
    return hasChosenFiles;
  };

  return (
    <PopupStructure
      popupButtons={
        showDnDComponent
          ? [
              {
                text: "Cancel ",
                buttonType: PopupButtonTypeEnum.neutral,
                action: () => {
                  setChosenFileList([]);
                  setShowDnDComponent(false);
                },
              },
              {
                text: "Save",
                buttonType: PopupButtonTypeEnum.main,
                disabled: !fileInputValidation() || isAnyLoading,
                tooltipText: !fileInputValidation() ? "Please select some files first" : isAnyLoading ? "Preparing files" : undefined,
                action: () => {
                  fileInputValidation() &&
                    onChangeStatusFn({
                      ...manpowerPlanItem,
                      referenceFiles: [
                        ...manpowerPlanItem.referenceFiles,
                        ...chosenFileList.map((fileItem) => {
                          return {
                            fileName: fileItem.name,
                            uploadedBy: { name: "John Smith", code: 654345 },
                            dateUploaded: new Date(),
                          };
                        }),
                      ],
                    });
                  if (fileInputValidation()) {
                    setChosenFileList([]);
                    setShowDnDComponent(false);
                  }
                },
              },
            ]
          : [
              {
                text: "Close ",
                buttonType: PopupButtonTypeEnum.neutral,
                action: closeFn,
              },
              {
                text: "Upload more",
                buttonType: PopupButtonTypeEnum.main,
                action: () => setShowDnDComponent(true),
              },
            ]
      }
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      overrideContentContainerStyleClass={""}
    >
      <div className={classNames()} ref={parent}>
        {showDnDComponent && (
          <div className={classNames(ManpowerPlanReferenceFilesPopupStyles.dndContainer)}>
            <DnDComponent
              chosenFileList={chosenFileList}
              setChosenFileList={setChosenFileList}
              setIsAnyLoading={setIsAnyLoading}
              constraintOptions={{
                validExtensions: [
                  ExtensionConstraintEnum.pdf,
                  ExtensionConstraintEnum.docx,
                  ExtensionConstraintEnum.xlsx,
                  ExtensionConstraintEnum.xls,
                ],
              }}
            />
          </div>
        )}
        <div
          className={classNames(
            ManpowerPlanReferenceFilesPopupStyles.existingFilesContainer,
            showDnDComponent && ManpowerPlanReferenceFilesPopupStyles.existingFilesContainerSmall,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap05,
          )}
        >
          {showDnDComponent && <div className={classNames(ManpowerPlanReferenceFilesPopupStyles.existingFilesHeader)}>{"Existing Files"}</div>}
          <div
            className={classNames(
              ManpowerPlanReferenceFilesPopupStyles.existingFilesList,
              GlobalStyles.flex,
              GlobalStyles.flexDirectionColumn,
              GlobalStyles.gap,
            )}
          >
            {manpowerPlanItem.referenceFiles.map((fileItem) => (
              <div
                key={fileItem.fileName}
                className={classNames(ManpowerPlanReferenceFilesPopupStyles.existingFileContainer, GlobalStyles.flex, GlobalStyles.gap)}
              >
                <div className={classNames(GlobalStyles.centerVertical)}>
                  <img src={documentIcon} alt="doc" className={classNames(ManpowerPlanReferenceFilesPopupStyles.existingFileIconImg)} />
                </div>
                <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
                  <div className={classNames(ManpowerPlanReferenceFilesPopupStyles.existingFileName)}>{fileItem.fileName}</div>
                  <div className={classNames(GlobalStyles.flex, GlobalStyles.gap15)}>
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                      <div className={classNames(GlobalStyles.centerVertical)}>
                        <img
                          src={uploadedIcon}
                          alt="uploaded"
                          className={classNames(ManpowerPlanReferenceFilesPopupStyles.existingFileUploadedIconImg)}
                        />
                      </div>
                      <div className={classNames()}>{formatDate(fileItem.dateUploaded)}</div>
                    </div>
                    <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
                      <div className={classNames(GlobalStyles.centerVertical)}>
                        <img src={personIcon} alt="person" className={classNames(ManpowerPlanReferenceFilesPopupStyles.existingFilePersonIconImg)} />
                      </div>
                      <div className={classNames()}>{fileItem.uploadedBy.name}</div>
                    </div>
                  </div>
                </div>
                <div className={classNames(GlobalStyles.flex1)} />
                <div className={classNames(GlobalStyles.centerVertical)}>
                  <img
                    src={downloadIcon}
                    alt="downloadTemplate"
                    className={classNames(ManpowerPlanReferenceFilesPopupStyles.existingFileDownloadIconImg)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PopupStructure>
  );
};
