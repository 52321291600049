import { FC } from "react";
import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ConfirmCreateCalendarPopupStyles from "./ConfirmCreateCalendarPopup.module.css";

interface ConfirmCreateCalendarPopupProps extends PopupType {
  onRenderCalendar: () => void;
}

const ConfirmCreateCalendarPopup: FC<ConfirmCreateCalendarPopupProps> = ({ isOpen, closeFn, headerText, onRenderCalendar }) => {
  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel",
          buttonType: PopupButtonTypeEnum.neutral,
          action: closeFn,
        },
        {
          text: "Confirm ",
          buttonType: PopupButtonTypeEnum.main,
          action: onRenderCalendar,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
    >
      <div className={classNames(ConfirmCreateCalendarPopupStyles.popupContainer, GlobalStyles.flex, GlobalStyles.overflowHiddenFullHeight)}>
        Are you sure you want to create a calendar for the project?
      </div>
    </PopupStructure>
  );
};

export default ConfirmCreateCalendarPopup;
