import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { ProjectTimesheetDisplayListTypeEnum, ProjectTimesheetGroupPopupModeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";
import ArrowLeftBlackLarger from "../../../../../assets/images/arrow-left-black-larger.svg";
import AddGroupMemberStyles from "./AddGroupMemberStyles.module.css";

import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { ProjectsPagePopups, useProjectsPopups } from "../../../use-projects-popups.ts";

import ConfirmAddEmployeesToGroupPopup from "../confirmaddemployeestogrouppopup/ConfirmAddEmployeesToGroupPopup.tsx";
import { Fade, Tooltip } from "@mui/material";
import GroupTimesheetVersatileList from "../grouptimesheetversatilelist/GroupTimesheetVersatileList.tsx";
import { SearchBar } from "../../../../../ui/searchbar/SearchBar.tsx";
import SearchInputStyles from "../../../../../ui/searchbar/SearchInputStyles.module.css";

interface AddGroupMemberProps {
  setPopupMode: Dispatch<SetStateAction<ProjectTimesheetGroupPopupModeEnum>>;
  setNewGroupMembersLength: (length: number) => void;
  projectId: number;
  groupId: number;
}

const AddGroupMember: FC<AddGroupMemberProps> = ({ setPopupMode, setNewGroupMembersLength, projectId, groupId }) => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const [searchMember, setSearchMember] = useState<string>("");
  const [newGroupMembers, setNewGroupMembers] = useState<number[]>([]);
  const [isAddGroupMemberButtonHovered, setIsAddGroupMemberButtonHovered] = useState<boolean>(false);
  useEffect(() => {
    setNewGroupMembersLength(newGroupMembers.length);
  }, [newGroupMembers, setNewGroupMembersLength]);

  const isAddGroupMemberDisabled = useMemo(() => {
    return newGroupMembers.length == 0;
  }, [newGroupMembers]);

  return (
    <>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap15, GlobalStyles.overflowHiddenFullHeight)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
          <div
            className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}
            onClick={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.view)}
          >
            <img src={ArrowLeftBlackLarger} alt={"timesheet-group-settings-back-icon"} />
          </div>
          <div className={classNames(AddGroupMemberStyles.backText)}>Add Group Employee</div>
        </div>
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap075,
            GlobalStyles.overflowHiddenFullHeight,
            GlobalStyles.flex1,
          )}
        >
          <SearchBar
            setSearchQuery={setSearchMember}
            placeholder={"Search for employee"}
            searchBarContainerStyles={classNames(
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.gap05,
              SearchInputStyles.searchInputContainer,
            )}
          />
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, AddGroupMemberStyles.addMemberContainer)}>
            <GroupTimesheetVersatileList
              newGroupMembers={newGroupMembers}
              setNewGroupMembers={setNewGroupMembers}
              projectId={projectId}
              searchMember={searchMember}
              groupId={groupId}
              listType={ProjectTimesheetDisplayListTypeEnum.projectEmployees}
              ungrouped
              inSettings
            />
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
            {isAddGroupMemberDisabled ? (
              <Tooltip title={"Select at least one employee first"} TransitionComponent={Fade} placement={"top"} arrow>
                <div className={classNames(AddGroupMemberStyles.addMemberBtn, AddGroupMemberStyles.addMemberBtnDisabled)}>Add to Group</div>
              </Tooltip>
            ) : (
              <div
                className={classNames(
                  AddGroupMemberStyles.addMemberBtn,
                  GlobalStyles.elementWithCursor,
                  isAddGroupMemberButtonHovered && AddGroupMemberStyles.addMemberBtnHovered,
                )}
                onMouseEnter={() => setIsAddGroupMemberButtonHovered(true)}
                onMouseLeave={() => setIsAddGroupMemberButtonHovered(false)}
                onClick={() => onOpenPopup(ProjectsPagePopups.confirmAddEmployeesToGroup, popupHandler)}
              >
                Add to Group
              </div>
            )}
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
            <div
              className={classNames(AddGroupMemberStyles.cancelBtn, GlobalStyles.elementWithCursor)}
              onClick={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.view)}
            >
              Back
            </div>
          </div>
        </div>
      </div>
      {popupHandler.get(ProjectsPagePopups.confirmAddEmployeesToGroup)!.isOpen && (
        <ConfirmAddEmployeesToGroupPopup
          setNewMembers={setNewGroupMembers}
          newMembers={newGroupMembers}
          timesheetGroupId={groupId}
          isOpen={popupHandler.get(ProjectsPagePopups.confirmAddEmployeesToGroup)!.isOpen}
          projectId={projectId}
          closeFn={() => {
            onClosePopup(ProjectsPagePopups.confirmAddEmployeesToGroup, popupHandler);
            setPopupMode(ProjectTimesheetGroupPopupModeEnum.view);
          }}
          headerText={popupHeaders.get(ProjectsPagePopups.confirmAddEmployeesToGroup)}
        />
      )}
    </>
  );
};

export default AddGroupMember;
