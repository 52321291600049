// Ensure all necessary imports are included
import { Dispatch, FC, SetStateAction, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { UiComponentGeneralEnum } from "../../types/UiComponentGeneralType.ts";
import EpcmDatePickerMainStyles from "./EpcmDatePickerMainStyles.module.css";
import EpcmDatePickerSecondaryStyles from "./EpcmDatePickerSecondaryStyles.module.css";
import EpcmDatePickerThirdStyles from "./EpcmDatePickerThirdStyles.module.css";
import EpcmDatePickerFourthStyles from "./EpcmDatePickerFourthStyles.module.css";
import classNames from "classnames";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";
import DatePickerEpcmIcon from "../../assets/images/date-picker-mui-icon.svg";
import { calculateAge, DateFormatEnum, formatDate, MuiDateFormatEnum } from "../../utils/DateManipulation.ts";

interface EpcmDatePickerMuiProps {
  label?: string;
  date: Date | null;
  setDate: Dispatch<SetStateAction<Date | null>>;
  theme?: UiComponentGeneralEnum;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  placeholder?: string;
  availableDates?: Date[];
  isAge?: boolean;
  formatType?: MuiDateFormatEnum;
  showClearButton?: boolean;
}

const EpcmDatePickerMui: FC<EpcmDatePickerMuiProps> = ({
  label,
  date,
  setDate,
  theme = UiComponentGeneralEnum.MAIN,
  minDate,
  maxDate,
  disabled,
  placeholder,
  availableDates,
  isAge,
  formatType,
  showClearButton,
}) => {
  const OpenPickerIcon: FC = () => <img src={DatePickerEpcmIcon} alt="Open DatePicker" />;

  const [open, setOpen] = useState(false); // Assuming we have a way to control the dialog

  const displayValue = date ? `${formatDate(date, DateFormatEnum.DD_MMM_YYYY)}   [${calculateAge(date)} Y/O]` : "Select a date";

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div
        className={classNames(
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          (theme === UiComponentGeneralEnum.MAIN || theme === UiComponentGeneralEnum.FOURTH) && GlobalStyles.gap05,
          theme === UiComponentGeneralEnum.SECONDARY && GlobalStyles.gap05,
          theme === UiComponentGeneralEnum.THIRD && GlobalStyles.gap075,
        )}
      >
        {label ? (
          theme === UiComponentGeneralEnum.MAIN || theme === UiComponentGeneralEnum.FOURTH ? (
            <>
              {showClearButton ? (
                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
                  <label>{label}</label>

                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
                  <div
                    onClick={() => setDate(null)}
                    className={classNames(EpcmDatePickerSecondaryStyles.clearButton, GlobalStyles.elementWithCursor)}
                  >{`Clear`}</div>
                </div>
              ) : (
                <label>{label}</label>
              )}
            </>
          ) : (
            <>
              {showClearButton ? (
                <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
                  <label className={EpcmDatePickerSecondaryStyles.secondaryLabelText}>{label}</label>

                  <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
                  <div
                    onClick={() => setDate(null)}
                    className={classNames(EpcmDatePickerSecondaryStyles.clearButton, GlobalStyles.elementWithCursor)}
                  >{`Clear`}</div>
                </div>
              ) : (
                <label className={EpcmDatePickerSecondaryStyles.secondaryLabelText}>{label}</label>
              )}
            </>
          )
        ) : null}
        {!open && isAge && (
          <div
            className={classNames(EpcmDatePickerSecondaryStyles.ageInput, GlobalStyles.flex, GlobalStyles.centerHorizontal)}
            onClick={() => setOpen(true)}
          >
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, !date ? EpcmDatePickerSecondaryStyles.placeholderText : "")}>
              {date ? displayValue : placeholder}
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical)}>
              <img src={DatePickerEpcmIcon} alt="epcm-datepicker-icon"></img>
            </div>
          </div>
        )}
        {(open || !isAge) && (
          <DatePicker
            value={date}
            disableOpenPicker={disabled}
            onChange={(newDate: Date | null) => {
              setDate(newDate);
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            format={formatType ? formatType : MuiDateFormatEnum.DD_MMM_YYYY}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: theme === UiComponentGeneralEnum.THIRD ? "11px 12px" : theme === UiComponentGeneralEnum.FOURTH ? "9px 12px" : "12px 15px",
                fontFamily: "poppins",
                fontSize: theme === UiComponentGeneralEnum.THIRD ? "13px" : theme === UiComponentGeneralEnum.FOURTH ? "15px" : "15px",
                color: theme === UiComponentGeneralEnum.FOURTH ? "#1c1b1e" : "",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "#dde1ec",
                borderWidth: disabled ? "0px" : "1px",
                fontFamily: "poppins",
                padding: "15px 15px",
                fontSize: "15px",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#1c1b1e",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "#dde1ec",
                borderWidth: disabled ? "0px" : "1px",
                fontFamily: "poppins",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "#dde1ec",
                borderWidth: disabled ? "0px" : "1px",
              },

              "&.Mui-focused .MuiOutlinedInput-root": {
                border: "#dde1ec",
                borderWidth: disabled ? "0px" : "1px",
                fontFamily: "poppins",
                boxShadow: "none",
              },

              "& .Mui-focused": {
                border: "#dde1ec",
                fontFamily: "poppins",
                borderWidth: disabled ? "0" : "1px",
              },
            }}
            className={classNames(
              theme === UiComponentGeneralEnum.MAIN && EpcmDatePickerMainStyles.inputContainer,
              theme === UiComponentGeneralEnum.SECONDARY && EpcmDatePickerSecondaryStyles.calendarInput,
              theme === UiComponentGeneralEnum.THIRD
                ? disabled
                  ? EpcmDatePickerThirdStyles.calendarInput
                  : EpcmDatePickerThirdStyles.inputContainer
                : "",
              theme === UiComponentGeneralEnum.FOURTH
                ? disabled
                  ? EpcmDatePickerFourthStyles.inputContainerSmallerPaddingDisabled
                  : EpcmDatePickerFourthStyles.inputContainerSmallerPadding
                : "",
            )}
            disabled={disabled}
            slotProps={{
              textField: {
                value: date ? date : null,
                placeholder: placeholder,
                disabled: disabled,
              },
            }}
            slots={{
              openPickerIcon: OpenPickerIcon,
            }}
            minDate={minDate}
            maxDate={maxDate}
            shouldDisableDate={(date: Date) =>
              availableDates ? !availableDates.some((availableDate) => availableDate.toDateString() === date?.toDateString()) : false
            }
          />
        )}
      </div>
    </LocalizationProvider>
  );
};

export default EpcmDatePickerMui;
