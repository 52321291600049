import React from "react";
import TimesheetFormHeadersStyles from "./TimesheetFormHeadersStyles.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import { FrontendTimesheetFormHeaderDateType } from "../../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import { DateFormatEnum, formatDate } from "../../../../../../../../utils/DateManipulation.ts";

interface TimesheetFormHeadersProps {
  dateInfoHeaders: FrontendTimesheetFormHeaderDateType[];
}

const TimesheetFormHeaders: React.FC<TimesheetFormHeadersProps> = ({ dateInfoHeaders }) => {
  return (
    <>
      <div className={classNames(TimesheetFormHeadersStyles.gridHeader, TimesheetFormHeadersStyles.firstHeader, GlobalStyles.centerVertical)}>
        {"Group Employees"}
      </div>
      <div className={classNames(TimesheetFormHeadersStyles.gridHeader, GlobalStyles.centerVertical)}>
        <div>Job Title</div>
      </div>
      {dateInfoHeaders.map((dateInfo, index) => (
        <div
          key={dateInfo.id + index}
          className={classNames(
            TimesheetFormHeadersStyles.gridHeader,
            TimesheetFormHeadersStyles.gridDateHeader,
            GlobalStyles.flex,
            GlobalStyles.flexDirectionColumn,
          )}
        >
          <div className={classNames(TimesheetFormHeadersStyles.gridDateHeaderMonth)}>{dateInfo.dayName.toUpperCase().slice(0, 3)}</div>
          <div className={classNames()}>{formatDate(dateInfo.date, DateFormatEnum.DD_MMM_YYYY)}</div>
        </div>
      ))}
    </>
  );
};

export default TimesheetFormHeaders;
