import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ConfigurationCtrLibraryRowStyles from "./ConfigurationCtrLibraryRow.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";

import TrashCanDarkIcon from "../../../../../../../assets/images/trashcan-icon-dark-blue.svg";
import EditIcon from "../../../../../../../assets/images/timesheet-list-item-edit-icon.svg";
import TrashCanRedIcon from "../../../../../../../assets/images/trashcan-icon-red.svg";
import EditIconHover from "../../../../../../../assets/images/timesheet-list-item-edit-hover-icon.svg";
import ConfigurationsTradesRowStyles from "../../../configurationlocations/configurationlocationsrow/ConfigurationLocationsRow.module.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FrontendCtrNormalType, FrontendCtrLimitedType } from "../../../../../../../types/apicallstypes/CtrTypes.ts";
import { Skeleton } from "@mui/material";

import ConfigurationCtrPopup from "../../../popups/configurationctrpopup/ConfigurationCtrPopup.tsx";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../../use-projects-popups.ts";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { useProjectContext } from "../../../../../ProjectsUtils.ts";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../../../../utils/use-response-alert-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { usePopupState } from "../../../../../../../utils/use-popup-state.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { useEpcmApiProjectsUtils } from "../../../../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { DeletePopup } from "../../../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { configurationsPopupType } from "../../../../../../../types/projects/ConfigurationTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../../types/Roles.ts";

interface ConfigurationCtrLibraryRowProps {
  ctrItem: FrontendCtrLimitedType;
}

const ConfigurationCtrLibraryRow: FC<ConfigurationCtrLibraryRowProps> = ({ ctrItem }) => {
  const [deleteIconHovered, setDeleteIconHovered] = useState<boolean>(false);
  const [editIconHovered, setEditIconHovered] = useState<boolean>(false);
  const [isRowHovered, setIsRowHovered] = useState<boolean>(false);
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const { currentProject } = useProjectContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getProjectCtr } = useEpcmApiProjectsUtils();

  const queryClient = useQueryClient();
  const { useDeleteProjectConfigurationEntityMutation } = useEpcmApiProjectUtilsMutations();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const deleteProjectCtrMutation = useDeleteProjectConfigurationEntityMutation(
    currentProject.id,
    ctrItem.id,
    FrontendProjectConfigurationsEntityEnum.Ctr,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: "Project CTR was deleted successfully!" });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );
  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectCtrs, currentProject.id] });
  }, [queryClient, currentProject.id]);

  const ctrItemQuery = useQuery({
    queryKey: [QueryNames.ProjectCtrs, currentProject.id, ctrItem.id],
    queryFn: () => getProjectCtr(currentProject.id, ctrItem.id, DetailLevel.NORMAL),
    enabled: isAuthorized,
    select: (data) => data as FrontendCtrNormalType,
  });

  const ctrItemData = useMemo(() => {
    return ctrItemQuery.data;
  }, [ctrItemQuery.data]);

  const canListConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigList);
  const canUpdateConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigUpdate);
  const canDeleteConfigurations = canPerformProjectAction(ProjectAction.ProjectConfigDelete);

  useEffect(() => {
    return () => {
      void queryClient.cancelQueries({ queryKey: [QueryNames.ProjectCtrs, currentProject.id, ctrItem.id] }).then(() => {
        console.log(`In configuration-ctr-library-row component, ${QueryNames.ProjectCtrs} query for ctr ${ctrItem.id} cancelled`);
      });
    };
  }, [queryClient, currentProject, ctrItem.id]);

  return (
    <>
      <tr
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
        key={ctrItem.id}
        className={classNames(
          ConfigurationsTradesRowStyles.itemContainer,
          isRowHovered && ConfigurationsTradesRowStyles.rowHoverEffect,

          ConfigurationCtrLibraryRowStyles.Trow,
        )}
      >
        {ctrItem && canListConfigurations ? (
          <>
            <td
              className={classNames(
                ConfigurationCtrLibraryRowStyles.tableCell,
                ConfigurationCtrLibraryRowStyles.boldText,
                ConfigurationCtrLibraryRowStyles.tD,
              )}
            >
              {ctrItem.code}
            </td>
            <td
              className={classNames(
                ConfigurationCtrLibraryRowStyles.tableCell,
                ConfigurationCtrLibraryRowStyles.boldText,
                ConfigurationCtrLibraryRowStyles.tD,
              )}
            >
              {ctrItemData ? ctrItemData.description : <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>}
            </td>
            <td
              className={classNames(
                ConfigurationCtrLibraryRowStyles.tableCell,
                ConfigurationCtrLibraryRowStyles.boldText,
                ConfigurationCtrLibraryRowStyles.tD,
              )}
            >
              {ctrItemData ? ctrItemData.type : <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>}
            </td>
            <td
              className={classNames(
                ConfigurationCtrLibraryRowStyles.tableCell,
                ConfigurationCtrLibraryRowStyles.boldText,
                ConfigurationCtrLibraryRowStyles.tD,
              )}
            >
              {ctrItemData ? ctrItemData.category : <Skeleton variant={"rounded"} width={200} height={30}></Skeleton>}
            </td>
            <td className={classNames(ConfigurationCtrLibraryRowStyles.tableCellActions, ConfigurationCtrLibraryRowStyles.tD)}>
              <div
                className={classNames(
                  ConfigurationCtrLibraryRowStyles.tableCellActionsDiv,
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.gap2,
                  GlobalStyles.elementWithCursor,
                )}
              >
                {canDeleteConfigurations && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();

                      onOpenUtilPopup();
                    }}
                    onMouseEnter={() => setDeleteIconHovered(true)}
                    onMouseLeave={() => setDeleteIconHovered(false)}
                  >
                    <img src={deleteIconHovered ? TrashCanRedIcon : TrashCanDarkIcon} alt={"Trashcan dark icon"} />
                  </div>
                )}
                {canUpdateConfigurations && (
                  <div
                    className={classNames(ConfigurationCtrLibraryRowStyles.actionDiv, GlobalStyles.elementWithCursor)}
                    onClick={() => onOpenPopup(ProjectsPagePopups.updateProjectCtr, popupHandler)}
                    onMouseEnter={() => setEditIconHovered(true)}
                    onMouseLeave={() => setEditIconHovered(false)}
                  >
                    <img src={editIconHovered ? EditIconHover : EditIcon} alt={"Edit icon"} />
                  </div>
                )}
              </div>
            </td>
            {isUtilPopupOpen && (
              <DeletePopup
                isOpen={isUtilPopupOpen}
                closeFn={() => {
                  onCloseUtilPopup();
                }}
                onDeleteFn={() => {
                  deleteProjectCtrMutation.mutate();
                  onOpenLoaderPopup();
                }}
              />
            )}
            {isResponseAlertPopupOpen && responseType && responseObject && (
              <ResponseAlertPopup
                responseType={responseType}
                responseObject={responseObject}
                isOpen={isResponseAlertPopupOpen}
                closeFn={() => {
                  initializeResponseAlertPopup();
                  onCloseResponseAlertPopup();
                  onCloseUtilPopup();
                  responseType === ApiResponseTypeEnum.success && invalidateQueries();
                }}
              />
            )}
            {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
          </>
        ) : (
          <></>
        )}
      </tr>
      {popupHandler.get(ProjectsPagePopups.updateProjectCtr)!.isOpen && (
        <ConfigurationCtrPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(ProjectsPagePopups.updateProjectCtr, popupHandler)}
          projectId={currentProject.id}
          isOpen={popupHandler.get(ProjectsPagePopups.updateProjectCtr)!.isOpen}
          secondaryHeaderText={currentProject.name}
          headerText={popupHeaders.get(ProjectsPagePopups.updateProjectCtr)}
          ctrItem={
            {
              code: ctrItem.code,
              description: ctrItemData ? ctrItemData.description : "",
              type: ctrItemData ? ctrItemData.type : "",
              category: ctrItemData ? ctrItemData.category : "",
              id: ctrItem.id,
            } as FrontendCtrNormalType
          }
        />
      )}
    </>
  );
};

export default ConfigurationCtrLibraryRow;
