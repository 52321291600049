import { Dispatch, FC, SetStateAction } from "react";
import MmrCreateNewVersionStyles from "./MmrCreateNewVersion.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import AddMmrGreenIcon from "../../../../../../../assets/images/add-mmr-green-icon.svg";
import { ProjectEmployeeDetailsPopupTypeEnum } from "../../../../../../../types/projects/ProjectEmployeesTypes.ts";
import { useRetrieveUserPermittedActions } from "../../../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../../../types/Roles.ts";

interface MmrCreateNewVersionProps {
  setPopupType: Dispatch<SetStateAction<ProjectEmployeeDetailsPopupTypeEnum>>;
}

export const MmrCreateNewVersion: FC<MmrCreateNewVersionProps> = ({ setPopupType }) => {
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const canEditProjectEmployeeMmr = canPerformProjectAction(ProjectAction.ProjectEmployeeUpdate);

  return (
    <div className={classNames(MmrCreateNewVersionStyles.mainContainer, GlobalStyles.flex)}>
      <div className={classNames(MmrCreateNewVersionStyles.container, GlobalStyles.flex, GlobalStyles.flex2)}>
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.centerHorizontal,
            GlobalStyles.gap075,
            MmrCreateNewVersionStyles.textContainer,
          )}
        >
          {canEditProjectEmployeeMmr && (
            <div
              className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05, GlobalStyles.elementWithCursor)}
              onClick={() => setPopupType(ProjectEmployeeDetailsPopupTypeEnum.create)}
            >
              <div
                className={classNames(
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizontal,
                  GlobalStyles.centerVertical,
                  MmrCreateNewVersionStyles.addMmrIconContainer,
                )}
              >
                <img alt={"add-mmr-icon"} className={classNames(MmrCreateNewVersionStyles.addMmrIcon)} src={AddMmrGreenIcon}></img>
              </div>

              <div
                className={classNames(
                  MmrCreateNewVersionStyles.mainText,
                  GlobalStyles.flex,
                  GlobalStyles.centerHorizonta,
                  GlobalStyles.centerVerticall,
                )}
              >
                Issue New Mmr Version
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
