import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import FormEmployeeInfoDetailedStyles from "./FormEmployeeInfoDetailed.module.css";
import { FC, useEffect, useMemo, useState } from "react";
import { FrontendEmployeeCategoryEnum } from "../../../../../../types/apicallstypes/EmployeesApiTypes.ts";

import arrowSelectBox from "../../../../../../assets/images/arrow-down-dark-blue-fill.svg";
import { Menu, MenuItem } from "@mui/material";

import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../../apicalls/config.ts";
import { useImpersonationStore } from "../../../../../../store/use-impersonation-store.ts";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import InfiniteScrollInViewElement from "../../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useEpcmApiConfigurations } from "../../../../../../apicalls/configurations/useEpcmApiConfigurations.ts";
import { FrontendGlobalPositionType } from "../../../../../../types/apicallstypes/ConfigurationsTypes.ts";
import EpcmDatePickerMui from "../../../../../../ui/epcmdatepickermui/EpcmDatepickerMui.tsx";
import { UiComponentGeneralEnum } from "../../../../../../types/UiComponentGeneralType.ts";

interface FormEmployeeInfoDetailedProps {
  isEditMode: boolean;
  employeePosition: FrontendGlobalPositionType | null;
  employeeCategory: FrontendEmployeeCategoryEnum | null;
  employeeNationality: string;
  employeeDateOfBirth: string | null;
  onEmployeePositionChange: (eventValue: FrontendGlobalPositionType) => void;
  onEmployeeCategoryChange: (eventValue: FrontendEmployeeCategoryEnum) => void;
  onEmployeeNationalityChange: (eventValue: string | undefined) => void;
  onEmployeeDateOfBirthChange: (eventValue: Date) => void;
}

const EMPLOYEE_TYPES_ARRAY: FrontendEmployeeCategoryEnum[] = [
  FrontendEmployeeCategoryEnum.ARCHIRODON,
  FrontendEmployeeCategoryEnum.SUBCONTRACTOR,
  FrontendEmployeeCategoryEnum.RENTED,
];

export const FormEmployeeInfoDetailed: FC<FormEmployeeInfoDetailedProps> = ({
  isEditMode,
  employeePosition,
  employeeCategory,
  employeeNationality,
  employeeDateOfBirth,
  onEmployeePositionChange,
  onEmployeeCategoryChange,
  onEmployeeNationalityChange,
  onEmployeeDateOfBirthChange,
}) => {
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(null);
  const [positionDropdownAnchorEl, setPositionDropdownAnchorEl] = useState<null | HTMLElement>(null);
  const [nationalityDropdownAnchorEl, setNationalityDropdownAnchorEl] = useState<null | HTMLElement>(null);
  const isDropdownMenuOpen = Boolean(dropdownAnchorEl);
  const isPositionDropdownMenuOpen = Boolean(positionDropdownAnchorEl);
  const isNationalityDropdownMenuOpen = Boolean(nationalityDropdownAnchorEl);

  const { projectId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();

  const { ref: nationalitiesRef, inView: natInView } = useInView();
  const { getAllGlobalPositions, getAllNationalities } = useEpcmApiConfigurations();

  const onDropdownMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };
  const onDropdownMenuClose = () => {
    setDropdownAnchorEl(null);
  };
  const onNationalityDropdownMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setNationalityDropdownAnchorEl(event.currentTarget);
  };
  const onNationalityDropdownMenuClose = () => {
    setNationalityDropdownAnchorEl(null);
  };

  const onPositionDropdownMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPositionDropdownAnchorEl(event.currentTarget);
  };
  const onPositionDropdownMenuClose = () => {
    setPositionDropdownAnchorEl(null);
  };
  const allGlobalPositionsQuery = useInfiniteQuery({
    queryKey: [QueryNames.GlobalPositions, projectId],
    queryFn: ({ pageParam }) => getAllGlobalPositions(pageParam, undefined, PAGINATION_PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const allGlobalPositionsData = useMemo(() => allGlobalPositionsQuery.data?.pages.flatMap((page) => page.data), [allGlobalPositionsQuery.data]);

  const allGlobalNationalitiesQuery = useInfiniteQuery({
    queryKey: [QueryNames.GlobalNationalities],
    queryFn: ({ pageParam }) => getAllNationalities(pageParam, undefined, PAGINATION_PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const allGlobalNationalitiesData = useMemo(
    () => allGlobalNationalitiesQuery.data?.pages.flatMap((page) => page.data),
    [allGlobalNationalitiesQuery.data],
  );

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.GlobalNationalities] }).then(() => {
        console.log(`Query ${QueryNames.GlobalNationalities} cancelled`);
      });
    };
  }, [queryClient]);

  useEffect(() => {
    return () => {
      queryClient.cancelQueries({ queryKey: [QueryNames.GlobalPositions, projectId] }).then(() => {
        console.log(`Query ${QueryNames.GlobalPositions} for project ${Number(projectId)} cancelled`);
      });
    };
  }, [allGlobalPositionsData, queryClient, projectId]);

  useEffect(() => {
    if (
      inView &&
      !allGlobalPositionsQuery.isLoading &&
      !allGlobalPositionsQuery.isFetching &&
      !allGlobalPositionsQuery.isFetchingNextPage &&
      allGlobalPositionsQuery.hasNextPage
    ) {
      void allGlobalPositionsQuery.fetchNextPage();
    }
  }, [inView, allGlobalPositionsQuery]);

  useEffect(() => {
    if (
      natInView &&
      !allGlobalNationalitiesQuery.isLoading &&
      !allGlobalNationalitiesQuery.isFetching &&
      !allGlobalNationalitiesQuery.isFetchingNextPage &&
      allGlobalNationalitiesQuery.hasNextPage
    ) {
      void allGlobalNationalitiesQuery.fetchNextPage();
    }
  }, [natInView, allGlobalNationalitiesQuery]);

  return (
    <div className={classNames(FormEmployeeInfoDetailedStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025, GlobalStyles.elementWithCursor)}>
        <div className={classNames()}>{"Employee Position"}</div>
        <div
          className={classNames(FormEmployeeInfoDetailedStyles.inputContainer, GlobalStyles.flex, GlobalStyles.gap)}
          onClick={onPositionDropdownMenuOpen}
        >
          {employeePosition?.id ? (
            <div className={classNames()}>{employeePosition.description}</div>
          ) : (
            <div className={classNames(FormEmployeeInfoDetailedStyles.inputPlaceholder)}>{"Select"}</div>
          )}
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(FormEmployeeInfoDetailedStyles.selectBoxArrowIconImg)} src={arrowSelectBox} alt="arrow" />
          </div>
        </div>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={positionDropdownAnchorEl}
          open={isPositionDropdownMenuOpen}
          onClose={onPositionDropdownMenuClose}
          PaperProps={{
            style: {
              maxHeight: "15em",
              width: "20em",
            },
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          {allGlobalPositionsData &&
            allGlobalPositionsData.map((typeItem) => (
              <MenuItem
                key={typeItem.id}
                selected={typeItem.id === employeePosition?.id}
                onClick={() => {
                  onEmployeePositionChange(typeItem);
                  onPositionDropdownMenuClose();
                }}
              >
                {typeItem.description}
              </MenuItem>
            ))}
          <InfiniteScrollInViewElement reference={ref} infiniteQueryResult={allGlobalPositionsQuery} loaderComponent={<></>} />
        </Menu>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025, GlobalStyles.elementWithCursor)}>
        <div className={classNames()}>{"Employee Type"}</div>
        <div className={classNames(FormEmployeeInfoDetailedStyles.inputContainer, GlobalStyles.flex, GlobalStyles.gap)} onClick={onDropdownMenuOpen}>
          {employeeCategory ? (
            <div className={classNames()}>{employeeCategory}</div>
          ) : (
            <div className={classNames(FormEmployeeInfoDetailedStyles.inputPlaceholder)}>{"Select"}</div>
          )}
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(FormEmployeeInfoDetailedStyles.selectBoxArrowIconImg)} src={arrowSelectBox} alt="arrow" />
          </div>
        </div>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={dropdownAnchorEl}
          open={isDropdownMenuOpen}
          onClose={onDropdownMenuClose}
          PaperProps={{
            style: {
              maxHeight: "15em",
              width: "20em",
              overflowY: "auto",
            },
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          {EMPLOYEE_TYPES_ARRAY.map((typeItem) => (
            <MenuItem
              key={typeItem}
              selected={typeItem === employeeCategory}
              onClick={() => {
                onEmployeeCategoryChange(typeItem);
                onDropdownMenuClose();
              }}
            >
              {typeItem}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025, GlobalStyles.elementWithCursor)}>
        <div className={classNames()}>{"Nationality"}</div>
        <div
          className={classNames(FormEmployeeInfoDetailedStyles.inputContainer, GlobalStyles.flex, GlobalStyles.gap)}
          onClick={onNationalityDropdownMenuOpen}
        >
          {employeeNationality ? (
            <div className={classNames()}>{employeeNationality}</div>
          ) : (
            <div className={classNames(FormEmployeeInfoDetailedStyles.inputPlaceholder)}>{"Select"}</div>
          )}
          <div className={classNames(GlobalStyles.flex1)} />
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img className={classNames(FormEmployeeInfoDetailedStyles.selectBoxArrowIconImg)} src={arrowSelectBox} alt="arrow" />
          </div>
        </div>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={nationalityDropdownAnchorEl}
          open={isNationalityDropdownMenuOpen}
          onClose={onNationalityDropdownMenuClose}
          PaperProps={{
            style: {
              maxHeight: "15em",
              width: "20em",
            },
          }}
          transformOrigin={{ horizontal: "center", vertical: "top" }}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          {allGlobalNationalitiesData &&
            allGlobalNationalitiesData.map((nationalityItem) => (
              <MenuItem
                key={nationalityItem.description}
                selected={nationalityItem.description === employeeNationality}
                onClick={() => {
                  onEmployeeNationalityChange(nationalityItem.description);
                  onNationalityDropdownMenuClose();
                }}
              >
                {nationalityItem.description}
              </MenuItem>
            ))}
          <InfiniteScrollInViewElement reference={nationalitiesRef} infiniteQueryResult={allGlobalNationalitiesQuery} loaderComponent={<></>} />
        </Menu>
      </div>
      {!isEditMode && (
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames()}>{"Date of Birth"}</div>
          <EpcmDatePickerMui
            date={employeeDateOfBirth ? new Date(employeeDateOfBirth) : null}
            theme={UiComponentGeneralEnum.SECONDARY}
            isAge={true}
            placeholder={"Select Date"}
            setDate={(date) => date && onEmployeeDateOfBirthChange(date as Date)}
          />
        </div>
      )}
    </div>
  );
};
