import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import GroupExistingMembersListSelectStyles from "./GroupTimesheetVersatileListStyles.module.css";
import GroupEmployeeListItem from "../groupemployeelistitem/GroupEmployeeListItem.tsx";
import { useInView } from "react-intersection-observer";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import InfiniteScrollInViewElement from "../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import EmployeeItemSkeletonLoader from "../loaders/EmployeeItemSkeletonLoader.tsx";
import { useEpcmApiProjectEmployee } from "../../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts"; // Adjust import paths
import { QueryModelWithPagination, QueryNames, SubQueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../apicalls/config.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import { ProjectTimesheetDisplayListTypeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";
import {
  FrontendProjectEmployeeLimited,
  FrontendProjectGroupEmployeeLimitedType,
} from "../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";

interface GroupEmployeesListProps {
  projectId: number;
  groupId: number | null;
  newGroupMembers: number[];
  setNewGroupMembers: Dispatch<SetStateAction<number[]>>;
  searchMember: string;
  listType: ProjectTimesheetDisplayListTypeEnum;
  ungrouped?: boolean;
  inSettings?: boolean;
  periodStart?: Date;
  periodEnd?: Date;
}

const GroupEmployeesList: FC<GroupEmployeesListProps> = ({
  projectId,
  groupId,
  newGroupMembers,
  setNewGroupMembers,
  searchMember,
  listType,
  ungrouped,
  inSettings,
  periodStart,
  periodEnd,
}) => {
  const { inView, ref } = useInView();
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();

  const { getAllProjectEmployees } = useEpcmApiProjectEmployee();
  const { getAllTimesheetGroupsEmployees } = useEpcmApiProjectsTimesheetsGroups();

  const isProjectEmployeesQuery = useMemo(() => listType === ProjectTimesheetDisplayListTypeEnum.projectEmployees, [listType]);
  const isTimesheetGroupEmployeesQuery = useMemo(() => listType === ProjectTimesheetDisplayListTypeEnum.groupEmployess, [listType]);

  const projectEmployeesQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectEmployees, projectId, groupId, SubQueryNames.employeesVersatileList, searchMember],
    queryFn: ({ pageParam }) => getAllProjectEmployees(projectId, pageParam, searchMember, PAGINATION_PAGE_SIZE, ungrouped),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized && isProjectEmployeesQuery,
    select: (data) => data as InfiniteData<QueryModelWithPagination<FrontendProjectEmployeeLimited>>,
  });

  const timesheetGroupEmployeesQuery = useInfiniteQuery({
    queryKey: [QueryNames.TimesheetGroupEmployees, projectId, groupId, SubQueryNames.employeesVersatileList, periodStart, periodEnd],
    queryFn: ({ pageParam }) =>
      getAllTimesheetGroupsEmployees(projectId, Number(groupId), PAGINATION_PAGE_SIZE, pageParam, undefined, periodStart, periodEnd),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized && groupId != null && isTimesheetGroupEmployeesQuery,
    select: (data) => data as InfiniteData<QueryModelWithPagination<FrontendProjectGroupEmployeeLimitedType>>,
  });

  const activeQuery = useMemo(
    () => (isProjectEmployeesQuery ? projectEmployeesQuery : timesheetGroupEmployeesQuery),
    [isProjectEmployeesQuery, projectEmployeesQuery, timesheetGroupEmployeesQuery],
  );
  const totalEmployeesCount = useMemo(() => {
    return activeQuery.data?.pages[0]?.totalCount ?? 0;
  }, [activeQuery.data]);

  const employeeData = useMemo(() => {
    return activeQuery.data?.pages.flatMap((page) => page.data) ?? [];
  }, [activeQuery.data]);

  const isEmployeeSelected = (employeeId: number) => newGroupMembers.includes(employeeId);

  const toggleEmployeeSelection = (id: number) => {
    setNewGroupMembers((prev) => (prev.includes(id) ? prev.filter((c) => c !== id) : [...prev, id]));
  };

  useEffect(() => {
    if (inView && !activeQuery.isLoading && !activeQuery.isFetchingNextPage && activeQuery.hasNextPage) {
      void activeQuery.fetchNextPage();
    }
  }, [inView, activeQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectEmployees, projectId, groupId, SubQueryNames.employeesVersatileList, searchMember] })
        .then(() => console.log(`In project with id ${projectId}, ${QueryNames.ProjectEmployees} query canceled`));
      queryClient
        .cancelQueries({
          queryKey: [QueryNames.TimesheetGroupEmployees, projectId, groupId, SubQueryNames.employeesVersatileList, periodStart, periodEnd],
        })
        .then(() => console.log(`In project with id ${projectId}, ${QueryNames.TimesheetGroupEmployees} query canceled`));
    };
  }, [queryClient, projectId, groupId, listType, searchMember, periodStart, periodEnd]);

  return (
    <div
      className={classNames(
        inSettings
          ? GroupExistingMembersListSelectStyles.radioGroupContainerInsideSettings
          : GroupExistingMembersListSelectStyles.radioGroupContainer,
        GlobalStyles.flex,
        GlobalStyles.gap,
        GlobalStyles.flex1,
        GlobalStyles.flexDirectionColumn,
      )}
    >
      {employeeData && totalEmployeesCount ? (
        employeeData.map((employee) => (
          <div
            key={employee.id}
            className={classNames(
              GroupExistingMembersListSelectStyles.radioContainer,
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.gap075,
            )}
            onClick={() => toggleEmployeeSelection(employee.id)}
          >
            <input
              type="checkbox"
              value={employee.id}
              checked={isEmployeeSelected(employee.id)}
              //onChange={() => toggleEmployeeSelection(employee.id)}
              className={classNames(GroupExistingMembersListSelectStyles.radioInput)}
            />
            <GroupEmployeeListItem projectId={projectId} id={employee.id} />
            <InfiniteScrollInViewElement
              reference={ref}
              infiniteQueryResult={activeQuery}
              loaderComponent={<EmployeeItemSkeletonLoader width={"100%"} />}
            />
          </div>
        ))
      ) : activeQuery.isLoading ? (
        <div
          className={classNames(
            GroupExistingMembersListSelectStyles.radioContainer,
            GlobalStyles.flex,
            GlobalStyles.centerHorizontal,
            GlobalStyles.gap075,
          )}
        >
          <EmployeeItemSkeletonLoader width={inSettings ? 490 : ""} />
        </div>
      ) : (
        <div
          className={classNames(
            GlobalStyles.emptyListMsgSmall,
            GlobalStyles.centerVertical,
            GlobalStyles.centerHorizontal,
            GlobalStyles.overflowHiddenFullHeight,
          )}
        >
          No Employees found
        </div>
      )}
    </div>
  );
};

export default GroupEmployeesList;
