import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../config";
import { FrontendFileRetrievalResponseType } from "../../types/apicallstypes/GeneralTypes.ts";
import { OvertimeRequestExportType } from "epcm-common/dist/Types/OvertimeRequestTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getOvertimeTemplate = async (id: number, type: OvertimeRequestExportType): Promise<FrontendFileRetrievalResponseType> => {
  const params = new URLSearchParams();
  type && params.set("type", `${type}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/overtime`,
    params: params,
    method: "GET",
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  const responseData = response.data;

  return {
    fileId: responseData,
    fileName: response.headers["content-disposition"]?.split('filename="')[1].split(".")[0] ?? `overtime-template-project-${id}.xlsx`,
  };
};
