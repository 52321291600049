import { FrontendTimesheetStatusEnum, FrontendTimesheetType } from "../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import classNames from "classnames";
import ChangeTimesheetStatusContentStyles from "./ChangeTimesheetStatusContent.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import checkIcon from "../../../../../assets/images/tick-white.svg";
import preparedTimesheetIcon from "../../../../../assets/images/prepared-timesheet-icon.svg";
import { capitalizeFirstLetter } from "../../../../../utils/StringManipulation.ts";
import pendingIconTimesheetStatus from "../../../../../assets/images/pending-timesheet-icon-status.svg";
import checkCircledIcon from "../../../../../assets/images/tick-circled.svg";
import xMarkCircledIcon from "../../../../../assets/images/x-mark-circled.svg";
import { formatDate } from "../../../../../utils/DateManipulation.ts";
import userIcon from "../../../../../assets/images/profile-blue-lines.svg";
import { FC, useMemo } from "react";
import { useRetrieveUserPermittedActions } from "../../../../../utils/useRetrieveUserPermittedActions.ts";
import { ProjectAction } from "../../../../../types/Roles.ts";

interface ChangeTimesheetStatusPopupProps {
  timesheetItem: FrontendTimesheetType;
  statusItem: FrontendTimesheetStatusEnum;
  statusSelected: FrontendTimesheetStatusEnum;
}

const ChangeTimesheetStatusContent: FC<ChangeTimesheetStatusPopupProps> = ({ timesheetItem, statusSelected, statusItem }) => {
  const { canPerformProjectAction } = useRetrieveUserPermittedActions();

  const isChangeStatusFromPendingToPreparedAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPendingToPrepared);
  const isChangeStatusFromPreparedToPendingAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToPending);
  const isChangeStatusToReviewedAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromPreparedToReviewed);
  const isChangeStatusBackToPreparedAllowed = canPerformProjectAction(ProjectAction.ProjectTimesheetChangeStatusFromReviewedToPrepared);

  const canShowTheStatus = useMemo(() => {
    return (
      (isChangeStatusFromPendingToPreparedAllowed && statusItem === FrontendTimesheetStatusEnum.PENDING) ||
      (isChangeStatusFromPreparedToPendingAllowed && statusItem === FrontendTimesheetStatusEnum.PREPARED) ||
      (isChangeStatusToReviewedAllowed && statusItem === FrontendTimesheetStatusEnum.PREPARED) ||
      (statusItem === FrontendTimesheetStatusEnum.REVIEWED && isChangeStatusBackToPreparedAllowed)
    );
  }, [isChangeStatusFromPendingToPreparedAllowed, isChangeStatusToReviewedAllowed, statusItem]);

  return (
    <>
      {canShowTheStatus && (
        <>
          <div className={classNames(ChangeTimesheetStatusContentStyles.statusContainer, GlobalStyles.flex, GlobalStyles.gap05)}>
            <div
              className={classNames(
                ChangeTimesheetStatusContentStyles.checkIcon,
                statusItem === statusSelected &&
                  (statusSelected === FrontendTimesheetStatusEnum.REVIEWED
                    ? ChangeTimesheetStatusContentStyles.checkIconApproved
                    : statusSelected === FrontendTimesheetStatusEnum.PENDING
                      ? ChangeTimesheetStatusContentStyles.checkIconPending
                      : ChangeTimesheetStatusContentStyles.checkIconRejected),
                GlobalStyles.centerVertical,
              )}
            >
              <img
                src={statusItem !== FrontendTimesheetStatusEnum.PREPARED ? checkIcon : preparedTimesheetIcon}
                alt="check"
                className={classNames(ChangeTimesheetStatusContentStyles.checkIconImg)}
              />
            </div>
            <div className={classNames(GlobalStyles.centerVertical)}>{capitalizeFirstLetter(statusItem)}</div>
          </div>
          <div
            className={classNames(
              ChangeTimesheetStatusContentStyles.selectedStatusInfoContainer,
              statusSelected !== statusItem && ChangeTimesheetStatusContentStyles.selectedStatusInfoContainerInvisible,
              GlobalStyles.flex,
              GlobalStyles.gap,
            )}
          >
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap05)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                <img
                  src={
                    statusItem === FrontendTimesheetStatusEnum.PENDING
                      ? pendingIconTimesheetStatus
                      : statusItem === FrontendTimesheetStatusEnum.REVIEWED
                        ? checkCircledIcon
                        : xMarkCircledIcon
                  }
                  alt="fileUploadStatus"
                  className={classNames(
                    ChangeTimesheetStatusContentStyles.selectedStatusInfoImg,
                    timesheetItem.status === statusSelected ? "" : ChangeTimesheetStatusContentStyles.selectedStatusInfoContainerInvisible,
                  )}
                />
              </div>

              <div className={classNames(GlobalStyles.centerVertical)}>
                {statusItem === timesheetItem.status ? formatDate(timesheetItem.editDate) : ""}
                {/* TODO: Change when date created is availaable */}
              </div>
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.gap025)}>
              <div className={classNames(GlobalStyles.centerVertical)}>
                {timesheetItem.status === statusSelected && (
                  <img src={userIcon} alt="user" className={classNames(ChangeTimesheetStatusContentStyles.selectedStatusInfoImg)} />
                )}
              </div>

              <div className={classNames(GlobalStyles.centerVertical)}>
                {statusItem !== timesheetItem.status
                  ? ""
                  : timesheetItem.status === FrontendTimesheetStatusEnum.PENDING
                    ? (timesheetItem.editor?.name ?? "-")
                    : timesheetItem.status === FrontendTimesheetStatusEnum.PREPARED
                      ? (timesheetItem.submitter?.user.name ?? "-")
                      : timesheetItem.status === FrontendTimesheetStatusEnum.REVIEWED
                        ? (timesheetItem.reviewer?.user.name ?? "-")
                        : ""}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ChangeTimesheetStatusContent;
