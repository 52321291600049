import { FrontendProfileType } from "../../types/apicallstypes/ProfileApiTypes.ts";
import { getMe } from "./getMe.ts";
import { convertUserProfileData } from "../../types/apicallstypes/apicallstypesconverters/ProfileApiConverter.ts";

interface ApiDefinition {
  getMe: () => Promise<FrontendProfileType>;
}

export const useEpcmApiProfile = (): ApiDefinition => {
  const getMeConverted = async (): Promise<FrontendProfileType> => {
    const profileData = await getMe();
    return convertUserProfileData(profileData);
  };

  return {
    getMe: getMeConverted,
  };
};
