import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { TradeLimited } from "epcm-common/dist/Types/TradeTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getManpowerPositionCodeTrade = async (
  id: number,
  tradeId: number,
  detailLevel: DetailLevel = DetailLevel.LIMITED,
): Promise<TradeLimited> => {
  const params = new URLSearchParams();
  detailLevel && params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/trade/${tradeId}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  return response.data satisfies TradeLimited;
};
