import { MmrDetailed, MmrNormal } from "epcm-common/dist/Types/MmrTypes";
import {
  FrontendMmrDateStatusEnum,
  FrontendProjectEmployeeMmrBasicType,
  FrontendProjectEmployeeMmrDetailedType,
  FrontendProjectEmployeeMmrNormalType,
  FrontendProjectEmployeeMmrType,
  FrontendProjectEmployeeToMmrType,
} from "../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { getAllProjectEmployeeMmr } from "./getAllProjectEmployeeMmr.ts";
import {
  convertProjectEmployeeMmrData,
  convertSingleProjectEmployeeMmrBasicData,
  convertSingleProjectEmployeeMmrDetailedData,
  convertSingleProjectEmployeeMmrNormalData,
} from "../../../types/apicallstypes/apicallstypesconverters/projects/projectemployees/ProjectEmployeeMmrApiConverter.ts";
import { QueryModelWithCount, QueryModelWithPagination } from "../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { getProjectEmployeeMmr } from "./getProjectEmployeeMmr.ts";
import { convertProjectEmployeesData } from "../../../types/apicallstypes/apicallstypesconverters/projects/ProjectsApiConverter.ts";
import { getAllProjectEmployees } from "./getAllProjectEmployees.ts";
import { getProjectEmployee } from "./getProjectEmployee.ts";

import { convertSingleProjectEmployeeData } from "../../../types/apicallstypes/apicallstypesconverters/projects/projectemployees/ProjectEmployeeApiConverter.ts";
import { FrontendMmrStatusQueryEnum, FrontendProjectEmployeeBasicType } from "../../../types/projects/ProjectEmployeesTypes.ts";
import { getProjectEmployeesCount } from "./getProjectEmployeesCounts.ts";
import { getAllProjectEmployeeMmrCtrs } from "./getAllProjectEmployeeMmrCtrs.ts";
import { FrontendCtrLimitedType } from "../../../types/apicallstypes/CtrTypes.ts";
import { convertAllCtrsLimitedFrontend } from "../../../types/apicallstypes/apicallstypesconverters/CtrApiConverter.ts";
import { FrontendMmrDateLimits } from "../../../types/projects/MmrTypes.ts";
import { convertProjectEmployeeMmrDates } from "../../../types/apicallstypes/apicallstypesconverters/projects/projectemployees/ProjectEmployeeMmrDatesConverter.ts";
import getProjectEmployeeMmrDateLimits from "./getProjectEmployeeMmrDateLimits.ts";
import { FrontendFileIdResponseType } from "../../../types/apicallstypes/GeneralTypes.ts";
import { getBulkMmrExport } from "./getBulkMmrExport.ts";
import { getMmrRegisterExport } from "./getMmrRegisterExport.ts";
import { getMmrFileExported } from "./getMmrFileExported.ts";

interface ApiDefinition {
  getAllProjectEmployeeMmr: (
    id: number,
    projectEmployeeId: number,
    pageOffset?: number,
    pageSize?: number,
    mmrDateStatus?: FrontendMmrDateStatusEnum,
    searchFilter?: string,
  ) => Promise<QueryModelWithPagination<FrontendProjectEmployeeMmrType>>;

  getProjectEmployeeMmr: (
    id: number,
    projectEmployeeId: number,
    mmrId: number,
    detailLevel?: DetailLevel,
  ) => Promise<FrontendProjectEmployeeMmrDetailedType | FrontendProjectEmployeeMmrBasicType | FrontendProjectEmployeeMmrNormalType>;

  getAllProjectEmployees: (
    id: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
    ungrouped?: boolean,
    mmrStatus?: FrontendMmrStatusQueryEnum,
  ) => Promise<QueryModelWithPagination<FrontendProjectEmployeeToMmrType>>;
  getAllProjectEmployeesMmrStatusCounts: (id: number, mmrStatus: FrontendMmrStatusQueryEnum) => Promise<QueryModelWithCount>;

  getSingleProjectEmployee: (id: number, projectEmployeeId: number, detailLevel?: DetailLevel) => Promise<FrontendProjectEmployeeBasicType>;
  getAllProjectEmployeeMmrCtrs: (
    id: number,
    projectEmployeeId: number,
    mmrId: number,
    pageOffset?: number,
    pageSize?: number,
    searchFilter?: string,
  ) => Promise<QueryModelWithPagination<FrontendCtrLimitedType>>;

  getProjectEmployeeMmrDateLimits: (projectId: number, projectEmployeeId: number, mmrId: number) => Promise<FrontendMmrDateLimits>;

  getBulkMmrExport: (projectId: number) => Promise<FrontendFileIdResponseType>;
  getMmrRegisterExport: (projectId: number) => Promise<FrontendFileIdResponseType>;
  getMmrFile: (projectId: number, projectEmployeeId: number, mmrId: number) => Promise<FrontendFileIdResponseType>;
}

export const useEpcmApiProjectEmployee = (): ApiDefinition => {
  const getAllProjectEmployeesConverted = async (
    id: number,
    pageOffset?: number,
    searchFilter?: string,
    pageSize?: number,
    ungrouped?: boolean,
    mmrStatus?: FrontendMmrStatusQueryEnum,
  ): Promise<QueryModelWithPagination<FrontendProjectEmployeeToMmrType>> => {
    const projectEmployeesData = await getAllProjectEmployees(id, pageOffset, pageSize, searchFilter, ungrouped, mmrStatus);

    return {
      data: convertProjectEmployeesData(projectEmployeesData.data),
      nextPage: projectEmployeesData.nextPage,
      totalCount: projectEmployeesData.totalCount,
    };
  };
  const getAllProjectEmployeesMmrStatusCountsConverted = async (id: number, mmrStatus: FrontendMmrStatusQueryEnum): Promise<QueryModelWithCount> => {
    const projectEmployeesCount = await getProjectEmployeesCount(id, mmrStatus);

    return { count: projectEmployeesCount.count };
  };

  const getSingleProjectEmployeeConverted = async (
    id: number,
    projectEmployeeId: number,
    detailLevel: DetailLevel = DetailLevel.BASIC,
  ): Promise<FrontendProjectEmployeeBasicType> => {
    const projectEmployeeData = await getProjectEmployee(id, projectEmployeeId, detailLevel);

    return convertSingleProjectEmployeeData(projectEmployeeData);
  };

  const getAllProjectEmployeeMmrConverted = async (
    id: number,
    projectEmployeeId: number,
    pageOffset?: number,
    pageSize?: number,
    mmrDateStatus?: FrontendMmrDateStatusEnum,
    searchFilter?: string,
  ): Promise<QueryModelWithPagination<FrontendProjectEmployeeMmrType>> => {
    const projectEmployeeMmrData = await getAllProjectEmployeeMmr(id, projectEmployeeId, pageOffset, pageSize, mmrDateStatus, searchFilter);

    return {
      data: convertProjectEmployeeMmrData(projectEmployeeMmrData.data),
      nextPage: projectEmployeeMmrData.nextPage,
      totalCount: projectEmployeeMmrData.totalCount,
    };
  };

  const getProjectEmployeeMmrConverted = async (
    id: number,
    projectEmployeeId: number,
    mmrId: number,
    detailLevel?: DetailLevel,
  ): Promise<FrontendProjectEmployeeMmrDetailedType | FrontendProjectEmployeeMmrBasicType | FrontendProjectEmployeeMmrNormalType> => {
    const projectEmployeeMmrData = await getProjectEmployeeMmr(id, projectEmployeeId, mmrId, detailLevel);

    return detailLevel == DetailLevel.DETAILED
      ? convertSingleProjectEmployeeMmrDetailedData(projectEmployeeMmrData as MmrDetailed)
      : detailLevel == DetailLevel.NORMAL
        ? convertSingleProjectEmployeeMmrNormalData(projectEmployeeMmrData as MmrNormal)
        : convertSingleProjectEmployeeMmrBasicData(projectEmployeeMmrData);
  };

  const getAllProjectEmployeeMmrCtrsConverted = async (
    id: number,
    projectEmployeeId: number,
    mmrId: number,
    pageOffset?: number,
    pageSize?: number,
    searchFilter?: string,
  ): Promise<QueryModelWithPagination<FrontendCtrLimitedType>> => {
    const projectEmployeeMmrCtrData = await getAllProjectEmployeeMmrCtrs(id, projectEmployeeId, mmrId, pageOffset, pageSize, searchFilter);

    return {
      data: convertAllCtrsLimitedFrontend(projectEmployeeMmrCtrData.data),
      nextPage: projectEmployeeMmrCtrData.nextPage,
      totalCount: projectEmployeeMmrCtrData.totalCount,
    };
  };
  const getProjectEmployeeMmrDateLimitsConverted = async (
    projectId: number,
    projectEmployeeId: number,
    mmrId: number,
  ): Promise<FrontendMmrDateLimits> => {
    const response = await getProjectEmployeeMmrDateLimits(projectId, projectEmployeeId, mmrId);

    return convertProjectEmployeeMmrDates({ ...response });
  };

  const getBulkMmrExportConverted = async (projectId: number): Promise<FrontendFileIdResponseType> => {
    const response = await getBulkMmrExport(projectId);

    return {
      fileId: response.fileId,
    };
  };

  const getMmrRegisterExportConverted = async (projectId: number): Promise<FrontendFileIdResponseType> => {
    const response = await getMmrRegisterExport(projectId);

    return {
      fileId: response.fileId,
    };
  };

  const getMmrFileConverted = async (projectId: number, projectEmployeeId: number, mmrId: number): Promise<FrontendFileIdResponseType> => {
    const response = await getMmrFileExported(projectId, projectEmployeeId, mmrId);

    return {
      fileId: response.fileId,
    };
  };
  return {
    getAllProjectEmployeeMmr: getAllProjectEmployeeMmrConverted,
    getProjectEmployeeMmr: getProjectEmployeeMmrConverted,
    getAllProjectEmployees: getAllProjectEmployeesConverted,
    getSingleProjectEmployee: getSingleProjectEmployeeConverted,
    getAllProjectEmployeesMmrStatusCounts: getAllProjectEmployeesMmrStatusCountsConverted,
    getAllProjectEmployeeMmrCtrs: getAllProjectEmployeeMmrCtrsConverted,
    getProjectEmployeeMmrDateLimits: getProjectEmployeeMmrDateLimitsConverted,
    getBulkMmrExport: getBulkMmrExportConverted,
    getMmrRegisterExport: getMmrRegisterExportConverted,
    getMmrFile: getMmrFileConverted,
  };
};
