import { Accordion, styled } from "@mui/material";

export const EPCMAccordion = styled(Accordion)({
  // Styles for the root
  boxShadow: "none",
  borderTop: "0.1px solid #DDE1EC",
  padding: "0em 0em",

  "& .MuiAccordionSummary-root,.MuiAccordionDetails-root": {
    padding: "0em 0em",
  },
  "& ..MuiAccordionSummary-root": {
    padding: "0em !important",
  },
});
