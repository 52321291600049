import classNames from "classnames";
import ConfigurationTitleStyles from "../../../projects/project/configurationmenu/configurationtitle/ConfigurationTitle.module.css";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import { AppRoutesPaths } from "../../../../types/AppRoutesTypes.ts";

import { FC } from "react";
import backToConfigButtonImg from "../../../../assets/images/configuration/backToConfigButtonImg.svg";
import closeButtonImg from "../../../../assets/images/configuration/black-close-button.svg";
import { GlobalConfigurationBackRouteType } from "../../../../types/settings/GlobalConfigurationsBackRouteTypes.ts";
import { NavLink, Outlet } from "react-router-dom";

export interface ConfigurationTitleProps {
  routeType: GlobalConfigurationBackRouteType;
}

const GlobalConfigurationTitle: FC<ConfigurationTitleProps> = ({ routeType }) => {
  const buttonImage = routeType === GlobalConfigurationBackRouteType.BackToGlobalConfigurations ? backToConfigButtonImg : closeButtonImg;

  return (
    <>
      <div className={classNames(ConfigurationTitleStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
          <div className={classNames(ConfigurationTitleStyles.pageHeader, GlobalStyles.centerVertical)}>Configurations</div>
          <div className={classNames(GlobalStyles.flex3)} />
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
            <NavLink to={`/${AppRoutesPaths.configurations}`} end>
              {({ isActive }) =>
                !isActive && <div className={classNames(ConfigurationTitleStyles.exitText, GlobalStyles.elementWithCursor)}>{routeType}</div>
              }
            </NavLink>
            <NavLink to={`/${AppRoutesPaths.configurations}`} end>
              {({ isActive }) => !isActive && <img className={classNames(GlobalStyles.elementWithCursor)} src={buttonImage} alt="back button" />}
            </NavLink>
          </div>
        </div>
      </div>
      {<Outlet />}
    </>
  );
};

export default GlobalConfigurationTitle;
