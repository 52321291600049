import { EPCM_API_URLS, epcmAxiosInstance } from "../../../../config.ts";
import { ApiRequestBodyTimesheetGroupAddProjectEmployees } from "epcm-common/dist/Types/TimesheetGroupTypes.ts";
import { AxiosRequestConfig } from "axios";

const API = `${EPCM_API_URLS.projects}`;

export const createTimesheetGroupEmployees = async (
  projectId: number,
  timesheetGroupId: number,
  projectEmployeesData: ApiRequestBodyTimesheetGroupAddProjectEmployees,
) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/timesheet-groups/${timesheetGroupId}/employees`,
    method: "POST",
    data: projectEmployeesData,
  } as AxiosRequestConfig;

  return epcmAxiosInstance(config);
};
