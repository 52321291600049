import { PopupType } from "../../types/PopupType.ts";
import { FC } from "react";
import LoaderPopupStyles from "./LoaderPopup.module.css";
import { PopupStructure } from "../popupstructure/PopupStructure.tsx";
import loaderGif from "../../assets/images/Loader-figma.gif";
import classNames from "classnames";
import GlobalStyles from "../../assets/css/GlobalStyles.module.css";

interface LoaderPopupProps extends PopupType {}

export const LoaderPopup: FC<LoaderPopupProps> = ({ isOpen, closeFn }) => {
  return (
    <PopupStructure popupButtons={[]} isOpen={isOpen} closeFn={closeFn} headerText={undefined}>
      <div className={classNames(LoaderPopupStyles.contentContainer, GlobalStyles.flex)}>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img src={loaderGif} alt="loader" className={classNames()} />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
    </PopupStructure>
  );
};
