import { SubPositionLimited } from "epcm-common/dist/Types/SubPositionTypes";
import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";

const URL = `${EPCM_API_URLS.projects}`;

export const getAllProjectSubpositions = async (projectId: number, positionId: number): Promise<SubPositionLimited[]> => {
  const config: AxiosRequestConfig = {
    url: `${URL}/${projectId}/position/${positionId}/sub-positions`,
    method: "GET",
  };

  const response = await epcmAxiosInstance(config);

  return response.data satisfies SubPositionLimited[];
};
