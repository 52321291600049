import { FrontendUserRolesType } from "../types/apicallstypes/UsersApiTypes.ts";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface ProjectRolesState {
  projectRoles: FrontendUserRolesType[];
  setProjectRoles: (newProjectRoles: FrontendUserRolesType[]) => void;
}

export const useProjectRoleStore = create<ProjectRolesState>()(
  persist(
    (set, get) => ({
      projectRoles: get()?.projectRoles,
      setProjectRoles: (newProjectRoles: FrontendUserRolesType[]) => {
        set((state) => ({
          ...state,
          projectRoles: newProjectRoles,
        }));
      },
    }),
    {
      name: "project-roles-storage",
    },
  ),
);
