import classNames from "classnames";
import ConfigurationSubpositionStyles from "./ConfigurationSubpositionTooltip.module.css";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import settingGearIcon from "../../../../../../../assets/images/settings-icon-blue-dark.svg";
import deleteIcon from "../../../../../../../assets/images/trashcan-icon-red.svg";
import DeleteMmrItemGrayIcon from "../../../../../../../assets/images/delete-bin-light-grey.svg";

import { FC, useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";

import { usePopupState } from "../../../../../../../utils/use-popup-state.ts";
import { useResponseAlertPopupStateType } from "../../../../../../../utils/use-response-alert-popup-state.ts";
import { FrontendSubPositionType } from "../../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { ProjectsPagePopups, useProjectsPopups } from "../../../../../use-projects-popups.ts";
import { configurationsPopupType } from "../../../../../../../types/projects/ConfigurationTypes.ts";
import { useProjectContext } from "../../../../../ProjectsUtils.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { DeletePopup } from "../../../../../../../ui/deletepopup/DeletePopup.tsx";
import { ResponseAlertPopup } from "../../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import ConfigurationSubpositionsPopup from "../../../popups/configurationsubpositionspopup/ConfigurationSubpositionsPopup.tsx";
import { Tooltip } from "@mui/material";

interface ConfigurationSubpositionTooltipProps {
  positionId: number;
  subpositionItem: FrontendSubPositionType;
  canBeDeleted: boolean;
}

const ConfigurationSubpositionTooltip: FC<ConfigurationSubpositionTooltipProps> = ({ subpositionItem, positionId, canBeDeleted }) => {
  const { currentProject } = useProjectContext();
  const queryClient = useQueryClient();
  const { useDeleteProjectConfigurationEntityMutation } = useEpcmApiProjectUtilsMutations();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();
  const { isUtilPopupOpen, onOpenUtilPopup, onCloseUtilPopup } = usePopupState();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();

  const deleteProjectSubpositionMutation = useDeleteProjectConfigurationEntityMutation(
    currentProject.id,
    positionId,
    FrontendProjectConfigurationsEntityEnum.Subposition,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: "Sub-position  was deleted successfully!" });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
    subpositionItem.id ?? 0,
  );

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectSubpositions, currentProject.id, positionId] });
  }, [queryClient, currentProject.id, positionId]);

  return (
    <div className={classNames(ConfigurationSubpositionStyles.employeeTooltipContainer, GlobalStyles.flex, GlobalStyles.flexDirectionColumn)}>
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.gap)}
        onClick={(event) => {
          event.preventDefault();
          onOpenPopup(ProjectsPagePopups.updateProjectSubPosition, popupHandler);
        }}
      >
        <div className={classNames(GlobalStyles.centerVertical)}>
          <img className={classNames(ConfigurationSubpositionStyles.employeeTooltipIconImg)} src={settingGearIcon} alt="gear" />
        </div>
        <div className={classNames(GlobalStyles.centerVertical, ConfigurationSubpositionStyles.toolTipTextBase)}>{"Update sub-position"}</div>
      </div>
      <Tooltip title={canBeDeleted ? "" : "This sub-position cannot be deleted"} placement="right" arrow>
        <div
          className={classNames(GlobalStyles.flex, GlobalStyles.gap, !canBeDeleted && ConfigurationSubpositionStyles.toolTipNotClickableText)}
          onClick={(event) => {
            if (canBeDeleted) {
              event.preventDefault();
              onOpenUtilPopup();
            }
          }}
        >
          <div className={classNames(GlobalStyles.centerVertical)}>
            <img
              className={classNames(ConfigurationSubpositionStyles.employeeTooltipIconImg)}
              src={canBeDeleted ? deleteIcon : DeleteMmrItemGrayIcon}
              alt="deleteIcon"
            />
          </div>
          <div
            className={classNames(
              GlobalStyles.centerVertical,
              ConfigurationSubpositionStyles.toolTipTextBase,
              canBeDeleted ? ConfigurationSubpositionStyles.toolTipTextRed : ConfigurationSubpositionStyles.toolTipItemNotClickable,
              !canBeDeleted && ConfigurationSubpositionStyles.toolTipNotClickableText,
            )}
          >
            {"Delete sub-position"}
          </div>
        </div>
      </Tooltip>
      {isUtilPopupOpen && (
        <DeletePopup
          isOpen={isUtilPopupOpen}
          closeFn={() => {
            onCloseUtilPopup();
          }}
          onDeleteFn={() => {
            deleteProjectSubpositionMutation.mutate();
            onOpenLoaderPopup();
          }}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            onCloseUtilPopup();
            invalidateQueries();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
      {popupHandler.get(ProjectsPagePopups.updateProjectSubPosition)!.isOpen && (
        <ConfigurationSubpositionsPopup
          mode={configurationsPopupType.edit}
          closeFn={() => onClosePopup(ProjectsPagePopups.updateProjectSubPosition, popupHandler)}
          projectId={currentProject.id}
          isOpen={popupHandler.get(ProjectsPagePopups.updateProjectSubPosition)!.isOpen}
          secondaryHeaderText={currentProject.name}
          headerText={popupHeaders.get(ProjectsPagePopups.updateProjectSubPosition)}
          positionId={positionId}
          subpositionItem={subpositionItem}
        />
      )}
    </div>
  );
};
export default ConfigurationSubpositionTooltip;
