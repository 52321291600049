import { AxiosRequestConfig } from "axios";

import { QueryModelWithCount } from "../../../types/apicallstypes/queryCommons.ts";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { FrontendMmrStatusQueryEnum } from "../../../types/projects/ProjectEmployeesTypes.ts";

const API = `${EPCM_API_URLS.projects}`;

export const getProjectEmployeesCount = async (id: number, mmrStatus: FrontendMmrStatusQueryEnum): Promise<QueryModelWithCount> => {
  const params = new URLSearchParams();
  params.set("mmrStatus", `${mmrStatus}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/employees/count`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);
  return { count: response.data.count };
};
