import { EPCM_API_URLS, epcmAxiosInstance } from "../../../../config.ts";
import { ApiRequestBodyTimesheetCreate } from "epcm-common/dist/Types/TimesheetTypes";
import { AxiosRequestConfig } from "axios";

const API = `${EPCM_API_URLS.projects}`;

export const createTimesheet = async (projectId: number, timesheetGroupId: number, newTimesheetData: ApiRequestBodyTimesheetCreate) => {
  const config: AxiosRequestConfig = {
    url: `${API}/${projectId}/timesheet-groups/${timesheetGroupId}/timesheets`,
    method: "POST",
    data: newTimesheetData,
  } as AxiosRequestConfig;

  return epcmAxiosInstance(config);
};
