// GlobalNotificationContext.tsx
import { createContext, useContext } from "react";
import { EpcmSnackbarMessage } from "../../types/EpcmToastProps.ts";

interface NotificationContextProps {
  addNotification: (message: EpcmSnackbarMessage) => void;
  updateNotification: (message: EpcmSnackbarMessage) => void;
  removeNotification: (fileId: string) => void;
  notifications: EpcmSnackbarMessage[];
  setNotifications: (notifications: EpcmSnackbarMessage[]) => void;
}

export const GlobalNotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const useNotificationContext = () => {
  const context = useContext(GlobalNotificationContext);
  if (!context) {
    throw new Error("useNotificationContext must be used within a NotificationProvider");
  }
  return context;
};
