import { FC, useEffect, useMemo } from "react";
import classNames from "classnames";
import GroupEmployeeListItemStyles from "./GroupEmployeeListItemStyles.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
//import TimesheetWrenchIcon from "../../../../../assets/images/timesheet-wrench-icon.svg";
//import TimesheetLocationIcon from "../../../../../assets/images/timesheet-location-icon.svg";

import { useEpcmApiProjectEmployee } from "../../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { FrontendProjectEmployeeBasicType } from "../../../../../types/projects/ProjectEmployeesTypes.ts";

import EmployeeNameSkeletonLoader from "../loaders/EmployeeNameSkeletonLoader.tsx";
import CodeSkeletonLoader from "../loaders/CodeSkeletonLoader.tsx";
import { DefaultAvatar } from "../../../../../ui/defaultavatar/DefaultAvatar.tsx";

interface GroupEmployeeListItemProps {
  id: number;
  projectId: number;
}

const GroupEmployeeListItem: FC<GroupEmployeeListItemProps> = ({ id, projectId }) => {
  const { getSingleProjectEmployee } = useEpcmApiProjectEmployee();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  const singleProjectEmployeeQuery = useQuery({
    queryKey: [QueryNames.ProjectEmployee, projectId, id],
    queryFn: () => getSingleProjectEmployee(projectId, id, DetailLevel.BASIC),
    enabled: isAuthorized,
    select: (data) => data as FrontendProjectEmployeeBasicType,
  });

  const singleProjectEmployeeData = useMemo(() => {
    return singleProjectEmployeeQuery.data ? singleProjectEmployeeQuery.data : null;
  }, [singleProjectEmployeeQuery]);

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.ProjectEmployee, projectId, id] })
        .then(() => console.log(`In project with id ${projectId}, ${QueryNames.ProjectEmployee} query canceled`));
    };
  }, [queryClient, projectId, id]);

  return (
    <div
      key={id}
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.flex1,
        GlobalStyles.centerVertical,
        GlobalStyles.gap2,
        GroupEmployeeListItemStyles.container,
        GroupEmployeeListItemStyles.mainContainer,
      )}
    >
      <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap075)}>
        <DefaultAvatar avatarImgSizeInEm={1.4} avatarSizeInEm={2.3} />

        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical, GlobalStyles.gap025, GroupEmployeeListItemStyles.bottomPadding)}>
          {singleProjectEmployeeData ? (
            <div className={classNames(GroupEmployeeListItemStyles.infoText)}>{singleProjectEmployeeData.name}</div>
          ) : (
            <EmployeeNameSkeletonLoader />
          )}
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GroupEmployeeListItemStyles.infoSubText, GlobalStyles.gap05)}>
            <div className={classNames(GroupEmployeeListItemStyles.idTextDiv)}>CODE</div>
            {singleProjectEmployeeData ? (
              <div className={classNames(GroupEmployeeListItemStyles.codeText)}>{singleProjectEmployeeData.code}</div>
            ) : (
              <div>
                <CodeSkeletonLoader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GroupEmployeeListItem;
