import MmrVersionActionSectionStyles from "./MmrVersionActionSection.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import MmrVersionsInfoBox from "./mmrversionactionviewelements/mmrversionsinfobox/MmrVersionsInfoBox.tsx";
import ProjectEmployeeDetailsPopupStyles from "../ProjectEmployeeDetailsPopup.module.css";
import MmrVersionAccordionGrouped from "./mmrversionactionviewelements/mmrversionsinfocontent/mmrversionaccordiongrouped/MmrVersionAccordionGrouped.tsx";
import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { ProjectEmployeeDetailsPopupTypeEnum } from "../../../../../types/projects/ProjectEmployeesTypes.ts";
import { MmrCreateNewVersion } from "./mmrversionactionviewelements/mmrcreatenewversion/MmrCreateNewVersion.tsx";
import CreateMmrDraftVersion from "./mmrversionactioncreateelements/createmmrdraftversion/CreateMmrDraftVersion.tsx";
import { FrontendMmrDateStatusEnum } from "../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";

interface MmrVersionActionSectionProps {
  projectId: number;
  employeeId: number;
  popupType: ProjectEmployeeDetailsPopupTypeEnum;
  setPopupType: Dispatch<SetStateAction<ProjectEmployeeDetailsPopupTypeEnum>>;
  selectedMmrId: number | null;
  mmrReference: string | null;
  setSelectedMmrId: Dispatch<SetStateAction<number | null>>;
  setIsCloned: Dispatch<SetStateAction<boolean>>;
  selectedMmrIdType: FrontendMmrDateStatusEnum | null;
  setSelectedMmrIdType: Dispatch<SetStateAction<FrontendMmrDateStatusEnum | null>>;
}

const MmrVersionActionSection: FC<MmrVersionActionSectionProps> = ({
  projectId,
  employeeId,
  selectedMmrId,
  mmrReference,
  setPopupType,
  setSelectedMmrId,
  setIsCloned,
  popupType,
  selectedMmrIdType,
  setSelectedMmrIdType,
}) => {
  const isViewOnlyMode = useMemo(() => popupType === ProjectEmployeeDetailsPopupTypeEnum.view, [popupType]);
  const isCreateMode = useMemo(() => popupType === ProjectEmployeeDetailsPopupTypeEnum.create, [popupType]);
  const isEditMode = useMemo(() => popupType === ProjectEmployeeDetailsPopupTypeEnum.edit, [popupType]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1, GlobalStyles.gap05, GlobalStyles.overflowHiddenFullHeight)}>
      <div
        className={classNames(
          MmrVersionActionSectionStyles.mmrVersionsContainer,
          GlobalStyles.flex,
          GlobalStyles.flex03,
          GlobalStyles.centerVertical,
          GlobalStyles.gap075,
        )}
      >
        {<MmrVersionsInfoBox mmrFileReference={mmrReference} />}
        <div className={classNames(ProjectEmployeeDetailsPopupStyles.testCont, GlobalStyles.flex, GlobalStyles.flex1)}>
          {isViewOnlyMode ? (
            <MmrVersionAccordionGrouped
              projectId={projectId}
              employeeId={employeeId}
              setPopupType={setPopupType}
              selectedMmrId={selectedMmrId}
              setSelectedMmrId={setSelectedMmrId}
              setIsCloned={setIsCloned}
              selectedMmrIdType={selectedMmrIdType}
              setSelectedMmrIdType={setSelectedMmrIdType}
            />
          ) : (
            (isCreateMode || isEditMode) && <CreateMmrDraftVersion />
          )}
        </div>
        {isViewOnlyMode && <MmrCreateNewVersion setPopupType={setPopupType} />}
      </div>
    </div>
  );
};
export default MmrVersionActionSection;
