import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import ProjectEmployeeListStyles from "./ProjectEmployeeList.module.css";
import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { ProjectEmployeeListItem } from "./projectemployeelistitem/ProjectEmployeeListItem.tsx";
import { FrontendProjectEmployeeToMmrType } from "../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";

interface ProjectEmployeeListProps {
  projectEmployeeList: FrontendProjectEmployeeToMmrType[];
}

export const ProjectEmployeeList: FC<PropsWithChildren<ProjectEmployeeListProps>> = ({ projectEmployeeList, children }) => {
  const [parent] = useAutoAnimate();

  return projectEmployeeList.length > 0 ? (
    <div
      ref={parent}
      className={classNames(
        ProjectEmployeeListStyles.projectEmployeeListContainer,
        GlobalStyles.flex,
        GlobalStyles.flexDirectionColumn,
        GlobalStyles.gap15,
      )}
    >
      {projectEmployeeList.map((projectEmployeeItem) => (
        <ProjectEmployeeListItem key={projectEmployeeItem.code} projectEmployeeItem={projectEmployeeItem} />
      ))}
      {children}
    </div>
  ) : (
    <div className={classNames(GlobalStyles.emptyListMsg, GlobalStyles.flex)}>
      <div
        id={"test-div"}
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionRow, GlobalStyles.gap15)}
        style={{ backgroundColor: "red" }}
      ></div>
      <div className={classNames(GlobalStyles.flex1)} />
      <div>{"No Employees"}</div>
      <div className={classNames(GlobalStyles.flex1)} />
    </div>
  );
};
