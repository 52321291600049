import classNames from "classnames";
import EditProjectInfoStyles from "./EditProjectInfo.module.css";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import { Dispatch, FC, SetStateAction } from "react";

import { FrontendProjectDetailedType, FrontendProjectStatus } from "../../../../../types/apicallstypes/ProjectsApiTypes.ts";
import { NumericFormat } from "react-number-format";
import { formatDate } from "../../../../../utils/DateManipulation.ts";
import { FrontendProjectFieldsEnum, ProjectManagerInputFieldsEnum } from "../../../../../types/projects/ProjectsTypes.ts";
import { capitalizeFirstLetter } from "../../../../../utils/StringManipulation.ts";
import { FrontendUserLimitedType } from "../../../../../types/apicallstypes/UsersApiTypes.ts";
import SelectUserInput from "../../selectuserinput/SelectUserInput.tsx";

interface EditProjectInfoProps {
  projectInfoObject: FrontendProjectDetailedType;
  setProjectInfoObject: Dispatch<SetStateAction<FrontendProjectDetailedType | undefined>>;
}

const EditProjectInfo: FC<EditProjectInfoProps> = ({ projectInfoObject, setProjectInfoObject }) => {
  const handleProjectStatusChange = (status: FrontendProjectStatus) => {
    setProjectInfoObject({ ...projectInfoObject, status: status });
  };

  return (
    <div
      className={classNames(
        EditProjectInfoStyles.columnWrapper,
        GlobalStyles.flex,
        GlobalStyles.centerHorizontal,
        GlobalStyles.gap,
        GlobalStyles.overflowAutoFullHeight,
      )}
    >
      <div
        className={classNames(
          EditProjectInfoStyles.columnContent,
          EditProjectInfoStyles.border,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_CODE}</div>
          <NumericFormat
            value={projectInfoObject.code || 0}
            className={classNames(EditProjectInfoStyles.inputContainer)}
            allowNegative={false}
            onValueChange={(values) => {
              const { floatValue } = values;
              if (floatValue) {
                setProjectInfoObject({ ...projectInfoObject, code: floatValue });
              }
            }}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_NAME}</div>
          <input
            type="text"
            className={classNames(EditProjectInfoStyles.inputContainer)}
            value={projectInfoObject.name || ""}
            onChange={(e) => setProjectInfoObject({ ...projectInfoObject, name: e.target.value })}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_STATUS}</div>
          <div className={classNames(EditProjectInfoStyles.radioBoxContainer, GlobalStyles.flex, GlobalStyles.gap05, GlobalStyles.centerHorizontal)}>
            <div
              className={classNames(
                projectInfoObject.status === FrontendProjectStatus.INACTIVE
                  ? EditProjectInfoStyles.radioBoxItemSelected
                  : EditProjectInfoStyles.radioBox,
                GlobalStyles.flex1,
                GlobalStyles.centerHorizontal,
                GlobalStyles.centerVertical,
                GlobalStyles.elementWithCursor,
              )}
              onClick={() => handleProjectStatusChange(FrontendProjectStatus.INACTIVE)}
            >
              {capitalizeFirstLetter(FrontendProjectStatus.INACTIVE)}
            </div>
            <div
              className={classNames(
                projectInfoObject.status === FrontendProjectStatus.ACTIVE
                  ? EditProjectInfoStyles.radioBoxItemSelected
                  : EditProjectInfoStyles.radioBox,
                GlobalStyles.flex1,
                GlobalStyles.centerHorizontal,
                GlobalStyles.centerVertical,
                GlobalStyles.elementWithCursor,
              )}
              onClick={() => handleProjectStatusChange(FrontendProjectStatus.ACTIVE)}
            >
              {capitalizeFirstLetter(FrontendProjectStatus.ACTIVE)}
            </div>
            <div
              className={classNames(
                projectInfoObject.status === FrontendProjectStatus.CANCELLED
                  ? EditProjectInfoStyles.radioBoxItemSelected
                  : EditProjectInfoStyles.radioBox,
                GlobalStyles.flex1,
                GlobalStyles.centerHorizontal,
                GlobalStyles.centerVertical,
                GlobalStyles.elementWithCursor,
              )}
              onClick={() => handleProjectStatusChange(FrontendProjectStatus.CANCELLED)}
            >
              {capitalizeFirstLetter(FrontendProjectStatus.CANCELLED)}
            </div>
            <div
              className={classNames(
                projectInfoObject.status === FrontendProjectStatus.COMPLETED
                  ? EditProjectInfoStyles.radioBoxItemSelected
                  : EditProjectInfoStyles.radioBox,
                GlobalStyles.flex1,
                GlobalStyles.centerHorizontal,
                GlobalStyles.centerVertical,
                GlobalStyles.elementWithCursor,
              )}
              onClick={() => handleProjectStatusChange(FrontendProjectStatus.COMPLETED)}
            >
              {capitalizeFirstLetter(FrontendProjectStatus.COMPLETED)}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          EditProjectInfoStyles.columnContent,
          EditProjectInfoStyles.border,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.AGREEMENT_NUMBER}</div>
          <input
            type="text"
            className={classNames(EditProjectInfoStyles.inputContainer)}
            value={projectInfoObject.agreementNumber || ""}
            onChange={(e) => setProjectInfoObject({ ...projectInfoObject, agreementNumber: e.target.value })}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.DATE_OF_SIGN}</div>
          <input
            type="text"
            className={classNames(EditProjectInfoStyles.disabledInput)}
            value={formatDate(projectInfoObject.dateOfSign) || ""}
            disabled
            //onChange={(e) => setProjectInfoObject({ ...projectInfoObject, mmrPrefix: e.target.value })}
          />
        </div>

        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.DURATION}</div>
          <NumericFormat
            type="text"
            className={classNames(EditProjectInfoStyles.inputContainer)}
            value={projectInfoObject.duration || 0}
            allowNegative={false}
            onValueChange={(values) => setProjectInfoObject({ ...projectInfoObject, duration: values.floatValue || 0 })}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_LOCATION}</div>
          <input
            type="text"
            className={classNames(EditProjectInfoStyles.inputContainer)}
            value={projectInfoObject.projectLocation || ""}
            onChange={(e) => setProjectInfoObject({ ...projectInfoObject, projectLocation: e.target.value })}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.MMR_PREFIX}</div>
          <input
            type="text"
            className={classNames(EditProjectInfoStyles.disabledInput)}
            value={projectInfoObject.mmrPrefix || ""}
            disabled
            onChange={(e) => setProjectInfoObject({ ...projectInfoObject, mmrPrefix: e.target.value })}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.OVERTIME_PREFIX}</div>
          <input
            type="text"
            className={classNames(EditProjectInfoStyles.disabledInput)}
            value={projectInfoObject.overtimePrefix || ""}
            disabled
            onChange={(e) => setProjectInfoObject({ ...projectInfoObject, mmrPrefix: e.target.value })}
          />
        </div>
      </div>
      <div
        className={classNames(
          EditProjectInfoStyles.columnContent,
          GlobalStyles.flex,
          GlobalStyles.flex1,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
          <div className={classNames(EditProjectInfoStyles.managerLabel)}>ARCHIRODON</div>
          <div
            className={classNames(EditProjectInfoStyles.managerInputsPadding, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap)}
          >
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
              <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_MANAGER}</div>
              <SelectUserInput
                projectManagerInputEntity={projectInfoObject?.projectManager ?? undefined}
                setProjectManagerInputEntity={(value) =>
                  setProjectInfoObject({
                    ...projectInfoObject,
                    projectManager: value as FrontendUserLimitedType | null,
                  })
                }
                projectManagerInputType={ProjectManagerInputFieldsEnum.PROJECT_MANAGER}
                overrideInputContainerStyles={
                  projectInfoObject?.projectManager ? EditProjectInfoStyles.inputContainer : EditProjectInfoStyles.inputContainerEmpty
                }
              />
            </div>
            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
              <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_COST_CONTROL_ENGINEER}</div>
              <SelectUserInput
                projectManagerInputEntity={projectInfoObject?.projectCostControlEngineer ?? undefined}
                setProjectManagerInputEntity={(value) =>
                  setProjectInfoObject({
                    ...projectInfoObject,
                    projectCostControlEngineer: value as FrontendUserLimitedType | null,
                  })
                }
                projectManagerInputType={ProjectManagerInputFieldsEnum.PROJECT_COST_CONTROL_ENGINEER}
                overrideInputContainerStyles={
                  projectInfoObject?.projectCostControlEngineer ? EditProjectInfoStyles.inputContainer : EditProjectInfoStyles.inputContainerEmpty
                }
              />
            </div>

            <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
              <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_CONTROLS_MANAGER}</div>
              <SelectUserInput
                projectManagerInputEntity={projectInfoObject?.projectControlsManager ?? undefined}
                setProjectManagerInputEntity={(value) =>
                  setProjectInfoObject({
                    ...projectInfoObject,
                    projectControlsManager: value as FrontendUserLimitedType | null,
                  })
                }
                projectManagerInputType={ProjectManagerInputFieldsEnum.PROJECT_CONTROLS_MANAGER}
                overrideInputContainerStyles={
                  projectInfoObject?.projectControlsManager ? EditProjectInfoStyles.inputContainer : EditProjectInfoStyles.inputContainerEmpty
                }
              />
            </div>
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap05)}>
            <div className={classNames(EditProjectInfoStyles.managerLabel)}>CLIENT</div>
            <div
              className={classNames(
                EditProjectInfoStyles.managerInputsPadding,
                GlobalStyles.flex,
                GlobalStyles.flexDirectionColumn,
                GlobalStyles.gap,
              )}
            >
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_CLIENT_SENIOR_MANAGER}</div>
                <input
                  type="text"
                  className={classNames(EditProjectInfoStyles.inputContainer)}
                  value={projectInfoObject?.projectClientSeniorManager || ""}
                  onChange={(e) =>
                    setProjectInfoObject({
                      ...projectInfoObject,
                      projectClientSeniorManager: e.target.value,
                    })
                  }
                  placeholder={"Senior Project Manager"}
                />
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_COST_CONTROL_ENGINEER}</div>
                <input
                  type="text"
                  className={classNames(EditProjectInfoStyles.inputContainer)}
                  value={projectInfoObject?.projectClientCostControlEngineer || ""}
                  onChange={(e) =>
                    setProjectInfoObject({
                      ...projectInfoObject,
                      projectClientCostControlEngineer: e.target.value,
                    })
                  }
                  placeholder={"Cost Control Engineer"}
                />
              </div>
              <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
                <div className={classNames(EditProjectInfoStyles.labelTitle)}>{FrontendProjectFieldsEnum.PROJECT_CONTROLS_MANAGER}</div>
                <input
                  type="text"
                  className={classNames(EditProjectInfoStyles.inputContainer)}
                  value={projectInfoObject?.projectClientControlsManager || ""}
                  onChange={(e) =>
                    setProjectInfoObject({
                      ...projectInfoObject,
                      projectClientControlsManager: e.target.value,
                    })
                  }
                  placeholder={"Project Controls Manager"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditProjectInfo;
