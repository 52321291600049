import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import TimesheetFormCtrRecordListStyles from "./TimesheetFormCtrRecordList.module.css";
import {
  FrontendGroupsTimesheetModeEnum,
  FrontendTimesheetFormHeaderDateType,
  FrontendTimesheetProjectEmployeeActiveMMRType,
  FrontendTimesheetRecordType,
} from "../../../../../../../../types/apicallstypes/ProjectTimesheetsApiTypes.ts";
import GlobalStyles from "../../../../../../../../assets/css/GlobalStyles.module.css";
import { FrontendCtrLimitedType, FrontendMmrCtrsBody } from "../../../../../../../../types/apicallstypes/CtrTypes.ts";

import { useParams, useSearchParams } from "react-router-dom";
import { useImpersonationStore } from "../../../../../../../../store/use-impersonation-store.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryModelWithCountAndData, QueryNames } from "../../../../../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import TimesheetFormCtrRecord from "../timesheetformctrrecord/TimesheetFormCtrRecord.tsx";
import { FrontendMmrCalendarDayRecords } from "../../../../../../../../types/projects/ProjectTimesheetsTypes.ts";
import { Skeleton } from "@mui/material";

interface TimesheetFormCtrRecordProps {
  MMRItem: FrontendTimesheetProjectEmployeeActiveMMRType;
  mmrCtrRecords: { [mmrId: number]: FrontendMmrCtrsBody[] };
  setMmrCtrRecords: Dispatch<SetStateAction<{ [mmrId: number]: FrontendMmrCtrsBody[] }>>;
  groupTimesheetDates: FrontendTimesheetFormHeaderDateType[];
  onAddCtr: (mmrId: number) => void; // Callback for adding a CTR
  applicableDates: FrontendMmrCalendarDayRecords;
  onWorkHoursChange: (ctrId: number, calendarDayId: number, workedHours: number, newHours: number) => void;
  isEditMode: boolean;
  timesheetGroupId: number;
  timesheetId: number;
  timesheetEmployeeId: number;
  projectEmployeeId: number;
  hoursPerDayRecordData: {
    [calendarDayId: number]: FrontendTimesheetRecordType;
  };
}

const TimesheetFormCtrRecordList: FC<TimesheetFormCtrRecordProps> = ({
  MMRItem,
  mmrCtrRecords,
  setMmrCtrRecords,
  groupTimesheetDates,
  onAddCtr,
  applicableDates,
  onWorkHoursChange,
  isEditMode,
  timesheetGroupId,
  timesheetId,
  timesheetEmployeeId,
  projectEmployeeId,
  hoursPerDayRecordData,
}) => {
  const { projectId } = useParams();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();
  const { getGroupTimesheetActiveMmrCtrData } = useEpcmApiProjectsTimesheetsGroups();

  const [isDataFromServer, setIsDataFromServer] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const displayModeSearchQuery = searchParams.get("mode") || FrontendGroupsTimesheetModeEnum.VIEW;
  const statusSearchQuery = searchParams.get("status") || "";

  const mmrCtrCodesQuery = useQuery({
    queryKey: [
      QueryNames.ProjectTimesheetGroupActiveMmrCtrCodes,
      parseInt(projectId!),
      timesheetGroupId,
      timesheetId,
      timesheetEmployeeId,
      projectEmployeeId,
      MMRItem.mmrId,
      displayModeSearchQuery,
      statusSearchQuery,
    ],
    queryFn: () =>
      getGroupTimesheetActiveMmrCtrData(parseInt(projectId!), timesheetGroupId, timesheetId, timesheetEmployeeId, projectEmployeeId, MMRItem.mmrId),
    enabled: isAuthorized,
    select: (data) => data as QueryModelWithCountAndData<FrontendCtrLimitedType>,
  });

  const mmrCtrCodesData = useMemo(() => {
    return mmrCtrCodesQuery.data?.data;
  }, [mmrCtrCodesQuery.data]);

  useEffect(() => {
    if (mmrCtrCodesData && !isDataFromServer) {
      const ctrsArray = mmrCtrCodesData.map((ctr) => {
        // Create initial calendar day hours array for each day in groupTimesheetDates
        const initialCalendarDayHours = groupTimesheetDates.map((date) => ({
          calendarDayId: date.id,
          workedHours: 0, // Initialize with 0 hours
        }));

        return {
          ctrId: ctr.id,
          calendarDayHours: initialCalendarDayHours, // Use the initialized array
        };
      });

      setMmrCtrRecords((prevMmrCtrRecords) => ({
        ...prevMmrCtrRecords,
        [MMRItem.mmrId]: ctrsArray,
      }));
      setIsDataFromServer(true);
    }
  }, [mmrCtrCodesData, setMmrCtrRecords, MMRItem.mmrId, groupTimesheetDates, isDataFromServer, mmrCtrRecords]);

  //When the mode changes reset the isDataFromServer

  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({
          queryKey: [
            QueryNames.ProjectTimesheetGroupActiveMmrCtrCodes,
            parseInt(projectId!),
            timesheetGroupId,
            timesheetId,
            timesheetEmployeeId,
            projectEmployeeId,
            MMRItem.mmrId,
            displayModeSearchQuery,
            statusSearchQuery,
          ],
        })
        .then(() => {
          console.log(`In timesheets-groups component, ${QueryNames.ProjectTimesheetGroupActiveMmrCtrCodes} query cancelled`);
        });
    };
  }, [
    queryClient,
    projectId,
    timesheetGroupId,
    timesheetId,
    timesheetEmployeeId,
    projectEmployeeId,
    MMRItem.mmrId,
    displayModeSearchQuery,
    statusSearchQuery,
  ]);

  return (
    <React.Fragment key={MMRItem.mmrId + displayModeSearchQuery + statusSearchQuery}>
      {mmrCtrRecords[MMRItem.mmrId] && mmrCtrRecords[MMRItem.mmrId].length > 0 ? (
        mmrCtrRecords[MMRItem.mmrId].map((ctr, index) => (
          <TimesheetFormCtrRecord
            key={`${ctr.ctrId} + ${index}`}
            ctrId={ctr.ctrId}
            ctrsArrayLength={mmrCtrRecords[MMRItem.mmrId] ? mmrCtrRecords[MMRItem.mmrId].length : 0}
            mmrCtrRecords={mmrCtrRecords}
            setMmrCtrRecords={setMmrCtrRecords}
            onAddCtr={onAddCtr}
            onWorkHoursChange={onWorkHoursChange}
            MMRItem={MMRItem}
            applicableDates={applicableDates}
            groupTimesheetDates={groupTimesheetDates}
            isEditMode={isEditMode}
            itemIndex={index}
            timesheetGroupId={timesheetGroupId}
            timesheetId={timesheetId}
            timesheetEmployeeId={timesheetEmployeeId}
            projectEmployeeId={projectEmployeeId}
            hoursPerDayRecordData={hoursPerDayRecordData}
          />
        ))
      ) : mmrCtrCodesQuery.isLoading ? (
        <>
          <div className={classNames(TimesheetFormCtrRecordListStyles.loaderDiv)}>
            <Skeleton
              width={"100%"}
              height={30}
              variant={"rounded"}
              className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, TimesheetFormCtrRecordListStyles.addCtrTextDiv)}
            ></Skeleton>
          </div>

          {groupTimesheetDates.map((eachDayOfTheWeek, index) => {
            return (
              <div key={eachDayOfTheWeek.id + index} className={classNames(TimesheetFormCtrRecordListStyles.loaderDiv)}>
                <Skeleton
                  width={"100%"}
                  height={30}
                  variant={"rounded"}
                  className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, TimesheetFormCtrRecordListStyles.addCtrTextDiv)}
                ></Skeleton>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div
            className={classNames(
              TimesheetFormCtrRecordListStyles.addCtrTextDiv,
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.centerVertical,
              isEditMode && GlobalStyles.elementWithCursor,
            )}
            onClick={() => isEditMode && onAddCtr(MMRItem.mmrId)}
          >
            {isEditMode ? "+" : ""}
          </div>
          {groupTimesheetDates.map((eachDayOfTheWeek, index) => {
            return <div key={eachDayOfTheWeek.id + index}></div>;
          })}
        </>
      )}
    </React.Fragment>
  );
};

export default TimesheetFormCtrRecordList;
