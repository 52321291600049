import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from "react";
import ProjectEmployeeMmrInformationActionBarStyles from "./MmrInformationActionBar.module.css";
import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
// import ProjectEmployeeAddIcon from "../../../../../../../assets/images/project_employee_add_icon.svg";
import ProjectEmployeeCloneIcon from "../../../../../../../assets/images/project_employee_clone_icon.svg";
import ProjectEmployeeDownloadIcon from "../../../../../../../assets/images/project_employee_download_icon.svg";
import DownloadMmrGreenIcon from "../../../../../../../assets/images/download-mmr-green-icon.svg";
// import AddFilesMmrHoverIcon from "../../../../../../../assets/images/add_files_mmr_hover_icon.svg";
import CloneMmrHoverIcon from "../../../../../../../assets/images/clone_mmr_hover_icon.svg";
import { Fade, Tooltip } from "@mui/material";
import { MmrInformationActionButtonEnum } from "../../../../../../../types/projects/MmrTypes.ts";
import { FrontendMmrStatusEnum } from "../../../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import { ProjectEmployeeDetailsPopupTypeEnum } from "../../../../../../../types/projects/ProjectEmployeesTypes.ts";
import { useEpcmApiProjectEmployee } from "../../../../../../../apicalls/projects/projectemployee/useEpcmApiProjectEmployee.ts";
import { useNotificationContext } from "../../../../../../../ui/globalnotificationcontext/GlobalNotificationContext.ts";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { useResponseAlertPopupStateType } from "../../../../../../../utils/use-response-alert-popup-state.ts";
import useEpcmProcessFileEvents from "../../../../../../../utils/useProcessFileEvents.ts";
import { FrontendFileAction } from "../../../../../../../types/projects/FileTypes.ts";
import { ApiResponseTypeEnum } from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { ResponseAlertPopup } from "../../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";

interface ProjectEmployeeMmrInformationActionBarProps {
  mmrVersion: number | undefined;
  mmrStatus: FrontendMmrStatusEnum | null;
  mmrId: number | undefined;
  setPopupType: Dispatch<SetStateAction<ProjectEmployeeDetailsPopupTypeEnum>>;
  setIsCloned: Dispatch<SetStateAction<boolean>>;
  employeeId: number;
  projectId: number;
}

export const MmrInformationActionBar: FC<ProjectEmployeeMmrInformationActionBarProps> = ({
  mmrVersion,
  mmrStatus,
  setIsCloned,
  setPopupType,
  projectId,
  employeeId,
  mmrId,
}) => {
  const [actionBtnHovered, setActionBtnHovered] = useState<MmrInformationActionButtonEnum>(MmrInformationActionButtonEnum.NONE);
  const { addNotification, updateNotification } = useNotificationContext();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { getMmrFile } = useEpcmApiProjectEmployee();

  const { processFileEvents: processMmrFileEventsExport } = useEpcmProcessFileEvents(
    FrontendFileAction.EXPORT,
    projectId!.toString(),
    isAuthorized,
    addNotification,
    updateNotification,
    onOpenResponseAlertPopup,
    setResponseType,
    setResponseObject,
  );

  const startFileEventsListener = async (fileId: string) => {
    await processMmrFileEventsExport(fileId);
  };

  const exportMmrToFile = useCallback(async () => {
    getMmrFile(projectId!, employeeId, mmrId!)
      .then((response) => {
        startFileEventsListener(response.fileId);
      })
      .catch((error) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error);
        onOpenResponseAlertPopup();
      });
  }, [projectId, employeeId, mmrId]);

  const isCloneHovered = useMemo(() => actionBtnHovered === MmrInformationActionButtonEnum.CLONE, [actionBtnHovered]);
  // const isAddFilesHovered = useMemo(() => actionBtnHovered === MmrInformationActionButtonEnum.ADD_FILES, [actionBtnHovered]);
  const isDownloadHovered = useMemo(() => actionBtnHovered === MmrInformationActionButtonEnum.DOWNLOAD, [actionBtnHovered]);

  return (
    <div className={classNames(ProjectEmployeeMmrInformationActionBarStyles.mainContainer, GlobalStyles.flex)}>
      <div className={classNames(ProjectEmployeeMmrInformationActionBarStyles.container, GlobalStyles.flex, GlobalStyles.flex2)}>
        <div
          className={classNames(
            GlobalStyles.flex,
            GlobalStyles.flex1,
            GlobalStyles.flexDirectionColumn,
            GlobalStyles.gap075,
            ProjectEmployeeMmrInformationActionBarStyles.textContainer,
          )}
        >
          <div className={classNames(ProjectEmployeeMmrInformationActionBarStyles.mainText, GlobalStyles.flex, GlobalStyles.centerHorizontal)}>
            {mmrVersion ? `MMR Version : #${mmrVersion}` : "No Active MMR Version"}
          </div>
          <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
            <div
              className={classNames(
                mmrStatus === FrontendMmrStatusEnum.APPROVED &&
                  classNames(ProjectEmployeeMmrInformationActionBarStyles.active, ProjectEmployeeMmrInformationActionBarStyles.secondaryText),
                mmrStatus === FrontendMmrStatusEnum.PENDING &&
                  classNames(ProjectEmployeeMmrInformationActionBarStyles.pending, ProjectEmployeeMmrInformationActionBarStyles.secondaryText),
                mmrStatus === FrontendMmrStatusEnum.REJECTED &&
                  classNames(ProjectEmployeeMmrInformationActionBarStyles.rejected, ProjectEmployeeMmrInformationActionBarStyles.secondaryText),
              )}
            >
              {mmrVersion
                ? mmrStatus === FrontendMmrStatusEnum.APPROVED
                  ? "Approved Version"
                  : mmrStatus === FrontendMmrStatusEnum.PENDING
                    ? "Pending Version"
                    : "Rejected Version"
                : ""}
            </div>
          </div>
        </div>

        {mmrVersion && (
          <div
            className={classNames(
              GlobalStyles.flex,
              GlobalStyles.centerHorizontal,
              GlobalStyles.gap075,
              ProjectEmployeeMmrInformationActionBarStyles.actionButtonsContainer,
              GlobalStyles.elementWithCursor,
            )}
          >
            <Tooltip title={"Clone MMR"} placement="top" arrow TransitionComponent={Fade}>
              <div
                className={classNames(ProjectEmployeeMmrInformationActionBarStyles.actionIcon, GlobalStyles.elementWithCursor)}
                onMouseEnter={() => setActionBtnHovered(MmrInformationActionButtonEnum.CLONE)}
                onMouseLeave={() => setActionBtnHovered(MmrInformationActionButtonEnum.NONE)}
                onClick={() => {
                  setIsCloned(true);
                  setPopupType(ProjectEmployeeDetailsPopupTypeEnum.create);
                }}
              >
                <img
                  src={isCloneHovered ? CloneMmrHoverIcon : ProjectEmployeeCloneIcon}
                  alt="clone"
                  className={classNames(ProjectEmployeeMmrInformationActionBarStyles.actionIcon)}
                />
              </div>
            </Tooltip>
            {/*<Tooltip title={"Not yet implemented"} placement="top" arrow TransitionComponent={Fade}>*/}
            {/*  <div*/}
            {/*    className={classNames(ProjectEmployeeMmrInformationActionBarStyles.actionIcon, ProjectEmployeeMmrInformationActionBarStyles.disabled)}*/}
            {/*    // onMouseEnter={() => setActionBtnHovered(MmrInformationActionButtonEnum.ADD_FILES)}*/}
            {/*    // onMouseLeave={() => setActionBtnHovered(MmrInformationActionButtonEnum.NONE)}*/}
            {/*  >*/}
            {/*    <img*/}
            {/*      // src={isAddFilesHovered ? AddFilesMmrHoverIcon : ProjectEmployeeAddIcon}*/}
            {/*      src={ProjectEmployeeAddIcon}*/}
            {/*      alt="add"*/}
            {/*      className={classNames(ProjectEmployeeMmrInformationActionBarStyles.actionIcon)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</Tooltip>*/}
            <Tooltip title={"Download MMR"} placement="top" arrow TransitionComponent={Fade}>
              <div
                className={classNames(ProjectEmployeeMmrInformationActionBarStyles.actionIcon, GlobalStyles.elementWithCursor)}
                onMouseEnter={() => setActionBtnHovered(MmrInformationActionButtonEnum.DOWNLOAD)}
                onMouseLeave={() => setActionBtnHovered(MmrInformationActionButtonEnum.NONE)}
                onClick={() => {
                  void exportMmrToFile();
                }}
              >
                <img
                  src={isDownloadHovered ? DownloadMmrGreenIcon : ProjectEmployeeDownloadIcon}
                  alt="downloadTemplate"
                  className={classNames(ProjectEmployeeMmrInformationActionBarStyles.actionIcon)}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
          }}
          responseType={responseType}
          responseObject={responseObject}
        />
      )}
    </div>
  );
};
