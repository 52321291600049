import classNames from "classnames";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import FormEmployeeInfoBasicStyles from "./FormEmployeeInfoBasic.module.css";
import defaultAvatar from "../../../../../../assets/images/profile-default-avatar-white.svg";
import { FC } from "react";

interface FormEmployeeInfoBasicProps {
  employeeName: string;
  employeeCode: string;
  onEmployeeNameChange: (eventValue: string | undefined) => void;
  onEmployeeCodeChange: (eventValue: string | undefined) => void;
}

export const FormEmployeeInfoBasic: FC<FormEmployeeInfoBasicProps> = ({ employeeName, employeeCode, onEmployeeNameChange, onEmployeeCodeChange }) => {
  return (
    <div className={classNames(FormEmployeeInfoBasicStyles.container, GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap2)}>
      <div className={classNames(GlobalStyles.flex)}>
        <div className={classNames(GlobalStyles.flex1)} />
        <div className={classNames(FormEmployeeInfoBasicStyles.avatar, GlobalStyles.centerVertical)}>
          <img className={classNames(FormEmployeeInfoBasicStyles.avatarImg)} src={defaultAvatar} alt="avatar" />
        </div>
        <div className={classNames(GlobalStyles.flex1)} />
      </div>
      <div
        className={classNames(
          FormEmployeeInfoBasicStyles.employeeCredentials,
          GlobalStyles.flex,
          GlobalStyles.flexDirectionColumn,
          GlobalStyles.gap075,
        )}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames()}>{"Full Name:"}</div>
          <input
            value={employeeName ?? undefined}
            onChange={(event) => onEmployeeNameChange(event.target.value)}
            placeholder={"Type here..."}
            className={classNames(FormEmployeeInfoBasicStyles.inputContainer)}
          />
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames()}>{"EmpCode:"}</div>
          <input
            value={employeeCode ?? undefined}
            onChange={(event) => onEmployeeCodeChange(event.target.value)}
            placeholder={"Type here..."}
            className={classNames(FormEmployeeInfoBasicStyles.inputContainer)}
          />
        </div>
      </div>
    </div>
  );
};
