import { ProjectFileNotificationMessage } from "../types/projects/FileTypes.ts";

export const validateSseResponse = (accumulatedData: string): ProjectFileNotificationMessage[] => {
  const lines = accumulatedData.split("\n");
  const parsedEvents: ProjectFileNotificationMessage[] = [];

  for (const line of lines) {
    try {
      if (line.trim() !== "") {
        const jsonString = line.replace(/^data:\s*/, "").trim();
        const parsedEvent = JSON.parse(jsonString) as ProjectFileNotificationMessage;
        parsedEvents.push(parsedEvent);
      }
    } catch (error) {
      console.error("Error parsing JSON object:", error);
    }
  }

  return parsedEvents;
};

export const readFileEventStream = (eventStream: ReadableStream<Uint8Array>) => {
  return eventStream.pipeThrough(new TextDecoderStream()).getReader();
};
