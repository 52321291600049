import { FrontendUserLimitedType } from "../../../../types/apicallstypes/UsersApiTypes.ts";
import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import SelectUserForProjectRolesPopup from "../../popups/selectuserforprojectrolespopup/SelectUserForProjectRolesPopup.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import SelectUserInputStyles from "./SelectUserInput.module.css";
import arrowBlackIcon from "../../../../assets/images/arrow-down-black.svg";
import { ProjectManagerInputFieldsEnum } from "../../../../types/projects/ProjectsTypes.ts";

interface SelectUserInputProps {
  projectManagerInputEntity: FrontendUserLimitedType | undefined;
  setProjectManagerInputEntity: Dispatch<SetStateAction<FrontendUserLimitedType | undefined>>;
  overrideInputContainerStyles?: string;
  projectManagerInputType: ProjectManagerInputFieldsEnum;
}

const SelectUserInput: FC<SelectUserInputProps> = ({
  projectManagerInputEntity,
  setProjectManagerInputEntity,
  projectManagerInputType,
  overrideInputContainerStyles,
}) => {
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();

  const projectManagerInputTypeText = useMemo(() => {
    switch (projectManagerInputType) {
      case ProjectManagerInputFieldsEnum.PROJECT_MANAGER:
        return ProjectManagerInputFieldsEnum.PROJECT_MANAGER;
      case ProjectManagerInputFieldsEnum.PROJECT_CONTROLS_MANAGER:
        return ProjectManagerInputFieldsEnum.PROJECT_CONTROLS_MANAGER;
      case ProjectManagerInputFieldsEnum.PROJECT_COST_CONTROL_ENGINEER:
        return ProjectManagerInputFieldsEnum.PROJECT_COST_CONTROL_ENGINEER;
      default:
        return "";
    }
  }, [projectManagerInputType]);

  return (
    <div>
      <div
        onClick={() => onOpenPopup(ProjectsPagePopups.selectUserForProjectRole, popupHandler)}
        className={classNames(
          overrideInputContainerStyles
            ? overrideInputContainerStyles
            : projectManagerInputEntity?.name
              ? SelectUserInputStyles.popupDiv
              : SelectUserInputStyles.inputContainer,
          GlobalStyles.elementWithCursor,
          GlobalStyles.flex,
          GlobalStyles.centerHorizontal,
        )}
      >
        {projectManagerInputEntity?.name ? projectManagerInputEntity.name : `Click to Select ${projectManagerInputType}`}
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        <img src={arrowBlackIcon} alt="arrow-black" className={classNames(GlobalStyles.flex, GlobalStyles.centerVertical)} />
      </div>
      {popupHandler.get(ProjectsPagePopups.selectUserForProjectRole)!.isOpen && (
        <SelectUserForProjectRolesPopup
          selectedUser={projectManagerInputEntity}
          setSelectedUser={setProjectManagerInputEntity}
          isOpen={popupHandler.get(ProjectsPagePopups.selectUserForProjectRole)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.selectUserForProjectRole, popupHandler)}
          headerText={`${popupHeaders.get(ProjectsPagePopups.selectUserForProjectRole)} - ${projectManagerInputTypeText}`}
        />
      )}
    </div>
  );
};

export default SelectUserInput;
