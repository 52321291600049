import {
  FrontendProjectCategoryType,
  FrontendProjectDisciplineType,
  FrontendProjectLocationLimited,
  FrontendProjectManpowerPositionLimitedType,
  FrontendProjectPositionType,
  FrontendProjectTradeType,
  FrontendSubPositionType,
} from "../../../ProjectsUtilsApiTypes.ts";
import { LocationLimited } from "epcm-common/dist/Types/LocationTypes";
import { CategoryLimited } from "epcm-common/dist/Types/CategoryTypes";
import { GlobalPositionLimited } from "epcm-common/dist/Types/GlobalPositionTypes";
import { TradeLimited } from "epcm-common/dist/Types/TradeTypes";
import { SubPositionLimited } from "epcm-common/dist/Types/SubPositionTypes";
import { DisciplineLimited } from "epcm-common/dist/Types/DisciplineTypes";
import { ManpowerPositionLimited } from "epcm-common/dist/Types/ManpowerPositionTypes";
import { ApiResponseMissingConfiguration, configurationStatus } from "epcm-common/dist/Types/GeneralTypes";
import { frontendConfigurationStatus, FrontendMissingConfigurationType } from "../../../../projects/ConfigurationTypes.ts";

export const convertProjectLocationLimitedData = (projectLocationData: LocationLimited): FrontendProjectLocationLimited => {
  return {
    id: projectLocationData.id,
    code: projectLocationData.code,
    description: projectLocationData.description,
    workingHours: projectLocationData.workingHours,
  };
};

export const convertCategoryData = (categoryData: CategoryLimited): FrontendProjectCategoryType => {
  return {
    code: categoryData.code,
    id: categoryData.id,
    description: categoryData.description,
  };
};
export const convertPositionData = (position: GlobalPositionLimited): FrontendProjectPositionType => {
  return {
    id: position.id,
    code: position.code,
    description: position.description,
  };
};
export const convertSubPositionSingleData = (subPositionData: SubPositionLimited): FrontendSubPositionType => {
  return {
    id: subPositionData.id,
    code: subPositionData.code,
    description: subPositionData.description,
  };
};
export const convertTradeData = (tradeData: TradeLimited): FrontendProjectTradeType => {
  return {
    id: tradeData.id,
    code: tradeData.code,
    description: tradeData.description,
  };
};
export const convertSubPositionData = (subPositionData: SubPositionLimited[]): FrontendSubPositionType[] => {
  return subPositionData.map((subPosition) => {
    return convertSubPositionSingleData(subPosition);
  });
};

export const convertProjectDisciplineData = (disciplineData: DisciplineLimited): FrontendProjectDisciplineType => {
  return {
    id: disciplineData.id,
    code: disciplineData.code,
    description: disciplineData.description,
  };
};
export const convertProjectManpowerPositionData = (manpowerPositionData: ManpowerPositionLimited): FrontendProjectManpowerPositionLimitedType => {
  return {
    id: manpowerPositionData.id,
    code: manpowerPositionData.code,
  };
};
export const convertConfigurationStatusToFrontend = (status: configurationStatus): frontendConfigurationStatus => {
  if (status === configurationStatus.MISSING) {
    return frontendConfigurationStatus.MISSING;
  } else {
    return frontendConfigurationStatus.NOT_MISSING;
  }
};
export const convertProjectMissingConfigurationInfo = (
  missingConfigurationData: ApiResponseMissingConfiguration,
): FrontendMissingConfigurationType => {
  return {
    positionConfiguration: convertConfigurationStatusToFrontend(missingConfigurationData.positionConfiguration),
    tradeConfiguration: convertConfigurationStatusToFrontend(missingConfigurationData.tradeConfiguration),
    disciplineConfiguration: convertConfigurationStatusToFrontend(missingConfigurationData.disciplineConfiguration),
    categoryConfiguration: convertConfigurationStatusToFrontend(missingConfigurationData.categoryConfiguration),
    locationConfiguration: convertConfigurationStatusToFrontend(missingConfigurationData.locationConfiguration),
    calendarConfiguration: convertConfigurationStatusToFrontend(missingConfigurationData.calendarConfiguration),
    ctrConfiguration: convertConfigurationStatusToFrontend(missingConfigurationData.ctrConfiguration),
    ctrMappingConfiguration: convertConfigurationStatusToFrontend(missingConfigurationData.ctrMappingConfiguration),
    locationCalendarMappingConfiguration: convertConfigurationStatusToFrontend(missingConfigurationData.locationCalendarMappingConfiguration),
    manpowerPositionConfiguration: convertConfigurationStatusToFrontend(missingConfigurationData.manpowerPositionConfiguration),
  };
};
