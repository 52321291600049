import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ConfigurationSetUpCalendarWorkingDaysPopupItemStyles from "../configurationsetupcalendarworkingdayspopup/workingDaysPopupItem/ConfigurationSetUpCalendarWorkingDaysPopupItemStyles.module.css";
import { DaysOfWeek } from "epcm-common/dist/Types/GeneralTypes";
import { Dispatch, FC, SetStateAction } from "react";
import ConfigurationSetUpCalendarWorkingDaysPopupStyles from "../configurationsetupcalendarworkingdayspopup/ConfigurationSetUpCalendarWorkingDaysPopup.module.css";

interface ConfigurationSetUpStartingDayPopupProps {
  startingDay: DaysOfWeek;
  setStartingDay: Dispatch<SetStateAction<DaysOfWeek>>;
}

const ConfigurationSetUpStartingDayPopup: FC<ConfigurationSetUpStartingDayPopupProps> = ({ startingDay, setStartingDay }) => {
  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.overflowHiddenFullHeight, GlobalStyles.flexDirectionColumn)}>
      <div className={classNames(ConfigurationSetUpCalendarWorkingDaysPopupStyles.descriptionText)}>{"Select starting day of the week"}</div>
      <div className={classNames(ConfigurationSetUpCalendarWorkingDaysPopupItemStyles.smallPadding, GlobalStyles.flex, GlobalStyles.gap)}>
        {Object.values(DaysOfWeek).map((day: DaysOfWeek, index: number) => {
          const dayLabel = day.substring(0, 3).toUpperCase();
          return (
            <div
              key={index}
              className={classNames(
                ConfigurationSetUpCalendarWorkingDaysPopupItemStyles.dayContainer,
                GlobalStyles.elementWithCursor,
                startingDay === day && ConfigurationSetUpCalendarWorkingDaysPopupItemStyles.selectedDay,
              )}
              onClick={() => setStartingDay(day)}
            >
              {dayLabel}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ConfigurationSetUpStartingDayPopup;
