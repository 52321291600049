import { AxiosRequestConfig } from "axios";
import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { DetailLevel } from "epcm-common/dist/Types/GeneralTypes";
import { CategoryLimited } from "epcm-common/dist/Types/CategoryTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getManpowerPositionCodeCategory = async (
  id: number,
  categoryId: number,
  detailLevel: DetailLevel = DetailLevel.LIMITED,
): Promise<CategoryLimited> => {
  const params = new URLSearchParams();
  detailLevel && params.set("detailLevel", `${detailLevel}`);

  const config: AxiosRequestConfig = {
    url: `${API}/${id}/category/${categoryId}`,
    method: "GET",
    params,
  } as AxiosRequestConfig;

  const response = await epcmAxiosInstance(config);

  return response.data satisfies CategoryLimited;
};
