import classNames from "classnames";
import ConfigurationLocationsPopupStyles from "./ConfigurationLocationsPopup.module.css";
import TimesheetGroupSettingsMainIcon from "../../../../../../assets/images/timesheet-group-settings-popup-main-icon.svg";
import GlobalStyles from "../../../../../../assets/css/GlobalStyles.module.css";
import { FC, useCallback, useMemo, useState, useEffect } from "react";
import InfoIconBlack from "../../../../../../assets/images/info-icon-black-bg.svg";
import { NumericFormat } from "react-number-format";

import { useQueryClient } from "@tanstack/react-query";
import { configurationsPopupType } from "../../../../../../types/projects/ConfigurationTypes.ts";
import { PopupButtonTypeEnum, PopupType } from "../../../../../../types/PopupType.ts";
import {
  FrontendProjectConfigurationsEntityEnum,
  useEpcmApiProjectUtilsMutations,
} from "../../../../../../apicalls/projects/projectsutils/mutations/useEpcmApiProjectUtilsMutations.ts";
import { useResponseAlertPopupStateType } from "../../../../../../utils/use-response-alert-popup-state.ts";
import { usePopupState } from "../../../../../../utils/use-popup-state.ts";
import {
  ApiResponseTypeEnum,
  ErrorCallbackDataType,
  QueryNames,
  SuccessCallbackDataType,
} from "../../../../../../types/apicallstypes/queryCommons.ts";
import { PopupStructure } from "../../../../../../ui/popupstructure/PopupStructure.tsx";
import { ResponseAlertPopup } from "../../../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import { LoaderPopup } from "../../../../../../ui/loaderpopup/LoaderPopup.tsx";
import { FrontendProjectLocationLimited } from "../../../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";

interface ConfigurationLocationsPopupProps extends PopupType {
  projectId?: number;
  locationItem?: FrontendProjectLocationLimited;
  mode: configurationsPopupType;
}

const ConfigurationLocationsPopup: FC<ConfigurationLocationsPopupProps> = ({
  closeFn,
  isOpen,
  headerText,
  secondaryHeaderText,
  projectId,
  locationItem,
  mode,
}) => {
  const [locationName, setLocationName] = useState<string>(locationItem?.description || "");
  const [locationCode, setLocationCode] = useState<string>(locationItem?.code || "");
  const [locationWorkingHours, setLocationWorkingHours] = useState<number>(locationItem?.workingHours || 0);

  useEffect(() => {
    if (locationItem) {
      setLocationName(locationItem.description);
      setLocationCode(locationItem.code);
      setLocationWorkingHours(locationItem.workingHours);
    }
  }, [locationItem]);

  const { useCreateProjectEntityMutation, useUpdateProjectEntityMutation } = useEpcmApiProjectUtilsMutations();

  const queryClient = useQueryClient();

  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const createProjectLocationMutation = useCreateProjectEntityMutation(projectId!, FrontendProjectConfigurationsEntityEnum.Location, {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: `Location was added to project successfully` });
      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      onCloseLoaderPopup();
    },
  });

  const updateProjectLocationMutation = useUpdateProjectEntityMutation(
    projectId!,
    locationItem?.id ?? 0,
    FrontendProjectConfigurationsEntityEnum.Location,
    {
      onSuccessCallback: (data: SuccessCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.success);
        setResponseObject({ status: data.data.status, message: `Location was updated successfully` });
        onOpenResponseAlertPopup();
      },
      onErrorCallback: (error: ErrorCallbackDataType) => {
        setResponseType(ApiResponseTypeEnum.error);
        setResponseObject(error.response.data);
        onOpenResponseAlertPopup();
      },
      onSettledCallback: () => {
        onCloseLoaderPopup();
      },
    },
  );

  const onCreateProjectLocation = useCallback(() => {
    createProjectLocationMutation.mutate({
      code: locationCode,
      description: locationName,
      workingHours: locationWorkingHours,
    });
    onOpenLoaderPopup();
  }, [createProjectLocationMutation, locationName, locationCode, locationWorkingHours, onOpenLoaderPopup]);

  const onUpdateProjectLocation = useCallback(() => {
    updateProjectLocationMutation.mutate({
      code: locationCode,
      description: locationName,
      workingHours: locationWorkingHours,
    });
    onOpenLoaderPopup();
  }, [updateProjectLocationMutation, locationName, locationCode, locationWorkingHours, onOpenLoaderPopup]);

  const invalidateQueries = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectLocations, projectId] });
  }, [queryClient, projectId]);

  const isEitherInputEmpty = useMemo(
    () => !locationName || !locationCode || !locationWorkingHours,
    [locationCode, locationName, locationWorkingHours],
  );

  return (
    <PopupStructure
      overrideContentContainerStyleClass={classNames(ConfigurationLocationsPopupStyles.popupContainer)}
      popupButtons={[
        {
          buttonType: PopupButtonTypeEnum.neutral,
          text: "Cancel",
          action: closeFn,
        },
        {
          buttonType: PopupButtonTypeEnum.main,
          text: mode === configurationsPopupType.edit ? "Update Location" : "Create New Location",
          action: () => {
            if (mode === configurationsPopupType.edit) {
              !!locationCode && !!locationName && !!locationWorkingHours && onUpdateProjectLocation();
            } else {
              !!locationCode && !!locationName && !!locationWorkingHours && onCreateProjectLocation();
            }
          },
          tooltipText: isEitherInputEmpty ? "Please enter both location code and description and positive working hours" : "",
          disabled: isEitherInputEmpty,
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
      headerIcon={TimesheetGroupSettingsMainIcon}
    >
      <div
        className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap, ConfigurationLocationsPopupStyles.mainContainer)}
      >
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationLocationsPopupStyles.infoText)}>Location Code</div>
          <input
            placeholder={"Type a location code..."}
            className={classNames(ConfigurationLocationsPopupStyles.infoContainer, ConfigurationLocationsPopupStyles.infoContainerInput)}
            required
            value={locationCode}
            onChange={(e) => setLocationCode(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationLocationsPopupStyles.infoText)}>Location Name</div>
          <input
            placeholder={"Type a location name..."}
            className={classNames(ConfigurationLocationsPopupStyles.infoContainer, ConfigurationLocationsPopupStyles.infoContainerInput)}
            required
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
          ></input>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flexDirectionColumn, GlobalStyles.gap025)}>
          <div className={classNames(ConfigurationLocationsPopupStyles.infoText)}> Working Hours</div>
          <NumericFormat
            placeholder={"Type location working hours"}
            className={classNames(ConfigurationLocationsPopupStyles.infoContainer, ConfigurationLocationsPopupStyles.infoContainerInput)}
            required
            value={locationWorkingHours}
            allowNegative={false}
            onValueChange={(values) => {
              const { floatValue } = values;
              if (!floatValue || floatValue <= 0) {
                return;
              }
              setLocationWorkingHours(floatValue);
            }}
          ></NumericFormat>
        </div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.flex1)}></div>
        <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap05)}>
          <div className={classNames(ConfigurationLocationsPopupStyles.locationInfoIcon)}>
            <img src={InfoIconBlack} alt={"Info icon"} />
          </div>
          <div className={classNames(ConfigurationLocationsPopupStyles.locationInfoText)}>
            New Location Types will be reflected across the entire project.
          </div>
        </div>
      </div>
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            if (responseType === ApiResponseTypeEnum.success) {
              invalidateQueries();
              closeFn && closeFn();
            }
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ConfigurationLocationsPopup;
