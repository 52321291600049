import { styled, Switch, SwitchProps } from "@mui/material";

import CustomSwitchStyles from "./EpcmSwitch.module.css";

interface CustomSwitchProps extends SwitchProps {
  isChecked: boolean;
  toggleSwitch: () => void;
}

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export const IconSwitch = styled(Switch)(() => ({
  width: 48,
  height: 28,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: "4px 0 0 0",
    padding: 0,
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#dde1ec",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#fff",
    width: 20,
    height: 20,
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#dde1ec",
    borderRadius: 20 / 2,
  },
}));

export const CustomSwitch: React.FC<CustomSwitchProps> = ({ isChecked, toggleSwitch, ...rest }) => (
  <IconSwitch
    checked={isChecked}
    onChange={toggleSwitch}
    disableRipple
    classes={{
      switchBase: CustomSwitchStyles.switchBase,
      checked: CustomSwitchStyles.switchBaseChecked,
      thumb: `${CustomSwitchStyles.switchThumb} ${isChecked ? CustomSwitchStyles.switchThumbChecked : ""}`,
      track: isChecked ? CustomSwitchStyles.switchTrackChecked : CustomSwitchStyles.switchTrack,
    }}
    {...rest}
  />
);
