import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PopupButtonTypeEnum, PopupType } from "../../../../types/PopupType.ts";
import { PopupStructure } from "../../../../ui/popupstructure/PopupStructure.tsx";
import classNames from "classnames";
import GlobalStyles from "../../../../assets/css/GlobalStyles.module.css";
import ConfigurationSetUpCalendarPopupStyles from "./ConfigurationSetUpCalendarPopup.module.css";
import ConfigurationSetUpCalendarDatePopup from "../configurationsetupcalendardatepopup/ConfigurationSetUpCalendarDatePopup.tsx";
import ConfigurationSetUpCalendarWorkingDaysPopup from "../configurationsetupcalendarworkingdayspopup/ConfigurationSetUpCalendarWorkingDaysPopup.tsx";
import ConfigurationSetUpCalendarHolidaysPopup from "../configurationsetupcalendarholidayspopup/ConfigurationSetUpCalendarHolidaysPopup.tsx";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ProjectsPagePopups, useProjectsPopups } from "../../use-projects-popups.ts";
import { ApiRequestBodyCalendarCreate, LocationHolidays, LocationWorkingDays } from "epcm-common/dist/Types/CalendarTypes";
import { useEpcmApiProjectCalendarMutations } from "../../../../apicalls/projects/projectcalendar/mutations/useEpcmApiProjectCalendarMutations.ts";
import { ApiResponseTypeEnum, ErrorCallbackDataType, QueryNames, SuccessCallbackDataType } from "../../../../types/apicallstypes/queryCommons.ts";
import { DaysOfWeek } from "epcm-common/dist/Types/GeneralTypes";
import { useParams } from "react-router-dom";
import { useEpcmApiProjectsUtils } from "../../../../apicalls/projects/projectsutils/useEpcmApiProjectsUtils.ts";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import ConfigurationLocationsPopup from "../../../configurations/popups/configurationlocationspopup/ConfigurationLocationsPopup.tsx";
import { FrontendProjectLocationLimited } from "../../../../types/apicallstypes/ProjectsUtilsApiTypes.ts";
import { convertDateToMilSecs } from "../../../../utils/DateManipulation.ts";
import { usePopupState } from "../../../../utils/use-popup-state.ts";
import { LoaderPopup } from "../../../../ui/loaderpopup/LoaderPopup.tsx";
import { useResponseAlertPopupStateType } from "../../../../utils/use-response-alert-popup-state.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../apicalls/config.ts";
import { useImpersonationStore } from "../../../../store/use-impersonation-store.ts";
import {
  createNewCalendarErrorMessages,
  FrontendCalendarTabs,
  FrontendHolidayItem,
  FrontendLocationWorkingDays,
} from "../../../../types/projects/CalendarTypes.ts";
import ConfirmCreateCalendarPopup from "../confirmcreatecalendarpopup/ConfirmCreateCalendarPopup.tsx";
import { ResponseAlertPopup } from "../../../../ui/responsealertpopup/ResponseAlertPopup.tsx";
import ConfigurationSetUpCalendarLocationsPopup from "../configurationsetupcalendarlocationspopup/ConfigurationSetUpCalendarLocationsPopup.tsx";
import ConfigurationSetUpStartingDayPopup from "../configurationsetupstartingdaypopup/ConfigurationSetUpStartingDayPopup.tsx";

const ConfigurationSetUpCalendarPopup: FC<PopupType> = ({ isOpen, closeFn, headerText, secondaryHeaderText }) => {
  const { projectId } = useParams();

  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllProjectLocations } = useEpcmApiProjectsUtils();
  const queryClient = useQueryClient();
  const { useCreateNewCalendarMutation } = useEpcmApiProjectCalendarMutations();
  const { popupHandler, onOpenPopup, onClosePopup, popupHeaders } = useProjectsPopups();
  const {
    isResponseAlertPopupOpen,
    onOpenResponseAlertPopup,
    onCloseResponseAlertPopup,
    responseType,
    setResponseType,
    responseObject,
    setResponseObject,
    initializeResponseAlertPopup,
  } = useResponseAlertPopupStateType();
  const { isUtilPopupOpen: isLoaderPopupOpen, onOpenUtilPopup: onOpenLoaderPopup, onCloseUtilPopup: onCloseLoaderPopup } = usePopupState();

  const projectLocationsQuery = useInfiniteQuery({
    queryKey: [QueryNames.ProjectLocations, projectId],
    queryFn: ({ pageParam }) => getAllProjectLocations(parseInt(projectId!), pageParam, undefined, PAGINATION_PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized,
  });

  const projectLocationsQueryData = useMemo(() => projectLocationsQuery.data?.pages.flatMap((page) => page.data) || [], [projectLocationsQuery.data]);
  const [currentTab, setCurrentTab] = useState<FrontendCalendarTabs>(FrontendCalendarTabs.Locations);
  const [selectedCutOffDay, setCutOffDay] = useState<number>(0);
  const [locationsWorkingDays, setLocationsWorkingDays] = useState<FrontendLocationWorkingDays[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<FrontendProjectLocationLimited[]>([]);
  const [unselectedLocations, setUnselectedLocations] = useState<FrontendProjectLocationLimited[]>([]);
  const [holidayItems, setHolidayItems] = useState<FrontendHolidayItem[]>([]);
  const [startDayOfWeek, setStartDayOfWeek] = useState<DaysOfWeek>(DaysOfWeek.MONDAY);

  const updateProjectLocations = useCallback((newLocation: FrontendProjectLocationLimited) => {
    setLocationsWorkingDays((prev) => [
      ...prev,
      {
        locationId: newLocation.id,
        workingDays: [],
        allSelectedLocations: true,
      },
    ]);
  }, []);

  const createNewCalendarMutation = useCreateNewCalendarMutation(Number(projectId), {
    onSuccessCallback: (data: SuccessCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.success);
      setResponseObject({ status: data.data.status, message: "Project Calendar was created successfully!" });

      onOpenResponseAlertPopup();
    },
    onErrorCallback: (error: ErrorCallbackDataType) => {
      setResponseType(ApiResponseTypeEnum.error);
      setResponseObject(error.response.data);
      onOpenResponseAlertPopup();
    },
    onSettledCallback: () => {
      void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectLocations, parseInt(projectId!)] });
      onCloseLoaderPopup();
    },
  });

  const handleTabChange = useCallback(
    (newValue: FrontendCalendarTabs) => {
      setCurrentTab(newValue);
    },
    [setCurrentTab],
  );

  const handleButtonClick = useCallback(() => {
    if (currentTab === FrontendCalendarTabs.Holidays) {
      onOpenLoaderPopup();
      const selectedLocationIds = selectedLocations.map((location) => location.id);

      const selectedLocationsWorkingDays: LocationWorkingDays[] = locationsWorkingDays
        .filter((location) => selectedLocationIds.includes(location.locationId ? location.locationId : 0))
        .filter((location) => location.workingDays.length > 0)
        .map((location) => {
          if (!location.allSelectedLocations) {
            return {
              locationId: location.locationId,
              workingDays: location.workingDays,
            };
          } else {
            return {
              allSelectedLocations: true,
              workingDays: location.workingDays,
            };
          }
        });

      const locationActualHolidays: LocationHolidays[] = holidayItems
        .filter((holidayItem) => holidayItem.locationHolidays.date !== null && holidayItem.locationHolidays.holidayTitle !== "")
        .map((holidayItem) => {
          // ensure it cannot contain locatiion id's that are not in selectedLocationIds
          if (holidayItem.allSelectedLocations) {
            return {
              locationHolidays: {
                date: convertDateToMilSecs(holidayItem.locationHolidays.date!),
                holidayTitle: holidayItem.locationHolidays.holidayTitle,
              },
              allSelectedLocations: true,
            };
          } else {
            return {
              locationIds: holidayItem.locationIds,
              locationHolidays: {
                date: convertDateToMilSecs(holidayItem.locationHolidays.date!),
                holidayTitle: holidayItem.locationHolidays.holidayTitle,
              },
            };
          }
        });

      const data: ApiRequestBodyCalendarCreate = {
        startDayOfWeek: startDayOfWeek,
        cutOffDay: selectedCutOffDay,
        selectedLocationIds: selectedLocationIds,
        locationsWorkingDays: selectedLocationsWorkingDays,
        locationsHolidays: locationActualHolidays.length !== 0 ? locationActualHolidays : [],
      } satisfies ApiRequestBodyCalendarCreate;

      createNewCalendarMutation.mutate(data);
    } else {
      handleTabChange(currentTab + 1);
    }
  }, [
    currentTab,
    selectedLocations,
    startDayOfWeek,
    locationsWorkingDays,
    holidayItems,
    selectedCutOffDay,
    createNewCalendarMutation,
    onOpenLoaderPopup,
    handleTabChange,
  ]);

  const atLeastOneLocationHasWorkingDays = useMemo(() => {
    return locationsWorkingDays.some((location) => location.workingDays.length > 0);
  }, [locationsWorkingDays]);

  const areNecessaryDataAvailable: boolean = useMemo(() => {
    return locationsWorkingDays.length > 0 && selectedLocations.length > 0 && selectedCutOffDay > 0 && atLeastOneLocationHasWorkingDays;
  }, [locationsWorkingDays, selectedLocations, selectedCutOffDay, atLeastOneLocationHasWorkingDays]);

  const buttonAction = useMemo(() => handleButtonClick, [handleButtonClick]);

  const showMissingDataTooltipText = useMemo(() => {
    if (!areNecessaryDataAvailable && currentTab === FrontendCalendarTabs.Holidays) {
      if (selectedLocations.length === 0) {
        return createNewCalendarErrorMessages.get(FrontendCalendarTabs.Locations);
      }
      if (selectedCutOffDay === 0) {
        return createNewCalendarErrorMessages.get(FrontendCalendarTabs.CutOffDate);
      }
      if (!atLeastOneLocationHasWorkingDays) {
        return createNewCalendarErrorMessages.get(FrontendCalendarTabs.WorkingDays);
      }
    } else {
      return "";
    }
  }, [areNecessaryDataAvailable, selectedLocations, selectedCutOffDay, currentTab, atLeastOneLocationHasWorkingDays]);

  const invalidateQuery = useCallback(() => {
    void queryClient.invalidateQueries({ queryKey: [QueryNames.ProjectCalendar, projectId] });
  }, [queryClient, projectId]);

  useEffect(() => {
    setSelectedLocations(
      projectLocationsQueryData.filter((location) => !unselectedLocations.some((unselectedLocation) => unselectedLocation.id === location.id)),
    );
  }, [projectLocationsQueryData, unselectedLocations]);

  useEffect(() => {
    const initialLocationsWorkingDays = selectedLocations.map((location) => ({
      locationId: location.id,
      workingDays: [],
      allSelectedLocations: true,
    }));
    setLocationsWorkingDays(initialLocationsWorkingDays);
  }, [selectedLocations]);

  useEffect(() => {
    if (selectedLocations.length > 0) {
      const initialHolidayItem: FrontendHolidayItem = {
        locationHolidays: { date: null, holidayTitle: "" },
        allSelectedLocations: true,
        locationIds: selectedLocations.map((location) => location.id),
      };
      setHolidayItems([initialHolidayItem]);
    }
  }, [selectedLocations]);

  return (
    <PopupStructure
      popupButtons={[
        {
          text: "Cancel",
          buttonType: PopupButtonTypeEnum.neutral,
          action: closeFn,
          disabled: createNewCalendarMutation.isPending,
        },
        {
          text: currentTab === FrontendCalendarTabs.Holidays ? "Render calendar" : "Next",
          buttonType: PopupButtonTypeEnum.main,
          disabled: (currentTab === FrontendCalendarTabs.Holidays && !areNecessaryDataAvailable) || createNewCalendarMutation.isPending,
          tooltipText: showMissingDataTooltipText,

          action: () => {
            currentTab === FrontendCalendarTabs.Holidays
              ? onOpenPopup(ProjectsPagePopups.confirmCalendarCreation, popupHandler)
              : handleButtonClick();
          },
        },
      ]}
      isOpen={isOpen}
      closeFn={closeFn}
      headerText={headerText}
      secondaryHeaderText={secondaryHeaderText}
    >
      {createNewCalendarMutation.isPending ? (
        <LoaderPopup isOpen={true} closeFn={() => {}} />
      ) : (
        <div
          className={classNames(
            GlobalStyles.flex,
            ConfigurationSetUpCalendarPopupStyles.mainContainer,
            GlobalStyles.overflowHiddenFullHeight,
            GlobalStyles.flexDirectionColumn,
          )}
        >
          <Tabs
            value={currentTab}
            onChange={(_event: React.SyntheticEvent, newValue: FrontendCalendarTabs) => setCurrentTab(newValue)}
            TabIndicatorProps={{ sx: { backgroundColor: "orange", height: 3 } }}
            sx={{
              "& .MuiTabs-flexContainer": { width: "100%" },
              "& .MuiTab-root": { flex: 1, color: "#000000" },
              "& .MuiTab-root.Mui-selected": {
                color: "#f16625",
              },
              "& button": { fontWeight: "bold" },
              "& button:active": { fontWeight: "bold", color: "#f16625" },
              "& button:focus": { fontWeight: "bold", color: "#f16625" },
              "& button:hover": { fontWeight: "bold", color: "#f16625" },
              paddingBottom: "1.5em",
            }}
          >
            <Tab className={classNames(ConfigurationSetUpCalendarPopupStyles.tabsContainer)} label="LOCATIONS" />
            <Tab className={classNames(ConfigurationSetUpCalendarPopupStyles.tabsContainer)} label="CUT-OFF DATE" />
            <Tab className={classNames(ConfigurationSetUpCalendarPopupStyles.tabsContainer)} label="START DAY OF THE WEEK" />
            <Tab className={classNames(ConfigurationSetUpCalendarPopupStyles.tabsContainer)} label="WORKING DAYS" />
            <Tab className={classNames(ConfigurationSetUpCalendarPopupStyles.tabsContainer)} label="HOLIDAYS" />
          </Tabs>
          {currentTab === FrontendCalendarTabs.Locations && (
            <ConfigurationSetUpCalendarLocationsPopup
              selectedLocations={selectedLocations}
              unselectedLocations={unselectedLocations}
              setHolidayItems={setHolidayItems}
              updateProjectLocations={updateProjectLocations}
              setSelectedLocations={setSelectedLocations}
              setUnselectedLocations={setUnselectedLocations}
            />
          )}
          {currentTab === FrontendCalendarTabs.ProjectStartDayOfWeek && (
            <ConfigurationSetUpStartingDayPopup setStartingDay={setStartDayOfWeek} startingDay={startDayOfWeek} />
          )}
          {currentTab === FrontendCalendarTabs.CutOffDate && (
            <ConfigurationSetUpCalendarDatePopup setCutOffDay={setCutOffDay} selectedCutOffDay={selectedCutOffDay} />
          )}
          {currentTab === FrontendCalendarTabs.WorkingDays && (
            <ConfigurationSetUpCalendarWorkingDaysPopup
              selectedLocations={selectedLocations}
              locationsWorkingDays={locationsWorkingDays}
              setLocationsWorkingDays={setLocationsWorkingDays}
            />
          )}
          {currentTab === FrontendCalendarTabs.Holidays && (
            <ConfigurationSetUpCalendarHolidaysPopup
              selectedLocations={selectedLocations}
              holidayItems={holidayItems}
              setHolidayItems={setHolidayItems}
            />
          )}
        </div>
      )}
      {popupHandler.get(ProjectsPagePopups.addProjectLocation)!.isOpen && (
        <ConfigurationLocationsPopup
          isOpen={popupHandler.get(ProjectsPagePopups.addProjectLocation)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.addProjectLocation, popupHandler)}
          headerText={popupHeaders.get(ProjectsPagePopups.addProjectLocation)}
          updateProjectLocations={updateProjectLocations}
        />
      )}
      {popupHandler.get(ProjectsPagePopups.confirmCalendarCreation)!.isOpen && (
        <ConfirmCreateCalendarPopup
          onRenderCalendar={buttonAction}
          isOpen={popupHandler.get(ProjectsPagePopups.confirmCalendarCreation)!.isOpen}
          closeFn={() => onClosePopup(ProjectsPagePopups.confirmCalendarCreation, popupHandler)}
          headerText={popupHeaders.get(ProjectsPagePopups.confirmCalendarCreation)}
        />
      )}
      {isResponseAlertPopupOpen && responseType && responseObject && (
        <ResponseAlertPopup
          responseType={responseType}
          responseObject={responseObject}
          isOpen={isResponseAlertPopupOpen}
          closeFn={() => {
            initializeResponseAlertPopup();
            onCloseResponseAlertPopup();
            invalidateQuery();
            responseType === ApiResponseTypeEnum.success && closeFn();
          }}
        />
      )}
      {isLoaderPopupOpen && <LoaderPopup isOpen={isLoaderPopupOpen} closeFn={() => {}} />}
    </PopupStructure>
  );
};

export default ConfigurationSetUpCalendarPopup;
