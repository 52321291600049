import { EPCM_API_URLS, epcmAxiosInstance } from "../../config.ts";
import { AxiosRequestConfig } from "axios";
import { ApiResponseProjectCalendarInfo } from "epcm-common/dist/Types/CalendarTypes";

const API = `${EPCM_API_URLS.projects}`;

export const getGeneralCalendarInfo = async (id: number): Promise<ApiResponseProjectCalendarInfo> => {
  const config: AxiosRequestConfig = {
    url: `${API}/${id}/calendar`,
    method: "GET",
  } as AxiosRequestConfig;
  const response = await epcmAxiosInstance(config);
  return response.data;
};
