import classNames from "classnames";
import GlobalStyles from "../../../../../assets/css/GlobalStyles.module.css";
import TimesheetGroupSettingsPopupStyles from "../TimesheetGroupSettingsPopupStyles.module.css";
import GroupMemberItem from "../groupmemberitem/GroupMemberItem.tsx";
import { useImpersonationStore } from "../../../../../store/use-impersonation-store.ts";
import { InfiniteData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { QueryModelWithPagination, QueryNames } from "../../../../../types/apicallstypes/queryCommons.ts";
import { PAGINATION_PAGE_SIZE } from "../../../../../apicalls/config.ts";
import { Dispatch, FC, SetStateAction, useEffect, useMemo } from "react";
import TimesheetCreateGroupUsersIcon from "../../../../../assets/images/timesheet-create-group-users-icon.svg";

import { FrontendProjectGroupEmployeeLimitedType } from "../../../../../types/apicallstypes/ProjectEmployeesApiTypes.ts";
import InfiniteScrollInViewElement from "../../../../../ui/infinitescrollinviewelement/InfiniteScrollInViewElement.tsx";
import { useInView } from "react-intersection-observer";
import { Skeleton } from "@mui/material";
import { useEpcmApiProjectsTimesheetsGroups } from "../../../../../apicalls/projects/projecttimesheets/projecttimesheetsgroups/useEpcmApiProjectsTimesheetsGroups.ts";
import AddEntityActionButton from "../addentityactionbutton/AddEntityActionButton.tsx";
import { ProjectTimesheetGroupPopupModeEnum } from "../../../../../types/projects/ProjectTimesheetsTypes.ts";

interface TimesheetGroupMembersListProps {
  projectId: number;
  groupId: number;
  setPopupMode: Dispatch<SetStateAction<ProjectTimesheetGroupPopupModeEnum>>;
  popupMode: ProjectTimesheetGroupPopupModeEnum;
}

const TimesheetGroupMembersList: FC<TimesheetGroupMembersListProps> = ({ groupId, projectId, popupMode, setPopupMode }) => {
  const { getAllTimesheetGroupsEmployees } = useEpcmApiProjectsTimesheetsGroups();
  const { ref, inView } = useInView();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const queryClient = useQueryClient();

  //Notifications day variables
  const allTimesheetGroupEmployeesQuery = useInfiniteQuery({
    queryKey: [QueryNames.TimesheetGroupEmployees, projectId, groupId],
    queryFn: ({ pageParam }) => getAllTimesheetGroupsEmployees(projectId, Number(groupId), PAGINATION_PAGE_SIZE, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextPage ?? undefined,
    enabled: isAuthorized && projectId != null,
    select: (data) => data as InfiniteData<QueryModelWithPagination<FrontendProjectGroupEmployeeLimitedType>>,
  });

  const allTimesheetGroupEmployeesData = useMemo(() => {
    return allTimesheetGroupEmployeesQuery.data?.pages.flatMap((page) => page.data) ?? [];
  }, [allTimesheetGroupEmployeesQuery.data]);

  const totalTimesheetGroupEmployeesCount = useMemo(() => {
    if (allTimesheetGroupEmployeesQuery.data) {
      return allTimesheetGroupEmployeesQuery.data.pages[0].totalCount;
    }
  }, [allTimesheetGroupEmployeesQuery]);
  useEffect(() => {
    return () => {
      queryClient
        .cancelQueries({ queryKey: [QueryNames.TimesheetGroupEmployees, projectId, groupId] })
        .then(() => console.log(`In project with id ${projectId}, ${QueryNames.TimesheetGroupEmployees} query canceled`));
    };
  }, [queryClient, groupId, projectId]);

  useEffect(() => {
    if (
      inView &&
      !allTimesheetGroupEmployeesQuery.isLoading &&
      !allTimesheetGroupEmployeesQuery.isFetching &&
      !allTimesheetGroupEmployeesQuery.isFetchingNextPage &&
      allTimesheetGroupEmployeesQuery.hasNextPage
    ) {
      allTimesheetGroupEmployeesQuery.fetchNextPage();
    }
  }, [inView, allTimesheetGroupEmployeesQuery]);

  const EmployeeItemSkeletonLoader = () => {
    return <Skeleton variant={"rectangular"} height={10} width={100} />;
  };
  return (
    <div
      className={classNames(
        GlobalStyles.flex,
        GlobalStyles.gap075,
        GlobalStyles.flexDirectionColumn,
        TimesheetGroupSettingsPopupStyles.groupMembersContainer,
      )}
    >
      {allTimesheetGroupEmployeesData && totalTimesheetGroupEmployeesCount && totalTimesheetGroupEmployeesCount > 0 ? (
        allTimesheetGroupEmployeesData.map((member) => {
          return (
            <GroupMemberItem
              key={member.id}
              projectEmployeeId={member.id}
              projectId={projectId}
              groupId={groupId}
              isSectionHead={false}
              canBeDeleted={member.canBeDeleted}
            />
          );
        })
      ) : allTimesheetGroupEmployeesQuery.isLoading ? (
        <Skeleton variant="rounded" height={55} width={495} />
      ) : (
        popupMode === ProjectTimesheetGroupPopupModeEnum.view && (
          <AddEntityActionButton
            icon={TimesheetCreateGroupUsersIcon}
            text={"Add project employees to the group. You can remove them later."}
            actionBtnText={"Add"}
            actionFn={() => setPopupMode(ProjectTimesheetGroupPopupModeEnum.addGroupMember)}
          />
        )
      )}
      <InfiniteScrollInViewElement
        key={"InfiniteScrollInViewElement"}
        reference={ref}
        infiniteQueryResult={allTimesheetGroupEmployeesQuery}
        loaderComponent={<EmployeeItemSkeletonLoader />}
      />
    </div>
  );
};
export default TimesheetGroupMembersList;
