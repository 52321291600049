import classNames from "classnames";
import GlobalStyles from "../../../../../../../assets/css/GlobalStyles.module.css";
import TimesheetAnalyticsListButtonItem from "./timesheetanalyticslistbuttonitem/TimesheetAnalyticsListButtonItem.tsx";
import { FC, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useImpersonationStore } from "../../../../../../../store/use-impersonation-store.ts";
import { QueryNames, SubQueryNames } from "../../../../../../../types/apicallstypes/queryCommons.ts";
import { useEpcmProjectTimesheetAnalytics } from "../../../../../../../apicalls/projects/projecttimesheets/projecttimesheetsanalytics/useEpcmProjectTimesheetAnalytics.ts";
import { convertDateToMilSecs } from "../../../../../../../utils/DateManipulation.ts";
import { ProjectTimesheetAnalyticsListButtonTypesEnum } from "../../../../../../../types/projects/ProjectTimesheetsTypes.ts";

interface TimesheetAnalyticsListButtonProps {
  selectedPeriodStart: Date | null;
  selectedPeriodEnd: Date | null;
  projectId: number;
}

const TimesheetAnalyticsListButtons: FC<TimesheetAnalyticsListButtonProps> = ({ selectedPeriodStart, selectedPeriodEnd, projectId }) => {
  const queryClient = useQueryClient();
  const isAuthorized = useImpersonationStore((state) => state).isAuthorized();
  const { getAllProjectEmployeesByPeriodCount, getAllProjectTimesheetItemsCount } = useEpcmProjectTimesheetAnalytics();

  const allProjectEmployeesCountQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.count],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(projectId, convertDateToMilSecs(selectedPeriodStart!), convertDateToMilSecs(selectedPeriodEnd!)),
    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const allProjectEmployeesCountData = useMemo(() => {
    return allProjectEmployeesCountQuery.data;
  }, [allProjectEmployeesCountQuery.data]);

  const projectTimesheetEmployeesActiveTotalQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.active],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(projectId, convertDateToMilSecs(selectedPeriodStart!), convertDateToMilSecs(selectedPeriodEnd!), true),

    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetActiveEmployeesCountData = useMemo(() => {
    return projectTimesheetEmployeesActiveTotalQuery.data;
  }, [projectTimesheetEmployeesActiveTotalQuery.data]);

  const projectTimesheetEmployeesNotActiveTotalQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.notActive],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(
        projectId,
        convertDateToMilSecs(selectedPeriodStart!),
        convertDateToMilSecs(selectedPeriodEnd!),
        undefined,
        true,
      ),

    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetNotActiveEmployeesCountData = useMemo(() => {
    return projectTimesheetEmployeesNotActiveTotalQuery.data;
  }, [projectTimesheetEmployeesNotActiveTotalQuery.data]);

  const projectTimesheetItemsQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsItems, projectId, selectedPeriodStart, selectedPeriodEnd],
    queryFn: () => getAllProjectTimesheetItemsCount(projectId, convertDateToMilSecs(selectedPeriodStart!), convertDateToMilSecs(selectedPeriodEnd!)),

    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetItemsData = useMemo(() => {
    return projectTimesheetItemsQuery.data;
  }, [projectTimesheetItemsQuery.data]);

  const projectTimesheetFinalizedItemsQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsItems, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.finalized],
    queryFn: () =>
      getAllProjectTimesheetItemsCount(
        projectId,
        convertDateToMilSecs(selectedPeriodStart!),
        convertDateToMilSecs(selectedPeriodEnd!),
        undefined,
        true,
      ),

    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetFinalizedItemsData = useMemo(() => {
    return projectTimesheetFinalizedItemsQuery.data;
  }, [projectTimesheetFinalizedItemsQuery.data]);

  const projectTimesheetNonFinalizedItemsQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsItems, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.draft],
    queryFn: () =>
      getAllProjectTimesheetItemsCount(projectId, convertDateToMilSecs(selectedPeriodStart!), convertDateToMilSecs(selectedPeriodEnd!), true, false),
    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetNonFinalizedItemsData = useMemo(() => {
    return projectTimesheetNonFinalizedItemsQuery.data;
  }, [projectTimesheetNonFinalizedItemsQuery.data]);

  const projectTimesheetEmployeesInTimesheetQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.inTimesheet],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(
        projectId,
        convertDateToMilSecs(selectedPeriodStart!),
        convertDateToMilSecs(selectedPeriodEnd!),
        undefined,
        undefined,
        true,
        true,
        true,
        undefined,
      ),
    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetEmployeesInTimesheetData = useMemo(() => {
    return projectTimesheetEmployeesInTimesheetQuery.data;
  }, [projectTimesheetEmployeesInTimesheetQuery.data]);

  const projectTimesheetEmployeesNotInTimesheetQuery = useQuery({
    queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.notInTimesheet],
    queryFn: () =>
      getAllProjectEmployeesByPeriodCount(
        projectId,
        convertDateToMilSecs(selectedPeriodStart!),
        convertDateToMilSecs(selectedPeriodEnd!),
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      ),
    enabled: isAuthorized && !!selectedPeriodStart && !!selectedPeriodEnd,
  });

  const projectTimesheetEmployeesNotInTimesheetData = useMemo(() => {
    return projectTimesheetEmployeesNotInTimesheetQuery.data;
  }, [projectTimesheetEmployeesNotInTimesheetQuery.data]);

  useEffect(() => {
    return () => {
      if (selectedPeriodStart && selectedPeriodEnd) {
        queryClient
          .cancelQueries({
            queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.count],
          })
          .then(() => {
            console.log(`Query name ${QueryNames.ProjectTimesheetAnalyticsEmployees} for project ${projectId} regarding employees count cancelled`);
          });
        queryClient
          .cancelQueries({
            queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.active],
          })
          .then(() => {
            console.log(`Query name ${QueryNames.ProjectTimesheetAnalyticsEmployees} for active employees cancelled`);
          });
        queryClient
          .cancelQueries({
            queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.notActive],
          })
          .then(() => {
            console.log(`Query name ${QueryNames.ProjectTimesheetAnalyticsEmployees} for not-active employees cancelled`);
          });

        queryClient
          .cancelQueries({
            queryKey: [QueryNames.ProjectTimesheetAnalyticsEmployees, projectId, selectedPeriodStart, selectedPeriodEnd, SubQueryNames.inTimesheet],
          })
          .then(() => {
            console.log(`Query name ${QueryNames.ProjectTimesheetAnalyticsEmployees} cancelled`);
          });
        queryClient
          .cancelQueries({
            queryKey: [
              QueryNames.ProjectTimesheetAnalyticsEmployees,
              projectId,
              selectedPeriodStart,
              selectedPeriodEnd,
              SubQueryNames.notInTimesheet,
            ],
          })
          .then(() => {
            console.log(`Query name ${QueryNames.ProjectTimesheetAnalyticsEmployees} cancelled`);
          });
      }
    };
  }, [queryClient, projectId, selectedPeriodStart, selectedPeriodEnd]);

  return (
    <div className={classNames(GlobalStyles.flex, GlobalStyles.centerHorizontal, GlobalStyles.gap)}>
      {
        <TimesheetAnalyticsListButtonItem
          mainText={"Employees \n Total"}
          primaryCount={allProjectEmployeesCountData?.count}
          firstSubtext={"With Active MMR"}
          firstSubtextCount={projectTimesheetActiveEmployeesCountData?.count}
          secondSubtext={"No Active MMR"}
          secondSubtextCount={projectTimesheetNotActiveEmployeesCountData?.count}
          timesheetAnalyticsButtonType={ProjectTimesheetAnalyticsListButtonTypesEnum.none}
          projectId={projectId}
          selectedPeriodStart={selectedPeriodStart}
          selectedPeriodEnd={selectedPeriodEnd}
        />
      }
      {
        <TimesheetAnalyticsListButtonItem
          mainText={"Employees Total\nWith Active MMR"}
          primaryCount={projectTimesheetActiveEmployeesCountData?.count}
          firstSubtext={"In timesheets"}
          firstSubtextCount={projectTimesheetEmployeesInTimesheetData?.count}
          secondSubtext={"Not in timesheets"}
          secondSubtextCount={projectTimesheetEmployeesNotInTimesheetData?.count}
          timesheetAnalyticsButtonType={ProjectTimesheetAnalyticsListButtonTypesEnum.employees}
          projectId={projectId}
          selectedPeriodStart={selectedPeriodStart}
          selectedPeriodEnd={selectedPeriodEnd}
        />
      }

      {
        <TimesheetAnalyticsListButtonItem
          mainText={"Timesheets \n Total"}
          primaryCount={projectTimesheetItemsData?.count}
          firstSubtext={"Finalized"}
          firstSubtextCount={projectTimesheetFinalizedItemsData?.count}
          secondSubtext={"Non-Finalized"}
          secondSubtextCount={projectTimesheetNonFinalizedItemsData?.count}
          timesheetAnalyticsButtonType={ProjectTimesheetAnalyticsListButtonTypesEnum.timesheetItems}
          projectId={projectId}
          selectedPeriodStart={selectedPeriodStart}
          selectedPeriodEnd={selectedPeriodEnd}
        />
      }
    </div>
  );
};
export default TimesheetAnalyticsListButtons;
